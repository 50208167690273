<template>
  <div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #000000">
          <div
            class="modal-wrapper"
            style="height: 100%; overflow-y: auto; display: block"
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 100px auto"
            >
              <div
                class="modal-content"
                style="background-color: #141b1f !important"
              >
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เพิ่มบัญชีธนาคาร 
                            <!-- <img src="@/assets/svg/question.svg" role="button"> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="showModal = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body pt-0 pb-0" style="margin-left: 10px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div
                        class="col-3 p-0"
                        style="width: 144px; margin-top: 6px"
                      >
                        <p class="mb-0" style="color: rgb(255, 255, 255)">ชื่อบัญชี</p>
                      </div>
                      <div
                        class="col-9 p-0"
                        style="width: 280px; margin-top: 8px"
                      >
                        <p class="table-text-white mb-0" style="color: #9bacb6">
                          {{ NewBank.AccountName }}
                        </p>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-3 p-0" style="width: 144px">
                        <p
                          class="mb-0"
                          style="margin-top: 30px; color: rgb(255, 255, 255)"
                        >
                          เลือกธนาคาร
                        </p>
                      </div>
                      <div class="col-9 p-0" style="width: 280px">
                        <div
                          ref="baDropdown"
                          class="col-7 col-md-8 input-container"
                          style="
                            line-height: 24px;
                            margin-top: 21px;
                            position: relative;
                            width: 280px;
                          "
                          @click="open_Banks_Dropdown = true"
                          @blur="open_Banks_Dropdown = false"
                          tabindex="0"
                        >
                          <input
                            style="
                              padding: 16px;
                              padding-left: 10px;
                              width: 280px;
                            "
                            type="text"
                            v-model="searchBank"
                            @change="removeBank"
                          />
                          <span
                            v-if="searchBank.length == 0"
                            style="
                              position: absolute;
                              left: 40px;
                              top: 7px;
                              color: #566a76;
                              pointer-events: none;
                            "
                          >
                            เลือกธนาคาร
                          </span>
                          <span>
                            <img src="@/assets/svg/arrow-down.svg" role="button">
                          </span>
                          <div
                            style="width: 280px"
                            class="d-options"
                            :class="open_Banks_Dropdown ? 'show' : 'hidden'"
                          >
                            <ul
                              style="
                                max-height: 200px;
                                position: relative;
                                z-index: 999;
                                overflow: auto;
                              "
                            >
                              <li
                                v-for="(item, index) in filteredListBanks"
                                :key="index"
                                @click.stop="
                                  searchBank = item.bankName;
                                  selectedBankID = item.bankId;
                                  open_Banks_Dropdown = false;
                                  getBranchList();
                                "
                              >
                                {{ item.bankName }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="col-3 p-0" style="width: 144px">
                        <p
                          class="mb-0"
                          style="margin-top: 30px; color: rgb(255, 255, 255)"
                        >
                          ชื่อสาขา
                        </p>
                      </div>
                      <div class="col-9 p-0" style="width: 280px">
                        <div
                          ref="brDropdown"
                          class="col-7 col-md-8 input-container"
                          style="
                            line-height: 24px;
                            margin-top: 21px;
                            position: relative;
                            width: 280px;
                          "
                          @click="open_Branch_Dropdown = true"
                          @blur="open_Branch_Dropdown = false"
                          tabindex="0"
                        >
                          <input
                            style="
                              padding: 16px;
                              padding-left: 10px;
                              width: 280px;
                            "
                            type="text"
                            v-model="searchBranch"
                            :disabled="searchBank.length <= 0"
                            @change="removeBranch"
                          />
                          <span
                            v-if="searchBranch.length == 0"
                            style="
                              position: absolute;
                              left: 40px;
                              top: 7px;
                              color: #566a76;
                              pointer-events: none;
                            "
                          >
                            เลือกสาขา
                          </span>
                          <span>
                            <img src="@/assets/svg/arrow-down.svg" role="button">
                          </span>
                          <div
                            style="width: 280px"
                            class="d-options"
                            :class="open_Branch_Dropdown ? 'show' : 'hidden'"
                          >
                            <ul
                              style="
                                max-height: 200px;
                                position: relative;
                                z-index: 999;
                                overflow: auto;
                              "
                            >
                              <li
                                v-for="(item, index) in filteredListBranch"
                                :key="index"
                                @click.stop="
                                  searchBranch = item.bankBranchName;
                                  selectedBranchID = item.bankBranchId;
                                  open_Branch_Dropdown = false;
                                "
                              >
                                {{ item.bankBranchName }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row m-0">
                      <div class="col-3 p-0" style="width: 144px">
                        <p
                          class="m-0"
                          style="margin-top: 22px !important; color: rgb(255, 255, 255)"
                        >
                          ชื่อสาขา
                        </p>
                      </div>
                      <div
                        class="col-9 p-0"
                        style="margin-top: 16px; width: 280px"
                      >
                        <input
                          type="text"
                          v-model="NewBank.BranchName"
                          class="form-control w-full"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div> -->
                    <div class="row m-0">
                      <div class="col-3 p-0" style="width: 144px">
                        <p
                          class="text-dark-grey m-0"
                          style="margin-top: 22px !important; color: rgb(255, 255, 255)"
                        >
                          เลขที่บัญชี
                        </p>
                      </div>
                      <div
                        class="col-9 p-0"
                        style="margin-top: 16px; width: 280px"
                      >
                        <input
                          type="text"
                          v-model="NewBank.AccountNumber"
                          minlength="10"
                          maxlength="14"
                          class="form-control w-full"
                          placeholder="ระบุข้อมูล"
                          @input="validateInput"
                        />
                      </div>
                    </div>
                    <p class="text-dark-grey mt-45">
                      สามารถเพิ่มได้สูงสุด 2 บัญชี
                    </p>
                    <!-- modal button bottom -->
                    <div
                      class="button text-center baf"
                      style="margin: 24px 0px 37px"
                    >
                      <button type="button" @click="AddBank()">บันทึก</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- ba success modal -->
    <div v-if="ModalOTPSuccess">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div
                class="modal-content"
                style="
                  background-color: #141b1f !important;
                  margin-top: 0 !important;
                "
              >
                <div class="modal-body scroller-otp p-0">
                  <div class="container">
                    <div class="row">
                      <div class="col text-center">
                        <div class="checkTick" style="padding-top: 51px">
                          <!-- <img
                            class="image-tick-modal"
                            src="../../assets/images/profile-setting/tick.png"
                            alt=""
                          /> -->
                          <span>
                            <img src="@/assets/svg/check-success-green.svg">
                          </span>
                        </div>
                        <div class="success-message">
                          <p
                            class="text-grey"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              font-family: Noto Sans Thai !important;
                            "
                          >
                            เพิ่มบัญชีธนาคารสำเร็จเเล้ว
                          </p>
                        </div>
                        <div class="mabc">
                          <div class="row">
                            <div class="col text-dark-grey">ชื่อบัญชี</div>
                            <div class="col-7">{{ NewBank.AccountName }}</div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col text-dark-grey">ธนาคาร</div>
                            <div class="col-7">{{ searchBank }}</div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col text-dark-grey">ชื่อสาขา</div>
                            <div class="col-7">{{ searchBranch }}</div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col text-dark-grey">เลขที่บัญชี</div>
                            <div class="col-7">{{ NewBank.AccountNumber }}</div>
                          </div>
                        </div>
                        <div
                          class="finisButton-SuccesOtp"
                          style="padding-bottom: 32px; padding-top: 30px"
                          @click="ModalOTPSuccess = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              width: 39%;
                              color: white;
                              border-radius: 4px;
                              background-color: #09BE8B;
                              color: #000000;
                              margin-left: 8px;
                            "
                          >
                            ตรวจสอบสถานะ
                          </button>
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              width: 39%;
                              color: white;
                              border-radius: 4px;
                              margin-left: 8px;
                              font-weight: 400;
                            "
                          >
                            กลับหน้าหลัก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="failEditbank">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div style="height: 56px"></div>
                        <div class="checkTick ">
                          <img
                            class="image-danger-modal"
                            src="../../assets/images/wallet/cross.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message  ">
                          <p
                            class="text-grey"
                            v-html="text_error_bank"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 24.18px;
                              color: #d6dde1;
                            "
                          >
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="failEditbank = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                        <div style="height: 32px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- ba success modal end -->
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import EventBus from "@/event-bus";
export default {
  data() {
    return {
      failEditbank:false,
      text_error_bank:"",
      NewBank: {
        AccountName: "",
        SelectedBankID: "",
        SelectedBranchID: "",
        SelectedBankName: "",
        BranchName: "",
        AccountNumber: "",
      },
      open_dropdown1: false,
      d1: "",
      showModal: false,
      showModalOTP: false,
      ModalOTPSuccess: false,
      searchBank: "",
      searchBranch: "",
      selectedBankID: "",
      selectedBranchID: "",
      open_Banks_Dropdown: false,
      open_Branch_Dropdown: false,
      ListBanks: [],
      ListBranch: [],
      minLength: 10,
    };
  },
  mounted() {
    window.addEventListener("click", this.checkIfClickedOutside);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkIfClickedOutside);
  },
  watch: {
    open_Banks_Dropdown(newValue) {
      if (newValue) {
        this.searchBank = '';
        this.searchBranch = ''
      }
    },
    open_Branch_Dropdown(newValue) {
      if (newValue) {
        this.searchBranch = ''
      }
    }
  },
  methods: {
    removeBank() {
      this.selectedBankID = null;
      this.selectedBranchID = null;
      this.searchBranch = "";
    },
    removeBranch() {
      this.selectedBranchID = null;
    },
    format_accountnumber(value) {
      if (value.length == 10) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 4);
        let c = value.slice(4, 9);
        let d = value.slice(9, 10);
        return a + "-" + b + "-" + c + "-" + d;
      }
      else  if  (value.length == 12) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 5);
        let c = value.slice(5, 11);
        let d = value.slice(11, 12);
        return a + "-" + b + "-" + c + "-" + d;
      } else{
        return value
      }
    },
    validateInput() {
      this.NewBank.AccountNumber = this.NewBank.AccountNumber.replace(
        /[^0-9]/g,
        ""
      );
      if (this.NewBank.AccountNumber.length > 14) {
        this.NewBank.AccountNumber = this.NewBank.AccountNumber.slice(0, 14);
      }
    },
    validateBank() {
      if (!this.selectedBankID) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Select เลือกธนาคาร",
        // });
        this.text_error_bank = "โปรดเลือกธนาคาร"
        this.failEditbank = true
        return false;
      } else if (!this.searchBank) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Select เลือกธนาคาร",
        // });
        this.text_error_bank = "โปรดเลือกธนาคาร"
        this.failEditbank = true
        return false;
      } else if (!this.selectedBranchID) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Enter ชื่อสาขา",
        // });
        this.text_error_bank = "โปรดเลือกสาขา"
        this.failEditbank = true
        return false;
        
      } else if (this.NewBank.AccountNumber.length < 10) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Enter เลขที่บัญชี Minimun 10 Digits",
        // });
        this.text_error_bank = "โปรดกรอกเลขบัญชี ขั้นต่ำ 10 หลัก"
        this.failEditbank = true
        return false;
      }
      return true;
    },
    AddBank() {
      if (this.validateBank()) {
        this.NewBank.SelectedBankID = this.selectedBankID;
        this.NewBank.SelectedBankName = this.searchBank;
        this.NewBank.SelectedBranchID = this.selectedBranchID;
        this.NewBank.BranchName = this.searchBranch;

        let self = this;
        HTTP.post("/uc/member/check-bank-request", {
          bankId: self.NewBank.SelectedBankID,
          accountNumber: self.NewBank.AccountNumber,
        })
          .then(function (response) {
            if (response.data.code === 0) {
              self.$emit("AddBank", self.NewBank);
            } else {
              self.$notify({
                group: "notification",
                type: "error",
                title: "This bank account is registered.",
                text: response.data.message,
              });
            }
          })
          .catch(function (error) {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Unable to verify bank account information",
              text: error,
            });
          });
      }
    },
    getBankList() {
      let self = this;
      HTTP.get("/uc/member/get-bank-ref")
        .then(function (response) {
          if (response.data.code == "0") {
            self.ListBanks = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Member Banks Successful"
                  : "Unable to Get Banks List",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Banks List",
            text: error,
          });
        });
    },
    getBranchList() {
      let self = this;
      HTTP.get("/uc/member/get-bank-branch?bankId=" + this.selectedBankID)
        .then(function (response) {
          if (response.data.code == "0") {
            self.ListBranch = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Get Banks Branch Successfully"
                  : "Unable to Get Branch List",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Branch List",
            text: error,
          });
        });
    },
    show() {
      this.searchBank = "";
      this.searchBranch = "";
      this.NewBank.AccountNumber = "";
      this.NewBank.SelectedBankID = "";
      this.NewBank.SelectedBranchID = "";
      this.NewBank.SelectedBankName = "";
      this.NewBank.BranchName = "";
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    showSuccessModal() {
      this.ModalOTPSuccess = true;
    },
    checkIfClickedOutside(event) {
      const baDropdown = this.$refs.baDropdown;
      if (baDropdown && !baDropdown.contains(event.target)) {
        this.open_Banks_Dropdown = false;
      }
      const brDropdown = this.$refs.brDropdown;
      if (brDropdown && !brDropdown.contains(event.target)) {
        this.open_Branch_Dropdown = false;
      }
    },
  },
  computed: {
    filteredListBanks() {
      return this.ListBanks.filter((item) =>
        item.bankName.toLowerCase().includes(this.searchBank.toLowerCase())
      );
    },
    filteredListBranch() {
      return this.ListBranch.filter((item) =>
        item.bankBranchName
          .toLowerCase()
          .includes(this.searchBranch.toLowerCase())
      );
    },
  },
  created() {
    const resMember = JSON.parse(localStorage.getItem("resMember") || "{}");
    this.NewBank.AccountName = resMember?.resMember.realName || "";
    this.getBankList();
  },
};
</script>

<style lang="scss" scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0d10;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-mask p {
  line-height: 24px;
}
.select {
  width: 280px;
  height: 40px;
  background: #191b1c;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  justify-content: space-between;
  position: relative;
}
.select .options {
  position: absolute;
}
.options {
  position: absolute;
  top: 41px !important;
  width: 100% !important;
  z-index: 1;
  width: 346px;
  background-color: #2c3b44;
  border-radius: 4px;
}
.options ul {
  margin: 0;
  padding: 0;
}
.options ul li {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.options ul li:hover {
  background-color: #2c3b44;
}
.select svg {
  width: 12px;
  height: 8px;
}
.w-400 {
  font-weight: 400 !important;
}
.mt-45 {
  margin-top: 45px !important;
}
input {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgb(255, 255, 255) !important;
  caret-color: #09BE8B;
  line-height: 24px;
}
input::placeholder {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
p {
  line-height: 24px;
}
.bg-box {
  background-color: #191b1c;
}
.w-full {
  width: 100% !important;
}
.closeButton {
  margin-right: 20px;
}
.Heading {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 500;
  font-family: "Noto Sans Thai" !important;
  line-height: 24px;
  padding: 24px 0px 21px 24px;
}
.box-personal-info {
  padding: 12px 28px 12px 28px;
  border-radius: 3px;
  /* remove width if not responsive */
  width: 359px;
}
.text-box {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 68px;
  height: 40px;
}
.text-box:hover {
  color: #ffff;
}
.width-table {
  width: 764px;
}

.table-text {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.table-text-white {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
.button-camera-open {
  padding-top: 87px;
  padding-bottom: 80px;
  justify-content: center;
  display: flex;
  /* margin-left: 30px; */
}
.attachment-wrap {
  width: 409px;
  height: 119px;
  background-color: #121516;
  /* padding: 48px 150px 48px 182px; */
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  cursor: pointer;
}
.attachment-wrap-skull {
  background-color: #121516;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
}
.image-note {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  padding-top: 43px;
  padding-bottom: 20px;
}
.note-list {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
/* modal */
.text-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.iconPaperClip {
  color: #09BE8B;
  font-size: 16px;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-dark-grey {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}
.baf {
  button {
    width: 240px;
    height: 40px;
    margin: auto;
    background-color: #09BE8B;
    border-radius: 4px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-content {
  width: 100% !important;
}
.modal-body {
  padding-left: 20px;
  padding-right: 24px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}

.custom-checkbox {
  background: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  height: 17px;
  width: 17px;
}
.Get-started {
  margin: 20px;
}
.btn-cancel {
  background-color: #191b1c;
}

/* otp modal */
.otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  text-align: center;
}
.button-confirm {
  justify-content: center;
  display: flex;
  margin-left: 30px;
}
.form-control {
  outline: none;
}
.mabc {
  font-family: Sarabun;
  text-align: left;
  padding-left: 50px;
  .title {
    color: #9bacb6;
    width: 150px;
    margin-bottom: 14px;
  }
}
/* otp modal end */

/* modal otp finish succes */
.otp-success {
  max-width: 409px !important;
}
.otp-error {
  max-width: 409px !important;
}
.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.image-cross-modal {
  background-color: linear-gradient(360deg, #de2d40 0%, #ee5d6d 100%);
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.checkTick {
  padding-top: 100px;
}
.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}
.finisButton-SuccesOtp {
  padding-bottom: 100px;
}
/* modal otp finish succes end */

/* // %%%  scroller  start %%%% */

.scroller {
  /* // width: 300px; */
  height: 400px;
  overflow-y: scroll;
  /* // scrollbar-color: rebeccapurple green;
  // scrollbar-width: thin; */
}
.scroller-otp {
  /* height: 400px;
  overflow-y: scroll; */
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.paswordInput-width {
  width: 240px !important;
}
.SurnameWidth {
  width: 280px !important;
}
.SurnameWidthPasword {
  width: 331px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121516;
}
.modal-title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 28px;
}
/* modal select box address */
.address-input {
  width: 239px !important;
}
select > option:checked,
select > option:hover {
  /* box-shadow: 0 0 10px 100px #dc6900 inset; */
  color: #09BE8B !important;
}
/* select:focus{
    border-color: #09BE8B;
    outline:none;
} */
select {
  /* padding: 5px; */
  border-radius: 4px;
  height: 40px;
  width: 240px !important;
  color: #ffffff;
  /* padding: 8px 12px; */
  /* padding-right: 30px; */
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  border-color: transparent !important;
  background: #677f8e
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='14' height='14' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
    no-repeat;
  background-position: right 7px top 50%;
}
.modal-padding {
  margin-left: 1rem;
}
/* modal select box end */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-container {
  position: relative;
  input {
    background-color: #191b1c;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 40px;
    padding: 0 40px;
    text-transform: capitalize;
    &::placeholder {
      color: #566a76;
    }
  }
  span {
    color: #9bacb6;
    margin-left: -30px;
  }
  .coin-image {
    position: absolute;
    left: 50px;
    top: 6px;
    pointer-events: none;
    img {
      width: 23px;
    }
  }
}
.hidden {
  display: none;
}
.show {
  display: block;
}
.d-options {
  position: absolute;
  top: 45px;
  width: 59%;
  background-color: #191b1c;
  border-radius: 4px;
  z-index: 10;
  img {
    width: 23px;
    margin-left: 26px;
    margin-right: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #2c3b44;
      }
    }
  }
}
</style>
