<template>
  <div>
    <b-modal
      class="qrc-detail"
      id="qrc-detail"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <div>
        <h1>ยกเลิกรายการ</h1>
        <span @click="$bvModal.hide('qrc-detail')"
          ><svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <p>คุณยืนยันที่จะยกเลิกรายการฝากเงินนี้ใช่หรือไม่?</p>
        <div>
          <button @click="checkQrCode(qrFlag, data)" class="ob">
            ยืนยัน
          </button>
          <button @click="$bvModal.hide('qrc-detail')" class="gb">
            ยกเลิก
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { HTTP } from "../../../../config/axios";
export default {
  props:["data", "qrFlag"],
  data() {
    return {};
  },
  methods: {
    cancelQrCode(id){
      let self = this;
      HTTP.get("/uc/bank/qr/cancel/"+id, {
      }).then((res) => {
        if (res.data.code == 200) {
          self.$bvModal.hide('qrc-detail');
          self.$emit('qrcDone', true);
          self.$emit("getAllCashReports");
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: "error",
            text: res.data.message,
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    deleteQrCode(id){
      let self = this;
      HTTP.get("/uc/bank/qr/delete/"+id, {
      }).then((res) => {
        if (res.data.code == 200) {
          self.$bvModal.hide('qrc-detail');
          self.$emit('qrcDone', true);
          self.$emit("getAllCashReports");
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: "error",
            text: res.data.message,
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    checkQrCode(flag, id){
      if(flag == undefined && id == undefined){
        this.$bvModal.hide('qrc-detail');
        this.$bvModal.hide('deposit-qr-modal');
      }
      if(flag == "Cancel"){
        this.cancelQrCode(id);
      }else if(flag == "Delete"){
        this.deleteQrCode(id);
      }
    }
  }
};
</script>
<style lang="scss">
#qrc-detail___BV_modal_content_ {
  width: 360px;
  height: 255px;
  margin: 0 auto !important;
}
#qrc-detail___BV_modal_body_ {
  text-align: center;
  h1 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    margin-top: 8px;
  }
  span {
    position: absolute;
    right: 30px;
    top: 27px;
    cursor: pointer;
  }
  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #d6dde1;
    margin-top: 52px;
    margin-bottom: 58px;
  }
  button {
    width: 156px;
    height: 36px;
    border-radius: 4px;
    margin-left: 7px;
  }
  button.ob {
    background-color: #09BE8B;
    color: #0a0d10;
  }
  button.gb {
    background-color: #191b1c;
    color: #ffffff;
  }
}
</style>
