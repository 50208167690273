<template>
  <div>
    <b-modal
      class="qr-success-modal"
      id="request-done-modal"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
    >
      <div class="d-flex justify-content-center" style="margin-top: 4px">
        <img src="@/assets/images/wallet/tick-svg.png" alt="tick-image" />
      </div>
      <div class="m-center">
        <p class="c-title">ระบบได้รับคำขอถอนเหรียญเเล้ว</p>
        <p class="c-value">คุณจะได้รับอีเมลยืนยัน</p>
        <p class="c-value">เมื่อการถอนเหรียญสำเร็จ</p>
      </div>
      <div class="d-flex justify-content-center" style="margin-top: 21px">
        <button
          v-if="showStatusButton"
          @click="onCloseSuccessModel(1)"
          class="btn-yellow"
        >
          ตรวจสอบสถานะการถอน
        </button>
      </div>
      <div class="d-flex mt-3 justify-content-center">
        <button @click="onCloseSuccessModel(2)" class="btn-blue">
          กลับหน้าหลัก
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CoinStatusModel from "./CoinStatusModel.vue";
export default {
  components: { CoinStatusModel },
  props: {
    showStatusButton: { type: Boolean, default: false },
  },
  methods: {
    onCloseSuccessModel(n) {
      if (n === 1) {
        this.$emit("onShowStatus");
        this.$router.push({ path: "/uc/dw-report/coin" });
      } else {
        this.$emit("onCloseSuccessModel");
        //this.$router.push("/exchange");
        this.$emit('resetWithdrawCoin');
      }
      this.$bvModal.hide("request-done-modal");
    },
  },
};
</script>
<style lang="scss">
#request-done-modal___BV_modal_content_ {
  background-color: #141b1f;
  width: 458px;
  height: 355px;
  margin: auto;
  margin-top: 152px !important;
  font-family: "Sarabun";
  line-height: 24px;
  #request-done-modal___BV_modal_body_ {
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
    }
    .m-center {
      text-align: center;
      margin-top: 26px;
      p {
        margin: 0;
      }
      .c-title {
        color: #d6dde1;
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 5px;
      }
      .c-value {
        color: #9bacb6;
      }
    }
    .btn-blue {
      color: #fff;
      background-color: #38454d;
      border-radius: 4px;
      height: 40px;
      width: 176px;
      margin-left: 0 !important;
      font-weight: 400;
      &:hover {
        background-color: #283238 !important;
      }
    }
    .btn-yellow {
      color: #0a0d10;
      background-color: #09BE8B;
      border-radius: 4px;
      height: 40px;
      width: 176px;
      margin-right: 0 !important;
      font-weight: 500;
      &:hover {
        color: #0a0d10;
        background-color: #66cdb0 !important;
      }
    }
  }
}
</style>
