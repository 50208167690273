<template>
    <div>

        <b-modal id="fatca-form-detail-modal" hide-header centered scrollable size="xl">
            <div class="container pt-3">
                <div class="row">
                    <div class="col">

                        <div class="row">
                            <h4>page 1</h4>
                            <div class="col-6">
                                <div>
                                    <h4>General Instructions</h4>
                                    <p>
                                        Section references are to the Internal Revenue Code unless otherwise
                                        noted.
                                    </p>
                                    <p>
                                        <span><b>Future developments</b></span>For the latest information about
                                        developments related to Form W-9
                                        and its instructions, such as legislation enacted after they were
                                        published, go to www.irs.gov/FormW9.
                                    </p>
                                </div>
                                <div>
                                    <h4>What’s New</h4>
                                    <p>
                                        Line 3a has been modified to clarify how a disregarded entity
                                        completes this line. An LLC that is a disregarded entity should
                                        check the appropriate box for the tax classification of its owner.
                                        Otherwise, it should check the “LLC” box and enter its appropriate
                                        tax classification.
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;New line 3b has been added to this form. A
                                        flow-through entity is required to complete this line to indicate
                                        that it has direct or indirect foreign partners, owners, or
                                        beneficiaries when it provides the Form W-9 to another flow-through
                                        entity in which it has an ownership interest. This change is
                                        intended to provide a flow-through entity with information regarding
                                        the status of its indirect foreign partners, owners, or
                                        beneficiaries, so that it can satisfy any applicable reporting
                                        requirements. For example, a partnership that has any indirect
                                        foreign partners may be required to complete Schedules K-2 and K-3.
                                        See the Partnership Instructions for Schedules K-2 and K-3 (Form
                                        1065).
                                    </p>
                                </div>
                                <div>
                                    <h4>Purpose of Form</h4>
                                    <p>
                                        An individual or entity (Form W-9 requester) who is required to file
                                        an information return with the IRS is giving you this form because
                                        they
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <h4>page 2</h4>
                            <div class="col-6">
                                <p>
                                    must obtain your correct taxpayer identification number (TIN), which
                                    may be your social security number (SSN), individual taxpayer
                                    identification number (ITIN), adoption taxpayer identification number
                                    (ATIN), or employer identification number (EIN), to report on an
                                    information return the amount paid to you, or other amount reportable
                                    on an information return. Examples of information returns include, but
                                    are not limited to, the following.
                                </p>
                                <ul>
                                    <li>Form 1099-INT (interest earned or paid).</li>
                                    <li>
                                        Form 1099-DIV (dividends, including those from stocks or mutual
                                        funds).
                                    </li>
                                    <li>
                                        Form 1099-MISC (various types of income, prizes, awards, or gross
                                        proceeds).
                                    </li>
                                    <li>
                                        Form 1099-B (stock or mutual fund sales and certain other
                                        transactions by brokers).
                                    </li>
                                    <li>Form 1099-S (proceeds from real estate transactions).</li>
                                    <li>
                                        Form 1099-K (merchant card and third-party network transactions).
                                    </li>
                                    <li>
                                        Form 1098 (home mortgage interest), 1098-E (student loan interest),
                                        and 1098-T (tuition).
                                    </li>
                                    <li>Form 1099-C (canceled debt).</li>
                                    <li>
                                        Form 1099-A (acquisition or abandonment of secured property). Use
                                        Form W-9 only if you are a U.S. person (including a resident alien),
                                        to provide your correct TIN.
                                    </li>
                                </ul>
                                <p>
                                    <span><b>Caution:</b></span> If you don’t return Form W-9 to the
                                    requester with a TIN, you might be subject to backup withholding. See
                                    What is backup withholding, later.
                                </p>
                                <p>
                                    <span><b>By signing the filled-out form</b></span>, you:
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;1. Certify that the TIN you are giving is
                                    correct (or you are waiting for a number to be issued);
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;2. Certify that you are not subject to backup
                                    withholding; or
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;3. Claim exemption from backup withholding if
                                    you are a U.S. exempt payee; andด
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;4. Certify to your non-foreign status for
                                    purposes of withholding under chapter 3 or 4 of the Code (if
                                    applicable); and
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;5. Certify that FATCA code(s) entered on this
                                    form (if any) indicating that you are exempt from the FATCA reporting
                                    is correct. See What Is FATCA Reporting, later, for further
                                    information.
                                </p>
                                <p>
                                    <span><b>Note:</b></span> If you are a U.S. person and a requester
                                    gives you a form other than Form W-9 to request your TIN, you must use
                                    the requester’s form if it is substantially similar to this Form W-9.
                                </p>
                                <p>
                                    <span><b>Definition of a U.S. person.</b></span> For federal tax
                                    purposes, you are considered a U.S. person if you are:
                                </p>
                                <ul>
                                    <li>An individual who is a U.S. citizen or U.S. resident alien;</li>
                                    <li>
                                        A partnership, corporation, company, or association created or
                                        organized in the United States or under the laws of the United
                                        States;
                                    </li>
                                    <li>An estate (other than a foreign estate); or</li>
                                    <li>
                                        A domestic trust (as defined in Regulations section 301.7701-7).
                                    </li>
                                </ul>
                                <p>
                                    <span><b>Establishing U.S. status for purposes of chapter 3 and chapter
                                            4</b></span>
                                    withholding. Payments made to foreign persons, including certain
                                    distributions, allocations of income, or transfers of sales proceeds,
                                    may be subject to withholding under chapter 3 or chapter 4 of the Code
                                    (sections 1441–1474). Under those rules, if a Form W-9 or other
                                    certification of non-foreign status has not been received, a
                                    withholding agent, transferee, or partnership (payor) generally
                                    applies presumption rules that may require the payor to withhold
                                    applicable tax from the recipient, owner, transferor, or partner
                                    (payee). See Pub. 515, Withholding of Tax on Nonresident Aliens and
                                    Foreign Entities.
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;The following persons must provide Form W-9 to
                                    the payor for purposes of establishing its non-foreign status.
                                </p>
                                <ul>
                                    <li>
                                        In the case of a disregarded entity with a U.S. owner, the U.S.
                                        owner of the disregarded entity and not the disregarded entity.
                                    </li>
                                    <li>
                                        In the case of a grantor trust with a U.S. grantor or other U.S.
                                        owner, generally, the U.S. grantor or other U.S. owner of the
                                        grantor trust and not the grantor trust.
                                    </li>
                                    <li>
                                        In the case of a U.S. trust (other than a grantor trust), the U.S.
                                        trust and not the beneficiaries of the trust.
                                    </li>
                                </ul>
                                <p>
                                    See Pub. 515 for more information on providing a Form W-9 or a
                                    certification of non-foreign status to avoid withholding.
                                </p>
                            </div>
                            <div class="col-6">
                                <div>
                                    <p>
                                        <span><b>Foreign person.</b></span> If you are a foreign person or
                                        the U.S. branch of a foreign bank that has elected to be treated as
                                        a U.S. person (under Regulations section 1.1441-1(b)(2)(iv) or other
                                        applicable section for chapter 3 or 4 purposes), do not use Form
                                        W-9. Instead, use the appropriate Form W-8 or Form 8233 (see Pub.
                                        515). If you are a qualified foreign pension fund under Regulations
                                        section 1.897(l)-1(d), or a partnership that is wholly owned by
                                        qualified foreign pension funds, that is treated as a non-foreign
                                        person for purposes of section 1445 withholding, do not use Form
                                        W-9. Instead, use Form W-8EXP (or other certification of non-foreign
                                        status).
                                    </p>
                                    <p>
                                        <span><b>Nonresident alien who becomes a resident alien.</b></span>
                                        Generally, only a nonresident alien individual may use the terms of
                                        a tax treaty to reduce or eliminate U.S. tax on certain types of
                                        income. However, most tax treaties contain a provision known as a
                                        saving clause. Exceptions specified in the saving clause may permit
                                        an exemption from tax to continue for certain types of income even
                                        after the payee has otherwise become a U.S. resident alien for tax
                                        purposes.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you are a U.S. resident alien who is
                                        relying on an exception contained in the saving clause of a tax
                                        treaty to claim an exemption from U.S. tax on certain types of
                                        income, you must attach a statement to Form W-9 that specifies the
                                        following five items.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;1. The treaty country. Generally, this must
                                        be the same treaty under which you claimed exemption from tax as a
                                        nonresident alien.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;2. The treaty article addressing the income.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;3. The article number (or location) in the
                                        tax treaty that contains the saving clause and its exceptions.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;4. The type and amount of income that
                                        qualifies for the exemption from tax.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;5. Sufficient facts to justify the exemption
                                        from tax under the terms of the treaty article.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<span><b>Example.</b></span> Article 20 of
                                        the U.S.-China income tax treaty allows an exemption from tax for
                                        scholarship income received by a Chinese student temporarily present
                                        in the United States. Under U.S. law, this student will become a
                                        resident alien for tax purposes if their stay in the United States
                                        exceeds 5 calendar years. However, paragraph 2 of the first Protocol
                                        to the U.S.-China treaty (dated April 30, 1984) allows the
                                        provisions of Article 20 to continue to apply even after the Chinese
                                        student becomes a resident alien of the United States. A Chinese
                                        student who qualifies for this exception (under paragraph 2 of the
                                        first Protocol) and is relying on this exception to claim an
                                        exemption from tax on their scholarship or fellowship income would
                                        attach to Form W-9 a statement that includes the information
                                        described above to support that exemption.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you are a nonresident alien or a foreign
                                        entity, give the requester the appropriate completed Form W-8 or
                                        Form 8233.
                                    </p>
                                </div>
                                <div>
                                    <h4>Backup Withholding</h4>
                                    <p>
                                        <span><b>What is backup withholding?</b></span> Persons making
                                        certain payments to you must under certain conditions withhold and
                                        pay to the IRS 24% of such payments. This is called “backup
                                        withholding.” Payments that may be subject to backup withholding
                                        include, but are not limited to, interest, tax-exempt interest,
                                        dividends, broker and barter exchange transactions, rents,
                                        royalties, nonemployee pay, payments made in settlement of payment
                                        card and third-party network transactions, and certain payments from
                                        fishing boat operators. Real estate transactions are not subject to
                                        backup withholding.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;You will not be subject to backup
                                        withholding on payments you receive if you give the requester your
                                        correct TIN, make the proper certifications, and report all your
                                        taxable interest and dividends on your tax return.
                                    </p>
                                    <p>
                                        <span><b>Payments you receive will be subject to backup withholding
                                                if:</b></span>
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;1. You do not furnish your TIN to the
                                        requester;
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;2. You do not certify your TIN when required
                                        (see the instructions for Part II for details);
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;3. The IRS tells the requester that you
                                        furnished an incorrect TIN;
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;4. The IRS tells you that you are subject to
                                        backup withholding because you did not report all your interest and
                                        dividends on your tax return (for reportable interest and dividends
                                        only); or
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;5. You do not certify to the requester that
                                        you are not subject to backup withholding, as described in item 4
                                        under <i>“By signing the filledout form”</i> above (for reportable
                                        interest and dividend accounts opened after 1983 only).
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <h4>page 3</h4>
                            <div class="col-6">
                                <div>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Certain payees and payments are exempt from
                                        backup withholding. See Exempt payee code, later, and the separate
                                        Instructions for the Requester of Form W-9 for more information.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;See also Establishing U.S. status for
                                        purposes of chapter 3 and chapter 4 withholding, earlier.
                                    </p>
                                </div>
                                <div>
                                    <h4>What Is FATCA Reporting?</h4>
                                    <p>
                                        The Foreign Account Tax Compliance Act (FATCA) requires a
                                        participating foreign financial institution to report all U.S.
                                        account holders that are specified U.S. persons. Certain payees are
                                        exempt from FATCA reporting. See Exemption from FATCA reporting
                                        code, later, and the Instructions for the Requester of Form W-9 for
                                        more information.
                                    </p>
                                </div>
                                <div>
                                    <h4>Updating Your Information</h4>
                                    <p>
                                        You must provide updated information to any person to whom you
                                        claimed to be an exempt payee if you are no longer an exempt payee
                                        and anticipate receiving reportable payments in the future from this
                                        person. For example, you may need to provide updated information if
                                        you are a C corporation that elects to be an S corporation, or if
                                        you are no longer tax exempt. In addition, you must furnish a new
                                        Form W-9 if the name or TIN changes for the account, for example, if
                                        the grantor of a grantor trust dies.
                                    </p>
                                </div>
                                <div>
                                    <h4>Penalties</h4>
                                    <p>
                                        <span><b>Failure to furnish TIN.</b></span> If you fail to furnish
                                        your correct TIN to a requester, you are subject to a penalty of $50
                                        for each such failure unless your failure is due to reasonable cause
                                        and not to willful neglect.
                                    </p>
                                    <p>
                                        <span><b>Civil penalty for false information with respect to
                                                withholding.</b></span>
                                        If you make a false statement with no reasonable basis that results
                                        in no backup withholding, you are subject to a $500 penalty.
                                    </p>
                                    <p>
                                        <span><b>Criminal penalty for falsifying information.</b></span>
                                        Willfully falsifying certifications or affirmations may subject you
                                        to criminal penalties including fines and/or imprisonment.
                                    </p>
                                    <p>
                                        <span><b>Misuse of TINs.</b></span> If the requester discloses or
                                        uses TINs in violation of federal law, the requester may be subject
                                        to civil and criminal penalties.
                                    </p>
                                </div>
                                <div>
                                    <h4>Specific Instructions</h4>
                                    <p><b>Line 1</b></p>
                                    <p>
                                        You must enter one of the following on this line; do not leave this
                                        line blank. The name should match the name on your tax return.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If this Form W-9 is for a joint account
                                        (other than an account maintained by a foreign financial institution
                                        (FFI)), list first, and then circle, the name of the person or
                                        entity whose number you entered in Part I of Form W-9. If you are
                                        providing Form W-9 to an FFI to document a joint account, each
                                        holder of the account that is a U.S. person must provide a Form W-9.
                                    </p>
                                    <p>
                                        <b>• Individual.</b> Generally, enter the name shown on your tax
                                        return. If you have changed your last name without informing the
                                        Social Security Administration (SSA) of the name change, enter your
                                        first name, the last name as shown on your social security card, and
                                        your new last name.
                                    </p>
                                    <p>
                                        <b> Note for ITIN applicant: </b>
                                        Enter your individual name as it was entered on your Form W-7
                                        application, line 1a. This should also be the same as the name you
                                        entered on the Form 1040 you filed with your application.
                                    </p>
                                    <p>
                                        <b>• Sole proprietor.</b>
                                        Enter your individual name as shown on your Form 1040 on line 1.
                                        Enter your business, trade, or “doing business as” (DBA) name on
                                        line 2.
                                    </p>
                                    <p>
                                        <b>• Partnership, C corporation, S corporation, or LLC, other than a
                                            disregarded entity.</b>
                                        Enter the entity’s name as shown on the entity’s tax return on line
                                        1 and any business, trade, or DBA name on line 2.
                                    </p>
                                    <p>
                                        <b> • Other entities. </b>
                                        Enter your name as shown on required U.S. federal tax documents on
                                        line 1. This name should match the name shown on the charter or
                                        other legal document creating the entity. Enter any business, trade,
                                        or DBA name on line 2.
                                    </p>
                                    <p>
                                        <b> • Disregarded entity. </b>
                                        In general, a business entity that has a single owner, including an
                                        LLC, and is not a corporation, is disregarded as an entity separate
                                        from its owner (a disregarded entity). See Regulations section
                                        301.7701-2(c)(2). A disregarded entity should check the appropriate
                                        box for the tax classification of its owner. Enter the owner’s name
                                        on line 1. The name of the owner entered on line 1 should never be a
                                        disregarded entity. The name on line 1 should be the name shown on
                                        the income tax return on which the income should be reported. For
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div>
                                    <p>
                                        example, if a foreign LLC that is treated as a disregarded entity
                                        for U.S. federal tax purposes has a single owner that is a U.S.
                                        person, the U.S. owner’s name is required to be provided on line 1.
                                        If the direct owner of the entity is also a disregarded entity,
                                        enter the first owner that is not disregarded for federal tax
                                        purposes. Enter the disregarded entity’s name on line 2. If the
                                        owner of the disregarded entity is a foreign person, the owner must
                                        complete an appropriate Form W-8 instead of a Form W-9. This is the
                                        case even if the foreign person has a U.S. TIN.
                                    </p>
                                    <h4>Line 2</h4>
                                    <p>
                                        If you have a business name, trade name, DBA name, or disregarded
                                        entity name, enter it on line 2.
                                    </p>
                                    <h4>Line 3a</h4>
                                    <p>
                                        Check the appropriate box on line 3a for the U.S. federal tax
                                        classification of the person whose name is entered on line 1. Check
                                        only one box on line 3a.
                                    </p>
                                    <table>
                                        <tr>
                                            <th class='border-color'>
                                                <p>IF the entity/individual on line 1 is a(n) . . .</p>
                                            </th>
                                            <th class='border-color'>
                                                <p>THEN check the box for . . .</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Corporation</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Corporation.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Individual or</p>
                                                <p>• Sole proprietorship</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Individual/sole proprietor.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    • LLC classified as a partnership for U.S. federal tax
                                                    purposes or
                                                </p>
                                                <p>
                                                    • LLC that has filed Form 8832 or 2553 electing to be taxed as
                                                    a corporation
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>
                                                    Limited liability company and enter the appropriate tax
                                                    classification:
                                                </p>
                                                <p>P = Partnership,</p>
                                                <p>C = C corporation, or</p>
                                                <p>S = S corporation.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Partnership</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Partnership.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Trust/estate</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Trust/estate.</p>
                                            </td>
                                        </tr>
                                    </table>
                                    <h4>Line 3b</h4>
                                    <p>
                                        Check this box if you are a partnership (including an LLC classified
                                        as a partnership for U.S. federal tax purposes), trust, or estate
                                        that has any foreign partners, owners, or beneficiaries, and you are
                                        providing this form to a partnership, trust, or estate, in which you
                                        have an ownership interest. You must check the box on line 3b if you
                                        receive a Form W-8 (or documentary evidence) from any partner,
                                        owner, or beneficiary establishing foreign status or if you receive
                                        a Form W-9 from any partner, owner, or beneficiary that has checked
                                        the box on line 3b.
                                    </p>
                                    <p>
                                        <b>Note:</b> A partnership that provides a Form W-9 and checks box
                                        3b may be required to complete Schedules K-2 and K-3 (Form 1065).
                                        For more information, see the Partnership Instructions for Schedules
                                        K-2 and K-3 (Form 1065).
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you are required to complete line 3b but
                                        fail to do so, you may not receive the information necessary to file
                                        a correct information return with the IRS or furnish a correct payee
                                        statement to your partners or beneficiaries. See, for example,
                                        sections 6698, 6722, and 6724 for penalties that may apply.
                                    </p>
                                    <h4>Line 4 Exemptions</h4>
                                    <p>
                                        If you are exempt from backup withholding and/or FATCA reporting,
                                        enter in the appropriate space on line 4 any code(s) that may apply
                                        to you.
                                    </p>
                                    <p>
                                        <b>Exempt payee code.</b>
                                    </p>
                                    <p>
                                        • Generally, individuals (including sole proprietors) are not exempt
                                        from backup withholding.
                                    </p>
                                    <p>
                                        • Except as provided below, corporations are exempt from backup
                                        withholding for certain payments, including interest and dividends.
                                    </p>
                                    <p>
                                        • Corporations are not exempt from backup withholding for payments
                                        made in settlement of payment card or third-party network
                                        transactions.
                                    </p>
                                    <p>
                                        • Corporations are not exempt from backup withholding with respect
                                        to attorneys’ fees or gross proceeds paid to attorneys, and
                                        corporations that provide medical or health care services are not
                                        exempt with respect to payments reportable on Form 1099-MISC.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;The following codes identify payees that are
                                        exempt from backup withholding. Enter the appropriate code in the
                                        space on line 4.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;1—An organization exempt from tax under
                                        section 501(a), any IRA, or a custodial account under section
                                        403(b)(7) if the account satisfies the requirements of section
                                        401(f)(2).
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;2—The United States or any of its agencies
                                        or instrumentalities.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;3—A state, the District of Columbia, a U.S.
                                        commonwealth or territory, or any of their political subdivisions or
                                        instrumentalities.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;4—A foreign government or any of its
                                        political subdivisions, agencies, or instrumentalities.
                                    </p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;5—A corporation.</p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;6—A dealer in securities or commodities
                                        required to register in the United States, the District of Columbia,
                                        or a U.S. commonwealth or territory.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;7—A futures commission merchant registered
                                        with the Commodity Futures Trading Commission.
                                    </p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;8—A real estate investment trust.</p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;9—An entity registered at all times during
                                        the tax year under the Investment Company Act of 1940.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;10—A common trust fund operated by a bank
                                        under section 584(a).
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;11—A financial institution as defined under
                                        section 581.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;12—A middleman known in the investment
                                        community as a nominee or custodian.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;13—A trust exempt from tax under section 664
                                        or described in section 4947.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;The following chart shows types of payments
                                        that may be exempt from backup withholding. The chart applies to the
                                        exempt payees listed above, 1 through 13.
                                    </p>
                                    <table>
                                        <tr>
                                            <th class='border-color'>
                                                <p>IF the payment is for . . .</p>
                                            </th>
                                            <th class='border-color'>
                                                <p>THEN the payment is exempt for . . .</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Interest and dividend payments</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>All exempt payees except for 7.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Broker transactions</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>
                                                    Exempt payees 1 through 4 and 6 through 11 and all C
                                                    corporations. S corporations must not enter an exempt payee
                                                    code because they are exempt only for sales of noncovered
                                                    securities acquired prior to 2012.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>• Barter exchange transactions and patronage dividends</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Exempt payees 1 through 4.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    • Payments over $600 required to be reported and direct sales
                                                    over $5,000<sup>1</sup>
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Generally, exempt payees 1 through 5<sup>2</sup></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    • Payments made in settlement of payment card or third-party
                                                    network transactions
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Exempt payees 1 through 4.</p>
                                            </td>
                                        </tr>
                                    </table>
                                    <p>
                                        <sup>1</sup>See Form 1099-MISC, Miscellaneous Information, and its
                                        instructions.
                                    </p>
                                    <p>
                                        <sup>2</sup>However, the following payments made to a corporation
                                        and reportable on Form 1099-MISC are not exempt from backup
                                        withholding: medical and health care payments, attorneys’ fees,
                                        gross proceeds paid to an attorney reportable under section 6045(f),
                                        and payments for services paid by a federal executive agency.
                                    </p>
                                    <p>
                                        <b> Exemption from FATCA reporting code. </b> The following codes
                                        identify payees that are exempt from reporting under FATCA. These
                                        codes apply to persons submitting this form for accounts maintained
                                        outside of the United States by certain foreign financial
                                        institutions. Therefore, if you are only submitting this form for an
                                        account you hold in the United States, you may leave this field
                                        blank. Consult with the person requesting this form if you are
                                        uncertain if the financial institution is subject to these
                                        requirements. A requester may indicate that a code is not required
                                        by providing you with a Form W-9 with “Not Applicable” (or any
                                        similar indication) entered on the line for a FATCA exemption code.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;A—An organization exempt from tax under
                                        section 501(a) or any individual retirement plan as defined in
                                        section 7701(a)(37).
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;B—The United States or any of its agencies
                                        or instrumentalities.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;C—A state, the District of Columbia, a U.S.
                                        commonwealth or territory, or any of their political subdivisions or
                                        instrumentalities.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;D—A corporation the stock of which is
                                        regularly traded on one or more established securities markets, as
                                        described in Regulations section 1.1472-1(c)(1)(i).
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;E—A corporation that is a member of the same
                                        expanded affiliated group as a corporation described in Regulations
                                        section 1.1472-1(c)(1)(i).
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;F—A dealer in securities, commodities, or
                                        derivative financial instruments (including notional principal
                                        contracts, futures, forwards, and options) that is registered as
                                        such under the laws of the United States or any state.
                                    </p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;G—A real estate investment trust.</p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;H—A regulated investment company as defined
                                        in section 851 or an entity registered at all times during the tax
                                        year under the Investment Company Act of 1940.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;I—A common trust fund as defined in section
                                        584(a).
                                    </p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;J—A bank as defined in section 581.</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;K—A broker.</p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;L—A trust exempt from tax under section 664
                                        or described in section 4947(a)(1).
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;M—A tax-exempt trust under a section 403(b)
                                        plan or section 457(g) plan.
                                    </p>
                                    <p>
                                        <b>Note: </b>
                                        You may wish to consult with the financial institution requesting
                                        this form to determine whether the FATCA code and/or exempt payee
                                        code should be completed.
                                    </p>
                                    <h4>Line 5</h4>
                                    <p>
                                        Enter your address (number, street, and apartment or suite number).
                                        This is where the requester of this Form W-9 will mail your
                                        information returns. If this address differs from the one the
                                        requester already has on file, enter “NEW” at the top. If a new
                                        address is provided, there is still a chance the old address will be
                                        used until the payor changes your address in their records.
                                    </p>
                                    <h4>Line 6</h4>
                                    <p>Enter your city, state, and ZIP code.</p>
                                    <h4>Part I. Taxpayer Identification Number (TIN)</h4>
                                    <p>
                                        <b>Enter your TIN in the appropriate box.</b> If you are a resident
                                        alien and you do not have, and are not eligible to get, an SSN, your
                                        TIN is your IRS ITIN. Enter it in the entry space for the Social
                                        security number. If you do not have an ITIN, see How to get a TIN
                                        below.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you are a sole proprietor and you have an
                                        EIN, you may enter either your SSN or EIN.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you are a single-member LLC that is
                                        disregarded as an entity separate from its owner, enter the owner’s
                                        SSN (or EIN, if the owner has one). If the LLC is classified as a
                                        corporation or partnership, enter the entity’s EIN.
                                    </p>
                                    <p>
                                        <b>Note:</b> See What Name and Number To Give the Requester, later,
                                        for further clarification of name and TIN combinations.
                                    </p>
                                    <p>
                                        <b>How to get a TIN.</b>
                                        If you do not have a TIN, apply for one immediately. To apply for an
                                        SSN, get Form SS-5, Application for a Social Security Card, from
                                        your local SSA office or get this form online at www.SSA.gov. You
                                        may also get this form by calling 800-772-1213. Use Form W-7,
                                        Application for IRS Individual Taxpayer Identification Number, to
                                        apply for an ITIN, or Form SS-4, Application for Employer
                                        Identification Number, to apply for an EIN. You can apply for an EIN
                                        online by accessing the IRS website at www.irs.gov/EIN. Go to
                                        www.irs.gov/Forms to view, download, or print Form W-7 and/or Form
                                        SS-4. Or, you can go to www.irs.gov/OrderForms to place an order and
                                        have Form W-7 and/or Form SS-4 mailed to you within 15 business
                                        days.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you are asked to complete Form W-9 but do
                                        not have a TIN, apply for a TIN and enter “Applied For” in the space
                                        for the TIN, sign and date the form, and give it to the requester.
                                        For interest and dividend payments, and certain payments made with
                                        respect to readily tradable instruments, you will generally have 60
                                        days to get a TIN and give it to the requester before you are
                                        subject to backup withholding on payments. The 60-day rule does not
                                        apply to other types of payments. You will be subject to backup
                                        withholding on all such payments until you provide your TIN to the
                                        requester.
                                    </p>
                                    <p>
                                        <b>Note:</b> Entering “Applied For” means that you have already
                                        applied for a TIN or that you intend to apply for one soon. See also
                                        Establishing U.S. status for purposes of chapter 3 and chapter 4
                                        withholding, earlier, for when you may instead be subject to
                                        withholding under chapter 3 or 4 of the Code.
                                    </p>
                                    <p>
                                        <b>Caution:</b>
                                        A disregarded U.S. entity that has a foreign owner must use the
                                        appropriate Form W-8.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <h4>page 5</h4>
                            <div class="col-6">
                                <div>
                                    <h4>Part II. Certification</h4>
                                    <p>
                                        To establish to the withholding agent that you are a U.S. person, or
                                        resident alien, sign Form W-9. You may be requested to sign by the
                                        withholding agent even if item 1, 4, or 5 below indicates otherwise.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;For a joint account, only the person whose
                                        TIN is shown in Part I should sign (when required). In the case of a
                                        disregarded entity, the person identified on line 1 must sign.
                                        Exempt payees, see Exempt payee code, earlier.
                                    </p>
                                    <p>
                                        <b>Signature requirements.</b> Complete the certification as
                                        indicated in items 1 through 5 below.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<b>
                                            1. Interest, dividend, and barter exchange accounts opened before
                                            1984 and broker accounts considered active during 1983.
                                        </b>
                                        You must give your correct TIN, but you do not have to sign the
                                        certification.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<b>2. Interest, dividend, broker, and barter exchange
                                            accounts
                                            opened after 1983 and broker accounts considered inactive during
                                            1983.</b>
                                        You must sign the certification or backup withholding will apply. If
                                        you are subject to backup withholding and you are merely providing
                                        your correct TIN to the requester, you must cross out item 2 in the
                                        certification before signing the form.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b> 3. Real estate transactions. </b>
                                        You must sign the certification. You may cross out item 2 of the
                                        certification.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b> 4. Other payments. </b>
                                        You must give your correct TIN, but you do not have to sign the
                                        certification unless you have been notified that you have previously
                                        given an incorrect TIN. “Other payments” include payments made in
                                        the course of the requester’s trade or business for rents,
                                        royalties, goods (other than bills for merchandise), medical and
                                        health care services (including payments to corporations), payments
                                        to a nonemployee for services, payments made in settlement of
                                        payment card and third-party network transactions, payments to
                                        certain fishing boat crew members and fishermen, and gross proceeds
                                        paid to attorneys (including payments to corporations).
                                    </p>
                                    <p>
                                        <b>
                                            5. Mortgage interest paid by you, acquisition or abandonment of
                                            secured property, cancellation of debt, qualified tuition program
                                            payments (under section 529), ABLE accounts (under section 529A),
                                            IRA, Coverdell ESA, Archer MSA or HSA contributions or
                                            distributions, and pension distributions.
                                        </b>
                                        You must give your correct TIN, but you do not have to sign the
                                        certification.
                                    </p>
                                    <h4>What Name and Number To Give the Requester</h4>
                                    <table>
                                        <tr>
                                            <th class='border-color'>
                                                <p>For this type of account:</p>
                                            </th>
                                            <th class='border-color'>
                                                <p>Give name and SSN of:</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>1. Individua</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The individual</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    2. Two or more individuals (joint account) other than an
                                                    account maintained by an FFI
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>
                                                    The actual owner of the account or, if combined funds, the
                                                    first individual on the account<sup>1</sup>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    3. Two or more U.S. persons (joint account maintained by an
                                                    FFI)
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Each holder of the account</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    4. Custodial account of a minor (Uniform Gift to Minors Act)
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The minor<sup>2</sup></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    5. a. The usual revocable savings trust (grantor is also
                                                    trustee) The grantor-trustee1 b. So-called trust account that
                                                    is not a legal or valid trust under state law
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The grantor-trustee<sup>1</sup></p>
                                                <p>The actual owner<sup>1</sup></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    6. Sole proprietorship or disregarded entity owned by an
                                                    individual
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The owner<sup>3</sup></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    7. Grantor trust filing under Optional Filing Method 1 (see
                                                    Regulations section 1.671-4(b)(2)(i)(A))**
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The grantor*</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div>
                                    <table>
                                        <tr>
                                            <th class='border-color'>
                                                <p>For this type of account:</p>
                                            </th>
                                            <th class='border-color'>
                                                <p>Give name and SSN of:</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>8. Disregarded entity not owned by an individual</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The owner</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>9. A valid trust, estate, or pension trust</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>Legal entity<sup>4</sup></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    10. Corporation or LLC electing corporate status on Form 8832
                                                    or Form 2553
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The corporation</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    11. Association, club, religious, charitable, educational, or
                                                    other tax-exempt organization
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The organization</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>12. Partnership or multi-member LLC</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The partnership</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>13. A broker or registered nominee</p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The broker or nominee</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    14. Account with the Department of Agriculture in the name of
                                                    a public entity (such as a state or local government, school
                                                    district, or prison) that receives agricultural program
                                                    payments
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The public entity</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='border-color'>
                                                <p>
                                                    15. Grantor trust filing Form 1041 or under the Optional
                                                    Filing Method 2, requiring Form 1099 (see Regulations section
                                                    1.671-4(b)(2)(i)(B))**
                                                </p>
                                            </td>
                                            <td class='border-color'>
                                                <p>The trust</p>
                                            </td>
                                        </tr>
                                    </table>
                                    <p>
                                        <sup>1</sup>List first and circle the name of the person whose
                                        number you furnish. If only one person on a joint account has an
                                        SSN, that person’s number must be furnished.
                                    </p>
                                    <p>
                                        <sup>2</sup>Circle the minor’s name and furnish the minor’s SSN.
                                    </p>
                                    <p>
                                        <sup>3</sup>You must show your individual name on line 1, and enter
                                        your business or DBA name, if any, on line 2. You may use either
                                        your SSN or EIN (if you have one), but the IRS encourages you to use
                                        your SSN.
                                    </p>
                                    <p>
                                        <sup>4</sup>List first and circle the name of the trust, estate, or
                                        pension trust. (Do not furnish the TIN of the personal
                                        representative or trustee unless the legal entity itself is not
                                        designated in the account title.)
                                    </p>
                                    <p>
                                        <b>*Note:</b>
                                        The grantor must also provide a Form W-9 to the trustee of the
                                        trust.
                                    </p>
                                    <p>
                                        **For more information on optional filing methods for grantor
                                        trusts, see the Instructions for Form 1041.
                                    </p>
                                    <p>
                                        <b> Note: </b>
                                        If no name is circled when more than one name is listed, the number
                                        will be considered to be that of the first name listed.
                                    </p>
                                    <h4>Secure Your Tax Records From Identity Theft</h4>
                                    <p>
                                        Identity theft occurs when someone uses your personal information,
                                        such as your name, SSN, or other identifying information, without
                                        your permission to commit fraud or other crimes. An identity thief
                                        may use your SSN to get a job or may file a tax return using your
                                        SSN to receive a refund.
                                    </p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;To reduce your risk:</p>
                                    <p>• Protect your SSN,</p>
                                    <p>• Ensure your employer is protecting your SSN, and</p>
                                    <p>• Be careful when choosing a tax return preparer.</p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If your tax records are affected by identity
                                        theft and you receive a notice from the IRS, respond right away to
                                        the name and phone number printed on the IRS notice or letter.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If your tax records are not currently
                                        affected by identity theft but you think you are at risk due to a
                                        lost or stolen purse or wallet, questionable credit card activity,
                                        or a questionable credit report, contact the IRS Identity Theft
                                        Hotline at 800-908-4490 or submit Form 14039.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;For more information, see Pub. 5027,
                                        Identity Theft Information for Taxpayers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <h4>page 6</h4>
                            <div class="col-6">
                                <div>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Victims of identity theft who are
                                        experiencing economic harm or a systemic problem, or are seeking
                                        help in resolving tax problems that have not been resolved through
                                        normal channels, may be eligible for Taxpayer Advocate Service (TAS)
                                        assistance. You can reach TAS by calling the TAS toll-free case
                                        intake line at 877-777-4778 or TTY/TDD 800-829-4059.
                                    </p>
                                    <p>
                                        <b>Protect yourself from suspicious emails or phishing schemes. </b>
                                    </p>
                                    <p>
                                        Phishing is the creation and use of email and websites designed to
                                        mimic legitimate business emails and websites. The most common act
                                        is sending an email to a user falsely claiming to be an established
                                        legitimate enterprise in an attempt to scam the user into
                                        surrendering private information that will be used for identity
                                        theft.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;The IRS does not initiate contacts with
                                        taxpayers via emails. Also, the IRS does not request personal
                                        detailed information through email or ask taxpayers for the PIN
                                        numbers, passwords, or similar secret access information for their
                                        credit card, bank, or other financial accounts.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;If you receive an unsolicited email claiming
                                        to be from the IRS, forward this message to phishing@irs.gov. You
                                        may also report misuse of the IRS name, logo, or other IRS property
                                        to the Treasury Inspector General for Tax Administration (TIGTA) at
                                        800-366-4484. You can forward suspicious emails to the Federal Trade
                                        Commission at spam@uce.gov or report them at www.ftc.gov/complaint.
                                        You can contact the FTC at www.ftc.gov/idtheft or 877-IDTHEFT
                                        (877-438-4338). If you have been the victim of identity theft, see
                                        www.IdentityTheft.gov and Pub. 5027.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Go to www.irs.gov/IdentityTheft to learn
                                        more about identity theft and how to reduce your risk.
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div>
                                    <h4>Privacy Act Notice</h4>
                                    <p>
                                        Section 6109 of the Internal Revenue Code requires you to provide
                                        your correct TIN to persons (including federal agencies) who are
                                        required to file information returns with the IRS to report
                                        interest, dividends, or certain other income paid to you; mortgage
                                        interest you paid; the acquisition or abandonment of secured
                                        property; the cancellation of debt; or contributions you made to an
                                        IRA, Archer MSA, or HSA. The person collecting this form uses the
                                        information on the form to file information returns with the IRS,
                                        reporting the above information. Routine uses of this information
                                        include giving it to the Department of Justice for civil and
                                        criminal litigation and to cities, states, the District of Columbia,
                                        and U.S. commonwealths and territories for use in administering
                                        their laws. The information may also be disclosed to other countries
                                        under a treaty, to federal and state agencies to enforce civil and
                                        criminal laws, or to federal law enforcement and intelligence
                                        agencies to combat terrorism. You must provide your TIN whether or
                                        not you are required to file a tax return. Under section 3406,
                                        payors must generally withhold a percentage of taxable interest,
                                        dividends, and certain other payments to a payee who does not give a
                                        TIN to the payor. Certain penalties may also apply for providing
                                        false or fraudulent information.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
.border-color {
    border: 1px solid #677f8e;
}
</style>