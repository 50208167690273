<template>
  <div>
    <b-modal
      class="qr-success-modal"
      id="login-otp-modal"
      :hide-footer="true"
      :hide-header="true"
      @shown="onModalShown"
      no-close-on-backdrop
    >
      <div>
        <form @submit.prevent="otpSubmit">
          <div class="modal-container">
            <div class="modalHeading">
              <label style="font-size: 18px">
                Two-Factor Authentication 
                <!-- <span><img src="@/assets/svg/question.svg" role="button"></span> -->
              </label>
              <span @click="close">&times;</span>
            </div>
            <!-- further heading -->
            <div class="modalHeadingWithdraw">
              <label
                class="sub-h"
                v-if="loginResponse"
                style="text-align: center"
              >
                ระบุ OTP ที่ไดัรับจาก SMS ({{ $MyFunc.formatPhone(user.mobile) }})
                <br />
                
                Ref Code : {{ this.refCode }}
              </label>
            </div>
            <!-- OTP INPUT FIELD -->
            <div class="OTPInput">
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit1"
                autofocus
                ref="input1"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @keydown.enter="otpSubmit"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit2"
                autofocus
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @keydown.enter="otpSubmit"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit3"
                autofocus
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @keydown.enter="otpSubmit"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit4"
                autofocus
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @keydown.enter="otpSubmit"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit5"
                autofocus
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @keydown.enter="otpSubmit"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit6"
                autofocus
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @keydown.enter="otpSubmit"
              />
            </div>
            <!-- buttons -->
            <div class="flexCoinWithdraw">
              <button class="btn1" type="submit">ยืนยัน</button>
              <!-- <button class="btn2">ยกเลิกการฝากเงิน</button> -->
            </div>
            <div class="bottomLine text-center">
              <label>
                กรุณาตรวจสอบรหัส OTP บนโทรศัพท์มือถือของท่าน
                ภายใน 5 นาที หากท่านไม่ได้รับรหัส OTP
                <span @click="resendOtp" class="text-primary" role="button">กรุณาคลิกที่นี่ เพื่อขอรับรหัส OTP ใหม่อีกครั้ง</span>
                <template v-if="otp_delay > 0"> in {{ otp_delay / 1000 }} second</template><br>
                กรณีไม่สามารถยืนยัน OTP ได้ โทร. {{ CALLCENTER }}
              </label>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { HTTP } from "@/config/axios";
import EventBus from "@/event-bus";
export default {
  name: "OtpModal",
  components: {},
  props: ["loginResponse", "refCode", "pin", "user"],
  data() {
    return {
      otp_lock: true,
      otp_delay: 0,
      otp_delayTime: 120000,
      otp_code: {
        digit1: null,
        digit2: null,
        digit3: null,
        digit4: null,
        digit5: null,
        digit6: null,
      },
    };
  },
  directives: {
    focusOnBackspace: {
      inserted(el, binding) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "Backspace" && event.target.value === "") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnLeftArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowLeft") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnRightArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowRight") {
            event.preventDefault();
            const nextInput = el.nextElementSibling;
            if (nextInput) {
              nextInput.select();
            }
          }
        });
      },
    },
  },
  methods: {
    close() {
      this.$bvModal.hide("login-otp-modal");
      this.otp_code.digit1 = null;
      this.otp_code.digit2 = null;
      this.otp_code.digit3 = null;
      this.otp_code.digit4 = null;
      this.otp_code.digit5 = null;
      this.otp_code.digit6 = null;
      this.otp_delay = 0;
    },
    //...mapMutations(["setResMember"]), // map mutation from Vuex store
    next(e) {
      if (e.data == null) {
        e.target?.previousSibling?.select();
      } else {
        e.target?.nextSibling?.select();
      }
    },
    openModal() {
      EventBus.$emit("openModal");
    },
    openPasswordAlertModal() {
      EventBus.$emit("openPasswordAlertModal");
    },
    otpSubmit() {
      let self = this;
      let otp =
        this.otp_code.digit1 +
        this.otp_code.digit2 +
        this.otp_code.digit3 +
        this.otp_code.digit4 +
        this.otp_code.digit5 +
        this.otp_code.digit6;
      HTTP.post("/uc/validateOtp", {
        verificationCode: otp,
        refCode: this.refCode,
        platform: 1,
      })
        .then((res) => {
          if (res.data.code === 0) {
            HTTP.post("/uc/member/get-member-info")
              .then((res) => {
                let resMember = res.data.data.member;
                let reslockStatus = res.data.data.lockStatus;
                let combinedDataMemberStatus = { resMember: resMember, reslockStatus: reslockStatus };
                localStorage.setItem("resMember", JSON.stringify(combinedDataMemberStatus) || "{}");
                let kycReviewStatus = res.data.data.kycReviewStatus;
                let kycReviewReason = res.data.data.kycReviewReason;
                let requireKycReview = res.data.data.member.requireKycReview
                localStorage.setItem("kycReviewReason",JSON.stringify(kycReviewReason) || "{}");
                localStorage.setItem("kycReviewStatus",JSON.stringify(kycReviewStatus) || 500);
                localStorage.setItem("requireKycReview",JSON.stringify(requireKycReview) || "false");
                localStorage.setItem("kycReviewExpire",JSON.stringify(res.data.data.member.kycReviewExpire) || 500);
                //this.setResMember(resMember);
                this.openPasswordAlertModal();
                this.openModal();
                this.get_configmember()
              })
              .catch((err) => {
                console.log(err);
              });

            localStorage.setItem("user-data",JSON.stringify(this.loginResponse));
            if(this.pin){
              localStorage.setItem("user-pin", btoa(this.pin));
            }
            let user_data = JSON.parse( localStorage.getItem("user-data") || "{}" );
            if (user_data?.resetPasswordFlag) {
              if (self.$route.path !== "/update-password") {
                self.$router.push({ path: "/update-password" });
              }
            } else if (user_data?.resetPin) {
              if (self.$route.path !== "/update-pin") {
                self.$router.push({ path: "/update-pin" });
              }
            /* } else if (user_data?.requirePdpa) {
              if (self.$route.path !== "/policy") {
                self.$router.push({ path: "/policy" });
              } */
            } else if (!user_data?.knowledgeTest) {
              if (self.$route.path !== "/knowledge-test") {
                self.$router.push({ path: "/knowledge-test" });
              }
            } else {
              if (self.$route.path !== "/market/favorite") {
                self.$router.push({ path: "/market/favorite" });
              }
            }
          }else{
            self.$notify({
              group: "notification",
              type: res.data.code === 0 ? "success" : "error",
              title: res.data.code === 0 ? "Success" : "Otp validation Error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async get_configmember() {
      let self = this;
      HTTP.get("/uc/member/get-member-config")
        .then(function (response) {
          if (response.data.code == "0") {
            var Configs = response.data.data;
            Configs.forEach((item) => {
              if(item.configName == "ORDER_CONFIRM_BEFORE_SUBMIT"){
                if(item.configValue === "1"){
                  self.$store.state.ChkOrderConfirm = true;;
                  localStorage.setItem("ChkOrderConfirm", true);
                } else{
                  self.$store.state.ChkOrderConfirm = false;;
                  localStorage.setItem("ChkOrderConfirm", false);
                }
              }
              if (item.configName === "DONT_NOTIFY_AGAIN") {
                if (item.configValue === "1") {
                  localStorage.setItem("TPSLTut", true);
                  localStorage.setItem("SLTut", true);
                  localStorage.setItem("alertTut", true);
                  localStorage.setItem("dCoinTut", true);
                  localStorage.setItem("WithdrawCoinAlert", true);
                  localStorage.setItem("SLtrailing", true);
                  localStorage.setItem("automode-detail", true);
                  self.$store.state.member_noti_all = true;
                } else {
                  localStorage.setItem("TPSLTut", false);
                  localStorage.setItem("SLTut", false);
                  localStorage.setItem("alertTut", false);
                  localStorage.setItem("dCoinTut", false);
                  localStorage.setItem("WithdrawCoinAlert", false);
                  localStorage.setItem("SLtrailing", false);
                  localStorage.setItem("automode-detail", false);
                  self.$store.state.member_noti_all = false;
                }
              }
            });
        
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Get Member Configs Successful"
                  : "Unable to Get Current Member Config",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Config",
            text: error,
          });
        });
    },
    resendOtp() {
      if (!this.otp_lock) {
        /* this.otp_delay = this.otp_delayTime;
        this.otpDelay(); */
        let self = this;
        HTTP.post("/uc/resendOtp")
          .then((res) => {
            if (res.data.code == 0) {
              self.$emit("UpdateRefCode", res.data.data);
              self.otp_lock = true;
              self.onModalShown();
            }
            self.$notify({
              group: "notification",
              type: res.data.code == "0" ? "success" : "error",
              title:
                res.data.code == "0"
                  ? "OTP Send to Mobile Successful"
                  : "Unable to Send OTP to Mobile",
              text: res.data.message,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "ไม่สามารถส่งได้",
          text: "กรุณารอเป็นเวลา "+(this.otp_delay/1000)+" วินาที",
        });
      }
    },
    onModalShown() {
      this.otp_delay = this.otp_delayTime;
      this.otpDelay();
      this.$nextTick(() => {
        this.$refs.input1.focus();
      });
    },
    otpDelay(){
      this.otpTimeCount();
      setTimeout(()=>{ 
        this.otp_lock = false;
      },this.otp_delayTime);
    },
    otpTimeCount(){
      if(this.otp_delay > 0){
        setTimeout(()=>{ 
          this.otp_delay -= 1000;
          if(this.otp_delay > 0){
            this.otpTimeCount();
          }
        },1000);
      }
    }
  },
  created() {},
};
</script>

<style lang="scss">
#login-otp-modal___BV_modal_content_ {
  max-width: 424px;
  background: #141b1f;
  margin: auto;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
  margin-top: -100px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  font-family: "Sarabun" !important;
  border-radius: 8px 8px 8px 8px;
  margin: 0px auto;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0px 25px;
  line-height: 24px;
}

.modalHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.modalHeading > span {
  position: absolute;
  right: -13px;
  top: 23px;
  font-size: 30px;
  color: rgb(155, 172, 182);
  cursor: pointer;
}

.sub-h {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai";
}

.modalHeading label {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #d6dde1;
  margin-top: 20px;
  margin-bottom: 30px;
}

.modalHeadingWithdraw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OTPInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 16px;
  input {
    caret-color: #09BE8B;
  }
}

.flexCoinWithdraw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bottomLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
}

.bottomLine label {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
}

.flexCoinWithdraw .btn1 {
  background-color: #09BE8B;
  margin-top: 40px;
  padding: 0px 16px;
  color: #0a0d10;
  width: 344px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  border-style: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.OTPInput input {
  width: 44px;
  height: 56px;
  background-color: #222b2f;
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  font-size: 30px;
  border-style: none;
  outline: none;
}

.l-info {
  color: #9bacb6;
  font-size: 14px;
  text-align: center;
  display: block;
  line-height: 24px;
  margin-top: 32px;
  padding-bottom: 24px;
}
</style>
