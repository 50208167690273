import { render, staticRenderFns } from "./TrailingStopBuyConfirmation.vue?vue&type=template&id=518b465a&scoped=true"
import script from "./TrailingStopBuyConfirmation.vue?vue&type=script&lang=js"
export * from "./TrailingStopBuyConfirmation.vue?vue&type=script&lang=js"
import style0 from "./TrailingStopBuyConfirmation.vue?vue&type=style&index=0&id=518b465a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518b465a",
  null
  
)

export default component.exports