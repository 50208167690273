<template>
    <div>
        <b-modal   v-model="isShow" hide-footer >
  
            <template #modal-header="{ close } " >
                <span class="fs-5 fw-semibold font">{{ $t('notipassword') }}</span>
                <b-button variant="danger" @click="close()"><i class="fa fa-times"></i></b-button>
            </template>

            <div class="container font tex-white">

                <div class="row my-4">
                    <span class="text-white text-center">
                        <br>
                        {{ $t('notipasswordtext') }}  
                    </span>
                </div>
                <br>
                <div class="d-grid d-flex justify-content-around">
                   
                    <button type="button" class="btn btn-primary" @click="continueModal()">{{ $t('processed') }}</button>
                    <button type="button" class="btn b-g" @click="closeModal()">{{ $t('notprocessed') }}</button>
                </div>
                
            </div>
        


        </b-modal>
    </div>
</template>
  
<script>
import EventBus from '@/event-bus';
export default {
    data() {
        return {
            isShow: false,
        };
    },
    mounted() {
        setTimeout(() => {
            EventBus.$on('openPasswordAlertModal', this.openPasswordAlertModal);
        this.openPasswordAlertModal() 
        }, 500);
       
    },
    methods: {
        openPasswordAlertModal() {
            let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
            if(user_data?.passwordExpire === true){
                this.isShow = true;
            }
        },
        continueModal() {
            this.$router.push({
                name: 'PinPassword',
            })
        },
        closeModal() {
            this.isShow = false;
        },
    }
};
</script>
  
<style lang="scss" scoped >

.font {
    font-family: "Sarabun";
}
.text-indent {
    text-indent: 2em;
}
button.b-g {
    background-color: #191b1c;
    color: #ffffff;
}
</style>