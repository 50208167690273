<template>
  <div class="wrapper">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h1 class="page-heading">แจ้งขอปิดบัญชี</h1>
          </div>
          <div v-if="isLoading"> <div class="loader-wrapper"> <div class="loader"></div> </div> </div>
          <div v-else>
            <div class="row m-0">
            <div class="col-4 p-0">
              <div class="container" style="margin-left: 24px">
                <div class="row">
                  <div class="col p-2">ชื่อบัญชี</div>
                  <div class="col p-2">{{ userinfo.name }}</div>
                </div>
                <div class="row" style="border-bottom: 1px solid #677f8e">
                  <div class="col p-2">เลขที่บัญชี</div>
                  <div class="col p-2">{{ userinfo.accountNumber }}</div>
                </div>
                <div v-if="checkError" class="row">
                  <div class="col p-3 text-center">
                    <button type="button" style="margin-right: 16px; height: 36px; width: 140px"
                      class="btn btn-primary btn-text-sarabun" @click="closeAccount">
                      ขอปิดบัญชี
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        
        </div>
      </div>
    </div>

    <!--- alert -->
    <div v-if="ModalAlertClose">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" style="background-color: #0a0d10">
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="border-radius: 8px; margin-top: 0 !important">
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0" style="padding-top: 24px; padding-bottom: 24px">
                      <div class="p-0">
                        <div class="d-flex justify-content-between position-relative">
                          <h5 class="modal-title m-auto text-center">
                            แจ้งขอปิดบัญชี
                          </h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            ">
                            <span aria-hidden="true" @click="ModalAlertClose = false"
                              style="color: #677f8e; font-size: 30px">&times;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div class="container" style="padding: 20px; padding-top: 0">
                    <div class="row">
                      <div class="col-12 text-center">
                        <p class="text-center" style="color: #677f8e">
                          รายการสินทรัพย์คงเหลือที่คุณต้องทำการถอนก่อนปิดบัญชี
                        </p>
                      </div>
                      <div v-for="(item, index) in wallet" :key="index" class="row m-0">
                        <div class="col-1"></div>
                        <div class="col-5 list-line">
                          {{ item.unit }}<span style="color: #677f8e"> {{ item.fullCoinName }}</span>
                        </div>
                        <div class="col-5 list-line text-right">
                          {{ $MyFunc.NumberFormat(item.actual, item.coinScale?item.coinScale:2) }}
                        </div>
                      </div>
                    </div>
                    <!-- modal button bottom -->
                    <div class="row mt-4">
                      <div class="col-12 text-center">
                        <button type="button" class="btn btn-cancel btn-text-sarabun" style="
                            width: 160px;
                            height: 40px;
                            background-color: #212529;
                            color: #ddd;
                          " @click="ModalAlertClose = false">
                          ปิด
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!--- alert -->
    <div v-if="ModalConfirmClose">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" style="background-color: #0a0d10">
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="border-radius: 8px; margin-top: 0 !important">
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0" style="padding-top: 24px; padding-bottom: 24px">
                      <div class="p-0">
                        <div class="checkTick">
                          <img class="image-tick-modal" src="../../assets/images/profile-setting/tick.png" />
                        </div>
                        <div class="d-flex justify-content-between position-relative">
                          <h5 class="modal-title m-auto text-center">
                            ยืนยันคำขอปิดบัญชี
                          </h5>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div class="container" style="padding: 20px; padding-top: 0">

                    <div class="row">
                      <div class="col-2"></div>
                      <div class="col">
                        <div class="row">
                          <div class="col p-2">ชื่อบัญชี</div>
                          <div class="col p-2">{{ userinfo.name }}</div>
                        </div>
                        <div class="row">
                          <div class="col p-2">เลขที่บัญชี</div>
                          <div class="col p-2">{{ userinfo.accountNumber }}</div>
                        </div>
                        <div class="row">
                          <div class="col p-2">
                            <label>ระบุเหตุผล</label>
                            <input type="text" v-model="inputReason" @input="checkInputReason" class="form-control" placeholder="ระบุเหตุผล" />
                          </div>
                        </div>
                      </div>
                      <div class="col-2"></div>
                    </div>

                    <!-- modal button bottom -->
                    <div class="row mt-4">
                      <div class="col-6 text-center">
                        <button :disabled="inputReasonStatus" @click="submitConfirmClose" type="button" class="btn btn-primary btn-text-sarabun" style="width: 160px; height: 40px">
                          <span v-if="ModalAlertCloseLoading" style="color: black">กำลังส่งคำขอ ...</span>
                          <span v-else style="color: black">ยืนยัน</span>
                        </button>
                      </div>
                      <div class="col-6 text-center">
                        <button type="button" class="btn btn-cancel btn-text-sarabun" style="
                            width: 160px;
                            height: 40px;
                            background-color: #212529;
                            color: #ddd;
                          " @click="ModalConfirmClose = false; inputReason = ''">
                          ปิด
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <OtpModalSettings 
      :OtpResponse="OtpResponse"
      :OtpValue="OtpValue"
      :OtpError="OtpError"
      :OtpSuccess="OtpSuccess"
      :refCode="refCode"
      @OtpResponseUpdate="handleOtpResponseUpdate" 
      @UpdateRefCode="UpdateRefCode"
    />

  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import OtpModalSettings from "@/pages/ProfileSetting/components/OtpModalSettings.vue";
export default {
  components: { OtpModalSettings },
  name: "CloseAccount",
  data() {
    return {
      isLoading:true,
      userinfo: {
        name: "ไม่มีข้อมูล",
        accountNumber: "ไม่มีข้อมูล",
      },
      wallet: [],
      checkError: false,
      ModalAlertClose: false,
      ModalAlertCloseLoading: false,
      ModalConfirmClose: false,
      inputReason: "",
      inputReasonStatus: true,
      refCode: "",
      OtpValue: "",
      OtpResponse: false,
      OtpError: false,
      OtpSuccess: false,
    };
  },
  methods: {
    checkCloseAccount(){
      HTTP.get("/uc/member/check-close-account")
        .then((res) => {
          if(res.data.code === 1 || res.data.code === 0){
            this.checkError = true;
            this.wallet = res.data.data;
          }else if(res.data.code === 3){
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
    sendOtp() {
      let self = this;
      self.ModalAlertCloseLoading = true;
      HTTP.post("/uc/sendOtp")
        .then((res) => {
          if (res.data.code === 0) {
            self.OtpValue = "";
            self.OtpResponse = true;
            self.refCode = res.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        }).finally(() => {
          self.ModalAlertCloseLoading = false;
        })
        .catch((error) => {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
    verifyCloseAccount(){
      let self = this;
      let inputReason = self.inputReason.trim();
      HTTP.post("uc/member/verify-close-account", {
        reason: inputReason
      })
        .then((res) => {
          self.OtpResponse = false;
          if(res.data.code === 500){
            self.OtpSuccess = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }else{
            self.OtpSuccess = true;
            self.ModalConfirmClose = false;
            this.$store.dispatch("logout");
          }
        })
        .catch((error) => {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
    handleOtpResponseUpdate(Value) {
      this.checkInputReason();
      if (Value == "OtpSuccess") {
        this.verifyCloseAccount();
      } else if (Value == "OtpError") {
        this.OtpResponse = false;
        this.OtpError = true;
      } else if (Value == "OTP") {
        this.OtpResponse = false;
      } else if (Value == "OTPSuccess") {
        this.$store.dispatch("logout");
        this.OtpSuccess = false;
      } else if (Value == "OTPError") {
        this.OtpError = false;
      }
    },
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    closeAccount() {
      if (this.wallet.length > 0) {
        this.ModalAlertClose = true;
      } else {
        this.ModalConfirmClose = true;
      }
    },
    submitConfirmClose() {
      if(!this.ModalAlertCloseLoading){
        this.sendOtp();
      }
    },
    checkInputReason() {
      if (this.inputReason.trim() === '') {
        this.inputReasonStatus = true;
      }else{
        this.inputReasonStatus = false;
      }
    },
  },
  created() {
    this.checkCloseAccount();
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user-data"));
    if(user){
      this.userinfo.name = user.realName;
      this.userinfo.accountNumber = user.username;
    }
  },
};
</script>

<style scoped>
body,
div {
  font-family: "Noto Sans Thai" !important;
}

.Heading {
  padding: 24px 0px 55px 48px;
}

.page-heading {
  font-family: "Noto Sans Thai" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
}

.list-line {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #212529;
}

.checkTick {
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;
}

.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
</style>
