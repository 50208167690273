<template>
  <div>
    <b-container>
      <div class="borderd mt-5 mb-5">
        <b-row>
          <b-col cols="2">
            <b-card-body>
              <b-card-text>
                Form
                <span class="size-large">W-8BEN</span>
                <div class="text-color size mt-2">
                  (Rev. October 2021)
                </div>
                <div class="text-color size mt-2">
                  Department of the Treasury Internal Revenue Service
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>

          <b-col cols="8" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div class="size-large text-center">
                  Certificate of Foeign Status of Beneficial Owner for United
                </div>
                <div class="size-large text-center">
                  States Tax Withholding and Reporting (individuals)
                </div>
                <div class="weight mt-2" style="padding-left: 130px;">
                  <b-icon icon="play-fill" font-scale="2"></b-icon>
                  <span class="text-color">For use by individuals.Entities must use Form W-8BEN-E.</span>
                </div>
                <div class="weight mt-2" style="padding-left: 130px;">
                  <b-icon icon="play-fill" font-scale="2"></b-icon>
                  <span class="text-color">Go 10 www.irs.gov/Form W8BEN-E for instructions and the latest
                    information.</span>
                </div>
                <div class="weight mt-2 mb-2" style="padding-left: 130px;">
                  <b-icon icon="play-fill" font-scale="2"></b-icon>
                  <span class="text-color">Give this form to the withholding agent or payer.Do not send to the IRS.</span>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>

          <b-col cols="2" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div class="weight text-center" style="margin-top: 75px;">
                  OMB No. 1545-1621
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>

        <div class="borderd-bottom"></div>

        <b-card-body>
          <b-card-text>
            <div class="mt-3">
              Do Not Use This Form If:
              <span style="float: right;">Instead, Use Form:</span>
            </div>
          </b-card-text>
        </b-card-body>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align"> You are NOT an individual - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - -
            <span> W-8BENE </span>
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">You are a U.S. citizen or other U.S. person, including a resident alien individual - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - -
            <span> W-9 </span>
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">You are a beneficial owner claiming that income is effectively connected with the
            conduct of trade or business
            <br>
            within the United States (other than personal services) - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            <span> W-8ECI </span>
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">You are a beneficial owner who is receiving compensation for personal services performed
            in the United States
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            <span> 8233 or W-4 </span>
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">You are a person acting as an intermediary
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            <span> W-8IMY </span>
          </div>
        </div>

        <div class="borderd-bottom mt-4"></div>

        <b-card-body>
          <b-card-text>
            <div class="mt-2">
              <span style="font-size: 17px;font-weight:500">Note:</span>
              <span style="font-weight: 200;">
                If you are resident in a FATCA partner jurisdiction (i.e., a Model 1 IGA jurisdiction with reciprocity),
                certain tax account information may be provided to your jurisdiction of residence.
              </span>
            </div>
          </b-card-text>
        </b-card-body>

        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="6">
            <b-col cols="5">
              <div style="background-color: black;" class="p-1">
                PARTI
              </div>
            </b-col>

          </b-col>
          <b-col cols="6">
            <div class="p-1">Indentification of Beneficial Owner (see Instructions)</div>
          </b-col>
        </b-row>
        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="8">
            <b-card-body>
              <b-card-text>
                <div>
                  1 <span class="font-size">Name of individual who is the beneficial owner</span>
                </div>
                <input v-model="fatcaForm.signerName" placeholder="" class="input-width" :class="{ 'bg-input-validate' : formError.signerName }"
                  @input="updateFormData('signerName')" :disabled="fatcaForm.signerName">
                <p v-if="formError.signerName" class="text-danger mb-0">
                  {{ formError.signerName?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col cols="4" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div>
                  2 <span class="font-size">Country of citizenship.</span>
                </div>
                <input v-model="fatcaForm.citizenCountry" placeholder="" @input="updateFormData('citizenCountry')" :class="{ 'bg-input-validate' : formError.citizenCountry }"
                  class="input-width">
                <p v-if="formError.citizenCountry" class="text-danger mb-0">
                  {{ formError.citizenCountry?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <div class="borderd-bottom"></div>
        <b-card-body>
          <b-card-text>
            <div>
              3 <span class="font-size">Permanent residence address (street, apt. or suite no., or rural route). Do not
                use a P.O.box or in-care-of address.</span>
            </div>
            <input v-model="fatcaForm.address" placeholder="" class="input-width" @input="updateFormData('address')" :class="{ 'bg-input-validate' : formError.address }">
            <p v-if="formError.address" class="text-danger mb-0">
              {{ formError.address?.message }}
            </p>
          </b-card-text>
        </b-card-body>
        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="8">
            <b-card-body>
              <b-card-text>
                <div class="font-size">
                  City or town, state or province. Include postal code where appropriate.
                </div>
                <input v-model="fatcaForm.city" placeholder="" class="input-width" @input="updateFormData('city')" :class="{ 'bg-input-validate' : formError.city }">
                <p v-if="formError.city" class="text-danger mb-0">
                  {{ formError.city?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col cols="4" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div class="font-size">
                  Country:
                </div>
                <input v-model="fatcaForm.country" placeholder="" class="input-width" @input="updateFormData('country')" :class="{ 'bg-input-validate' : formError.country }">
                <p v-if="formError.country" class="text-danger mb-0">
                  {{ formError.country?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <div class="borderd-bottom"></div>
        <b-card-body>
          <b-card-text>
            <div>
              4 <span class="font-size">Mailing address (if different from above)</span>
            </div>
            <input v-model="fatcaForm.mailAddress" placeholder="" class="input-width" @input="updateFormData('mailAddress')" :class="{ 'bg-input-validate' : formError.mailAddress }">
            <p v-if="formError.mailAddress" class="text-danger mb-0">
              {{ formError.mailAddress?.message }}
            </p>
          </b-card-text>
        </b-card-body>
        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="8">
            <b-card-body>
              <b-card-text>
                <div class="font-size">
                  City or town, state or province. Include postal code where appropriate.
                </div>
                <input v-model="fatcaForm.mailCity" placeholder="" class="input-width" @input="updateFormData('mailCity')" :class="{ 'bg-input-validate' : formError.mailCity }">
                <p v-if="formError.mailCity" class="text-danger mb-0">
                  {{ formError.mailCity?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col cols="4" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div class="font-size">
                  Country:
                </div>
                <input v-model="fatcaForm.mailCountry" placeholder="" class="input-width" @input="updateFormData('mailCountry')" :class="{ 'bg-input-validate' : formError.mailCountry }">
                <p v-if="formError.mailCountry" class="text-danger mb-0">
                  {{ formError.mailCountry?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <div class="borderd-bottom"></div>
        <b-card-body>
          <b-card-text>
            <div>
              5 <span class="font-size">U.S. taxpayer identification number (SSN or ITIN), if required (see
                instructions)</span>
            </div>
            <input v-model="fatcaForm.taxId" placeholder="" class="input-width" @input="updateFormData('taxId')" :class="{ 'bg-input-validate' : formError.taxId }">
            <p v-if="formError.taxId" class="text-danger mb-0">
              {{ formError.taxId?.message }}
            </p>
          </b-card-text>
        </b-card-body>

        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="6">
            <b-card-body>
              <b-card-text>
                <div>
                  6a <span class="font-size">Foreign tax identifying number (see instructions)</span>
                </div>
                <input v-model="fatcaForm.foreignTaxId" placeholder="" class="input-width" @input="updateFormData('foreignTaxId')" :class="{ 'bg-input-validate' : formError.foreignTaxId }">
                <p v-if="formError.foreignTaxId" class="text-danger mb-0">
                  {{ formError.foreignTaxId?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col cols="6" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div>
                  6b <span class="font-size">Check if FTIN not legally required</span>
                  <label>
                    <!-- <p>Other(see instructions)</p> -->
                    <input v-model="fatcaForm.checkFtin" type="checkbox" class="form-check-input space-left"
                      value="false" />
                  </label>
                </div>

              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="6">
            <b-card-body>
              <b-card-text>
                <div>
                  7 <span class="font-size">Reference number(s) (see instructions)</span>
                </div>
                <input v-model="fatcaForm.refNo" placeholder="" class="input-width" @input="updateFormData('refNo')" :class="{ 'bg-input-validate' : formError.refNo }">
                <p v-if="formError.refNo" class="text-danger mb-0">
                  {{ formError.refNo?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col cols="6" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div>
                  8 <span class="font-size">Date of birth (MM-DD-YYYY) (see instructions)</span>
                </div>
                <input v-model="fatcaForm.birthDate" placeholder="" style="color: white;" disabled :class="{ 'bg-input-validate' : formError.birthDate }">
                <p v-if="formError.birthDate" class="text-danger mb-0">
                  {{ formError.birthDate?.message }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>

        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="6">
            <b-col cols="5">
              <div style="background-color: black;" class="p-1">
                PART II
              </div>
            </b-col>

          </b-col>
          <b-col cols="6">
            <div class="p-1">
              Claim of Tax Treaty Benefits (for chapter 3 purposes only) (see Instructions)
            </div>
          </b-col>
        </b-row>
        <div class="borderd-bottom mb-3"></div>
        <b-card-body>
          <b-card-text>
            <div class="d-flex flex-wrap">
              9&nbsp;<span class="font-size">
                I certify that the beneficial owner is a resident of
              </span>
              <div class="d-flex flex-column flex-grow-1">
                <input v-model="fatcaForm.resident" type="text" placeholder="" class="w-100" :class="{ 'bg-input-validate' : formError.resident }"
                  @input="updateFormData('resident')">
                <p v-if="formError.resident" class="text-danger mb-0">
                  {{ formError.resident?.message }}
                </p>
              </div>
              <span class="font-size">within the meaning of the income tax treaty between the United States and that
                country.</span>
            </div>
          </b-card-text>
        </b-card-body>

        <b-card-body>
          <b-card-text>
            <div>
              10 Special rates and conditions <span class="font-size">
                (if applicable _ see instructions): The beneficial owner is claiming the provisions of Article
                and paragraph
              </span><br>
              <input v-model="fatcaForm.incomeTax" type="text" placeholder="" @input="updateFormData('incomeTax')" :class="{ 'bg-input-validate' : formError.incomeTax }">
              <p v-if="formError.incomeTax" class="text-danger mb-0">
                {{ formError.incomeTax?.message }}
              </p>
              <span class="font-size">
                of the treaty identified on line 9 above to claim a
              </span>
              <input v-model="fatcaForm.treaty" type="text" placeholder="" v-maska data-maska="0.0" :class="{ 'bg-input-validate' : formError.treaty }"
                data-maska-tokens="0:\d:multiple" @input="updateFormData('treaty')"> %
              <p v-if="formError.treaty" class="text-danger mb-0">
                {{ formError.treaty?.message }}
              </p>
              <span class="font-size">
                rate of withholding on (specify type of income):
              </span>
              <input type="text" placeholder="" v-model="fatcaForm.rateHold" @input="updateFormData('rateHold')" :class="{ 'bg-input-validate' : formError.rateHold }">
              <p v-if="formError.rateHold" class="text-danger mb-0">
                {{ formError.rateHold?.message }}
              </p>
            </div>
            <div class="mt-4 font-size">
              Explain the additional conditions in the Article and paragraph the beneficial owner meets to be
              eligible for the rate of withholding:
            </div>
            <input v-model="fatcaForm.explain" type="text" style="width: 100%;" @input="updateFormData('explain')" :class="{ 'bg-input-validate' : formError.explain }">
            <p v-if="formError.explain" class="text-danger mb-0">
              {{ formError.explain?.message }}
            </p>
          </b-card-text>
        </b-card-body>

        <div class="borderd-bottom mt-1"></div>
        <b-row>
          <b-col cols="6">
            <b-col cols="5">
              <div style="background-color: black;" class="p-1">
                PART III
              </div>
            </b-col>

          </b-col>
          <b-col cols="6">
            <div class="p-1">
              Certification
            </div>
          </b-col>
        </b-row>
        <div class="borderd-bottom mb-3"></div>

        <b-card-body>
          <b-card-text>
            <div>
              Under penalties of perjury, I declare that I have examined the information on this form and to
              the best of my knowledge and belief it is true, correct, and complete. I further certify under
              penalties of perjury that:
            </div>
          </b-card-text>
        </b-card-body>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">
            I am the individual that is the beneficial owner (or am authorized to sign for the
            individual that is
            the beneficial owner)
            of all the income to which this form relates or am
            using this form to document myself for chapter 4
            purposes;
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">
            The person named on line 1 of this form is not a U.S. person;
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">
            This form relates to: <br>
            (a) income not effectively connected with the conduct of a trade or business in the United States;<br>
            (b) income effectively connected with the conduct of a trade or business in the United States but is not subject to tax under an applicable income tax treaty;<br>
            (c) the partner’s share of a partnership’s effectively connected taxable income; or<br>
            (d) the partner’s amount realized from the transfer of a partnership interest subject to withholding under section 1446(f); 
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">
            The person named on line 1 of this form is resident of the treaty country listed on line 9
            of the form (if any) within the meaning of the income tax treaty between the United States
            and that country; and
          </div>
        </div>
        <div style="margin-left: 5px;">
          <b-icon icon="dot" font-scale="2"></b-icon>
          <div class="text-align">
            For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person
            as defined in the instructions.
          </div>
        </div>
        <b-card-body>
          <b-card-text>
            <div class="terms">
              Furthermore, I authorize this form to be provided to any withholding agent that has control,
              receipt, or custody of the income of which I am the beneficial owner or any withholding agent
              that can disburse or make payments of the income of which I am the beneficial owner.
              <span style="font-size: 16px;font-weight: 400;">
                I agree that I will submit a new form within 30 days if any certification made on this form
                becomes incorrect.
              </span>
            </div>
          </b-card-text>
        </b-card-body>
        <b-card-body>
          <b-card-text>
            <div class="form-group form-check">
              <label>
                <input v-model="fatcaForm.acceptAgreement" type="checkbox" class="form-check-input" value="false"
                  @click="updateFormData('acceptAgreement')" />
                <p class="left mt-1" style="font-size: 15px;font-weight: 200;">
                  I certify that I have the capacity to sign for the person identified on line 1
                  of this form.
                </p>
                <p v-if="formError.acceptAgreement" class="text-danger mb-0">
                  {{ formError.acceptAgreement?.message }}
                </p>
              </label>
            </div>
            <div style="font-size: 20px;font-weight: 500;" class="d-flex">
              <div class="d-flex text-nowrap">
                Sign Here&nbsp;
                <b-icon icon="caret-right-fill"></b-icon>
              </div>
              <div class="d-flex flex-grow-1 row">
                <div class="d-flex flex-column col-8">
                  <input v-model="fatcaForm.signature" type="text" class="w-100" @input="updateFormData('signature')" :class="{ 'bg-input-validate' : formError.signature }">
                  <small v-if="formError.signature" class="text-danger mb-0" style="font-size: 15px;font-weight: 200;">
                    {{ formError.signature?.message }}
                  </small>
                  <span class="text-center" style="font-size: 15px;font-weight: 200">Signature of beneficial owner (or
                    individual authorized to sign for beneficial owner)</span>
                </div>
                <div class="d-flex flex-column col-4">
                  <input v-model="fatcaForm.signDate" class="w-100" type="text" disabled :class="{ 'bg-input-validate' : formError.signDate }">
                  <small v-if="formError.signDate" class="text-danger mb-0" style="font-size: 15px;font-weight: 200;">
                    {{ formError.signDate?.message }}
                  </small>
                  <span class="text-center" style="font-size: 15px;font-weight: 200">Date(MM-DD-YYYY)</span>
                </div>
                <div class="d-flex flex-column col-8">
                  <input v-model="fatcaForm.signerName" type="text" class="w-100" @input="updateFormData('signerName')">
                  <div style="font-size: 15px;font-weight: 200;">
                    Print Name of signer
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card-body>
        <div class="borderd-bottom mb-1 mt-1"></div>
        <b-card-body class="mb-3">
          <b-card-text>
            <div style="display: flex;justify-content: space-between;">
              <div>
                For Paperwork Reduction Act Notice, see separate instructions.
              </div>
              <div style="font-size: 14px;font-weight: 200;">
                Cat. No. 25047Z
              </div>
              <div style="font-size: 14px;font-weight: 200;">
                Form
                <span style="font-size: 13px;font-weight: 500;">
                  W-8BEN
                </span> (Rev. 10-2021)
              </div>
            </div>
          </b-card-text>
          <div class="mx-3 thai-font justify-center text-center">
            <button @click="submitForm" class="btn-yellow">
              ส่ง
            </button>
            <button @click="backToReviewKYC()" class="btn-blue">
              ยกเลิก
            </button>
          </div>
        </b-card-body>
      </div>
    </b-container>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import * as yup from 'yup';
import { getValidationErrors } from '../../assets/js/util'
import moment from 'moment'

const schema = yup.object().shape({
  citizenCountry: yup.string().nullable().required("This is a required field."),
  address: yup.string().nullable().required("This is a required field."),
  city: yup.string().nullable().required("This is a required field."),
  country: yup.string().nullable().required("This is a required field."),
  mailAddress: yup.string().nullable(),
  mailCity: yup.string().nullable(),
  mailCountry: yup.string().nullable(),
  taxId: yup.string().nullable().required("This is a required field."),
  foreignTaxId: yup.string().nullable(),
  checkFtin: yup.string().nullable(),
  refNo: yup.string().nullable(),
  birthDate: yup.string().nullable().matches(/^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/, { message: "Please enter date as MM-DD-YYYY" }),
  resident: yup.string().nullable(),
  incomeTax: yup.string().nullable(),
  treaty: yup.string().nullable(),
  rateHold: yup.string().nullable(),
  explain: yup.string().nullable(),
  acceptAgreement: yup.boolean().nullable().oneOf([true], "Please accept agreement"),
  signature: yup.string().nullable(),
  signerName: yup.string().nullable().required("This is a required field."),
  signDate: yup.string().nullable().required("This is a required field.").matches(/^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/, { message: "Please enter date as MM-DD-YYYY" }),
})

const initFrom = {
  citizenCountry: null,
  address: null,
  city: null,
  city: null,
  country: null,
  mailAddress: null,
  mailCity: null,
  mailCountry: null,
  taxId: null,
  foreignTaxId: null,
  checkFtin: false,
  refNo: null,
  birthDate: null,
  resident: null,
  incomeTax: null,
  treaty: null,
  rateHold: null,
  explain: null,
  acceptAgreement: false,
  signature: null,
  signerName: null,
  signDate: moment().format("MM-DD-YYYY"),
}

export default {
  data() {
    return {
      sole: true,
      s_corporation: true,
      other: false,
      fatcaForm: { ...initFrom },
      fatcaForm8Data: {},
      triggerForm: false,
      choiceSelect:null,
    }
  },
  created() {
    const formData = JSON.parse(localStorage.getItem("kycReviewData"));
    if (formData) {
      this.fatcaForm.signerName = this.showTitleName(formData.titleId) + formData.nameEN;
      if (formData.birthDay) this.fatcaForm.birthDate = moment(formData.birthDay).format("MM-DD-YYYY");
    }
    this.getOldFatca();
    this.KYCflag();
  },
  computed: {
    formError() {
      if (!this.triggerForm)
        return {}

      const errors = getValidationErrors(schema, this.fatcaForm);
      return errors;
    },
  },
  methods: {
    KYCflag() {
      localStorage.setItem("fatcaSet", true)
    },
    showTitleName(titleId){
      let titleNameEn = "";
      if(titleId == 1){
        titleNameEn = "MR.";
      }else if(titleId == 2){
        titleNameEn = "MRS.";
      }else if(titleId == 3){
        titleNameEn = "MISS";
      }
      return titleNameEn;
    },
    getOldFatca() {
      const fatcaData = localStorage.getItem("fatcaData");
      const formData = JSON.parse(fatcaData);
      this.choiceSelect = formData
      this.fatcaForm.citizenCountry = formData.citizenCountry_w8;
      this.fatcaForm.country = formData.country_w8;
      this.fatcaForm.city = formData.city_w8;
      this.fatcaForm.address = formData.address_w8;
      this.fatcaForm.mailAddress = formData.mailAddress_w8;
      this.fatcaForm.mailCity = formData.mailCity_w8;
      this.fatcaForm.mailCountry = formData.mailCountry_w8;
      this.fatcaForm.taxId = formData.taxId_w8;
      this.fatcaForm.foreignTaxId = formData.foreignTaxId_w8;
      this.fatcaForm.checkFtin = formData.checkFtin_w8;
      this.fatcaForm.refNo = formData.refNo_w8;
      this.fatcaForm.resident = formData.resident_w8;
      this.fatcaForm.incomeTax = formData.incomeTax_w8;
      this.fatcaForm.treaty = formData.treaty_w8;
      this.fatcaForm.rateHold = formData.rateHold_w8;
      this.fatcaForm.explain = formData.explain_w8;
      this.fatcaForm.signature = formData.signature_w8;
      this.fatcaForm.acceptAgreement = formData.accept_agreement_w8;
    },

    updateFormData(data) {
      const regex = /^[a-zA-Z\s]*$/;
      const regexNum = /^[a-zA-Z0-9\s]*$/;
      const regexSpecial = /^[a-zA-Z0-9!@#$%^&*(),-_+\s]*$/;
      
      if (this.fatcaForm.signerName && data === 'signerName') {
        if (!regex.test(this.fatcaForm.signerName)) {
          this.fatcaForm.signerName = this.fatcaForm.signerName.replace(/[^a-zA-Z.\s]/g, '');
        }
      }else if (this.fatcaForm.citizenCountry && data === 'citizenCountry') {
        if (!regex.test(this.fatcaForm.citizenCountry)) {
          this.fatcaForm.citizenCountry = this.fatcaForm.citizenCountry.replace(/[^a-zA-Z\s]/g, '');
        }
      }else if (this.fatcaForm.address && data === 'address') {
        if (!regexSpecial.test(this.fatcaForm.address)) {
          this.fatcaForm.address = this.fatcaForm.address.replace(/[^a-zA-Z0-9!@#$%^&*(),-_+\s]/g, '');
        }
      }else if (this.fatcaForm.city && data === 'city') {
        if (!regexSpecial.test(this.fatcaForm.city)) {
          this.fatcaForm.city = this.fatcaForm.city.replace(/[^a-zA-Z0-9!@#$%^&*(),-_+\s]/g, '');
        }
      }else if (this.fatcaForm.country && data === 'country') {
        if (!regex.test(this.fatcaForm.country)) {
          this.fatcaForm.country = this.fatcaForm.country.replace(/[^a-zA-Z\s]/g, '');
        }
      }else if (this.fatcaForm.mailAddress && data === 'mailAddress') {
        if (!regexSpecial.test(this.fatcaForm.mailAddress)) {
          this.fatcaForm.mailAddress = this.fatcaForm.mailAddress.replace(/[^a-zA-Z0-9!@#$%^&*(),-_+\s]/g, '');
        }
      }else if (this.fatcaForm.mailCity && data === 'mailCity') {
        if (!regexSpecial.test(this.fatcaForm.mailCity)) {
          this.fatcaForm.mailCity = this.fatcaForm.mailCity.replace(/[^a-zA-Z0-9!@#$%^&*(),-_+\s]/g, '');
        }
      }else if (this.fatcaForm.mailCountry && data === 'mailCountry') {
        if (!regexNum.test(this.fatcaForm.mailCountry)) {
          this.fatcaForm.mailCountry = this.fatcaForm.mailCountry.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.taxId && data === 'taxId') {
        if (!regexNum.test(this.fatcaForm.taxId)) {
          this.fatcaForm.taxId = this.fatcaForm.taxId.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.foreignTaxId && data === 'foreignTaxId') {
        if (!regexNum.test(this.fatcaForm.foreignTaxId)) {
          this.fatcaForm.foreignTaxId = this.fatcaForm.foreignTaxId.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.refNo && data === 'refNo') {
        if (!regexNum.test(this.fatcaForm.refNo)) {
          this.fatcaForm.refNo = this.fatcaForm.refNo.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.resident && data === 'resident') {
        if (!regexNum.test(this.fatcaForm.resident)) {
          this.fatcaForm.resident = this.fatcaForm.resident.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.incomeTax && data === 'incomeTax') {
        if (!regexNum.test(this.fatcaForm.incomeTax)) {
          this.fatcaForm.incomeTax = this.fatcaForm.incomeTax.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.treaty && data === 'treaty') {
        if (!regexNum.test(this.fatcaForm.treaty)) {
          this.fatcaForm.treaty = this.fatcaForm.treaty.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.rateHold && data === 'rateHold') {
        if (!regexNum.test(this.fatcaForm.rateHold)) {
          this.fatcaForm.rateHold = this.fatcaForm.rateHold.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.explain && data === 'explain') {
        if (!regexNum.test(this.fatcaForm.explain)) {
          this.fatcaForm.explain = this.fatcaForm.explain.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }else if (this.fatcaForm.signature && data === 'signature') {
        if (!regexNum.test(this.fatcaForm.signature)) {
          this.fatcaForm.signature = this.fatcaForm.signature.replace(/[^a-zA-Z0-9\s]/g, '');
        }
      }
    },
    submitForm() {
      this.triggerForm = true;
      const errors = getValidationErrors(schema, this.fatcaForm);
      if (Object.keys(errors).length == 0) {
        HTTP.post("/uc/fatca/save?fatcaType=0", {
          isUSCitizen: this.choiceSelect.is_us_citizen? true:false,
          isUSAuthPerson: this.choiceSelect.is_us_auth_person? true:false,
          isUsGreenCard: this.choiceSelect.is_us_green_card? true:false,
          isUsResident: this.choiceSelect.is_us_resident? true:false,
          isUsRenounce: this.choiceSelect.is_us_renounce? true:false,
          isUsAddress: this.choiceSelect.is_us_address? true:false,
          isUsTel: this.choiceSelect.is_us_tel? true:false,    
          isUsBankAccount: this.choiceSelect.is_us_bank_account? true:false,
          signerName: this.fatcaForm.signerName,
          citizenCountry: this.fatcaForm.citizenCountry,
          country: this.fatcaForm.country,
          city: this.fatcaForm.city,
          address: this.fatcaForm.address,
          mailAddress: this.fatcaForm.mailAddress,
          mailCity: this.fatcaForm.mailCity,
          mailCountry: this.fatcaForm.mailCountry,
          taxId: this.fatcaForm.taxId,
          foreignTaxId: this.fatcaForm.foreignTaxId,
          checkFtin: this.fatcaForm.checkFtin,
          refNo: this.fatcaForm.refNo,
          birthDate: this.fatcaForm.birthDate,
          resident: this.fatcaForm.resident,
          incomeTax: this.fatcaForm.incomeTax,
          treaty: this.fatcaForm.treaty,
          rateHold: this.fatcaForm.rateHold,
          explain: this.fatcaForm.explain,
          signature: this.fatcaForm.signature,
          signDate: this.fatcaForm.signDate,
          acceptAgreement: this.fatcaForm.acceptAgreement
        }).then((res) => {
          const fatcaId = res.data.data.fatcaId;
          localStorage.setItem("fatcaId", res.data.data.fatcaId);
          this.$notify({
            group: "notification",
            type: "success",
            title: "Success",
            text: "Save Fatca W8BEN Successfully",
          });
          this.$router.push({
            name: "ReviewKYC",
            params: { fatcaId: fatcaId }
          });

        });
      }
      else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Fields Empty",
          text: 'Kindly fill all the required Fields',
        });
      }
    },
    backToReviewKYC() {
      this.$router.push({
        name: "ReviewKYC",
        params: { fatcaId: "NO" }
      });
    }
  }
}
</script>

<style scoped>
.terms {
  font-size: 15px;
  font-weight: 100
}

.font-size {
  font-size: 15px;
  font-weight: 200;
}

.text-align {
  margin-top: -24px;
  margin-left: 25px;
  font-weight: 200;
  font-size: 15px;
}

input[type=text] {
  width: 20%;
  /* padding: 12px 20px; */
  /* margin: 8px 0; */
  /* box-sizing: border-box; */
  border: none;
  border-bottom: 1px solid #677f8e;
  color: #677f8e;
}


.left {
  margin-left: 5px;
  font-size: 13px;
}

.space-left {
  margin-left: 10px;
}

.borderd-left {
  border-left: 1px solid #677f8e;
}

.borderd-bottom {
  border-bottom: 1px solid #677f8e;
}

.borderd {
  border: 1px solid #677f8e;
}

.size-large {
  font-size: 20px;
  font-weight: 600;
}

.text-large {
  font-size: 15px;
  font-weight: 600;
}

.text-color {
  color: #677f8e;
}

.size {
  font-size: 9px;
  font-weight: 600;
}

.weight {
  font-size: 11px;
  font-weight: 600;
}

.input-width {
  width: 100%;
  color: white;
}
.bg-input-validate{
  background-color: #191b1c !important;
}
</style>
