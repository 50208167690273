<template>
  <div>
    <h1 class="hp-title">Home Page</h1>
    <div>{{ Vtest }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Vtest: "",
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
<style>
.hp-title {
  text-align: center;
  margin-top: 50px;
}
</style>
