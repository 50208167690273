<template>
  <div class="wraper-main">
    <div class="categories-detail" v-show="!ShowBuySell" style="margin-top: 45px">
      <iframe src="https://cryptobubbles.net" width="100%" height="700" style="border: none" loading="lazy"></iframe>
    </div>
    <div class="buy-sell-detail" v-show="ShowBuySell">
      <div class="container-fluid">
        <div class="row" style="margin-top: 23px">
          <div class="col-9 col-md-8 col-lg-10" style="width: 750px; padding-right: 70px">
            <h2 class="heading-coin">{{topic_detail}}</h2>
            <p class="discription-coin thaiLang">
              {{detail}}
            </p>
          </div>
          <!-- <div class="vr"></div> -->
          <div class="col-3 col-md-4 col-lg-2 px-0" style="width: 220px">
            <div class="wrpaer-values">
              <div class="change">
                <p class="heading">%Change</p>
                <p class="green-value">{{perchange}}</p>
              </div>

              <div class="change">
                <p class="heading">MCap.</p>
                <p class="value">{{mcap_detail}}</p>
              </div>

              <div class="change">
                <p class="heading">Volume</p>
                <p class="value">{{volume_detail}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <Dynamic-Table :TableHeader="TableHeading" :favCoinRows="favCoinRows" :TableData="TableDatas2"   
          :Showfavorite="true" current_page="theme"></Dynamic-Table>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import Table from "../../components/table/Table.vue";
import EventBus from "../../event-bus";
export default {
  name: "themes",

  components: {
    "Dynamic-Table": Table,
  },
  data() {
    return {
      datail_theme: [],
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price",
          headingHourChange: "Change (24h)",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
          headingDescription: "Description",
          headingCategories: "Theme",
          headingChart: "Chart (24h)",
        },
      ],
      TableDatas: [],
      TableDatas2: [],
      ShowBuySell: false,
      favCoinRows: [],
      topic_detail: "",
      detail: "",
      perchange: "",
      mcap_detail: "",
      volume_detail: "",
      detail_theme: "",
    };
  },
  created() {
    this.get_theme()
  },
  methods: {
    async get_theme() {
      try {
        await HTTP.get("market/announcement/get-all-web-theme").then((response) => {
          this.TableDatas = []
          if (response.data.code == 0) {
            this.data = response.data.data
            let i = 0
            this.data.forEach(d => {
              let p = {
                StarIcon: require("../../assets/images/favoriteTable/star.png"),
                CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
                hourChange: d.themeChange,
                volume: d.themeValue,
                marketCap: d.themeMaket,
                Description: d.themeDetail,
                Categories: d.themeName,
                id: d.id,
                length: i
              }
              i++
              this.TableDatas.push(p)
            });
          }
        });
      } catch (error) {

      }
    },
    async get_theme_detail(value) {
      try {
        var length = value.length
        this.TableDatas2 = []
        var data_d = this.data[value.length]
        this.detail_theme = this.data[value.length].details
        if(this.detail_theme.length == 0){
          this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "ไม่พบรายละเอียดใน  " + data_d.themeName+ " นี้ กรุณาจัดการที่ Backoffice"
              });
          return false
        }else{
          this.ShowBuySell = value.child_msg;
        }
        this.topic_detail = data_d.themeName
        this.detail = data_d.themeDetail
        this.perchange = data_d.themeChange
        this.mcap_detail = data_d.themeMaket
        this.volume_detail = data_d.themeValue
        this.detail_theme.forEach(element => {
          let p = {
            StarIcon: require("../../assets/images/favoriteTable/star.png"),
            CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
            Coinsybmol: element.detailCoinname,
            CoinName: element.detailCoinname,
            lastPrice: element.detaillastprice === undefined ? 0 : element.detaillastprice,
            hourChange: element.detailChange,
            volume: element.detailValue,
            marketCap: element.detailMaket,
            series: [
              {
                name: element.detailCoinname,
                data: [50, 20, 30, 50, 10],
              },
            ],
          }
          this.TableDatas2.push(p)
        });
      } catch (error) {
        console.log(error)
      }
    },
    updateParent(value) {
      this.get_theme_detail(value);
      // this.ShowBuySell = value_from_child.child_msg;

      // alert("hello child" + value_from_child)
    },
  },
  mounted(){
    EventBus.$on("get-Symbol-Thumb-trend-theme", () => {
      this.get_theme();
    });
  }
};
</script>
<style lang="scss" scoped>
.thaiLang {
  font-family: "Sarabun" !important;
  line-height: 24px;
}

.textGrey {
  color: rgb(255, 255, 255);
}

.textGreyDark {
  color: #677f8e;
}

.heading-coin {
  color: #d6dde1;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  // margin-top: 3px;
  margin-left: 0.5rem;
  margin-bottom: 10px;
}

.discription-coin {
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 0.5rem;
}

.heading {
  color: #677f8e;
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;
  // margin-right: 3rem;
}

.green-value {
  color: #3fde68;
  font-size: 14px;
  font-weight: 400;
}

.value {
  color: #d6dde1;
  font-size: 14px;
  font-weight: 400;
}

.change {
  // justify-content: space-between;
  display: flex;
}

.wrpaer-values {
  border-left: 2px solid #28363e;
  // width: 28%;
  margin-top: 1rem;
  margin-left: 1rem;

  p {
    margin-bottom: 5px;
    padding-left: 32px;
  }
}

.vertical-line {
  border-left: 2px solid #28363e;
}
</style>
