import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/home/Home.vue";
import Login from "../pages/uc/login";
import UpdatePassword from "../pages/uc/updatepassword";
import UpdatePin from "../pages/uc/updatepin";
import Success from "../pages/uc/Success";
import Download from "../pages/uc/download";
import Exchange from "../pages/exchange/exchange.vue";
import OrderHistory from "../pages/exchange/OrderHistory.vue";
import News from "../pages/news/news";
import NewsDetail from "../pages/news/news-detail";
import Chart from "../pages/chart/chart";
import Notification from "../pages/cms/notification";
import CoinDetail from "../pages/CoinDetail/CoinDetail";
import MarketCenter from "../components/market/market-center";
import AllCoins from "../pages/market/all-coins";
import Favorite from "../pages/market/favorite";
import Categories from "../pages/market/categories";
import Themes from "../pages/market/themes";
import Heatmap from "../pages/market/heatmap";
import GainLoss from "../pages/market/gainloss";
import MarketUpdate from "../pages/market/marketupdate";
import MarketNews from "../pages/market/news";
import CategoryCoinList from "../pages/market/coin-list";
import ThemesCoinList from "../pages/market/coin-list";
import ScannerList from "../pages/scanner/scanner.vue";
import PreTest from "../pages/pre-test/PreTest.vue";
import PreTestPass from "../pages/pre-test/PreTestPass.vue";
import PreTestFail from "../pages/pre-test/PreTestFail.vue";
import KnowledgeTest from "../pages/pre-test/KnowledgeTest.vue";
import policyForm from "../pages/pre-test/policyForm.vue";
import SuitabilityGroup from "../pages/pre-test/SuitabilityGroup.vue";
import Fatca from "../pages/pre-test/Fatca.vue";
import FatcaForm from "../pages/pre-test/FatcaForm.vue";
import FatcaFormBen from "../pages/pre-test/FatcaFormBen.vue";
import MemberCenter from "../pages/uc/member-center";
import MyPortfolio from "../pages/portfolio/my-portfolio";
import AutoMode from "../pages/portfolio/AutoMode.vue";
import DWReport from "../pages/portfolio/DWReport.vue";
import History from "../pages/portfolio/history";
import ByDetail from "../pages/portfolio/History/ByDetail";
import ByDate from "../pages/portfolio/History/ByDate";
import ByCoin from "../pages/portfolio/History/ByCoin";
import DCash from "../pages/deposit-withdraw/deposit/Cash.vue";
import DCoin from "../pages/deposit-withdraw/deposit/Coin.vue";
import WCash from "../pages/deposit-withdraw/withdraw/Cash.vue";
import WCoin from "../pages/deposit-withdraw/withdraw/Coin.vue";
import CashMainAccountWithdraw from "../pages/deposit-withdraw/withdraw/CashMainAccount.vue";
import MainAccountWithdraw from "../pages/deposit-withdraw/withdraw/MainAccount.vue";
import ProfileSetting from "../pages/ProfileSetting/ProfileSetting";
import PersonalInformation from "../pages/ProfileSetting/PersonalInformation";
import BankAccountSuccessful from "../pages/ProfileSetting/BankAccountSuccessful";
import PinPassword from "../pages/ProfileSetting/PinPassword";
import ReviewKYC from "../pages/ProfileSetting/ReviewKYC";
import TouchFaceID from "../pages/ProfileSetting/TouchFaceID";
import RequestIncrease from "../pages/ProfileSetting/RequestIncrease";
import DetermineCost from "../pages/ProfileSetting/DetermineCost";
import ReceivingInformation from "../pages/ProfileSetting/ReceivingInformation";
import LoginHistory from "../pages/ProfileSetting/LoginHistory";
import SetNotifications from "../pages/ProfileSetting/SetNotifications";
import SetAlerts from "../pages/ProfileSetting/SetAlerts";
import AboutUs from "../pages/ProfileSetting/AboutUs";
import ContactUs from "../pages/ProfileSetting/ContactUs";
import CloseAccount from "../pages/ProfileSetting/CloseAccount.vue";
import { HTTP } from "./axios";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    //component: MarketCenter,
    name: "Home",
    redirect: (to) => {
      return { path: "/market/all-coins" };
    },
    // meta: {
    //   requireKnowledgeTest: true
    // },
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/update-password",
    component: UpdatePassword,
    name: "UpdatePassword",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update-pin",
    component: UpdatePin,
    name: "UpdatePin",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/success",
    component: Success,
    name: "Success",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/download",
    component: Download,
    name: "Download",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/hd-wallet",
    component: () => import("../pages/uc/HDWallet"),
    name: "HDWallet",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/test-api",
    component: () => import("../pages/uc/TestAPIs.vue"),
    name: "TestAPIs",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/exchange",
    component: Exchange,
    name: "exchange",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/exchange/:pair",
    component: Exchange,
    name: "exchangePair",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/order-history",
    component: OrderHistory,
    name: "order-history",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/news",
    component: News,
    name: "News",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/news/:news",
    component: NewsDetail,
    name: "NewsDetail",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/chart",
    component: Chart,
    name: "Chart",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/chart/:pair",
    component: Chart,
    name: "ChartPair",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/notification",
    component: Notification,
    name: "Notification",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/market",
    component: MarketCenter,
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
    children: [
      // {
      //   path: "/",
      //   component: AllCoins,
      //   name: "MarketAllCoin",
      // },
      {
        path: "all-coins",
        component: AllCoins,
        name: "MarketAllCoin",
      },
      {
        path: "coin-detail",
        redirect: (to) => {
          return { path: "/market/coin-detail/btc_thb" };
        },
      },
      {
        path: "coin-detail/:pair",
        component: CoinDetail,
        name: "CoinDetail",
      },
      {
        path: "favorite",
        component: Favorite,
        name: "MarketFavorite",
      },
      {
        path: "categories",
        component: Categories,
        name: "MarketCategories",
      },
      {
        path: "themes",
        component: Themes,
        name: "MarketThemes",
      },
      {
        path: "heatmap",
        component: Heatmap,
        name: "MarketHeatmap",
      },
      {
        path: "Gain/Loss",
        component: GainLoss,
        name: "Gainloss",
      },
      {
        path: "marketupdate",
        component: MarketUpdate,
        name: "marketupdate",
      },
      {
        path: "news",
        component: MarketNews,
        name: "news",
      },
      {
        path: "category-coin-list",
        component: CategoryCoinList,
        name: "MarketCategoryCoinList",
      },
      {
        path: "themes-coin-list",
        component: ThemesCoinList,
        name: "MarketThemesCoinList",
      },
    ],
  },
  {
    path: "/scan",
    component: ScannerList,
    name: "ScannerList",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/pre-test",
    component: PreTest,
    name: "PreTest",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/pre-test/pass",
    component: PreTestPass,
    name: "PreTestPass",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/pre-test/fail",
    component: PreTestFail,
    name: "PreTestFail",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/knowledge-test",
    component: KnowledgeTest,
    name: "KnowledgeTest",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  /* {
    path: "/policy",
    component: policyForm,
    name: "policyForm",
  }, */
  {
    path: "/suitability-group",
    component: SuitabilityGroup,
    name: "SuitabilityGroup",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/fatca",
    component: Fatca,
    name: "Fatca",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
      requireKycPending: true,
    },
  },
  {
    path: "/fatca/w9",
    component: FatcaForm,
    name: "FatcaForm",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
      requireKycPending: true,
    },
  },
  {
    path: "/fatca/w8",
    component: FatcaFormBen,
    name: "FatcaFormBen",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
      requireKycPending: true,
    },
  },
  {
    path: "/uc",
    component: MemberCenter,
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
    children: [
      {
        path: "my-portfolio",
        component: MyPortfolio,
        name: "MyPortfolio",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "auto-mode",
        component: AutoMode,
        name: "AutoMode",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "dw-report",
        redirect: (to) => {
          return { path: "/uc/dw-report/cash" };
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "dw-report/:page",
        component: DWReport,
        name: "DWReport",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "history",
        component: History,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            component: ByDetail,
            name: "ByDetail",
          },
          {
            path: "ByDate",
            component: ByDate,
            name: "ByDate",
          },
          {
            path: "ByCoin",
            component: ByCoin,
            name: "ByCoin",
          },
        ],
      },
    ],
  },
  {
    path: "/deposit/cash",
    component: DCash,
    name: "DepositeCash",
    meta: {
      requiresAuth: true,
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/deposit/coin",
    component: DCoin,
    name: "DepositeCoin",
    meta: {
      requiresAuth: true,
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/withdraw/cash",
    component: WCash,
    name: "WithdrawCash",
    meta: {
      requiresAuth: true,
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/withdraw/coin",
    component: WCoin,
    name: "WithdrawCoin",
    meta: {
      requiresAuth: true,
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/withdraw/cash_main_account",
    component: CashMainAccountWithdraw,
    name: "cash_main_account",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/withdraw/main_account",
    component: MainAccountWithdraw,
    name: "main_account",
    meta: {
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
  },
  {
    path: "/ProfileSetting",
    component: ProfileSetting,
    meta: {
      requiresAuth: true,
      //requirePolicy: true,
      requireKnowledgeTest: true,
    },
    children: [
      {
        path: "",
        component: PersonalInformation,
        name: "PersonalInformation",
      },
      {
        path: "BankAccountSuccessful",
        component: BankAccountSuccessful,
        name: "BankAccountSuccessful",
      },
      {
        path: "PinPassword",
        component: PinPassword,
        name: "PinPassword",
      },
      {
        path: "ReviewKYC",
        component: ReviewKYC,
        name: "ReviewKYC",
        meta: {
          requireKycPending: true,
        },
      },
      {
        path: "CloseAccount",
        component: CloseAccount,
        name: "CloseAccount",
      },
      {
        path: "TouchFaceID",
        component: TouchFaceID,
        name: "TouchFaceID",
      },
      {
        path: "RequestIncrease",
        component: RequestIncrease,
        name: "RequestIncrease",
      },
      {
        path: "DetermineCost",
        component: DetermineCost,
        name: "DetermineCost",
      },
      {
        path: "ReceivingInformation",
        component: ReceivingInformation,
        name: "ReceivingInformation",
      },
      {
        path: "LoginHistory",
        component: LoginHistory,
        name: "LoginHistory",
      },
      {
        path: "SetNotifications",
        component: SetNotifications,
        name: "SetNotifications",
      },
      {
        path: "SetAlerts",
        component: SetAlerts,
        name: "SetAlerts",
      },
      {
        path: "AboutUs",
        component: AboutUs,
        name: "AboutUs",
      },
      {
        path: "ContactUs",
        component: ContactUs,
        name: "ContactUs",
      },
    ],
  },
];
import packageInfo from "../../package.json";
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
  let isLoggedIn = user_data?.id || false;
  let isResetPassword = user_data?.resetPasswordFlag || false;
  let isResetPin = user_data?.resetPin || false;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next("/login");
    } else if (to.path === "/update-password" && !isResetPassword) {
      next("/");
    } else if (to.path === "/update-pin" && !isResetPin) {
      next("/");
    } else {
      next();
    }
  } else if (to.path === "/login" && isLoggedIn) {
    next("/");
  } else {
    if (isResetPassword && to.path !== "/update-password") {
      next("/update-password");
    } else if (isResetPin && to.path !== "/update-pin") {
      next("/update-pin");
    } else {
      next();
    }
  }

  //check Policy || PDPA
  /* const requirePolicy = user_data?.requirePdpa || false;
  if (to.matched.some(record => record.meta.requirePolicy) && requirePolicy && isLoggedIn) {
    next('/policy');
  } else if (to.path === "/policy" && !requirePolicy) {
    next("/login");
  } else {
    next();
  } */

  //const isKnowledgePassed = localStorage.getItem("isKnowledgePassed") === "false";
  //check knowledge-test
  // Get current date
  var user_login = JSON.parse(localStorage.getItem("user-data") || "{}");
  if (user_login?.id) {
    HTTP.get("/uc/member/check-quiz")
      .then(function (res) {
        if (res.data.code == 1) {
          next("/knowledge-test");
        } else if (res.data.code == 0) {
          if (to.path == "/knowledge-test") {
            next("/market/all-coins");
          }
        } else {
          // next("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    if (to.path == "/knowledge-test") {
      next("/login");
    }
  }

  //check kyc-pending
  // let kycReviewStatus = localStorage.getItem("kycReviewStatus");
  // let data = JSON.parse(localStorage.getItem("resMember"));
  // let requireKycReview = data?.resMember.requireKycReviewType;
  // if (to.matched.some((record) => record.meta.requireKycPending)) {
  //   if (requireKycReview && kycReviewStatus != 0) {
  //     next();
  //   } else if (kycReviewStatus == 0) {
  //     next("/ProfileSetting");
  //   } else {
  //     next("/ProfileSetting");
  //   }
  // } else {
  //   // next();
  // }
});
router.afterEach(() => {
  Vue.prototype.$Progress.finish();
});
export default router;
