<template>
  <div class="wrapper pi">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h1 class="page-heading m-0">ข้อมูลส่วนตัว</h1>
          </div>
          <div v-if="isLoading">
            <div class="loader-wrapper"><div class="loader"></div></div>
          </div>
          <div v-else>
            <div class="row m-0">
              <div class="col-5 p-0">
                <div class="box-personal-info bg-box">
                  <p class="u-n">{{ user.fullNameTH }}</p>
                  <div class="firt-line d-flex justify-content-between">
                    <p class="text-box">เลขที่บัญชี</p>
                    <p class="text-box" style="margin-bottom: 21px">
                      {{ user.username }}
                    </p>
                  </div>
                  <div class="third-line d-flex justify-content-between">
                    <p class="text-box m-0">เงินสดคงเหลือ</p>
                    <p class="text-box m-0" style="margin-bottom: 21px">
                      {{ $MyFunc.NumberFormat(user.balance, 2) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-11 p-0">
                <table class="table width-table">
                  <tbody>
                    <tr class="border-bottom-table">
                      <td class="table-text p-0">
                        <p class="m-0">ชื่อ-นามสกุล</p>
                      </td>
                      <td class="table-text-white p-0 pb-2">
                        <p class="m-0 font_sarabun">{{ user.fullNameTH }}</p>
                        <p class="m-0 font_sarabun">{{ user.fullNameEN }}</p>
                      </td>
                      <td class="p-0 text-right">
                        <p
                          class="table-text-yellow correct-button m-0"
                          @click="handleModal('FullName', true)"
                        >
                          แก้ไข
                        </p>
                      </td>
                    </tr>
                    <tr class="border-bottom-table">
                      <td class="table-text p-0 pb24 pt20">
                        <p class="m-0">เบอร์โทรศัพท์</p>
                      </td>
                      <td class="table-text-white p-0 pt20">
                        <p class="m-0">{{ user.phone }}</p>
                      </td>
                      <td class="p-0 pt20 text-right">
                        <p
                          class="table-text-yellow correct-button m-0"
                          @click="handleModal('Phone', true)"
                        >
                          แก้ไข
                        </p>
                      </td>
                    </tr>
                    <tr class="border-bottom-table">
                      <td class="table-text p-0 pb24 pt20">
                        <p class="m-0 roboto_flex">Email</p>
                      </td>
                      <td class="table-text-white p-0 pt20">
                        <p class="m-0">{{ user.email }}</p>
                      </td>
                      <td class="p-0 pt20 text-right">
                        <p
                          class="table-text-yellow correct-button m-0"
                          @click="handleModal('Email', true)"
                        >
                          แก้ไข
                        </p>
                      </td>
                    </tr>
                    <tr class="border-bottom-table">
                      <td class="table-text p-0 pb24 pt20">
                        <p class="m-0">ที่อยู่ปัจจุบัน</p>
                      </td>
                      <td class="table-text-white p-0 pt20 pb-2">
                        <p class="m-0 font_sarabun">
                          {{ user.addresscurrent }}
                        </p>
                      </td>
                      <td class="p-0 pt20 text-right">
                        <p
                          v-if="editAddress.inChangeAddressCurrent == 0"
                          class="table-text-yellow correct-button m-0"
                          @click="handleModal('Address', true)"
                        >
                          แก้ไข
                        </p>
                        <p
                          v-if="editAddress.inChangeAddressCurrent == 1"
                          class="table-text-yellow"
                        >
                          รอตรวจสอบ
                        </p>
                      </td>
                    </tr>
                    <tr class="border-bottom-table">
                      <td class="table-text p-0 pb24 pt20">
                        <p class="m-0">ที่อยู่ตามบัตรประชาชน</p>
                      </td>
                      <td class="table-text-white p-0 pt20 pb-2">
                        <p class="m-0 font_sarabun">
                          {{ user.addressidcard }}
                        </p>
                      </td>
                      <td class="p-0 pt20 text-right">
                        <p
                          v-if="editAddress.inChangeAddressIdCard == 0"
                          class="table-text-yellow correct-button m-0"
                          @click="handleModal('AddressIDCard', true)"
                        >
                          แก้ไข
                        </p>
                        <p
                          v-if="editAddress.inChangeAddressIdCard == 1"
                          class="table-text-yellow"
                        >
                          รอตรวจสอบ
                        </p>
                      </td>
                    </tr>
                    <tr class="border-bottom-end">
                      <td class="table-text p-0 pb24 pt20">
                        <p class="m-0">
                          สถาน{{ checkTextWorkplace(occupation) }}
                        </p>
                      </td>
                      <td class="table-text-white p-0 pt20 pb-2">
                        <p class="m-0 font_sarabun">
                          {{ user.placeofwork }}
                        </p>
                        <p
                          v-if="user.detailofwork.length > 0"
                          class="m-0 font_sarabun"
                        >
                          {{ user.detailofwork }}
                        </p>
                        <p
                          v-if="user.detailofwork2.length > 0"
                          class="m-0 font_sarabun"
                        >
                          {{ user.detailofwork2 }}
                        </p>
                      </td>
                      <td class="p-0 pt20 text-right">
                        <p
                          v-if="editAddress.inChangeAddressWork == 0"
                          class="table-text-yellow correct-button m-0"
                          @click="handleModal('Workplace', true)"
                        >
                          แก้ไข
                        </p>
                        <p
                          v-if="editAddress.inChangeAddressWork == 1"
                          class="table-text-yellow"
                        >
                          รอตรวจสอบ
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal Edit Name-Surname-th change -->
    <div v-if="ModalEditFullName">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div
            class="modal-wrapper"
            style="height: 100%; overflow-y: auto; display: block"
          >
            <div class="modal-dialog" role="document" style="margin: 60px auto">
              <div class="modal-content">
                <div class="modal-header p-0">
                  <div class="container p-0">
                    <div class="row m-0 padding24">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงชื่อ-นามสกุล
                            <!-- <span class="info-icon">
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                (ModalEditFullName = false),
                                  (validate_change_name = false)
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-body scroller1 p-0"
                  style="padding: 0px !important"
                >
                  <div
                    class="container m-0 p-0 paddingLeft24"
                    style="padding-right: 24px !important"
                  >
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p class="text-grey">ชื่อ-นามสกุลปัจจุบัน</p>
                          <p
                            class="text-dark-grey"
                            style="margin-left: 16px; margin-bottom: 16px"
                          >
                            {{ user.fullNameTH }} <br />
                            {{ user.fullNameEN }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex mt-4"
                          style="width: 432px"
                        >
                          <p class="text-grey">ชื่อ-นามสกุลใหม่</p>
                        </div>
                      </div>
                    </div>

                    <!-- title id start  -->
                    <div
                      class="wraper-input d-flex justify-content-between mb-3"
                      style="margin-top: 12px"
                    >
                      <!-- Bussiness -->
                      <p
                        class="text-dark-grey mb-1 ms-3"
                        style="margin-top: 9px"
                      >
                        คำนำหน้า
                      </p>
                      <div
                        ref="titleNameThDropdown"
                        class="col-7 input-container"
                        style="
                          line-height: 24px;
                          position: relative;
                          left: 11px;
                        "
                        @click="
                          open_titleNameTh_dropdown = true;
                          searchTitleNameTh = '';
                          searchTitleNameEn = '';
                        "
                        @blur="open_titleNameTh_dropdown = false"
                      >
                        <input
                          :class="{
                            error:
                              !searchTitleNameTh &&
                              validate_change_name === true,
                          }"
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchTitleNameTh"
                          @blur="chkTitleName"
                          readonly
                        />
                        <p
                          v-if="
                            !searchTitleNameTh && validate_change_name === true
                          "
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                        <span
                          class="text-dark-grey"
                          v-if="
                            !searchTitleNameTh || searchTitleNameTh.length == 0
                          "
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #566a76;
                            pointer-events: none;
                          "
                        >
                          ระบุคำนำหน้าชื่อ
                        </span>

                        <div
                          style="width: 240px"
                          :class="
                            open_titleNameTh_dropdown
                              ? 'd-options show'
                              : 'd-options hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListTitleNameTh"
                              :key="index"
                              @click.stop="
                                searchTitleNameTh = item.nameTh;
                                searchTitleNameEn = item.nameEn;
                                searchTitleId = item.id;
                                open_titleNameTh_dropdown = false;
                                open_titleNameEn_dropdown = false;
                              "
                            >
                              {{ item.nameTh }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- title id  -->
                    <!-- name TH input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="margin-top: 12px"
                    >
                      <p class="text-dark-grey mt-2 ms-3">ชื่อ</p>
                      <div class="name-input">
                        <label for="inputPassword2" class="visually-hidden">
                          ระบุชื่อ
                        </label>
                        <input
                          class="form-controlv1 mb-0 address-input"
                          :class="{
                            error: !NewNameTh && validate_change_name === true,
                          }"
                          type="text"
                          v-model="NewNameTh"
                          placeholder="ระบุชื่อ"
                          @input="ValidateThaiInput($event, 'nameTh')"
                        />
                        <p
                          v-if="!NewNameTh && validate_change_name === true"
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- surname TH input  -->
                    <div class="wraper-input d-flex justify-content-between">
                      <p class="text-dark-grey mt-2 ms-3">นามสกุล</p>
                      <div class="name-input">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุนามสกุล</label
                        >
                        <input
                          class="form-controlv1 mb-0 address-input"
                          :class="{
                            error:
                              !NewSurNameTh && validate_change_name === true,
                          }"
                          type="text"
                          v-model="NewSurNameTh"
                          placeholder="ระบุนามสกุล"
                          @input="ValidateThaiInput($event, 'surnameTh')"
                        />
                        <p
                          v-if="!NewSurNameTh && validate_change_name === true"
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- EN start  -->
                    <!-- title EN start  -->
                    <div
                      class="wraper-input d-flex justify-content-between mb-3"
                    >
                      <!-- Bussiness -->
                      <p
                        class="text-dark-grey mb-1 ms-3"
                        style="margin-top: 9px"
                      >
                        คำนำหน้า (ภาษาอังกฤษ)
                      </p>
                      <div
                        ref="titleNameEnDropdown"
                        class="col-7 input-container"
                        style="
                          line-height: 24px;
                          position: relative;
                          left: 11px;
                        "
                        @click="
                          open_titleNameEn_dropdown = true;
                          searchTitleNameTh = '';
                          searchTitleNameEn = '';
                        "
                        @blur="open_titleNameEn_dropdown = false"
                      >
                        <input
                          :class="{
                            error:
                              !searchTitleNameEn &&
                              validate_change_name === true,
                          }"
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchTitleNameEn"
                          @blur="chkTitleName()"
                          readonly
                        />
                        <span
                          class="text-dark-grey"
                          v-if="
                            !searchTitleNameEn || searchTitleNameEn.length == 0
                          "
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #566a76;
                            pointer-events: none;
                          "
                        >
                          ระบุคำนำหน้าชื่อภาษาอังกฤษ
                        </span>
                        <p
                          v-if="
                            !searchTitleNameEn && validate_change_name === true
                          "
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                        <div
                          style="width: 240px"
                          :class="
                            open_titleNameEn_dropdown
                              ? 'd-options show'
                              : 'd-options hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListTitleNameEn"
                              :key="index"
                              @click.stop="
                                searchTitleNameTh = item.nameTh;
                                searchTitleNameEn = item.nameEn;
                                searchTitleId = item.id;
                                open_titleNameTh_dropdown = false;
                                open_titleNameEn_dropdown = false;
                              "
                            >
                              {{ item.nameEn }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- title EN  -->

                    <!-- name EN input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="margin-top: 12px"
                    >
                      <p class="text-dark-grey mt-2 ms-3">ชื่อ (ภาษาอังกฤษ)</p>
                      <div class="name-input">
                        <label for="inputPassword2" class="visually-hidden">
                          ระบุชื่อภาษาอังกฤษ
                        </label>
                        <input
                          type="text"
                          v-model="NewNameEn"
                          class="form-controlv1 mb-0 address-input"
                          :class="{
                            error: !NewNameEn && validate_change_name === true,
                          }"
                          placeholder="ระบุชื่อภาษาอังกฤษ"
                          @input="ValidateThaiInput($event, 'nameEn')"
                        />
                        <p
                          v-if="!NewNameEn && validate_change_name === true"
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- surname EN input  -->
                    <div class="wraper-input d-flex justify-content-between">
                      <p class="text-dark-grey mt-2 ms-3">
                        นามสกุล (ภาษาอังกฤษ)
                      </p>
                      <div class="name-input">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุนามสกุลภาษาอังกฤษ</label
                        >
                        <input
                          type="text"
                          v-model="NewSurNameEn"
                          class="form-controlv1 mb-0 address-input"
                          :class="{
                            error:
                              !NewSurNameEn && validate_change_name === true,
                          }"
                          placeholder="ระบุนามสกุลภาษาอังกฤษ"
                          @input="ValidateThaiInput($event, 'surnameEn')"
                        />
                        <p
                          v-if="!NewSurNameEn && validate_change_name === true"
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- code back of id card -->
                    <div class="id-code">
                      <p class="text-dark-grey mb-4">
                        รหัสหลังบัตรประชาชน (Laser Code)
                      </p>
                      <div class="name-input mb-4">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุรหัสหลังบัตรประชาชน</label
                        >
                        <input
                          type="text"
                          v-model="NewLaserCode"
                          class="form-controlv1 address-input mb-0"
                          :class="{
                            error:
                              !NewLaserCode && validate_change_name === true,
                          }"
                          placeholder="ระบุรหัสหลังบัตรประชาชน"
                          :maxlength="14"
                          v-maska:[laserIdOptions]
                          data-maska="AA#-#######-##"
                        />
                        <p
                          v-if="!NewLaserCode && validate_change_name === true"
                          class="error-message"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- file attachment -->
                    <div class="id-code">
                      <p class="text-dark-grey">ยืนยันตัวตน</p>
                      <p class="text-dark-grey">
                        กรุณากดบันทึกเพื่อเริ่มยืนยันตัวตนแบบเซลฟี่
                      </p>
                      <div class="name-input">
                        <label for="inputPassword2" class="visually-hidden"
                          >ถ่ายรูปของท่าน</label
                        >
                      </div>
                    </div>

                    <div class="button marginTopBottom32 text-center">
                      <button
                        type="button"
                        class="btn-primary btn-text-sarabun"
                        style="width: 240px; border-radius: 4px"
                        @click="changeName"
                      >
                        <span v-if="isLoadingName" style="color: black">
                          กำลังส่งคำขอ ...
                        </span>
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="ModalIDCard">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 650px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            กรุณาทำตามขั้นตอน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                ModalIDCard = false;
                                StopCamera();
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="oc2">
                        <p>ขั้นตอนที่ 1</p>
                        <p>จัดบัตรประชาชนให้อยู่ในกรอบ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <video
                            ref="camera"
                            autoplay
                            width="100%"
                            height="100%"
                            style="object-fit: cover; border-radius: 4px"
                          ></video>
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">หมายเหตุ</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            กดปุ่ม “ถ่ายรูป” เพื่อเปิดกล้อง
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            หากท่านยังไม่สามารถกดถ่ายรูปได้ กรุณาตรวจสอบ
                            การตั้งค่า ได้
                          </p>
                          <p style="margin-left: 24px; color: #9bacb6">
                            ได้ <span style="color: #f38220">ที่นี่</span>
                          </p>
                        </div>
                        <div
                          class="button-camera-open"
                          style="padding-top: 70px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="TakePicture"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                position: relative;
                              "
                            >
                              <img
                                style="position: absolute; left: 49px; top: 5px"
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                                alt=""
                              />
                              ถ่ายรูปบัตรประชาชน
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalSelfi">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 650px"
            >
              <div
                v-if="checkStatusLiveness"
                class="modal-content"
                style="margin-bottom: 200px"
              >
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            กรุณาทำตามขั้นตอน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                ModalSelfi = false;
                                StopCamera();
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="oc2">
                        <p>ขั้นตอนที่ 2</p>
                        <p>“ถ่ายรูปเซลฟี่” เพื่อยืนยันตัวตนของคุณ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <video
                            ref="camera"
                            autoplay
                            width="100%"
                            height="100%"
                            style="object-fit: cover; border-radius: 4px"
                          ></video>
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">ข้อแนะนำก่อนแสกนใบหน้า</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            ไม่สวมหมวก, หน้ากาก หรือแว่นตาปิดบังใบหน้า
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            อยู่ในพื้นที่แสงสว่างเพียงพอ และไม่ติดคนด้านหลัง
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">3.</span>
                            ไม่มีผมปิดบังใบหน้า
                          </p>
                        </div>
                        <div
                          class="button-camera-open"
                          style="padding-top: 70px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="TakePicture"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                position: relative;
                              "
                            >
                              <img
                                style="position: absolute; left: 80px; top: 5px"
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                                alt=""
                              />
                              &nbsp; ถ่ายรูปเซลฟี่
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row text-center">
                <span class="mt-5">กำลังตรวจสอบ...</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalShowPhoto">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 100px auto; height: 443px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            <span v-if="CameraStep == 0">
                              ยืนยันรูปบัตรประชาชน
                            </span>
                            <span v-else> การยืนยันการเซลฟี่ </span>
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalShowPhoto = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <img
                            :src="PhotoBase64"
                            alt="Captured Image"
                            style="
                              cursor: pointer;
                              object-fit: cover;
                              border-radius: 4px;
                            "
                            width="100%"
                            height="100%"
                          />
                        </div>

                        <div
                          class="button-camera-open"
                          style="padding-top: 40px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="NexttoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                              "
                            >
                              ยืนยัน
                            </button>
                            <button
                              @click="BacktoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #222b2f !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              ถ่ายรูปอีกครั้ง
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalSimilarity">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 100px auto; height: 443px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <!-- <h5 class="modal-title m-auto text-center">
                             <span v-if="CameraStep == 0">
                              ยืนยันรูปบัตรประชาชน
                            </span>
                            <span v-else> การยืนยันการเซลฟี่ </span> 
                            
                            <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span>
                          </h5> -->
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalSimilarity = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          v-if="!isCalculating"
                          class="d-grid justify-content-center py-5"
                        >
                          <div class="text-center">
                            <p
                              v-if="NameSimilarity > 95"
                              style="font-size: 20px; color: #40994f"
                            >
                              ผ่าน
                            </p>
                            <p v-else style="font-size: 20px; color: #de2d40">
                              ไม่ผ่าน
                            </p>
                          </div>
                          <div v-if="NameSimilarity > 95" class="text-center">
                            <img
                              class="image-tick-modal"
                              src="../../assets/images/profile-setting/tick.png"
                              alt=""
                              style="margin-bottom: 40px"
                            />
                          </div>
                          <div v-else>
                            <span>
                              <svg
                                style="margin-bottom: 40px"
                                width="95"
                                height="95"
                                viewBox="0 0 68 70"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_di_1067_133588)">
                                  <circle
                                    cx="34"
                                    cy="32"
                                    r="30"
                                    fill="url(#paint0_linear_1067_133588)"
                                  />
                                </g>
                                <g filter="url(#filter1_d_1067_133588)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M25.1213 18.8787C23.9497 17.7071 22.0503 17.7071 20.8787 18.8787C19.7071 20.0503 19.7071 21.9497 20.8787 23.1213L29.6363 31.8789L20.8791 40.636C19.7076 41.8076 19.7076 43.7071 20.8791 44.8787C22.0507 46.0503 23.9502 46.0503 25.1218 44.8787L33.8789 36.1215L42.0919 44.3345C43.2635 45.5061 45.163 45.5061 46.3345 44.3345C47.5061 43.163 47.5061 41.2635 46.3345 40.0919L38.1215 31.8789L46.335 23.6655C47.5065 22.4939 47.5065 20.5944 46.335 19.4228C45.1634 18.2513 43.2639 18.2513 42.0923 19.4228L33.8789 27.6363L25.1213 18.8787Z"
                                    fill="url(#paint1_linear_1067_133588)"
                                  />
                                </g>
                                <defs>
                                  <filter
                                    id="filter0_di_1067_133588"
                                    x="0"
                                    y="0"
                                    width="68"
                                    height="70"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_1067_133588"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_1067_133588"
                                      result="shape"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="-2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="arithmetic"
                                      k2="-1"
                                      k3="1"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0.486369 0 0 0 0 0.789038 0 0 0 0 0.588355 0 0 0 0.4 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="shape"
                                      result="effect2_innerShadow_1067_133588"
                                    />
                                  </filter>
                                  <filter
                                    id="filter1_d_1067_133588"
                                    x="16"
                                    y="18"
                                    width="35.2109"
                                    height="35.7573"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0.721569 0 0 0 0 0.117647 0 0 0 0 0.180392 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_1067_133588"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_1067_133588"
                                      result="shape"
                                    />
                                  </filter>
                                  <linearGradient
                                    id="paint0_linear_1067_133588"
                                    x1="33.5082"
                                    y1="62"
                                    x2="33.5082"
                                    y2="2"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#DE2D40" />
                                    <stop offset="1" stop-color="#EE5D6D" />
                                  </linearGradient>
                                  <linearGradient
                                    id="paint1_linear_1067_133588"
                                    x1="33.6068"
                                    y1="49.5"
                                    x2="33.6068"
                                    y2="28.5"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#E6BDBD" />
                                    <stop offset="1" stop-color="white" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                          </div>
                          <div class="text-center">
                            ความเหมือน
                            {{ $MyFunc.toFixedDigits(NameSimilarity, 2) }}%
                          </div>
                          <div
                            class="button-camera-open"
                            style="padding-top: 40px"
                          ></div>
                        </div>
                        <div v-else class="d-flex justify-content-center">
                          <span>กำลังคำนวณ...</span>
                        </div>
                        <div
                          v-if="!isCalculating"
                          class="button-camera-open"
                          style="padding-top: 40px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              v-if="NameSimilarity > 95"
                              @click="backToChangeNameOTP"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #222b2f !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 50px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              ยืนยันตัวตนผ่าน OTP
                            </button>
                            <button
                              v-else
                              @click="ModalSimilarity = false"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #222b2f !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 50px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              ยกเลิก
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- End modal Edit Name-Surname change -->
    <!-- modal Edit Phone Number -->
    <div v-if="ModalEditPhoneNumber">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" style="background-color: #0a0d10">
            <div class="modal-dialog" role="document" style="height: 327px">
              <div class="modal-content">
                <div class="modal-header p-0">
                  <div class="container p-0">
                    <div class="row m-0 padding24">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงเบอร์โทรศัพท์
                            <!-- <span
                              ><svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                (ModalEditPhoneNumber = false),
                                  (validate_change_tel = false)
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body p-0">
                  <div class="container" style="padding: 0px 24px">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="current-name d-flex borderBottom-in-modal">
                          <p
                            style="
                              margin-top: 8px;
                              margin-bottom: 28px;
                              color: #d6dde1;
                            "
                          >
                            เบอร์โทรศัพท์ปัจจุบัน
                          </p>
                          <p
                            style="
                              margin-top: 8px;
                              margin-bottom: 28px;
                              margin-left: 28px;
                              font-family: 'Roboto Flex' !important;
                              color: #9bacb6;
                            "
                          >
                            {{ user.phone }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new phone number input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%; margin-top: 24px"
                    >
                      <p class="mt-2 mb-0" style="color: #d6dde1">
                        เบอร์โทรศัพท์ใหม่
                      </p>
                      <div class="name-input" style="width: 66%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุเบอร์โทรศัพท์</label
                        >
                        <input
                          style="margin-left: 16px"
                          v-model="NewPhone"
                          @input="validateInput"
                          :class="{
                            error: !NewPhone && validate_change_tel === true,
                          }"
                          required
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุเบอร์โทรศัพท์"
                          maxlength="10"
                        />
                        <p
                          v-if="!NewPhone && validate_change_tel === true"
                          class="error-message mt-0"
                          style="padding-left: 15px"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin-top: 40px; margin-bottom: 48px"
                    >
                      <button
                        style="margin-left: 16px; width: 240px"
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changePhone"
                      >
                        <span v-if="isLoadingPhone" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="failPhoneNumber">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div style="height: 56px"></div>
                        <div class="checkTick mt-10">
                          <img
                            class="image-danger-modal"
                            src="../../assets/images/wallet/cross.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message">
                          <p
                            class=""
                            v-html="text_phone_error"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 24.18px;
                              color: #d6dde1;
                            "
                          >
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="failPhoneNumber = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                        <div style="height: 32px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- end modal Edit phone number -->
    <!-- modal Edit Email -->
    <div v-if="ModalEditEmail">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document" style="height: 327px">
              <div class="modal-content">
                <div class="modal-header p-0">
                  <div class="container p-0">
                    <div class="row m-0 padding24">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงอีเมล
                            <span>
                              <!-- <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg> -->
                            </span>
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                (ModalEditEmail = false),
                                  (validate_change_email = false)
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body p-0">
                  <div class="container p-0 paddingLeft24">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p
                            class="text-dark-grey"
                            style="margin-top: 8px; margin-bottom: 24px"
                          >
                            อีเมลปัจจุบัน
                          </p>
                          <p
                            class="t"
                            style="
                              margin-left: 39px;
                              margin-top: 8px;
                              margin-bottom: 24px;
                              font-family: 'Roboto Flex' !important;
                            "
                          >
                            {{ user.email }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new email input -->
                    <div
                      class="wraper-input d-flex"
                      style="width: 90%; margin-top: 24px"
                    >
                      <p class="text-dark-grey mt-2 mb-0">อีเมลใหม่</p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุอีเมล</label
                        >
                        <input
                          type="text"
                          v-model="NewEmail"
                          class="form-controlv1"
                          :class="{
                            error: !NewEmail && validate_change_email === true,
                          }"
                          style="max-width: 240px; margin-left: 56px"
                          placeholder="ระบุอีเมล"
                          @input="validateFormatEmail"
                        />
                        <p
                          v-if="!NewEmail && validate_change_email === true"
                          class="error-message mt-0"
                          style="padding-left: 55px"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 40px 0px 48px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeEmail"
                        style="width: 240px"
                      >
                        <span v-if="isLoadingEmail" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="failEditEmail">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div style="height: 56px"></div>
                        <div class="checkTick mt-3">
                          <img
                            class="image-danger-modal"
                            src="../../assets/images/wallet/cross.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message mb-5 mt-3">
                          <p
                            class="text-grey"
                            v-html="text_error_email"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 24.18px;
                              color: #d6dde1;
                            "
                          >
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="failEditEmail = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                        <div style="height: 32px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal edit Email -->
    <!-- modal Edit Address -->
    <div v-if="ModalEditAddress">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              overflow-y: auto;
              display: block;
              background-color: #0a0d10;
            "
          >
            <div class="modal-dialog" role="document" style="margin: 60px auto">
              <div class="modal-content" style="width: 500px">
                <div class="modal-header p-0">
                  <div class="container p-0">
                    <div class="row m-0 padding24">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงที่อยู่ปัจจุบัน
                            <!-- <span
                              ><svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                (ModalEditAddress = false),
                                  (validate_change_address = true)
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div class="container p-0 paddingLeft24">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p style="margin: 8px 0px 48px; color: #d6dde1">
                            ที่อยู่ปัจจุบัน
                          </p>
                          <p style="margin: 8px 0px 24px 42px; color: #9bacb6">
                            {{ user.addresscurrent }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- id card  address heading -->
                    <p class="mt-3 mb-0" style="color: #d6dde1">ที่อยู่ใหม่</p>
                    <!-- number and group two input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 34px">
                        เลขที่
                        <span class="text-danger">*</span>
                      </p>
                      <div class="name-input" style="margin-top: 28px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <div class="left-right-input d-flex">
                          <div>
                            <input
                              v-model="Address.HouseNO"
                              type="text"
                              class="form-controlv1 two-ipnuts"
                              :class="{
                                error:
                                  !Address.HouseNO &&
                                  validate_change_address === true,
                              }"
                              placeholder="ระบุข้อมูล"
                              @input="onHouseNOInput"
                            />
                            <p
                              v-if="
                                !Address.HouseNO &&
                                validate_change_address === true
                              "
                              style="font-size: 12px"
                              class="error-message mb-0"
                            >
                              กรุณากรอกข้อมูล
                            </p>
                          </div>

                          <!-- เลื่อนไปอยู่ที่นี่ -->
                          <p
                            class="text-dark-grey mt-2 two-inputs-labelMargin"
                            style="margin: 0px 15px 0px 14px"
                          >
                            หมู่
                          </p>
                          <label for="inputPassword2" class="visually-hidden"
                            >ระบุข้อมูล</label
                          >
                          <div>
                            <input
                              v-model="Address.Group"
                              type="text"
                              style="width: 84px !important"
                              class="form-controlv1 two-ipnuts"
                              placeholder="ระบุข้อมูล"
                              @input="onGroupInput"
                            />
                          </div>

                          <!-- และเลื่อนมาที่นี่ -->
                        </div>
                      </div>
                    </div>

                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        หมู่บ้าน / อาคาร
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Village"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ชั้น
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Floor"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onFloorInput"
                        />
                      </div>
                    </div>

                    <!-- alley input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ซอย
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Alley"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <!-- road input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ถนน
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Road"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <!-- Country select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ประเทศ
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="CountryDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_Country_dropdown = true;
                          searchCountry = '';
                          clearFullAddress();
                        "
                        @blur="open_Country_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchCountry"
                          :class="{ error: !searchCountry && validate_change_address === true}"
                          @blur="chkCountry"
                        />
                        <span
                          v-if="searchCountry.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกประเทศ
                        </span>
                        <!-- :value="selectedCountry" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Country_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListCountry"
                              :key="index"
                              @click.stop="getThailand(item)"
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p v-if="!searchCountry && validate_change_address === true" class="error-message mb-0">
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- / Country select box -->

                    <!-- Province select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        จังหวัด
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="ProvinceDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_Province_dropdown = true;
                          searchProvince = '';
                        "
                        @blur="open_Province_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchProvince"
                          :class="{
                            error:
                              !searchProvince &&
                              validate_change_address === true,
                          }"
                          @blur="chkProvince"
                        />

                        <span
                          v-if="searchProvince.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกจังหวัด
                        </span>
                        <!-- :value="selectedSubDist" -->
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->
                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Province_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListProvince"
                              :key="index"
                              @click.stop="
                                searchProvince = item.name;
                                selectedProvince = item.id;
                                open_Province_dropdown = false;
                                searchDist = '';
                                selectedDist = '';
                                searchSubDist = '';
                                selectedSubDist = '';
                                Address.ZipCode = '';
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchProvince && validate_change_address === true
                          "
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- district select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        อำเภอ/ เขต
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="DistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_Dist_dropdown = true;
                          searchDist = '';
                        "
                        @blur="open_Dist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchDist"
                          :class="{
                            error:
                              !searchDist && validate_change_address === true,
                          }"
                          @blur="chkDist"
                        />
                        <span
                          v-if="searchDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอำเภอ/ เขต
                        </span>
                        <!-- :value="selectedSubDist" -->
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->
                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Dist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListDist"
                              :key="index"
                              @click.stop="
                                searchDist = item.name;
                                selectedDist = item.id;
                                open_Dist_dropdown = false;

                                searchSubDist = '';
                                selectedSubDist = '';
                                Address.ZipCode = '';
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="!searchDist && validate_change_address === true"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- subdistrict select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ตำบล/ แขวง
                        <span class="text-danger">*</span>
                      </p>
                      {{ Address.SubDistrict }}
                      <div
                        ref="subDistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_subDist_dropdown = true;
                          searchSubDist = '';
                        "
                        @blur="open_subDist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchSubDist"
                          @blur="chkSubDist"
                          :class="{
                            error:
                              !searchSubDist &&
                              validate_change_address === true,
                          }"
                        />

                        <span
                          v-if="searchSubDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกตำบล/ แขวง
                        </span>
                        <!-- :value="selectedSubDist" -->
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->
                          <!-- search? -->
                        </span>

                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_subDist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListSubDist"
                              :key="index"
                              @click.stop="
                                searchSubDist = item.name;
                                selectedSubDist = item.id;
                                open_subDist_dropdown = false;
                                Address.ZipCode = item.zipcode;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="!searchDist && validate_change_address === true"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- zipcode input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        รหัสไปรษณีย์
                        <span class="text-danger">*</span>
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >

                        <input
                          v-model="Address.ZipCode"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onZipcCodeInput"
                          :class="{
                            error:
                              !Address.ZipCode &&
                              validate_change_address === true,
                          }"
                          @blur="chkZipCode"
                        />
                        <p
                          v-if="
                            !Address.ZipCode && validate_change_address === true
                          "
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 42px 0px 40px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeAddress('Address')"
                      >
                        <span v-if="isLoadingAddress" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="
                          (ModalEditAddress = false),
                            (validate_change_address = false)
                        "
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="failEditAddress">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div style="height: 56px"></div>
                        <div class="checkTick mt-3">
                          <img
                            class="image-danger-modal"
                            src="../../assets/images/wallet/cross.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message mb-5 mt-3">
                          <p
                            class="text-grey"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 24.18px;
                              color: #d6dde1;
                            "
                          >
                            {{ this.text_error_edit_address }}
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="failEditAddress = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                        <div style="height: 32px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal edit Address -->
    <!-- modal Edit Address id card -->
    <div v-if="ModalEditAddressIdCard">
      <transition name="modal">
        <div class="modal-mask ModalEditAddressIdCard">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow: auto;
              background-color: #0a0d10;
            "
          >
            <div class="modal-dialog" role="document" style="margin: 60px auto">
              <div class="modal-content">
                <div class="modal-header p-0">
                  <div class="container p-0">
                    <div class="row m-0 padding24">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงที่อยู่ตามบัตรประชาชน
                            <!-- <span>
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            @click="
                              selectedSubDist = '';
                              selectedDist = '';
                              selectedProvince = '';
                              selectedCountry = '';
                            "
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditAddressIdCard = false ,validate_change_address = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div class="container p-0 paddingLeft24">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p style="margin: 8px 0px 48px; color: #d6dde1">
                            ที่อยู่ปัจจุบัน
                          </p>
                          <p style="margin: 8px 0px 24px 62px; color: #9bacb6">
                            {{ user.addressidcard }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- id card  address heading -->
                    <p class="mt-3 mb-0" style="color: #d6dde1">ที่อยู่ใหม่</p>
                    <!-- number and group two input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 34px">
                        เลขที่
                        <span class="text-danger">*</span>
                      </p>
                      <div class="name-input" style="margin-top: 28px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <div class="left-right-input d-flex">
                          <div>
                            <input
                              v-model="Address.HouseNO"
                              type="text"
                              class="form-controlv1 two-ipnuts"
                              :class="{
                                error:
                                  !Address.HouseNO &&
                                  validate_change_address === true,
                              }"
                              placeholder="ระบุข้อมูล"
                              @input="onHouseNOInput"
                            />
                            <p
                              v-if="
                                !Address.HouseNO &&
                                validate_change_address === true
                              "
                              style="font-size: 12px"
                              class="error-message mb-0"
                            >
                              กรุณากรอกข้อมูล
                            </p>
                          </div>

                          <!-- เลื่อนไปอยู่ที่นี่ -->
                          <p
                            class="text-dark-grey mt-2 two-inputs-labelMargin"
                            style="margin: 0px 15px 0px 14px"
                          >
                            หมู่
                          </p>
                          <label for="inputPassword2" class="visually-hidden"
                            >ระบุข้อมูล</label
                          >
                          <div>
                            <input
                              v-model="Address.Group"
                              type="text"
                              style="width: 84px !important"
                              class="form-controlv1 two-ipnuts"
                              placeholder="ระบุข้อมูล"
                              @input="onGroupInput"
                            />
                          </div>

                          <!-- และเลื่อนมาที่นี่ -->
                        </div>
                      </div>
                    </div>

                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        หมู่บ้าน / อาคาร
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Village"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ชั้น
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Floor"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onFloorInput"
                        />
                      </div>
                    </div>

                    <!-- alley input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 40px">
                        ซอย
                      </p>
                      <div class="name-input" style="margin-top: 25px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Alley"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <!-- road input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 36px">
                        ถนน
                      </p>
                      <div class="name-input" style="margin-top: 27px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Road"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <!-- Province select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        จังหวัด
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="ProvinceDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_Province_dropdown = true;
                          searchProvince = '';
                        "
                        @blur="open_Province_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchProvince"
                          :class="{
                            error:
                              !searchProvince &&
                              validate_change_address === true,
                          }"
                          @blur="chkProvince"
                        />
                        <span
                          v-if="searchProvince.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกจังหวัด
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Province_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListProvince"
                              :key="index"
                              @click.stop="
                                searchProvince = item.name;
                                selectedProvince = item.id;
                                open_Province_dropdown = false;

                                searchDist = '';
                                selectedDist = '';
                                searchSubDist = '';
                                selectedSubDist = '';
                                Address.ZipCode = '';
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchProvince && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- district select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        อำเภอ/ เขต
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="DistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_Dist_dropdown = true;
                          searchDist = '';
                        "
                        @blur="open_Dist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          :class="{
                            error:
                              !searchDist && validate_change_address === true,
                          }"
                          type="text"
                          v-model="searchDist"
                          @blur="chkDist"
                        />
                        <span
                          v-if="searchDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอำเภอ/ เขต
                        </span>
                        <!-- :value="selectedSubDist" -->
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->
                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Dist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListDist"
                              :key="index"
                              @click.stop="
                                searchDist = item.name;
                                selectedDist = item.id;
                                open_Dist_dropdown = false;
                                searchSubDist = '';
                                selectedSubDist = '';
                                Address.ZipCode = '';
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="!searchDist && validate_change_address === true"
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- subdistrict select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ตำบล/ แขวง
                        <span class="text-danger">*</span>
                      </p>
                      {{ Address.SubDistrict }}
                      <div
                        ref="subDistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="
                          open_subDist_dropdown = true;
                          searchSubDist = '';
                        "
                        @blur="open_subDist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchSubDist"
                          @blur="chkSubDist"
                          :class="{
                            error:
                              !searchSubDist &&
                              validate_change_address === true,
                          }"
                        />

                        <span
                          v-if="searchSubDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกตำบล/ แขวง
                        </span>
                        <!-- :value="selectedSubDist" -->
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->
                          <!-- search? -->
                        </span>

                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_subDist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListSubDist"
                              :key="index"
                              @click.stop="
                                searchSubDist = item.name;
                                selectedSubDist = item.id;
                                open_subDist_dropdown = false;
                                Address.ZipCode = item.zipcode;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="!searchDist && validate_change_address === true"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- zipcode input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        รหัสไปรษณีย์
                        <span class="text-danger">*</span>
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.ZipCode"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onZipcCodeInput"
                          :class="{
                            error:
                              !Address.ZipCode &&
                              validate_change_address === true,
                          }"
                          @blur="chkZipCode"
                        />
                        <p
                          v-if="
                            !Address.ZipCode && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 42px 0px 40px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeAddress('AddressIDCard')"
                      >
                        <span v-if="isLoadingAddress" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="
                          (ModalEditAddressIdCard = false),
                            (validate_change_address = false)
                        "
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal edit Address id card -->
    <!-- modal Edit workplace -->
    <div v-if="ModalEditWorkplace">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              overflow-y: auto;
              display: block;
              background-color: #0a0d10;
            "
          >
            <div class="modal-dialog" role="document" style="margin: 60px auto">
              <div class="modal-content">
                <div class="modal-header">
                  <div class="container p-0">
                    <div class="row m-0 padding24">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงสถาน{{ checkTextWorkplace(occupation) }}
                            <!-- <span>
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditWorkplace = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller">
                  <div class="container p-0 paddingLeft24">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p
                            class="text-dark-grey mb-0"
                            style="margin-top: 28px; color: #d6dde1 !important"
                          >
                            สถาน{{ checkTextWorkplace(occupation) }}ปัจจุบัน
                          </p>
                          <p
                            class="text-grey"
                            style="margin: 28px 0px 39px 26px; color: #9bacb6"
                          >
                            {{ user.placeofwork }}<br />
                            <span
                              v-if="user.detailofwork.length > 0"
                              style="color: #9bacb6"
                              >{{ user.detailofwork }}</span
                            >
                            <span
                              v-if="user.detailofwork2.length > 0"
                              style="color: #9bacb6"
                              >{{ user.detailofwork2 }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new workplace heading -->
                    <p class="mb-0 mt-4" style="color: #d6dde1">
                      อาขีพและรายได้
                    </p>
                    <!-- work name -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 42px">
                        ชื่อ{{ checkTextWorkplace(searchOcupation, "f") }}
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 36px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-controlv1 address-input"
                          :class="{
                            'bg-disabled': JobWorkNameChk === false,
                            error:
                              !Address.WorkName &&
                              validate_change_address === true &&
                              JobWorkNameChk,
                          }"
                          :placeholder="
                            JobWorkNameChk == true ? 'ระบุข้อมูล' : ''
                          "
                          v-model="Address.WorkName"
                          :disabled="JobWorkNameChk == false"
                        />
                        <p
                          v-if="
                            !Address.WorkName && validate_change_address === true && JobWorkNameChk
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- occupation -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <!-- uocupation -->
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        อาชีพ
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="ocupationDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_Ocupation_dropdown = true;
                          searchOcupation = '';
                        "
                        @blur="open_Ocupation_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          v-model="searchOcupation"
                          :class="{
                            error:
                              !searchOcupation &&
                              validate_change_address === true,
                          }"
                          style="padding: 16px; padding-left: 10px"
                          type="text"
                          @blur="chkOcupation"
                          class="inPDis"
                        />
                        <span
                          v-if="searchOcupation.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอาชีพ
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <img
                            src="@/assets/svg/arrow-down-sm.svg"
                            role="button"
                          />
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          :class="
                            open_Ocupation_dropdown
                              ? 'd-options show'
                              : 'd-options hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListOcupation"
                              :key="index"
                              @click.stop="
                                searchOcupation = item.name;
                                selectedOcupation = item.id;
                                open_Ocupation_dropdown = false;

                                JobTitleChk = item.positionFlag;
                                JobWorkNameChk = item.workplaceFlag;
                                JobBussinessChk = item.businessFlag;

                                searchBussinesstype = '';
                                selectedBussinesstype = 0;

                                if (!JobWorkNameChk) {
                                  Address.WorkName = '';
                                }
                                if (!JobTitleChk) {
                                  Address.JobTitle = '';
                                }
                                if (item.businessFlag) {
                                  getBussinesstype();
                                }
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchOcupation && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- job position -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ตำแหน่งงาน
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-controlv1 address-input"
                          :class="{
                            'bg-disabled': JobTitleChk === false,
                            error:
                              !Address.JobTitle &&
                              validate_change_address === true &&
                              JobTitleChk,
                          }"
                          :placeholder="JobTitleChk == true ? 'ระบุข้อมูล' : ''"
                          v-model="Address.JobTitle"
                          :disabled="JobTitleChk == false"
                        />
                        <p
                          v-if="
                            !Address.JobTitle &&
                            validate_change_address === true &&
                            JobTitleChk
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <!-- Bussiness -->
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ประเภทธุรกิจ
                      </p>
                      <div
                        ref="bussinesstypeDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_Bussinesstype_dropdown = true;
                          searchBussinesstype = '';
                        "
                        @blur="open_Bussinesstype_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          type="text"
                          v-model="searchBussinesstype"
                          @blur="chkBussinessType"
                          :disabled="JobBussinessChk == false"
                          :class="{
                            'bg-disabled': JobBussinessChk === false,
                            error:
                              !searchBussinesstype &&
                              validate_change_address === true &&
                              JobBussinessChk,
                          }"
                          class="inPDis"
                          style="padding: 16px; padding-left: 10px"
                        />
                        <span
                          v-if="
                            searchBussinesstype.length == 0 && JobBussinessChk
                          "
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            pointer-events: none;
                          "
                        >
                          เลือกประเภทธุรกิจ
                        </span>
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          :class="
                            open_Bussinesstype_dropdown
                              ? 'd-options show'
                              : 'd-options hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListBussinesstype"
                              :key="index"
                              @click.stop="
                                searchBussinesstype = item.name;
                                selectedBussinesstype = item.id;
                                open_Bussinesstype_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchBussinesstype &&
                            validate_change_address === true &&
                            JobBussinessChk
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- income -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <!-- Bussiness -->
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        รายได้
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="monthlyIncomeDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_MonthlyIncome_dropdown = true;
                          searchMonthlyIncome = '';
                        "
                        @blur="open_MonthlyIncome_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          @blur="chkMonthlyIncome"
                          v-model="searchMonthlyIncome"
                          :class="{
                            error:
                              !searchMonthlyIncome &&
                              validate_change_address === true,
                          }"
                        />
                        <span
                          v-if="searchMonthlyIncome.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #566a76;
                            pointer-events: none;
                          "
                        >
                          เลือกรายได้
                        </span>
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="
                            open_MonthlyIncome_dropdown ? 'show' : 'hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListMonthlyIncome"
                              :key="index"
                              @click.stop="
                                searchMonthlyIncome = item.monthlyIncome;
                                selectedMonthlyIncome = item.id;
                                open_MonthlyIncome_dropdown = false;
                              "
                            >
                              {{ item.monthlyIncome }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchMonthlyIncome &&
                            validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- ocupation -->
                    <!-- new workplace heading -->
                    <p class="text-dark-grey mt-4 mb-0">
                      ที่ตั้ง{{ checkTextWorkplace(searchOcupation, "f") }}ใหม่
                    </p>
                    <!-- number and group two input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="margin-right: 70px"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 34px">
                        เลขที่
                        <span class="text-danger">*</span>
                      </p>
                      <div class="name-input" style="margin-top: 28px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <div class="left-right-input d-flex">
                          <div>
                            <input
                              v-model="Address.HouseNO"
                              type="text"
                              class="form-controlv1 two-ipnuts"
                              :class="{
                                error:
                                  !Address.HouseNO &&
                                  validate_change_address === true,
                              }"
                              placeholder="ระบุข้อมูล"
                              @input="onHouseNOInput"
                            />
                            <p
                              v-if="
                                !Address.HouseNO &&
                                validate_change_address === true
                              "
                              style="font-size: 12px"
                              class="error-message mb-0"
                            >
                              กรุณากรอกข้อมูล
                            </p>
                          </div>

                          <!-- เลื่อนไปอยู่ที่นี่ -->
                          <p
                            class="text-dark-grey mt-2 two-inputs-labelMargin"
                            style="margin: 0px 15px 0px 14px"
                          >
                            หมู่
                          </p>
                          <label for="inputPassword2" class="visually-hidden"
                            >ระบุข้อมูล</label
                          >
                          <div>
                            <input
                              v-model="Address.Group"
                              type="text"
                              style="width: 84px !important"
                              class="form-controlv1 two-ipnuts"
                              placeholder="ระบุข้อมูล"
                              @input="onGroupInput"
                            />
                          </div>

                          <!-- และเลื่อนมาที่นี่ -->
                        </div>
                      </div>
                    </div>
                    <!-- village workplace -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        หมู่บ้าน / อาคาร
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.Village"
                        />
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ชั้น
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Floor"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onFloorInput"
                        />
                      </div>
                    </div>
                    <!-- alley input workplace-->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ซอย
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.Alley"
                        />
                      </div>
                    </div>
                    <!-- road input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ถนน
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.Road"
                        />
                      </div>
                    </div>

                    <!-- Country select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ประเทศ
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="CountryDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_Country_dropdown = true;
                          searchCountry = '';
                          clearFullAddress();
                        "
                        @blur="open_Country_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchCountry"
                          :class="{
                            error:
                              !searchCountry &&
                              validate_change_address === true,
                          }"
                          @blur="chkCountry"
                        />
                        <span
                          v-if="searchCountry.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกประเทศ
                        </span>
                        <!-- :value="selectedCountry" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Country_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListCountry"
                              :key="index"
                              @click.stop="getThailand(item)"
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchCountry && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>
                    <!-- / Country select box -->

                    <!-- Province select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        จังหวัด
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="ProvinceDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_Province_dropdown = true;
                          searchProvince = '';
                        "
                        @blur="open_Province_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchProvince"
                          :class="{
                            error:
                              !searchProvince &&
                              validate_change_address === true,
                          }"
                          @blur="chkProvince"
                        />
                        <span
                          v-if="searchProvince.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกจังหวัด
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Province_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListProvince"
                              :key="index"
                              @click.stop="
                                searchProvince = item.name;
                                selectedProvince = item.id;
                                open_Province_dropdown = false;
                                searchDist = '';
                                selectedDist = '';
                                searchSubDist = '';
                                selectedSubDist = '';
                                Address.ZipCode = '';
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchProvince && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- district select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        อำเภอ/ เขต
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="DistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_Dist_dropdown = true;
                          searchDist = '';
                        "
                        @blur="open_Dist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchDist"
                          :class="{
                            error:
                              !searchDist && validate_change_address === true,
                          }"
                          @blur="chkDist"
                        />
                        <span
                          v-if="searchDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอำเภอ/ เขต
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Dist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListDist"
                              :key="index"
                              @click.stop="
                                searchDist = item.name;
                                selectedDist = item.id;
                                open_Dist_dropdown = false;
                                searchSubDist = '';
                                selectedSubDist = '';
                                Address.ZipCode = '';
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="!searchDist && validate_change_address === true"
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- subdistrict select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ตำบล/ แขวง
                        <span class="text-danger">*</span>
                      </p>
                      <div
                        ref="subDistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="
                          open_subDist_dropdown = true;
                          searchSubDist = '';
                        "
                        @blur="open_subDist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchSubDist"
                          :class="{
                            error:
                              !searchSubDist &&
                              validate_change_address === true,
                          }"
                          @blur="chkSubDist"
                        />
                        <span
                          v-if="searchSubDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกตำบล/ แขวง
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_subDist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListSubDist"
                              :key="index"
                              @click.stop="
                                searchSubDist = item.name;
                                selectedSubDist = item.id;
                                open_subDist_dropdown = false;
                                Address.ZipCode = item.zipcode;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <p
                          v-if="
                            !searchSubDist && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- zipcode input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        รหัสไปรษณีย์
                        <span class="text-danger">*</span>
                      </p>

                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.ZipCode"
                          type="text"
                          class="form-controlv1 address-input"
                          placeholder="ระบุข้อมูล"
                          :class="{
                            error:
                              !Address.ZipCode &&
                              validate_change_address === true,
                          }"
                          @input="onZipcCodeInput"
                          @blur="chkZipCode"
                        />
                        <p
                          v-if="
                            !Address.ZipCode && validate_change_address === true
                          "
                          style="font-size: 12px"
                          class="error-message mb-0"
                        >
                          กรุณากรอกข้อมูล
                        </p>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 40px 0px 32px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeAddress('Workplace')"
                      >
                        <span v-if="isLoadingAddress" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="
                          (ModalEditWorkplace = false),
                            (validate_change_address = false)
                        "
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal workplace -->
    <OtpModalSettings
      :OtpResponse="OtpResponse"
      :OtpType="OtpType"
      @OtpResponseUpdate="handleOtpResponseUpdate"
      :OtpValue="OtpValue"
      :OtpError="OtpError"
      :OtpSuccess="OtpSuccess"
      v-bind:refCode.sync="refCode"
      @UpdateRefCode="UpdateRefCode"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { HTTP } from "@/config/axios";
import OtpModalSettings from "./components/OtpModalSettings.vue";
export default Vue.extend({
  components: { OtpModalSettings },
  name: "PersonalInformation",
  data() {
    return {
      validate_change_name: false,
      validate_change_tel: false,
      validate_change_address: false,
      failEditEmail: false,
      isLoading: true,
      coin: [],
      searchTitleNameTh: "",
      searchTitleNameEn: "",
      searchTitleId: "",
      open_titleNameTh_dropdown: false,
      open_titleNameEn_dropdown: false,
      ListTitleName: [],
      //
      ListSubDist: [],
      searchSubDist: "",
      selectedSubDist: "",
      open_subDist_dropdown: false,
      //
      ListDist: [],
      searchDist: "",
      selectedDist: "",
      open_Dist_dropdown: false,
      //
      ListProvince: [],
      searchProvince: "",
      selectedProvince: "",
      open_Province_dropdown: false,
      //
      ListCountry: [],
      searchCountry: "ไทย",
      selectedCountry: "TH",
      open_Country_dropdown: false,
      chkThailand: true,
      //
      ListOcupation: [],
      searchOcupation: "",
      selectedOcupation: 0,
      open_Ocupation_dropdown: false,
      //
      ListBussinesstype: [],
      searchBussinesstype: "",
      selectedBussinesstype: 0,
      open_Bussinesstype_dropdown: false,
      //
      ListMonthlyIncome: [],
      searchMonthlyIncome: "",
      selectedMonthlyIncome: 0,
      open_MonthlyIncome_dropdown: false,
      //
      ModalEditFullName: false,

      showModalOpenCamera: false,
      showModalOpenCamera3: false,

      ModalEditPhoneNumber: false,
      failPhoneNumber: false,
      ModalEditEmail: false,
      ModalEditAddress: false,
      ModalEditAddressIdCard: false,
      ModalEditWorkplace: false,
      user: {
        fullNameTH: "",
        fullNameEN: "",
        realname: "",
        username: "",
        balance: "",
        phone: "",
        email: "",
        addresscurrent: "",
        addressidcard: "",
        placeofwork: "",
        detailofwork: "",
        detailofwork2: "",
      },
      isLoadingPhone: false,
      isLoadingEmail: false,
      isLoadingName: false,
      NewPhone: "",
      NewEmail: "",
      NewNameTh: "",
      NewNameEn: "",
      NewSurNameTh: "",
      NewSurNameEn: "",
      NewLaserCode: "",
      OtpType: "",
      OtpValue: "",
      OtpResponse: false,
      refCode: "",
      OtpError: false,
      OtpSuccess: false,
      Address: {
        HouseNO: "",
        Group: "",
        Village: "",
        Floor: "",
        Alley: "",
        Road: "",
        SubDistrict: "",
        District: "",
        Province: "",
        ZipCode: "",
        WorkName: "",
        JobTitle: "",
        Income: "",
        Occupation: 0,
      },
      JobTitleChk: false,
      JobWorkNameChk: false,
      JobBussinessChk: false,
      laserIdOptions: {
        tokens: {
          A: {
            pattern: /[A-Z]/,
            multiple: false,
            transform: (chr) => chr.toUpperCase(),
          },
        },
      },
      englishOptions: {
        tokens: {
          A: {
            pattern: /[a-zA-Z]/,
            multiple: false,
          },
        },
      },
      // For FaceRecognize System
      cameraFail: false,
      ModalIDCard: false,
      ModalShowPhoto: false,
      ModalIDCard: false,
      ModalSelfi: false,
      CameraStep: 0,
      ModalSimilarity: false,
      NameSimilarity: 0,
      VideoStream: null,
      CameraVideo: null,
      PhotoBase64: null,
      IDCardBase64: null,
      SelfiBase64: null,
      SaveIdCardBase64: null,
      CameraReady: false,
      checkStatusLiveness: true,
      isCalculating: true,
      failEditAddress: false,
      text_error_edit_address: "",
      SelfiGuid: null,
      editAddress: {
        inChangeAddressCurrent: 0,
        inChangeAddressIdCard: 0,
        inChangeAddressWork: 0,
      },
      checkName: {
        firstNameTh: null,
        lastNameTh: null,
        firstNameEn: null,
        lastNameEn: null,
      },
      text_error_email: "",
      BankAccountShow: false,
      BankAccountStatus: "",
      BankAccountcolor: "",
      occupation: "",
      checkOccupation: /นักเรียน|นักศึกษา/,
      validate_change_email: false,
    };
  },
  methods: {
    BacktoStartCamera() {
      this.ModalShowPhoto = false;
      this.CameraStep == 1
        ? (this.ModalSelfi = true)
        : (this.ModalIDCard = true);
      this.PhotoBase64 = null;
      (this.CameraVideoStream = null), (this.CameraVideo = null);
      this.CameraReady = false;
      this.StartCamera();
    },
    NexttoStartCamera() {
      this.CameraStep += 1;
      if (this.CameraStep == 1) {
        this.ModalShowPhoto = false;
        this.ModalSelfi = true;
        //this.IDCardBase64 = this.PhotoBase64;
        (this.CameraVideoStream = null), (this.CameraVideo = null);
        this.CameraReady = false;
        this.PhotoBase64 = null;
        this.StartCamera();
      } else if (this.CameraStep == 2) {
        this.ModalSelfi = false;
        this.ModalIDCard = false;
        this.ModalEditName = false;
        this.FaceRecognize();
        this.ModalSimilarity = true;
      } else if (this.CameraStep == 3) {
        this.ModalSimilarity = true;
      }
    },
    prepareStartCamera() {
      this.CameraStep = 0;
      this.txtVerification = null;
      this.livenessStatus = 0;
      this.StartCamera();
    },
    async StartCamera() {
      try {
        this.CameraVideoStream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1920, height: 1080 },
        });
        this.CameraVideo = this.$refs.camera;
        this.CameraVideo.srcObject = this.CameraVideoStream;
        this.CameraVideo.addEventListener("loadedmetadata", () => {
          this.CameraReady = true;
          console.log("Camera MetaData Loaded");
        });
      } catch (error) {
        console.log("Camera MetaData Loaded" + error);
        this.$notify({
          group: "notification",
          type: "error",
          title: "Unable to Start Camera for Verification",
          text: error,
        });
      }
    },
    StopCamera() {
      if (this.CameraVideoStream) {
        this.CameraVideoStream.getTracks().forEach((track) => track.stop());
      }
    },
    TakePicture() {
      if (
        this.CameraVideo &&
        this.CameraVideoStream &&
        this.CameraReady === true
      ) {
        const canvas = document.createElement("canvas");
        canvas.width = this.CameraVideo.videoWidth;
        canvas.height = this.CameraVideo.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(this.CameraVideo, 0, 0, canvas.width, canvas.height);
        const base64Image = canvas.toDataURL("image/jpeg");
        console.log("TakePicture Base64 image: ", base64Image);

        this.PhotoBase64 = base64Image;
        if (this.CameraVideoStream) {
          this.CameraVideoStream.getTracks().forEach((track) => track.stop());
        }
        console.log("CameraStep", this.CameraStep);
        if (this.CameraStep == 1) {
          this.SelfiBase64 = this.PhotoBase64;
          this.CheckLiveness();
        } else {
          // HTTP.post("/uc/upload/base64", {
          //   fileName: "selfIdCard",
          //   contentType: "image/jpg",
          //   base64: this.processBase64(this.SelfiBase64),
          //   requireAuthen: true,
          // }).then((response) => {
          //   console.log("response", response);
          //   this.IDCardBase64 = response.data.data;
          // });
          this.IDCardBase64 = this.PhotoBase64;
          this.ModalShowPhoto = true;
          this.ModalSelfi = false;
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Unable to Take Picture for Verification",
          text: "Please Wait for the Camera to be Ready.",
        });
      }
    },
    CheckLiveness() {
      let self = this;
      this.checkStatusLiveness = false;
      HTTP.post("/uc/recognize/liveness", {
        imageBase64: this.processBase64(this.SelfiBase64),
      })
        .then(function (response) {
          self.checkStatusLiveness = true;
          if (response.data.code === 0) {
            if (response.data.data?.result == true) {
              self.ModalShowPhoto = true;
              self.ModalSelfi = false;
              self.$notify({
                group: "notification",
                type: "success",
                title: "Liveness Pass",
                text: self.livenessCode(response.data.data?.code),
              });
            } else if (response.data.data?.result == false) {
              self.BacktoStartCamera();
              self.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: self.livenessCode(response.data.data?.code),
              });
            }
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: self.livenessCode(response.data.data?.code),
            });
          }
        })
        .catch(function (error) {
          self.checkStatusLiveness = true;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
    FaceRecognize() {
      let self = this;
      console.log("FaceRecognize");
      this.isCalculating = true;
      this.ModalShowPhoto = false;
      this.NameSimilarity = 0;
      //self.livenessStatus = 1;
      HTTP.post("/uc/recognize/face-recognize-verify", {
        imgSource: this.processBase64(this.SelfiBase64),
        /*
        HTTP.post("/uc/recognize/face-recognize", {
          imgSource: this.processBase64(this.IDCardBase64),
          imgTarget: this.processBase64(this.SelfiBase64),
        */
      })
        .then((response) => {
          if (response.data.code === 0) {
            self.NameSimilarity = response.data.data?.similarity;
            if (self.NameSimilarity > 95) {
              self.ModalShowPhoto = false;
              self.livenessStatus = 1;
              self.txtVerification = true;
              self.isCalculating = false;
              self.saveIdCardPhoto();
              self.saveSelfiePhoto();
            } else {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: response.data.data.reason,
              });
              self.livenessStatus = 2;
              self.ModalShowPhoto = false;
              self.OtpError = true;
              self.OtpValue = self.NameSimilarity;
              self.OtpType = "name";
              self.isCalculating = false;
            }
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        })
        .finally(() => {
          self.isCalculating = false;
        });
    },
    saveIdCardPhoto() {
      let self = this;
      HTTP.post("/uc/upload/base64", {
        fileName: "IdcardVerify",
        contentType: "image/jpg",
        base64: this.processBase64(this.IDCardBase64),
        requireAuthen: false,
      }).then((response) => {
        self.SaveIdCardBase64 = response.data;
      });
    },
    saveSelfiePhoto() {
      let self = this;
      HTTP.post("/uc/upload/base64", {
        fileName: "selfieVerify",
        contentType: "image/jpg",
        base64: this.processBase64(this.SelfiBase64),
        requireAuthen: false,
      }).then((response) => {
        self.SelfiGuid = response.data;
      });
    },
    processBase64(Base64String) {
      const prefix = "data:image/jpeg;base64,";
      if (Base64String.startsWith(prefix)) {
        return Base64String.substring(prefix.length);
      }
    },
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    onHouseNOInput(event) {
      let inputValue = event.target.value;
      let SP = "0123456789/-";
      let out = "";
      let chkSh = 0;
      for (let i1 = 0; i1 < inputValue.length; i1++) {
        let chk1 = false;
        for (let i2 = 0; i2 < SP.length; i2++) {
          if (inputValue[i1] == SP[i2]) {
            chk1 = true;
            if (inputValue[i1] == "/") {
              chkSh++;
            }
          }
        }
        if ((chkSh == 0 || chkSh == 1) && chk1) {
          out += inputValue[i1];
        }
      }
      this.Address.HouseNO = out;
    },
    onGroupInput(event) {
      this.Address.Group = event.target.value.replace(/\D/g, "");
    },
    onFloorInput(event) {
      this.Address.Floor = event.target.value.replace(/[^0-9A-Z]/g, "");
    },
    onZipcCodeInput(event) {
      this.Address.ZipCode = event.target.value.replace(/\D/g, "");
    },
    chkOcupation() {
      setTimeout(() => {
        if (this.searchOcupation.length > 0) {
          let chkOcupation = this.ListOcupation.filter(
            (x) => x.name == this.searchOcupation
          );
          if (chkOcupation.length == 0) {
            this.searchOcupation = "";
            this.selectedOcupation = 0;
          }
        } else if (this.selectedOcupation) {
          let chkOcupation = this.ListOcupation.filter(
            (x) => x.id == this.selectedOcupation
          );
          if (chkOcupation.length > 0) {
            this.searchOcupation = chkOcupation[0].name;
          }
        }
      }, 500);
    },
    chkBussinessType() {
      setTimeout(() => {
        if (this.searchBussinesstype.length > 0) {
          let chkBussinessType = this.ListBussinesstype.filter(
            (x) => x.name == this.searchBussinesstype
          );
          if (chkBussinessType.length == 0) {
            this.searchBussinesstype = "";
            this.selectedBussinesstype = 0;
          }
        } else if (this.selectedBussinesstype) {
          let chkBussinessType = this.ListBussinesstype.filter(
            (x) => x.id == this.selectedBussinesstype
          );
          if (chkBussinessType.length > 0) {
            this.searchBussinesstype = chkBussinessType[0].name;
          }
        }
      }, 500);
    },
    chkMonthlyIncome() {
      setTimeout(() => {
        if (this.searchMonthlyIncome.length > 0) {
          let chkMonthlyIncome = this.ListMonthlyIncome.filter(
            (x) => x.monthlyIncome == this.searchMonthlyIncome
          );
          if (chkMonthlyIncome.length == 0) {
            this.searchMonthlyIncome = "";
            this.selectedMonthlyIncome = 0;
          }
        } else if (this.selectedMonthlyIncome) {
          let chkMonthlyIncome = this.ListMonthlyIncome.filter(
            (x) => x.id == this.selectedMonthlyIncome
          );
          if (chkMonthlyIncome.length > 0) {
            this.searchMonthlyIncome = chkMonthlyIncome[0].monthlyIncome;
          }
        }
      }, 500);
    },
    chkCountry() {
      
      setTimeout(() => {
        if (this.searchCountry.length > 0) {
          let chkCountry = this.ListCountry.filter(
            (x) => x.name == this.searchCountry
          );
          if (chkCountry.length == 0) {
            this.searchCountry = "";
            this.selectedCountry = "";
          } else {
            if(this.searchCountry == 'ไทย' && chkCountry[0].name){
              this.getThailand(chkCountry[0]);
              this.getAllProvinces()
              this.getDistrict()
              this.getSubDistrict()
            } else{
              this.searchProvince = "";
              this.ListProvince = "";
              this.ListDist = ""
              this.ListSubDist = ""
            }
           
          }
        } else if (this.selectedCountry) {
          let chkCountry = this.ListCountry.filter(
            (x) => x.code == this.selectedCountry
          );
          if (chkCountry.length > 0) {
            this.searchCountry = chkCountry[0].name;
          }
        }
      }, 500);
    },
    chkProvince() {
      if (this.chkThailand) {
        setTimeout(() => {
          if (this.searchProvince.length > 0) {
            let chkProvince = this.ListProvince.filter(
              (x) => x.name == this.searchProvince
            );
            if (chkProvince.length == 0) {
              this.searchProvince = "";
              this.selectedProvince = "";
            } else {
              this.selectedProvince = chkProvince[0].id;
            }
          } else if (this.selectedProvince) {
            let chkProvince = this.ListProvince.filter(
              (x) => x.id == this.selectedProvince
            );
            if (chkProvince.length > 0) {
              this.searchProvince = chkProvince[0].name;
            }
          }
        }, 500);
      }
    },
    chkDist() {
      if (this.chkThailand) {
        setTimeout(() => {
          if (this.searchDist.length > 0) {
            let chkDist = this.ListDist.filter(
              (x) =>
                x.name == this.searchDist &&
                x.provinceId == this.selectedProvince
            );
            if (chkDist.length == 0) {
              this.searchDist = "";
              this.selectedDist = "";
            } else {
              this.selectedDist = chkDist[0].id;
            }
          } else if (this.selectedDist) {
            let chkDist = this.ListDist.filter(
              (x) => x.id == this.selectedDist
            );
            if (chkDist.length > 0) {
              this.searchDist = chkDist[0].name;
            }
          }
        }, 500);
      }
    },
    chkSubDist() {
      if (this.chkThailand) {
        setTimeout(() => {
          if (this.searchSubDist.length > 0) {
            let chkSubDist = this.ListSubDist.filter(
              (x) =>
                x.name == this.searchSubDist &&
                x.districtId == this.selectedDist
            );
            if (chkSubDist.length == 0) {
              this.searchSubDist = "";
              this.selectedSubDist = "";
            } else {
              this.selectedSubDist = chkSubDist[0].id;
            }
          } else if (this.selectedSubDist) {
            let chkSubDist = this.ListSubDist.filter(
              (x) => x.id == this.selectedSubDist
            );
            if (chkSubDist.length > 0) {
              this.searchSubDist = chkSubDist[0].name;
            }
          }
        }, 500);
      }
    },
    chkZipCode() {
      if (this.chkThailand) {
        setTimeout(() => {
          let chkZipCode = [];
          if (this.selectedSubDist) {
            chkZipCode = this.ListSubDist.filter(
              (x) =>
                x.zipcode === this.Address.ZipCode &&
                x.id === this.selectedSubDist
            );
          }
          if (chkZipCode.length == 0) {
            this.Address.ZipCode = "";
          }
        }, 500);
      }
    },
    ValidateThaiInput(event, type) {
      let inputValue = event.target.value;
      let cleanedValueEn = inputValue
        .replace(/[^a-zA-Z\s]/g, "")
        .replace(/^\s+/, "")
        .replace(/\s{2,}/g, " ");
      let cleanedValueTh = inputValue
        .replace(/[^\u0E00-\u0E7F\s]/g, "")
        .replace(/(\..*)\./g, "$1")
        .replace(/^\s+/, "")
        .replace(/[๐-๙฿]/g, "")
        .replace(/\s{2,}/g, " ");

      if (type === "nameTh") {
        this.NewNameTh = cleanedValueTh;
      } else if (type === "surnameTh") {
        this.NewSurNameTh = cleanedValueTh;
      } else if (type === "nameEn") {
        this.NewNameEn = cleanedValueEn.toUpperCase();
      } else if (type === "surnameEn") {
        this.NewSurNameEn = cleanedValueEn.toUpperCase();
      }
    },
    validateInput() {
      this.NewPhone = this.NewPhone.replace(/[^0-9]/g, "").slice(0, 10);
    },
    OpenCameraModel() {
      this.ModalEditFullName = !this.ModalEditFullName;
      this.showModalOpenCamera = !this.showModalOpenCamera;
    },
    handleOtpResponseUpdate(Value) {
      if (Value == "OtpSuccess") {
        if (this.OtpType == "Name") {
          this.changeNameSuccess();
        } else if (this.OtpType == "address") {
          this.changeAddressSuccess("current");
        } else if (this.OtpType == "addressidcard") {
          this.changeAddressSuccess("addressidcard");
        } else if (this.OtpType == "workplace") {
          this.changeAddressSuccess("workplace");
        } else {
          this.OtpResponse = false;
          this.OtpSuccess = true;
          this.getMemberInfo();
          //EventBus.$emit("EmailUpdated", this.user.email);
        }
      } else if (Value == "OtpError") {
        this.OtpResponse = false;
        this.OtpError = true;
      } else if (Value == "OTP") {
        this.OtpResponse = false;
        if (this.OtpType == "phone") {
          this.handleModal("Phone", true);
        } else if (this.OtpType == "email") {
          this.handleModal("Email", true);
        } else if (this.OtpType == "Name") {
          this.handleModal("fullName", true);
        }
      } else if (Value == "OTPSuccess") {
        this.OtpSuccess = false;
      } else if (Value == "OTPError") {
        this.OtpError = false;
      }
    },
    getMemberInfo() {
      let self = this;
      HTTP.post("/uc/member/get-member-info")
        .then(function (response) {
          if (response.data.code === 0) {
            let resData = response.data.data;
            let resMember = response.data.data.member;
            let reslockStatus = response.data.data.lockStatus;
            let combinedDataMemberStatus = {
              resMember: resMember,
              reslockStatus: reslockStatus,
            };
            let resWallet = response.data.data.wallets;
            let kycReviewStatus = response.data.data.member.kycReviewStatus;
            let resBasicInfo = resData.userBasicInfo;

            if (resBasicInfo.titleId) {
              let NameTh = self.ListTitleName.filter(
                (x) => x.id == resBasicInfo.titleId
              );
              let NameEn = self.ListTitleName.filter(
                (x) => x.id == resBasicInfo.titleId
              );
              self.user.fullNameTH =
                NameTh[0].nameTh +
                resBasicInfo.firstNameTh +
                " " +
                resBasicInfo.lastNameTh;
              self.user.fullNameEN = (
                NameEn[0].nameEn +
                resBasicInfo.firstNameEn +
                " " +
                resBasicInfo.lastNameEn
              ).toLocaleUpperCase();
              self.isLoading = false;
            } else {
              self.user.fullNameTH =
                resBasicInfo.firstNameTh + " " + resBasicInfo.lastNameTh;
              self.user.fullNameEN =
                resBasicInfo.firstNameEn +
                " " +
                resBasicInfo.lastNameEn.toLocaleUpperCase();
            }
            self.checkName.firstNameTh = resBasicInfo.firstNameTh;
            self.checkName.lastNameTh = resBasicInfo.lastNameTh;
            self.checkName.firstNameEn = resBasicInfo.firstNameEn;
            self.checkName.lastNameEn = resBasicInfo.lastNameEn;

            resMember.fullNameTh = self.user.fullNameTH;
            localStorage.setItem(
              "resMember",
              JSON.stringify(combinedDataMemberStatus)
            );

            self.editAddress.inChangeAddressCurrent =
              resData.inChangeAddressCurrent === 1 ? 1 : 0;
            self.editAddress.inChangeAddressIdCard =
              resData.inChangeAddressIdCard === 1 ? 1 : 0;
            self.editAddress.inChangeAddressWork =
              resData.inChangeAddressWork === 1 ? 1 : 0;

            self.user.realname = resMember.realName;
            self.user.username = resMember.username;
            self.user.phone = resMember.mobilePhone;
            self.user.email = resMember.email;
            self.user.balance = self.$MyFunc.toFixedDigits(
              resWallet[0]["balance"] + resWallet[0]["frozenBalance"],
              2
            );
            self.user.titleName = resBasicInfo.prefixt;
            self.user.districtName = resBasicInfo.currentdistricttext;
            self.user.provinceName = resBasicInfo.currentprovincetext;
            self.user.amphoeName = resBasicInfo.currentamphoetext;
            self.user.districtName = resBasicInfo.currentdistricttext;
            self.user.provinceName = resBasicInfo.currentprovincetext;
            self.user.amphoeName = resBasicInfo.currentamphoetext;
            self.user.idCardDistrictName = resBasicInfo.idcarddistrictext;
            self.user.idCardProvinceName = resBasicInfo.idcardprovincetext;
            self.user.idCardAmphoeName = resBasicInfo.idcardamphoetext;
            self.user.workDistrictName = resBasicInfo.workdistrictext;
            self.user.workProvinceName = resBasicInfo.workprovincetext;
            self.user.workAmphoeName = resBasicInfo.workamphoetext;
            self.user.addresscurrent = resData.currentAddress;
            self.user.addressidcard = resData.idCardAddress;
            self.user.placeofwork = resData.workAddress;

            self.user.detailofwork = "";
            self.user.detailofwork2 = "";

            if (resData.occupation != null) {
              self.user.detailofwork2 += " อาชีพ" + resData.occupation;
              self.occupation = resData.occupation;
            }
            if (resBasicInfo.position != null) {
              self.user.detailofwork2 += " ตำแหน่งงาน" + resBasicInfo.position;
            }
            if (resBasicInfo.monthlyIncome != null) {
              self.user.detailofwork2 +=
                " รายได้ " + resBasicInfo.monthlyIncome + " บาท/เดือน";
            }
            if (resBasicInfo.workplace != null) {
              self.user.detailofwork +=
                " ชื่อ" +
                self.checkTextWorkplace(resData.occupation, "f") +
                resBasicInfo.workplace;
            }
            if (resBasicInfo.businessTypeId) {
              HTTP.post(
                "/uc/member/get-bussinesstype/" + resBasicInfo.occupationId
              )
                .then((response) => {
                  if (response.data.code === 0) {
                    let resBussinesstype = response.data.data.filter(
                      (x) => x.id == resBasicInfo.businessTypeId
                    );
                    if (resBussinesstype[0].id) {
                      self.user.detailofwork +=
                        " ธุรกิจ" + resBussinesstype[0].name;
                    }
                  }
                  self.isLoading = false;
                })
                .catch(function (error) {});
            }
            self.isLoading = false;
          } else if (response.data.code === 4000) {
            self.$store.dispatch("logout");
            self.isLoading = false;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Member Info Successful"
                  : "Unable to Get Current Member Info",
              text: response.data.message,
            });
            self.isLoading = false;
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Info",
            text: error,
          });
        });
    },
    changeName() {
      if (this.validateForm("FullName")) {
        if (this.validateForm("CheckName")) {
          this.laserCodeDopaValidate().then((isValid) => {
            if (isValid) {
              this.ModalIDCard = true;
              this.prepareStartCamera();
            }
          });
        }
      }
    },
    backToChangeNameOTP() {
      this.ModalShowPhoto = false;
      this.ModalSimilarity = false;
      this.ModalIDCard = false;
      this.CameraStep = 0;
      if (this.validateForm("FullName")) {
        this.changeNameOTP();
      }
    },
    laserCodeDopaValidate() {
      return new Promise((resolve, reject) => {
        HTTP.post("/uc/member/check-laser-code", {
          firstName: this.NewNameTh.trim(),
          lastName: this.NewSurNameTh.trim(),
          laserCode: this.NewLaserCode.replace(/-/g, ""),
        })
          .then((response) => {
            if (response.data.code === 0) {
              this.$notify({
                group: "notification",
                type: "success",
                title: "การตรวจสอบรหัสหลังบัตรสำเร็จ",
                text: "ข้อมูลถูกต้อง",
              });
              resolve(true);
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: response.data.message,
              });
              resolve(false);
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the HTTP request
            console.error("Error during HTTP request:", error);
            reject(error);
          });
      });
    },
    changeNameOTP() {
      this.isLoadingName = true;
      if (this.validateForm("FullName")) {
        let self = this;
        HTTP.post("/uc/sendOtp")
          .then(function (response) {
            self.isLoadingName = false;
            if (response.data.code === 0) {
              self.OtpType = "Name";
              self.OtpValue = self.NameSimilarity;
              self.ModalEditFullName = false;
              self.OtpResponse = true;
              self.refCode = response.data.data;
            }
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: response.data.code === 0 ? "Success" : "Error",
              text: response.data.message,
            });
          })
          .catch(function (error) {
            self.isLoadingName = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Change Name Unsuccessful",
              text: error,
            });
          });
      } else {
        this.isLoadingName = false;
      }
    },
    changeNameSuccess() {
      let self = this;
      HTTP.post("/uc/member/change-name", {
        nameTh: this.NewNameTh.trim(),
        lastNameTh: this.NewSurNameTh.trim(),
        nameEn: this.NewNameEn.trim(),
        lastNameEn: this.NewSurNameEn.trim(),
        titleId: this.searchTitleId,
        laserCode: this.NewLaserCode.replace(/-/g, ""),
        imageSelfie: this.SelfiGuid.data,
        imageIdCard: this.SaveIdCardBase64.data,
        faceSimilarity: this.NameSimilarity,
      })
        .then(function (response) {
          if (response.data.code === 0) {
            let resUpdate = response.data.data;
            let NameTh = self.ListTitleName.filter(
              (x) => x.id == resUpdate.titleId
            );
            let NameEn = self.ListTitleName.filter(
              (x) => x.id == resUpdate.titleId
            );
            self.user.fullNameTH =
              NameTh[0].nameTh +
              resUpdate.firstNameTh +
              " " +
              resUpdate.lastNameTh;
            self.user.fullNameEN =
              NameEn[0].nameEn +
              resUpdate.firstNameEn +
              " " +
              resUpdate.lastNameEn;
            self.OtpSuccess = true;
            self.OtpResponse = false;
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Change Name Unsuccessful",
            text: error,
          });
        });
    },
    changePhone() {
      this.isLoadingPhone = true;
      if (this.validateForm("Phone")) {
        let self = this;
        HTTP.post("/uc/member/change-mobile", {
          mobile: this.NewPhone,
        })
          .then(function (response) {
            self.isLoadingPhone = false;
            if (response.data.code === 0) {
              self.OtpType = "phone";
              self.OtpValue = self.NewPhone;
              self.ModalEditPhoneNumber = false;
              self.OtpResponse = true;
              self.refCode = response.data.data.refCode;
            } else {
              // self.ModalEditPhoneNumber = false
              self.text_phone_error = `ไม่สามารถใช้เบอร์นี้ได้<br>
เนื่องจากเบอร์โทรศัพท์นี้ถูกใช้ในระบบแล้ว`;
              self.failPhoneNumber = true;
              //   self.$notify({
              //   group: "notification",
              //   type: response.data.code === 0 ? "success" : "error",
              //   title: response.data.code === 0 ? "Success" : "Error",
              //   text:
              //     response.data.message != ""
              //       ? response.data.message
              //       : "Phone Number Already Exist",
              // });
            }
          })
          .catch(function (error) {
            self.isLoadingPhone = false;
            // self.ModalEditPhoneNumber = false
            self.text_phone_error = `ไม่สามารถใช้เบอร์นี้ได้ <br>เนื่องจากเบอร์โทรศัพท์นี้ถูกใช้ในระบบแล้ว`;
            self.failPhoneNumber = true;
            // self.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: error,
            // });
          });
      } else {
        this.isLoadingPhone = false;
      }
    },
    validateFormatEmail() {
      this.NewEmail = this.NewEmail.replace(
        /[^0-9-A-Za-z\s@._+-]/g,
        ""
      ).replace(/[^\S*$]/g, "");
    },
    changeEmail() {
      if (this.validateForm("Email")) {
        let self = this;
        this.isLoadingEmail = true;
        HTTP.post("/uc/member/change-email", {
          email: this.NewEmail,
        })
          .then(function (response) {
            self.isLoadingEmail = false;
            if (response.data.code === 0) {
              self.OtpType = "email";
              self.OtpValue = self.NewEmail;
              self.ModalEditEmail = false;
              self.OtpResponse = true;
              self.refCode = response.data.data;
              self.$notify({
                group: "notification",
                type: response.data.code === 0 ? "success" : "error",
                title: response.data.code === 0 ? "Success" : "Error",
                text: response.data.message,
              });
            } else {
              self.isLoadingEmail = false;
              self.text_error_email =
                "ไม่สามารถใช้อีเมลนี้ได้ <br> เนื่องจากอีเมลนี้ถูกใช้ในระบบแล้ว";
              self.failEditEmail = true;
            }
          })
          .catch(function (error) {
            self.isLoadingEmail = false;
            self.text_error_email =
              "ไม่สามารถใช้อีเมลนี้ได้ <br>  เนื่องจากอีเมลนี้ถูกใช้ในระบบแล้ว";
            self.failEditEmail = true;
          });
      } else {
        self.isLoadingEmail = false;
      }
    },
    async getTitleName() {
      let self = this;
      await HTTP.post("/uc/member/get-title")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListTitleName = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Change Email Successful"
                  : "Get Title Name Unsuccessful",
              text: response.data.message,
            });
          }
          return true;
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getAllCountry() {
      let self = this;
      HTTP.post("/uc/member/get-all-country")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListCountry = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Change Email Successful"
                  : "Get All Country Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get All Country Unsuccessful",
            text: error,
          });
        });
    },
    getAllProvinces() {
      let self = this;
      HTTP.post("/uc/member/get-all-provinces")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListProvince = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Change Email Successful"
                  : "Get All Provinces Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get All Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getDistrict() {
      let self = this;
      HTTP.post("/uc/member/get-all-districts")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListDist = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Change Email Successful"
                  : "Get Amphures By Provinces Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getSubDistrict() {
      let self = this;
      HTTP.post("/uc/member/get-sub-districts")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListSubDist = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Change Email Successful"
                  : "Get Amphures By Provinces Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getOcupation() {
      let self = this;
      HTTP.post("/uc/member/get-occupation")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListOcupation = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation Unsuccessful",
            text: error,
          });
        });
    },
    getBussinesstype() {
      let self = this;
      HTTP.post("/uc/member/get-bussinesstype/" + this.selectedOcupation)
        .then((response) => {
          if (response.data.code === 0) {
            this.ListBussinesstype = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation Unsuccessful",
            text: error,
          });
        });
    },
    getMonthlyIncome() {
      let self = this;
      HTTP.post("/uc/member/get-monthlyincome")
        .then((response) => {
          if (response.data.code === 0) {
            this.ListMonthlyIncome = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation Unsuccessful",
            text: error,
          });
        });
    },
    validateForm(Type) {
      let valid = true;
      if (Type == "FullName") {
        if (!this.searchTitleId) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดเลือกคำนำหน้า",
          // });
          this.validate_change_name = true;
          valid = false;
        }
        if (!this.NewNameTh) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกชื่อไทย",
          // });
          this.validate_change_name = true;
          valid = false;
        }
        if (!this.NewSurNameTh) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกนามสกุลไทย",
          // });
          this.validate_change_name = true;
          valid = false;
        }
        if (!this.NewNameEn) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกชื่ออังกฤษ",
          // });
          this.validate_change_name = true;
          valid = false;
        }
        if (!this.NewSurNameEn) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกนามสกุลอังกฤษ",
          // });
          this.validate_change_name = true;
          valid = false;
        }
        //LaserCode
        const RGXLaserCode = /^[a-zA-Z]{2}\d{1}-\d{7}-\d{2}$/;
        if (!RGXLaserCode.test(this.NewLaserCode)) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกรหัสหลังบัตรประชาชน(Laser Code)",
          // });
          this.validate_change_name = true;
          valid = false;
        }
      } else if (Type == "Phone") {
        let CheckPhone = this.NewPhone;
        if (!CheckPhone) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกเบอร์โทรศัพท์ใหม่",
          // });
          this.validate_change_tel = true;
          valid = false;
        }
        if (CheckPhone.substring(0, 1) != 0 || !CheckPhone.match(/^\d+$/)) {
          this.validate_change_tel = true;
          valid = false;
        } else if (
          ["0", "1", "2", "3", "4", "5", "7"].includes(
            CheckPhone.substring(1, 2)
          )
        ) {
          this.validate_change_tel = true;
          valid = false;
        } else if (CheckPhone.length !== 10) {
          this.validate_change_tel = true;
          valid = false;
        }

        if (!valid) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "เบอร์โทรศัพท์ใหม่ไม่ถูกต้อง",
          // });
          this.validate_change_tel = true;
          this.failPhoneNumber = true;
          this.text_phone_error = "เบอร์โทรศัพท์ใหม่ไม่ถูกต้อง";
        }
      } else if (Type == "Email") {
        if (!this.NewEmail) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกอีเมลใหม่",
          // });
          this.validate_change_email = true;
          this.text_error_email = "โปรดกรอกอีเมลใหม่";
          this.failEditEmail = true;
          valid = false;
        } else if (this.NewEmail.length < 10) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "อีเมลใหม่ไม่สามารถใช้งานได้",
          // });
          this.validate_change_email = true;
          this.text_error_email = "อีเมลใหม่ไม่สามารถใช้งานได้";
          this.failEditEmail = true;
          valid = false;
        } else if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.NewEmail)
        ) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "รูปแบบอีเมลไม่ถูกต้อง",
          // });
          this.validate_change_email = true;
          this.text_error_email = "รูปแบบอีเมลไม่ถูกต้อง";
          this.failEditEmail = true;
          valid = false;
        } else if (this.NewEmail.endsWith(".con")) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "ไม่อนุญาตให้ใช้ .con ในอีเมล",
          // });
          this.validate_change_email = true;
          this.text_error_email = "ไม่อนุญาตให้ใช้ .con ในอีเมล";
          this.failEditEmail = true;
          valid = false;
        } else if (/^[.-_]/.test(this.NewEmail)) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "รูปแบบอีเมลไม่ถูกต้อง ห้ามกรอกชื่ออีเมลขึ้นต้นด้วยสัญลักษณ์",
          // });
          this.validate_change_email = true;
          this.text_error_email =
            "รูปแบบอีเมลไม่ถูกต้อง ห้ามกรอกชื่ออีเมลขึ้นต้นด้วยสัญลักษณ์";
          this.failEditEmail = true;
          valid = false;
        } else if (/[_]@/.test(this.NewEmail)) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "รูปแบบอีเมลไม่ถูกต้อง ห้ามกรอกชื่ออีเมลก่อนหน้า @ ด้วยสัญลักษณ์",
          // });
          this.validate_change_email = true;
          this.text_error_email =
            "รูปแบบอีเมลไม่ถูกต้อง ห้ามกรอกชื่ออีเมลก่อนหน้า @ ด้วยสัญลักษณ์";
          this.failEditEmail = true;
          valid = false;
        }
      } else if (Type == "Workplace") {
        if (!this.Address.WorkName && this.JobWorkNameChk) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: `โปรดกรอกชื่อ${this.checkTextWorkplace(
          //     this.searchOcupation,
          //     "f"
          //   )}`,
          // });
          valid = false;
        } else if (!this.searchOcupation) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดเลืออาขีพ",
          // });
          valid = false;
        } else if (this.selectedOcupation == 0) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดเลืออาขีพ",
          // });
          valid = false;
        } else if (!this.Address.JobTitle && this.JobTitleChk) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดกรอกตำแหน่งงาน",
          // });
          valid = false;
        } else if (this.selectedBussinesstype == 0 && this.JobBussinessChk) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดเลือกประเภทธุรกิจ",
          // });
          valid = false;
        } else if (this.selectedMonthlyIncome == 0) {
          // this.$notify({
          //   group: "notification",
          //   type: "error",
          //   title: "Error",
          //   text: "โปรดเลือกรายได้",
          // });
          valid = false;
        }
      }
      if (this.chkThailand) {
        if (Type == "Address") {
          if (!this.Address.HouseNO) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณากรอกเลขที่",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกเลขที่"), (valid = false);
          } else if (!this.selectedCountry && Type != "AddressIDCard") {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกประเทศ",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกประเทศ"),
              (valid = false);
          } else if (!this.selectedProvince) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกจังหวัด",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกจังหวัด"),
              (valid = false);
          } else if (!this.selectedDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกอำเภอ/เขต",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกอำเภอ/เขต"),
              (valid = false);
          } else if (!this.selectedSubDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกตำบล/แขวง",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกตำบล/แขวง"),
              (valid = false);
          } else if (!this.Address.ZipCode || this.Address.ZipCode) {
            let chkZipCode = [];
            if (this.selectedSubDist) {
              chkZipCode = this.ListSubDist.filter(
                (x) =>
                  x.zipcode === this.Address.ZipCode &&
                  x.id === this.selectedSubDist
              );
            }
            if (chkZipCode.length == 0) {
              this.Address.ZipCode = "";
              this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกรหัสไปรษณีย์"),
              (valid = false);
            }
           
          }
        }
        // Type == "Workplace" ||
        // Type == "AddressIDCard"
        if (Type == "AddressIDCard") {
          if (!this.Address.HouseNO) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณากรอกเลขที่",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกเลขที่"), (valid = false);
          } else if (!this.selectedCountry && Type != "AddressIDCard") {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกประเทศ",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกประเทศ"),
              (valid = false);
          } else if (!this.selectedProvince) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกจังหวัด",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกจังหวัด"),
              (valid = false);
          } else if (!this.selectedDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกอำเภอ/เขต",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกอำเภอ/เขต"),
              (valid = false);
          } else if (!this.searchSubDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกตำบล/แขวง",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกตำบล/แขวง"),
              (valid = false);
          } else if (!this.Address.ZipCode || this.Address.ZipCode) {
            let chkZipCode = [];
            if (this.selectedSubDist) {
              chkZipCode = this.ListSubDist.filter(
                (x) =>
                  x.zipcode === this.Address.ZipCode &&
                  x.id === this.selectedSubDist
              );
            }
            if (chkZipCode.length == 0) {
              this.Address.ZipCode = "";
              this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกรหัสไปรษณีย์"),
              (valid = false);
            }
          }
        }
        if (Type == "Workplace") {
          if (!this.Address.HouseNO) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณากรอกเลขที่",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกเลขที่"), (valid = false);
          } else if (!this.selectedCountry && Type != "AddressIDCard") {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกประเทศ",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกประเทศ"),
              (valid = false);
          } else if (!this.selectedProvince) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกจังหวัด",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกจังหวัด"),
              (valid = false);
          } else if (!this.selectedDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกอำเภอ/เขต",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกอำเภอ/เขต"),
              (valid = false);
          } else if (!this.searchSubDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณาเลือกตำบล/แขวง",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณาเลือกตำบล/แขวง"),
              (valid = false);
          } else if (!this.Address.ZipCode) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณากรอกรหัสไปรษณีย์",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกรหัสไปรษณีย์"),
              (valid = false);
          } else if (!this.searchMonthlyIncome) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "กรุณากรอกรหัสไปรษณีย์",
            // });
            this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกรหัสไปรษณีย์"),
              (valid = false);
          } else if (!this.searchOcupation) {
            let chkZipCode = [];
            if (this.selectedSubDist) {
              chkZipCode = this.ListSubDist.filter(
                (x) =>
                  x.zipcode === this.Address.ZipCode &&
                  x.id === this.selectedSubDist
              );
            }
            if (chkZipCode.length == 0) {
              this.Address.ZipCode = "";
              this.validate_change_address = true;
            // this.failEditAddress = true,
            (this.text_error_edit_address = "กรุณากรอกรหัสไปรษณีย์"),
              (valid = false);
            }
          }
        }
      } else {
        if (
          Type != "CheckName" &&
          Type != "FullName" &&
          Type != "Email" &&
          Type != "Phone"
        ) {
          if (!this.Address.HouseNO) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "Please input House Number.",
            // });
            valid = false;
          } else if (!this.searchProvince) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "Please enter a province.",
            // });
            valid = false;
          } else if (!this.searchDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "Please enter district.",
            // });
            valid = false;
          } else if (!this.searchSubDist) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "Please enter a subdistrict.",
            // });
            valid = false;
          } else if (!this.Address.ZipCode) {
            // this.$notify({
            //   group: "notification",
            //   type: "error",
            //   title: "Error",
            //   text: "Please enter zip code.",
            // });
            valid = false;
          }
        }
      }
      if (Type == "CheckName") {
        // if (
        //   (this.NewNameTh.trim() == this.checkName.firstNameTh && this.NewSurNameTh.trim() == this.checkName.lastNameTh) ||
        //   (this.NewNameEn.trim() == this.checkName.firstNameEn && this.NewSurNameEn.trim() == this.checkName.lastNameEn)
        // ) {
        // if (
        //   this.NewNameTh.trim() == this.checkName.firstNameTh &&
        //   this.NewNameEn.trim() == this.checkName.firstNameEn &&
        //   this.NewSurNameTh.trim() == this.checkName.lastNameTh &&
        //   this.NewSurNameEn.trim() == this.checkName.lastNameEn
        // ) {

        //   this.$notify({
        //     group: "notification",
        //     type: "error",
        //     title: "Error",
        //     text: "ห้ามกรอก ชื่อ-นามสกุล ภาษาไทยหรืออังกฤษ ซ้ำกัน",
        //   });
        //   valid = false;
        // }
      }
      return valid;
    },
    handleModal(Type, Value) {
      this.OtpResponse = false;
      this.isLoadingPhone = false;
      this.isLoadingEmail = false;
      this.isLoadingAddress = false;
      this.OtpType = "";
      this.OtpValue = "";
      if (Type == "Phone") {
        this.ModalEditPhoneNumber = Value;
        this.NewPhone = "";
      } else if (Type == "Email") {
        this.ModalEditEmail = Value;
        this.NewEmail = "";
      } else if (Type == "FullName") {
        this.ModalEditFullName = Value;
        this.searchTitleNameTh = this.user.titleName;
        this.searchTitleNameEn = this.user.titleName;
        this.NewNameTh = "";
        this.NewSurNameTh = "";
        this.NewNameEn = "";
        this.NewSurNameEn = "";
        this.NewLaserCode = "";
      } else if (Type == "Address") {
        this.ModalEditAddress = Value;
        this.Address.HouseNO = "";
        this.Address.Group = "";
        this.Address.Village = "";
        this.Address.Floor = "";
        this.Address.Alley = "";
        this.Address.Road = "";
        this.Address.SubDistrict = "";
        this.Address.District = "";
        this.Address.Province = "";
        this.Address.Country = "";
        this.Address.ZipCode = "";
        this.selectedDist = "";
        this.selectedProvince = "";
        //this.selectedCountry = "";
        this.selectedSubDist = "";
        this.searchDist = "";
        this.searchProvince = "";
        //this.searchCountry = "";
        this.searchSubDist = "";
      } else if (Type == "AddressIDCard") {
        this.ModalEditAddressIdCard = Value;
        this.Address.HouseNO = "";
        this.Address.Group = "";
        this.Address.Village = "";
        this.Address.Floor = "";
        this.Address.Alley = "";
        this.Address.Road = "";
        this.Address.SubDistrict = "";
        this.Address.District = "";
        this.Address.Province = "";
        this.Address.ZipCode = "";
        this.selectedDist = "";
        this.selectedProvince = "";
        this.selectedSubDist = "";
        this.searchDist = "";
        this.searchProvince = "";
        this.searchSubDist = "";
        //this.chkThailand = true;
      } else if (Type == "Workplace") {
        this.ModalEditWorkplace = Value;
        this.Address.HouseNO = "";
        this.Address.Group = "";
        this.Address.Village = "";
        this.Address.Floor = "";
        this.Address.Alley = "";
        this.Address.Road = "";
        this.Address.SubDistrict = "";
        this.Address.District = "";
        this.Address.Province = "";
        this.Address.Country = "";
        this.Address.ZipCode = "";
        this.Address.WorkName = "";
        this.Address.JobTitle = "";
        this.Address.Income = "";
        this.Address.Occupation = "";
        this.selectedDist = "";
        this.selectedOcupation = 0;
        this.selectedProvince = "";
        //this.selectedCountry = "";
        this.selectedSubDist = "";
        this.searchOcupation = "";
        this.searchDist = "";
        this.searchProvince = "";
        //this.searchCountry = "";
        this.searchSubDist = "";
        this.getOcupation();
        this.getMonthlyIncome();
      }
    },
    changeAddress(Type) {
      this.isLoadingAddress = true;
      let Message = "";
      if (Type == "Address") {
        Message = "OTP for Change Address";
      } else if (Type == "AddressIDCard") {
        Message = "OTP for Change Address on ID Card";
      } else if (Type == "Workplace") {
        Message = "OTP for Change Address of Workplace";
      }
      if (this.validateForm(Type)) {
        let self = this;
        HTTP.post("/uc/sendOtp")
          .then(function (response) {
            self.isLoadingAddress = false;
            if (response.data.code === 0) {
              self.OtpType = Type.toLowerCase();
              self.OtpValue = "";
              if (Type == "Address") {
                self.ModalEditAddress = false;
              } else if (Type == "AddressIDCard") {
                self.ModalEditAddressIdCard = false;
              } else if (Type == "Workplace") {
                self.ModalEditWorkplace = false;
              }
              self.OtpResponse = true;
              self.refCode = response.data.data;
            }
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? Message + " Successful"
                  : Message + " Unsuccessful",
              text: response.data.message,
            });
          })
          .catch(function (error) {
            self.isLoadingAddress = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: Message + " Unsuccessful",
              text: error,
            });
          });
      } else {
        this.isLoadingAddress = false;
      }
    },
    changeAddressSuccess(Type) {
      if (Type == "current") {
        var Data = {
          houseNo: this.Address.HouseNO,
          group: this.Address.Group,
          village: this.Address.Village,
          addressFloor: this.Address.Floor,
          alley: this.Address.Alley,
          road: this.Address.Road,
          subDistrict: this.chkThailand ? this.selectedSubDist : 0,
          district: this.chkThailand ? this.selectedDist : 0,
          province: this.chkThailand ? this.selectedProvince : 0,
          countryCode: this.selectedCountry,
          subdistrictText: this.searchSubDist,
          districtText: this.searchDist,
          provinceText: this.searchProvince,
          countryText: this.searchCountry,
          zipCode: this.Address.ZipCode,
          type: "current",
        };
      }
      if (Type == "addressidcard") {
        var Data = {
          houseNo: this.Address.HouseNO,
          group: this.Address.Group,
          village: this.Address.Village,
          addressFloor: this.Address.Floor,
          alley: this.Address.Alley,
          road: this.Address.Road,
          subDistrict: this.chkThailand ? this.selectedSubDist : 0,
          district: this.chkThailand ? this.selectedDist : 0,
          province: this.chkThailand ? this.selectedProvince : 0,
          countryCode: this.selectedCountry,
          zipCode: this.Address.ZipCode,
          subdistrictText: this.searchSubDist,
          districtText: this.searchDist,
          provinceText: this.searchProvince,
          countryText: this.searchCountry,
          type: "idcard",
        };
      }
      if (Type == "workplace") {
        var Data = {
          houseNo: this.Address.HouseNO,
          group: this.Address.Group,
          village: this.Address.Village,
          addressFloor: this.Address.Floor,
          alley: this.Address.Alley,
          road: this.Address.Road,
          subDistrict: this.chkThailand ? this.selectedSubDist : 0,
          district: this.chkThailand ? this.selectedDist : 0,
          province: this.chkThailand ? this.selectedProvince : 0,
          countryCode: this.selectedCountry,
          zipCode: this.Address.ZipCode,
          nameTh: this.Address.WorkName,
          title: this.Address.JobTitle,
          income: this.selectedMonthlyIncome,
          occupation: this.selectedOcupation,
          bussinessType: this.selectedBussinesstype === 0
          ? null
          : this.selectedBussinesstype,
          subdistrictText: this.searchSubDist,
          districtText: this.searchDist,
          provinceText: this.searchProvince,
          countryText: this.searchCountry,
          type: "workplace",
        };
      }
      let Message = "";
      if (Type == "address") {
        Data.type = "current";
        Message = "Change Address";
      } else if (Type == "addressidcard") {
        Data.type = "idcard";
        Message = "Change Address on ID Card";
      } else if (Type == "workplace") {
        Data.type = "work";
        Message = "Change Address of Workplace";
      }
      let self = this;
      HTTP.post("/uc/member/change-current-address", Data)
        .then(function (response) {
          if (response.data.code === 0) {
            self.OtpSuccess = true;
            self.OtpResponse = false;
            self.getMemberInfo();
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: Message + "Unsuccessful",
            text: error,
          });
        });
    },
    livenessCode(code) {
      if (code == 1) {
        return "successfully took a confirmation photo.";
      } else if (code == 2) {
        return "Confirmation photo did not pass the verification process.";
      } else if (code == 3) {
        return "Incomplete confirmation photo, please take a new one.";
      } else if (code == 4) {
        return "The system has a problem at this time; please try again later.";
      } else {
        return "The system has a problem at this time; please try again later.";
      }
    },
    checkIfClickedOutside(event) {
      const TitleNameThDropdown = this.$refs.titleNameThDropdown;
      const TitleNameEnDropdown = this.$refs.titleNameEnDropdown;
      const subDistDropdown = this.$refs.subDistDropdown;
      const DistDropdown = this.$refs.DistDropdown;
      const ProvinceDropdown = this.$refs.ProvinceDropdown;
      const CountryDropdown = this.$refs.CountryDropdown;
      const OcupationDropdown = this.$refs.ocupationDropdown;
      const BussinesstypeDropdown = this.$refs.bussinesstypeDropdown;
      const MonthlyIncomeDropdown = this.$refs.monthlyIncomeDropdown;
      if (TitleNameThDropdown && !TitleNameThDropdown.contains(event.target)) {
        this.open_titleNameTh_dropdown = false;
      }
      if (TitleNameEnDropdown && !TitleNameEnDropdown.contains(event.target)) {
        this.open_titleNameEn_dropdown = false;
      }
      if (subDistDropdown && !subDistDropdown.contains(event.target)) {
        this.open_subDist_dropdown = false;
      }
      if (DistDropdown && !DistDropdown.contains(event.target)) {
        this.open_Dist_dropdown = false;
      }
      if (ProvinceDropdown && !ProvinceDropdown.contains(event.target)) {
        this.open_Province_dropdown = false;
      }
      if (CountryDropdown && !CountryDropdown.contains(event.target)) {
        this.open_Country_dropdown = false;
      }
      if (OcupationDropdown && !OcupationDropdown.contains(event.target)) {
        this.open_Ocupation_dropdown = false;
      }
      if (
        BussinesstypeDropdown &&
        !BussinesstypeDropdown.contains(event.target)
      ) {
        this.open_Bussinesstype_dropdown = false;
      }
      if (
        MonthlyIncomeDropdown &&
        !MonthlyIncomeDropdown.contains(event.target)
      ) {
        this.open_MonthlyIncome_dropdown = false;
      }
    },
    getThailand(item) {
      this.open_Country_dropdown = false;
      this.searchCountry = item.name;
      this.selectedCountry = item.code;
      this.clearFullAddress();
      if (item.code == "TH") {
        this.chkThailand = true;
      } else {
        this.chkThailand = false;
      }
    },
    clearFullAddress() {
      this.selectedDist = "";
      this.selectedProvince = "";
      this.selectedSubDist = "";
      this.searchDist = "";
      this.searchProvince = "";
      this.searchSubDist = "";
      this.Address.ZipCode = "";
      //this.ListSubDist = [];
      //this.ListDist = [];
      //this.ListProvince = [];
    },
    chkTitleName() {
      setTimeout(() => {
        if (this.searchTitleId) {
          let chkTitleStatus = this.filteredListTitleNameTh.find(
            (x) => x.id == this.searchTitleId
          );
          if (chkTitleStatus?.id) {
            this.searchTitleNameTh = chkTitleStatus?.nameTh;
            this.searchTitleNameEn = chkTitleStatus?.nameEn;
          }
        } else {
          this.searchTitleNameTh = "";
          this.searchTitleNameEn = "";
        }
      }, 500);
    },
    checkTextWorkplace(occupation, type) {
      if (this.checkOccupation.test(occupation)) {
        //f = fulltext
        if (type == "f") {
          return "สถานศึกษา";
        }
        return "ศึกษา";
      }
      return "ที่ทำงาน";
    },
  },
  computed: {
    filteredListTitleNameTh() {
      if (!this.ListTitleName) return [];
      return this.ListTitleName.filter((item) =>
        item.nameTh
          ?.toLowerCase()
          .includes(this.searchTitleNameTh?.toLowerCase() || "")
      );
    },
    filteredListTitleNameEn() {
      if (!this.ListTitleName) return [];
      return this.ListTitleName.filter((item) =>
        item.nameEn
          ?.toLowerCase()
          .includes(this.searchTitleNameEn?.toLowerCase() || "")
      );
    },
    filteredListSubDist() {
      if (!this.ListSubDist) return [];
      if (this.selectedDist == "") {
        return this.ListSubDist.filter((item) =>
          item.name
            ?.toLowerCase()
            .includes(this.searchSubDist?.toLowerCase() || "")
        );
      } else {
        return this.ListSubDist.filter(
          (item) =>
            item.name
              ?.toLowerCase()
              .includes(this.searchSubDist?.toLowerCase() || "") &&
            item.districtId === this.selectedDist
        );
      }
    },
    filteredListDist() {
      if (!this.ListDist) return [];
      if (this.selectedProvince == "") {
        return this.ListDist.filter((item) =>
          item.name
            ?.toLowerCase()
            .includes(this.searchDist?.toLowerCase() || "")
        );
      } else {
        return this.ListDist.filter(
          (item) =>
            item.name
              ?.toLowerCase()
              .includes(this.searchDist?.toLowerCase() || "") &&
            item.provinceId === this.selectedProvince
        );
      }
    },
    filteredListProvince() {
      if (!this.ListProvince) return [];
      return this.ListProvince.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchProvince?.toLowerCase() || "")
      );
    },
    filteredListCountry() {
      if (!this.ListCountry) return [];
      return this.ListCountry.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchCountry?.toLowerCase() || "")
      );
    },
    filteredListOcupation() {
      if (!this.ListOcupation) return [];
      return this.ListOcupation.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchOcupation?.toLowerCase() || "")
      );
    },
    filteredListBussinesstype() {
      if (!this.ListBussinesstype) return [];
      return this.ListBussinesstype.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchBussinesstype?.toLowerCase() || "")
      );
    },
    filteredListMonthlyIncome() {
      if (!this.ListMonthlyIncome) return [];
      return this.ListMonthlyIncome.filter((item) =>
        item.monthlyIncome
          ?.toLowerCase()
          .includes(this.searchMonthlyIncome?.toLowerCase() || "")
      );
    },
  },
  mounted() {
    window.addEventListener("click", this.checkIfClickedOutside);
  },
  created() {
    this.getTitleName().then((res) => {
      this.getMemberInfo();
      this.getAllCountry();
      this.getAllProvinces();
      this.getDistrict();
      this.getSubDistrict();
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkIfClickedOutside);
    if (this.CameraVideoStream) {
      this.CameraVideoStream.getTracks().forEach((track) => track.stop());
    }
  },
});
</script>

<style lang="scss" scoped>
.padding24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.paddingLeft24 {
  padding-left: 24px !important;
}
.inPDis {
  width: 240px !important;

  :disabled {
    background-color: #222b2f;
    color: #d6dde1 !important;
  }
}
.mr-101 {
  margin-right: 101px;
}
.font_sarabun {
  font-family: Sarabun !important;
}
.roboto_flex {
  font-family: Roboto Flex !important;
}
.w-400 {
  font-weight: 400 !important;
}

input {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d6dde1 !important;
  caret-color: #f38220;
  line-height: 24px;
}
.select {
  width: 240px;
  height: 40px;
  background: #222b2f;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #677f8e;
  justify-content: space-between;
  position: relative;
}
.select .options {
  position: absolute;
}
.options {
  position: absolute;
  top: 41px !important;
  width: 100% !important;
  z-index: 1;
  width: 346px;
  background-color: #2c3b44;
  border-radius: 4px;
}
.options ul {
  margin: 0;
  padding: 0;
}
.options ul li {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.options ul li:hover {
  background-color: #2c3b44;
}
.select svg {
  width: 12px;
  height: 8px;
}

table tr td:nth-child(2) p {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.ModalEditAddressIdCard input::placeholder {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.modal-header {
  padding: 0px !important;
  border-bottom: none;
}

.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.bg-box {
  background-color: #121516;
}

.page-heading {
  padding: 24px 0px 21px 50px;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: #d6dde1;
}

.box-personal-info {
  padding: 20px 28px 20px 28px;
  border-radius: 3px;
  width: 359px;
  margin-left: 40px;

  .u-n {
    margin: 0;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
    color: white;
  }
}

.text-box {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 68px;
  height: 40px;
}

/* .text-box:hover {
  color: #ffff;
} */
.width-table {
  margin-left: 45px;
  margin-top: 41px;
}

.table-text {
  color: #9bacb6;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.table-text-white {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.form-controlv1 {
  // border: none !important;
  // outline: none;
  display: block;
  width: 344px !important;
  max-height: 40px !important;
  padding: 8px 8px !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary) !important;
  background: var(--gray-default-state) !important;
  background-clip: padding-box;
  // border: 1px solid var(--gray-default-state) !important;
  appearance: none;
  border-radius: 4px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.table-text-yellow {
  color: #f38220;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.correct-button {
  text-decoration: underline;
  float: right;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}

.border-bottom-end {
  border-bottom: 1px solid #141b1f;
}

.button-camera-open {
  padding-top: 33px;
  // padding-bottom: 80px;
  justify-content: center;
  display: flex;
  /* margin-left: 30px; */
}

.attachment-wrap {
  background-color: #192126;
  /* padding: 48px 150px 48px 182px; */
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 432px;
  height: 120px;
}
.error {
  border: 2px solid red;
}
.error-message {
  color: red;
  margin-top: 5px;
}
.attachment-wrap-skull {
  background-color: #192126;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 432px;
  margin: auto;
  height: 206px;
}

.image-note {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 43px;
  padding-bottom: 20px;
  color: #d6dde1;
}

.note-list {
  color: #9bacb6;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  font-family: "Sarabun" !important;
}

/* modal */
.marginTopBottom32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.modal-title {
  color: #d6dde1;
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 27px;
}

.info-icon {
  margin-left: 3px;
}

.text-grey {
  color: #d6dde1;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.text-yellow {
  color: #f38220;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.text-dark-grey {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0d10;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-mask p {
  line-height: 24px;
}

.pi {
  input::placeholder {
    color: #677f8e;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog {
  // background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}

.custom-checkbox {
  background: #222b2f;
  border: 1px solid #3c515d;
  border-radius: 2px;
  height: 17px;
  width: 17px;
}

.Get-started {
  margin: 20px;
}

.btn-cancel {
  background-color: #222b2f;
}

.input-container {
  position: relative;

  input {
    background-color: #222b2f;
    color: #d6dde1;
    border-radius: 4px;
    height: 40px;
    padding: 0 40px;
    text-transform: capitalize;

    &::placeholder {
      color: #566a76;
    }
  }

  span {
    color: #9bacb6;
    margin-left: -30px;
  }

  .coin-image {
    position: absolute;
    left: 50px;
    top: 6px;
    pointer-events: none;

    img {
      width: 23px;
    }
  }
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.d-options {
  position: absolute;
  top: 45px;
  width: 59%;
  background-color: #222b2f;
  border-radius: 4px;
  z-index: 10;

  img {
    width: 23px;
    margin-left: 26px;
    margin-right: 10px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #2c3b44;
      }
    }
  }
}

.pass-modal {
  text-align: center;

  p {
    margin-bottom: 0;
  }

  .title {
    color: #40994f;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-top: 23px;
    margin-bottom: 30px;
  }

  .ph {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #d6dde1;
    margin-top: 15px;
  }

  .psh {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #d6dde1;
  }

  .pb {
    width: 160px;
    height: 36px;
    background: #222b2f;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin: auto;
    border-radius: 4px;
    margin-top: 46px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}
.oc2 {
  text-align: center;
  margin-top: 17px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  p {
    margin-bottom: 0;
  }
}

/* modal select box address */
.address-input {
  width: 240px !important;
}

.two-ipnuts {
  width: 109px !important;
  height: 40px !important;
}

.two-inputs-labelMargin {
  margin-left: 6px;
  margin-right: 9px;
}

select > option:checked,
select > option:hover {
  /* box-shadow: 0 0 10px 100px #dc6900 inset; */
  color: #f38220 !important;
}

/* select:focus{
    border-color: #f38220;
    outline:none;
} */
select {
  /* padding: 5px; */
  border-radius: 4px;
  height: 40px;
  width: 240px !important;
  color: #ffffff;
  /* padding: 8px 12px; */
  /* padding-right: 30px; */
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("../../../src/assets/images/profile-setting/dropdownChevron.png");
  background-repeat: no-repeat;
  background-position: right 7px top 50%;
}

.modal-padding {
  margin-left: 1rem;
}

/* modal select box end */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-device-width: 768px) {
  .table-text {
    font-size: 10px;
  }

  .table-text-white {
    font-size: 10px;
  }

  .table-text-yellow {
    font-size: 10px;
  }

  .box-personal-info {
    width: 290px !important;
  }

  .text-box {
    font-size: 12px !important;
  }
}

.modal-content {
  border-radius: 8px !important;
  margin-top: 10px !important;
  margin-bottom: 100px !important;
  width: 100% !important;
  height: auto !important;
  background-color: #141b1f !important;
  overflow-x: hidden !important;
  // overflow-y: scroll !important;
  border-radius: 8px !important;
}

.d-dropdown {
  background-color: lightgray !important;
}

.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: 67.5px;
  width: 67.5px;
  padding: 11px;
}
.image-danger-modal {
  // background-color: #277c44;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  padding: 11px;
}
.bg-disabled {
  background-color: #d6dde1 !important;
  opacity: 0.3 !important;
}
</style>
