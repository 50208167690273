<template>
  <div>
    <img
      class="pretest-site-logo"
      :src="require('../../assets/img-fss-logo.png')"
      alt="site-logo"
    />
    <div class="pretest-container">
      <h1>แบบทดสอบความรู้</h1>
      <div class="detail thai-font mt-3">
        <p>กรุณาทำแบบทดสอบความรู้ตามเกณฑ์กลต.</p>
        <ul>
          <li>แบบทดสอบต้องผ่าน 11 จาก 15 ข้อ</li>
          <li>หากไม่ผ่าน สามารถทำแบบทดสอบใหม่ได้ทันที</li>
        </ul>
      </div>
      <div class="questions row thai-font">
        <div class="col-1">1.</div>
        <div class="col-11 q-text">
          คริปโทเคอร์เรนซี ทำงานอยู่บนเทคโนโลยีที่ชื่อว่า “บล็อกเชน” Blockchain
          ทำให้มีความ ปลอดภัยต่ำ ต้นทุนการทำธุรกรรมสูง ไม่มีความเป็นส่วนตัว
          ใช่หรือไม่?
        </div>
        <div class="answer">
          <div class="radio_btn a1">
            <input
              :class="q1 ? 'red' : 'normal'"
              @click="q1 = true"
              type="radio"
              id="option1"
              name="q1"
            />
            <label for="option1">ใช่</label>
          </div>
          <div class="radio_btn a2">
            <input
              :class="q1 ? 'red' : 'normal'"
              @click="q1 = false"
              type="radio"
              id="option2"
              name="q1"
            />
            <label for="option2">ไม่ใช่</label>
          </div>
        </div>
        <div class="correct-answer" v-if="q1">
          คำตอบที่ถูกคือ “ไม่ใช่” เพราะ “บล็อกเชน” Blockchain ทำให้มีความ
          ปลอดภัยต่ำ ต้นทุนการทำธุรกรรมสูง ไม่มีความเป็นส่วนตัว
        </div>
      </div>
      <div class="questions row thai-font">
        <div class="col-1">2.</div>
        <div class="col-11 q-text">
          คริปโทเคอร์เรนซี ทำงานอยู่บนเทคโนโลยีที่ชื่อว่า “บล็อกเชน” Blockchain
          ทำให้มีความ ปลอดภัยต่ำ ต้นทุนการทำธุรกรรมสูง ไม่มีความเป็นส่วนตัว
          ใช่หรือไม่?
        </div>
        <div class="answer">
          <div class="radio_btn a1">
            <input
              :class="q2 ? 'red' : 'normal'"
              @click="q2 = true"
              type="radio"
              id="option3"
              name="q2"
            />
            <label for="option3">ใช่</label>
          </div>
          <div class="radio_btn a2">
            <input
              :class="q2 ? 'red' : 'normal'"
              @click="q2 = false"
              type="radio"
              id="option4"
              name="q2"
            />
            <label for="option4">ไม่ใช่</label>
          </div>
        </div>
        <div class="correct-answer" v-if="q2">
          คำตอบที่ถูกคือ “ไม่ใช่” เพราะ “บล็อกเชน” Blockchain ทำให้มีความ
          ปลอดภัยต่ำ ต้นทุนการทำธุรกรรมสูง ไม่มีความเป็นส่วนตัว
        </div>
      </div>
      <div class="questions row thai-font">
        <div class="col-1">3.</div>
        <div class="col-11 q-text">
          คริปโทเคอร์เรนซี ทำงานอยู่บนเทคโนโลยีที่ชื่อว่า “บล็อกเชน” Blockchain
          ทำให้มีความ ปลอดภัยต่ำ ต้นทุนการทำธุรกรรมสูง ไม่มีความเป็นส่วนตัว
          ใช่หรือไม่?
        </div>
        <div class="answer">
          <div class="radio_btn a1">
            <input
              :class="q3 ? 'red' : 'normal'"
              @click="q3 = true"
              type="radio"
              id="option5"
              name="q3"
            />
            <label for="option5">ใช่</label>
          </div>
          <div class="radio_btn a2">
            <input
              :class="q3 ? 'red' : 'normal'"
              @click="q3 = false"
              type="radio"
              id="option6"
              name="q3"
            />
            <label for="option6">ไม่ใช่</label>
          </div>
        </div>
        <div class="correct-answer" v-if="q3">
          คำตอบที่ถูกคือ “ไม่ใช่” เพราะ “บล็อกเชน” Blockchain ทำให้มีความ
          ปลอดภัยต่ำ ต้นทุนการทำธุรกรรมสูง ไม่มีความเป็นส่วนตัว
        </div>
      </div>
      <div class="f-section">
        <router-link to="/pre-test/pass"><button>ส่งคำตอบ</button></router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      q1: false,
      q2: false,
      q3: false,
    };
  },
};
</script>
<style lang="scss">
.pretest-site-logo {
  margin: auto;
  margin-top: 25px;
  display: block;
}
.pretest-container {
  background-color: #121516;
  border-radius: 4px;
  width: 560px;
  min-height: 588px;
  margin: auto;
  margin-top: 25px;
  padding: 11px 16px 16px 16px;
  line-height: 24px;
  h1 {
    text-align: center;
    color: #d6dde1;
    font-family: "Noto Sans Thai";
    font-weight: 500;
    font-size: 24px;
    padding: 5px 0;
    line-height: 36px;
  }
  .detail {
    color: #9bacb6;
    border-bottom: 1px solid #28363e;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    p {
      margin: 0;
    }
    ul {
      list-style-type: disc;
    }
  }
  .questions {
    color: #d6dde1;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: -11px;
    margin-top: 16px;
    .q-text {
      margin-left: -30px;
      margin-bottom: 9px;
    }
    .answer {
      .radio_btn {
        display: inline-block;
        margin: 2.5px 0;
        label {
          padding-left: 10px;
          margin-bottom: 0;
          font-family: "Sarabun";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }

        input[type="radio"]:after {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          top: -4px;
          right: 4px;
          position: relative;
          background-color: #191b1c;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid #38454d;
        }

        input[type="radio"]:checked:after {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: relative;
          background-color: #d6dde1;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 4px solid #09BE8B;
        }
        .red:checked:after {
          border: 4px solid #de2d40 !important;
        }
        .normal:checked:after {
          border: 4px solid #09BE8B !important;
        }
      }
      .a1 {
        margin-left: 20px;
      }
      .a2 {
        margin-left: 40px;
      }
    }

    .correct-answer {
      width: 90%;
      color: #f72000;
      margin-left: 15px;
    }
  }
  .f-section {
    margin-top: 14px;
    border-top: 1px solid #28363e;
    button {
      display: block;
      width: 89px;
      height: 40px;
      margin: auto;
      margin-top: 32px;
      color: #0a0d10;
      background-color: #09BE8B;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      font-family: "Sarabun";

      &:hover {
        background-color: #66cdb0;
      }
    }
  }
}
</style>
