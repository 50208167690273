<template>
  <div class="wraper-main m-gainloss">
    <div class="Tab-wrapper px-3">
      <ul class="nav nav-pills lh-24">
        <li>
          <a
            class="tabInactive border-raduis-left"
            :class="activeTab == 1 ? 'is-active' : ''"
            @click="onChangeActiveTap(1)"
            style="width: 82px"
          >
          {{ $t('all') }}
          </a>
        </li>
        <li>
          <a
            class="tabInactive"
            :class="activeTab == 2 ? 'is-active' : ''"
            @click="onChangeActiveTap(2)"
            style="width: 82px"
          >
          {{ $t('topgain') }}
          </a>
        </li>
        <li>
          <a
            class="tabInactive border-raduis-right"
            :class="activeTab == 3 ? 'is-active' : ''"
            @click="onChangeActiveTap(3)"
            style="width: 83px"
          >
          {{ $t('toploss') }}
          </a>
        </li>
      </ul>
    </div>
    <div class="mr-0">
      <div class="">
        <div v-if="isLoading == true" class="loader-wrapper">
          <div class="loader"></div>
        </div>
        <div v-else>
          <div v-if="activeTab == 1 && isLoading == false" class="">
            <div
              v-if="
                this.series[0].data.length != 0 &&
                this.series_2[0].data.length != 0
              "
              class="d-flex justify-content-center"
            >
              <div name="start" class="col-0"></div>
              <div
                name="chart1"
                :class="class_chart_1"
                style="padding-right: 0px; padding-left: 0px"
              >
                <apexchart
                  ref="chart"
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_1"
                  :series="series"
                ></apexchart>
              </div>
              <div
                name="chart2"
                :class="class_chart_2"
                style="padding-right: 0px; padding-left: 0px"
              >
                <div style="height: 122px"></div>
                <apexchart
                  ref="chart"
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_2"
                  :series="series_2"
                ></apexchart>
                <!-- <div style="height: 121px"></div> -->
              </div>
              <div name="end" class="col-0"></div>
            </div>

            <div v-else>
              <div v-if="this.series[0].data.length != 0" class="col-12 d-flex justify-content-center">
                <div id="chart3" :class="[class_chart_1, 'mx-auto']">
                  <apexchart
                    type="bar"
                    height="200"
                    :options="chartOptionsdata_1"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
              <div v-if="this.series_2[0].data.length != 0" class="col-12 d-flex justify-content-center">
                <div id="chart4" :class="[class_chart_2, 'mx-auto']">
                  <apexchart
                    type="bar"
                    height="200"
                    :options="chartOptionsdata_2"
                    :series="series_2"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab == 2 && isLoading == false" class="row pad">
            <div
              v-if="this.series[0].data.length != 0"
              class="col-12 d-flex justify-content-center"
            >
              <div id="chart3" :class="[class_chart_1, 'mx-auto']">
                <apexchart
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_1"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>

          <div v-if="activeTab == 3 && isLoading == false" class="row pad">
            <div
              v-if="this.series_2[0].data.length != 0"
              class="col-12 d-flex justify-content-center"
            >
              <div id="chart4" :class="[class_chart_2, 'mx-auto']">
                <apexchart
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_2"
                  :series="series_2"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <Dynamic-Table
        v-if="TableDatas.length > 0"
        :TableHeader="TableHeading"
        :TableData="TableDatas"
        :favCoinRows="favCoinRows"
        :ShowGainLoss="true"
      ></Dynamic-Table>
      <p v-else class="text-center">--ไม่มีข้อมูล--</p>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import Table from "../../components/table/Table.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Gainloss",
  components: {
    apexchart: VueApexCharts,
    "Dynamic-Table": Table,
  },
  data() {
    return {
      class_chart_1: "mx-auto",
      class_chart_2: "mx-auto",
      isLoading: false,
      ac: false,
      data_chart_option: "",
      activeTab: 1,
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price",
          headingHourChange: "24h Change",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
          headingChart: "24h Chart",
        },
      ],
      TableDatas: [],
      favCoinRows: [],
      data_1: [],
      data_2: [],
      series: [
        {
          name: "Cash Flow",
          data: [],
          label: {
            show: true,
            position: "top",
            formatter: function (data) {
              return "Bar #" + (data.dataIndex + 1);
            },
          },
          type: "bar",
        },
      ],
      chartOptions: {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          show: true,
        },
        chart: {
          type: "bar",
          height: 360,
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: "50",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
              { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
          formatter: function (val) {
            return val + " %";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: { show: false },
      },
      series_3: [
        {
          name: "Cash Flow",
          data: [],
          label: {
            show: true,
            position: "top",
            formatter: function (data) {
              return "Bar #" + (data.dataIndex + 1);
            },
          },
          type: "bar",
        },
      ],
      series_2: [
        {
          name: "Cash Flow",
          data: [],
          label: {
            show: true,
            position: "top",
            formatter: function (data) {
              return "Bar #" + (data.dataIndex + 1);
            },
          },
          type: "bar",
        },
      ],
      decimal: 2,
      chartOptions_2: {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          position: "top",
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          suggestedMin: 0,
          beginAtZero: true,
          show: true,
        },
        chart: {
          type: "bar",
          height: 360,
          // width: "50%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "70",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 5000, color: "#40994F" },
                { from: -5000, to: 0, color: "#DE2D40" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          formatter: function (val) {
            return val + " %";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: { show: false },
      },
    };
  },
  methods: {
    async getCoinInfo() {
      let self = this;
      await HTTP.post("/market/symbol-info-all")
        .then((res) => {
          self.coinMarketScale = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      return true;
    },
    async getGainLoss(val) {
      this.isLoading = true;
      await HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let categories = [];
          let chg = [];
          let tableDatas = [];
          this.chartOptions.xaxis.categories = [];
          this.chartOptions_2.xaxis.categories = [];
          this.series_2[0].data = [];
          this.series[0].data = [];
          data.forEach((element) => {
            this.coinMarketScale.forEach((item, index) => {
              if (element.symbol.includes(item.coinSymbol) == true) {
                this.decimal = item.baseCoinScale;
              }
            });

            tableDatas.push({
              StarIcon: "",
              bathScale: this.decimal,
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.volume,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });

          if (val == 2) {
            const gainItems = tableDatas
              .sort((a, b) => b.chg - a.chg)
              .slice(0, 20);
            gainItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              if (x.chg > 0) {
                categories.push(coinName);
                chg.push((x.chg * 100).toFixed(2));
              }
              /*  
              else{
                categories.push("");
                chg.push(0);
              } */
            });
            let d_green = [];
            if (gainItems.length > 0) {
              for (let i = 0; i < gainItems.length; i++) {
                if (gainItems[i].chg > 0) {
                  d_green.push(gainItems[i]);
                }
              }
            }
            let class1 = 0.6 * chg.length;
            var r = Number(class1.toFixed(0)) + 1;
            this.class_chart_1 = `col-${r} mx-auto`;
            this.chartOptions.xaxis.categories = categories;
            this.series[0].data = chg;
            this.TableDatas = d_green;
          } else if (val == 3) {
            const lossItems = tableDatas
              .sort((a, b) => a.chg - b.chg)
              .slice(0, 20)
              .reverse();
            let categories_2 = [];
            let chg_2 = [];
            lossItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];

              // this.chartOptions_2 = {
              //   xaxis: {
              //     ...this.chartOptions_2.xaxis,
              //     categories: [...categories_2],
              //   },
              // };
              if (x.chg < 0) {
                chg_2.push((x.chg * 100).toFixed(2));
                categories_2.push(coinName);
              } /*  else {
                chg_2.push(0);
                categories_2.push("");
              } */
              this.series_2 = [{ data: [...chg_2] }];
            });
            this.chartOptions_2.xaxis.categories = categories_2;
            let d_red = [];
            if (lossItems.length > 0) {
              for (let i = 0; i < lossItems.length; i++) {
                if (lossItems[i].chg < 0) {
                  d_red.push(lossItems[i]);
                }
              }
            }
            let class1 = 0.6 * chg_2.length;
            var r = Number(class1.toFixed(0)) + 1;
            this.class_chart_2 = `col-${r} mx-auto`;
            this.TableDatas = d_red;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    async getGainLoss_2(val) {
      this.isLoading = true;
      var coininfo = await this.getCoinInfo();
      if (coininfo == false) {
        return false;
      }
      this.isLoading = false;
      await HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let categories = [];
          let chg = [];
          let tableDatas = [];
          this.chartOptions.xaxis.categories = [];
          this.chartOptions_2.xaxis.categories = [];
          this.series_2[0].data = [];
          this.series[0].data = [];
          let chg_2 = [];
          this.series_3[0].data = [];

          data.forEach((element) => {
            this.coinMarketScale.forEach((item, index) => {
              if (element.symbol.includes(item.coinSymbol)) {
                this.decimal = item.baseCoinScale;
              }
            });
            tableDatas.push({
              bathScale: this.decimal,
              StarIcon: "",
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.volume,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });

          let categories_2 = [];
          if (val == 0) {
            const gainItems = tableDatas
              .sort((a, b) => b.chg - a.chg)
              .slice(0, 10);
            const lossItems = tableDatas
              .sort((a, b) => a.chg - b.chg)
              .slice(0, 10)
              .reverse();
            this.data_1 = gainItems;
            this.data_2 = lossItems;
            this.TableDatas = tableDatas.sort((a, b) => b.chg - a.chg);

            gainItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              if (x.chg > 0) {
                categories.push(coinName);
                chg.push((x.chg * 100).toFixed(2));
              }
            });

            lossItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              if (x.chg < 0) {
                chg_2.push((x.chg * 100).toFixed(2));
                categories_2.push(coinName);
              }
            });

            this.series[0].data = chg;
            this.chartOptions.xaxis.categories = categories;
            this.series_3[0].data = chg;
            this.chartOptions_2.xaxis.categories = categories_2;
            this.series_2[0].data = chg_2;

            const chartElement = document.getElementsByName("chart1");
            const chartElement2 = document.getElementsByName("chart2");
            const chartElement_start = document.getElementsByName("start");
            const chartElement2_end = document.getElementsByName("end");
            if (chg.length === chg_2.length) {
              let col_c1 = chg.length * 2; // ใช้ multiplier ที่ละเอียดขึ้น
              let col_c2 = chg_2.length * 2;
              let cola = parseFloat(col_c1.toFixed(2)); // ใช้ทศนิยม 2 ตำแหน่ง
              let colb = parseFloat(col_c2.toFixed(2));
              let space = 12 - (cola + colb);
              let haft = space / 2;
              let colstart = parseFloat(haft.toFixed(2)); // ทศนิยม 2 ตำแหน่ง
              let colend = parseFloat(haft.toFixed(2));
              let sum = colstart + cola + colb + colend;

              if (sum > 12) {
                colstart = colstart - 0.01; // ลดขนาดถ้าผลรวมเกิน 12
              }

              this.class_chart_1 = `col-${cola.toString().replace(".", "_")} `;
              this.class_chart_2 = `col-${colb.toString().replace(".", "_")} `;
            } else {
              let col_c1 = chg.length * 2; // ใช้ multiplier ที่ละเอียดขึ้น
              let col_c2 = chg_2.length * 2;
              let cola = parseFloat(col_c1.toFixed(2)); // ใช้ทศนิยม 2 ตำแหน่ง
              let colb = parseFloat(col_c2.toFixed(2));
              let space = 12 - (cola + colb);
              let haft = space / 2;
              let colstart = parseFloat(haft.toFixed(2)); // ทศนิยม 2 ตำแหน่ง
              let colend = parseFloat(haft.toFixed(2));
              let sum = colstart + cola + colb + colend;

              if (sum > 12) {
                colstart = colstart - 0.01; // ลดขนาดถ้าผลรวมเกิน 12
              }

              let result = this.calculateColSizes(chg, chg_2);
              this.class_chart_1 = result.class_chart_1;
              this.class_chart_2 = result.class_chart_2;
            }
          }
          this.isLoading = false;
          // this.$refs.chart.updateOptions(this.chartOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    calculateColSizes(chg, chg_2) {
      let multiplier = 0.8;

      let col_c1 = chg.length * multiplier;
      let col_c2 = chg_2.length * multiplier;

      let cola = parseFloat(col_c1.toFixed(1)); 
      let colb = parseFloat(col_c2.toFixed(1));

      let space = 12 - (cola + colb);
      let haft = space / 2; 
      let colstart = parseFloat(haft.toFixed(1)); 
      let colend = parseFloat(haft.toFixed(1));

      let sum = colstart + cola + colb + colend;

      if (sum > 12) {
        colstart -= sum - 12; 
      } else if (sum < 12) {
        colstart += 12 - sum; 
      }
      let class_chart_1 = `col-${cola.toString().replace(".", "_")} `;
      let class_chart_2 = `col-${colb.toString().replace(".", "_")} `;
      return {
        class_chart_1,
        class_chart_2,
        colstart,
        colend,
      };
    },
    check_chart_2() {
      HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let tableDatas = [];
          data.forEach((element) => {
            tableDatas.push({
              StarIcon: "",
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.volume,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });
          const lossItems = tableDatas
            .sort((a, b) => a.chg - b.chg)
            .slice(0, 10)
            .reverse();
          this.data_2 = lossItems;
          let categories = [];
          let chg = [];
          lossItems.forEach((x) => {
            let coinName = x.Coinsybmol.split("/")[0];
            categories.push(coinName);
            if (x.chg > 0) {
              chg.push(0);
            } else {
              chg.push(x.chg);
            }
          });
          this.chartOptions_2.xaxis.categories = categories;
          this.series_2[0].data = chg;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    check_d() {
      var data_1 = {
        categories: this.chartOptions_2.xaxis.categories,
        chg: this.series_2[0].data,
      };
      // data_1.categories.push("aaaa")
      // data_1.chg.push(-50)
      return data_1;
    },
    check_d_1() {
      var data = {
        categories: this.chartOptions.xaxis.categories,
        chg: this.series[0].data,
      };
      return data;
    },

    onChangeActiveTap(val) {
      if (val == 1) {
        this.isLoading = true;
        this.chartOptions.xaxis.categories = [];
        this.chartOptions_2.xaxis.categories = [];
        this.series_2[0].data = [];
        this.series[0].data = [];
        this.ac = true;
        this.getGainLoss_2(0);
        this.activeTab = val;
      } else if (val == 2) {
        this.ac = false;
        this.activeTab = val;
        this.getGainLoss(2);
      } else {
        this.ac = false;
        this.activeTab = val;
        this.getGainLoss(3);
      }
    },
  },
  mounted() {},
  created() {
    this.getGainLoss_2(0);
  },
  metaInfo() {
    let pageTitle = "Top Gain/Loss";
    return {
      title: pageTitle,
      titleTemplate: "%s | " + this.TITLE,
    };
  },
  computed: {
    chartOptionsdata_1() {
      this.isLoading = true;
      var chartOptions = {
        legend: { show: false },
        tooltip: {
          fillSeriesColor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const xValue = w.globals.labels[dataPointIndex]; // Access X-axis label
            const yValue = series[seriesIndex][dataPointIndex]; // Access Y-axis value

            return (
              `<div class="arrow_box">` +
              `<span class="box" style="color: #398648;"> ${xValue} : +${yValue} %</span>` +
              `<span class="tooltip">This is the tooltip content.</span>` +
              "</div>"
            );
          },
          enabled: false,
          hideEmptySeries: false,
          inverseOrder: false,
        },
        xaxis: {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
          position: "bottom",
          categories: [],
          opposite: false,
          axisTicks: {
            show: false,
          },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          show: false,
        },
        chart: {
          type: "bar",
          height: 200,
          width: "100%",
          redrawOnParentResize: true,
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "55",
            dataLabels: { position: "top" },
            responsive: [
              {
                breakpoint: 768,
                options: {
                  plotOptions: {
                    bar: {
                      columnWidth: "80%",
                    },
                  },
                },
              },
            ],
            colors: {
              ranges: [
              { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          position: "top",
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      };

      var r1 = [];
      r1 = this.check_d_1();
      if (r1.categories.length != 0) {
        chartOptions.xaxis.categories = r1.categories;
      }
      if (r1.chg.length != 0) {
        this.series[0].data = r1.chg;
      }

      this.isLoading = false;
      return chartOptions;
    },
    chartOptionsdata_2() {
      this.isLoading = true;
      var r = [];
      var dataoption_2 = {
        legend: { show: false },
        tooltip: {
          fillSeriesColor: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const xValue = w.globals.labels[dataPointIndex]; // Access X-axis label
            const yValue = series[seriesIndex][dataPointIndex]; // Access Y-axis value
            return (
              `<div class="arrow_box">` +
              `<span class="box" style="color: #f72000;"> ${xValue} : ${yValue} %</span>` +
              `<span class="tooltip"></span>` + // Empty tooltip content
              "</div>"
            );
          },
          enabled: false,
          hideEmptySeries: true,
          inverseOrder: true,
          // marker: {
          //   show: true,
          // },
        },
        xaxis: {
          gridLines: {
            display: false,
          },
          position: "top",
          categories: r,
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          min: -50,
          max: 0,
          ticks: {
            beginAtZero: true,
          },
          labels: {
            show: false,
            style: { colors: "#D6DDE1" },
          },
        },
        chart: {
          type: "bar",
          height: 200,
          width: "100%",
          offsetX: -35,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "60",
            dataLabels: {
              position: "top",
              orientation: "horizontal", // Adjusting the orientation
            },
            responsive: [
              {
                breakpoint: 768, // ค่า breakpoint ที่ต้องการ
                options: {
                  plotOptions: {
                    bar: {
                      columnWidth: "100%", // ตั้งค่าความกว้างของแท่งกราฟให้ตรงกับหน้าจอที่เล็กลง
                    },
                  },
                },
              },
            ],
            colors: {
              ranges: [
              { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
          background: {
            enabled: false,
            opacity: 0.9,
          },
          dropShadow: {
            enabled: true,
            left: 1,
            top: 1,
            opacity: 0.5,
          },
        },
        grid: { show: false },
      };
      dataoption_2.xaxis.categories = [];

      r = this.check_d();
      var last = r.chg[r.chg.length - 1];
      var min_chart2 = -(Math.abs(last) + 10);
      dataoption_2.yaxis.min = min_chart2;
      if (r.categories.length != 0) {
        dataoption_2.xaxis.categories = r.categories;
      }
      if (r.chg.length != 0) {
        this.series_2[0].data = r.chg;
      }
      this.isLoading = false;
      return dataoption_2;
    },
  },
};
</script>

<style lang="scss" scoped>

[class^="col-"] {
  display: inline-block;
  float: left;
}

.col-0_1 { width: 0.83%; }
.col-0_2 { width: 1.67%; }
.col-0_3 { width: 2.50%; }
.col-0_4 { width: 3.33%; }
.col-0_5 { width: 4.17%; }
.col-0_6 { width: 5.00%; }
.col-0_7 { width: 5.83%; }
.col-0_8 { width: 6.67%; }
.col-0_9 { width: 7.50%; }
.col-1_0 { width: 8.33%; }
.col-1_1 { width: 9.17%; }
.col-1_2 { width: 10.00%; }
.col-1_3 { width: 10.83%; }
.col-1_4 { width: 11.67%; }
.col-1_5 { width: 12.50%; }
.col-1_6 { width: 13.33%; }
.col-1_7 { width: 14.17%; }
.col-1_8 { width: 15.00%; }
.col-1_9 { width: 15.83%; }
.col-2_0 { width: 16.67%; }
.col-2_1 { width: 17.50%; }
.col-2_2 { width: 18.33%; }
.col-2_3 { width: 19.17%; }
.col-2_4 { width: 20.00%; }
.col-2_5 { width: 20.83%; }
.col-2_6 { width: 21.67%; }
.col-2_7 { width: 22.50%; }
.col-2_8 { width: 23.33%; }
.col-2_9 { width: 24.17%; }
.col-3_0 { width: 25.00%; }
.col-3_1 { width: 25.83%; }
.col-3_2 { width: 26.67%; }
.col-3_3 { width: 27.50%; }
.col-3_4 { width: 28.33%; }
.col-3_5 { width: 29.17%; }
.col-3_6 { width: 30.00%; }
.col-3_7 { width: 30.83%; }
.col-3_8 { width: 31.67%; }
.col-3_9 { width: 32.50%; }
.col-4_0 { width: 33.33%; }
.col-4_1 { width: 34.17%; }
.col-4_2 { width: 35.00%; }
.col-4_3 { width: 35.83%; }
.col-4_4 { width: 36.67%; }
.col-4_5 { width: 37.50%; }
.col-4_6 { width: 38.33%; }
.col-4_7 { width: 39.17%; }
.col-4_8 { width: 40.00%; }
.col-4_9 { width: 40.83%; }
.col-5_0 { width: 41.67%; }
.col-5_1 { width: 42.50%; }
.col-5_2 { width: 43.33%; }
.col-5_3 { width: 44.17%; }
.col-5_4 { width: 45.00%; }
.col-5_5 { width: 45.83%; }
.col-5_6 { width: 46.67%; }
.col-5_7 { width: 47.50%; }
.col-5_8 { width: 48.33%; }
.col-5_9 { width: 49.17%; }
.col-6_0 { width: 50.00%; }
.col-6_1 { width: 50.83%; }
.col-6_2 { width: 51.67%; }
.col-6_3 { width: 52.50%; }
.col-6_4 { width: 53.33%; }
.col-6_5 { width: 54.17%; }
.col-6_6 { width: 55.00%; }
.col-6_7 { width: 55.83%; }
.col-6_8 { width: 56.67%; }
.col-6_9 { width: 57.50%; }
.col-7_0 { width: 58.33%; }
.col-7_1 { width: 59.17%; }
.col-7_2 { width: 60.00%; }
.col-7_3 { width: 60.83%; }
.col-7_4 { width: 61.67%; }
.col-7_5 { width: 62.50%; }
.col-7_6 { width: 63.33%; }
.col-7_7 { width: 64.17%; }
.col-7_8 { width: 65.00%; }
.col-7_9 { width: 65.83%; }
.col-8_0 { width: 66.67%; }
.col-8_1 { width: 67.50%; }
.col-8_2 { width: 68.33%; }
.col-8_3 { width: 69.17%; }
.col-8_4 { width: 70.00%; }
.col-8_5 { width: 70.83%; }
.col-8_6 { width: 71.67%; }
.col-8_7 { width: 72.50%; }
.col-8_8 { width: 73.33%; }
.col-8_9 { width: 74.17%; }
.col-9_0 { width: 75.00%; }
.col-9_1 { width: 75.83%; }
.col-9_2 { width: 76.67%; }
.col-9_3 { width: 77.50%; }
.col-9_4 { width: 78.33%; }
.col-9_5 { width: 79.17%; }
.col-9_6 { width: 80.00%; }
.col-9_7 { width: 80.83%; }
.col-9_8 { width: 81.67%; }
.col-9_9 { width: 82.50%; }
.col-10_0 { width: 83.33%; }
.col-10_1 { width: 84.17%; }
.col-10_2 { width: 85.00%; }
.col-10_3 { width: 85.83%; }
.col-10_4 { width: 86.67%; }
.col-10_5 { width: 87.50%; }
.col-10_6 { width: 88.33%; }
.col-10_7 { width: 89.17%; }
.col-10_8 { width: 90.00%; }
.col-10_9 { width: 90.83%; }
.col-11_0 { width: 91.67%; }
.col-11_1 { width: 92.50%; }
.col-11_2 { width: 93.33%; }
.col-11_3 { width: 94.17%; }
.col-11_4 { width: 95.00%; }
.col-11_5 { width: 95.83%; }
.col-11_6 { width: 96.67%; }
.col-11_7 { width: 97.50%; }
.col-11_8 { width: 98.33%; }
.col-11_9 { width: 99.17%; }
.col-12_0 { width: 100.00%; }

.Tab-wrapper {
  margin-top: 15px;

  ul {
    li {
      a {
        display: block;
        text-align: center;
      }
    }
  }
}

.border-raduis-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.border-raduis-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.is-active {
  color: #ffffff !important;
  background-color: #38454d !important;
  text-decoration: none;
  // padding: 10px;
}

.tabInactive {
  background-color: #191b1c;
  text-decoration: none;
  height: 40px;
  margin: 1px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pad {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.tabInactive:hover {
  background-color: #38454d;
}

a {
  color: #9bacb6 !important;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 43, 47, 0.7);
  /* สีดำที่โปร่งแสง 70% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* สร้าง loader ที่หมุน */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
