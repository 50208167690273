<template>
  <div class="notification-box">
    <h1>
      Notification
      <span style="font-size: small"
        >({{ unreadNotificationIds.length }}
        {{ unreadNotificationIds.length > 1 ? "messages" : "message" }} )</span
      >
    </h1>
    <!-- notification start -->
    <template v-if="isLoading">
      <b-skeleton
        v-for="(item, index) in 6"
        :key="index"
        class="skc custom-skeleton-wave"
        animation="wave"
        width="100%"
        height="10%"
        style="margin-bottom: 20px"
      ></b-skeleton>
    </template>
    <div
      class="row mt-4 notification"
      v-for="(notification, index) in notifications"
      :key="index"
    >
      <div class="col-1">
        <svg
          :class="notification.read ? 'is-show' : 'not-show'"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" fill="#DE2D40" />
        </svg>
      </div>
      <div class="col-11" style="padding-left: 0;">
        {{ notification.message }}
             <div
        class="col-2 text-right p-0"
        v-if="
          notification.notificationType == 'DEPOSIT_COIN_SET_COST_PRICE' &&
          notification.button
        "
      >
        <button
          class="btn btn-info"
          @click="$router.push('/uc/dw-report/coin')"
        >
          ระบุต้นทุน
        </button>
      </div>
        <div class="col-12 notification-time" style="margin-left: 0">
          {{
            isToday(notification.createdTimestamp)
              ? "วันนี้ | " + formatTime(notification.createdTimestamp)
              : formatDate(notification.createdTimestamp)
          }}
        </div>
      </div>
 
    </div>
    <!-- notification end -->
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import moment from "moment";
export default {
  data() {
    return {
      notifications: [],
      unreadNotificationIds: [],
      isLoading: true,
    };
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (user_data?.id) {
      this.getNotifications();
    }
  },
  methods: {
    getNotifications() {
      let self = this;
      HTTP.get("/uc/member/get-all-member-notification")
        .then((res) => {
          if (res.data.code === 0) {
            this.isLoading = false;
            this.notifications = res.data.data.memberNotifications;
            this.unreadNotificationIds = this.notifications.filter(
              (notification) => !notification.read
            );
            if (this.unreadNotificationIds.length > 0) {
              this.markNotificationsAsRead();
            }
          }else if(res.data.code === 4000) {
            this.$store.dispatch("logout");
            this.$router.push({name: 'login'})
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    markNotificationsAsRead() {
      HTTP.post("/uc/member/read-notification")
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    isToday(createdTimestamp) {
      return moment(createdTimestamp).isSame(moment(), "day");
    },
    formatTime(createdTimestamp) {
      return moment(createdTimestamp).format("HH:mm:ss");
    },
    formatDate(createdTimestamp) {
      return moment(createdTimestamp).format(this.TIMEPICKER + " HH:mm:ss");
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-box {
  width: 610px;
  min-height: 692px;
  background-color: #000000;
  border-radius: 4px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 21px;
  h1 {
    font-family: "Roboto Flex";
    color: #d6dde1;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    padding-left: 1px;
    padding-bottom: 5px;
  }
  .notification-button {
    text-align: right;
    button {
      background-color: #09BE8B;
      width: 83px;
      height: 28px;
      border-radius: 4px;
      font-size: 12px;
      font-family: "Sarabun";
      line-height: 24px;
      position: relative;
      top: -3px;
      color: #0a0d10;
      &:hover {
        background-color: #09BE8B;
      }
    }
  }
  .is-show {
    visibility: hidden;
  }
  .not-show {
    visibility: visible;
  }
  .notification-time {
    color: #9bacb6;
    font-size: 12px;
    margin-left: 12px;
    line-height: 24px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    margin-top: -4px;
  }
  .notification {
    border-bottom: 1px solid #28363e;
    padding-bottom: 12px;
    margin: 0 2px;
    font-family: "sarabun";
    font-weight: 400;
    font-size: 14px;
    p {
      margin: 0;
      line-height: 24px;
      svg {
        margin-right: 12.5px;
      }
    }
  }
}
</style>
