<template>
    <div>
      <b-modal id="TSBC-buy-modal" :hide-footer="true" :hide-header="true">
        <div class="row">
          <div class="col-12 modal-th">
            <h5>
              Order Confirmation
              <div class="h-info-c">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                    fill="#677F8E"
                  />
                </svg>
                <span class="hover-info">Order Confirmation คือ การยืนยันคำสั่งซื้อ</span>
              </div>
            </h5>
            <span
              class="modal-close-button"
              @click="$bvModal.hide('TSBC-buy-modal')"
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </div>
        </div>
        <form @submit.prevent="submit">
          <div class="row body order-confirm">
            <div class="col-12">
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 20px">Coin</p>
                </div>
                <div class="col-8">
                  <span>{{ coinName }}/{{ pairName }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 10px">Order</p>
                </div>
                <div class="col-8"><span class="buy-badge">BUY</span></div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 8px">Price</p>
                </div>
                <div class="col-8">
                  <span
                    >{{ $MyFunc.FormatNumber_notdigit(trailingBuyPrice || 0) }}
                    {{ pairName }} &nbsp; (Trailing Stop)</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 8px">Amount</p>
                </div>
                <div class="col-8">
                  <span>
                    {{ $MyFunc.NumberFormat(trailing_buying_value) }}
                    {{ pairName }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 16px">Total</p>
                </div>
                <div class="col-8">
                  <span>
                    {{ $MyFunc.NumberFormat(trailing_buying_value / trailingBuyPrice, coinscale) }}
                    {{ coinName }}
                  </span>
                </div>
              </div>
              <div class="row mb-3" v-if="!showPin">
                <div class="col-4 m-0 p-0" style="position: relative; top: 5px">
                  <p class="c-title" style="margin-bottom: 16px">PIN</p>
                </div>
                <div class="col-8">
                  <input
                    class="pin-input hide-password-toggle"
                    :type="isShowPin ? 'text' : 'password'"
                    @input="validateInput"
                    required
                    v-model="lbPin"
                    maxlength="6"
                  />
                  <span
                    @click="isShowPin = !isShowPin"
                    style="
                      position: absolute;
                      right: 95px;
                      top: 263px;
                      cursor: pointer;
                    "
                  >
                    <svg
                      v-if="!isShowPin"
                      font-scale="1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                        fill="#677F8E"
                      />
                    </svg>
                    <svg
                      v-else
                      font-scale="1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3_154"
                        style="mask-type: alpha"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3_154)">
                        <path
                          d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                          fill="#677F8E"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              
              <div class="form-group form-check" v-else>
                <label class="checkbox-input">
                  <input type="checkbox" class="form-check-input" @click="FNorderConfirm" />
                  <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                </label>
              </div>
              
              <div class="row buttons-row" style="margin-top: 8px">
                <div class="col-6" style="padding-right: 8px">
                  <button
                    type="submit"
                    class="yellow-btn text-center"
                    :disabled="btn_disable"
                  >
                    ยืนยัน
                  </button>
                </div>
                <div class="col-6" style="padding-left: 8px">
                  <button
                    type="button"
                    class="grey-btn text-center"
                    @click="BuyTrailingCancel()"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </template>
  <script>
  import { mapMutations, mapActions } from "vuex"; 
  export default {
    data() {
      return {
        lbPin: null,
        isShowPin: false,
      };
    },
    watch: {
      lbPin(value) {
        this.$emit("lbPin", value);
      },
    },
    props: [
      "coinName",
      "pairName",
      "trailingBuyPrice",
      "trailing_buying_value",
      "coinscale",
      "btn_disable",
      "showPin",
    ],
    methods: {
      submit() {
        if(this.showPin){
          this.$emit("lbPin", atob(this.showPin));
        }else{
          this.lbPin = "";
        }
        this.$emit("submit", true);
      },
      FNorderConfirm(e){
        this.setChkOrderConfirm(e.target.checked);
        this.updateMemberConfigOrderConfirm(e.target.checked);
      },
      BuyTrailingCancel() {
        this.$bvModal.hide("TSBC-buy-modal");
        this.lbPin = "";
      },
      validateInput() {
        this.lbPin = this.lbPin.replace(/[^0-9]/g, "");
      },
      ...mapMutations(["setChkOrderConfirm"]),
      ...mapActions(["updateMemberConfigOrderConfirm"]),
    }
  };
  </script>

  <style scoped>
  .hide-password-toggle::-ms-reveal {
    display: none;
  }
  .h-info-c {
    position: relative;
    display: inline;
  }
  .h-info-c .hover-info {
    display: none;
    position: absolute;
    z-index: 9999;
    width: 240px;
    left: 130%;
    top: -30%;
    background-color: #d6dde1;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-family: "Sarabun";
    font-style: normal;
    color: #0a0d10;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .h-info-c:hover .hover-info {
    display: flex;
  }
  </style>
  