<!-- <template>
    <div>
        <svg width="357" height="167" viewBox="0 0 357 167" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56.8925" height="52.8119" fill="#8FB3E3"/>
<rect x="61.1602" width="55.4702" height="52.8119" fill="#558ED5"/>
<rect x="119.473" width="56.8925" height="52.8119" fill="#18385F"/>
<rect x="180.633" width="55.4702" height="52.8119" fill="#FDD4B6"/>
<rect x="240.371" width="55.4702" height="52.8119" fill="#FAC090"/>
<rect x="300.105" width="56.8925" height="52.8119" fill="#E46C0B"/>
<rect y="57.0942" width="56.8925" height="52.8119" fill="#E6B8BA"/>
<rect x="61.1602" y="57.0942" width="55.4702" height="52.8119" fill="#DB9796"/>
<rect x="119.473" y="57.0942" width="56.8925" height="52.8119" fill="#963735"/>
<rect x="180.633" y="57.0942" width="55.4702" height="52.8119" fill="#DAE4BF"/>
<rect x="240.371" y="57.0942" width="55.4702" height="52.8119" fill="#C3D79C"/>
<rect x="300.105" y="57.0942" width="56.8925" height="52.8119" fill="#77933C"/>
<rect y="114.188" width="56.8925" height="52.8119" fill="#CDC0DA"/>
<rect x="61.1602" y="114.188" width="55.4702" height="52.8119" fill="#B3A1C7"/>
<rect x="119.473" y="114.188" width="56.8925" height="52.8119" fill="#61497B"/>
<rect x="180.633" y="114.188" width="55.4702" height="52.8119" fill="#B8DEEB"/>
<rect x="240.371" y="114.188" width="55.4702" height="52.8119" fill="#95CEDF"/>
<rect x="300.105" y="114.188" width="56.8925" height="52.8119" fill="#31859D"/>
</svg>

    </div>
</template> -->


<template>
    <div>
        <div class="color-picker">
            <div v-for="(color, idx) in availableColors" :key="idx"
                :style="{ backgroundColor: color }"
                :class="{ selected: color === currentColor }" 
                class="color-box" 
                @click="changeColor(color)"
                >
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
      currentColor: '#8FB3E3', // Set your initial current color here
      availableColors: [
        '#8FB3E3',
        '#558ED5',
        '#18385F',
        '#FDD4B6',
        '#FAC090',
        '#E46C0B',
        '#E6B8BA',
        '#DB9796',
        '#963735',
        '#DAE4BF',
        '#C3D79C',
        '#77933C',
        '#CDC0DA',
        '#B3A1C7',
        '#61497B',
        '#B8DEEB',
        '#95CEDF',
        '#31859D',
      ],
        }
    },
    methods: {
        changeColor(color) {
            this.currentColor = color
            this.$bvModal.hide('cp2')
            this.$emit("change-color", color);
        },
    },
};
</script>
  
<style scoped>
.color-picker {
    display: flex;
    flex-wrap: wrap;
    max-width: 340px;
}

.color-box {
    width: 50px;
    /* Adjust to your preference */
    height: 50px;
    /* Adjust to your preference */
    margin: 3px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.2s ease-in-out;
}

.color-box.selected {
    border-color: #09BE8B;
    /* You can change the border color for the selected color */
}
</style>
  
  