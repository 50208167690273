<template>
  <div class="scroller">
    <table class="table ma-3" id="myTable">
      <thead v-for="(item, index) in TableHeader" :key="index">
        <!-- Heading Coin -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex">
            <div class="align-Icon-Heading">{{ item.headingCoin }}</div>
          </div>
        </th>
        <!-- End Heading Coin -->
        <!-- Heading Actual -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">
              {{ item.Actual }}
              <svg
                @click="sortTable(0)"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-question-circle"
                viewBox="0 0 16 16"
                v-b-tooltip="'จำนวนเหรียญที่มีในพอร์ต'"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </div>
          </div>
        </th>
        <!-- End Heading Actual -->
        <!-- Heading AvrgCost -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.AvrgCost }}</div>
          </div>
        </th>
        <!-- End Heading AvrgCost -->
        <!-- Heading lastPrice -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.lastPrice }}</div>
          </div>
        </th>
        <!-- End Heading lastPrice -->
        <!-- Heading Total -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.Total }}</div>
          </div>
        </th>
        <!-- End Heading Total -->
        <!-- Heading Unrealized -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.Unrealized }}</div>
          </div>
        </th>
        <!-- End Heading Unrealized -->
        <!-- Heading realized -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.realized }}</div>
          </div>
        </th>
        <!-- End Heading realized -->
      </thead>
      <tbody style="position: relative; top: -9px">
        <tr
          class="textDarkgrey-Border"
          v-for="(item, index) in TableData"
          :key="index"
        >
          <!-- {{ index }}: {{ item.lastPrice }} -->
          <!-- coin data without start in gainloss -->
          <td v-if="mycoins || coinheatmap">
            <span class="textGrey Coin-Symbol">
              {{ item.coin.name }}
            </span>
            <span class="textGreyDark-table">
              {{ item.coin.fullName }}
            </span>
          </td>
          <!-- End coin data without star in gainloss -->
          <!-- last ActualValue -->
          <td v-if="mycoins || coinheatmap" class="textGrey text-end">
            {{ item.coin.name=="THB"? $MyFunc.NumberFormat(item.actual, item.baseCoinScale) : $MyFunc.NumberFormat(item.actual,item.coinScale) }}
          </td>
          <!-- End ActualValue -->
          <!-- last AvrgCostValue -->
          <td v-if="mycoins || coinheatmap" class="textGrey text-end">
            {{ $MyFunc.NumberFormat(item.averagePrice, item.baseCoinScale) }}
          </td>
          <!-- End AvrgCostValue -->
          <!-- last lastPriceValue -->
          <td v-if="mycoins || coinheatmap" class="textGrey text-end">
            {{ $MyFunc.NumberFormat(item.lastPrice, item.baseCoinScale) }}
          </td>
          <!-- End lastPriceValue -->
          <!-- last TotalValue -->
          <td v-if="mycoins || coinheatmap" class="textGrey text-end">
            {{ $MyFunc.NumberFormat(item.total, 2) }}
          </td>
          <!-- End TotalValue -->
          <!-- UnrealizedValue  -->
          <td
            v-if="mycoins || coinheatmap"
            class="text-end"
            :class="item.unrealized > 0
                  ? 'textGreen'
                  : item.unrealized < 0 ? 'textRed' : 'textGrey'
              "
          >
            {{ $MyFunc.NumberFormat(item.unrealized, 2) }} ({{ item.unRealizedPercent }}%)
          </td>
          <!--End UnrealizedValue  -->
          <!-- last realizedValue -->
          <td
            v-if="mycoins || coinheatmap"
            class="text-end"
            :class="item.realized > 0
                  ? 'textGreen'
                  : item.realized < 0 ? 'textRed' : 'textGrey'
              "
          >
            {{ $MyFunc.NumberFormat(item.realized, 2) }}  ({{ item.realizedPercent }}%)
          </td>
          <!-- End realizedValue -->
          <!-- buysell button -->
          <td style="text-align: end; vertical-align: middle">
            <span class="textYellow" @click="handleDetail(item)">ดูรายละเอียด</span>
          </td>
          <!-- End BuySell Button -->
        </tr>
      </tbody>
    </table>
    <PortfolioDetailModal :portfolioDetail="pdata" currentCoin="portfolio" />
  </div>
</template>

<script>
import PortfolioDetailModal from "../../exchange/components/PortfolioDetailModal.vue";
export default {
  name: "tablePortfolio",
  components: { PortfolioDetailModal },
  props: ["TableHeader", "TableData", "mycoins", "coinheatmap"],
  data() {
    return {
      child_msg: true,
      pdata: []
    };
  },
  methods: {
    childMethod() {
      this.$emit("child-method", this.child_msg);
    },
    handleBuySell() {
      this.$router.push("/exchange");
    },
    handleDetail(item) {
      this.pdata = item;
      this.$bvModal.show("portfolio-detail");
    },
  },
};
</script>
<style lang="scss" scoped>
.padding-table {
  padding: 15px 18px 0px 18px;
}
.textGreen {
  color: #0c9;
  text-align: center;
  vertical-align: middle;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.textRed {
  color: #ff2500;
  text-align: center;
  vertical-align: middle;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.textYellow {
  color: #09BE8B;
  cursor: pointer;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.textGrey {
  color: rgb(255, 255, 255);
  vertical-align: middle;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.textGreyDark {
  color: #677f8e;
  vertical-align: middle;
  line-height: 14px;
  div {
    margin-bottom: 4px;
  }
}
.textGreyDark-table {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #677f8e;
  vertical-align: top;
}
.textGreyDark-description {
  color: #677f8e;
  vertical-align: middle;
  font-family: "Sarabun";
}
.textDarkgrey-Border {
  border-color: #28363e;
}

.align-Icon-Heading {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #677f8e;
  font-family: "Roboto Flex";
}

.Coin-Symbol {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.question-circle {
  position: relative;
  margin-top: -3px;
  left: 8px;
  cursor: pointer;
}

// %%%  scroller  start %%%%

.scroller {
  min-height: 200px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121516;
}
</style>
