<template>
  <!-- <div class="right-info-tablet thai-font">
    <p style="color: #677f8e">คู่มือแนะนำการทำรายการฝาก</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ QR Code</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-Bank-Transfer.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ Bank Transfer</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการยกเลิกฝาก แบบ QR Code</a
            >
          </li>
        </ul>
  </div> -->
</template>
<script>
export default {};
</script>
<style lang="scss">
.right-info-tablet {
  border-top: 1px solid #28363e;
  margin-left: 40px;
  margin-right: 40px;
  padding-top: 40px;
  color: #677f8e;
  ul {
    list-style: disc;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}
@media (min-width: 1024px) {
  .right-info-tablet {
    display: none;
  }
}
</style>
