<template>
    <div>
      <b-modal id="TSBC-sell-modal" :hide-footer="true" :hide-header="true">
        <div class="row">
          <div class="col-12 modal-th">
            <h5>
              Order Confirmation
              <div class="h-info-c">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                    fill="#677F8E"
                  />
                </svg>
                <span class="hover-info">Order Confirmation คือ การยืนยันคำสั่งขาย</span>
              </div>
            </h5>
            <span
              class="modal-close-button"
              @click="$bvModal.hide('TSBC-sell-modal')"
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </div>
        </div>
        <form @submit.prevent="submit">
          <div class="row body order-confirm">
            <div class="col-12">
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 20px">Coin</p>
                </div>
                <div class="col-8">
                  <span>{{ coinName }}/{{ pairName }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 10px">Order</p>
                </div>
                <div class="col-8"><span class="sell-badge">SELL</span></div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 8px">Price</p>
                </div>
                <div class="col-8">
                  <span
                    >{{ $MyFunc.FormatNumber_notdigit(trailingSellPrice || 0) }}
                    {{ pairName }} &nbsp; (Trailing Stop)</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 8px">Amount</p>
                </div>
                <div class="col-8">
                  <span>
                    {{ $MyFunc.NumberFormat(trailing_selling_value) }}
                    {{ pairName }}</span
                  >
                </div>
              </div>
              <div class="row" v-if="sltrailing">
              <div class="col-4 m-0 p-0">
                <p style="margin-bottom: 8px">StopLoss</p>
              </div>
              <div class="col-8">
                {{ $MyFunc.NumberFormat(trailingSlData.sl) }}
                <span v-if="this.trailingSlData.slPercent > 0"
                  >(-{{ trailingSlData.slPercent }}%)</span
                ><span v-else>THB</span>
              </div>
            </div>
            
              <div class="row">
                <div class="col-4 m-0 p-0">
                  <p style="margin-bottom: 16px">Total</p>
                </div>
                <div class="col-8">
                  <span>
                    {{ $MyFunc.NumberFormat(trailing_selling_value / trailingSellPrice, coinscale) }}
                    {{ coinName }}
                  </span>
                </div>
              </div>
              <div class="row">
              <div class="col-4"></div>
              <div class="col-8">
                <p style="margin-bottom: 16px"><small>*ราคาซื้อ/ขาย ไม่รวม Fee + Vat</small></p>
              </div>
            </div>

            <div class="row mb-3" v-if="!showPin">
              <div class="col-4 m-0 p-0" style="position: relative; top: 5px">
                <p class="c-title" style="margin-bottom: 16px">PIN</p>
              </div>
              <div class="col-8 position-relative">
                <span
                  @click="isShowPin = !isShowPin"
                  class="pin-toggle-icon"
                >
                  <img v-if="isShowPin" src="@/assets/svg/eye-show.svg" role="button">
                  <img v-else src="@/assets/svg/eye-hide.svg" role="button">
                </span>
                <input
                  class="pin-input"
                  required
                  v-model="lbPin"
                  maxlength="6"
                  v-mask="'######'"
                  :type="isShowPin ? 'text' : 'password'"
                />
              </div>
            </div>
              <div class="form-group form-check" v-else>
                <label class="checkbox-input">
                  <input type="checkbox" class="form-check-input" @click="FNorderConfirm" />
                  <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                </label>
              </div>
              
              <div class="row buttons-row" style="margin-top: 8px">
                <div class="col-6" style="padding-right: 8px">
                  <button
                    type="submit"
                    class="yellow-btn text-center"
                    :disabled="btn_disable"
                  >
                    ยืนยัน
                  </button>
                </div>
                <div class="col-6" style="padding-left: 8px">
                  <button
                    type="button"
                    class="grey-btn text-center"
                    @click="SellTrailingCancel()"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </template>
  <script>
  import { mapMutations, mapActions } from "vuex"; 
  export default {
    data() {
      return {
        lbPin: null,
        isShowPin: false,
      };
    },
    watch: {
      lbPin(value) {
        this.$emit("lbPin", value);
      },
    },
    props: [
      "coinName",
      "pairName",
      "sltrailing",
      "trailingSlData",
      "trailingSellPrice",
      "coinscale",
      "trailing_selling_value",
      "btn_disable",
      "showPin",
    ],
    methods: {
      submit() {
        if(this.showPin){
          this.$emit("lbPin", atob(this.showPin));
        }else{
          this.lbPin = "";
        }
        this.$emit("submit", true);
      },
      FNorderConfirm(e){
        this.setChkOrderConfirm(e.target.checked);
        this.updateMemberConfigOrderConfirm(e.target.checked);
      },
      SellTrailingCancel() {
        this.$bvModal.hide("TSBC-sell-modal");
        this.lbPin = "";
      },
      calculatedAllCoin() {
      let pValue = this.sa3 ? Number(this.sa3) : Number(this.sa3p);
      let calcPercent = (this.all_coin * pValue) / 100;
      let balance = parseFloat((this.all_coin * 100 - calcPercent * 100) / 100);
      let coin = parseFloat((this.all_coin * 100 - balance * 100) / 100);
      return this.$MyFunc.mathFloorPrice(parseFloat(coin), 8);
    },
      validateInput() {
        this.lbPin = this.lbPin.replace(/[^0-9]/g, "");
      },
      ...mapMutations(["setChkOrderConfirm"]),
      ...mapActions(["updateMemberConfigOrderConfirm"]),
    },
    mounted() {
  },
  };
  </script>

  <style scoped>
  .hide-password-toggle::-ms-reveal {
    display: none;
  }
  .h-info-c {
    position: relative;
    display: inline;
  }
  .h-info-c .hover-info {
    display: none;
    position: absolute;
    z-index: 9999;
    width: 240px;
    left: 130%;
    top: -30%;
    background-color: #d6dde1;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-family: "Sarabun";
    font-style: normal;
    color: #0a0d10;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .h-info-c:hover .hover-info {
    display: flex;
  }
  </style>
  