<template>
  <div>
    <b-modal
      id="annoumentModal"
      centered
      title="Carousel Modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <VueSlickCarousel
        v-if="processedAnnouncementData.length > 0"
        class="mb-2"
        :arrows="false"
        :dots="true"
        :infinite="true"
        :initialSlide="0"
        :speed="500"
        :slidesToShow="1"
        :slidesToScroll="1"
        :swipeToSlide="true"
        :autoplay="true"
        :autoplaySpeed="3000"
        :pauseOnDotsHover="true"
        :pauseOnFocus="true"
        :pauseOnHover="true"
      >
      <div v-for="(announcement, index) in processedAnnouncementData" :key="index">
    <div>
      <div class="row px-2 pt-3">
        <div class="col">
          <h5>
            {{ announcement.topicTh }}<br />
            {{ announcement.topicEn }}
          </h5>
        </div>
      </div>
      <div class="row px-2 pt-2 content-size">
        <div class="col mx-1 ano" >
          <div v-html="announcement.content" class="announcement-content"></div>
        </div>
      </div>
    </div>
  </div>
      </VueSlickCarousel>

      <b-form>
        <!-- button -->
        <b-form-group class="forgetbtn-group text-center">
          <button
            class="forgetbtn-primary"
            type="button"
            @click="closeAnnoumentModal"
          >
            <span class="text-dark">ปิด</span>
          </button>
        </b-form-group>
        <!-- button -->
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: {
    VueSlickCarousel,
  },
  props: [],
  data() {
    return {
      announcementData: [],
      processedAnnouncementData:[],
    };
  },
  mounted() {
    this.getAnnouncement();
  },
  methods: {
    styleImages() {
      this.$nextTick(() => {
        const contentElements = document.querySelectorAll('.announcement-content');
        contentElements.forEach(contentElement => {
          const images = contentElement.getElementsByTagName('img');
          for (let img of images) {
            const imgWidth = img.width
            if (imgWidth < 300) {
            img.style.display = 'block';
            img.style.margin = '0 auto';
            img.style.height = 'auto';
            // Do not apply maxWidth and minWidth if width is less than 100
          } else {
            img.style.display = 'block';
            img.style.margin = '0 auto';
            img.style.maxWidth = '100%';
            img.style.minWidth = '100%';
            img.style.height = 'auto';
          }
          }
        });
      });
    },
    removeFigureTag(content) {
      // Use a regular expression to remove <figure> and </figure> tags
      return content.replace(/<\/?figure[^>]*>/g, '');
    },
    setImageStyles() {
      // document.querySelectorAll('.image').forEach(el => {
      //   el.style.width = '100%';
      //   el.style.height = '100%';
      // });
    },
    async getAnnouncement() {
      let self = this;
      HTTP.get("/market/announcement")
        .then(function (res) {
          self.announcementData = [...res.data.data];
          self.processedAnnouncementData = self.announcementData
          self.styleImages()
          if (self.announcementData.length > 0) {
            self.$bvModal.show("annoumentModal");
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Announcement Error",
            text: error,
          });
        });
    },
    closeAnnoumentModal() {
      this.$bvModal.hide("annoumentModal");
    },
  },
  watch: {
    'announcement.content': function() {
      this.styleImages();
    }
  },
};
</script>

<style scoped>
.forgetbtn-primary {
  width: 120px;
  height: 36px;
  font-family: "sarabun";
  background-color: #09be8b;
  border-radius: 4px;
  color: #0a0d10;
  font-weight: 500;
}
.forgetbtn-primary:hover {
  background-color: #66cdb0;
}
.forgetbtn-group {
  position: absolute;
  margin-top: 15%;
  padding-bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content-size {
  height: auto;
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 5px;
}
.content-size figure {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%; /* Ensure it takes up the full width of the container */
  height: 0; /* Use padding-bottom to control the height */
  padding-bottom: 65%; /* Adjust to maintain the aspect ratio */
}

.ano {
  display: inline-block; max-width: 100%;padding-right: 0px;
}
.content-size figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust to fit the image within the container */
}
.announcement-content img {
  display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
    height: auto;
}
figure.image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
    height: auto;
}
</style>
