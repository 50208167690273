<template>
  <div>
    <b-modal
      class="forgetPasswordModal"
      id="forgetPasswordModal"
      :hide-footer="true"
      :hide-header="true"
      @show="clearForm"
    >
      <div>
        <div class="modal-container">
          <div class="modalHeading">
            <label class="font-size"> ลืมรหัสผ่าน </label>
            <span @click="close">&times;</span>
          </div>

          <div class="modalcontainer">
            <b-form>
              <b-form-group class="forgetbtn-group">
                <label>อีเมล (Email) เพื่อรับรหัสผ่านใหม่</label>
                <b-form-input
                  type="text"
                  v-model="forgotEmail"
                  placeholder="กรุณาระบุอีเมลที่ได้ลงทะเบียนไว้"
                  class="width-100"
                />
              </b-form-group>

              <!-- button -->
              <b-form-group class="forgetbtn-group">
                <button
                  class="forgetbtn-primary"
                  type="button"
                  @click="forgotPassword"
                  :disabled="isLoading"
                >
                  <span v-if="isLoading" class="text-black">
                    กำลังส่งคำขอ ...
                  </span>
                  <span v-else class="text-black">ยืนยัน</span>
                </button>
              </b-form-group>
              <!-- button -->
            </b-form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { HTTP } from "../../../config/axios";
export default {
  components: {},
  props: [],
  data() {
    return {
      isLoading: false,
      forgotEmail: null,
    };
  },
  methods: {
    forgotPassword() {
      this.isLoading = true;
      if (this.validateForm()) {
        let self = this;
        HTTP.post("/uc/forgot-password", {
          email: this.forgotEmail,
        })
          .then(function (response) {
            self.isLoading = false;
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Forget Password Successful"
                  : "Forget Password Unsuccessful",
              text: response.data.message,
            });
            self.close();

          })
          .catch(function (error) {

            self.isLoading = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Forget Password Unsuccessful",
              text: error,
            });

          });
      } else {
        this.isLoading = false;
      }
    },
    validateForm() {
      let valid = true;
      if (!this.forgotEmail) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Please Enter อีเมล (Email) เพื่อรับรหัสผ่านใหม่ ",
        });
        valid = false;
      }
      return valid;
    },
    clearForm(){
      this.forgotEmail = null;
    },
    close() {
      this.$bvModal.hide("forgetPasswordModal");
    },
  },
};
</script>
<style scoped>
.forgetbtn-primary {
  width: 344px;
  height: 36px;
  font-family: "sarabun";
  background-color: #09BE8B;
  border-radius: 4px;
  color: #0a0d10;
  font-weight: 500;
  &:hover {
    background-color: #66cdb0;
  }
}
.forgetbtn-group {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 40px;
}
.font-size {
  font-size: 18px;
}
.width-100 {
  width: 100%;
}
.text-black {
  color: black !important;
}
</style>
