
<template>
  <div class="news-container">
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container pt-0">
      <div class="tradingview-widget-container__widget pt-0"></div>
      <div class="tradingview-widget-copyright">
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-timeline.js" async>
{
"feedMode": "market",
"market": "crypto",
"isTransparent": true,
"displayMode": "regular",
"width": "100%",
"height": "100%",
"colorTheme": "dark",
"locale": "en"
}
</script>
<script>
import { HTTP } from "@/config/axios";
import moment from "moment";
import Table from "@/components/table/Table.vue";
export default {
  name: "favorite",
  components: {
    "Dynamic-Table": Table,
  },
  data() {
    return {
      currentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      data: [],
    };
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    script.innerHTML = `
    {
      "feedMode": "market",
      "market": "crypto",
      "isTransparent": true,
      "displayMode": "regular",
      "width": "100%",
      "height": "700",
      "colorTheme": "dark",
      "locale": "en"
    }`;
    this.$el.querySelector('.tradingview-widget-container__widget').appendChild(script);
    this.get_news();
  },
  methods: {
    
    format_date_day(value) {
      return moment(this.currentDate).diff(value, "days");
    },
    async get_news() {
      try {
        await HTTP.get("market/announcement/get-all-news").then((response) => {
          if (response.data.code == 0) {
            this.data = response.data.data.resultData;
            if (this.data.length > 0) {
            }
          }
        });
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.news-container {
  margin-top: 34px;
  margin-bottom: 50px;
  .news-item {
    border-bottom: 1px solid #28363e;
    width: 929px;
    margin-left: 64px;
    padding-top: 28px;
    padding-bottom: 41px;
    p {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }
  .news-title {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
  }
  .news-info {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #677f8e;
  }
  .detail-btn {
    float: right;
    color: #d6dde1;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1040px) {
  .news-item {
    width: 650px !important;
  }
}
</style>
