<template>
  <p>AboutUs</p>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: { },
  created() {},
};
</script>
