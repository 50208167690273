<template>
  <div>
    <b-modal
      class="otp-verify-modal"
      id="otp-verify-modal"
      ref="otp-verify-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <!-- centered -->
      <div class="otp-verify-modal">
        <div class="tick-img">
          <img
            src="@/assets/images/wallet/tick-svg.png"
            alt="tick-image"
            width="67px"
          />
        </div>
        <div class="m-center" style="">
          <p class="c-title" style="font-family: 'Noto Sans Thai'">
            ระบบได้รับคำขอถอนเงินแล้ว
          </p>
          <p
            class="c-value thai-font"
            style="line-height: 19px; color: #9bacb6"
          >
            คุณจะได้รับเงินภายใน 24 ชั่วโมง<br />และจะได้รับอีเมลยืนยันเมื่อการถอนเงินสำเร็จ
          </p>
        </div>
        <div class="row">
          <div class="col-5 thai-font">
            <span style="color: #677f8e">ธนาคารที่รับเงิน</span>
          </div>
          <div class="col-7 thai-font" style="color: #d6dde1">
            {{ bData.bankname }}<br />{{ realName }}<br />{{ bData.bankaccno }}
          </div>
        </div>
        <div class="row tp">
          <div class="col-5 thai-font">
            <span style="color: #677f8e">จำนวนเงิน</span>
          </div>
          <div
            class="col-7 font-roboto-flex"
            style="line-height: 20px; color: #d6dde1"
          >
            {{ $MyFunc.NumberFormat(ci) }} THB
          </div>
        </div>
        <div class="row tp">
          <div class="col-5 thai-font">
            <span style="color: #677f8e">ค่าธรรมเนียม</span>
          </div>
          <div
            class="col-7 font-roboto-flex"
            style="line-height: 20px; color: #d6dde1"
          >
           {{ $MyFunc.NumberFormat(bFee,2) }} THB
          </div>
        </div>
        <div class="row tp">
          <div class="col-5 thai-font">
            <span style="color: #677f8e">จำนวนที่ได้รับ</span>
          </div>
          <div
            class="col-7 font-roboto-flex"
            style="line-height: 20px; color: #d6dde1"
          >
            {{ $MyFunc.NumberFormat(withdrawAmount) }} THB
          </div>
        </div>
        <!-- @click="mainPage()" -->
        <button class="f-btn" @click="submitWithdrawCash()">
          กลับหน้าหลัก
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["bData", "realName", "bFee", "withdrawAmount", "ci"],
  methods: {
    mainPage() {
      this.$router.push("/withdraw/cash_main_account");
    },
    submitWithdrawCash() {
      this.$router.push("/exchange");
    },
  },
};
</script>
<style lang="scss">
#otp-verify-modal___BV_modal_content_ {
  background-color: #191b1c;
  width: 409px;
  margin: auto;
  margin-top: 93px !important;
  min-height: 515px;
  border-radius: 15px;
  margin-top: 80px;
  font-family: "Noto Sans Thai";
  #otp-verify-modal___BV_modal_body_ {
    padding: 0px;
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
    }
    .tick-img {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      margin-bottom: 26px;
    }
    .m-center {
      text-align: center;
      border-bottom: 1px solid #28363e;
      padding-bottom: 24px;
      margin-bottom: 24px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      p {
        margin: 0;
      }
      .c-title {
        color: #d6dde1;
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .c-value {
        color: #677f8e;
      }
    }
    .f-btn {
      width: 163px !important;
      height: 55px !important;
      display: block;
      margin: auto;
      border-radius: 4px;
      color: white;
      font-size: 16px;
      font-weight: 700;
      margin-top: 40px;
      background-color: #191b1c;
    }
  }
}
</style>
<style scoped>
.col-5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #677f8e;
}
.col-5 > span {
  width: 90px;
  display: block;
  margin-left: auto;
}
.col-7 {
  line-height: 19px;
}
.tp {
  margin-top: 9px;
}
</style>