<template>
  <div class="wrapper">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h1 class="page-heading">PIN / Password</h1>
          </div>
          <div v-if="isLoading">
            <div class="loader-wrapper"><div class="loader"></div></div>
          </div>
          <div v-else>
            <div class="row m-0">
              <div class="col-10 p-0">
                <table class="table width-table">
                  <thead>
                    <th scope="col"></th>
                  </thead>
                  <tbody>
                    <tr class="telephone-number border-bottom-table">
                      <td class="table-text p-0">
                        <p class="mb-0" style="margin-bottom: 20px">
                          เปลี่ยน PIN
                        </p>
                      </td>
                      <td style="width: 7%" class="p-0">
                        <p
                          class="table-text-yellow correct-button"
                          style="margin-bottom: 20px"
                          @click="handleModal('Pin', 'true')"
                        >
                          แก้ไข
                        </p>
                      </td>
                    </tr>
                    <tr class="telephone-number border-bottom-table">
                      <td class="table-text p-0">
                        <p style="margin: 20px 0px">เปลี่ยน Password</p>
                      </td>
                      <td style="width: 7%" class="p-0">
                        <p
                          class="table-text-yellow correct-button"
                          style="margin: 20px 0px"
                          @click="handleModal('Password', 'true')"
                        >
                          แก้ไข
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal pin-->
    <div v-if="ModalEditPin">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" style="background-color: #0a0d10">
            <div class="modal-dialog" role="document">
              <div
                class="modal-content"
                style="border-radius: 8px; margin-top: 0 !important"
              >
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยน PIN
                            <!-- <span>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditPin = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body p-0">
                  <div class="container" style="padding: 0px 36px 0px 24px">
                    <!-- Original pin input -->
                    <div
                      class="wraper-input d-flex justify-content-between align-items-center"
                      style="width: 100%"
                    >
                      <p
                        class="text-dark-grey mb-0"
                        style="width: 144px; color: rgb(255, 255, 255)"
                      >
                        PIN ปัจจุบัน
                      </p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุ</label
                        >
                        <input
                          :type="showPin ? 'text' : 'password'"
                          v-model="OldPin"
                          :class="{ error: !OldPin && validate_change_pin }"
                          class="form-controlv1 pinInput-width"
                          placeholder="ระบุ"
                          maxlength="6"
                          @input="onPinInput($event, 'OLD')"
                        />
                        <span
                          @click="showPin = !showPin"
                          style="
                            position: absolute;
                            right: 55px;
                            top: 9px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPin && validate_change_pin"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>

                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <!-- new pin input -->
                    <div
                      class="wraper-input d-flex justify-content-between align-items-center"
                      style="width: 100%; margin-top: 16px"
                    >
                      <p
                        class="text-dark-grey mt-2 mb-0"
                        style="width: 144px; color: #d6dde1"
                      >
                        PIN ใหม่
                      </p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >PIN ใหม่</label
                        >
                        <input
                          :type="showPinNew ? 'text' : 'password'"
                          v-model="NewPin"
                          :class="{ error: !NewPin && validate_change_pin }"
                          maxlength="6"
                          class="form-controlv1 pinInput-width"
                          placeholder="กรอกตัวเลข 6 หลัก"
                          @input="onPinInput($event, 'NEW')"
                        />
                        <span
                          @click="showPinNew = !showPinNew"
                          style="
                            position: absolute;
                            right: 55px;
                            top: 65px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPinNew"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>

                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <!-- confirm new pin input -->
                    <div
                      class="wraper-input d-flex justify-content-between align-items-center"
                      style="width: 100%; margin-top: 16px"
                    >
                      <p
                        class="text-dark-grey mt-2 mb-0"
                        style="width: 144px; color: #d6dde1"
                      >
                        ยืนยัน PIN ใหม่
                      </p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >PIN ใหม่</label
                        >
                        <input
                          :type="showPinNewConfirm ? 'text' : 'password'"
                          v-model="ConfirmPin"
                          :class="{ error: !ConfirmPin && validate_change_pin }"
                          maxlength="6"
                          class="form-controlv1 pinInput-width"
                          placeholder="กรอกตัวเลข 6 หลัก"
                          @input="onPinInput($event, 'CONFIRM')"
                        />
                        <span
                          @click="showPinNewConfirm = !showPinNewConfirm"
                          style="
                            position: absolute;
                            right: 55px;
                            top: 121px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPinNewConfirm"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>

                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 32px 0px 32px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changePin"
                        style="
                          height: 40px;
                          width: 240px;
                          margin-left: 12px;
                          font-weight: 700px;
                        "
                      >
                        <span v-if="isLoadingPin" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <!-- <button
                        type="button"
                        style="height: 40px; width: 68px; color: white"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        @click="ModalEditPin = false"
                      >
                        ยกเลิก
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- end modal pin -->

    <!-- modal change password-->

    <div v-if="ModalEditPassword">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" style="padding-top: 0px;background-color: rgb(10, 13, 16);">
            <div class="modal-dialog" role="document">
              <div
                class="modal-content"
                style="border-radius: 8px; margin-top: 0 !important"
              >
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยน Password
                            <!-- <span>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditPassword = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div class="container" style="padding: 30px; padding-top: 0">
                    <!-- old password input -->
                    <div
                      class="wraper-input d-flex justify-content-between align-items-center"
                      style="width: 100%"
                    >
                      <p
                        class="text-dark-grey mb-0"
                        style="width: 144px; color: #d6dde1"
                      >
                        รหัสผ่านปัจจุบัน
                      </p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <b-form-input
                          :type="showPassword ? 'text' : 'password'"
                          style="margin-top: 5px"
                          v-model="OldPassword"
                          :class="{ error: !OldPassword }"
                          class="form-controlv1 pinInput-width"
                          placeholder="ระบุข้อมูล"
                          :formatter="validateInput"
                          :maxlength="
                            passwordMaxLength ? passwordMaxLength : ''
                          "
                        >
                        </b-form-input>
                        <span
                          @click="showPassword = !showPassword"
                          style="
                            position: absolute;
                            right: 60px;
                            top: 12px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPassword"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>

                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <!-- new password input -->
                    <div
                      class="wraper-input d-flex justify-content-between align-items-center"
                      style="width: 100%; margin-top: 16px"
                    >
                      <p
                        class="text-dark-grey mb-0"
                        style="width: 144px; color: #d6dde1"
                      >
                        รหัสผ่านใหม่
                      </p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <b-form-input
                          :type="showPasswordNew ? 'text' : 'password'"
                          v-model="NewPassword"
                          :class="{ error: !NewPassword }"
                          class="form-controlv1 pinInput-width"
                          placeholder="ระบุข้อมูล"
                          :formatter="validateInput"
                          :maxlength="
                            passwordMaxLength ? passwordMaxLength : ''
                          "
                        >
                        </b-form-input>
                        <span
                          @click="showPasswordNew = !showPasswordNew"
                          style="
                            position: absolute;
                            right: 60px;
                            top: 70px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPasswordNew"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>

                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <!-- confirm new password input -->
                    <div
                      class="wraper-input d-flex justify-content-between align-items-center"
                      style="width: 100%; margin-top: 16px"
                    >
                      <p
                        class="text-dark-grey mb-0"
                        style="width: 144px; color: rgb(255, 255, 255)"
                      >
                        ยืนยันรหัสผ่านใหม่
                      </p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <b-form-input
                          :type="showPasswordNewConfirm ? 'text' : 'password'"
                          v-model="ConfirmPassword"
                          :class="{ error: !ConfirmPassword }"
                          class="form-controlv1 pinInput-width"
                          placeholder="ระบุข้อมูล"
                          :formatter="validateInput"
                          :maxlength="
                            passwordMaxLength ? passwordMaxLength : ''
                          "
                        >
                        </b-form-input>
                        <span
                          @click="
                            showPasswordNewConfirm = !showPasswordNewConfirm
                          "
                          style="
                            position: absolute;
                            right: 60px;
                            top: 124px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPasswordNewConfirm"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>

                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="showPasswordRules.length > 0"
                      class="wraper-list"
                      style="margin-top: 40px"
                    >
                      <p class="text-dark-grey" style="margin-bottom: 0">
                        หมายเหตุ:
                      </p>
                      <ul>
                        <li
                          v-for="(rule, index) in showPasswordRules"
                          :key="index"
                        >
                          {{ rule }}
                        </li>
                      </ul>
                    </div>

                    <!-- modal button bottom -->
                    <div class="button text-center" style="margin-top: 28px">
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changePassword"
                        style="width: 240px; height: 40px"
                      >
                        <span v-if="isLoadingPassword" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="failEditPin">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper" style="z-index: 999">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div class="checkTick">
                          <img
                            style="padding-top: 25px"
                            class="image-danger-modal"
                            src="../../assets/images/wallet/cross.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message pb-0">
                          <p
                            class="text-grey"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 600;
                              font-size: 20px;
                              line-height: 36px;
                            "
                          >
                            {{ this.text_error }}
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="updateFailEditPin()"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- end modal change password -->
    <OtpModalSettings
      :OtpResponse="OtpResponse"
      :OtpType="OtpType"
      @OtpResponseUpdate="handleOtpResponseUpdate"
      :OtpValue="OtpValue"
      :OtpError="OtpError"
      :OtpSuccess="OtpSuccess"
      :refCode="refCode"
      @UpdateRefCode="UpdateRefCode"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { HTTP } from "../../config/axios";
import OtpModalSettings from "./components/OtpModalSettings.vue";
import EventBus from "../../event-bus";
export default Vue.extend({
  components: { OtpModalSettings },
  name: "PinPassword",
  data: function () {
    return {
      failEditPin: false,
      isLoading: true,
      user: {
        phone: "",
        email: "",
      },
      OtpType: "",
      OtpValue: "",
      OtpResponse: false,
      refCode: "",
      OtpError: false,
      OtpSuccess: false,
      NewPassword: "",
      OldPassword: "",
      ConfirmPassword: "",
      NewPin: "",
      OldPin: "",
      ConfirmPin: "",
      isLoadingPin: "",
      isLoadingPassword: "",
      ModalEditPin: false,
      ModalEditPassword: false,
      showPin: false,
      showPinNew: false,
      showPinNewConfirm: false,
      showPassword: false,
      showPasswordNew: false,
      showPasswordNewConfirm: false,
      passwordRules: [],
      showPasswordRules: [],
      passwordMaxLength: null,
      text_error: null,
      validate_change_pin: false,
    };
  },
  created() {
    this.getPasswordRules();
    this.previousPath = this.$previousPath();
    if (this.previousPath === '/market/favorite' || this.previousPath === '/market/all-coins') {
      this.handleModal('Password', 'true');
    }
  },
  methods: {
    getPasswordRules() {
      let self = this;
      HTTP.post("/uc/password-rules")
        .then(function (res) {
          if (res.data.code == "0") {
            self.passwordRules = res.data.data;
            if (
              self.passwordRules.requireMinLength &&
              self.passwordRules.minLength >= 0
            ) {
              self.showPasswordRules.push(
                `ความยาวขั้นต่ำของรหัสผ่าน อย่างน้อย ${self.passwordRules.minLength} ตัวอักขระ`
              );
            }
            if (
              self.passwordRules.requireMaxLength &&
              self.passwordRules.maxLength >= 0
            ) {
              self.showPasswordRules.push(
                `ความยาวสูงสุดของรหัสผ่าน ไม่เกิน ${self.passwordRules.maxLength} ตัวอักขระ`
              );
              self.passwordMaxLength = self.passwordRules.maxLength;
            }
            if (
              self.passwordRules.requireNumber &&
              self.passwordRules.minNumberLength >= 0
            ) {
              self.showPasswordRules.push(
                `ตัวเลข อย่างน้อย ${self.passwordRules.minNumberLength} ตัว`
              );
            }
            if (
              self.passwordRules.requireCapitalLetter &&
              self.passwordRules.minCapitalLetter >= 0
            ) {
              self.showPasswordRules.push(
                `ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อย ${self.passwordRules.minCapitalLetter} ตัวอักษร`
              );
            }
            if (
              self.passwordRules.requireSmallLetter &&
              self.passwordRules.minSmallLetter >= 0
            ) {
              self.showPasswordRules.push(
                `ตัวอักษรภาษาอังกฤษพิมพ์เล็ก อย่างน้อย ${self.passwordRules.minSmallLetter} ตัวอักษร`
              );
            }
            if (
              self.passwordRules.requireSpecialChar &&
              self.passwordRules.acceptedSpecialChar
            ) {
              self.showPasswordRules.push(
                `อักขระพิเศษที่อนุญาต ${self.passwordRules.acceptedSpecialChar}`
              );
            }
            self.isLoading = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
    validateInput(value) {
      const thaiRegex = /[ก-๙]/g;
      const filteredValue = value.replace(thaiRegex, "");
      return filteredValue;
    },
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    onPinInput(event, Type) {
      const inputValue = event.target.value;
      const cleanedValue = inputValue.replace(/\D/g, "");
      if (cleanedValue.length > 6) {
        cleanedValue = cleanedValue.slice(0, 6);
      }
      if (Type == "OLD") {
        this.OldPin = cleanedValue;
      } else if (Type == "NEW") {
        this.NewPin = cleanedValue;
      } else if (Type == "CONFIRM") {
        this.ConfirmPin = cleanedValue;
      }
    },
    onPasswordInput(event, Type) {
      let inputValue = event.target.value;
      if (Type == "OLD") {
        this.OldPassword = inputValue;
      } else if (Type == "NEW") {
        this.NewPassword = inputValue;
      } else if (Type == "CONFIRM") {
        this.ConfirmPassword = inputValue;
      }
    },
    handleOtpResponseUpdate(Value) {
      if (Value == "OtpSuccess") {
        if (this.OtpType == "pin") {
          this.ChangePinSuccess();
        } else if (this.OtpType == "password") {
          this.changePasswordSuccess();
        } else {
          this.OtpResponse = false;
          this.OtpSuccess = true;
        }
      } else if (Value == "OtpError") {
        this.OtpResponse = false;
        this.OtpError = true;
      } else if (Value == "OTP") {
        this.OtpResponse = false;
        if (this.OtpType == "pin") {
          this.handleModal("Pin", "true");
        } else if (this.OtpType == "password") {
          this.handleModal("Password", "true");
        }
      } else if (Value == "OTPSuccess") {
        this.OtpSuccess = false;
      } else if (Value == "OTPError") {
        this.OtpError = false;
      }
    },
    handleModal(Type, Value) {
      this.OtpResponse = false;
      this.isLoadingPin = false;
      this.isLoadingPassword = false;
      this.OtpType = "";
      this.OtpValue = "";
      if (Type == "Pin") {
        this.ModalEditPin = Value;
        this.NewPin = "";
        this.ConfirmPin = "";
        this.OldPin = "";
      } else if (Type == "Password") {
        this.ModalEditPassword = Value;
        this.NewPassword = "";
        this.OldPassword = "";
        this.ConfirmPassword = "";
      }
    },
    validatePassword() {
      if (!this.OldPassword) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "กรุณากรอกรหัสผ่านเดิม",
        // });
        this.failEditPin = true;
        this.text_error = "กรุณากรอกรหัสผ่านเดิม";
        return false;
      } else if (!this.NewPassword) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "กรุณากรอกรหัสผ่านใหม่",
        // });
        this.failEditPin = true;
        this.text_error = "กรุณากรอกรหัสผ่านใหม่";
        return false;
      } else if (!this.ConfirmPassword) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "กรุณากรอกรหัสผ่านยืนยัน",
        // });
        this.failEditPin = true;
        this.text_error = "กรุณากรอกรหัสผ่านยืนยัน";
        return false;
      } else if (this.NewPassword == this.OldPassword) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
        // });
        this.failEditPin = true;
        this.text_error = "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม";
        return false;
      } else if (this.NewPassword !== this.ConfirmPassword) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "รหัสผ่านยืนยันไม่ตรงกัน",
        });
        return false;
      } /* else if ( this.NewPassword.length < 8 ) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "ความยาวรหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร",
        });
        return false;
      } else if (!/[A-Z]/.test(this.NewPassword)) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "รหัสผ่านต้องมีตัวอักษรตัวใหญ่อย่างน้อย 1 ตัว",
        });
        return false;
      } else if (!/[a-z]/.test(this.NewPassword)) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "รหัสผ่านต้องมีตัวอักษรตัวเล็กอย่างน้อย 1 ตัว",
        });
        return false;
      } else if (!/\d/.test(this.NewPassword)) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว",
        });
        return false;
      } else if (!/[@#$%*&]/.test(this.NewPassword)) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "รหัสผ่านต้องมีตัวอักษรพิเศษ (@#$%*&) อย่างน้อย 1 ตัว",
        });
        return false;
      } */
      return true;
    },
    checkPinMatch() {
      if (this.NewPin !== this.ConfirmPin) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "PIN and Confirm PIN do not match",
        // });
        this.text_error = "Pin ปัจจุบันกับ Pin ใหม่ไม่ match กัน";
        this.failEditPin = true;
        return false;
      }
      return true;
    },
    validatePin() {
      if (!this.OldPin) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please enter a Old PIN",
        // });
        this.text_error = "โปรดกรอกรหัส PIN ปัจจุบัน";
        this.validate_change_pin = true;
        this.failEditPin = true;
        return false;

        return false;
      } else if (!this.NewPin) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please enter a PIN",
        // });
        this.text_error = "โปรดกรอกรหัส PIN ใหม่";
        this.validate_change_pin = true;
        this.failEditPin = true;
        return false;
      } else if (!/^\d{6}$/.test(this.NewPin)) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "PIN must be numeric and have exactly 6 digits",
        // });
        this.text_error = "กรุณากรอกตัวเลขทั้งหมด 6 หลัก";
        this.validate_change_pin = true;
        this.failEditPin = true;
        return false;
      }
      return true;
    },
    changePin() {
      this.isLoadingPin = true;
      if (this.validatePin() && this.checkPinMatch()) {
        let self = this;
        HTTP.post("/uc/validate-pin", {
          pin: this.OldPin,
        })
          .then(function (response) {
            self.isLoadingPin = false;
            if (response.data.code == "0") {
              HTTP.post("/uc/sendOtp")
                .then(function (response) {
                  self.isLoadingPin = false;
                  if (response.data.code == "0") {
                    self.OtpType = "pin";
                    self.OtpValue = "";
                    self.ModalEditPin = false;
                    self.OtpResponse = true;
                    self.refCode = response.data.data;
                  } else {
                    self.$notify({
                      group: "notification",
                      type: response.data.code == "0" ? "success" : "error",
                      title: response.data.code == "0" ? "Success" : "Error",
                      text: response.data.message,
                    });
                  }
                })
                .catch(function (error) {
                  self.isLoadingPin = false;
                  self.$notify({
                    group: "notification",
                    type: "error",
                    title: "Change Pin Unsuccessful",
                    text: error,
                  });
                });
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              self.$notify({
                group: "notification",
                type: response.data.code == "0" ? "success" : "error",
                title: response.data.code == "0" ? "Success" : "Error",
                text:
                  response.data.code == "0"
                    ? "Pin " + response.data.message
                    : "Invalid Old Pin",
              });
            }
          })
          .catch(function (error) {
            self.isLoadingPin = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Change Pin Unsuccessful",
              text: error,
            });
          });
      } else {
        this.isLoadingPin = false;
      }
    },
    updateFailEditPin() {
      this.failEditPin = false;
      this.text_error = "";
    },
    ChangePinSuccess() {
      let self = this;
      HTTP.post("/uc/reset-pin", {
        oldPin: this.OldPin,
        pin: this.ConfirmPin,
      })
        .then(function (response) {
          if (response.data.code == "0") {
            self.OtpSuccess = true;
            self.OtpResponse = false;
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Change Pin Unsuccessful",
            text: error,
          });
        });
    },
    changePassword() {
      this.isLoadingPassword = true;
      if (this.validatePassword()) {
        let self = this;
        HTTP.post("/uc/validate-password", {
          password: this.OldPassword,
        })
          .then(function (response) {
            self.isLoadingPassword = false;
            if (response.data.code === 0) {
              HTTP.post("/uc/password-check", {
                newPassword: self.NewPassword,
              })
                .then(function (response) {
                  if (response.data.code === 0) {
                    HTTP.post("/uc/sendOtp")
                      .then(function (response) {
                        self.isLoadingPassword = false;
                        if (response.data.code === 0) {
                          self.OtpType = "password";
                          self.OtpValue = "";
                          self.ModalEditPassword = false;
                          self.OtpResponse = true;
                          self.refCode = response.data.data;
                        } else {
                          self.$notify({
                            group: "notification",
                            type:
                              response.data.code === 0 ? "success" : "error",
                            title:
                              response.data.code === 0 ? "Success" : "Error",
                            text: response.data.message,
                          });
                        }
                      })
                      .catch(function (error) {
                        self.isLoadingPassword = false;
                        self.$notify({
                          group: "notification",
                          type: "error",
                          title: "Change Password Unsuccessful",
                          text: error,
                        });
                      });
                  } else if (response.data.code === 500) {
                    self.isLoadingPassword = false;
                    self.$notify({
                      group: "notification",
                      type: "error",
                      title: "Error",
                      text: response.data.message,
                    });
                  }
                })
                .catch(function (error) {
                  self.isLoadingPassword = false;
                  self.$notify({
                    group: "notification",
                    type: "error",
                    title: "Error",
                    text: error,
                  });
                });
            } else {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch(function (error) {
            self.isLoadingPassword = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Change Password Unsuccessful",
              text: error,
            });
          });
      } else {
        this.isLoadingPassword = false;
      }
    },
    changePasswordSuccess() {
      let self = this;
      HTTP.post("/uc/reset-password-update", {
        oldPassword: this.OldPassword,
        password: this.ConfirmPassword,
      })
        .then(function (response) {
          if (response.data.code === 0) {
            self.OtpSuccess = true;
            self.OtpResponse = false;
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          self.$notify({
            group: "notification",
            type: "error",
            title: "Change Password Unsuccessful",
            text: error,
          });
        });
    },
  },
});
</script>
<style scoped>
.w-400 {
  font-weight: 400 !important;
}
input {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d6dde1 !important;
  caret-color: #09BE8B;
  line-height: 24px;
}
input::placeholder {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

p {
  line-height: 24px;
}

.model-header {
  padding: 0px !important;
}

.bg-box {
  background-color: #1c262b;
}
.page-heading {
  font-family: "Noto Sans Thai" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
}
.Heading {
  padding: 24px 0px 55px 48px;
}
.telephone-number td p {
  font-family: "Noto Sans Thai" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.width-table {
  max-width: 764px;
  margin-left: 48px;
}

.table-text {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai" !important;
  cursor: pointer;
}
.table-text-white {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai" !important;
  cursor: pointer;
}

.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai" !important;
  cursor: pointer;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
/* modal */
.marginTopBottom32 {
  margin-bottom: 32px;
  margin-top: 32px;
}
.buttonSuccess {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Noto Sans Thai" !important;
}
.form-control {
  outline: none;
}
.modal-title {
  color: #d6dde1;
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 28px;
}
.text-grey {
  color: #d6dde1;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai" !important;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai" !important;
}
.correct-button {
  float: right;
  clear: both;
}
.text-dark-grey {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans Thai" !important;
}
.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}
.error {
  border: 2px solid red;
}
.error-message {
  color: red;
  margin-top: 5px;
}
.modal-mask {
  position: fixed;
  /* z-index: 9998; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-content {
  width: 100% !important;
  background-color: #141b1f !important;
}
.modal-wrapper {
  display: table-cell;
  /* vertical-align: middle; */
  padding: 180px
}

.modal-dialog {
  background-color: #141b1f !important;
  max-width: 492px !important;
  border-radius: 8px;
}
.btn-cancel {
  background-color: #191b1c;
}
.button-confirm {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}
ul {
  list-style: none;
  color: #9bacb6 !important;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
ul li::before {
  content: "\2022";
  color: #9bacb6 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.form-controlv1 {
  display: block;
  width: 344px !important;
  max-height: 40px !important;
  padding: 8px 8px !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary) !important;
  background: var(--gray-default-state) !important;
  background-clip: padding-box;
  appearance: none;
  border-radius: 4px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* otp modal */
.otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  text-align: center;
}
/* otp modal end */

/* modal otp finish succes */
.otp-success {
  max-width: 409px !important;
}
.otp-error {
  max-width: 409px !important;
}
.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.image-cross-modal {
  background-color: linear-gradient(360deg, #de2d40 0%, #ee5d6d 100%);
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.checkTick {
  padding-top: 30px;
}
.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}
.finisButton-SuccesOtp {
  padding-bottom: 100px;
}
/* modal otp finish succes end */

/* // %%%  scroller  start %%%% */

.scroller {
  min-height: 400px;
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.pinInput-width {
  width: 280px !important;
}
.SurnameWidth {
  width: 280px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121516;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-device-width: 768px) {
  .table-text {
    font-size: 10px;
  }
  .table-text-white {
    font-size: 10px;
  }

  .table-text-yellow {
    font-size: 10px;
  }
}
</style>
