<template>
  <div>
    <MainHeader
      v-if="
        $route.name != 'Login' &&
        $route.name != 'KnowledgeTest' &&
        $route.name != 'UpdatePassword' &&
        $route.name != 'UpdatePin'
      "
    ></MainHeader>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
    <notifications group="notification" />
  </div>
</template>

<script>
import Vue from "vue";
import MainHeader from "./components/header/MainHeader";
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

export default Vue.extend({
  components: { MainHeader ,VueMeta},
  data() {
    return {
      previousPath:"",
      currentPath:"",
    };
  },
  watch: {
    $route(to, from) {
      // this.previousPath = this.$previousPath();
      this.currentPath = this.$route.path
      if (from.path.startsWith('/market/coin-detail') && !to.path.startsWith('/market/coin-detail')) {
        window.location.href =  this.currentPath
      }
      if (from.path.startsWith('/exchange') && !to.path.startsWith('/exchange')) {
        window.location.href =  this.currentPath
      }
      if (from.path.startsWith('/chart') && !to.path.startsWith('/chart')) {
        window.location.href =  this.currentPath
      }
    }
  }
});
</script>

<style>
#app {
  font-family: "Roboto Flex";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.my-notification,
.vue-notification-wrapper,
.vue-notification-group {
  margin-top: 0 !important;
}
button,
input {
  border: none;
  background-color: transparent;
}
nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #121516;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: rgb(255, 255, 255);
  -webkit-text-fill-color: rgb(255, 255, 255) !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.form-check-input:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.btn:focus {
  box-shadow: none !important;
}
.btn:active {
  border: none !important;
  outline: none !important;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='111827' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
}
:root {
  overflow-y: auto;
}
.skc {
  background-color: #191b1c !important;
}
.custom-skeleton-wave {
  opacity: 0.3 !important;
  cursor: auto !important;
}
.mx-calendar {
  border: none !important;
}
</style>
