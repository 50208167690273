<template>
  <div>
    <b-container>
      <div class="borderd mt-5 mb-5">
        <b-row>
          <b-col cols="3">
            <b-card-body>
              <b-card-text>
                Form
                <span class="size-large">W-9</span>
                <div class="text-color size mt-2">(Rev. March 2024)</div>
                <div class="text-color size mt-2">
                  Department of the Treasury Internal Revenue Service
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>

          <b-col cols="6" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div class="size-large text-center">Request for Taxpayer</div>
                <div class="size-large text-center">
                  Identification Number and Certification
                </div>
                <div class="text-center weight mt-2">
                  <b-icon icon="play-fill" font-scale="2"></b-icon>
                  <span class="text-color">
                    Go to www.irs.gov/FormW9 for instructions and the latest
                    information.</span>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>

          <b-col cols="3" class="borderd-left">
            <b-card-body>
              <b-card-text>
                <div class="size-large">
                  Give Form to the<br />
                  requester. Do not send<br />
                  to the IRS
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>

        <div class="borderd-bottom"></div>

        <b-card-body>
          <b-card-text>
            <div class="mt-3">
              Name of entity/individual. An entry is required. (For a sole proprietor or disregarded entity, enter the owner’s name on line 1, and enter the business/disregarded
              entity’s name on line 2.)
            </div>
            <input v-model="name" type="text" placeholder="" class="mt-2" @input="updateFormData('name')" :class="{ 'bg-input-validate' : nameError }" :disabled="name"/>
            <p v-if="nameError" class="text-danger mb-0">Required.</p>
          </b-card-text>
        </b-card-body>
        <b-card-body>
          <b-card-text>
            <div class="mt-3">
              2 Business name/disregarded entity name, if different from above.
            </div>
            <input v-model="businessName" type="text" placeholder="" class="mt-2" :class="{ 'bg-input-validate' : businessError }"
              @input="updateFormData('businessName')" />
            <!-- <p v-if="businessError" class="text-danger mb-0">Required.</p> -->
          </b-card-text>
        </b-card-body>

        <b-card-body>
          <b-card-text>
            <div class="mt-3">
              3a Check appropriate box for federal tax classification of the
              person whose name is entered on line 1. Check only one of the
              following seven boxes.
            </div>
            <p v-if="classificationError && !selectedOption" class="text-danger mb-0">Required.</p>
            <div class="mt-4"></div>
            <div class="space-left">
              <b-row>
                <b-col cols="5">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="isLLCMember" v-model="selectedOption" @input="checkRadio('isLLCMember')"/>
                      <p class="left mt-1">
                        Individual/sole proprietor or single-member LLC
                      </p>
                    </label>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="cCorporation" v-model="selectedOption" @input="checkRadio('cCorporation')"/>
                      <p class="left mt-1">C Corporation</p>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="sCorporation" v-model="selectedOption" @input="checkRadio('sCorporation')"/>
                      <p class="left mt-1">S Corporation</p>
                    </label>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="partnership" v-model="selectedOption" @input="checkRadio('partnership')"/>
                      <p class="left mt-1">Partnership</p>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="trustOrEstate" v-model="selectedOption" @input="checkRadio('trustOrEstate')"/>
                      <p class="left mt-1">Trust/estate</p>
                    </label>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="limitedLiability" v-model="selectedOption" @input="checkRadio('limitedLiability')"/>
                      <p class="left mt-1">Limited liability company. Enter the tax classification (C=C corporation, S=S
                        corporation,
                        P=Partnership)</p>
                    </label>
                  </div>
                  <input v-model="classification" type="text" placeholder="" class="mt-2 mb-1"
                    @input="updateFormData('classification')" :disabled="selectedOption != 'limitedLiability'" :class="{ 'bg-input-validate' : classificationError }"/>
                    <p v-if="classificationError" class="text-danger mb-0">Required.</p>
                </b-col>
              </b-row>
            </div>

            <div class="space-left">
              <span class="text-large">Note:</span>
              <span style="font-weight: 300">
                Check the appropriate box in the line above for the tax
                classitication of the single-member owner. Do not check LLC If
                the LLC Is Classified as a single-member LLC that is disregarded
                from the owner unless the owner of the LLC is another LLC that
                is not disregarded from the owner for U.S. federal tax purposes,
                Otherwise, a single-member LLC that is disregarded from the
                owner should check the appropriate box for the tax
                classification of its owner.
              </span>
              <b-row class="mt-4">
                <b-col cols="4">
                  <div class="form-group form-check">
                    <label>
                      <input type="radio" class="form-check-input" value="taxOther" v-model="selectedOption" @input="checkRadio('taxOther')"/>
                      <p class="left mt-1">Other(see instructions)</p>
                    </label>
                  </div>
                </b-col>
                <b-col cols="8">
                  <input v-model="taxOtherText" type="text" placeholder="" @input="updateFormData('taxOtherText')"
                    :disabled="selectedOption != 'taxOther'" :class="{ 'bg-input-validate' : taxOtherTextError }"/>
                  <p v-if="taxOtherTextError" class="text-danger mb-0">
                    Required.
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-card-body>

        <b-card-body>
          <b-card-text>
            <label>
              3b If on line 3a you checked “Partnership” or “Trust/estate,” or checked “LLC” and entered “P” as its tax classification,
              and you are providing this form to a partnership, trust, or estate in which you have an ownership interest, check
              this box if you have any foreign partners, owners, or beneficiaries. See instructions
              <input v-model="chkPartnershipW9" type="checkbox" class="form-check-input" />
            </label>
          </b-card-text>
        </b-card-body>

        <b-card-body>
          <b-card-text>
            <div class="mt-1">
              4 Exemptions
            </div>
            <b-row class="left mt-3">
              <b-col cols="5">
                <div>Exempt payee code (if any)</div>
              </b-col>
              <b-col cols="7">
                <input v-model="exPayee" type="text" @input="updateFormData('exPayee')" />
                <!-- <p v-if="exPayeeError" class="text-danger mb-0">Required.</p> -->
              </b-col>
            </b-row>
            <b-row class="left mt-3">
              <b-col cols="5">
                <div>Exemption from FATCA reporting code (if any)</div>
              </b-col>
              <b-col cols="7">
                <input v-model="exReporting" type="text" @input="updateFormData('exReporting')" />
                <!-- <p v-if="exReportingError" class="text-danger mb-0">
                  Required.
                </p> -->
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
        <b-card-body>
          <b-card-text>
            <div class="mt-3">
              5 Address (number, street, and apt. or suite no.) See
              instructions.
            </div>
            <input v-model="address" type="text" placeholder="" class="mt-2" @input="updateFormData('address')" :class="{ 'bg-input-validate' : addressError }" />
            <p v-if="addressError" class="text-danger mb-0">Required.</p>
          </b-card-text>
        </b-card-body>
        <b-card-body>
          <b-card-text>
            <div class="mt-3">6 City, state, and ZIP code</div>
            <input v-model="city" type="text" placeholder="" class="mt-2" @input="updateFormData('city')" :class="{ 'bg-input-validate' : cityError }" />
            <p v-if="cityError" class="text-danger mb-0">Required.</p>
          </b-card-text>
        </b-card-body>
        <b-card-body>
          <b-card-text>
            <div class="mt-3">7 List account number(s) here (optional)</div>
            <input v-model="accountNumber" type="text" placeholder="" class="mt-2" @input="updateFormData('accountNumber')" />
          </b-card-text>
        </b-card-body>
        <b-card-body>
          <b-card-text>
            <div class="mt-3">Requester's name and address (optional)</div>
            <input v-model="requester" placeholder="" class="mt-2 mb-4 bg-input" @input="updateFormData('requester')" />
          </b-card-text>
        </b-card-body>
        <div class="borderd-bottom"></div>
        <b-row>
          <b-col cols="6">
            <b-col cols="5">
              <div style="background-color: black" class="p-1">PARTI</div>
            </b-col>
          </b-col>
          <b-col cols="6">
            <div class="p-1">Taxpayer Identification Number (TIN) 
              <!-- <p v-if="isRequiredSocialVisible" class="text-danger mb-0" style="display: inline;">&nbsp&nbspRequired.</p> -->
            </div>
          </b-col>
        </b-row>
        <div class="borderd-bottom mb-3"></div>
        <b-card-body>
          <b-card-text>
            <b-row>
              <b-col cols="6">
                <div style="font-weight: 300">
                  Enter your TIN in the appropriate box. The TIN provided must
                  match the name given on line 1 to avoid backup withholding.
                  For individuals, this is generally your social security number
                  (SSN). However, for a resident alien, sole proprietor, or
                  disregarded entity, see the instructions for Part I, later.
                  For other entities, it is your employer identification number
                  (EIN). If you do not have a number, see How to get a TIN,
                  later.<br />
                  <br />
                  Note: If the account is in more than one name, see the
                  instructions for line 1. Also see What Name and Number To Give
                  the Requester for guidelines on whose number to enter.
                </div>
              </b-col>
              <b-col cols="6">
                <div class="space-left">
                  <span> Social security number </span>
                  <div class="OTPInput">
                    <input type="number" v-model="inputs[0]" maxlength="1" ref="sInput1"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput2')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="inputs[1]" ref="sInput2" maxlength="1"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput3')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="inputs[2]" ref="sInput3" maxlength="1"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput4')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    -
                    <input type="number" v-model="inputs[3]" ref="sInput4" maxlength="1"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput5')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" maxlength="1" ref="sInput5" v-model="inputs[4]"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput6')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    -
                    <input type="number" v-model="inputs[5]" maxlength="1" ref="sInput6"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput7')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" maxlength="1" v-model="inputs[6]" ref="sInput7"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput8')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="inputs[7]" maxlength="1" ref="sInput8"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'sInput9')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" maxlength="1" v-model="inputs[8]" ref="sInput9"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                  </div>
                  <div class="mt-3">Or</div>
                  <div>Employer identification number</div>
                  <div class="OTPInput">
                    <input type="number" v-model="identityNumber[0]" maxlength="1" ref="eInput1"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput2')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="identityNumber[1]" maxlength="1" ref="eInput2"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput3')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    -
                    <input type="number" v-model="identityNumber[2]" maxlength="1" ref="eInput3"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput4')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="identityNumber[3]" maxlength="1" ref="eInput4"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput5')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" maxlength="1" v-model="identityNumber[4]" ref="eInput5"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput6')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="identityNumber[5]" maxlength="1" ref="eInput6"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput7')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" maxlength="1" v-model="identityNumber[6]" ref="eInput7"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput8')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" v-model="identityNumber[7]" maxlength="1" ref="eInput8"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @input="moveToNext($event, 'eInput9')" v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <input type="number" maxlength="1" v-model="identityNumber[8]" ref="eInput9"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-focusOnBackspace v-focusOnLeftArrow v-focusOnRightArrow
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>

        <div class="borderd-bottom mt-3"></div>
        <b-row>
          <b-col cols="6">
            <b-col cols="5">
              <div style="background-color: black" class="p-1">PART II</div>
            </b-col>
          </b-col>
          <b-col cols="6">
            <div class="p-1">Certification</div>
          </b-col>
        </b-row>
        <div class="borderd-bottom mb-3"></div>

        <b-card-body>
          <b-card-text>
            <div style="font-weight: 200; font-size: 13px">
              under pendives or penury. I ceruty that.
            </div>
            <div style="font-weight: 300; font-size: 14px">
              <p>
                1. The number shown on this form is my correct taxpayer
                identification number (or I am waiting for a number to be issued
                to me); and
              </p>
              <p>
                2. I am not subject to backup withholding because: (a) I am
                exempt from backup withholding, or (b) I have not been notified
                by the Internal Revenue Service (IRS) that I am subject to
                backup withholding as a result of a failure to report all
                interest or dividends, or (c) the IRS has notified me that I am
                no longer subject to backup withholding; and
              </p>
              <p>
                3. 1 am a U.S. citizen or other U.S. person (defined below); and
              </p>
              <p>
                4. The FATCA code(s) entered on this form (if any) indicating
                that I am exempt from FATCA reporting is correct
              </p>
              <p>
                Certification instructions. You must cross out item 2 above if
                you have been notified by the IRS that you are currently subject
                to backup withholding because you have failed to report all
                interest and dividends on your tax return. For real estate
                transactions, item 2 does not apply. For mortgage interest paid,
                acquisition or abandonment of secured property, cancellation of
                debt, contributions to an individual retirement arrangement
                (IRA), and generally, payments other than interest and
                dividends, you are not required to sign the certification, but
                you must provide your correct TIN. See the instructions for Part
                II, later.
              </p>
            </div>
            <div class="form-group form-check">
              <label>
                <input v-model="acceptAgreement" type="checkbox" class="form-check-input" value="false" />
                <p class="left mt-1">
                  Accept Agreement
                </p>
              </label>
              <span class="text-primary" @click="openFatcaW9DetailModal()" role="button"> (Read More)</span>
              <p v-if="acceptAgreementError && !acceptAgreement" class="text-danger mb-0">Required.</p>
            </div>
          </b-card-text>
          <div class="mx-3 thai-font justify-center text-center">
            <button @click="submitForm()" class="btn-yellow">
              ส่ง
            </button>
            <button @click="backToReviewKYC()" class="btn-blue">
              ยกเลิก
            </button>
          </div>
        </b-card-body>
      </div>
    </b-container>
    <FatcaFormDetailModal />
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import * as Yup from "yup";
import FatcaFormDetailModal from "@/pages/pre-test/FatcaFormDetailModal.vue";

const validateSchema = Yup.object().shape({
  name: Yup.string().required(),
  businessName: Yup.string().required(),
  address: Yup.string().required(),
  city: Yup.string().required(),
});

export default {
  components: { 
    FatcaFormDetailModal
  },
  data() {
    return {
      test: null,
      sole: false,
      other: false,
      name: null,
      businessName: null,
      chkPartnershipW9: false,
      isUSAuthPerson: false,
      isUSCitizen: false,
      federalTax: null,
      isLLCMember: false,
      cCorporation: false,
      sCorporation: false,
      partnership: false,
      trustOrEstate: false,
      limitedLiability: false,
      classification: null,
      taxOther: false,
      taxOtherText: null,
      exemptions: null,
      exPayee: null,
      exReporting: null,
      address: null,
      city: null,
      accountNumber: null,
      requester: null,
      socialSecurityNo: null,
      empIdNo: null,
      acceptAgreement: false,
      inputs: [],
      identityNumber: [],
      fatcaFormData: {},
      nameError: false,
      cityError: false,
      addressError: false,
      // exReportingError: false,
      SocialEmployeeError:false,
      exemptionsError: false,
      // exPayeeError: false,
      taxOtherTextError: false,
      classificationError: false,
      businessError: false,
      acceptAgreementError: false,
      selectedOption: '',
      choiceSelect: null,
    };
  },
  computed: {
    getField() {
      return [
        "name",
        "businessName",
        "chkPartnershipW9",
        "isUSAuthPerson",
        "isUSCitizen",
        "federalTax",
        "isLLCMember",
        "cCorporation",
        "sCorporation",
        "partnership",
        "trustOrEstate",
        "limitedLiability",
        "classification",
        "taxOther",
        "taxOtherText",
        "exemptions",
        "exPayee",
        "exReporting",
        "address",
        "city",
        "accountNumber",
        "requester",
        "socialSecurityNo",
        "empIdNo",
        "acceptAgreement",
        "identityNumber",
      ];
    },
    isRequiredSocialVisible() {
      return this.SocialEmployeeError && this.hasEmptyValues;
    },
    hasEmptyValues() {
      //const inputsEmpty = this.inputs.some(value => value === "");
      //const identityNumberEmpty = this.identityNumber.some(value => value === "");
      //return inputsEmpty && identityNumberEmpty;

      const inputsEmpty = this.inputs.length === 9 && this.inputs.every(value => value !== "");
      const inputsCheckEmpty = this.inputs.length === 0 || this.inputs.every(value => value === "");

      const identityNumberEmpty = this.identityNumber.length === 9 && this.identityNumber.every(value => value !== "");
      const identityNumberCheckEmpty = this.identityNumber.length === 0 || this.identityNumber.every(value => value === "");

      if (inputsEmpty && identityNumberCheckEmpty) {
        return false;
      } else if (identityNumberEmpty && inputsCheckEmpty) {
        return false;
      } else if (inputsEmpty && identityNumberEmpty){
        return false;
      }

      return true;
  },
    generatedOTP() {
      if (this.inputs.length) {
        this.socialSecurityNo = this.inputs.join("");
        this.empIdNo = this.identityNumber.join("");
      }
    },
    socialSecurityOrEmpId() {
      return this.socialSecurityNo || this.empIdNo ? true : false;
    },
    areAllRequiredFieldsFilled() {
      this.generatedOTP;
      return this.nameError === false &&
        this.classificationError === false && 
        this.taxOtherTextError === false &&
        this.exemptionsError === false &&
        this.addressError === false &&
        this.cityError === false &&
        this.addressError === false &&
        this.acceptAgreementError === false
        ? true
        : false;
    },
  },
  directives: {
    focusOnBackspace: {
      inserted(el, binding) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "Backspace" && event.target.value === "") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnLeftArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowLeft") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnRightArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowRight") {
            event.preventDefault();
            const nextInput = el.nextElementSibling;
            if (nextInput) {
              nextInput.select();
            }
          }
        });
      },
    },
  },
  created() {
    this.getOldFatca();
    this.KYCflag();
  },
  methods: {
    KYCflag() {
      localStorage.setItem("fatcaSet", true)
    },
    next(e) {
      if (e.data == null) {
        e.target?.previousSibling?.select();
      } else {
        e.target?.nextSibling?.select();
        // console.log(e.data);
      }
    },
    onInput(index) {
      if (index < this.inputs.length - 1) {
        this.currentIndex = index + 1;
      }
    },
    onInput2(index) {
      if (index < this.identityNumber.length - 1) {
        this.currentIndex = index + 1;
      }
    },
    moveToNext(e, nextInputRef) {
      if (e.target.value.length === e.target.maxLength) {
       this.$refs[nextInputRef].focus();
      }
    },
    updateFormData(data) {
      const regex = /^[a-zA-Z\s]*$/;
      const regexNum = /^[a-zA-Z0-9\s]*$/;
      const regexSpecial = /^[a-zA-Z0-9!@#$%^&*(),-_+\s]*$/;

      if (this.name && data === "name") {
        if (!regex.test(this.name)) {
          this.name = this.name.replace(/[^a-zA-Z.\s]/g, '');
        }
        this.nameError = false;

      } else if (this.businessName && data === "businessName") {
        if (!regex.test(this.businessName)) {
          this.businessName = this.businessName.replace(/[^a-zA-Z\s]/g, '');
        }
        // this.businessError = false;
      
      }else if (this.classification && data === "classification") {
        this.classification = this.classification.replace(/[^a-zA-Z0-9\s]/g, '');

      }else if (this.taxOtherText && data === "taxOtherText") {
        this.taxOtherText = this.taxOtherText.replace(/[^a-zA-Z0-9\s]/g, '');

      } else if (this.exReporting && data === "exReporting") {
        if (!regex.test(this.exReporting)) {
          this.exReporting = this.exReporting.replace(/[^a-zA-Z\s]/g, '');
        }
        // this.exReportingError = false;

      } else if (this.exPayee && data === "exPayee") {
        if (!regex.test(this.exPayee)) {
          this.exPayee = this.exPayee.replace(/[^a-zA-Z\s]/g, '');
        }
        // this.exPayeeError = false;

      } else if (this.address && data === "address") {
        if (!regexSpecial.test(this.address)) {
          this.address = this.address.replace(/[^a-zA-Z0-9!@#$%^&*(),-_+\s]/g, '');
        }
        this.addressError = false;

      } else if (this.city && data === "city") {
        if (!regexSpecial.test(this.city)) {
          this.city = this.city.replace(/[^a-zA-Z0-9!@#$%^&*(),-_+\s]/g, '');
        }
        this.cityError = false;

      }else if (this.accountNumber && data === "accountNumber") {
        this.accountNumber = this.accountNumber.replace(/[^0-9\s]/g, '');
      
      }else if (this.requester && data === "requester") {
        this.requester = this.requester.replace(/[^a-zA-Z0-9\s]/g, '');
      
      } else if (this.acceptAgreement && data === "acceptAgreement") {
        this.acceptAgreementError = false;  
      }

      this.getField.forEach((field) => {
        this.fatcaFormData[field] = this[field];
      });
      
      localStorage.setItem("fatcaFormData", JSON.stringify(this.fatcaFormData));
    },
    getOldFatca() {
      const kycReviewData = localStorage.getItem("kycReviewData");
      if (kycReviewData) {
        const formData = JSON.parse(kycReviewData);
        this.name = this.showTitleName(formData.titleId) + formData.nameEN;
      }
      const fatcaData = localStorage.getItem("fatcaData");
      const formData = JSON.parse(fatcaData);
      this.choiceSelect = formData
      this.businessName = formData.business_name_w9
      this.chkPartnershipW9 = formData.chkPartnershipW9 ? formData.chkPartnershipW9 : false
      this.federalTax = formData.federal_tax_w9 ? formData.federal_tax_w9 : null
      this.selectedOption = formData.is_llc_member_w9 ? "isLLCMember" : this.selectedOption
      this.selectedOption = formData.c_corporation_w9 ? "cCorporation" : this.selectedOption
      this.selectedOption = formData.s_corporation_w9 ? "sCorporation" : this.selectedOption
      this.selectedOption = formData.partnership_w9 ? "partnership" : this.selectedOption
      this.selectedOption = formData.trust_or_estate_w9 ? "trustOrEstate" : this.selectedOption
      this.selectedOption = formData.limited_liability_w9 ? "limitedLiability" : this.selectedOption
      this.taxOther = formData.taxOther_w9  ? " taxOther" : null;
      this.classification = formData.classification_w9 ? formData.classification_w9 : null
      this.taxOtherText = formData.taxOtherText_w9 ? formData.taxOtherText_w9 : null
      this.exemptions = formData.exemptions_w9 ? formData.exemptions_w9 : null
      this.exPayee = formData.exPayee_w9 ? formData.exPayee_w9 : null
      this.exReporting = formData.exReporting_w9 ? formData.exReporting_w9 : null
      this.address = formData.address_w9 ? formData.address_w9 : null
      this.city = formData.city_w9 ? formData.city_w9 : null
      this.accountNumber = formData.account_number_w9 ? formData.account_number_w9 : null
      this.requester = formData.requester_w9 ? formData.requester_w9 : null
      this.inputs = formData.socialSecurityNo ? Array.from(formData.socialSecurityNo.toString().replace(/-/g, '')).map(Number) : this.inputs
      this.identityNumber = formData.empIdNo ? Array.from(formData.empIdNo.toString().replace(/-/g, '')).map(Number) : this.identityNumber
    },
    showTitleName(titleId){
      let titleNameEn = "";
      if(titleId == 1){
        titleNameEn = "MR.";
      }else if(titleId == 2){
        titleNameEn = "MRS.";
      }else if(titleId == 3){
        titleNameEn = "MISS";
      }
      return titleNameEn;
    },
    checkRadio(el){
      if(el == 'limitedLiability'){
        this.taxOtherTextError = false;
      }else if(el == 'taxOther'){
        this.classificationError = false;
      }else{
        this.taxOtherTextError = false;
        this.classificationError = false;
      }
    },
    submitForm() {
      if (!this.name) {
        this.nameError = true;
      }
      // if (!this.businessName) {
      //   this.businessError = true;
      // }
      if (this.selectedOption === 'limitedLiability') {
        this.classificationError = !this.classification;
      } else if (this.selectedOption === 'taxOther') {
        this.taxOtherTextError = !this.taxOtherText;
      }
      // if (!this.taxOtherText) {
      //   this.taxOtherTextError = true;
      // }
      // if (!this.taxOtherText) {
      //   this.taxOtherTextError = true;
      // }
      // if (!this.exPayee) {
      //   this.exPayeeError = true;
      // }
      // if (!this.exemptions) {
      //   this.exemptionsError = true;
      // }
      // if (!this.exReporting) {
      //   this.exReportingError = true;
      // }
      if(this.hasEmptyValues){
        this.SocialEmployeeError = true;
      }
      if (!this.address) {
        this.addressError = true;
      }
      if (!this.city) {
        this.cityError = true;
      }
      if (!this.acceptAgreement) {
        this.acceptAgreementError = true;
      } else {
        this.acceptAgreementError = false;
      }
      if (this.areAllRequiredFieldsFilled) {
        HTTP.post("/uc/fatca/save?fatcaType=1", {
          name: this.name,
          businessName: this.businessName,
          chkPartnershipW9: this.chkPartnershipW9,
          isUSCitizen: this.choiceSelect.is_us_citizen? true:false,
          isUSAuthPerson: this.choiceSelect.is_us_auth_person? true:false,
          isUsGreenCard: this.choiceSelect.is_us_green_card? true:false,
          isUsResident: this.choiceSelect.is_us_resident? true:false,
          isUsRenounce: this.choiceSelect.is_us_renounce? true:false,
          isUsAddress: this.choiceSelect.is_us_address? true:false,
          isUsTel: this.choiceSelect.is_us_tel? true:false,    
          isUsBankAccount: this.choiceSelect.is_us_bank_account? true:false,
          federalTax: this.federalTax,
          isLLCMember: this.selectedOption == "isLLCMember",
          cCorporation: this.selectedOption == "cCorporation",
          sCorporation: this.selectedOption == "sCorporation",
          partnership: this.selectedOption == "partnership",
          trustOrEstate: this.selectedOption == "trustOrEstate",
          limitedLiability: this.selectedOption == "limitedLiability",
          classification: this.classification,
          taxOther: this.taxOther == "taxOther",
          taxOtherText: this.taxOtherText,
          exemptions: this.exemptions,
          exPayee: this.exPayee,
          exReporting: this.exReporting,
          address: this.address,
          city: this.city,
          accountNumber: this.accountNumber,
          requester: this.requester,
          socialSecurityNo: this.socialSecurityNo?this.socialSecurityNo.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3'): this.socialSecurityNo,
          empIdNo: this.empIdNo?this.empIdNo.replace(/(\d{2})(\d{7})/, '$1-$2'):this.empIdNo,
          acceptAgreement: true,
        }).then((res) => {
          //console.log(res.data.data.fatcaId);
          localStorage.setItem("fatcaId", res.data.data.fatcaId);
          // this.$router.push('/fatca/w8')
          this.$notify({
            group: "notification",
            type: "success",
            title: "Success",
            text: "Save Fatca W9 Successfully",
          });
          this.$router.push({
            name: "ReviewKYC",
            params: { fatcaId: res.data.data.fatcaId }
          });
          //this.$router.push('/ProfileSetting/ReviewKYC')
        });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Fields Empty",
          text: "Kindly fill all the required Fields",
        });
      }
    },
    backToReviewKYC() {
      this.$router.push({
        name: "ReviewKYC",
        params: { fatcaId: "NO" }
      });
    },
    openFatcaW9DetailModal(){
      this.$bvModal.show('fatca-form-detail-modal')
    }
  },
};
</script>

<style scoped>
/* .OTPInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 16px;
  input {
    caret-color: #09BE8B;
  }
} */
.OTPInput input {
  width: 40px;
  height: 40px;
  background-color: #191b1c;
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  font-size: 30px;
  border-style: none;
  outline: none;
}

input[type="text"] {
  width: 100%;
  /* padding: 12px 20px; */
  /* margin: 8px 0; */
  /* box-sizing: border-box; */
  border: none;
  border-bottom: 1px solid #677f8e;
  color: #677f8e;
}

.bg-input {
  background-color: #191b1c;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #677f8e;
}

.left {
  margin-left: 5px;
  font-size: 13px;
}

.space-left {
  margin-left: 10px;
}

.borderd-left {
  border-left: 1px solid #677f8e;
}

.borderd-bottom {
  border-bottom: 1px solid #677f8e;
}

.borderd {
  border: 1px solid #677f8e;
}

.size-large {
  font-size: 20px;
  font-weight: 600;
}

.text-large {
  font-size: 15px;
  font-weight: 600;
}

.text-color {
  color: #677f8e;
}

.size {
  font-size: 9px;
  font-weight: 600;
}

.weight {
  font-size: 11px;
  font-weight: 600;
}

.detail-color {
  color: #4583ff;
}

.modal-default-button {
  float: right;
}

.OTPInput {
  display: flex;
  gap: 10px;
}
.bg-input-validate{
  background-color: #191b1c !important;
}
</style>
