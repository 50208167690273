<template>
  <div>
    <div class="dwr-c">
      <h1>{{ $t('header.depositwithdrawreport') }}</h1>
      <div class="dwr-head">
        <button
          :class="activeC == 'cash' ? 'active' : 'not-active'"
          @click="activeC = 'cash'"
        >
        {{ $t('cash') }}
        </button>
        <button
          :class="activeC == 'coin' ? 'active' : 'not-active'"
          @click="activeC = 'coin'"
        >
        {{ $t('coin') }}
        </button>
      </div>
      <component :is="activeC"></component>
      <div style="margin-bottom: 90px"></div>
    </div>
    <div class="dwr-footer">
      <div class="content">
        <div class="d-inline-block">
          <p class="c-title">Date</p>
          <p class="c-value">{{ RangeDate }}</p>
        </div>
        <div class="d-inline-block">
          <p class="c-title">Total Deposit (THB)</p>
          <p class="c-value">{{ $MyFunc.NumberFormat(Total[0]) }}</p>
        </div>
        <div class="d-inline-block">
          <p class="c-title">Total Withdraw (THB)</p>
          <p class="c-value">{{ $MyFunc.NumberFormat(Total[1]) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cash from "@/pages/portfolio/components/RCash.vue";
import coin from "@/pages/portfolio/components/RCoin.vue";
export default {
  components: { cash, coin },
  data() {
    return {
      activeC: 'cash',
      RangeDate: null,
      Total: [],
    };
  },
  metaInfo() {
    let pageTitle = "D/W Report";
    return {
      title: pageTitle,
      titleTemplate: "%s | "+ this.TITLE ,
    };
  },
  methods: {
    UpdateReport() {
      let dw = JSON.parse(localStorage.getItem("DWReport"));
      this.RangeDate = dw.RangeDate;
      this.Total = dw.Total;
      setTimeout(() => {
        this.UpdateReport();
      }, 100);
    },
  },
  created() {
    this.activeC = this.$route.params.page;
    setTimeout(() => {
      this.UpdateReport();
    }, 1000);
  },
  mounted(){
    const activeC = this.$route.query.activeC;
    if(activeC){
      this.activeC = activeC;
    }   
  }
};
</script>
<style lang="scss">
.dwr-c {
  width: 932px;
  margin: auto;
  min-height: 77vh;
  h1 {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #d6dde1;
    margin-top: 17px;
  }
  .dwr-head {
    border-bottom: 1px solid #28363e;
    margin-top: 24px;
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;
      padding: 12.5px 0;
      margin-right: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;
      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }
}
.dwr-footer {
  .content {
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
    .c-title {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #677f8e;
    }
    .c-value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #d6dde1;
      padding-top: 1px;
    }
    width: 932px;
    height: 80px;
    margin: auto;
  }

  .d-inline-block {
    margin-right: 50px;
  }

  background-color: #000;
  position: fixed;
  width: 100%;
  bottom: 0;
}
</style>
