export default {
  message: {
    hello: "สวัสดี",
    welcome: "ยินดีต้อนรับสู่เว็บไซต์ของเรา!",
  },
  header: {
    home: "หน้าแรก",
    market: "ตลาด",
    scan: "สแกน",
    buysell: "ซื้อ/ขาย",
    chart: "กราฟ",
    portfolio: "พอร์ตการลงทุน",
    depositwithdraw: "ฝาก/ถอน",
    download: "ดาวน์โหลด",
    favorite: "รายการโปรด",
    allcoins: "เหรียญทั้งหมด",
    cryptosignals: "สัญญาณคริปโต",
    cryptobubbles: "ฟองสบู่คริปโต",
    heatmap: "แผนที่ความร้อน",
    topgainloss: "ผู้ชนะ/ผู้แพ้สูงสุด",
    news: "ข่าว",
    coindetail: "รายละเอียดเหรียญ",
    myportfolio: "พอร์ตการลงทุนของฉัน",
    automode: "โหมดอัตโนมัติ",
    depositwithdrawreport: "รายงานฝาก/ถอน",
    history: "ประวัติ",
    deposit: "ฝากเงิน",
    withdraw: "ถอนเงิน",
  },
  announcement: "ประกาศ",
  allcoin: "เหรียญทั้งหมด",
  all: "ทั้งหมด",
  topgain: "กำไรสูงสุด",
  toploss: "ขาดทุนสูงสุด",
  addfavorite: "เพิ่มรายการโปรด",
  addpricealert: "เพิ่มการแจ้งเตือนราคา",
  partperfomance: "ผลการดำเนินงานในอดีต",
  last7day: "7 วันที่ผ่านมา",
  last30day: "30 วันที่ผ่านมา",
  last90day: "90 วันที่ผ่านมา",
  marketstatus: "สถานะตลาด",
  rankbymarketcap: "อันดับตามมูลค่าตลาด",
  marketcapusd: "มูลค่าตลาด (USD)",
  totalsupply: "อุปทานรวม ()",
  maxsupply: "อุปทานสูงสุด",
  website: "เว็บไซต์",
  trendsignal: "สัญญาณแนวโน้ม",
  price: "ราคา",
  volume: "ปริมาณ",
  searchlast: "ค้นหาล่าสุด",
  notipassword: "การแจ้งเตือนรหัสผ่าน",
  processed: "ดำเนินการ",
  notprocessed: "ยังไม่ดำเนินการ",
  notipasswordtext:
    "  ถึงกำหนดเวลาเปลี่ยนรหัสผ่าน กรุณาเปลี่ยนรหัสผ่านเพื่อความปลอดภัย",
  kyctext: "การทบทวนข้อมูล KYC ของท่านไม่ได้รับการอนุมัติ กรุณาดำเนินการทบข้อมูล KYC ก่อนวันที่ ",
  kyctext1: "กรุณาดำเนินการทบทวนข้อมูล KYC ก่อนวันที่ ",
  kyctext2: "หากเลยเวลาดังกล่าวจะไม่สามารถทำรายการซื้อได้ ",
  myportfoliovalue: "มูลค่าพอร์ตการลงทุนของฉัน",
coin: "เหรียญ",
cash: "เงินสด",
plun: "P/L ที่ยังไม่ realiz (ประมาณ)",
plre: "P/L ที่ realiz (วันนี้)",
myport: "พอร์ตของฉัน",
coinheatmap: "แผนที่ความร้อนของเหรียญ",
assetallocation: "การจัดสรรสินทรัพย์",
hidecoin: "ซ่อนเหรียญที่มีมูลค่าน้อย",
addcondition:"กรุณาเพิ่มเงื่อนไข เพื่อเริ่มใช้งาน",
selectcoin:"เลือกเหรียญใน Portfolio ที่ต้องการใช้ Auto Mode",
newautomode:"สร้างเงื่อนไข Auto Mode ใหม่",
editsetting:"แก้ไขการตั้งค่า",
settakeprofit:"ตั้งค่า Take Profit / Stop Loss ที่ต้องการ",
settakeprofit2:"คำนวณจากต้นทุนเฉลี่ย",
wantsell:"จำนวนที่ต้องการขาย",
sellamount:"ขายจำนวน",
takeprofit:"ขายทำกำไร",
 stoploss:"หยุดขาดทุน",
 depositcash: "ฝากเงินสด",
 depositcoin: "ฝากเหรียญ",
choicedeposit: "เลือกวิธีการฝากเงิน",
depositamount: "จำนวนเงินที่จะฝาก",
bank: "ธนาคาร",
choicebank: "เลือกธนาคาร",
accountbank: "บัญชีธนาคาร",
depositterm: "ยอมรับเงื่อนไขการฝากเงิน",
depositterm1: "คุณต้องใช้บัญชีธนาคารที่ระบุไว้ข้างต้นเพื่อโอนเงินเท่านั้น",
depositterm2: "สามารถโอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคารต้นทาง)",
depositterm3: "รหัส QR มีอายุไม่เกิน 24 ชั่วโมง",
allterm:"ยอมรับเงื่อนไขทั้งหมด",
confirmdeposit:"ยืนยันการฝากเงิน",
accountinput:"ระบุเลขที่บัญชี",
choicecoindeposit:"เลือกเหรียญที่ต้องการฝาก",
choicecoin:"เลือกเหรียญ",
withdrawcash: "ถอนเงินสด",
withdrawcoin: "ถอนเหรียญ",
amountwithdraw: "จำนวนเงินที่ต้องการถอน",
bankmoney: "บัญชีธนาคารที่รับเงิน",
samarywithdraw: "สรุปรายการถอน",
samarywithdraw1: "จำนวนเงินที่ถอน",
samarywithdraw2: "ค่าธรรมเนียมการถอน",
samarywithdraw3: "จำนวนเงินที่ได้รับ",
notewithdraw: "หมายเหตุ",
notewithdraw1: "จำนวนเงินถอนขั้นต่ำ 20 บาท (ไม่รวมค่าธรรมเนียมการถอน)",
notewithdraw2: "การถอนไม่เกิน 2,000,000 บาทจะได้รับภายใน 24 ชั่วโมง",
notewithdraw3: "การถอนที่เกิน 2,000,000 บาทจะได้รับภายใน 3 วันทำการ",
submitwithdraw: "ยืนยันการถอน",
withdrawall:"ถอนทั้งหมด",
change:"เปลี่ยน",
detail:"รายละเอียด",
    buysell:"ซื้อ/ขาย",
    quwanttobuy:"จำนวนที่ต้องการซื้อ",
    quwanttosell:"จำนวนที่ต้องการขาย",
    amountthatcan:"ยอดเงินที่ซื้อได้",
    priceper:"ราคาต่อ",
    quantity:"จำนวน",
};
