<template>
  <div>
    <b-container class="mb-5 thai-font">
      <b-card text-variant="white" class="bg-card mt-3 p-2" style="margin-bottom: -10px">
        <b-card-text class="text-danger">
          กรุณาเลือกข้อที่ตรงกับท่านมากที่สุดเพื่อท่านจะได้ทราบว่าท่านเหมาะที่จะลงทุนในทรัพย์สินประเภทใด
        </b-card-text>
      </b-card>
      <!-- <b-card text-variant="white" class="bg-card mt-3 p-2" style="margin-bottom: -10px">
        <b-card-text class="text">
          ระดับความเสี่ยงในการลงทุนของท่าน
        </b-card-text>
        <div class="form-group">
          <select class="form-control" id="currentRiskLevel" v-model="currentRiskLevel"
            @change="setTotalScoreBasedOnRiskLevel(currentRiskLevel)">
            <option value="เสี่ยงต่ำ">เสี่ยงต่ำ</option>
            <option value="เสี่ยงปานกลางค่อนข้างต่ำ">เสี่ยงปานกลางค่อนข้างต่ำ</option>
            <option value="เสี่ยงปานกลางค่อนข้างสูง">เสี่ยงปานกลางค่อนข้างสูง</option>
            <option value="เสี่ยงสูง">เสี่ยงสูง</option>
            <option value="เสี่ยงสูงมาก">เสี่ยงสูงมาก</option>
          </select>
        </div>
      </b-card> -->
      <form @submit.prevent="getAnswer">
        <div v-for="(item, index) in suitabiltyQuestions" :key="index">
          <b-card text-variant="white" class="bg-card mb-3">
            <b-card-text>
              <span>{{ index + 1 }}.</span>
              {{ item.question }}
            </b-card-text>
            <b-row v-if="item.type == 1">
              <b-col cols="6" v-for="(option, optionIndex) in item.choice_list" :key="optionIndex">
                <label class="container1" :for="`q${index}_option${optionIndex}`">{{ option.ans_text }}
                  <input type="radio" :id="`q${index}_option${optionIndex}`" :name="index" :value="option.no"
                    v-model="selectedOptions[index].ans" @change="updateUserAnswers(option, item)" />
                  <span class="checkmark"></span>
                </label>
              </b-col>
            </b-row>

            <b-row v-else>
              <b-col cols="12" v-for="(checkbox, index) in item.choice_list" :key="index">
                <div class="form-group form-check">
                  <label>
                    <input type="checkbox" class="form-check-input" :value="checkbox.no"
                      :checked="[...item.ans_list].includes(checkbox.no)"
                      @change="updateUserAnswers(checkbox, item, index)" />
                    <p class="left mt-1" style="
    margin-bottom: 0px;
">{{ checkbox.ans_text }}</p>
                  </label>
                </div>
              </b-col>
            </b-row>
            <span class="text-danger" v-if="!item.ans ||
              (item.ans <= 0 &&
                (!item.ans_list || item.ans_list.length == 0) &&
                validationFlag)
              ">
              กรุณาเลือกคำตอบ
            </span>
          </b-card>
        </div>

        <div class="mt-4">
          <h5>ผลการประเมินความเหมาะสมในการลงทุน</h5>
          <b-row class="mt-3 row-left">
            <b-col cols="2" class="borderd">
              <div class="text-left">ผลคะแนนที่ท่านได้</div>

              <div class="mt-2 text-center">
                <vue-ellipse-progress :progress="progress" :legend-value="rating" :size="100" emptyColorFill="#1c262b"
                  color="#09BE8B" thickness="10%" emptyThickness="10%" fontColor="#09BE8B" fontSize="30px">
                </vue-ellipse-progress>
              </div>
            </b-col>

            <b-col cols="3" class="borderd">
              <div class="text-left">การวิเคราะห์ผล</div>
              <h6 class="text-left mt-2">ท่านเป็นนักลงทุนประเภท</h6>
              <h6 class="text-left mt-2">
                {{ getRiskGroup(rating) }}
              </h6>
            </b-col>

            <b-col cols="3" class="borderd">
              <div class="text-left">ประเภทตราสารที่สามารถลงทุนได้</div>
<!--               <div class="text-left font-size mt-2">ตราสารหนี้</div>
              <div class="text-left font-size mt-2">ตราสารทุนบางส่วน</div>
              <div class="text-left font-size mt-2">ตราสารอนุพันธ์เล็กน้อย</div>
              <div class="text-left font-size mt-2">
                หน่วยลงทุนที่มีระดับความเสี่ยง 1-5
              </div> -->
              <div class="text-left font-size mt-2">
                สินทรัพย์ดิจิทัลสัดส่วน {{ getDigitalAssets(rating) }}
              </div>
            </b-col>

            <b-col cols="4">
              <table style="width: 100%">
                <tr>
                  <th>ประเภทผู้ลงทุน</th>
                  <th>
                    สัดส่วนการลงทุนใน<br />
                    สินทรัพย์ดิจิทัล
                  </th>
                </tr>
                <tr>
                  <td class="text-left">&nbsp; เสี่ยงต่ำ</td>
                  <td class="text-center">&lt;1%</td>
                </tr>
                <tr>
                  <td class="text-left">&nbsp; เสี่ยงปานกลางค่อนข้างต่ำ</td>
                  <td class="text-center">&lt;5%</td>
                </tr>
                <tr>
                  <td class="text-left">&nbsp; เสี่ยงปานกลางค่อนข้างสูง</td>
                  <td class="text-center">&lt;10%</td>
                </tr>
                <tr>
                  <td class="text-left">&nbsp; เสี่ยงสูง</td>
                  <td class="text-center">&lt;15%</td>
                </tr>
                <tr>
                  <td class="text-left">&nbsp; เสี่ยงสูงมาก</td>
                  <td class="text-center">&lt;20%</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>

        <div v-if="disableAccept" class="row px-2 mt-5">
          <div class="row">
            <div class="col-auto ms-1">
              <input
                type="checkbox"
                v-model="checkedSubmit"
                class="form-check-input"
                id="checkedSubmit"
              />
            </div>
            <div class="col me-2">
              <label for="checkedSubmit">
                <h6 class="text-danger">
                  กรุณา <u>"ทำเครื่องหมายในช่องสี่เหลี่ยม"</u><br>
                  <!-- เนื่องจากผลการประเมินความเหมาะสมในการลงทุนของ ท่านอยู่ในระดับ "{{ getRiskGroup(rating) }}" อาจไม่เหมาะกับระดับความเสี่ยงในการลงทุนสินทรัพย์ดีจิทัล หากท่านต้องการลงทุนกรุณา
                  ยืนยันยอมรับความเสี่ยงการลงทุนที่ไม่สอดคล้องกับผลประเมิน -->
                  <p class="mt-2">
                    เนื่องจาก คริปโทเคอร์เรนซีมีความเสี่ยงสูง โปรดศึกษาและทำความเข้าใจความเสี่ยงของคริปโทเคอร์เรนซีอย่างรอบด้าน เพราะท่านอาจสูญเสียเงินลงทุนได้ทั้งจำนวน
                  </p>
                </h6> 
              </label>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-flex p-2">
            <button type="submit" class="font-family_th mt-32 fda_submit_btn-primary lh-24" :disabled="disableAccept && !checkedSubmit" :class="disableAccept && !checkedSubmit ? 'check-disable' : ''">
              ส่งแบบทดสอบ
            </button>
          </div>
          <div class="d-flex p-2">
            <button type="button" class="font-family_th mt-32 fda_submit_btn-close lh-24" @click="goCancel()">
              ยกเลิก
            </button>
          </div>
        </div>

      </form>
    </b-container>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
export default {
  data() {
    return {
      suitabiltyQuestions: [],
      selectedOptions: [],
      score: 0,
      updatedUserAnswers: null,
      rating: null,
      progress: 0,
      validationFlag: false,
      ageRisk: 0,
      totalScore: 0,
      currentRiskLevel: null,
      low: [1, 1, [1], 1, 1, 1, 1, 1, 1],
      medLow: [1, 2, [2], 2, 2, 2, 2, 2, 2],
      medHigh: [2, 2, [3], 2, 2, 2, 2, 2, 2],
      high: [3, 3, [3], 3, 3, 3, 3, 3, 3],
      higher: [4, 4, [4], 4, 4, 4, 4, 4, 4],
      disableAccept: false,
      checkedSubmit: false,
    };
  },
  mounted() {
    const storedData = localStorage.getItem("kycReviewData")
    const formData = JSON.parse(storedData);
    let self = this;
    HTTP.post("/uc/member/get-suitabilty-questions", {
      suitId: formData.suitTestId
    })
      .then(function (response) {
        //console.log("res", response);
        self.suitabiltyQuestions = response.data.items;
        self.suitabiltyQuestions.forEach((ans, index) => {
          self.selectedOptions[index] = ans;
        });
        //console.log(self.suitabiltyQuestions)
        self.calculateTotalScore();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    updateUserAnswers(option, item, index) {
      this.updateScores();
      // Find the question in the suitabilityQuestions array
      const questionToUpdate = this.suitabiltyQuestions.find((question) => question.no === item.no);

      if (questionToUpdate) {
        // Update the user's answer (ans) for the question
        const selectedChoiceIndex = questionToUpdate.ans_list.indexOf(option.no);

        if (selectedChoiceIndex === -1) {
          // Checkbox was checked, add the option to ans_list
          questionToUpdate.ans_list.push(option.no);
        } else {
          // Checkbox was unchecked, remove the option from ans_list
          if (questionToUpdate.type === 1) {
            questionToUpdate.ans_list.push(option.no);
          }else{
            questionToUpdate.ans_list.splice(selectedChoiceIndex, 1);
          }
        }

        // Calculate the score for this question based on the selected choices
        questionToUpdate.score = 0; // Reset the score
        for (const ansNo of questionToUpdate.ans_list) {
          const selectedChoice = questionToUpdate.choice_list.find((choice) => choice.no === ansNo);
          if (selectedChoice) {
            questionToUpdate.score = selectedChoice.score;
          }
        }

        // Recalculate the total score
        this.totalScore = this.suitabiltyQuestions.reduce((total, question) => {
          if (question.type === 1) {
            total += question.score;
          } else if (question.type === 2 && question.ans_list.length > 0) {
            const ansScores = question.ans_list.map((ansNo) => {
              const selectedChoice = question.choice_list.find((choice) => choice.no === ansNo);
              return selectedChoice ? selectedChoice.score : 0;
            });
            total += Math.max(...ansScores);
          }
          return total;
        }, 0);

        // Update the rating and progress based on the total score and ageRisk
        this.rating = this.totalScore;
        this.rating += this.ageRisk;
        this.currentRiskLevel = this.getRiskGroup(this.rating);
        //console.log(this.currentRiskLevel);
        this.progress = (this.rating * 100) / 40;
      }
    },
    setTotalScoreBasedOnRiskLevel(selectedRiskLevel) {
      //console.log(selectedRiskLevel)
      let riskLever = []
      if (selectedRiskLevel == "เสี่ยงต่ำ") {
        riskLever = this.low
      } else if (selectedRiskLevel == "เสี่ยงปานกลางค่อนข้างต่ำ") {
        riskLever = this.medLow
      } else if (selectedRiskLevel == "เสี่ยงปานกลางค่อนข้างสูง") {
        riskLever = this.medHigh
      } else if (selectedRiskLevel == "เสี่ยงสูง") {
        riskLever = this.high
      } else {
        riskLever = this.higher
      }
      //console.log(riskLever.length)
      for (let i = 0; i < riskLever.length; i++) {
        if (Array.isArray(riskLever[i])) {
          // For type 2 questions (ans_list), update the ans_list
          this.suitabiltyQuestions[i].ans_list = riskLever[i];
        } else {
          // For type 1 questions (ans), update the ans
          this.suitabiltyQuestions[i].ans = riskLever[i];
        }
      }
      this.suitabiltyQuestions.forEach((ans, index) => {
        this.selectedOptions[index] = ans;
      });
      //console.log(this.suitabiltyQuestions)
      this.calculateTotalScore();
    },
    calculateTotalScore() {
      this.totalScore = this.suitabiltyQuestions.reduce((total, question) => {
        if (question.type === 1) {
          // For type 1 questions, find the selected answer and add its score
          const selectedChoice = question.choice_list.find(
            (choice) => choice.no === question.ans
          );
          total += selectedChoice ? selectedChoice.score : 0;
        } else if (question.type === 2) {
          // For type 2 questions, use ans_list to calculate the score
          if (question.ans_list && question.ans_list.length > 0) {
            const maxScore = Math.max(
              ...question.ans_list.map((ansNo) => {
                const selectedChoice = question.choice_list.find(
                  (choice) => choice.no === ansNo
                );
                return selectedChoice ? selectedChoice.score : 0;
              })
            );
            total += maxScore;
          }
        }
        return total;
      }, 0);
      // Update the rating and progress based on the total score and ageRisk
      this.rating = this.totalScore;
      this.rating += this.ageRisk;
      this.currentRiskLevel = this.getRiskGroup(this.rating)
      //console.log(this.currentRiskLevel)
      this.progress = (this.rating * 100) / 40;
    },
    updateScores() {
      // Iterate through the suitabilityQuestions
      this.suitabiltyQuestions.forEach((question) => {
        if (question.type === 1) {
          const selectedChoice = question.choice_list.find((choice) => choice.no === question.ans);
          if (selectedChoice) {
            question.score = selectedChoice.score;
          }
        } else if (question.type === 2 && question.ans_list && question.ans_list.length > 0) {
          // For type 2 questions, find the maximum score from the 'ans_list'
          const maxScore = Math.max(
            ...question.ans_list.map((ansNo) => {
              const selectedChoice = question.choice_list.find((choice) => choice.no === ansNo);
              return selectedChoice ? selectedChoice.score : 0;
            })
          );
          // Update the 'score' with the maximum score
          question.score = maxScore;
        }
      });
    },

    goCancel() {
      this.$router.push({
        name: 'ReviewKYC'
      });
    },
    getAnswer() {
      this.validationFlag = true;
      let self = this;
      const suitabiltyQuestionsJSON = JSON.stringify(this.suitabiltyQuestions);
      if (!this.suitabiltyQuestions.find(
        (item) =>
          !item.ans ||
          (item.ans <= 0 && (!item.ans_list || item.ans_list.length == 0))
      )
      ) {
        HTTP.post("/uc/member/save-quiz-ans", {
          quizType: 1,
          quizId: 1,
          quizAnsData: suitabiltyQuestionsJSON,
          score: this.rating,

        })
          .then((res) => {
            //console.log(res);
            this.$router.push({
              name: "ReviewKYC",
              params: {
                suitabilityGroupId: res.data.data.id,
                suitabilityGroupText: this.getRiskGroup(res.data.data.score),
                suitabilityScore: res.data.data.score,
                flagNewSuitability: true,
              },
            });
            self.$notify({
              group: "notification",
              type: "success",
              title: "Success",
              text: "Submitted Successfully",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        self.$notify({
          group: "notification",
          type: "error",
          title: "",
          text: "Please complete the question",
        });
      }
    },
    getRiskGroup(score) {
      if (score <= 15) {
        this.disableAccept = true;
        return "เสี่ยงต่ำ";
      } else if (score > 15 && score <= 21) {
        this.disableAccept = true;
        return "เสี่ยงปานกลางค่อนข้างต่ำ";
      } else if (score >= 22 && score <= 29) {
        this.disableAccept = true;
        return "เสี่ยงปานกลางค่อนข้างสูง";
      } else if (score >= 30 && score <= 36) {
        this.disableAccept = false;
        this.checkedSubmit = false;
        return "เสี่ยงสูง";
      } else if (score >= 37) {
        this.disableAccept = false;
        this.checkedSubmit = false;
        return "เสี่ยงสูงมาก";
      } else {
        return "";
      }
    },
    getDigitalAssets(score) {
      if (score <= 15) {
        return "<1%";
      } else if (score > 15 && score <= 21) {
        return "<5%";
      } else if (score >= 22 && score <= 29) {
        return "<10%";
      } else if (score >= 30 && score <= 36) {
        return "<15%";
      } else if (score >= 37) {
        return "<20%";
      } else {
        return "";
      }
    },
    KYCflag() {
      localStorage.setItem("fatcaSet", true)
    },
    ageRiskCalculator() {
      const storedData = JSON.parse(localStorage.getItem('kycReviewData'));
      const age = this.getAge(storedData.birthDay);

      if (age >= 60) {
        this.ageRisk = 1;
      } else if (age >= 45 && age <= 59) {
        this.ageRisk = 2;
      } else if (age >= 35 && age <= 44) {
        this.ageRisk = 3;
      } else if (age < 35) {
        this.ageRisk = 4;
      } else {
        this.ageRisk = 0;
      }
    },
    getAge(DOB) {
      if (DOB) {
        const today = new Date();
        const birthdate = new Date(DOB);
        //หาวันเกิดปีปัจจุบัน
        const tbirthDay = new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate());
        return (
          today.getFullYear() -
          birthdate.getFullYear() -
          (today < tbirthDay ? 1 : 0)
        );
      }
      return null;
    },
  },
  created() {
    this.KYCflag();
    this.ageRiskCalculator();
  },
};
</script>

<style scoped>
.fda_submit_btn-primary {

  width: 344px;
  height: 36px;
  font-family: "sarabun";
  display: block;
  margin: auto;
  background-color: #09BE8B;
  border-radius: 4px;
  color: #0a0d10;
  font-weight: 500;

  &:hover {
    background-color: #f28e38;
  }

}

.fda_submit_btn-close {

  width: 344px;
  height: 36px;
  font-family: "sarabun";
  display: block;
  margin: auto;
  background-color: #38454d;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 500;

  &:hover {
    background-color: #4b5d68;
  }

}

.mt-32 {
  margin-top: 30px;
}

/* The container */
.container1 {
  display: block;
  position: relative;
  padding-left: 28px;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #191b1c;
  border-radius: 50%;
  border: 2px solid #38454d;
}

/* When the radio button is checked, add a yellow background */
.container input:checked~.checkmark {
  background-color: #eb8125;
  border: 2px solid #eb8125;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

table {
  border: 1px solid #677f8e;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #677f8e !important;
  font-size: 12px;
}

th {
  text-align: center !important;
  font-size: 12px;
}

.bg-card {
  background-color: #1c262b;
}

.left {
  margin-left: 5px;
  font-size: 13px;
}

.font-size {
  font-size: 12px;
}

.text-color {
  color: #002661;
}

.borderd {
  border-left: 1px solid #677f8e;
}

.text-left {
  margin-left: 20px;
}

.row-left {
  margin-left: 1px !important;
}

.center {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #677f8e;
}
.check-disable {
  opacity: 50%;
}
</style>
