<template>
  <div>
    <!-- Color Picker -->
    <div class="color-display" :style="{ backgroundColor: selectcolor }">
      Selected Color: {{ selectcolor }}
    </div>
    <template>
      <verte 
       picker="square"
       model="hex"
       display="widget"  
       :rgbSliders="true" 
       :showHistory="null" 
       :enableAlpha="false"
       @input="changecolor($event)">
      </verte>
    </template>

    <!-- Display Selected Color -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      selectcolor: "",  // ตัวแปรเก็บค่าสีที่เลือก
    }
  },
  methods: {
    changecolor(color) {
      this.selectcolor = color; // เก็บค่าสีที่เลือก
      this.$emit("check-colorset", color); // ส่งค่าไปยังพาเรนต์คอมโพเนนต์
    }
  }
}
</script>

<style lang="scss">
.verte__menu {
  background-color: #141b1f !important;
  width: 340px !important;
  box-shadow: none !important;
}

.verte-picker__cursor {
  box-shadow: none !important;
  border: 2px solid #eb8125 !important;
  width: 9px !important;
  height: 9px !important;
}

.verte-picker__origin {
  background: linear-gradient(to right, #4f3af9, #2ee6d7, #7afc46,#e5422c);
  border-radius: 5px;
}

.verte-picker__canvas {
  width: 340px;
}

.slider {
  margin-left: 4px !important;
  margin-right: -1px !important;
}

.slider__input {
  color: aliceblue;
  background-color: #191b1c;
  border-radius: 5px;
  margin: 6px !important;
  padding: 6px !important;
  margin-right: -15px !important;
}

.slider__track {
  height: 4px !important;
}

.slider__handle {
  border: 2px solid #eb8125 !important;
  width: 11px !important;
  height: 11px !important;
}

.slider__fill {
  height: 1 !important;
}

.verte__input {
  display: none;
}

.verte__model,
.verte__submit {
  display: none !important;
}

.verte__icon {
  display: none;
}

/* เพิ่มสไตล์สำหรับการแสดงสี */
.color-display {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;
  color: white;
  border: 2px solid #eb8125;
  background-color: #000;
  font-weight: bold;
  font-size: 16px;
}
</style>
