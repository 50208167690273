<template>
  <div v-if="isLoading">
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
  <div v-else class="wraper-main mt-4">
    <Dynamic-Table
      v-if="totalCoinMarket > 0"
      :TableHeader="TableHeading"
      :favCoinRows="favCoinRows"
      :TableData="filteredItems()"
      :Showfavorite="true"
      current_page="favourite"
    ></Dynamic-Table>
    <p v-else class="text-center">--ไม่มีข้อมูล--</p>
    <ReviewKYCModel />
    <PasswordAlertModal />
  </div>

</template>

<script>
import { HTTP } from "@/config/axios";
import EventBus from "@/event-bus";
import Table from "@/components/table/Table.vue";
var SockJS = require("sockjs-client");
var Stomp = require("stompjs");
export default {
  name: "favorite",
  components: {
    "Dynamic-Table": Table,
  },
  metaInfo() {
    let pageTitle = "Favorite";
    return {
      title: pageTitle,
      titleTemplate: "%s | "+ this.TITLE ,
    };
  },

  data() {
    return {
      reload:0,
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingChart: "24h Chart",
          headingLastPrice: "Last Price",
          headingHourChange: "24h Change",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
        },
      ],
      defaultPath: "btc_usdt",
      showSearchBox: false,
      currentCoin: {
        base: "",
        coin: "",
        symbol: "BTC/USDT",
      },
      plate: {
        maxPostion: 20,
        askRows: [],
        bidRows: [],
      },
      ticker: {
        tickerRows: [],
      },
      priceInfo: {
        last: 0,
        chg: 0,
        high24: 0,
        low24: 0,
        vol24: 0,
        pChg: 0,
        textColor: "",
      },
      coinMarket: [],
      chartSymbolUrl: "/libchart/index.php?symbol=BTC%2FTHB",
      test: "",
      favouriteCoinSymbol: [],
      favCoinRows: [],
      totalCoinMarket: 0,
      isUserLogin: false,
      isLoading: true,
      previousPath: ''
    };
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
    }
    this.getSymbolThumb();
  },
  mounted() {
    this.previousPath = this.$previousPath();
    this.defaultSymbol();
    //this.getSymbolThumb();
    //this.getBidAsk();
    //this.getTickers();
    //this.updateDataTrade();
    EventBus.$on("get-Symbol-Thumb-trend-favourite", () => {
      this.getSymbolThumb();
    });
  },
  methods: {
    defaultSymbol() {
      if (this.$route.params.pair == undefined) {
        this.currentCoin.symbol = "BTC/THB";
        this.$route.params["pair"] = "btc_thb";
      } else {
        let pair = this.$route.params.pair;
        let dataArr = pair.split("_");

        this.currentCoin.symbol = (dataArr[0] + "/" + dataArr[1]).toUpperCase();
        let symbolEncode = encodeURIComponent(this.currentCoin.symbol);
        this.chartSymbolUrl = "/libchart/index.php?symbol=" + symbolEncode;
      }
    },
    updateDataTrade() {
      if (this.stompClient) {
        this.stompClient.ws.close();
      }

      var stompClient = null;
      var that = this;
      var symbol = that.currentCoin.symbol;
      var url = this.MARKET_WS_URL + "/market/market-ws";
      //console.log("MARKET_WS_URL", this.MARKET_WS_URL);
      var socket = new SockJS(url);
      stompClient = Stomp.over(socket);
      this.stompClient = stompClient;
      stompClient.debug = false;

      stompClient.connect({}, function (frame) {
        stompClient.subscribe("/topic/market/thumb", function (msg) {
          var resp = JSON.parse(msg.body);
          //   console.log(resp);
          that.updatePriceInfo(resp);
        });

        stompClient.subscribe("/topic/market/trade/" + symbol, function (msg) {
          var resp = JSON.parse(msg.body);
          //   console.log(resp);

          that.updateTickers(resp);
        });

        stompClient.subscribe(
          "/topic/market/trade-plate/" + symbol,
          function (msg) {
            var resp = JSON.parse(msg.body);
            // console.log(resp);

            that.updateBidAsk(resp);
          }
        );
      });
    },
    getBidAsk() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;

      HTTP.post("/market/exchange-plate", params, {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        if (resp.ask) {
          var asks = resp.ask;
          this.plate.askRows = [];
          for (var i = this.plate.maxPostion - 1; i >= 0; i--) {
            var ask = {
              price: asks[i].price.toFixed(2),
              amount: asks[i].amount.toFixed(6),
              total: (asks[i].price * asks[i].amount).toFixed(2),
            };
            this.plate.askRows.push(ask);
          }
          if (this.plate.askRows.length > 20) {
            this.plate.askRows = this.plate.askRows.slice(0, 20);
          }
        } else {
          var bids = resp.items;
          this.plate.bidRows = [];
          for (var i = this.plate.maxPostion - 1; i >= 0; i--) {
            var bid = {
              price: bids[i].price.toFixed(2),
              amount: bids[i].amount.toFixed(6),
              total: (bids[i].price * bids[i].amount).toFixed(2),
            };
            this.plate.bidRows.push(bid);
          }
          if (this.plate.bidRows.length > 20) {
            this.plate.bidRows = this.plate.bidRows.slice(0, 20);
          }
        }
        // console.log(resp);
      });
    },
    updateBidAsk(resp) {
      // console.log(resp)
      var that = this;
      if (resp.direction == "SELL") {
        var asks = resp.items;
        this.plate.askRows = [];
        for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
          var ask = {
            price: asks[i].price.toFixed(2),
            amount: asks[i].amount.toFixed(6),
            total: (asks[i].price * asks[i].amount).toFixed(2),
          };
          that.plate.askRows.push(ask);
        }
        if (that.plate.askRows.length > 20) {
          that.plate.askRows = that.plate.askRows.slice(0, 20);
        }
      } else {
        var bids = resp.items;
        this.plate.bidRows = [];
        for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
          var bid = {
            price: bids[i].price.toFixed(2),
            amount: bids[i].amount.toFixed(6),
            total: (bids[i].price * bids[i].amount).toFixed(2),
          };
          that.plate.bidRows.unshift(bid);
        }
        if (that.plate.bidRows.length > 20) {
          that.plate.bidRows = that.plate.bidRows.slice(0, 20);
        }
      }
    },
    getTickers() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/latest-trade", params, {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        if (resp.length > 0) {
          this.priceInfo.last = resp[0].price;
          for (var i = 0; i < resp.length; i++) {
            let date = new Date(resp[i].time);
            let hours = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            let second = ("0" + date.getSeconds()).slice(-2);
            let time = hours + ":" + minute + ":" + second;
            resp[i].price = resp[i].price.toFixed(2);
            resp[i].amount = resp[i].amount.toFixed(6);
            resp[i].time = time;
            this.ticker.tickerRows.push(resp[i]);
          }
        }
        if (this.ticker.tickerRows.length > 20) {
          this.ticker.tickerRows = this.ticker.tickerRows.slice(0, 20);
        }
      });
    },
    updateTickers(resp) {
      // console.log(resp);
      var that = this;
      if (resp.length > 0) {
        for (var i = 0; i < resp.length; i++) {
          let date = new Date(resp[i].time);
          let hours = ("0" + date.getHours()).slice(-2);
          let minute = ("0" + date.getMinutes()).slice(-2);
          let second = ("0" + date.getSeconds()).slice(-2);
          let time = hours + ":" + minute + ":" + second;
          resp[i].price = resp[i].price.toFixed(2);
          resp[i].amount = resp[i].amount.toFixed(6);
          resp[i].time = time;
          that.priceInfo.last = resp[i].price;
          that.ticker.tickerRows.unshift(resp[i]);
        }
      }
      if (that.ticker.tickerRows.length > 20) {
        that.ticker.tickerRows = that.ticker.tickerRows.slice(0, 20);
      }
    },
    async getSymbolThumb() {
      try {
        var params = {};
        params["symbol"] = this.currentCoin.symbol;
        params["size"] = 15;

        await HTTP.post("/market/symbol-thumb-trend", {
          emulateJSON: true,
        }).then((response) => {
          var resp = response.data;
          this.coinMarket = [];
          for (let i = 0; i < resp.length; i++) {
            // console.log(resp)
            let symbolArr = resp[i].symbol.split("/");
            let symbol = (symbolArr[0] + "_" + symbolArr[1]).toLowerCase();
            let color = "";
            if (resp[i].chg > 0) {
              color = "green";
            } else if (resp[i].chg < 0) {
              color = "red";
            }
            let coinMarket = {
              Coinsybmol: resp[i].symbol,

              StarIcon: require("../../assets/images/favoriteTable/star.png"),
              CoinImage:
                this.MARKET_WS_URL + `/market/coin/image/${resp[i].logoFileId}`,
              CoinName: resp[i].fullName,
              lastPrice: resp[i].close,
              hourChange: resp[i].chg,
              volume: resp[i].marketCapVolume24h,
              marketCap: resp[i].marketCap,
              series: [
                {
                  name: "STOCK ABC",
                  data: resp[i].trend,
                },
              ],

              //
              // price: resp[i].close,
              // pChg: resp[i].chg,
              // url: "/exchange/" + symbol,
              // color: color,
            };
            this.coinMarket.push(coinMarket);

            if (this.currentCoin.symbol == resp[i].symbol) {
              if (resp[i].chg < 0) {
                this.priceInfo.textColor = "red";
              } else if (resp[i].chg > 0) {
                this.priceInfo.textColor = "green";
              }

              this.priceInfo.chg = resp[i].change;
              this.priceInfo.high24 = resp[i].high;
              this.priceInfo.low24 = resp[i].low;
              this.priceInfo.vol24 = resp[i].volume;
              this.priceInfo.pChg = resp[i].chg;
            }
          }
          if (this.isUserLogin) {
            this.getFov();
          } else {
            let a = localStorage.getItem("fav");
            if(a == null){
              this.isLoading = false;
              if((this.reload == 0 && !this.previousPath.startsWith('/market'))){
                this.$router.push({ path: "/market/all-coins" });
              }
              
            }
            let get_favlocal = a.split(",");
            if (get_favlocal.length > 0) {
              for (let i = 0; i < this.coinMarket.length; i++) {
                for (let j = 0; j < get_favlocal.length; j++) {
                  let typeSymbol = this.coinMarket[i].Coinsybmol;
                  if (get_favlocal[j] == typeSymbol) {
                    this.$set(this.favCoinRows, typeSymbol, true);
                  }
                }
              }
             
              this.filteredItems();
            }
            this.isLoading = false;
          }
        });
      } catch (error) {}
    },
    getFov() {
      HTTP.post("uc/member/get-member-favorite").then((result) => {
        let memberFavorite = result.data;
        if (memberFavorite.code == 0) {
          for (let keyy in this.coinMarket) {
            for (let key in memberFavorite.data) {
              let typeSymbol = this.coinMarket[keyy].Coinsybmol;
              if (memberFavorite.data[key].coinId == typeSymbol) {
                if (memberFavorite.data[key].favorite) {
                  this.reload += 1
                  this.$set(this.favCoinRows, typeSymbol, true);
                  break;
                } else {
                  this.$set(this.favCoinRows, typeSymbol, false);
                  break;
                }
              } else {
                this.$set(this.favCoinRows, typeSymbol, false);
              }
            }
          }
          if(this.reload == 0 && !this.previousPath.startsWith('/market')){
            this.$router.push({ path: "/market/all-coins" });
          }
          this.isLoading = false;
          this.filteredItems();
        }
      });
    },
    updatePriceInfo(resp) {
      for (let i = 0; i < this.coinMarket.length; i++) {
        if (this.coinMarket[i].Coinsybmol == resp.symbol) {
          this.coinMarket[i].lastPrice = resp.close;
          this.coinMarket[i].hourChange = resp.chg;
          this.coinMarket[i].volume = resp.marketCapVolume24h;
          this.coinMarket[i].marketCap = resp.marketCap;
          if (resp.chg > 0) {
            this.coinMarket[i].color = "green";
          } else if (resp.chg < 0) {
            this.coinMarket[i].color = "red";
          }

          break;
        }
      }

      if (this.currentCoin.symbol == resp.symbol) {
        if (resp.chg < 0) {
          this.priceInfo.textColor = "red";
        } else if (resp.chg > 0) {
          this.priceInfo.textColor = "green";
        }

        this.priceInfo.chg = resp.change;
        this.priceInfo.high24 = resp.high;
        this.priceInfo.low24 = resp.low;
        this.priceInfo.vol24 = resp.volume;
        this.priceInfo.pChg = resp.chg;
      }
    },
    selectMarketSymbol(url) {
      window.location.href = url;
    },
    filteredItems() {
      if (this.isUserLogin) {
        let compareWith = this.$store.state.marketCoinSearchField;
        let data = this.coinMarket.filter((item) => {
          return this.favCoinRows[item.Coinsybmol];
        });
        this.totalCoinMarket = data.length;
        this.isLoading = false;
        return data;
      } else {
        let data = this.coinMarket.filter((item) => {
          return this.favCoinRows[item.Coinsybmol];
        });
        this.totalCoinMarket = data.length;
        this.isLoading = false;
        return data;
      }
    },
  },
};
</script>

<style lang="scss"></style>
