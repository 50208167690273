<template>
  <div>
    <b-modal
      class="choose-network"
      id="choose-network"
      :hide-footer="true"
      :hide-header="true"
    >
      <!-- {{ coinUnit }} -->
      <div>
        <h5>เลือก Network</h5>
        <span class="close" @click="$bvModal.hide('choose-network')">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <p class="info">กรุณาตรวจสอบ Network ก่อนโอนทุกครั้ง</p>
        <p class="info">“มิเช่นนั้นทรัพย์สินของคุณอาจสูญหายได้”</p>
        <div class="coin-box-c">
          <!-- <span class="mc">My Coin</span>
          <span class="ac">Available</span> -->
          <div
            class="coin-box"
            v-for="(network, index) in filteredNetworkData"
            :key="index"
          >
            <div
              style="cursor: pointer"
              @click="
                selectedNetwork = network.networkName;
                (networkId = network.id),
                  (selectedNetworkChain = network.networkChain);
              "
            >
              <div class="radio_btn a1">
                <input
                  class="normal"
                  type="radio"
                  id="12"
                  name="coin"
                  @click="
                    selectedNetwork = network.networkName;
                    networkId = network.id;
                    selectedNetworkChain = network.networkChain;
                  "
                  :checked="selectedNetwork == network.networkName"
                />
              </div>

              <p class="coin-name">{{ network.networkName }}</p>
              <p class="coin-fee">
                Fee {{ network.fee }} {{ coinUnit }} (~{{ $MyFunc.NumberFormat(feed * network.fee) }}
                THB)
              </p>
              <p class="coin-quantity">ใช้เวลา ~{{ network.estimatedTime }}</p>
            </div>
          </div>
          <!-- <div class="coin-box" style="opacity: 0.5">
            <div class="radio_btn a1" style="pointer-events: none">
              <input
                class="normal"
                type="radio"
                id="12"
                name="coin"
                value="1"
              />
            </div>
            <p class="coin-name">Bitcoin Segwit</p>
            <p class="coin-fee">Fee 0.0002 BTC (~144.02 THB)</p>
            <p class="coin-quantity q-inactive">ระงับ</p>
          </div> -->
          <!-- <div class="coin-box">
            <div class="radio_btn a1">
              <input
                class="normal"
                type="radio"
                id="12"
                name="coin"
                value="1"
              />
            </div>
            <p class="coin-name">Bitcoin Segwit</p>
            <p class="coin-fee">Fee 0.0002 BTC (~144.02 THB)</p>
            <p class="coin-quantity">ใช้เวลา ~5 นาที</p>
          </div> -->
        </div>
        <div class="fb">
          <button
            @click="
              $bvModal.hide('choose-network');
              selectednetwork();
            "
          >
            บันทึก
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
export default {
  props: ["selectedCoin", "coinUnit"],
  data() {
    return {
      Thaibaht_balance: [],
      networkData: [],
      selectedNetwork: "",
      networkId: 0,
      lastprice: 0,
      datascale: [],
      selectedNetworkChain: "",
    };
  },

  methods: {
    selectednetwork() {
      //console.log("networkChain : "+this.selectedNetworkChain);
      this.$emit("network", this.selectedNetwork);
      this.$emit("networkChain", this.selectedNetworkChain);
      this.$emit("networkId", this.networkId);
    },
  },
  computed: {
    filteredNetworkData() {
      return this.networkData.filter((data) =>
        data.coinId.toLowerCase().includes(this.selectedCoin.toLowerCase())
      );
    },
    feed() {
      const result = this.datascale.find(
        (data) => data.coin.name === this.coinUnit
      );
      var lastprice = 0;
      if (result) {
        lastprice = result.lastPrice;
      }
      return lastprice; // คุณสามารถเปลี่ยน 'null' เป็นค่าเริ่มต้นที่คุณต้องการได้
    },
  },
  created() {
    // getting networks
    HTTP.post("/market/get-all-coin-networks")
      .then((res) => {
        this.networkData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // getting thb balance
    HTTP.get("/uc/asset/wallet/")
      .then((res) => {
        this.datascale = res.data.data;
        res.data.data.map((item) => {
          if (item.coin.name == this.coinUnit) {
            this.lastprice = item.lastPrice;
          }
          if (item.coin.unit == "THB") {
            // console.log(item);

            this.Thaibaht_balance = item.balance;
            this.Thaibaht_balance = this.Thaibaht_balance.toFixed(2);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style lang="scss">
#choose-network___BV_modal_content_ {
  width: 480px;
  height: 508px;
}
#choose-network___BV_modal_body_ {
  padding-right: 4px !important;
  .radio_btn {
    position: absolute;
    left: -30px;
    top: 20px;
  }
  input[type="radio"]:after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: -4px;
    right: 4px;
    position: relative;
    background-color: #191b1c;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 3px solid #38454d;
  }

  input[type="radio"]:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: relative;
    background-color: #d6dde1;
    content: "";
    display: inline-block;
    visibility: visible;
  }
  .normal:checked:after {
    border: 4px solid #09BE8B;
  }
  h5{
    text-align: center;
    padding: 24px 0;
    width: 100%;
  }
  span.close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 25px;
  }
  .info {
    margin-bottom: 0;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #d6dde1;
    text-align: center;
  }
  .coin-box-c {
    position: relative;
    margin-top: 16px;
    .mc {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #677f8e;
      position: absolute;
      left: 75px;
      top: -23px;
    }
    .ac {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #677f8e;
      position: absolute;
      right: 20px;
      top: -23px;
    }
    .coin-box {
      width: 408px;
      height: 80px;
      background: #191b1c;
      border-radius: 4px;
      left: 40px;
      position: relative;
      margin-bottom: 13px;
      .coin-name {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        display: inline-block;
        margin-bottom: 0;
        padding: 16px;
      }
      .coin-fee {
        position: absolute;
        left: 16px;
        top: 41px;
        font-weight: 500;
        color: #9bacb6;
      }
      .q-inactive {
        color: #de2d40 !important;
      }
      .coin-quantity {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
        float: right;
        margin-bottom: 0;
        padding: 16px;
        padding-top: 25px;
      }
    }
  }
  .fb {
    text-align: center;
    margin-top: 33px;
    button {
      width: 120px;
      height: 36px;
      background-color: #09BE8B;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0a0d10;
    }
  }
}
</style>
