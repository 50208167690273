<template>
  <div>
    <b-container>
      <div class="borderd mt-5 mb-5">
        <h2>กรอกข้อมูล FATCA</h2>
        <h4 class="mt-4 mb-3">ข้าพเจ้ามีข้อใดข้อหนึ่งดังนี้:</h4>
        <p class="fatca_p">
          - มีสัญชาติอเมริกัน / เกิดที่อเมริกา /
          มีที่อยู่ในอเมริกาสำหรับพักอาศัยและติดต่อ
        </p>
        <p class="fatca_p">- โอนเงินเป็นประจำไปบัญชีที่อเมริกา</p>
        <div class="fatca_form">
          <div class="form-check form-check-inline">
            <input v-model="fatcaData.is_us_citizen_v" class="form-check-input" type="radio" id="fatcaCheck1" value="true"
              @change="setIsUSCitizenEnable" />
            <label class="form-check-label" for="fatcaCheck1">ใช่</label>
          </div>
          <div class="form-check form-check-inline">
            <input v-model="fatcaData.is_us_citizen_v_out" class="form-check-input" type="radio" id="fatcaCheck0" value="false"
              @change="setIsUSCitizen" />
            <label class="form-check-label" for="fatcaCheck0">ไม่ใช่</label>
          </div>
          <p v-if="fatcaCheckValidateRadioButton" class="text-danger">กรุณาเลือกข้อใดข้อหนึ่ง</p>
        </div>

        <div class="fatca_form">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_citizen" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_citizen"/>
            <label class="form-check-label" for="is_us_citizen"> เป็นพลเมืองอเมริกา</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_auth_person" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_auth_person"/>
            <label class="form-check-label" for="is_us_auth_person">
              มีกรีนการ์ดหรือบัตรผู้มีที่อยู่ถาวรในอเมริกา
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_green_card" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_green_card"/>
            <label class="form-check-label" for="is_us_green_card">
              มีที่อยู่ในอเมริกาเพื่อวัตถุประสงค์ทางภาษี
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_resident" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_resident"/>
            <label class="form-check-label" for="is_us_resident">
              เกิดในอเมริกาแต่สละสถานะพลเมืองแล้ว
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_renounce" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_renounce"/>
            <label class="form-check-label" for="is_us_renounce">
              มีที่อยู่ในอเมริกาสำหรับบัญชีที่เปิดกับบริษัท BITBULL THAILAND จำกัด
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_address" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_address"/>
            <label class="form-check-label" for="is_us_address">
              มีเบอร์โทรอเมริกา (ตนเองหรือผู้เกี่ยวข้อง) เกี่ยวข้องกับบัญชีที่เปิดกับบริษัท BITBULL THAILAND จำกัด
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_tel" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_tel"/>
            <label class="form-check-label" for="is_us_tel">
              มีการโอนงินอัตโนมัติจากบัญชีที่เปิดกับบริษัท BITBULL THAILAND จำกัด ไปบัญชีอเมริกา
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="fatcaData.is_us_bank_account" :true-value="true"
              :false-value="false" :disabled="!fatcaData.is_fatca_enable" id="is_us_bank_account"/>
            <label class="form-check-label" for="is_us_bank_account">
              ได้มอบอำนาจให้บุคคลที่อยู่ในอเมริกาทำธุรกรรมใดๆที่เกี่ยวข้องกับบัญชี บริษัท BITBULL THAILAND จำกัด
            </label>
          </div>
          <p v-if="fatcaCheckValidate && !isUsCitizenValidation()" class="text-danger">กรุณาเลือกอย่างน้อย 1 รายการ </p>

          <div class="fatca_form form-check text-center">
            <label class="btn btn-primary me-2" @click="GoFatca()" style="padding:10px 40px;">ถัดไป</label>
            <label class="btn-dark-custom" @click="CancelFatca()" style="padding:10px 40px;">ยกเลิก</label>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
export default {
  name: "fatca",
  data() {
    return {
      fatcaCheck: "0",
      fatcaCheckW8: [],
      fatcaCheckValidate: false,
      fatcaCheckValidateRadioButton: false,
      fatcaData: {
        is_fatca_enable: null,
        is_us_citizen: null,
        is_us_citizen_v: null,
        is_us_citizen_v_out: null,
        is_us_auth_person: null,
        is_us_green_card: null,
        is_us_resident: null,
        is_us_renounce: null,
        is_us_address: null,
        is_us_tel: null,
        is_us_bank_account: null,
      },
    };
  },
  created() {
    this.KYCflag();
    this.getChoiceFatca();
  },
  methods: {
    isUsCitizenValidation() {
      let count = 0
      if (this.fatcaData.is_us_citizen) { count++ }
      if (this.fatcaData.is_us_auth_person) { count++ }
      if (this.fatcaData.is_us_green_card) { count++ }
      if (this.fatcaData.is_us_resident) { count++ }
      if (this.fatcaData.is_us_renounce) { count++ }
      if (this.fatcaData.is_us_address) { count++ }
      if (this.fatcaData.is_us_tel) { count++ }
      if (this.fatcaData.is_us_bank_account) { count++ }
      if (count > 0) {
        return true
      } else {
        return false;
      }
    },
    setIsUSCitizen() {
      this.fatcaData.is_fatca_enable = false;
      this.fatcaData.is_us_citizen = false;
      this.fatcaData.is_us_citizen_v = false;
      this.fatcaData.is_us_citizen_v_out = "false";
      this.fatcaData.is_us_auth_person = false;
      this.fatcaData.is_us_green_card = false;
      this.fatcaData.is_us_resident = false;
      this.fatcaData.is_us_renounce = false;
      this.fatcaData.is_us_address = false;
      this.fatcaData.is_us_tel = false;
      this.fatcaData.is_us_bank_account = false;
      this.fatcaCheckValidate = false;
      this.fatcaCheckValidateRadioButton = false;
    },
    setIsUSCitizenEnable() {
      this.fatcaData.is_fatca_enable = true;
      this.fatcaData.is_us_citizen_v = true;
      this.fatcaData.is_us_citizen_v_out = "true";
      this.fatcaCheckValidate = false;
      this.fatcaCheckValidateRadioButton = false;
    },
    getChoiceFatca() {
      const fatcaData = localStorage.getItem("fatcaData");
      const formData = JSON.parse(fatcaData);
      this.fatcaData = { ...formData }
      if(this.isUsCitizenValidation()){
        this.fatcaData.is_fatca_enable = true;
        this.fatcaData.is_us_citizen_v = true;
      }/* else{
        this.fatcaData.is_us_citizen_v_out = "false";
      } */
    },
    saveChoiceFatca() {
      localStorage.setItem("fatcaData", JSON.stringify(this.fatcaData));
    },
    GoFatca() {
      if (this.fatcaData.is_us_citizen_v != null || this.fatcaData.is_us_citizen_v_out != null) {
        this.fatcaCheckValidateRadioButton = false;
        if (this.fatcaData.is_fatca_enable) {
          this.saveChoiceFatca();
          if (this.isUsCitizenValidation()) {
            this.fatcaCheckValidate = false;
            var chk = true;
            if (this.fatcaData.is_us_citizen || this.fatcaData.is_us_auth_person || this.fatcaData.is_us_green_card) {
              // w9
              chk = false;
            }

            if (chk) {
              HTTP.get("/uc/fatca/getFatca?type=0").then((res) => {
                console.log('w8', res);
                localStorage.setItem('fatcaFormData', JSON.stringify(res.data.data))
              });
            } else {
              HTTP.get("/uc/fatca/getFatca?type=1").then((res) => {
                console.log('w9', res);
                localStorage.setItem('fatcaFormData', JSON.stringify(res.data.data))
              });
            }

            return chk ? this.$router.push("/fatca/w8") : this.$router.push("/fatca/w9");

          } else {
            this.fatcaCheckValidate = true;
          }

        } else {
          this.$router.push({
            name: 'ReviewKYC',
            params: { fatcaId: "NO" }
          });
        }
        //alert(this.fatcaCheckW8);
      } else {
        this.fatcaCheckValidateRadioButton = true;
      }
      
    },
    KYCflag() {
      localStorage.setItem("fatcaSet", true)
    },
    CancelFatca(){
      this.$router.push({
        name: 'ReviewKYC'
      });
    }
  },
  computed: {
   
  },
};
</script>

<style scoped>
.fatca_form {
  margin-top: 40px;
  margin-left: 50px;
}

.fatca_p {
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: 20px;
}

.btn-dark-custom{
  background-color: #38454d;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 2px;
}
</style>
