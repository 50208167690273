<template>
  <div>
    <!-- center section start -->
    <div class="row center-section">
      <!-- {{ wallet }} -->
      <div class="col-12">
        <div class="row">
          <div class="col-4 my-auto thai-font px-0 lh-24">
            จำนวนเหรียญที่ต้องการถอน
          </div>
          <!-- {{ wallet.coin.unit }} -->
          <div class="col-8 top-input-container p-0 lh-24">
            <cleave
              v-model="ct"
              :options="options"
              placeholder="0.00"
           
            ></cleave>
            <span>{{ wallet.coin.unit }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 offset-md-4 input-container p-0">
            <div class="input_label" style="margin-top: 2px">
              <div
                style="
                  display: inline;
                  line-height: 18px;
                  font-family: 'Noto Sans Thai';
                "
              >
                เหรียญที่ถอนได้
              </div>
              &nbsp;
              <div style="display: inline; line-height: 14px">
                {{ avBalance }} {{ wallet.coin.unit }}
              </div>
              &nbsp;&nbsp;<u
                class="thai-font"
                style="cursor: pointer"
                @click="ct = avBalance"
                >ถอนทั้งหมด</u
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 thai-font px-0 lh-24"><p>ต้องการถอนไปที่</p></div>
          <div class="col-8 px-0 thai-font">
            <div class="row side-card mx-0">
              <div
                class="col-5 col-md-4 my-auto thai-font del-font title"
                align="right"
              >
                Wallet Name
              </div>
              <div class="col-7 col-md-8 value">{{ wallet.walletName }}</div>
              <div
                class="col-5 col-md-4 my-auto thai-font del-font title"
                align="right"
              >
                Address
              </div>
              <div class="col-7 col-md-8 value" style="word-break: break-all">
                {{ wallet.address }}
              </div>
              <div
                class="col-5 col-md-4 my-auto thai-font del-font title"
                align="right"
              >
                Network
              </div>
              <div class="col-7 col-md-8 value">
                {{ wallet.network.networkName }}
                <!-- <span style="color: #f8c417; margin-left: 5px">Congest</span> -->
              </div>
              <div
                class="col-5 col-md-4 my-auto thai-font del-font title"
                align="right"
              >
                ใช้เวลาถอน
              </div>
              <div class="col-7 col-md-8 value">
                {{ wallet.network.estimatedTime }}
              </div>
              <div
                class="col-5 col-md-4 my-auto thai-font del-font title"
                align="right"
              >
                Fee
              </div>
              <div class="col-7 col-md-8 value">
                {{
                  $MyFunc.NumberFormat(
                    wallet.network.fee,
                    this.options.numeralDecimalScale
                  )
                }}
                {{ wallet.coin.unit }} (~{{
                  $MyFunc.NumberFormat(lastPrice * wallet.network.fee, 2)
                }}
                THB)
              </div>
            </div>
            <u class="c-b" @click="$emit('onCancelClicked')"
              >เลือก Wallet Address ใหม่</u
            >
          </div>
        </div>
        <div class="divider"></div>
        <div class="row my-3">
          <div class="col-5 my-auto thai-font px-0 lh-24" style="width: 172px">
            จำนวนเหรียญที่ได้รับ
          </div>
          <div
            class="col-7 my-auto"
            style="
              font-family: 'Roboto Flex';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
            "
          >
            {{
              $MyFunc.mathFloorPrice(
                receavingCoins,
                this.options.numeralDecimalScale
              )
            }}
            {{ wallet.coin.unit }}
            <span>(~{{ $MyFunc.mathFloorPrice(totalInThb) }} THB)</span>
          </div>
        </div>
      </div>
    </div>
    <p class="bottom-text my-2">
      เงื่อนไขการถอนเหรียญ<br />กรณาเลือก Network ของผู้รับ Address ให้ตรงกับ
      Network ของเหรียญที่คุณเลือก บริษัทฯ จะไม่รับผิดชอบในความ เสียหายใดๆ
      ที่เกิดจาก การที่ผู้ใช้งานโอนเหรียญผิด Wallet Address
    </p>
    <div
      class="mx-3 thai-font justify-center text-center"
      style="padding-top: 27px; padding-bottom: 24px"
    >
      <button class="btn-yellow thai-font" @click="openOTPModel()">
        ยืนยัน
      </button>
      <button class="btn-blue thai-font" @click="$emit('onCancelClicked')">
        ยกเลิก
      </button>
    </div>
    <otp-model
      :otp="true"
      :bData="bData"
      :realName="realName"
      :bFee="bFee"
      :withdrawAmount="withdrawAmount"
      :ci="ci"
      :requestId="requestId"
      :otpReference="otpReference"
      :refCode="refCode"
      @UpdateRefCode="UpdateRefCode"
      @resetWithdrawCoin="resetWithdrawCoin"
    ></otp-model>
    <!-- <CoinStatusModel
      :cfee="wallet.network.fee"
      :data="withdrawData"
      :wallet="wallet"
      :address="wallet.address"
    ></CoinStatusModel> -->
  </div>
</template>
<script>
import OtpModel from "@/components/Modal/WIthdrawCoinOtpModel.vue";
import CoinStatusModel from "./CoinStatusModel.vue";
import { HTTP } from "@/config/axios";
export default {
  props: ["wallet"],
  components: { OtpModel, CoinStatusModel },
  data() {
    return {
      showWithdrawOTPModal: false,
      showQrSuccessModal: false,
      refCode: "",
      ct: null,
      withdrawData: "",
      avBalance: 0,
      minWithdraw: 0,
      lastPrice: 0,
      options: {
        numeral: true,
        bathscale: 2,
        numeralIntegerScale: 10,
        numeralDecimalScale: 8,
        numeralPositiveOnly: true,
      },
      requestId: "",
      bData: "",
      realName: "",
      bFee: "",
      ci: null,
      otpReference: null,
      receivedData: "",
      coinMarketScale: [],
    };
  },
  created() {
    this.getWallet();
  },
  computed: {
    withdrawAmount() {
      if (!this.ci) {
        return 0.0;
      } else {
        return this.ci - this.bFee;
      }
    },
    receavingCoins() {
      if (this.ct >= this.wallet.network.fee + this.minWithdraw) {
        return this.ct - this.wallet.network.fee;
      } else {
        return "0.00";
      }
    },
    totalInThb() {
      let total =
        this.receavingCoins * this.lastPrice - this.wallet.network.fee;
      if (isNaN(total) || total < 0) {
        return "0.00";
      } else {
        return total.toFixed(2);
      }
    },
  },
  methods: {
    handleInput: _.debounce(function () {
      this.setMaxWithdrawCoin();
      this.setMinWithdrawCoin();
    }, 300),
    handleBlur() {
      this.setMinWithdrawCoin();
    },
    getWallet() {
      HTTP.get("/uc/asset/wallet/")
        .then((res) => {
          res.data.data.map((item) => {
            if (item.coin.name == this.wallet.coin.name) {
              this.avBalance = this.$MyFunc.mathFloorPrice(
                item.balance,
                item.coin.withdrawScale
              );
              this.minWithdraw = item.coin.minWithdrawAmount;
              this.lastPrice = item.lastPrice.toFixed(2);
              this.options.bathscale = item.baseCoinScale;
              this.options.numeralDecimalScale = item.coin.withdrawScale;
              //console.log(this.lastPrice);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetWithdrawCoin() {
      this.ct = null;
      this.avBalance = 0;
      this.getWallet();
    },
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    onOTPSubmitted() {
      this.showWithdrawOTPModal = false;
      this.showQrSuccessModal = true;
      this.$bvModal.show("request-done-modal");
    },
    sendRequetsWithdraw() {
      let self = this;
      HTTP.post("/uc/withdraw/withdraw-coin-request", {
        amount: this.ct,
        walletId: this.wallet.id,
      })
        .then((res) => {
          // self.$notify({
          //     group: "notification",
          //     type: res.data.code == "0" ? "success" : "error",
          //     title:
          //     res.data.code == "0"
          //         ? "OTP Send to Mobile Successful"
          //         : "Unable to Send OTP to Mobile",
          //     text: res.data.message,
          // });
          if (res.data.code === 0 && res.data.data.requestId) {
            self.requestId = res.data.data.requestId;
            self.otpReference = res.data.data.otpReference;
            self.refCode = res.data.data.otpReference;
            self.$bvModal.show("otp-success-modal");
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "ระบบไม่สามารถถอนเหรียญได้ชั่วคราว",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openOTPModel() {
      if (this.ct) {
        // this.ct = parseFloat(this.ct);
        this.avBalance = parseFloat(this.avBalance);
        if (this.ct > this.avBalance) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "คุณมีเหรียญไม่เพียงพอในการถอน",
          });
        } else if (this.ct < this.wallet.network.fee + this.minWithdraw) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "จำนวนเหรียญที่คุณต้องการถอนจะต้องกว่ามากค่าธรรมเนียมการทำรายการ"});
        } else {
          this.sendRequetsWithdraw();
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "โปรดกรอก จำนวนเหรียญที่ต้องการถอน",
        });
      }
    },
    setMaxWithdrawCoin() {
      if (parseFloat(this.ct) > this.avBalance) {
        this.ct = this.avBalance;
      }
    },
    setMinWithdrawCoin() {
      if (parseFloat(this.ct) < this.wallet.network.fee + this.minWithdraw) {
        this.ct = parseFloat(this.wallet.network.fee + this.minWithdraw);
      }
    },
  },
};
</script>
<style lang="scss">
.btn-blue {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background-color: #38454d;
  border-radius: 4px;
  width: 140px;
  height: 36px;
  margin-left: 8.5px;
  &:hover {
    background-color: #283238 !important;
  }
}
.btn-yellow {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0a0d10;
  background-color: #09BE8B;
  border-radius: 4px;
  width: 140px;
  height: 36px;
  margin-right: 8.5px;
  &:hover {
    color: #0a0d10;
    background-color: #66cdb0 !important;
  }
}
.c-b {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #677f8e;
  display: inline-block;
  margin-top: 16px;
  cursor: pointer;
}
.divider {
  border: 1px solid #28363e;
  margin: 20px -13px;
}
.side-card {
  background-color: #1c262b;
  border-radius: 5px;
  padding: 10px 0;
  width: 380px;
  margin-bottom: 2px;
  .title {
    margin: 2px 0;
  }
  .value {
    line-height: 24px;
    margin: 2px 0;
  }
}
// .withdraw-coin-container {
//   background-color: #192126;
//   border-radius: 8px;
//   margin-top: 50px;
//   position: relative;
//   .dcc-head {
//     border-bottom: 1px solid #28363e;
//     button {
//       display: inline-block;
//       font-size: 16px;
//       padding: 15px 0;
//       margin-left: 20px;
//       border-bottom: 2px solid transparent;
//       color: #677f8e;
//       &:hover {
//         border-bottom: 2px solid #09BE8B;
//         color: #d6dde1;
//       }
//     }

//     button.active {
//       border-bottom: 2px solid #09BE8B;
//       color: #d6dde1;
//     }
//   }
//   .center-section {
//     .select-container {
//       background-color: #191b1c;
//       color: #d6dde1;
//       border-radius: 4px;
//       height: 40px;
//       width: 240px;
//       margin: auto 0;
//       display: flex;
//       align-items: center;
//       position: relative;
//       cursor: pointer;
//       .icon {
//         pointer-events: none;
//         position: absolute;
//         right: 20px;
//       }
//       .rotate-sc-icon {
//         transform: rotateX(180deg);
//       }
//       .text {
//         padding-left: 15px;
//       }
//       .options {
//         position: absolute;
//         bottom: -82px;
//         width: 100%;
//         background-color: #191b1c;
//         border-radius: 4px;
//         ul {
//           margin: 0;
//           padding: 0;
//           li {
//             display: flex;
//             align-items: center;
//             height: 40px;
//             padding: 0 15px;
//             border-radius: 4px;
//             &:hover {
//               background-color: #2c3b44;
//             }
//           }
//         }
//       }
//       .hidden {
//         display: none;
//       }
//       .show {
//         display: block;
//       }
//     }
//     .input-container {
//       input {
//         background-color: #191b1c;
//         color: #d6dde1;
//         border-radius: 4px;
//         height: 40px;
//         padding: 0 18px;
//         &::placeholder {
//           color: #566a76;
//         }
//       }
//       span {
//         color: #9bacb6;
//         margin-left: -40px;
//       }
//     }

//     .info {
//       color: #d6dde1;
//       p {
//         margin: 0;
//       }
//       ul {
//         list-style: disc;
//       }
//     }
//     .lr {
//       font-size: 16px;
//       span {
//         font-size: 14px;
//         color: #f38220;
//         margin-left: 8px;
//       }
//     }
//     .bcb {
//       margin: 0 -27px;
//       margin-top: 30px;
//       margin-bottom: 20px;
//       border-top: 1px solid #28363e;
//     }

//     .bc-info {
//       ul {
//         list-style: disc;
//         padding: 0 24px;
//         li {
//           color: #9bacb6;
//           width: 70%;
//         }
//       }
//       ul.number {
//         list-style: decimal;
//       }
//     }
//   }
// }
.lh-24 {
  line-height: 24px;
}
.bottom-text {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #f72000;
}
.right-info {
  position: absolute;
  top: 3px;
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;
  ul {
    font-weight: 400;
    list-style: disc;
    position: relative;
    left: -8px;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}
// @media (max-width: 640px) {
//   .withdraw-coin-container {
//     width: auto;
//     height: auto;
//     margin-left: 10px;
//     margin-right: 10px;
//     padding-bottom: 20px;
//     .center-section {
//       padding: 0;
//     }
//     // .select-container {
//     //   select {
//     //     width: 190px;
//     //   }
//     // }
//     .input-container {
//       input {
//         width: 190px;
//       }
//     }
//   }
// }
// @media (min-width: 640px) {
//   .withdraw-coin-container {
//     width: 616px;
//     padding-bottom: 0;
//     .center-section {
//       padding: 0 1rem;
//     }
//     // .select-container {
//     //   select {
//     //     width: 240px;
//     //   }
//     // }
//     .input-container {
//       input {
//         width: 240px;
//       }
//     }
//   }
//   .qr-h {
//     height: 458px;
//   }
//   .bc-h {
//     height: 580px;
//   }
// }
@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}
@media (min-width: 1366px) {
  .right-info {
    display: block;
    // right: 100px;
  }
}

.top-input-container {
  input {
    background-color: #191b1c;
    color: #d6dde1;
    border-radius: 4px;
    height: 40px;
    padding: 0 50px 0 18px;
    width: 171px;
    &::placeholder {
      color: #566a76;
    }
  }
  span {
    color: #9bacb6;
    margin-left: -40px;
  }
}
</style>
<style scoped>
/* .withdraw-coin-container {
  min-height: 540px !important;
} */
.input-container .input_label {
  margin: 0px;
  line-height: 24px;
  color: #677f8e;
  font-size: 12px;
}
.d-info {
  max-width: 285px;
  height: 59px;
  background: #191b1c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #9bacb6;
  padding: 0px 10px;
  font-size: 14px;
}
.d-info > div {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.d-info > div span:nth-child(3) {
  color: #09BE8B;
  margin-top: -15px;
  display: block;
}
.del-font {
  color: #677f8e;
  line-height: 24px;
  font-size: 14px;
  margin-left: -22px;
}
.b-ins p {
  color: #677f8e;
  margin: 0px;
  line-height: 24px;
}
.b-ins ul {
  color: #677f8e;
  font-size: 14px;
  line-height: 24px;
  list-style: inherit;
}
</style>
