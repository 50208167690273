<template>
  <p></p>
</template>

<script>
import Table from "../../components/table/Table.vue";
export default {
  name: "marketupdate",
  metaInfo() {
    let pageTitle = "Market Update";
    return {
      title: pageTitle,
      titleTemplate: "%s | "+ this.TITLE ,
    };
  },

  components: {
    "Dynamic-Table": Table,
  },
  data() {
    return {

    };
  },
};
</script>
<style lang="scss">
</style>
