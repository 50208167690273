<template>
  <div class="wrapper pi">
    <div class=" scroller" style="padding:10px">
      <div class="container p-0">
        <div class="text-left" style="padding: 24px 0px 55px 48px">
          <h1 class="modal-title">ทบทวนข้อมูล KYC</h1>
          <p class="text-dark-grey mt-1">*อัปเดตข้อมูลให้เป็นปัจจุบัน</p>
        </div>
        <div class="row">
          <div class="col-4">
            <p class="text-dark-grey mb-0">ชื่อ - นามสกุล</p>
          </div>
          <div class="col-8">
            <p class="text-dark-grey mb-0">
              {{ user.fullNameTh }}
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">เลขที่บัญชี</p>
          </div>
          <div class="col-8">
            <p class="text-dark-grey mb-0">
              {{ user.username }}
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">วันเดือนปีเกิด</p>
          </div>
          <div class="col-8">
            <p class="text-dark-grey mb-0">
              {{ user.DOB }}
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">อายุ</p>
          </div>
          <div class="col-8">
            <p class="text-dark-grey mb-0">
              {{ user.Age }}
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">สถานภาพ</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <!-- Marital -->
              <div
                ref="cboMaritalStatus"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleMaritalStatusClick"
                @blur="Open_cboMaritalStatus = false"
                tabindex="0"
              >
                <input
                  class="form-kyc"
                  type="text"
                  :class="searchcboMaritalStatus == '' ? 'form-validate' : ''"
                  v-model="searchcboMaritalStatus"
                  @input="updateFormData('searchcboMaritalStatus')"
                  @blur="chkMaritalStatus"
                />
                <span
                  v-if="searchcboMaritalStatus?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #677f8e;
                    pointer-events: none;
                  "
                >
                  เลือกสถานภาพ
                </span>
                <!-- :value="selectedSubDist" -->

                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboMaritalStatus ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListMaritalStatus"
                      :key="index"
                      @click.stop="
                        searchcboMaritalStatus = item.name;
                        selectedMaritalStatus = item.id;
                        Open_cboMaritalStatus = false;
                        updateFormData('searchcboMaritalStatus');
                      "
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Error message -->
            <p v-if="!selectedMaritalStatus" class="text-danger mb-0">
              โปรดเลือกสถานภาพ
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">ระดับการศึกษาสูงสุด</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <div
                ref="cboEducationLevel"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleEducationLevelStatusClick"
                @blur="Open_cboEducationLevel = false"
                tabindex="0"
              >
                <input
                  class="form-kyc"
                  type="text"
                  :class="searchcboEducationLevel == '' ? 'form-validate' : ''"
                  v-model="searchcboEducationLevel"
                  @input="updateFormData('searchcboEducationLevel')"
                  @blur="chkEducationLevel"
                />
                <span
                  v-if="searchcboEducationLevel?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #566a76;
                    pointer-events: none;
                  "
                >
                  เลือกระดับการศึกษาสูงสุด
                </span>
                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboEducationLevel ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListEducationLevel"
                      :key="index"
                      @click.stop="
                        searchcboEducationLevel = item.nameTh;
                        selectedEducationLevel = item.code;
                        Open_cboEducationLevel = false;
                        updateFormData('searchcboEducationLevel');
                      "
                    >
                      {{ item.nameTh }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Error message -->
            <span v-if="searchcboEducationLevel == ''" style="color: #dc3545"
              >โปรดเลือกระดับการศึกษาสูงสุด</span
            >
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">
              สถาน{{ checkTextWorkplace(searchcboOccupation) }}
            </p>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control address-input"
              :placeholder="
                JobWorkName && txtWorkPlaceName.length == 0
                  ? `ระบุสถาน${checkTextWorkplace(searchcboOccupation)}`
                  : ''
              "
              @input="updateFormData('txtWorkPlaceName')"
              v-model="txtWorkPlaceName"
              :disabled="!JobWorkName"
              :class="
                JobWorkName && txtWorkPlaceName == '' ? 'form-validate' : ''
              "
              @blur="txtWorkPlaceName = txtWorkPlaceName.trim()"
            />
            <span
              v-if="JobWorkName && txtWorkPlaceName == ''"
              style="color: #dc3545"
              >ระบุสถาน{{ checkTextWorkplace(searchcboOccupation) }}</span
            >
            <!-- <p v-if="JobWorkName && txtWorkPlaceName.length == 0" class="text-danger mb-0">โปรดเลือก</p> -->
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">อาชีพ</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <!-- uocupation -->
              <div
                ref="cboOccupation"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleOccupationStatusClick"
                @blur="Open_cboOccupation = false"
                tabindex="0"
              >
                <input
                  style="width: 280px; padding: 16px; padding-left: 10px"
                  type="text"
                  v-model="searchcboOccupation"
                  :class="searchcboOccupation == '' ? 'form-validate' : ''"
                  @input="updateFormData('searchcboOccupation')"
                  @blur="chkOccupation"
                />

                <span
                  v-if="searchcboOccupation?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #677f8e;
                    pointer-events: none;
                  "
                >
                  เลือกอาชีพ
                </span>
                <!-- :value="selectedSubDist" -->

                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboOccupation ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListOccupation"
                      :key="index"
                      @click.stop="
                        searchcboOccupation = item.name;
                        selectedOccupation = item.id;
                        Open_cboOccupation = false;

                        JobWorkName = item.workplaceFlag;
                        JobPosition = item.positionFlag;
                        JobBusiness = item.businessFlag;

                        searchcboBusinessType = '';
                        selectedBusinessType = '';

                        if (!JobWorkName) {
                          txtWorkPlaceName = '';
                        }
                        if (!JobPosition) {
                          txtPosition = '';
                        }
                        if (JobBusiness) {
                          getBussinesstype();
                        }
                        updateFormData('searchcboOccupation');
                      "
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Error message -->
            <span v-if="searchcboOccupation == ''" style="color: #dc3545"
              >โปรดเลือกอาชีพ</span
            >
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">ประเภทธุรกิจ</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <!-- uocupation -->
              <div
                ref="cboBusinessType"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleBusinessTypeStatusClick"
                @blur="Open_cboBusinessType = false"
                tabindex="0"
              >
                <input
                  style="width: 280px; padding: 16px; padding-left: 10px"
                  type="text"
                  :class="
                    JobBusiness && searchcboBusinessType == ''
                      ? 'form-validate'
                      : ''
                  "
                  v-model="searchcboBusinessType"
                  @input="updateFormData('searchcboBusinessType')"
                  :disabled="!JobBusiness"
                  @blur="chkBusinessType"
                />
                <span
                  v-if="JobBusiness && searchcboBusinessType?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #677f8e;
                    pointer-events: none;
                  "
                >
                  เลือกประเภทธุรกิจ
                </span>
                <!-- :value="selectedSubDist"  v-if="JobBusiness && searchcboBusinessType?.length == 0" -->
                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboBusinessType ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListBusinessType"
                      :key="index"
                      @click.stop="
                        searchcboBusinessType = item.name;
                        selectedBusinessType = item.id;
                        Open_cboBusinessType = false;
                        updateFormData('searchcboBusinessType');
                      "
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Error message -->
            <span
              v-if="JobBusiness && searchcboBusinessType == ''"
              style="color: #dc3545"
              >โปรดเลือกประเภทธุรกิจ</span
            >
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">ตำแหน่ง</p>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control address-input"
              :class="JobPosition && txtPosition == '' ? 'form-validate' : ''"
              :placeholder="
                JobPosition && txtPosition == '' ? 'ระบุตำแหน่ง' : ''
              "
              v-model="txtPosition"
              @input="updateFormData('txtPosition')"
              :disabled="!JobPosition"
            />
            <p
              v-if="JobPosition && txtPosition == '' && JobPosition"
              class="text-danger mb-0"
            >
              กรอกตำแหน่ง
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">รายได้ (บาท)</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <div
                ref="cboIncome"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleIncomeStatusClick"
                @blur="Open_cboIncome = false"
                tabindex="0"
              >
                <input
                  style="width: 280px; padding: 16px; padding-left: 10px"
                  type="text"
                  :class="searchcboIncome == '' ? 'form-validate' : ''"
                  v-model="searchcboIncome"
                  @input="updateFormData('searchcboIncome')"
                  @blur="chkIncome"
                />
                <p v-if="searchcboIncome == ''" class="text-danger mb-0">
                  กรอกรายได้
                </p>
                <span
                  v-if="searchcboIncome?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #566a76;
                    pointer-events: none;
                  "
                >
                  เลือกรายได้ต่อเดือน
                </span>
                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboIncome ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListIncome"
                      :key="index"
                      @click.stop="
                        searchcboIncome = item.monthlyIncome;
                        selectedIncome = item.id;
                        Open_cboIncome = false;
                        updateFormData('searchcboIncome');
                      "
                    >
                      {{ item.monthlyIncome }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Error message -->
            <p v-if="!selectedIncome" class="text-danger mb-0">โปรดเลือก</p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">แหล่งที่มารายได้</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <div
                ref="cboIncomeSource"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleIncomeSourceStatusClick"
                @blur="Open_cboIncomeSource = false"
                tabindex="0"
              >
                <input
                  class="form-kyc"
                  type="text"
                  :class="searchcboIncomeSource == '' ? 'form-validate' : ''"
                  v-model="searchcboIncomeSource"
                  @input="updateFormData('searchcboIncomeSource')"
                  @blur="chkIncomeSource"
                />
                <span
                  v-if="searchcboIncomeSource?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #566a76;
                    pointer-events: none;
                  "
                >
                  เลือกแหล่งที่มารายได้
                </span>
                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboIncomeSource ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListIncomeSource"
                      :key="index"
                      @click.stop="
                        searchcboIncomeSource = item.name;
                        selectedIncomeSource = item.id;
                        Open_cboIncomeSource = false;
                        updateFormData('searchcboIncomeSource');
                      "
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Error message -->
            <span v-if="searchcboIncomeSource == ''" style="color: #dc3545"
              >โปรดเลือกแหล่งที่มารายได้</span
            >
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">ประเทศที่มาของรายได้</p>
          </div>
          <div class="col-8">
            <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <div
                ref="cboCountryIncome"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleCountryIncomeStatusClick"
                @blur="Open_cboCountryIncome = false"
                tabindex="0"
              >
                <input
                  class="form-kyc"
                  type="text"
                  :class="searchcboCountryIncome == '' ? 'form-validate' : ''"
                  v-model="searchcboCountryIncome"
                  @input="updateFormData('searchcboCountryIncome')"
                  @blur="chkCountryIncome"
                />
                <span
                  v-if="searchcboCountryIncome?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #566a76;
                    pointer-events: none;
                  "
                >
                  เลือกประเทศที่มาของรายได้
                </span>
                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <!-- there -->

                  <!-- search? -->
                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboCountryIncome ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListCountryIncome"
                      :key="index"
                      @click.stop="
                        searchcboCountryIncome = item.name;
                        selectedCountryIncome = item.code;
                        Open_cboCountryIncome = false;
                        updateFormData('searchcboCountryIncome');
                      "
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Error message -->
            <p v-if="!selectedCountryIncome" class="text-danger mb-0">
              โปรดเลือกประเทศ
            </p>
          </div>

          <!-- Purpose -->
          <div class="col-4">
            <p class="text-dark-grey mb-0">วัตถุประสงค์การลงทุน</p>
          </div>
          <div class="col-8">
            <!--             <div
              class="wraper-input d-flex justify-content-between"
              style="width: 90%"
            >
              <div
                ref="cboInvestment"
                class="input-container"
                style="line-height: 24px; position: relative"
                @click="handleInvestmentStatusClick"
                @blur="Open_cboInvestment = false"
                tabindex="0"
              >
                <input
                  class="form-kyc"
                  type="text"
                  :class="searchcboInvestment == '' ? 'form-validate' : ''"
                  v-model="searchcboInvestment"
                  @input="updateFormData('searchcboInvestment')"
                  @blur="chkInvestment"
                />
                <span
                  v-if="searchcboInvestment?.length == 0"
                  style="
                    position: absolute;
                    left: 40px;
                    top: 7px;
                    color: #566a76;
                    pointer-events: none;
                  "
                >
                  เลือกวัตถุประสงค์การลงทุน
                </span>
                <span>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>

                </span>
                <div
                  style="width: 280px"
                  class="d-options"
                  :class="Open_cboInvestment ? 'show' : 'hidden'"
                >
                  <ul
                    style="
                      max-height: 200px;
                      position: relative;
                      z-index: 999;
                      overflow: auto;
                    "
                  >
                    <li
                      v-for="(item, index) in filteredListInvestment"
                      :key="index"
                      @click.stop="
                        searchcboInvestment = item.name;
                        selectedInvestment = item.id;
                        Open_cboInvestment = false;
                        updateFormData('searchcboInvestment');
                      "
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->

            <div v-for="(item, index) in ListInvestment" :key="index">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="item.id"
                  :id="index"
                  v-model="selectedInvestment"
                />
                <label class="form-check-label" :for="index">
                  {{ item.name }}
                </label>
              </div>
            </div>

            <!-- Error message -->
            <!-- <p v-if="!selectedInvestment" class="text-danger mb-0">โปรดเลือกวัตถุประสงค์</p> -->
            <p v-if="selectedInvestment.length <= 0" class="text-danger mb-0">
              โปรดเลือกวัตถุประสงค์
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">บุคคลอเมริกันตามเกณฑ์ FATCA</p>
          </div>
          <div class="col-8">
            <div class="custom-input address-input">
              {{ txtFatca ? "Yes" : "No" }}
              <a class="underline-button" @click="handleInputClick"
                >ไปที่แบบฟอร์ม</a
              >
            </div>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">
              แบบประเมินความเหมาะสมการลงทุน<span class="text-danger">*</span>
            </p>
          </div>
          <div class="col-8">
            <div
              class="custom-input address-input"
              :class="!flagNewSuitability ? 'custom-input-danger' : ''"
            >
              {{ txtRiskSuitability ? txtRiskSuitability : "No" }}
              <a class="underline-button" @click="handleSuitabilityGroup"
                >ไปที่แบบฟอร์ม</a
              >
            </div>
            <p v-if="!flagNewSuitability" class="text-danger mb-0">
              กรุณาทำแบบประเมินความเหมาะสมการลงทุนใหม่อีกครั้ง
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">
              รหัสหลังบัตรประชาชน (Laser Code)
              <span class="text-danger">*</span>
            </p>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control address-input"
              v-model="txtLasserCode"
              :class="txtLasserCode == '' ? 'form-validate' : ''"
              @input="updateFormData('txtLasserCode')"
              v-maska:[laserIdOptions]
              data-maska="AA#-#######-##"
              placeholder="ระบุรหัสหลังบัตรประชาชน (Laser Code)"
            />
            <p v-if="txtLasserCode == ''" class="text-danger mb-0">
              โปรดกรอกข้อมูลหลังบัตรประชาชน
            </p>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">
              ขั้นตอนถ่ายรูปยืนยันตัวตน <span class="text-danger">*</span>
            </p>
          </div>
          <div class="col-8">
            <div class="custom-input address-input">
              <span
                v-if="this.livenessStatus"
                :style="
                  this.livenessStatus === 1
                    ? 'color: #40994F'
                    : this.livenessStatus === 2
                    ? 'color: red'
                    : ''
                "
              >
                {{
                  this.livenessStatus === 1
                    ? "ผ่าน"
                    : this.livenessStatus === 2
                    ? "ไม่ผ่าน"
                    : ""
                }}
              </span>
              <a
                class="underline-button"
                style="margin-left: 10px"
                @click="(ModalIDCard = true), prepareStartCamera()"
                >ไปที่ถ่ายรูปยืนยันตัวตน</a
              >
              <p v-if="!txtVerification" class="text-danger mb-0">
                กรุณายืนยันรูปถ่าย
              </p>
            </div>
          </div>
          <div class="col-4">
            <p class="text-dark-grey mb-0">แนบเอกสาร</p>
          </div>
          <div class="col-4">
            <upload-picture-vue
              :fileItems.sync="selectedAttachments"
            ></upload-picture-vue>
          </div>
        </div>
        <!-- modal button bottom -->
        <div class="button text-center col-6" style="margin: 40px 0px 32px">
          <button
            type="button"
            class="btn btn-primary btn-text-sarabun"
            @click="submitKyc()"
            :disabled="disabledSubmitKyc"
          >
            <span style="color: black">ยืนยัน</span>
          </button>
        </div>
      </div>
    </div>

    <transition name="modal" v-if="LivenessError">
      <div class="modal-mask" style="background-color: #0a0d10">
        <div class="modal-wrapper">
          <div class="modal-dialog-live otp-error" role="document">
            <div class="modal-content">
              <div class="modal-body scroller-otp">
                <div class="container">
                  <div class="row">
                    <div class="col text-center" style="height: 337px">
                      <span>
                        <svg
                          style="margin-top: 61px"
                          width="68"
                          height="70"
                          viewBox="0 0 68 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_di_1067_133588)">
                            <circle
                              cx="34"
                              cy="32"
                              r="30"
                              fill="url(#paint0_linear_1067_133588)"
                            />
                          </g>
                          <g filter="url(#filter1_d_1067_133588)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M25.1213 18.8787C23.9497 17.7071 22.0503 17.7071 20.8787 18.8787C19.7071 20.0503 19.7071 21.9497 20.8787 23.1213L29.6363 31.8789L20.8791 40.636C19.7076 41.8076 19.7076 43.7071 20.8791 44.8787C22.0507 46.0503 23.9502 46.0503 25.1218 44.8787L33.8789 36.1215L42.0919 44.3345C43.2635 45.5061 45.163 45.5061 46.3345 44.3345C47.5061 43.163 47.5061 41.2635 46.3345 40.0919L38.1215 31.8789L46.335 23.6655C47.5065 22.4939 47.5065 20.5944 46.335 19.4228C45.1634 18.2513 43.2639 18.2513 42.0923 19.4228L33.8789 27.6363L25.1213 18.8787Z"
                              fill="url(#paint1_linear_1067_133588)"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_di_1067_133588"
                              x="0"
                              y="0"
                              width="68"
                              height="70"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1067_133588"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1067_133588"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="-2" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.486369 0 0 0 0 0.789038 0 0 0 0 0.588355 0 0 0 0.4 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect2_innerShadow_1067_133588"
                              />
                            </filter>
                            <filter
                              id="filter1_d_1067_133588"
                              x="16"
                              y="18"
                              width="35.2109"
                              height="35.7573"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.721569 0 0 0 0 0.117647 0 0 0 0 0.180392 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1067_133588"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1067_133588"
                                result="shape"
                              />
                            </filter>
                            <linearGradient
                              id="paint0_linear_1067_133588"
                              x1="33.5082"
                              y1="62"
                              x2="33.5082"
                              y2="2"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#DE2D40" />
                              <stop offset="1" stop-color="#EE5D6D" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_1067_133588"
                              x1="33.6068"
                              y1="49.5"
                              x2="33.6068"
                              y2="28.5"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#E6BDBD" />
                              <stop offset="1" stop-color="white" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <div class="success-message">
                        <p
                          style="
                            font-family: 'Noto Sans Thai';
                            color: #d6dde1;
                            font-size: 16px;
                            margin-bottom: 0;
                          "
                        >
                          ความเหมือน 12.78% <br />กรุณายืนยันตัวตนใหม่อีกครั้ง
                        </p>
                      </div>
                      <div
                        class="finisButton-SuccesOtp"
                        @click="showModalOTPError = false"
                      >
                        <button
                          style="color: white; width: 160px"
                          type="button"
                          class="btn btn-cancel"
                          @click="CloseModal('OTPError')"
                        >
                          ย้อนกลับ
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="LivenessSuccess">
      <div class="modal-mask" style="background-color: #090d10">
        <div class="modal-wrapper">
          <div class="modal-dialog-live otp-success" role="document">
            <div
              class="modal-content"
              style="border-radius: 8px; height: 435px"
            >
              <div class="modal-body scroller-otp p-0">
                <div class="container p-0">
                  <div class="row m-0">
                    <div class="col text-center p-0">
                      <div class="checkTick">
                        <img
                          class="image-tick-modal"
                          src="../../assets/images/profile-setting/tick.png"
                        />
                      </div>
                      <div class="success-message">
                        <p
                          class="text-grey mb-0"
                          style="
                            font-family: 'Noto Sans Thai' !important;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            color: #d6dde1;
                          "
                        >
                          ระบบได้รับข้อมูลของคุณแล้ว
                        </p>
                      </div>
                      <div
                        style="margin-bottom: 123px"
                        @click="showModalOTPSuccess = false"
                      >
                        <button
                          type="button"
                          class="btn-text-sarabun btn-cancel buttonSuccess"
                          style="
                            height: 44px;
                            width: 163px;
                            color: white;
                            border-radius: 4px;
                          "
                          @click="CloseModal('OTPSuccess')"
                        >
                          เสร็จสิ้น
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="ModalIDCard">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 650px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0" style="padding-top: 20px">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            กรุณาทำตามขั้นตอน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                ModalIDCard = false;
                                StopCamera();
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="oc2">
                        <p>ขั้นตอนที่ 1</p>
                        <p>จัดบัตรประชาชนให้อยู่ในกรอบ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <video
                            ref="camera"
                            autoplay
                            width="100%"
                            height="100%"
                            style="object-fit: cover; border-radius: 4px"
                          ></video>
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">หมายเหตุ</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            กดปุ่ม “ถ่ายรูป” เพื่อเปิดกล้อง
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            หากท่านยังไม่สามารถกดถ่ายรูปได้ กรุณาตรวจสอบ
                            การตั้งค่า ได้
                          </p>
                          <p style="margin-left: 24px; color: #9bacb6">
                            ได้
                            <a
                              href="#"
                              style="color: #09BE8B; text-decoration: none"
                              @click="modal_check()"
                              >ที่นี่</a
                            >
                          </p>
                        </div>
                        <div
                          class="button-camera-open"
                          style="padding-top: 70px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="TakePicture"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                position: relative;
                              "
                            >
                              <img
                                style="position: absolute; left: 49px; top: 5px"
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                              />
                              ถ่ายรูปบัตรประชาชน
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalSelfi">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 650px"
            >
              <div
                v-if="checkStatusLiveness"
                class="modal-content"
                style="margin-bottom: 200px"
              >
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0" style="padding-top: 20px">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            กรุณาทำตามขั้นตอน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                ModalSelfi = false;
                                StopCamera();
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="oc2">
                        <p>ขั้นตอนที่ 2</p>
                        <p>“ถ่ายรูปเซลฟี่” เพื่อยืนยันตัวตนของคุณ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <video
                            ref="camera"
                            autoplay
                            width="100%"
                            height="100%"
                            style="object-fit: cover; border-radius: 4px"
                          ></video>
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">ข้อแนะนำก่อนแสกนใบหน้า</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            ไม่สวมหมวก, หน้ากาก หรือแว่นตาปิดบังใบหน้า
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            อยู่ในพื้นที่แสงสว่างเพียงพอ และไม่ติดคนด้านหลัง
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">3.</span>
                            ไม่มีผมปิดบังใบหน้า
                          </p>
                        </div>
                        <div
                          class="button-camera-open"
                          style="padding-top: 70px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="TakePicture"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                position: relative;
                              "
                            >
                              <img
                                style="position: absolute; left: 80px; top: 5px"
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                                alt=""
                              />
                              &nbsp; ถ่ายรูปเซลฟี่
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row text-center">
                <span class="mt-5">กำลังตรวจสอบ...</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalShowPhoto">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 100px auto; height: 443px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            <span v-if="CameraStep == 0">
                              ยืนยันรูปบัตรประชาชน
                            </span>
                            <span v-else> การยืนยันการเซลฟี่ </span>
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalShowPhoto = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <img
                            :src="PhotoBase64"
                            alt="Captured Image"
                            style="
                              cursor: pointer;
                              object-fit: cover;
                              border-radius: 4px;
                            "
                            width="100%"
                            height="100%"
                          />
                        </div>

                        <div
                          class="button-camera-open"
                          style="padding-top: 40px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="NexttoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                              "
                            >
                              ยืนยัน
                            </button>
                            <button
                              @click="BacktoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #191b1c !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              ถ่ายรูปอีกครั้ง
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalSimilarity">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 100px auto; height: 443px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <!-- <h5 class="modal-title m-auto text-center">
                             <span v-if="CameraStep == 0">
                              ยืนยันรูปบัตรประชาชน
                            </span>
                            <span v-else> การยืนยันการเซลฟี่ </span> 
                            
                            <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span>
                          </h5> -->
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalSimilarity = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number"></div>
                        <div
                          v-if="!this.isCalculating"
                          class="d-grid justify-content-center py-5"
                        >
                          <!-- <img
                            :src="PhotoBase64"
                            alt="Captured Image"
                            style="
                              cursor: pointer;
                              object-fit: cover;
                              border-radius: 4px;
                            "
                            width="100%"
                            height="100%"
                          /> -->

                          <div class="text-center">
                            <p
                              v-if="NameSimilarity > 95"
                              style="font-size: 20px; color: #40994f"
                            >
                              ผ่าน
                            </p>
                            <p v-else style="font-size: 20px; color: #de2d40">
                              ไม่ผ่าน
                            </p>
                          </div>
                          <div v-if="NameSimilarity > 95" class="text-center">
                            <img
                              class="image-tick-modal"
                              src="../../assets/images/profile-setting/tick.png"
                              alt=""
                              style="margin-bottom: 40px"
                            />
                          </div>
                          <div v-else>
                            <span>
                              <svg
                                style="margin-bottom: 40px"
                                width="95"
                                height="95"
                                viewBox="0 0 68 70"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_di_1067_133588)">
                                  <circle
                                    cx="34"
                                    cy="32"
                                    r="30"
                                    fill="url(#paint0_linear_1067_133588)"
                                  />
                                </g>
                                <g filter="url(#filter1_d_1067_133588)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M25.1213 18.8787C23.9497 17.7071 22.0503 17.7071 20.8787 18.8787C19.7071 20.0503 19.7071 21.9497 20.8787 23.1213L29.6363 31.8789L20.8791 40.636C19.7076 41.8076 19.7076 43.7071 20.8791 44.8787C22.0507 46.0503 23.9502 46.0503 25.1218 44.8787L33.8789 36.1215L42.0919 44.3345C43.2635 45.5061 45.163 45.5061 46.3345 44.3345C47.5061 43.163 47.5061 41.2635 46.3345 40.0919L38.1215 31.8789L46.335 23.6655C47.5065 22.4939 47.5065 20.5944 46.335 19.4228C45.1634 18.2513 43.2639 18.2513 42.0923 19.4228L33.8789 27.6363L25.1213 18.8787Z"
                                    fill="url(#paint1_linear_1067_133588)"
                                  />
                                </g>
                                <defs>
                                  <filter
                                    id="filter0_di_1067_133588"
                                    x="0"
                                    y="0"
                                    width="68"
                                    height="70"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_1067_133588"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_1067_133588"
                                      result="shape"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="-2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="arithmetic"
                                      k2="-1"
                                      k3="1"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0.486369 0 0 0 0 0.789038 0 0 0 0 0.588355 0 0 0 0.4 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="shape"
                                      result="effect2_innerShadow_1067_133588"
                                    />
                                  </filter>
                                  <filter
                                    id="filter1_d_1067_133588"
                                    x="16"
                                    y="18"
                                    width="35.2109"
                                    height="35.7573"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0.721569 0 0 0 0 0.117647 0 0 0 0 0.180392 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_1067_133588"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_1067_133588"
                                      result="shape"
                                    />
                                  </filter>
                                  <linearGradient
                                    id="paint0_linear_1067_133588"
                                    x1="33.5082"
                                    y1="62"
                                    x2="33.5082"
                                    y2="2"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#DE2D40" />
                                    <stop offset="1" stop-color="#EE5D6D" />
                                  </linearGradient>
                                  <linearGradient
                                    id="paint1_linear_1067_133588"
                                    x1="33.6068"
                                    y1="49.5"
                                    x2="33.6068"
                                    y2="28.5"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#E6BDBD" />
                                    <stop offset="1" stop-color="white" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                          </div>
                          <div class="text-center">
                            ความเหมือน
                            {{ $MyFunc.toFixedDigits(NameSimilarity, 2) }}%
                          </div>
                        </div>
                        <div v-else class="d-flex justify-content-center">
                          <span>กำลังคำนวณ...</span>
                        </div>

                        <div
                          class="button-camera-open"
                          style="padding-top: 40px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="
                                () => {
                                  this.ModalShowPhoto = false;
                                  this.ModalSimilarity = false;
                                  this.ModalIDCard = false;
                                  this.CameraStep = 0;
                                }
                              "
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #191b1c !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 50px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              กลับหน้าหลัก
                            </button>
                            <!-- <button
                              @click="BacktoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #191b1c !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              ถ่ายรูปอีกครั้ง
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="config_detail">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background: #0a0d10;
            "
          >
            <div
              class="modal-dialog1"
              role="document"
              style="margin: 60px auto; height: 400px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div class="row m-0" style="padding-top: 20px">
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            วิธีตั้งค่าเปิดกล้อง
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>

                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <!-- <span
                              aria-hidden="true"
                              @click="
                                ModalIDCard = false;
                                StopCamera();
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            > -->
                          </button>
                        </div>
                      </div>
                      <div class="oc1">
                        <h5>
                          สำหรับ Browser ทั่วไป (เช่น Google Chrome บน PC,
                          Firefox, Microsoft Edge)
                        </h5>
                        <div style="padding-left: 20px">
                          <ul style="list-style-type: disc">
                            <li>เปิด Browser ที่คุณใช้</li>
                            <li>คลิกที่ สัญลักษณ์ ... หรือ ☰ (ขึ้นอยู่กับ Browser)ที่มุมขวาบน
                            </li>
                            <li>เลือก การตั้งค่า (Settings)</li>
                            <li>เลือก การตั้งค่าเว็บไซต์ (Site Settings) หรือการตั้งค่าขั้นสูง (Advanced Settings)</li>
                            <li>เลือก กล้องถ่ายรูป (Camera)</li>
                            <li>เลือก อนุญาต (Allow)</li>
                            <li>ทำซ้ำขั้นตอนสำหรับ ไมโครโฟน (Microphone)</li>
                          </ul>
                        </div>

                        <h5 style="padding-top: 20px">
                          สำหรับ iOS (iPhone/iPad)
                        </h5>
                        <div style="padding-left: 20px">
                          <ul style="list-style-type: disc">
                            <li>ไปที่การตั้งค่า (Settings)</li>
                            <li>เลือก Safari</li>
                            <li>เลือก การเข้าถึงกล้องและไมโครโฟน (Camera &
                              Microphone)
                            </li>
                            <li>เลือก อนุญาต (Allow)</li>
                          </ul>
                        </div>

                        <h5 style="padding-top: 20px">
                          สำหรับ Android (Google Chrome)
                        </h5>
                        <div style="padding-left: 20px">
                          <ul style="list-style-type: disc">
                            <li>ไปที่ Google Chrome</li>
                            <li>คลิกที่ สัญลักษณ์ ... มุมขวาบน/ล่าง</li>
                            <li>เลือก การตั้งค่า (Settings)</li>
                            <li>เลือก การตั้งค่าเว็บไซต์ (Site Settings)</li>
                            <li>เลือก กล้องถ่ายรูป (Camera)</li>
                            <li>เลือก อนุญาต (Allow)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body text-center" >
                  <div class="row justify-content-center">
                    <button
                      @click="config_detail = false"
                      type="button"
                      class="tpsl-btn"
                      style="margin:20px 50px 30px 50px; width: 300px"
                    >
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { HTTP } from "../../config/axios";
import IdentityVerificationProcessVue from "./IdentityVerificationProcess.vue";
import UploadPictureVue from "./UploadPicture.vue";
export default Vue.extend({
  name: "ReviewKYC",
  components: {
    UploadPictureVue,
    IdentityVerificationProcessVue,
  },
  data() {
    return {
      notnoti: false,
      config_detail: false,
      JobWorkName: false,
      JobPosition: false,
      JobBusiness: false,
      LivenessSuccess: false,
      LivenessError: false,
      livenessStatus: 0,
      //Martial Status
      ListMaritalStatus: [
        { id: 1, name: "โสด" },
        { id: 2, name: "สมรส" },
        { id: 3, name: "หย่าร้าง" },
      ],
      searchcboMaritalStatus: "",
      selectedMaritalStatus: "",
      Open_cboMaritalStatus: false,
      //
      //Occupation
      ListOccupation: [],
      searchcboOccupation: "",
      selectedOccupation: "",
      Open_cboOccupation: false,
      //
      //Business Type
      ListBusinessType: [],
      searchcboBusinessType: "",
      selectedBusinessType: "",
      Open_cboBusinessType: false,
      //
      //Monthly Income
      ListIncome: [],
      searchcboIncome: "",
      selectedIncome: "",
      Open_cboIncome: false,
      //
      //Income Source
      ListIncomeSource: [],
      searchcboIncomeSource: "",
      selectedIncomeSource: "",
      Open_cboIncomeSource: false,
      //
      //Income Country
      ListCountryIncome: [],
      searchcboCountryIncome: "",
      selectedCountryIncome: "",
      Open_cboCountryIncome: false,
      //
      // Education Level
      ListEducationLevel: [],
      searchcboEducationLevel: "",
      selectedEducationLevel: "",
      Open_cboEducationLevel: false,

      //Investment
      ListInvestment: [],
      searchcboInvestment: "",
      //selectedInvestment: "",
      selectedInvestment: [],
      Open_cboInvestment: false,
      user: {
        fullNameTh: "",
        username: "",
        DOB: "",
        Age: "",
        firstName: "",
        lastName: "",
      },
      txtWorkPlaceName: "",
      txtPosition: "",
      txtFatca: null,
      txtSuitability: null,
      txtRiskSuitability: null,
      flagNewSuitability: false,
      txtLasserCode: "",
      txtLasserCodeError: false,
      txtVerification: "",
      txtDocuments: "",
      formData: {},
      VideoStream: null,
      CameraVideo: null,
      PhotoBase64: null,
      IDCardBase64: null,
      SelfiBase64: null,
      CameraStep: 0,
      CameraReady: false,
      NameSimilarity: 0,
      ModalIDCard: false,
      ModalSelfi: false,
      ModalShowPhoto: false,
      ModalSimilarity: false,
      ListKYCReview: [],
      riskScoreTag: false,
      selectedAttachments: [],
      suitTestScore: null,
      laserIdOptions: {
        tokens: {
          A: {
            pattern: /[A-Z]/,
            multiple: false,
            transform: (chr) => chr.toUpperCase(),
          },
        },
      },
      disabledSubmitKyc: false,
      checkStatusLiveness: true,
      checkOccupation: /นักเรียน|นักศึกษา/,
    };
  },
  watch: {},
  methods: {
    //ระบบเมื่อคลิ๊ก
    modal_check() {
      this.config_detail = true;
    },
    handleMaritalStatusClick() {
      this.Open_cboMaritalStatus = true;
      this.searchcboMaritalStatus = "";
    },
    handleOccupationStatusClick() {
      this.Open_cboOccupation = true;
      this.searchcboOccupation = "";
    },
    handleIncomeStatusClick() {
      this.Open_cboIncome = true;
      this.searchcboIncome = "";
    },
    handleIncomeSourceStatusClick() {
      this.Open_cboIncomeSource = true;
      this.searchcboIncomeSource = "";
    },
    /* handleInvestmentStatusClick() {
      this.Open_cboInvestment = true;
      this.searchcboInvestment = "";
    }, */
    handleCountryIncomeStatusClick() {
      this.Open_cboCountryIncome = true;
      this.searchcboCountryIncome = "";
    },
    handleEducationLevelStatusClick() {
      this.Open_cboEducationLevel = true;
      this.searchcboEducationLevel = "";
    },
    handleBusinessTypeStatusClick() {
      this.Open_cboBusinessType = true;
      this.searchcboBusinessType = "";
    },

    // ระบบตรวจ selectbox
    chkMaritalStatus() {
      setTimeout(() => {
        if (this.searchcboMaritalStatus.length > 0) {
          let chkMaritalStatus = this.ListMaritalStatus.find(
            (x) => x.name == this.searchcboMaritalStatus
          );
          if (chkMaritalStatus?.id) {
            this.selectedMaritalStatus = chkMaritalStatus.id;
          } else {
            this.searchcboMaritalStatus = "";
            this.selectedMaritalStatus = "";
          }
        } else if (this.selectedMaritalStatus) {
          let chkMaritalStatus = this.ListMaritalStatus.find(
            (x) => x.id == this.selectedMaritalStatus
          );
          if (chkMaritalStatus?.id) {
            this.searchcboMaritalStatus = chkMaritalStatus.name;
          } else {
            this.searchcboMaritalStatus = "";
            this.selectedMaritalStatus = "";
          }
        }
      }, 500);
    },
    chkOccupation() {
      setTimeout(() => {
        if (this.searchcboOccupation.length > 0) {
          let chkOccupation = this.ListOccupation.find(
            (x) => x.name == this.searchcboOccupation
          );
          if (chkOccupation?.id) {
            this.selectedOccupation = chkOccupation.id;
          } else {
            this.searchcboOccupation = "";
            this.selectedOccupation = "";
          }
        } else if (this.selectedOccupation) {
          let chkOccupation = this.ListOccupation.find(
            (x) => x.id == this.selectedOccupation
          );
          if (chkOccupation?.id) {
            this.searchcboOccupation = chkOccupation.name;
          } else {
            this.searchcboOccupation = "";
            this.selectedOccupation = "";
          }
        }
      }, 500);
    },
    chkBusinessType() {
      setTimeout(() => {
        if (this.searchcboBusinessType.length > 0) {
          let chkBusinessType = this.ListBusinessType.find(
            (x) => x.name == this.searchcboBusinessType
          );
          if (chkBusinessType?.id) {
            this.selectedBusinessType = chkBusinessType.id;
          } else {
            this.searchcboBusinessType = "";
            this.selectedBusinessType = "";
          }
        } else if (this.selectedBusinessType) {
          let chkBusinessType = this.ListBusinessType.find(
            (x) => x.id == this.selectedBusinessType
          );
          if (chkBusinessType?.id) {
            this.searchcboBusinessType = chkBusinessType.name;
          } else {
            this.searchcboBusinessType = "";
            this.selectedBusinessType = "";
          }
        }
      }, 500);
    },
    chkIncome() {
      setTimeout(() => {
        if (this.searchcboIncome.length > 0) {
          let chkIncome = this.ListIncome.find(
            (x) => x.monthlyIncome == this.searchcboIncome
          );
          if (chkIncome?.id) {
            this.selectedIncome = chkIncome.id;
          } else {
            this.searchcboIncome = "";
            this.selectedIncome = "";
          }
        } else if (this.selectedIncome) {
          let chkIncome = this.ListIncome.find(
            (x) => x.id == this.selectedIncome
          );
          if (chkIncome?.id) {
            this.searchcboIncome = chkIncome.monthlyIncome;
          } else {
            this.searchcboIncome = "";
            this.selectedIncome = "";
          }
        }
      }, 500);
    },
    chkIncomeSource() {
      setTimeout(() => {
        if (this.searchcboIncomeSource.length > 0) {
          let chkIncomeSource = this.ListIncomeSource.find(
            (x) => x.name == this.searchcboIncomeSource
          );
          if (chkIncomeSource?.id) {
            this.selectedIncomeSource = chkIncomeSource.id;
          } else {
            this.searchcboIncomeSource = "";
            this.selectedIncomeSource = "";
          }
        } else if (this.selectedIncomeSource) {
          let chkIncomeSource = this.ListIncomeSource.find(
            (x) => x.id == this.selectedIncomeSource
          );
          if (chkIncomeSource?.id) {
            this.searchcboIncomeSource = chkIncomeSource.name;
          } else {
            this.searchcboIncomeSource = "";
            this.selectedIncomeSource = "";
          }
        }
      }, 500);
    },
    chkCountryIncome() {
      setTimeout(() => {
        if (this.searchcboCountryIncome.length > 0) {
          let chkCountryIncome = this.ListCountryIncome.find(
            (x) => x.name == this.searchcboCountryIncome
          );
          if (chkCountryIncome?.code) {
            this.selectedCountryIncome = chkCountryIncome.code;
          } else {
            this.searchcboCountryIncome = "";
            this.selectedCountryIncome = "";
          }
        } else if (this.selectedCountryIncome) {
          let chkCountryIncome = this.ListCountryIncome.find(
            (x) => x.code == this.selectedCountryIncome
          );
          if (chkCountryIncome?.code) {
            this.searchcboCountryIncome = chkCountryIncome.name;
          } else {
            this.searchcboCountryIncome = "";
            this.selectedCountryIncome = "";
          }
        }
      }, 500);
    },
    chkEducationLevel() {
      setTimeout(() => {
        if (this.searchcboEducationLevel.length > 0) {
          let chkEducationLevel = this.ListEducationLevel.find(
            (x) => x.nameTh == this.searchcboEducationLevel
          );
          if (chkEducationLevel?.code) {
            this.selectedEducationLevel = chkEducationLevel.code;
          } else {
            this.searchcboEducationLevel = "";
            this.selectedEducationLevel = "";
          }
        } else if (this.selectedEducationLevel) {
          let chkEducationLevel = this.ListEducationLevel.find(
            (x) => x.code == this.selectedEducationLevel
          );
          if (chkEducationLevel?.code) {
            this.searchcboEducationLevel = chkEducationLevel.nameTh;
          } else {
            this.searchcboEducationLevel = "";
            this.selectedEducationLevel = "";
          }
        }
      }, 500);
    },
    /* chkInvestment(){
      setTimeout( () => {
        if(this.searchcboInvestment.length > 0){
          let chkInvestment = this.ListInvestment.find((x) => x.name == this.searchcboInvestment);
          if(chkInvestment?.id){
            this.selectedInvestment = chkInvestment.id;
          }else{
            this.searchcboInvestment = "";
            this.selectedInvestment = "";
          }
        } else if(this.selectedInvestment){
          let chkInvestment = this.ListInvestment.find((x) => x.id == this.selectedInvestment);
          if(chkInvestment?.id){
            this.searchcboInvestment = chkInvestment.name;
          }else{
            this.searchcboInvestment = "";
            this.selectedInvestment = "";
          }
        }
      }, 500);
    }, */
    resBasicInfo(value, text) {
      setTimeout(() => {
        if (text == "maritalStatus") {
        let selectedStatus = this.ListMaritalStatus.find((x) => x.id == value);
        if (selectedStatus?.id) {
          this.selectedMaritalStatus = selectedStatus.id;
          this.searchcboMaritalStatus = selectedStatus.name;
        }
      } else if (text == "occupation") {
        let selectedStatus = value;
        if (selectedStatus) {
          this.getOcupation(selectedStatus);
        }
      } else if (text == "businessTypeId") {
        this.selectedBusinessType = value;
      } else if (text == "position") {
        this.txtPosition = value;
      } else if (text == "monthlyIncome") {
        this.searchcboIncome = value;
      } else if (text == "IncomeSource") {
        this.searchcboIncomeSource = value;
      } else if (text == "countryIncome") {
        //this.searchcboCountryIncome = value;
        this.selectedCountryIncome = value;
        const selectedStatus = this.ListCountryIncome.find(
          (item) => item.code === value
        );
        if (selectedStatus) {
          this.searchcboCountryIncome = selectedStatus.name;
        }
        /* } else if (text == "purposeOfInvestment") {
        this.selectedInvestment = value;
        const selectedStatus = this.ListInvestment.find(
          (item) => item.id === value
        );
        if (selectedStatus) {
          this.searchcboInvestment = selectedStatus.name;
        } */
      } else if (text == "educationLevelId") {
        this.selectedEducationLevel = value;
      } else if (text == "educationLevel") {
        this.searchcboEducationLevel = value;
      } else if (text == "purposeOfInvestmentList") {
        this.selectedInvestment = value;
      } else if (text == "fatcaId") {
        this.txtFatca = value;
        //this.fatcaHas();
      } else if (text == "suitTestId") {
        this.txtSuitability = value;
      } else if (text == "suitTestScore") {
        if (!this.riskScoreTag) {
          this.txtRiskSuitability = this.getRiskGroup(value);
        }
      } else if (text == "Laserid") {
        this.txtLasserCode = value;
      }
      }, 0);
    
    },
    getRiskGroup(score) {
      if (score <= 15) {
        return "เสี่ยงต่ำ";
      } else if (score > 15 && score <= 21) {
        return "เสี่ยงปานกลางค่อนข้างต่ำ";
      } else if (score >= 22 && score <= 29) {
        return "เสี่ยงปานกลางค่อนข้างสูง";
      } else if (score >= 30 && score <= 36) {
        return "เสี่ยงสูง";
      } else if (score >= 37) {
        return "เสี่ยงสูงมาก";
      } else {
        return "";
      }
    },
    BacktoStartCamera() {
      this.ModalShowPhoto = false;
      this.CameraStep == 1
        ? (this.ModalSelfi = true)
        : (this.ModalIDCard = true);
      this.PhotoBase64 = null;
      (this.CameraVideoStream = null), (this.CameraVideo = null);
      this.CameraReady = false;
      this.StartCamera();
    },
    async NexttoStartCamera() {
      this.CameraStep += 1;
      if (this.CameraStep == 1) {
        this.ModalShowPhoto = false;
        this.ModalSelfi = true;
        this.IDCardBase64 = this.PhotoBase64;
        (this.CameraVideoStream = null), (this.CameraVideo = null);
        this.CameraReady = false;
        this.PhotoBase64 = null;
        this.saveIdcard();
        this.StartCamera();
      } else if (this.CameraStep == 2) {
        this.ModalSelfi = false;
        this.ModalIDCard = false;
        this.ModalEditName = false;
        this.FaceRecognize();
        this.ModalSimilarity = true;
      } else if (this.CameraStep == 3) {
        this.ModalSimilarity = true;
      }
    },
    prepareStartCamera() {
      this.saveForm();
      this.CameraStep = 0;
      this.txtVerification = null;
      this.livenessStatus = 0;
      this.StartCamera();
    },
    async StartCamera() {
      try {
        this.CameraVideoStream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1280, height: 720 },
        });
        this.CameraVideo = this.$refs.camera;
        this.CameraVideo.srcObject = this.CameraVideoStream;

        this.CameraVideo.addEventListener("loadedmetadata", () => {
          // Video's metadata is loaded; it's now safe to work with this.CameraVideo
          this.CameraReady = true;
        });
      } catch (error) {
        console.log("Camera MetaData Loaded" + error);
        this.$notify({
          group: "notification",
          type: "error",
          title: "Unable to Start Camera for Verification",
          text: error,
        });
      }
    },
    StopCamera() {
      if (this.CameraVideoStream) {
        this.CameraVideoStream.getTracks().forEach((track) => track.stop());
      }
    },
    TakePicture() {
      if (
        this.CameraVideo &&
        this.CameraVideoStream &&
        this.CameraReady == true
      ) {
        const canvas = document.createElement("canvas");
        canvas.width = this.CameraVideo.videoWidth;
        canvas.height = this.CameraVideo.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(this.CameraVideo, 0, 0, canvas.width, canvas.height);
        const base64Image = canvas.toDataURL("image/jpeg");
        this.PhotoBase64 = base64Image;
        if (this.CameraVideoStream) {
          this.CameraVideoStream.getTracks().forEach((track) => track.stop());
        }
        if (this.CameraStep == 1) {
          this.SelfiBase64 = this.PhotoBase64;
          this.CheckLiveness();
        } else {
          this.ModalShowPhoto = true;
          this.ModalSelfi = false;
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Unable to Take Picture for Verification",
          text: "Please Wait for the Camera to be Ready.",
        });
      }
    },
    livenessCode(code) {
      if (code == 1) {
        return "successfully took a confirmation photo.";
      } else if (code == 2) {
        return "Confirmation photo did not pass the verification process.";
      } else if (code == 3) {
        return "Incomplete confirmation photo, please take a new one.";
      } else if (code == 4) {
        return "The system has a problem at this time; please try again later.";
      } else {
        return "The system has a problem at this time; please try again later.";
      }
    },
    CheckLiveness() {
      let self = this;
      //console.log("this.SelfiBase64");
      self.checkStatusLiveness = false;
      HTTP.post("/uc/recognize/liveness", {
        imageBase64: this.processBase64(this.SelfiBase64),
      })
        .then(function (response) {
          self.checkStatusLiveness = true;
          if (response?.data?.data?.result == true) {
            self.ModalShowPhoto = true;
            self.ModalSelfi = false;
            self.$notify({
              group: "notification",
              type: "success",
              title: "Liveness Pass",
              text: self.livenessCode(response.data.data?.code),
            });
          } else if (response?.data?.data?.result == false) {
            self.BacktoStartCamera();
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: self.livenessCode(response.data.data?.code),
            });
          }
          //console.log("CameraStep", self.CameraStep);
        })
        .catch(function (error) {
          self.checkStatusLiveness = true;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
    FaceRecognize() {
      let self = this;
      self.isCalculating = true;
      self.ModalShowPhoto = false;
      //self.livenessStatus = 1;

      HTTP.post("/uc/recognize/face-recognize-verify", {
        imgSource: this.processBase64(this.SelfiBase64),
        /*
        HTTP.post("/uc/recognize/face-recognize", {
          imgSource: this.processBase64(this.IDCardBase64),
          imgTarget: this.processBase64(this.SelfiBase64),
        */
      })
        .then(function (response) {
          //if (response?.data?.data?.reason == "Complete") {
          self.NameSimilarity = response?.data?.data?.similarity;
          if (response.data.code === 0) {
            if (self.NameSimilarity > 95) {
              // self.changeNameOTP();
              self.ModalShowPhoto = false;
              self.livenessStatus = 1;
              self.txtVerification = "true";
              self.updateFormData("txtVerification");

              self.isCalculating = false;
            } else {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: response.data.data.reason,
              });

              self.livenessStatus = 2;
              self.ModalShowPhoto = false;
              self.OtpError = true;
              self.OtpValue = self.NameSimilarity;
              self.OtpType = "name";

              self.isCalculating = false;
            }
          }

          if (response?.data?.code === 500) {
            const message = response?.data?.message;

            self.ModalShowPhoto = false;
            self.ModalSimilarity = false;
            self.ModalIDCard = false;
            self.CameraStep = 0;

            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: message,
            });
          }
        })
        .catch(function (error) {
          /* self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          }); */
        })
        .finally(() => {
          //self.isCalculating = false;
        });
    },
    processBase64(Base64String) {
      const prefix = "data:image/jpeg;base64,";
      if (Base64String.startsWith(prefix)) {
        return Base64String.substring(prefix?.length);
      }
    },
    uploadEmitDocument(payload) {
      this.txtDocuments = payload;
      this.formData.txtDocuments = payload;
      localStorage.setItem("formData", JSON.stringify(this.formData));
    },
    laserCodeDopaValidate() {
      const LasserCode = this.validateLaserCode(this.txtLasserCode);
      return new Promise((resolve, reject) => {
        HTTP.post("/uc/member/check-laser-code", {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          laserCode: LasserCode,
        })
          .then((response) => {
            if (response.data.code === 0) {
              this.$notify({
                group: "notification",
                type: "success",
                title: "การตรวจสอบรหัสหลังบัตรสำเร็จ",
                text: "ข้อมูลถูกต้อง",
              });
              resolve(true);
            } else {
              this.disabledSubmitKyc = false;
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: response.data.message,
              });
              resolve(false);
            }
          })
          .catch((error) => {
            this.disabledSubmitKyc = false;
            // Handle any errors that occurred during the HTTP request
            console.error("Error during HTTP request:", error);
            reject(error);
          });
      });
    },
    saveIdcard(){
      HTTP.post("/uc/save-id-card", {
        imgSource: this.processBase64(this.IDCardBase64),
                  }).then((res) => {
                    if (res.data.code != 0) {
                       this.$notify({
                        group: "notification",
                        type: "error",
                        title: "Save id card failed",
                        text: res.data.message,
                  });
                }
         })
    },
    submitKyc() {
      if (this.submitButtonDisabled) {
        this.disabledSubmitKyc = true;
        const LasserCode = this.validateLaserCode(this.txtLasserCode);
        this.laserCodeDopaValidate().then((isValid) => {
          if (isValid) {
            HTTP.post("/uc/kyc/review", {
              //requestId: "test",
              //kycReviewType: 0,
              //transactionDate: "14-02-2023",
              maritalStatus: this.selectedMaritalStatus - 1,
              occupationId: this.selectedOccupation,
              //occupationRevision: this.searchcboOccupation,
              businessTypeId: this.selectedBusinessType,
              //businessTypeRevision: this.searchcboBusinessType,
              position: this.txtPosition,
              workplace: this.txtWorkPlaceName,
              monthlyIncomeId: this.selectedIncome,
              monthlyIncome: this.searchcboIncome,
              fundSourceTypeId: this.selectedIncomeSource,
              fundSourceType: this.searchcboIncomeSource,
              fundSourceCountryCode: this.selectedCountryIncome,
              fatcaId: this.txtFatca,
              //purposeOfInvestmentId: this.selectedInvestment,
              purposeOfInvestmentList: this.selectedInvestment,
              //knowledgeTestId: 2,
              suitTestId: this.txtSuitability,
              educationLevelId: this.selectedEducationLevel,
              //riskId: 4,
              //oldRiskId: 5,
              //status: 0,
              //kycServiceMethod: 0,
              //workflowId: "test",
              //imageSelfieVerify: this.processBase64(this.IDCardBase64),
              imageSelfie: this.processBase64(this.SelfiBase64),
              laserCode: LasserCode,
              faceSimilarity: this.NameSimilarity,
            })
              .then((res) => {
                if (res.data.code != 0) {
                  this.$notify({
                    group: "notification",
                    type: "error",
                    title: "Kyc Review failed",
                    text: res.data.message,
                  });
                }
                const kycReviewId = res.data.data.kycReviewId;
                if (this.selectedAttachments.length > 0) {
                  const filesId = [];
                  for (const attachment of this.selectedAttachments) {
                    if (!attachment.fileId) {
                      HTTP.post("/uc/upload/base64", {
                        fileName: attachment.fileName,
                        contentType: attachment.contentType,
                        base64: attachment.base64,
                        requireAuthen: false,
                      }).then((response) => {
                        const filesIdFromResponse = response.data;
                        filesId.push(filesIdFromResponse);

                        HTTP.post("/uc/save/attachment/multiple", {
                          category: "KYC",
                          refId: kycReviewId,
                          refType: "KYC_REVIEW",
                          idList: [filesIdFromResponse.data],
                        });
                      });
                    } else {
                      HTTP.post("/uc/save/attachment/multiple", {
                        category: "KYC",
                        refId: kycReviewId,
                        refType: "KYC_REVIEW",
                        idList: [attachment.fileId],
                      });
                    }
                  }
                }
                this.$notify({
                  group: "notification",
                  type: res.data.code == 0 ? "success" : "error",
                  title:
                    res.data.code == 0
                      ? "Kyc Review Successful"
                      : "Kyc Review failed",
                  text: res.data.message,
                });
                localStorage.removeItem("kycReviewData");
                if (res.data.code == "0") {
                  this.$router.push("/ProfileSetting");
                  setTimeout(() => {
                    location.reload();
                  }, 1000);
                }
              })
              .finally(() => {
                this.disabledSubmitKyc = false;
              });
          }
        });
      }
    },
    checkStatusKyc() {
      const kycReviewStatus = localStorage.getItem("kycReviewStatus");
      const storedData = localStorage.getItem("resMember");
      const data = JSON.parse(storedData);
      const requireKycReview = localStorage.getItem("requireKycReview");
      //console.log("resMember=", data);
      this.user.fullNameTh = data?.resMember.fullNameTh;
      let nonName = data?.resMember.realName;
      let nameParts = nonName.split(" ");
      // Extract firstName and Surname from the array
      this.user.firstName = nameParts[0]; // "สมชาย"
      this.user.lastName = nameParts.slice(1).join(" "); // "จันทร์โอชา"
      if (requireKycReview == "true") {
        // Continue
      } else if (kycReviewStatus != '1' && requireKycReview == "false") {
        this.$router.push("/ProfileSetting");
      } else if (kycReviewStatus == '1') {
        this.$router.push("/ProfileSetting");
      }
    },
    handleInputClick() {
      this.saveForm();
      this.$router.push("/fatca");
    },
    handleSuitabilityGroup() {
      this.saveForm();
      this.$router.push("/suitability-group");
    },
    getFormattedDate(DOB) {
      if (DOB) {
        const birthdate = new Date(DOB);
        return moment(birthdate).format(this.TIMEPICKER);
      }
      return null;
    },
    getAge(DOB) {
      if (DOB) {
        const today = new Date();
        const birthdate = new Date(DOB);
        //หาวันเกิดปีปัจจุบัน
        const tbirthDay = new Date(
          today.getFullYear(),
          birthdate.getMonth(),
          birthdate.getDate()
        );
        return (
          today.getFullYear() -
          birthdate.getFullYear() -
          (today < tbirthDay ? 1 : 0)
        );
      }
      return null;
    },
    validateLaserCode(LaserCode) {
      if (LaserCode == undefined) {
        return false;
      }
      return LaserCode.replace(/[^A-Za-z0-9]/g, "");
    },
    getMemberInfo() {
      let self = this;
      HTTP.post("/uc/member/get-member-info")
        .then(function (response) {
          if (response.data.code === 0) {
            let resMember = response.data.data.member;
            let reslockStatus = response.data.data.lockStatus;
            let combinedDataMemberStatus = {
              resMember: resMember,
              reslockStatus: reslockStatus,
            };
            let kycReviewStatus = response.data.data.kycReviewStatus;
            let resData = response.data.data;
            localStorage.setItem(
              "resMember",
              JSON.stringify(combinedDataMemberStatus)
            );
            let data = JSON.parse(localStorage.getItem("resMember"));
             let requireKycReview = response.data.data.resMember.requireKycReview;
            //  copnsole.log(kycReviewStatus)
            //  copnsole.log(response.data.data.resMember.requireKycReview)
            localStorage.setItem("kycReviewStatus", kycReviewStatus);
            localStorage.setItem("requireKycReview", requireKycReview);
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Member Info Successful"
                  : "Unable to Get Current Member Info",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Info",
            text: error,
          });
        });
    },
    getOcupation(value) {
    
      let self = this;
      HTTP.post("/uc/member/get-occupation")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListOccupation = response.data.data;
            if (value) {

              let selectedStatus = self.ListOccupation.find(
                (x) => x.id == value
              );
              self.JobWorkName = selectedStatus.workplaceFlag;
              self.JobPosition = selectedStatus.positionFlag;
              self.JobBusiness = selectedStatus.businessFlag;
              self.selectedOccupation = selectedStatus.id;
              self.searchcboOccupation = selectedStatus.name;
              if (self.JobBusiness) {
                self.getBussinesstype();
              }
              if (!self.JobWorkName || !self.JobPosition || !self.JobBusiness) {
                if (!self.JobWorkName) {
                  self.formData.txtWorkPlaceName = "";
                  self.txtWorkPlaceName = "";
                } else if (!self.JobPosition) {
                  self.formData.txtPosition = "";
                  self.txtPosition = "";
                } else if (!self.JobBusiness) {
                  self.formData.selectedBusinessType = "";
                  self.formData.searchcboBusinessType = "";
                  self.selectedBusinessType = "";
                  self.searchcboBusinessType = "";
                }
                localStorage.setItem("formData", JSON.stringify(self.formData));
              }
            }
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation List Unsuccessful",
            text: error,
          });
        });
    },
    getBussinesstype() {
      let self = this;
      if (self.selectedOccupation) {
        HTTP.post("/uc/member/get-bussinesstype/" + self.selectedOccupation)
          .then((response) => {
            if (response.data.code === 0 && response.data.data.length > 0) {
              self.ListBusinessType = response.data.data;
              if (self.selectedBusinessType) {
                let selectedStatus = self.ListBusinessType.find(
                  (x) => x.id === self.selectedBusinessType
                );
                if (selectedStatus) {
                  self.searchcboBusinessType = selectedStatus.name;
                }
              }
            } else {
              self.selectedBusinessType = "";
              self.searchcboBusinessType = "";
            }
          })
          .catch(function (error) {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Get Business Type List Unsuccessful",
              text: error,
            });
          });
      }
    },
    getMonthlyIncome() {
      let self = this;
      HTTP.post("/uc/member/get-monthlyincome")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListIncome = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Income List Unsuccessful",
            text: error,
          });
        });
    },
    getIncomeSource() {
      let self = this;
      HTTP.post("/uc/member/get-fund-source-type")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListIncomeSource = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Income Source List Unsuccessful",
            text: error,
          });
        });
    },
    getIncomeCountry() {
      let self = this;
      HTTP.post("/uc/member/get-all-country")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListCountryIncome = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Country List Unsuccessful",
            text: error,
          });
        });
    },

    getEducationLevel() {
      let self = this;
      HTTP.post("/uc/member/get-education-level")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListEducationLevel = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Education List Unsuccessful",
            text: error,
          });
        });
    },

    getInvestment() {
      let self = this;
      HTTP.post("/uc/member/get-purpose-of-investment")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListInvestment = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Investment Unsuccessful",
            text: error,
          });
        });
    },
    getFetca() {
      if (!this.$route.params.fatcaId) {
        HTTP.post("/uc/fatca/getFatca").then((response) => {
          if (response.data.code == "200") {
            const fatca = response.data.data;
            localStorage.setItem("fatcaData", JSON.stringify(fatca));
          }
        });
      }
    },

    getFetcaById(fatcaId) {
      HTTP.post("/uc/fatca/getFatcaById", {
        id: fatcaId,
      }).then((response) => {
        if (response.data.code == "200") {
          const fatca = response.data.data;
          localStorage.setItem("fatcaData", JSON.stringify(fatca));
        }
      });
    },
    getKycReview() {
      let self = this;
      if (!localStorage.getItem("fatcaSet")) {
        HTTP.post("/uc/member/get-kyc-review")
          .then((response) => {
            if (response.data.code === 0) {
              const kycReviewData = response.data.data.kycReviewData;
              localStorage.setItem(
                "kycReviewData",
                JSON.stringify(kycReviewData)
              );
              self.ListKYCReview = response.data.data.kycReviewData;
              if (response.data.data && "kycReviewData" in response.data.data) {
                //ชื่อ - นามสกุล
                if (!self.user.fullNameTh) {
                  self.user.fullNameTh = self.ListKYCReview.nameTh;
                }
                //เลขที่บัญชี
                self.user.username = self.ListKYCReview.username;
                //วันเดือนปีเกิด
                self.user.DOB = self.getFormattedDate(
                  self.ListKYCReview.birthDay
                );
                //อายุ
                self.user.Age =
                  self.getAge(self.ListKYCReview.birthDay) + " ปี";
                //สถานภาพ
                self.resBasicInfo(
                  self.ListKYCReview.maritalStatus + 1,
                  "maritalStatus"
                );
                //สถานที่ทำงาน || สถานศึกษา
                this.txtWorkPlaceName = self.ListKYCReview.workplace;
                //อาชีพ
                self.resBasicInfo(
                  self.ListKYCReview.occupationId,
                  "occupation"
                );
                //ประเภทธุรกิจ
                self.resBasicInfo(
                  self.ListKYCReview.businessTypeId,
                  "businessTypeId"
                );
                //ตำแหน่ง
                self.resBasicInfo(self.ListKYCReview.position, "position");
                //รายได้ (บาท)
                self.resBasicInfo(
                  self.ListKYCReview.monthlyIncome,
                  "monthlyIncome"
                );
                this.selectedIncome = self.ListKYCReview.monthlyIncomeId;
                //แหล่งที่มารายได้
                self.resBasicInfo(
                  self.ListKYCReview.fundSourceType,
                  "IncomeSource"
                );
                this.selectedIncomeSource = self.ListKYCReview.fundSourceTypeId;
                //ประเทศที่มาของรายได้
                self.resBasicInfo(
                  self.ListKYCReview.fundSourceCountry,
                  "countryIncome"
                );

                // ระดับการศึกษาสูงสุด
                self.resBasicInfo(
                  self.ListKYCReview.educationLevelId,
                  "educationLevelId"
                );

                self.resBasicInfo(
                  self.ListKYCReview.educationLevel,
                  "educationLevel"
                );

                //วัตถุประสงค์การลงทุน
                //self.resBasicInfo(self.ListKYCReview.purposeOfInvestment,"purposeOfInvestment");
                self.resBasicInfo(
                  self.ListKYCReview.purposeOfInvestmentList,
                  "purposeOfInvestmentList"
                );
                //บุคคลอเมริกันตามเกณฑ์ FATCA
                // const localFatcaId = localStorage.getItem("fatcaId");
                // self.resBasicInfo(localFatcaId == null ? self.ListKYCReview.fatcaId : localFatcaId, 'fatcaId');
                self.resBasicInfo(self.ListKYCReview.fatcaId, "fatcaId");
                if (self.ListKYCReview.fatcaId) {
                  this.getFetca();
                }
                //แบบประเมินความเหมาะสมการลงทุน
                self.resBasicInfo(self.ListKYCReview.suitTestId, "suitTestId");
                self.resBasicInfo(
                  self.ListKYCReview.suitTestScore,
                  "suitTestScore"
                );
                //รหัสหลังบัตรประชาชน (Laser Code)
                //self.resBasicInfo(self.ListKYCReview.laserid, "Laserid");

                //ไฟล์อัพโหลด
                const fileNameToRemove = "รูปถ่ายบัตรประชาชน.jpg";
                // ใช้เมธอด filter เพื่อลบรายการที่มี fileName ตรงกับ fileNameToRemove
                if (self.ListKYCReview.attachments) {
                  self.ListKYCReview.attachments =
                    self.ListKYCReview.attachments.filter((attachment) => {
                      return attachment.fileName !== fileNameToRemove;
                    });
                  self.selectedAttachments = self.ListKYCReview.attachments;
                }

                //Review KYC สถานะ
                const kycReviewStatus = self.ListKYCReview.kycReviewStatus;
                // localStorage.setItem(
                //   "kycReviewStatus",
                //   JSON.stringify(kycReviewStatus)
                // );
              }
            } else {
              self.$notify({
                group: "notification",
                type: response.data.code === 0 ? "success" : "error",
                title:
                  response.data.code === 0
                    ? "success get-kyc-review"
                    : "error get-kyc-review",
                text: response.data.message,
              });
            }
          })
          .catch(function (error) {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Get KYC Review Unsuccessful",
              text: error,
            });
          });
      } else {
        localStorage.removeItem("fatcaSet");
        //ถ้ามีข้อมูล
        const storedData = localStorage.getItem("kycReviewData");
        if (storedData) {
          self.formData = JSON.parse(storedData);
          if (!self.user.fullNameTh) {
            self.user.fullNameTh = self.formData.nameTh;
          }
          //เลขที่บัญชี
          self.user.username = self.formData.username;
          //วันเดือนปีเกิด
          self.user.DOB = self.getFormattedDate(self.formData.birthDay);
          //อายุ
          self.user.Age = self.getAge(self.formData.birthDay) + " ปี";
          //สถานภาพ
          self.resBasicInfo(self.formData.maritalStatus, "maritalStatus");
          //สถานที่ทำงาน || สถานศึกษา
          self.txtWorkPlaceName = self.formData.workplace;
          //อาชีพ
          self.resBasicInfo(self.formData.occupationId, "occupation");
          self.selectedOccupation = self.formData.occupationId;
          //ตำแหน่ง
          self.resBasicInfo(self.formData.position, "position");
          //รายได้ (บาท)
          self.resBasicInfo(self.formData.monthlyIncome, "monthlyIncome");
          self.selectedIncome = self.formData.monthlyIncomeId;
          //แหล่งที่มารายได้
          self.resBasicInfo(self.formData.fundSourceType, "IncomeSource");
          self.selectedIncomeSource = self.formData.fundSourceTypeId;
          //ประเทศที่มาของรายได้
          self.resBasicInfo(self.formData.fundSourceCountry, "countryIncome");
          self.resBasicInfo(self.formData.businessTypeId, "businessTypeId");
          
          //วัตถุประสงค์การลงทุน
          //self.resBasicInfo(self.formData.purposeOfInvestment,"purposeOfInvestment");

          self.resBasicInfo(self.formData.educationLevelId, "educationLevelId");

          self.resBasicInfo(self.formData.educationLevel, "educationLevel");

          self.resBasicInfo(
            self.formData.purposeOfInvestmentList,
            "purposeOfInvestmentList"
          );
          //บุคคลอเมริกันตามเกณฑ์ FATCA
          //const localFatcaId = localStorage.getItem("fatcaId");
          self.resBasicInfo(self.formData.fatcaId, "fatcaId");
          //self.resBasicInfo(localFatcaId == null ? self.formData.fatcaId : localFatcaId, 'fatcaId');
          //แบบประเมินความเหมาะสมการลงทุน
          self.resBasicInfo(self.formData.suitTestId, "suitTestId");
          self.resBasicInfo(self.formData.suitTestScore, "suitTestScore");
          //รหัสหลังบัตรประชาชน (Laser Code)
          self.resBasicInfo(self.formData.laserid, "Laserid");
        }
      }
    },
    saveForm() {
      let tempData = JSON.parse(localStorage.getItem("kycReviewData"));
      if (tempData == null) {
        tempData = JSON.parse(localStorage.getItem("formData"));
      }
      const kycReviewData = {
        birthDay: tempData.birthDay,
        nameTh: tempData.nameTh,
        nameEN: tempData.nameEN,
        username: tempData.username,
        maritalStatus: this.selectedMaritalStatus,
        workplace: this.txtWorkPlaceName,
        occupationId: this.selectedOccupation,
        businessTypeId: this.selectedBusinessType,
        position: this.txtPosition,
        monthlyIncomeId: this.selectedIncome,
        monthlyIncome: this.searchcboIncome,
        fundSourceType: this.searchcboIncomeSource,
        fundSourceTypeId: this.selectedIncomeSource,
        fundSourceCountry: this.selectedCountryIncome,
        educationLevelId: this.selectedEducationLevel,
        educationLevel: this.searchcboEducationLevel,
        laserid: this.validateLaserCode(this.txtLasserCode),
        suitTestId: this.txtSuitability,
        suitTestScore: this.suitTestScore,
        fatcaId: this.txtFatca,
        //purposeOfInvestment: this.selectedInvestment,
        purposeOfInvestmentList: this.selectedInvestment,
        kycReviewStatus: tempData.kycReviewStatus,
        titleId: tempData.titleId,
      };
      localStorage.setItem("kycReviewData", JSON.stringify(kycReviewData));
    },
    getTitleName() {
      let self = this;
      HTTP.post("/uc/member/get-title")
        .then((response) => {
          if (response.data.code === 0) {
            self.ListTitleName = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Change Email Successful"
                  : "Get Title Name Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    checkIfClickedOutside(event) {
      const cboMaritalStatus = this.$refs.cboMaritalStatus;
      const cboOccupation = this.$refs.cboOccupation;
      const cboBusinessType = this.$refs.cboBusinessType;
      const cboIncome = this.$refs.cboIncome;
      const cboIncomeSource = this.$refs.cboIncomeSource;
      const cboCountryIncome = this.$refs.cboCountryIncome;
      const cboEducationLevel = this.$refs.cboEducationLevel;
      //const cboInvestment = this.$refs.cboInvestment;
      if (cboMaritalStatus && !cboMaritalStatus.contains(event.target)) {
        this.Open_cboMaritalStatus = false;
      }
      if (cboOccupation && !cboOccupation.contains(event.target)) {
        this.Open_cboOccupation = false;
      }
      if (cboBusinessType && !cboBusinessType.contains(event.target)) {
        this.Open_cboBusinessType = false;
      }
      if (cboIncome && !cboIncome.contains(event.target)) {
        this.Open_cboIncome = false;
      }
      if (cboIncomeSource && !cboIncomeSource.contains(event.target)) {
        this.Open_cboIncomeSource = false;
      }
      if (cboCountryIncome && !cboCountryIncome.contains(event.target)) {
        this.Open_cboCountryIncome = false;
      }
      if (cboEducationLevel && !cboEducationLevel.contains(event.target)) {
        this.Open_cboEducationLevel = false;
      }
      /* if (cboInvestment && !cboInvestment.contains(event.target)) {
        this.Open_cboInvestment = false;
      } */
    },
    /* fatcaHas() {
      if (this.$route.params.fatcaId) {
        if (this.$route.params.fatcaId == "NO") {
          this.txtFatca = null;
          localStorage.removeItem("fatcaData");
        } else {
          this.txtFatca = this.$route.params.fatcaId;
          this.formData.txtFatca = this.txtFatca;
          localStorage.setItem("formData", JSON.stringify(this.formData));
          this.getFetcaById(this.txtFatca);
        }
      }
    }, */
    updateFormData(data) {
      this.fieldsToUpdate.forEach((field) => {
        this.formData[field] = this[field];
      });
      localStorage.setItem("formData", JSON.stringify(this.formData));
    },
    checkTextWorkplace(occupation) {
      if (this.checkOccupation.test(occupation)) {
        return "ศึกษา";
      }
      return "ที่ทำงาน";
    },
  },
  mounted() {
    window.addEventListener("click", this.checkIfClickedOutside);
    this.getKycReview();
    //console.log(this.$route);
  },
  computed: {
    submitButtonDisabled() {
      let chkSubmit = true;
      if (!this.selectedMaritalStatus) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกสถานภาพของคุณ",
        });
        chkSubmit = false;
      } else if (!this.selectedEducationLevel) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกระดับการศึกษาสูงสุดของคุณ",
        });
        chkSubmit = false;
      } else if (!this.txtWorkPlaceName && this.JobWorkName) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: `กรุณากรอกสถาน${this.checkTextWorkplace(
            this.searchcboOccupation
          )}ของคุณ`,
        });
        chkSubmit = false;
      } else if (!this.selectedOccupation) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกอาชีพของคุณ",
        });
        chkSubmit = false;
      } else if (!this.selectedBusinessType && this.JobBusiness) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกประเภทธุรกิจของคุณ",
        });
        chkSubmit = false;
      } else if (!this.txtPosition && this.JobPosition) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณากรอกตำแหน่งของคุณ",
        });
        chkSubmit = false;
      } else if (!this.selectedIncome) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกรายได้ของคุณ",
        });
        chkSubmit = false;
      } else if (!this.selectedIncomeSource) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกแหล่งที่มาของรายได้ของคุณ",
        });
        chkSubmit = false;
      } else if (!this.selectedCountryIncome) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกประเทศที่มาของรายได้ของคุณ",
        });
        chkSubmit = false;
        //} else if(!this.selectedInvestment){
      } else if (this.selectedInvestment <= 0) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาเลือกวัตถุประสงค์การลงทุนของคุณ",
        });
        chkSubmit = false;
      } else if (!this.flagNewSuitability) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณาทำแบบประเมินความเหมาะสมการลงทุนใหม่อีกครั้ง",
        });
        chkSubmit = false;
      } else if (this.txtLasserCode.length != 14) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณากรอกรหัสหลังบัตรประชาชน",
        });
        this.txtLasserCodeError = true;
        chkSubmit = false;
      } else if (!this.txtVerification) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "KYC Validate",
          text: "กรุณายืนยันรูปถ่าย",
        });
        chkSubmit = false;
      }
      return chkSubmit;
    },
    fieldsToUpdate() {
      return [
        "searchcboMaritalStatus",
        "selectedOccupation",
        "searchcboOccupation",
        "selectedBusinessType",
        "searchcboBusinessType",
        "searchcboEducationLevel",
        "txtPosition",
        "txtWorkPlaceName",
        "selectedIncome",
        "searchcboIncome",
        "selectedIncomeSource",
        "searchcboIncomeSource",
        "searchcboInvestment",
        "searchcboCountryIncome",
        "txtFatca",
        "txtDocuments",
        "txtLasserCode",
        "txtSuitability",
        "txtRiskSuitability",
      ];
    },
    filteredListMaritalStatus() {
      if (!this.ListMaritalStatus) return [];
      return this.ListMaritalStatus.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchcboMaritalStatus?.toLowerCase() || "")
      );
    },
    filteredListOccupation() {
      if (!this.ListOccupation) return [];
      return this.ListOccupation.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchcboOccupation?.toLowerCase() || "")
      );
    },
    filteredListBusinessType() {
      if (!this.ListBusinessType) return [];
      return this.ListBusinessType.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchcboBusinessType?.toLowerCase() || "")
      );
    },
    filteredListIncome() {
      if (!this.ListIncome) return [];
      return this.ListIncome.filter((item) =>
        item.monthlyIncome
          ?.toLowerCase()
          .includes(this.searchcboIncome?.toLowerCase() || "")
      );
    },
    filteredListIncomeSource() {
      if (!this.ListIncomeSource) return [];
      return this.ListIncomeSource.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchcboIncomeSource?.toLowerCase() || "")
      );
    },
    filteredListCountryIncome() {
      //console.log(this.ListCountryIncome, "list");
      if (!this.ListCountryIncome) return [];
      return this.ListCountryIncome.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchcboCountryIncome?.toLowerCase() || "")
      );
    },
    filteredListEducationLevel() {
      if (!this.ListEducationLevel) return [];
      return this.ListEducationLevel;
      // .filter((item) =>
      //   item.nameTh
      //     ?.toLowerCase()
      //     .includes(this.searchcboEducationLevel?.toLowerCase() || "")
      // );
    },
    /* filteredListInvestment() {
      if (!this.ListInvestment) return [];
      return this.ListInvestment.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchcboInvestment?.toLowerCase() || "")
      );
    }, */
  },
  created() {
    this.checkStatusKyc();
    //this.getTitleName();
    
    //this.getMemberInfo();
    this.getMonthlyIncome();
    this.getIncomeSource();
    this.getIncomeCountry();
    this.getEducationLevel();
    this.getInvestment();
    const storedData = localStorage.getItem("formData");
    if (storedData) {
      this.formData = JSON.parse(storedData);
      this.fieldsToUpdate.forEach((field) => {
        this[field] = this.formData[field]; // Use this[field] to access the value
      });
    }
    //fatca
    if (this.$route.params.fatcaId) {
      if (this.$route.params.fatcaId == "NO") {
        this.txtFatca = null;
        localStorage.removeItem("fatcaData");
      } else {
        this.txtFatca = this.$route.params.fatcaId;
        this.formData.txtFatca = this.txtFatca;
        localStorage.setItem("formData", JSON.stringify(this.formData));
        if (this.txtFatca) {
          this.getFetcaById(this.txtFatca);
        }
      }
    }
    //suitability test
    if (this.$route.params.suitabilityGroupText) {
      this.riskScoreTag = true;
      this.txtSuitability = this.$route.params.suitabilityGroupId;
      this.txtRiskSuitability = this.$route.params.suitabilityGroupText;
      this.formData.txtSuitability = this.txtSuitability;
      this.formData.txtRiskSuitability = this.txtRiskSuitability;
      localStorage.setItem("formData", JSON.stringify(this.formData));
      this.flagNewSuitability = this.$route.params.flagNewSuitability;
    }
    if (!this.txtSuitability) {
      this.txtSuitability = this.$route.params.suitabilityGroupId;
      this.txtRiskSuitability = this.$route.params.suitabilityGroupText;
      this.formData.txtSuitability = this.txtSuitability;
      this.formData.txtRiskSuitability = this.txtRiskSuitability;
      localStorage.setItem("formData", JSON.stringify(this.formData));
    }
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkIfClickedOutside);
  },
});
</script>
<style lang="scss" scoped>
.w-400 {
  font-weight: 400 !important;
}

.modal-body.scroller {
  max-height: none;
  /* or a specific value like max-height: 500px; */
  overflow: hidden;
}

.modal-header {
  padding: 0px !important;
}

.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.modal-title {
  color: #d6dde1;
  font-size: 24px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 28px;
}

.input-container {
  position: relative;
  input {
    background-color: #191b1c;
    color: #d6dde1;
    border-radius: 4px;
    height: 40px;
    padding: 0 40px;
    text-transform: capitalize;
    :disabled {
      background-color: #d6dde1 !important;
      opacity: 0.3 !important;
    }
    &::placeholder {
      color: #566a76;
    }
  }
  span {
    color: #9bacb6;
    margin-left: -30px;
  }
}

ul li::before {
  content: "" !important;
}

.address-input {
  width: 280px !important;
}
.buttonWidth1 {
  width: 180px !important;
}

.buttonWidth2 {
  width: 140px !important;
}

label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #d6dde1;
}

.page-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: #d6dde1;
  margin: 0px;
}

.Heading {
  padding: 24px 0px 16px 52px;
}

.form-control {
  outline: none;
  :disabled {
    background-color: #d6dde1 !important;
    opacity: 0.3 !important;
  }
}

input:disabled {
  background-color: #d6dde1 !important;
  opacity: 0.3 !important;
}

ul {
  list-style: none;
  color: #9bacb6 !important;
}

ul li::before {
  content: "\2022";
  color: #9bacb6 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.scroller {
  overflow-y: scroll;
}

[class^="col-"] {
  margin-top: 12px;
}

.modal-header {
  padding: 0px !important;
}

.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.page-heading {
  padding: 24px 0px 21px 105px;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: #d6dde1;
}
.modal-dialog-live {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}
.d-options {
  position: absolute;
  top: 45px;
  width: 59%;
  background-color: #191b1c;
  border-radius: 4px;
  z-index: 10;

  img {
    width: 23px;
    margin-left: 26px;
    margin-right: 10px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #2c3b44;
      }
    }
  }
}

.otp-success {
  max-width: 409px !important;
}

.otp-error {
  max-width: 480px !important;
}

.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: 67.5px;
  width: 67.5px;
  padding: 11px;
}

.checkTick {
  padding-top: 100px;
}

.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}

.finisButton-SuccesOtp {
  padding-bottom: 100px;
}

.buttonSuccess {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Noto Sans Thai" !important;
}

input {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d6dde1 !important;
  caret-color: #09BE8B;
  line-height: 24px;
  :disabled {
    background-color: #d6dde1 !important;
    opacity: 0.3 !important;
  }
}

.modal-header {
  padding: 0px !important;
}

.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.custom-input {
  display: flex;
  align-items: center;
  width: 39%;
  /* Adjust the width as needed */
  padding: 5px;
  /* Add padding for styling */
  border: 1px solid #38454d;
  /* Add a border for input field appearance */
  border-radius: 4px;
  /* Add rounded corners for input field appearance */
  background: var(--gray-default-state) !important;
}

.custom-input-danger {
  border-color: red !important;
}

:root {
  --orange-link: #ffa500;
  /* Define your desired color value here */
}

.underline-button {
  text-decoration: underline;
  cursor: pointer;
  color: var(--orange-link) !important;
  /* Change the color to match your design */
  margin-left: auto;
  /* Push the button to the right side */
  margin-right: 5px;
  /* Add some spacing between the input and button */
}

/* Style the button when hovered */
.underline-button:hover {
  text-decoration: none;
}

.mr-101 {
  margin-right: 101px;
}

.font_sarabun {
  font-family: Sarabun !important;
}

.roboto_flex {
  font-family: Roboto Flex !important;
}

.w-400 {
  font-weight: 400 !important;
}

.select .options {
  position: absolute;
}

.options {
  position: absolute;
  top: 41px !important;
  width: 100% !important;
  z-index: 1;
  width: 346px;
  background-color: #2c3b44;
  border-radius: 4px;
}

.options ul {
  margin: 0;
  padding: 0;
}

.options ul li {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.options ul li:hover {
  background-color: #2c3b44;
}

.select svg {
  width: 12px;
  height: 8px;
}

table tr td:nth-child(2) p {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.ModalEditAddressIdCard input::placeholder {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.modal-header {
  padding: 0px !important;
}

.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.bg-box {
  background-color: #1c262b;
}

.page-heading {
  padding: 24px 0px 21px 105px;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: #d6dde1;
}

.box-personal-info {
  padding: 12px 28px 12px 29px;
  border-radius: 3px;
  /* remove width if not responsive */
  width: 359px;
  height: 179px;
  margin-left: 82px;

  .u-n {
    margin: 0;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
    color: white;
  }
}

.text-box {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 68px;
  height: 40px;
}

/* .text-box:hover {
  color: #ffff;
} */
.width-table {
  margin-left: 105px;
  margin-top: 41px;
  max-width: 764px;
}

.table-text {
  color: #9bacb6;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.table-text-white {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.correct-button {
  text-decoration: underline;
  float: right;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}

.border-bottom-end {
  border-bottom: 1px solid #141b1f;
}

.button-camera-open {
  padding-top: 33px;
  // padding-bottom: 80px;
  justify-content: center;
  display: flex;
  /* margin-left: 30px; */
}

.attachment-wrap {
  background-color: #121516;
  /* padding: 48px 150px 48px 182px; */
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 432px;
  height: 120px;
}

.attachment-wrap-skull {
  background-color: #121516;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 432px;
  margin: auto;
  height: 206px;
}

.image-note {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 43px;
  padding-bottom: 20px;
  color: #d6dde1;
}

.note-list {
  color: #9bacb6;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  font-family: "Sarabun" !important;
}

/* modal */
.marginTopBottom32 {
  margin-top: 48px;
  margin-bottom: 32px;
}

.info-icon {
  margin-left: 3px;
}

.text-grey {
  color: #d6dde1;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.text-dark-grey {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0d10;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-mask p {
  line-height: 24px;
}

.pi {
  input::placeholder {
    color: #677f8e;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}
.modal-dialog1 {
  background-color: #141b1f !important;
  max-width: 1000px !important;
  border-radius: 8px;
}
.custom-modal-width .modal-dialog {
  max-width: 350px;
}
.custom-checkbox {
  background: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  height: 17px;
  width: 17px;
}

.Get-started {
  margin: 20px;
}

.btn-cancel {
  background-color: #191b1c;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.pass-modal {
  text-align: center;

  p {
    margin-bottom: 0;
  }

  .title {
    color: #40994f;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-top: 23px;
    margin-bottom: 30px;
  }

  .ph {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #d6dde1;
    margin-top: 15px;
  }

  .psh {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #d6dde1;
  }

  .pb {
    width: 160px;
    height: 36px;
    background: #191b1c;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin: auto;
    border-radius: 4px;
    margin-top: 46px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

.paswordInput-width {
  width: 240px !important;
}

.SurnameWidth {
  width: 240px !important;
}

.SurnameWidthPasword {
  width: 240px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #121516;
}

.oc2 {
  text-align: center;
  margin-top: 17px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  p {
    margin-bottom: 0;
  }
}
.oc1 {
  margin-left: 20px;
  text-align: left;
  margin-top: 17px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  p {
    margin-bottom: 0;
  }
}

.two-ipnuts {
  width: 109px !important;
  height: 40px !important;
}

.two-inputs-labelMargin {
  margin-left: 6px;
  margin-right: 9px;
}

select > option:checked,
select > option:hover {
  color: #09BE8B !important;
}

select {
  border-radius: 4px;
  height: 40px;
  width: 240px !important;
  color: #ffffff;
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("../../../src/assets/images/profile-setting/dropdownChevron.png");
  background-repeat: no-repeat;
  background-position: right 7px top 50%;
}

.modal-padding {
  margin-left: 1rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-device-width: 768px) {
  .table-text {
    font-size: 10px;
  }

  .table-text-white {
    font-size: 10px;
  }

  .table-text-yellow {
    font-size: 10px;
  }

  .box-personal-info {
    width: 290px !important;
  }

  .text-box {
    font-size: 12px !important;
  }
}

.modal-content {
  border-radius: 8px !important;
  margin-top: 10px !important;
  margin-bottom: 100px !important;
  width: 100% !important;
  height: auto !important;
  background-color: #141b1f !important;
  overflow-x: hidden !important;
  // overflow-y: scroll !important;
  border-radius: 8px !important;
}

.d-dropdown {
  background-color: lightgray !important;
}
.form-validate {
  border: 1px solid #ee0a0a !important;
}

input.form-kyc {
  width: 280px;
  padding-left: 10px;
}
</style>
