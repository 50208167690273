<template>
  <div class="wrapper">
    <div class="container ps-5">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h4 class="page-heading pt-4 pb-4">บัญชีธนาคาร</h4>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
      <div class="loader-wrapper">
    <div class="loader"></div>
  </div>
    </div>
      <div v-else>
        <div v-if="MemberBanks.length > 0">
        <div v-for="(bank, index) in MemberBanks" :key="index">
          <div class="lightBlackBox">
            <div class="d-flex m-0">
              <div class="p-0">
                <p class="text-box mb-0">ชื่อบัญชี</p>
                <p class="text-box mb-0 pt-20">ธนาคาร</p>
                <p class="text-box mb-0 pt-20">ชื่อสาขา</p>
                <p class="text-box mb-0 pt-20">เลขที่บัญชี</p>
              </div>
              <div class="p-0 ms-150">
                <p class="table-text-white mb-0">{{ bank.accountName.slice(0,40) }}</p>
                <p class="table-text-white mb-0 pt-20">{{ bank.bankName.slice(0,40) }}</p>
                <p class="table-text-white mb-0 pt-20">
                  {{ bank.bankBranchName.slice(0,40) }}
                </p>
                <p class="table-text-white mb-0 pt-20 roboto_flex">
                  {{ bank.accountNumber.slice(0,40) }}
                </p>
              </div>
              <div
                class="ms-auto"
                v-if="MemberBanks.length > 1 && BankStatus && bank.status == 1"
              >
                <p class="table-text-yellow correct-button float-end">
                  <b-icon
                    @click="DeleteBank(bank.id, bank.status)"
                    class="iconsTrash"
                    icon="trash"
                  ></b-icon>
                </p>
              </div>
              <div class="status-text">
                <span>{{ showStatusText(bank.status) }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="MemberBanks.length > 1 && MemberBanks.length != index + 1"
            class="divider"
          ></div>
        </div>
      </div>

      <div v-if="MemberBanks.length == 0 || MemberBanks.length < 2 && !hide_buttom">
        <div class="row">
          <div class="col max-width text-center">
            <div class="mt-3 max-width text-center">
              <b-button
                @click="$refs.bankAccount.show()"
                class="btn btn-primary"
                size="md"
                :disabled="disableAddBank"
                >+ เพิ่มบัญชีธนาคาร</b-button
              >
            </div>
          </div>
        </div>
      </div>
      </div>
    
    </div>

    <deleteDialog
      ref="deleteDialog"
      @DeleteBankConfirm="handleDeleteBankConfirm"
    />
    <bankAccountDialog ref="bankAccount" @AddBank="handleAddBank" />
    <OtpModalSettings
      :OtpResponse="OtpResponse"
      :OtpType="OtpType"
      @OtpResponseUpdate="handleOtpResponseUpdate"
      :OtpValue="OtpValue"
      :OtpError="OtpError"
      :OtpSuccess="OtpSuccess"
      :refCode="refCode"
      @UpdateRefCode="UpdateRefCode"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { HTTP } from "@/config/axios";
import deleteDialog from "@/components/dialogs/delete.vue";
import bankAccountDialog from "@/components/dialogs/bankAccount.vue";
import OtpModalSettings from "./components/OtpModalSettings.vue";
import EventBus from "@/event-bus";
export default Vue.extend({
  data() {
    return {
      toggle: false,
      user: {
        phone: "",
        email: "",
      },
      OtpType: "",
      OtpValue: "",
      OtpResponse: false,
      refCode: "",
      OtpError: false,
      OtpSuccess: false,
      MemberBanks: [],
      DeleteBankID: 0,
      DeleteStatusBank: 0,
      NewBank: [],
      BankStatus: false,
      disableAddBank: true,
      isLoading:true,
      hide_buttom:false,
    };
  },
  components: {
    deleteDialog,
    bankAccountDialog,
    OtpModalSettings,
  },
  methods: {
    format_accountnumber(value) {
      if (value.length == 10) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 4);
        let c = value.slice(4, 9);
        let d = value.slice(9, 10);
        return a + "-" + b + "-" + c + "-" + d;
      }
      else  if  (value.length == 12) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 5);
        let c = value.slice(5, 11);
        let d = value.slice(11, 12);
        return a + "-" + b + "-" + c + "-" + d;
      } else{
        return value
      }
    },
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    DeleteBank(BankID, StatusBank) {
      this.DeleteStatusBank = StatusBank;
      this.DeleteBankID = BankID;
      this.$refs.deleteDialog.show();
    },
    handleOtpResponseUpdate(Value) {
      if (Value == "BankAdd") {
        this.BankAdd();
      }
      if (Value == "OtpSuccess") {
        if (this.OtpType == "bank") {
          this.BankAddConfirm();
        } else {
          this.OtpResponse = false;
          this.OtpSuccess = true;
        }
      } else if (Value == "OtpError") {
        this.OtpResponse = false;
        this.OtpError = true;
      } else if (Value == "OTP") {
        this.OtpResponse = false;
        this.$refs.bankAccount.show();
      } else if (Value == "OTPSuccess") {
        this.OtpSuccess = false;
      } else if (Value == "OTPError") {
        this.OtpError = false;
      }
    },
    BankAdd() {
      let self = this;
      HTTP.post("/uc/sendOtp")
        .then(function (response) {
          if (response.data.code === 0) {
            self.OtpType = "bank";
            self.OtpValue = "";
            self.$refs.bankAccount.hide();
            self.OtpResponse = true;
            self.refCode = response.data.data;
          }
          self.$notify({
            group: "notification",
            type: response.data.code == "0" ? "success" : "error",
            title: response.data.code == "0" ? "Success" : "Error",
            text: response.data.message,
          });
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Add Bank OTP Unsuccessful",
            text: error,
          });
        });
    },
    ChangePinSuccess() {
      let self = this;
      HTTP.post("/uc/reset-pin", {
        oldPin: this.OldPin,
        pin: this.ConfirmPin,
      })
        .then(function (response) {
          if (response.data.code == "0") {
            self.OtpSuccess = true;
            self.OtpResponse = false;
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Change Pin Unsuccessful",
            text: error,
          });
        });
    },
    getMemberBanks() {
      let self = this;
      HTTP.get("/uc/member/get-bank-request")
        .then(function (response) {
          if (response.data.code === 0) {
            self.disableAddBank = false;
            self.MemberBanks = response.data.data;

            //check status == 1 > 2 array
            const filteredBankStatus = self.MemberBanks.filter(
              (item) => item.status === 1
            );
            if(self.MemberBanks.length == 1){
              if(self.MemberBanks[0].status == 0){
                self.hide_buttom = true;
              }
            }
            if (filteredBankStatus.length > 1) {
              self.BankStatus = true;
            } else {
              self.BankStatus = false;
            }
            self.isLoading = false
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Member Banks Successful"
                  : "Unable to Get Current Member Banks",
              text: response.data.message,
            });
            self.isLoading = false
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Banks",
            text: error,
          });
        });
    },
    handleDeleteBankConfirm() {
      let self = this;
      //case approve delete from whitelist
      if (self.DeleteStatusBank == 1) {
        HTTP.post("/uc/member/delete-bank-whitelist", {
          id: this.DeleteBankID,
        })
          .then(function (response) {
            if (response.data.code === 0) {
              self.getMemberBanks();
              self.$refs.deleteDialog.enableFlagDeleteBank();
              self.$refs.deleteDialog.hide();
              self.DeleteBankID = 0;
              self.DeleteStatusBank = 0;
              //EventBus.$emit('update-bank-account');
            }
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Member Bank Delete Successful"
                  : "Unable to Delete Member Bank",
              text: response.data.message,
            });
          })
          .catch(function (error) {
            self.isLoadingPhone = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Unable to Delete Member Bank",
              text: error,
            });
          });
      }
    },
    BankAddConfirm() {
      if (this.MemberBanks.length < 2) {
        let self = this;
        HTTP.post("/uc/member/create-bank-request", {
          accountName: this.NewBank.AccountName,
          bankId: this.NewBank.SelectedBankID,
          branchName: this.NewBank.BranchName,
          accountNumber: this.NewBank.AccountNumber,
        })
          .then(function (response) {
            if (response.data.code === 0) {
              self.getMemberBanks();
              self.OtpResponse = false;
              self.$refs.bankAccount.showSuccessModal();
            }
            self.$notify({
              group: "notification",
              type: response.data.code === 0 ? "success" : "error",
              title:
                response.data.code === 0
                  ? "Member Bank Added Successful"
                  : "Unable to Add Member Bank",
              text: response.data.message,
            });
          })
          .catch(function (error) {
            self.isLoadingPhone = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Unable to Add Member Bank",
              text: error,
            });
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Max Member Bank",
          text: error,
        });
      }
    },
    handleAddBank(BankData) {
      this.NewBank = BankData;
      this.BankAdd();
    },
    showStatusText(status) {
      let statusText = "";
      if (status == 0) {
        statusText = "รออนุมัติ";
      } else if (status == 1) {
        statusText = "อนุมัติ";
      } else if (status == 2) {
        statusText = "ถูกปฎิเสธ";
      }
      return statusText;
    },
  },
  created() {
    this.getMemberBanks();
  },
});
</script>

<style scoped>
.lightBlackBox .roboto_flex {
  font-family: "Roboto Flex" !important;
}
.pt-20 {
  padding-top: 20px;
}
.ms-150 {
  margin-left: 150px;
}
.max-width {
  max-width: 764px;
  font-family: "Sarabun" !important;
}
.page-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: #d6dde1;
}
.primaryBtn {
  background: #09BE8B;
  border: none;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0a0d10;
  width: 143px;
  height: 40px;
}
.divider {
  background-color: #28363e;
  height: 2px;
  display: block;
  max-width: 684px;
  margin: 24px 24px;
  text-align: center;
}
.lightBlackBox {
  background: #121516;
  border-radius: 4px;
  height: 200px;
  padding: 24px 40px 20px 34px;
  max-width: 764px;
  position: relative;
}
.iconsTrash {
  font-size: 16px;
}
.bg-box {
  background-color: #191b1c;
}
p {
  margin-bottom: 0;
}
.Heading {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.Heading h4 {
  margin: 0px !important;
}
.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.status-text {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  position: absolute;
  bottom: 20px;
  right: 40px;
}
.text-box {
  color: #9bacb6;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.table-text-white {
  color: #fff;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
</style>
