<template>
  <div>
    <!-- <figure class="highcharts-figure">
      <div id="container"></div>
      <p class="highcharts-description">
       
      </p>
    </figure> -->
    <highcharts :options="chartOptions1"></highcharts>
  </div>
</template>

<script>
// import Highcharts from 'highcharts';
// import Treemap from 'highcharts/modules/treemap';
// import HighchartsVue from 'highcharts-vue';

// // Initialize the Treemap module
// Treemap(Highcharts);
export default {
  // components: {
  //   highcharts: HighchartsVue.component,
  // },
  name: "CryptoHeatmap",
  props: ["dataheatmap"],
  data() {
    return {
      chartOptions: {
        credits: {
          enabled: false, // Remove the Highcharts watermark
        },
        exporting: {
          enabled: false, // Remove the hamburger button
        },
        chart: {
          type: "treemap",
          renderTo: "container",
        },
        // title: {

        //   text: 'Norwegian regions and counties by area',
        //   align: 'left'
        // },
        // subtitle: {
        //   text: 'Source: <a href="https://snl.no/Norge" target="_blank">SNL</a>',
        //   align: 'left'
        // },
        series: [
          {
            type: "treemap",
            layoutAlgorithm: "stripes",
            alternateStartingDirection: true,
            borderColor: "#fff",
            borderRadius: 6,
            borderWidth: 2,
            dataLabels: {
              style: {
                textOutline: false,
              },
            },
            levels: [
              {
                level: 1,
                layoutAlgorithm: "squarified",
                // layoutAlgorithm: "sliceAndDice",
                dataLabels: {
                  enabled: true,
                  align: "left",
                  verticalAlign: "top",
                  style: {
                    fontSize: "16px",
                    fontWeight: "bold",
                  },
                },
              },
            ],
            data: [],
          },
        ],
        tooltip: {
          useHTML: true,
          pointFormat:
            "The area of <b>{point.name}</b> is <b>{point.value} km<sup>2</sup></b>",
        },
      },
      chartOptions1: {
        chart: {
          type: "treemap",
          backgroundColor: null, // Remove the white background
        },
        title: {
          text: null, // Remove the title
        },
        credits: {
          enabled: false, // Remove the Highcharts watermark
        },
        exporting: {
          enabled: false, // Remove the hamburger button
        },
        tooltip: {
          
          useHTML: true,
          formatter: function () {
            const value = this.point.realValue
              ? this.point.realValue.toFixed(2)
              : "0";
            const change = this.point.change
              ? this.point.change.toFixed(2)
              : "0";
            const sign = this.point.change < 0 ? "-" : "";

            return `
              <div class="draggable-tooltip">
                Coin: <span>${this.point.name} / THB</span><br/>
                Unrealized : <span>${change}฿</span><br/>
                Unrealized Percent : <span>${sign}${value}% </span>
              </div>
            `;
          },
        },
        // tooltip: {
        //   useHTML: true,
        //   formatter: function () {
        //     const value = this.point.realValue.toFixed(2);
        //     const change = this.point.change.toFixed(2);
        //     const sign = this.point.change < 0 ? "-" : "";

        //     return `
        //     Coin: <span>${this.point.name} / THB</span><br/>
        //     Unrealized : <span>${change}฿</span><br/>
        //     Unrealized Percent : <span>${sign}${value}% </span>
        // `;
        //   },
        //   backgroundColor: "#000000",
        //   style: {
        //     color: "#FFFFFF",
        //     padding: "10px",
        //   },
        //   positioner: function (labelWidth, labelHeight, point) {
        //     const tooltipX = point.plotX + this.chart.plotLeft;
        //     const tooltipY = point.plotY + this.chart.plotTop - labelHeight;

        //     // Adjust positioning to ensure tooltip remains within plot area
        //     const plotWidth = this.chart.plotWidth;
        //     const plotHeight = this.chart.plotHeight;

        //     return {
        //       x: Math.max(0, Math.min(tooltipX, plotWidth - labelWidth)),
        //       y: Math.max(0, Math.min(tooltipY, plotHeight - labelHeight)),
        //     };
        //   },
        // },
        series: [
          {
            colorByPoint: true,
            color: function () {
              return this.color;
            },
            type: "treemap",
            layoutAlgorithm: "squarified", // Change layout algorithm to sliceAndDice
            data: [
              // Add data points here
            ],
            borderColor: "#141b1f", // Color of the border (will be removed)
            borderWidth: 1, // Set border width to 0 to remove border
            shadow: false, // Remove shadow from the treemap
            dataLabels: {
              enabled: true,
              useHTML: true,
              formatter: function () {
                // Check if this.point.shapeArgs is defined
                const shapeArgs = this.point.shapeArgs || {};
                const width = shapeArgs.width || 100; // default width
                const height = shapeArgs.height || 100; // default height

                // Check if realValue is undefined
                if (
                  this.point.realValue === undefined ||
                  this.point.realValue === null
                ) {
                  return null; // Hide the data label
                }

                const size = Math.min(width, height) * 0.05;
                const value = this.point.realValue.toFixed(2);
                const change = this.point.change
                  ? this.point.change.toFixed(2)
                  : "0";
                const sign = this.point.change < 0 ? "-" : "";

                const name = `<span style="font-size: ${
                  size * 2
                }px; font-family: 'Roboto Flex', sans-serif; font-weight: normal;">${
                  this.point.name || "0"
                }</span><br/><br/>`;
                const price = `<span style="font-size: ${
                  size * 2.5
                }px; font-family: 'Roboto Flex', sans-serif; font-weight: 600;">${change}</span><br/>`;
                const changeFormatted = `<span style="font-size: ${
                  size * 1.5
                }px; font-family: 'Roboto Flex', sans-serif; font-weight: normal;">(${sign}${value})% </span>`;

                return `${name}${price}${changeFormatted}`;
              },
              style: {
                color: "#FFFFFF",
                textOutline: false,
                fontFamily: "Roboto Flex, sans-serif",
              },
              align: "left",
              verticalAlign: "middle",
              x: 10,
            },
            levels: [
              {
                level: 1,
                layoutAlgorithm: "sliceAndDice",
              },
            ],
            minSize: 20,
          },
        ],
      },
      chartData: [
        { id: "A", name: "Large Cap", color: "#50FFB1" },
        { id: "B", name: "Mid Cap", color: "#F5FBEF" },
        { id: "C", name: "Small Cap", color: "#A09FA8" },
      ],
      categories: {
        A: "Large Cap",
        B: "Mid Cap",
        C: "Small Cap",
      },
      thresholds: {
        A: 0, // Above 70%
        B: -10, // Between 30% and 70%
        C: -50, // Below 30%
      },
    };
  },
  mounted() {
    if (this.dataheatmap.length > 0) {
      for (let i = 0; i < this.dataheatmap.length; i++) {
        if (this.dataheatmap[i].unrealized < 0) {
          this.chartOptions1.series[0].data.push({
            name: this.dataheatmap[i].coin.name,
            value: Math.max(
              0.5,
              Math.log(this.dataheatmap[i].unRealizedPercent)
            ),
            realValue: this.dataheatmap[i].unRealizedPercent,
            change: this.dataheatmap[i].unrealized,
            color: "#d13c4f",
          });
        }
        if (this.dataheatmap[i].unrealized > 0) {
          this.chartOptions1.series[0].data.push({
            name: this.dataheatmap[i].coin.name,
            value: Math.max(
              0.5,
              Math.log(this.dataheatmap[i].unRealizedPercent)
            ),
            realValue: this.dataheatmap[i].unRealizedPercent,
            change: this.dataheatmap[i].unrealized,
            color: "#3ed59a",
          });
        }
        // if (this.dataheatmap[i].unrealized == 0) {
        //   this.chartOptions1.series[0].data.push({
        //     name: this.dataheatmap[i].coin.name,
        //     value: Math.max(
        //       0.5,
        //       Math.log(this.dataheatmap[i].unRealizedPercent)
        //     ),
        //     realValue: this.dataheatmap[i].unRealizedPercent,
        //     change: this.dataheatmap[i].unrealized,
        //     color: "#76808e",
        //   });
        // }
      }
    }
    // console.log(JSON.stringify(this.chartData))
    // console.log(JSON.stringify(this.chartOptions1.series[0].data, null, 2));
    var organizedData = [];

    for (let i = 0; i < this.chartOptions1.series[0].data.length; i++) {
      const item = this.chartOptions1.series[0].data[i];
      let categoryId = "C"; // ค่าเริ่มต้น
      if (item.value >= this.thresholds["A"]) {
        categoryId = "A";
      } else if (item.value >= this.thresholds["B"]) {
        categoryId = "B";
      }
      organizedData.push({
        name: item.name,
        parent: categoryId,
        value: item.value,
        realValue: item.realValue,
        change: item.change,
        color: item.color,
      });
    }

    const finalData = this.chartData.concat(organizedData);
    // console.log(finalData);
    this.chartOptions1.series[0].data = finalData;
  },
};
</script>

<style scoped>
.draggable-tooltip {
  cursor: move;
}
.highcharts-tooltip {
  padding: 10px;
  z-index: 1000; /* Ensure tooltip is above other elements */
}

.remove-font-weight {
  font-weight: normal;
}
.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 600px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
</style>
