<template>
  <div>
    <div v-if="isLoading">
      <div class="loader-wrapper">
    <div class="loader"></div>
  </div>
    </div>
    <div v-else class="container-fluid">
      <div >
        <P class="textGrey-heading marginTop32 ms-pt marginBottom16">{{ $t('announcement') }}</P>
        <!-- cards -->
        <VueSlickCarousel class="carousel-cards" :arrows="true" :dots="false" :infinite="true" :initialSlide="2"
          :speed="500" :slidesToShow="3" :slidesToScroll="1" :swipeToSlide="true">
          <div >
            <div class="card shadow backgroundColor">
              <div class="card-body " style="height: 100px;">
                <div class="d-flex justify-content-between cardt">
                  <img src="../market/01.png" alt="Girl in a jacket" width="100%" height="200px">
                </div>
              </div>
            </div>
          </div>
          <div >
            <div class="card shadow backgroundColor">
              <div class="card-body" >
                <div class="d-flex justify-content-between cardt" >
                  <img src="../market/02.png" alt="Girl in a jacket" width="100%" height="200px">
                </div>
              </div>
            </div>
          </div>
          <div >
            <div class="card shadow backgroundColor">
              <div class="card-body" >
                <div class="d-flex justify-content-between cardt">
                  <img src="../market/03.png" alt="Girl in a jacket" width="100%" height="200px">
                </div>
              </div>
            </div>
          </div>
          <template #prevArrow="arrowOption">
            <!-- custom prev-button -->
            <div class="custom-arrow">
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </div>
          </template>
          <template #nextArrow="arrowOption">
            <!-- custom next-button -->
            <div class="custom-arrow">
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </div>
          </template>
        </VueSlickCarousel>
      </div>
      <div>
        <P class="textGrey-heading marginTop32 marginBottom16 ms-pt"
          >{{ $t('allcoin') }}</P
        >
        <Dynamic-Table
          :TableHeader="TableHeading"
          :favCoinRows="favCoinRows"
          :TableData="filteredCoinMarket()"
          :Showfavorite="true"
          :PChartLabels="chart_labels"
          :PSeries="chart_series"
          current_page="allCoin"
        ></Dynamic-Table>
      </div>
      <!-- cards end  -->
    </div>

    <!-- import in main.js -->
    <ReviewKYCModel />
    <PasswordAlertModal />
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Table from "@/components/table/Table.vue";
import { HTTP } from "@/config/axios";
import EventBus from "../../event-bus";
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
export default {
  name: "all-coins",
  components: {
    "Dynamic-Table": Table,
    VueSlickCarousel,
  },
  data() {
    return {
      isLoading: true,
      chart_labels: [],
      chart_series: [],
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price",
          headingHourChange: "24h Change",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
          headingChart: "24h Chart",
        },
      ],
      TableDatas: [],
      isModalVisible: false,
      cards: [
        {
          CoinName: "ETH2.0",
          Mcap: "Mcap 420M",
          perentage: "-4.82%",
          detail:
            "การอัพเกรดครั้งยิ่งใหญ่ของเหรียญที่มีความยิ่งใหญ่อันดับสองของโลก Cryptocurrency ที่จะเปลี่ยนจากการทำงาน PoW เป็น PoS...",
          image1: require("../../assets/images/favoriteTable/bitcoin.png"),
          image2: require("../../assets/images/favoriteTable/BNB.png"),
          image3: require("../../assets/images/favoriteTable/Ethereum.png"),
        },
        {
          CoinName: "BIT3.0",
          Mcap: "Mcap 320M",
          perentage: "-2.82%",
          detail:
            "การอัพเกรดครั้งยิ่งใหญ่ของเหรียญที่มีความยิ่งใหญ่อันดับสองของโลก Cryptocurrency ที่จะเปลี่ยนจากการทำงาน PoW เป็น PoS...",
          image1: require("../../assets/images/favoriteTable/bitcoin.png"),
          image2: require("../../assets/images/favoriteTable/BNB.png"),
          image3: require("../../assets/images/favoriteTable/Ethereum.png"),
        },
        {
          CoinName: "USDT.2",
          Mcap: "Mcap 120M",
          perentage: "-1.82%",
          detail:
            "การอัพเกรดครั้งยิ่งใหญ่ของเหรียญที่มีความยิ่งใหญ่อันดับสองของโลก Cryptocurrency ที่จะเปลี่ยนจากการทำงาน PoW เป็น PoS...",
          image1: require("../../assets/images/favoriteTable/bitcoin.png"),
          image2: require("../../assets/images/favoriteTable/BNB.png"),
          image3: require("../../assets/images/favoriteTable/Ethereum.png"),
        },
        {
          CoinName: "BNB.2",
          Mcap: "Mcap 520M",
          perentage: "-7.82%",
          detail:
            "การอัพเกรดครั้งยิ่งใหญ่ของเหรียญที่มีความยิ่งใหญ่อันดับสองของโลก Cryptocurrency ที่จะเปลี่ยนจากการทำงาน PoW เป็น PoS...",
          image1: require("../../assets/images/favoriteTable/bitcoin.png"),
          image2: require("../../assets/images/favoriteTable/BNB.png"),
          image3: require("../../assets/images/favoriteTable/Ethereum.png"),
        },
        {
          CoinName: "USDC.2",
          Mcap: "Mcap 520M",
          perentage: "-7.82%",
          detail:
            "การอัพเกรดครั้งยิ่งใหญ่ของเหรียญที่มีความยิ่งใหญ่อันดับสองของโลก Cryptocurrency ที่จะเปลี่ยนจากการทำงาน PoW เป็น PoS...",
          image1: require("../../assets/images/favoriteTable/bitcoin.png"),
          image2: require("../../assets/images/favoriteTable/BNB.png"),
          image3: require("../../assets/images/favoriteTable/Ethereum.png"),
        },
      ],
      //
      //
      defaultPath: "btc_usdt",
      showSearchBox: false,
      currentCoin: {
        base: "",
        coin: "",
        symbol: "BTC/USDT",
      },
      plate: {
        maxPostion: 20,
        askRows: [],
        bidRows: [],
      },
      ticker: {
        tickerRows: [],
      },
      priceInfo: {
        last: 0,
        chg: 0,
        high24: 0,
        low24: 0,
        vol24: 0,
        pChg: 0,
        textColor: "",
      },
      coinMarket: [],
      decimal: 2,
      chartSymbolUrl: "/libchart/index.php?symbol=BTC%2FTHB",
      favCoinRows: [],
      isUserLogin: false,
      data_theme: [],
    };
  },
  metaInfo() {
    let pageTitle = " All Coins";
    return {
      title: pageTitle,
      titleTemplate: "%s | " + this.TITLE,
    };
  },
  methods: {
    get_theme() {
      HTTP.post("/market/announcement/get-all-web-theme").then((response) => {
        if (response.data.code == 0) {
          var data = response.data.data;
          data.forEach((element) => {
            if (!Array.isArray(element.img)) {
              element.img = [];
            }
            if(element.details){
              element.details.forEach((detail) => {
              this.coinMarket.forEach((data) => {
                if (detail.symbol === data.Coinsybmol) {
                  element.img.push(data.CoinImage);
                }
              });
            });
            }
           
            var q = {
              CoinName: element.themeName,
              Mcap: `Mcap ${this.$MyFunc.NumberFormatPrice(
                element.marketCapTotal
              )}`,
              perentage: element.chgAvg,
              detail: element.themeDetail,
              img:element.img
              // image1: require("../../assets/images/favoriteTable/bitcoin.png"),
              // image2: require("../../assets/images/favoriteTable/BNB.png"),
              // image3: require("../../assets/images/favoriteTable/Ethereum.png"),
            };
            this.data_theme.push(q);
          });
        }
      });
    },
    defaultSymbol() {
      if (this.$route.params.pair == undefined) {
        this.currentCoin.symbol = "BTC/THB";
        this.$route.params["pair"] = "btc_thb";
      } else {
        let pair = this.$route.params.pair;
        let dataArr = pair.split("_");

        this.currentCoin.symbol = (dataArr[0] + "/" + dataArr[1]).toUpperCase();
        let symbolEncode = encodeURIComponent(this.currentCoin.symbol);
        this.chartSymbolUrl = "/libchart/chart?symbol=" + symbolEncode;
      }
    },
    updateDataTrade() {
      if (this.stompClient) {
        this.stompClient.ws.close();
      }

      var stompClient = null;
      var that = this;
      var symbol = that.currentCoin.symbol;
      var url = this.MARKET_WS_URL + "/market/market-ws";
      // console.log("MARKET_WS_URL", this.MARKET_WS_URL);
      var socket = new SockJS(url);
      stompClient = Stomp.over(socket);
      this.stompClient = stompClient;
      stompClient.debug = false;

      stompClient.connect({}, function (frame) {
        stompClient.subscribe("/topic/market/thumb", function (msg) {
          var resp = JSON.parse(msg.body);
          //   console.log(resp);
          that.updatePriceInfo(resp);
        });

        stompClient.subscribe("/topic/market/trade/" + symbol, function (msg) {
          var resp = JSON.parse(msg.body);
          //   console.log(resp);

          that.updateTickers(resp);
        });

        stompClient.subscribe(
          "/topic/market/trade-plate/" + symbol,
          function (msg) {
            var resp = JSON.parse(msg.body);
            // console.log(resp);

            that.updateBidAsk(resp);
          }
        );
      });
    },
    getBidAsk() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;

      HTTP.post("/market/exchange-plate", params, {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        if (resp.ask) {
          var asks = resp.ask;
          this.plate.askRows = [];
          for (var i = this.plate.maxPostion - 1; i >= 0; i--) {
            var ask = {
              price: asks[i]?.price.toFixed(2),
              amount: asks[i]?.amount.toFixed(6),
              total: (asks[i]?.price * asks[i]?.amount).toFixed(2),
            };
            this.plate.askRows.push(ask);
          }
          if (this.plate.askRows.length > 20) {
            this.plate.askRows = this.plate.askRows.slice(0, 20);
          }
        } else {
          var bids = resp.items;
          this.plate.bidRows = [];
          for (var i = this.plate.maxPostion - 1; i >= 0; i--) {
            var bid = {
              price: bids[i]?.price.toFixed(2),
              amount: bids[i]?.amount.toFixed(6),
              total: (bids[i]?.price * bids[i]?.amount).toFixed(2),
            };
            this.plate.bidRows.push(bid);
          }
          if (this.plate.bidRows.length > 20) {
            this.plate.bidRows = this.plate.bidRows.slice(0, 20);
          }
        }
        // console.log(resp);
      });
    },
    updateBidAsk(resp) {
      // console.log(resp)
      var that = this;
      if (resp.direction == "SELL") {
        var asks = resp.items;
        this.plate.askRows = [];
        for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
          var ask = {
            price: asks[i]?.price.toFixed(2),
            amount: asks[i]?.amount.toFixed(6),
            total: (asks[i]?.price * asks[i]?.amount).toFixed(2),
          };
          that.plate.askRows.push(ask);
        }
        if (that.plate.askRows.length > 20) {
          that.plate.askRows = that.plate.askRows.slice(0, 20);
        }
      } else {
        var bids = resp.items;
        this.plate.bidRows = [];
        for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
          var bid = {
            price: bids[i]?.price.toFixed(2),
            amount: bids[i]?.amount.toFixed(6),
            total: (bids[i]?.price * bids[i]?.amount).toFixed(2),
          };
          that.plate.bidRows.unshift(bid);
        }
        if (that.plate.bidRows.length > 20) {
          that.plate.bidRows = that.plate.bidRows.slice(0, 20);
        }
      }
    },
    getTickers() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/latest-trade", params, {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        if (resp.length > 0) {
          this.priceInfo.last = resp[0]?.price;
          for (var i = 0; i < resp.length; i++) {
            let date = new Date(resp[i].time);
            let hours = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            let second = ("0" + date.getSeconds()).slice(-2);
            let time = hours + ":" + minute + ":" + second;
            resp[i].price = resp[i]?.price.toFixed(2);
            resp[i].amount = resp[i]?.amount.toFixed(6);
            resp[i].time = time;
            this.ticker.tickerRows.push(resp[i]);
          }
        }
        if (this.ticker.tickerRows.length > 20) {
          this.ticker.tickerRows = this.ticker.tickerRows.slice(0, 20);
        }
      });
    },
    updateTickers(resp) {
      // console.log(resp);
      var that = this;
      if (resp.length > 0) {
        for (var i = 0; i < resp.length; i++) {
          let date = new Date(resp[i].time);
          let hours = ("0" + date.getHours()).slice(-2);
          let minute = ("0" + date.getMinutes()).slice(-2);
          let second = ("0" + date.getSeconds()).slice(-2);
          let time = hours + ":" + minute + ":" + second;
          resp[i].price = resp[i]?.price.toFixed(2);
          resp[i].amount = resp[i]?.amount.toFixed(6);
          resp[i].time = time;
          that.priceInfo.last = resp[i]?.price;
          that.ticker.tickerRows.unshift(resp[i]);
        }
      }
      if (that.ticker.tickerRows.length > 20) {
        that.ticker.tickerRows = that.ticker.tickerRows.slice(0, 20);
      }
    },
    async getSymbolThumb() {
      var coininfo = await this.getCoinInfo();
      if (coininfo == false) {
        return false;
      }
      this.isLoading = true;
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/symbol-thumb-trend", {
        emulateJSON: true,
      }).then((response) => {
        let resp = response.data;
        this.coinMarket = [];
        for (let i = 0; i < resp.length; i++) {
          // console.log(resp)
          let symbolArr = resp[i].symbol.split("/");
          let symbol = (symbolArr[0] + "_" + symbolArr[1]).toLowerCase();
          let color = "";
          if (resp[i].chg > 0) {
            color = "green";
          } else if (resp[i].chg < 0) {
            color = "red";
          }
          this.decimal = 2;
          this.coinMarketScale.forEach((item, index) => {
            if (item.coinSymbol == resp[i].coinName) {
              this.decimal = item.baseCoinScale;
            }
          });
          const coinMarket = {
            bathScale: this.decimal,
            Coinsybmol: resp[i].symbol,
            StarIcon: require("../../assets/images/favoriteTable/star.png"),
            CoinImage:
              this.MARKET_WS_URL + `/market/coin/image/${resp[i].logoFileId}`,
            CoinName: resp[i].fullName,
            lastPrice: this.$MyFunc.mathFloorPrice(resp[i].close, this.decimal),
            hourChange: resp[i].chg,
            volume: this.$MyFunc.mathFloorPrice(
              resp[i].marketCapVolume24h,
              this.decimal
            ),
            marketCap: this.$MyFunc.mathFloorPrice(
              resp[i].marketCap,
              this.decimal
            ),
            series: [
              {
                name: "STOCK ABC",
                data: resp[i].trend,
              },
            ],
            // price: resp[i].close,
            // pChg: resp[i].chg,
            // url: "/exchange/" + symbol,
            // color: color,
          };
          this.coinMarket.push(coinMarket);

          if (this.currentCoin.symbol == resp[i].symbol) {
            if (resp[i].chg < 0) {
              this.priceInfo.textColor = "red";
            } else if (resp[i].chg > 0) {
              this.priceInfo.textColor = "green";
            }

            this.priceInfo.chg = resp[i].change;
            this.priceInfo.high24 = resp[i].high;
            this.priceInfo.low24 = resp[i].low;
            this.priceInfo.vol24 = resp[i].volume;
            this.priceInfo.pChg = resp[i].chg;
          }
        }
        this.get_theme();
        this.isLoading = false;
        if (this.isUserLogin) {
          this.getFov();
        } else {
          let fav_local = localStorage.getItem("fav");
          if (fav_local != null || fav_local != undefined) {
            var fav_local_s = fav_local.split(",");
          }
          if (fav_local != "" && fav_local != null) {
            for (let i = 0; i < this.coinMarket.length; i++) {
              for (let j = 0; j < fav_local_s.length; j++) {
                let typeSymbol = this.coinMarket[i].Coinsybmol;
                if (fav_local_s[j] == typeSymbol) {
                  this.$set(this.favCoinRows, typeSymbol, true);
                  break;
                } else {
                  this.$set(this.favCoinRows, typeSymbol, false);
                }
              }
            }
          } else {
            for (let i = 0; i < this.coinMarket.length; i++) {
              let typeSymbol = this.coinMarket[i].Coinsybmol;
              this.$set(this.favCoinRows, typeSymbol, false);
            }
          }
        }
      });
    },
    async getCoinInfo() {
      let self = this;
      await HTTP.post("/market/symbol-info-all")
        .then((res) => {
          self.coinMarketScale = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      return true;
    },
    updatePriceInfo(resp) {
      for (let i = 0; i < this.coinMarket.length; i++) {
        if (this.coinMarket[i].Coinsybmol == resp.symbol) {
          this.coinMarket[i].lastPrice = resp.close;
          this.coinMarket[i].hourChange = resp.chg;
          this.coinMarket[i].volume = resp.marketCapVolume24h;
          this.coinMarket[i].marketCap = resp.marketCap;
          if (resp.chg > 0) {
            this.coinMarket[i].color = "green";
          } else if (resp.chg < 0) {
            this.coinMarket[i].color = "red";
          }

          break;
        }
      }

      if (this.currentCoin.symbol == resp.symbol) {
        if (resp.chg < 0) {
          this.priceInfo.textColor = "red";
        } else if (resp.chg > 0) {
          this.priceInfo.textColor = "green";
        }

        this.priceInfo.chg = resp.change;
        this.priceInfo.high24 = resp.high;
        this.priceInfo.low24 = resp.low;
        this.priceInfo.vol24 = resp.volume;
        this.priceInfo.pChg = resp.chg;
      }
    },
    selectMarketSymbol(url) {
      window.location.href = url;
    },
    getFov() {
      HTTP.post("uc/member/get-member-favorite").then((result) => {
        let memberFavorite = result.data;
        if (memberFavorite.code == 0) {
          if (this.isUserLogin) {
            for (let keyy in this.coinMarket) {
              if (memberFavorite.data.length > 0) {
                for (let key in memberFavorite.data) {
                  let typeSymbol = this.coinMarket[keyy].Coinsybmol;
                  if (memberFavorite.data[key].coinId == typeSymbol) {
                    if (memberFavorite.data[key].favorite) {
                      this.$set(this.favCoinRows, typeSymbol, true);
                      break;
                    } else {
                      this.$set(this.favCoinRows, typeSymbol, false);
                    }
                  } else {
                    this.$set(
                      this.favCoinRows,
                      this.coinMarket[keyy].Coinsybmol,
                      false
                    );
                  }
                }
              } else {
                this.$set(
                  this.favCoinRows,
                  this.coinMarket[keyy].Coinsybmol,
                  false
                );
              }
            }
          }
        } else {
          this.$store.dispatch("logout");
        }
      });
    },
    filteredCoinMarket() {
      let compareWith = this.$store.state.marketCoinSearchField;
      const filteredCoins = this.coinMarket
        .filter(
          (coin) =>
            coin.Coinsybmol.toLowerCase().indexOf(compareWith.toLowerCase()) !==
            -1
        )
        .map((coin) => {
          coin.volume = coin.volume.toString();
          return coin;
        });
      return filteredCoins;
    },
  },
  mounted() {
    this.defaultSymbol();
    this.getSymbolThumb();
    this.getBidAsk();
    this.getTickers();
    this.updateDataTrade();
    /* EventBus.$on("get-Symbol-Thumb-trend-allCoin", () => {
      this.getSymbolThumb();
    }); */
  },
  watch: {
    filteredCoins(value) {},
  },
  computed: {},
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.carousel-cards {
  margin: 0 23px;
}

.marginTop32 {
  margin-top: 32px;
}

.marginBottom32 {
  margin-bottom: 32px;
}

.marginBottom16 {
  margin-bottom: 16px;
}

.marginRight {
  margin-right: 16px !important;
}

.font-sarabun {
  font-family: "Sarabun" !important;
}

.font-roboto {
  font-family: var(--bs-roboto-font-sans-serif) !important;
}

.card {
  border-radius: 8px !important;
  margin-left: 8px;
  margin-right: 8px;
}

.cardt {
  position: relative;
  top: 1px;
}

.card-percentage {
  color: #ff2500;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
}
.card-percentage-green {
  color: #198754;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
}

.textGrey-heading {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: "Roboto Flex";
  font-weight: 600;
  line-height: 24px;
}

.textGreyDark {
  color: #677f8e;
  font-family: "Sarabun";
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
  font-weight: 400;
}

.card-heading {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: rgb(255, 255, 255);
}

.detail-warper {
  position: relative;
  bottom: -8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  color: #677f8e;
  font-family: "Sarabun";
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  line-height: 24px;
}

.backgroundColor {
  background-color: #191b1c;
  //   width: 434px!important;
  // height: 184px!important;
}

.CardimageBitCoin {
  height: auto;
  width: 100%;
  max-width: 20px;
  border-radius: 50%;
  max-height: 20px;
  // margin-bottom: 15px;
}

.CardimageCoin2 {
  height: auto;
  width: 100%;
  max-width: 22px;
  border-radius: 50%;
  max-height: 20px;
  border-left: 3px solid black;
}

.CardimageCoin3 {
  height: auto;
  width: 100%;
  max-width: 22px;
  border-radius: 50%;
  max-height: 20px;
  border-left: 3px solid black;
}

#Cardimg1 {
  position: relative;
  z-index: 10;
}

#Cardimg2 {
  position: relative;
  z-index: 20;
  right: 10%;
}

#Cardimg3 {
  position: relative;
  z-index: 30;
  right: 18%;
}

.prev-button {
  position: relative;
  top: 110px;
  right: 40px;
}

.next-button {
  // position: relative;
  // left: 1125px;
  // bottom: 125px;
  float: right;
  clear: both;
  position: relative;
  top: -120px;
  left: 4%;
}

.slick-prev {
  left: -26px !important;
}

.slick-next {
  // left: -40px!important;
  right: -28px !important;
}

.slick-prev:before {
  // content: "<";
  // color: red;
  // font-size: 30px;
  display: none;
}

.slick-next:before {
  // content: ">";
  // color: red;
  // font-size: 30px;
  display: none;
}

.carousel-control-prev-icon {
  background-image: url(../../../src/assets/images/iconArrow/icon-arrow-left.png);
  width: 100%;
}

.carousel-control-next-icon {
  background-image: url(../../../src/assets/images/iconArrow/icon-arrow-right.png);
  width: 100%;
}

@media (max-width: 848px) {
  .ms-pt {
    margin-left: -1px;
    margin-right: 16px;
  }
}

@media (min-width: 848px) {
  .ms-pt {
    margin-left: -1px;
    margin-right: 24px;
  }
}
</style>
