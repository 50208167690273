<template>
  <div class="fda0112982022-login">
    <section class="fdaLogin">
      <div class="fdaLogin-content">
        <div class="login-c">
          <div class="">
            <div class="">
              <div class="login-wrap p-0">
                <router-link class="logo-wrap" to="/">
                  <b-img
                    :src="require('../../assets/img-fss-logo.png')"
                    fluid
                  ></b-img>
                </router-link>
                <div class="fdaForm">
                  <div>
                    <form @submit.prevent="login">
                      <b-form-group class="mb16">
                        <label class="text-title">Username</label>
                        <b-form-input
                          style="caret-color: #ffffff"
                          class="lh-24"
                          placeholder="Email"
                          type="text"
                          v-model="user.name"
                          required
                        >
                        </b-form-input>
                      </b-form-group>
                      <div style="position: relative">
                        <p>
                          Password
                          <span
                            style="
                              float: right;
                              color: #09BE8B;
                              cursor: pointer;
                              font-family: Sarabun;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                            "
                            class="forgot-pass"
                            @click="openModalForgetPass"
                          >
                            ลืมรหัสผ่าน
                          </span>
                        </p>
                        <input
                          style="
                            width: 344px;
                            height: 40px;
                            background: #191b1c;
                            border-radius: 4px;
                            color: white;
                            padding: 8px 8px;
                            caret-color: white;
                          "
                          placeholder="Password"
                          :type="showPassword ? 'text' : 'password'"
                          v-model="user.password"
                          required
                        />
                        <span
                          @click="showPassword = !showPassword"
                          style="
                            position: absolute;
                            right: 10px;
                            top: 46px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPassword"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>
                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                      <div
                        style="position: relative; margin-top: 16px"
                        v-if="savePin"
                      >
                        <p>PIN</p>
                        <input
                          style="
                            width: 344px;
                            height: 40px;
                            background: #191b1c;
                            border-radius: 4px;
                            color: white;
                            padding: 8px 8px;
                          "
                          :type="showPin ? 'text' : 'password'"
                          v-model="pin"
                          maxlength="6"
                          pattern="\d{6}"
                          @input="onPinInput"
                          required
                        />
                        <span
                          @click="showPin = !showPin"
                          style="
                            position: absolute;
                            right: 10px;
                            top: 46px;
                            cursor: pointer;
                          "
                        >
                          <svg
                            v-if="showPin"
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3_154"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3_154)">
                              <path
                                d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                                fill="#677F8E"
                              />
                            </g>
                          </svg>
                          <svg
                            v-else
                            font-scale="1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                              fill="#677F8E"
                            />
                          </svg>
                        </span>
                      </div>
                      <!-- Check box -->
                      <div class="cover-chk">
                        <input
                          type="checkbox"
                          id="cbx"
                          class="inp-cbx"
                          style="display: none"
                          v-model="savePin"
                        />
                        <label for="cbx" class="cbx">
                          <span class="inp-cbx">
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                          </span>
                          <span>Save PIN</span>
                        </label>
                      </div>
                      <button
                        type="submit"
                        class="font-family_th mt-32 fda_submit_btn-primary lh-24"
                        @click="handler"
                      >
                        เข้าสู่ระบบ
                      </button>
                    </form>
                    <a
                      href="https://openaccount.bitbullthailand.com/register/form/preinfo"
                      target="_black"
                      class="register-btn"
                      >เปิดบัญชี</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OtpModal
        :loginResponse="loginResponse"
        :refCode="refCode"
        :pin="pin"
        :user="user"
        @UpdateRefCode="UpdateRefCode"
      />
      <forgetPasswordModal />
      <annoumentModal />
    </section>
  </div>
</template>

<script>
import { HTTP } from "../../config/axios";
import OtpModal from "./components/OtpModal.vue";
import forgetPasswordModal from "./components/ForgetPasswordModel.vue";
import annoumentModal from "./components/AnnoumentModal.vue";
export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      showPassword: false,
      user: {
        name: "",
        password: "",
        mobile: "",
      },
      formInline: {
        user: "",
        password: "",
      },
      pin: "",
      showPin: false,
      savePin: false,
      showModal: false,
      loginResponse: null,
      refCode: "",
    };
  },
  components: {
    OtpModal,
    forgetPasswordModal,
    annoumentModal
  },
  mounted() {
    
  },
  methods: {
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    onPinInput(event) {
      this.pin = event.target.value.replace(/\D/g, "");
      if (this.pin.length > 6) {
        this.pin = this.pin.slice(0, 6);
      }
    },
    login() {
      if (!this.isLoading) {
        this.isLoading = true;
        let self = this;
        let payload = {
          username: this.user.name,
          password: this.user.password,
          platform: 1, //website
        };
        if (this.savePin) {
          payload.pin = this.pin;
        }
        HTTP.post("/uc/login", payload)
          .then(function (response) {
            if (response.data.code === 0) {
              self.loginResponse = response.data.data;
              localStorage.setItem("auth-token", self.loginResponse.token);
              self.$store.dispatch("setUser", self.loginResponse);
              self.user.mobile = self.loginResponse.mobile;
              if (!self.loginResponse.otpVerified) {
                HTTP.post("/uc/sendOtp")
                  .then((res) => {
                    if (res.data.code === 0) {
                      // self.updateMemberConfigOrderConfirm();
                      self.refCode = res.data.data;
                      self.$bvModal.show("login-otp-modal");
                    } else {
                      self.$notify({
                        group: "notification",
                        type: "error",
                        title: "Login Unsuccessful",
                        text: res.data.message,
                      });
                      return false
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                if (self.loginResponse.resetPasswordFlag) {
                  if (self.$route.path !== "/update-password") {
                    self.$router.push({ path: "/update-password" });
                  }
                } else if (self.loginResponse.resetPin) {
                  if (self.$route.path !== "/update-pin") {
                    self.$router.push({ path: "/update-pin" });
                  } else if (!self.loginResponse.knowledgeTest) {
                    if (self.$route.path !== "/knowledge-test") {
                      self.$router.push({ path: "/knowledge-test" });
                    }
                  }
                  /* } else if (self.loginResponse.requirePdpa) {
                  if (self.$route.path !== "/policy") {
                    self.$router.push({ path: "/policy" });
                  } */
                } else {
                  self.$router.push({ path: "/market/all-coins" });
                }
              }
            } else {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Login Unsuccessful",
                text: response.data.message,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            self.$notify({
              group: "notification",
              type: "error",
              title: "Login Unsuccessful",
              text: error,
            });
          });
      }
    },
    handler() {
      if (!this.isLoading) {
        setTimeout(() => {
          this.isLoading = false;
        }, 5000);
        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          tel = this.formInline.user,
          flagtel = reg.test(tel),
          flagpass = this.formInline.password.length >= 6 ? true : false;
        flagtel && flagpass;
        !flagtel || !flagpass;
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "เข้าสู่ระบบ",
          text: "กรุณารอระบบเข้าใช้กำลังโหลด",
        });
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowPin() {
      this.showPin = !this.showPin;
    },
    openModalForgetPass() {
      this.showModal = true;
      this.$bvModal.show("forgetPasswordModal");
    },
    updateMemberConfigOrderConfirm() {
      let self = this;
      HTTP.post("/uc/member/update-member-config", {
        name: "ORDER_CONFIRM_BEFORE_SUBMIT",
        value: "1",
      })
        .then(function (response) {
          if (response?.data?.code === 0) {
            self.$bvModal.show("login-otp-modal");
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: error,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fda0112982022-login {
  .select-container {
    background-color: #191b1c;
    color:rgb(255, 255, 255);
    border-radius: 4px;
    width: 160px;
    height: 40px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: auto 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    .icon {
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-60%);
    }
    .rotate-sc-icon {
      transform: rotateX(180deg);
    }
    .text {
      padding-left: 15px;
    }
    .options {
      position: absolute;
      bottom: -82px;
      width: 100%;
      background-color: #191b1c;
      border-radius: 4px;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 15px;
          border-radius: 4px;
          &:hover {
            background-color: #2c3b44;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    .show {
      display: block;
    }
  }
  .login-c {
    margin: auto;
    width: 346px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  select:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
  .logo-wrap {
    display: grid;
    place-items: center;
    width: 344px;
  }

  @media (max-width: 768px) {
    .fdaLogin-content {
      padding: 240px 0 !important;
    }
  }
  .fdaLogin-content {
    padding: 88px 0;
  }

  .fdaLogin-container {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .fdaForm {
    width: 344px;
    margin-top: 32px;
    .text-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--primary);
    }
    .mb16 {
      margin-bottom: 13px;
    }

    .login-psw {
      position: relative;
      .fdaPass_close {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 47.45%;
        bottom: 0;
        margin: auto 0;
      }
      .fdaPass_close svg {
        width: 20px;
        height: 20px;
        color: var(--sub-icon) !important;
      }
      .button-icon {
        cursor: pointer;
      }
    }

    .fr-right {
      font-family: "sarabun";
      float: right;
      .forgot-pass {
        position: relative;
        top: 0;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-family: "Sarabun";
        font-size: 14px;
        line-height: 24px;
        color: var(--orange-accent) !important;
        text-decoration: none;
      }
    }
    .fda_submit_btn-primary {
      width: 344px;
      height: 36px;
      font-family: "sarabun";
      display: block;
      margin: auto;
      background-color: #09BE8B;
      border-radius: 4px;
      color: #0a0d10;
      font-weight: 500;
      &:hover {
        background-color: #66cdb0;
      }
    }
    .register-btn {
      display: block;
      width: 344px;
      height: 36px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      background-color: #38454d;
      border-radius: 4px;
      margin-top: 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white !important;
      &:hover {
        background-color: #4a5257;
      }
    }
    .mt-32 {
      margin-top: 30px;
    }

    .p-t-32 {
      padding-top: 32px;
    }
    .mlr-8 {
      margin: 0 8px;
    }

    .textLink-ftLogin {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      font-family: "Sarabun";
      line-height: 24px;
      text-decoration: none;
    }
    .text_sub-ftLogin {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--sub-1) !important;
    }
  }

  .cover-chk {
    margin-left: 0px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  .inp-cbx {
    background-color: rgba(34, 43, 47, 1) !important;
    border-color: rgba(60, 81, 93, 1) !important;
  }
  .cbx span {
    padding-top: 2px;
    font-weight: 400;
    font-size: 14px;
    font-family: "Roboto Flex";
    line-height: 24px;
    padding-left: 10px !important;
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    border-radius: 2px !important;
  }
  .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 0px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #191b1c;
    transition: all 0.2s ease;
  }
  .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #0a0d10;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #191b1c;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 2px;
  }
  .cbx span:last-child {
    padding-left: 3px;
  }
  .cbx:hover span:first-child {
    border-color: #191b1c;
  }

  .inp-cbx:checked + .cbx span:first-child {
    background: #09BE8B !important;
    border-color: #09BE8B !important;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke: black !important;
    stroke-dashoffset: 0;
  }
  .inp-cbx:checked + .cbx span:first-child:before {
    opacity: 0;
  }

  .modal-psw {
    .modal {
      z-index: 1;
      background-color: #141b1f;
      width: 100%;
      height: 100%;
    }

    .modal-content {
      background-color: rgba(20, 27, 31, 1) !important;
      margin-top: 88px;
      max-width: 482px !important;
      height: 401px;
      .modal-header {
        border-bottom: none !important;
        justify-content: center;
        height: 75px;

        .modal-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: var(--primary);
          text-align: center;
        }
      }
      .register {
        display: flex;
        justify-content: center;
        padding: 8px !important;
        width: 560px !important ;
        height: auto !important;
      }
      .modal-body {
        height: 324px;
        padding: 16px 68px 32px 68px;
        .fdaForm-psw {
          padding: 0;
          .pad-16 {
            .text-title {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: var(--primary);
            }
            .text-title1 {
              margin-top: 12px;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: var(--primary);
            }
            .font-sarabun {
              font-family: "Sarabun" !important;
            }
            .place-hold {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
      .modal-footer {
        border-top: none !important;
        display: none;
      }
    }
    //  button
    .rm-title {
      font-family: "Noto Sans Thai";
      font-weight: 500;
      font-size: 24px !important;
    }
    .cover-button-register {
      justify-content: center;
      display: flex;
      margin: 24px 0 0 0 !important;
      padding: 24px;
      padding-bottom: 0;
      border-top: 1px solid #28363e !important;
    }
    .cover-button {
      margin-top: 48px;
      text-align: center;

      .buttons-C {
        background-color: var(--orange-accent);
        border-color: var(--orange-accent);
        color: var(--Black) !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        width: 120px;
        height: 40px;
        border-radius: 4px !important;
        margin: 0 8px;
      }
      .buttons-D {
        background-color: var(--gray-active-state);
        border-color: var(--gray-active-state);
        color: var(--white) !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        width: 120px;
        height: 40px;
        border-radius: 4px !important;
        margin: 0 8px;
      }
      .buttons-Cf {
        background-color: var(--orange-accent);
        border-color: var(--orange-accent);
        color: var(--Black) !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        width: 120px;
        height: 36px;
        border-radius: 4px !important;
        margin: 0 6px;
      }
      .buttons-Df {
        background-color: var(--gray-active-state);
        border-color: var(--gray-active-state);
        color: var(--white) !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        width: 120px;
        height: 36px;
        border-radius: 4px !important;
        margin: 0 6px;
      }
    }
    .contact {
      margin-top: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--sub-2);
      padding-bottom: 20px;
    }
    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 30px;
    }
  }
  .font-sarabun {
    font-family: "Sarabun" !important;
  }
  .p-8 {
    padding: 8px !important;
  }
  .borderBottom-in-modal {
    border-bottom: 1px solid #28363e;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-content {
    width: 100% !important;
    background-color: #141b1f;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-dialog {
    background-color: #141b1f !important;
    max-width: 424px;
    border-radius: 8px;
    height: 406px;
  }
  .otp-input {
    width: 44px !important;
    height: 56px !important;
    border-radius: 4px !important;
    margin-left: 16px !important;
    text-align: center;
    caret-color: #09BE8B !important;
    font-size: 16px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .otp-success {
    max-width: 409px !important;
  }
  .otp-error {
    max-width: 409px !important;
  }
  .image-tick-modal {
    background-color: #277c44;
    border-radius: 50%;
    height: auto;
    width: 100%;
    max-width: 16%;
    padding: 11px;
  }
  .image-cross-modal {
    background-color: linear-gradient(360deg, #de2d40 0%, #ee5d6d 100%);
    border-radius: 50%;
    height: auto;
    width: 100%;
    max-width: 16%;
    padding: 11px;
  }

  .finisButton-SuccesOtp {
    padding-bottom: 100px;
  }

  .wraper-input {
    position: relative;
    width: 256px;
    height: 40px;
    background: #191b1c;
    border-radius: 4px;
    span {
      position: absolute;
      right: 17px;
      top: 9px;
    }
    select {
      width: 100%;
      height: 100%;
      color:rgb(255, 255, 255);
      padding: 0 15px;
    }
  }
  ::-webkit-input-placeholder {
    color: #566a76;
  }
  .tf-heading {
    position: relative;
    margin-top: 17px;
    margin-bottom: 22px;
    h2 {
      font-size: 18px;
      font-weight: 600;
      color:rgb(255, 255, 255);
      text-align: center;
      font-family: "Noto Sans Thai";
      margin-top: -10px;
    }
    span {
      position: absolute;
      right: 5px;
      top: -16px;
      top: 0;
    }
  }
  .buttonConfirm {
    width: 344px;
    height: 40px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
