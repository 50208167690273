<template>
  <div>
    <NavDrawer></NavDrawer>
    <header class="container-fluid site-main-header pmd pt-3">
      <div class="row px-0">
        <div class="col-md-12 col-lg-1 my-auto main-header-logo">
          <span class="lmg">
            <b-link :to="{ path: '/' }">
              <b-img
                :src="require('../../assets/images/logo-mh/logo.png')"
                fluid
                alt="BITBULL Project"
              >
              </b-img>
            </b-link>
          </span>
        </div>
        <div class="col-md-10 col-lg-8 px-0 my-auto Navs1">
          <div class="main-NavMenu">
            <ul style="line-height: 19px !important">
              <li class="listmenu">
                <a :href="url.homePage" target="_blank">
                  <div :class="$route.name == 'Home' ? 'active' : ''">
                    <button class="dropbtn">{{ $t("header.home") }}</button>
                  </div>
                </a>
              </li>
              <li class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    :to="{ path: '/market/favorite' }"
                    class="hovername"
                    :class="
                      $route.name == 'MarketFavorite' ||
                      $route.name == 'MarketAllCoin' ||
                      $route.name == 'MarketCategories' ||
                      $route.name == 'CoinDetail' ||
                      $route.name == 'MarketThemes' ||
                      $route.name == 'MarketHeatmap' ||
                      $route.name == 'MarketCategoryCoinList' ||
                      $route.name == 'Gainloss' ||
                      $route.name == 'marketupdate' ||
                      $route.name == 'news' ||
                      $route.name == 'MarketThemesCoinList'
                        ? 'active'
                        : ''
                    "
                  >
                    {{ $t("header.market") }}
                  </b-link>
                </div>
              </li>
              <li v-if="!$store.state.hide_menu" class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    :to="{ path: '/scan' }"
                    class="hovername"
                    :class="$route.name == 'ScannerList' ? 'active' : ''"
                    >{{ $t("header.scan") }}</b-link
                  >
                </div>
              </li>
              <li class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    @click="checktopage('exchange')"
                    class="hovername"
                    :class="
                      $route.name == 'exchange'
                        ? 'active'
                        : '' || $route.name == 'exchangePair'
                        ? 'active'
                        : ''
                    "
                  >
                    {{ $t("header.buysell") }}
                  </b-link>
                </div>
              </li>
              <li class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    @click="checktopage('chart')"
                    class="hovername"
                    :class="{ active: $route.path.startsWith('/chart') }"
                  >
                    {{ $t("header.chart") }}</b-link
                  >
                </div>
              </li>
              <li class="listmenu">
                <div
                  class="dropdown dropdown-togglemenu"
                  :class="
                    $route.name == 'MyPortfolio' ||
                    $route.name == 'MyCoins' ||
                    $route.name == 'CoinHeatmap' ||
                    $route.name == 'AutoMode' ||
                    $route.name == 'DWReport' ||
                    $route.name == 'ByCoin' ||
                    $route.name == 'ByDate' ||
                    $route.name == 'ByDetail'
                      ? 'active'
                      : ''
                  "
                >
                  <button @click="mrkt" class="dropbtn">
                    {{ $t("header.portfolio")
                    }}<span
                      ><svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.175 0.158447L5 3.97511L8.825 0.158447L10 1.33345L5 6.33345L0 1.33345L1.175 0.158447Z"
                          fill="#677F8E"
                        />
                      </svg>
                    </span>
                  </button>
                  <div class="dropdown-content" style="min-width: 185px">
                    <b-link
                      :to="{ path: '/uc/my-portfolio' }"
                      class="fontdropdownLeft"
                    >
                      {{ $t("header.myportfolio") }}</b-link
                    >
                    <b-link
                      v-if="!$store.state.hide_menu"
                      :to="{ path: '/uc/auto-mode' }"
                      class="fontdropdownmiddle"
                    >
                      {{ $t("header.automode") }}</b-link
                    >
                    <b-link
                      :to="{ path: '/uc/dw-report' }"
                      class="fontdropdownmiddle"
                    >
                      {{ $t("header.depositwithdrawreport") }}</b-link
                    >
                    <b-link
                      :to="{ path: '/uc/history' }"
                      class="fontdropdownmiddle"
                    >
                      {{ $t("header.history") }}</b-link
                    >
                  </div>
                </div>
              </li>
              <li class="listmenu">
                <div
                  class="dropdown dropdown-togglemenu m-item"
                  :class="
                    $route.name == 'DepositeCash' ||
                    $route.name == 'DepositeCoin' ||
                    $route.name == 'WithdrawCash' ||
                    $route.name == 'WithdrawCoin'
                      ? 'active'
                      : ''
                  "
                >
                  <button class="dropbtn">
                    {{ $t("header.depositwithdraw") }}
                    <span
                      ><svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.175 0.158447L5 3.97511L8.825 0.158447L10 1.33345L5 6.33345L0 1.33345L1.175 0.158447Z"
                          fill="#677F8E"
                        />
                      </svg>
                    </span>
                  </button>
                  <div class="dropdown-content">
                    <b-link
                      :to="{ path: '/deposit/cash' }"
                      class="fontdropdownLeft"
                    >
                      {{ $t("header.deposit") }}</b-link
                    >
                    <b-link
                      :to="{ path: '/withdraw/cash' }"
                      class="fontdropdownRight"
                    >
                      {{ $t("header.withdraw") }}</b-link
                    >
                  </div>
                </div>
              </li>
              <li class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    :to="{ path: '/download' }"
                    class="hovername"
                    :class="$route.name == 'Download' ? 'active' : ''"
                  >
                    {{ $t("header.download") }}</b-link
                  >
                </div>
              </li>
              <!-- Hide Menu Copper Balance -->
              <li v-if="false" class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    :to="{ path: '/hd-wallet' }"
                    class="hovername"
                    :class="$route.name == 'HDWallet' ? 'active' : ''"
                  >
                    {{ $t("header.opperbalance") }}</b-link
                  >
                </div>
              </li>
              <!-- Hide Menu APIs -->
              <li v-if="false" class="listmenu">
                <div class="dropbtn border-dp">
                  <b-link
                    :to="{ path: '/test-api' }"
                    class="hovername"
                    :class="$route.name == 'TestAPIs' ? 'active' : ''"
                  >
                    {{ $t("header.apis") }}</b-link
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-md-2 col-lg-3 icon-navbar_right my-auto"
          v-if="$route.name != 'Login'"
        >
          <div>
            <!-- <p>{{ $t('message.hello') }}</p>
    <p>{{ $t('message.welcome') }}</p> -->

            <!-- ปุ่มเปลี่ยนภาษา -->
          </div>

          <ul class="header-dots" v-if="islogin">
            <button 
      @click="changeLanguage" 
      :class="{'th-button': currentLanguage === 'TH', 'en-button': currentLanguage === 'EN'}"
      style="color: white;">
      {{ currentLanguage === 'EN' ? 'TH' : 'EN' }}
    </button>
            <li>
              <span class="btn__dropdownIcon">
                <b-link class="count-indicator" :to="{ path: '/notification' }">
                  <span class="icon icon-shape bg-Icon_bell iconR-16">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 16V11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.89 22 12 22Z"
                        fill="#677F8E"
                      />
                    </svg>
                    <span
                      class="count count-varient2"
                      v-if="this.notificationCount > 0"
                      >{{ this.notificationCount }}</span
                    >
                  </span>
                </b-link>
              </span>
            </li>
            <li>
              <b-link :to="{ path: '/ProfileSetting' }">
                <span class="btn__dropdownIcon">
                  <div class="iconcover">
                    <div class="dropdown-icon">
                      <img
                        v-if="userImage"
                        style="width: 35px; border-radius: 100%"
                        :src="userImage"
                        alt="user-image"
                      />
                      <svg
                        v-else
                        width="24"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3_255)">
                          <circle cx="12" cy="12" r="9.75" fill="#677F8E" />
                          <path
                            d="M12.0002 0.000183105C5.37615 0.000183105 0.000152588 5.37618 0.000152588 12.0002C0.000152588 18.6242 5.37615 24.0002 12.0002 24.0002C18.6242 24.0002 24.0002 18.6242 24.0002 12.0002C24.0002 5.37618 18.6242 0.000183105 12.0002 0.000183105ZM12.0002 3.60018C13.9922 3.60018 15.6002 5.20818 15.6002 7.20018C15.6002 9.19218 13.9922 10.8002 12.0002 10.8002C10.0082 10.8002 8.40015 9.19218 8.40015 7.20018C8.40015 5.20818 10.0082 3.60018 12.0002 3.60018ZM12.0002 20.6402C9.00015 20.6402 6.34815 19.1042 4.80015 16.7762C4.83615 14.3882 9.60015 13.0802 12.0002 13.0802C14.3882 13.0802 19.1642 14.3882 19.2002 16.7762C17.6522 19.1042 15.0002 20.6402 12.0002 20.6402Z"
                            fill="#191b1c"
                          />
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3_255">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </span>
              </b-link>
            </li>
          </ul>
          <div
            v-if="!islogin"
            style="position: relative; top: -3px; text-align: right"
          >
          <button 
      @click="changeLanguage" 
      :class="{'th-button': currentLanguage === 'TH', 'en-button': currentLanguage === 'EN'}"
      style="color: white;">
      {{ currentLanguage === 'EN' ? 'TH' : 'EN' }}
    </button>&nbsp;&nbsp;&nbsp;&nbsp;
            <!-- <button 
      @click="changeLanguage('th')" 
      :class="{ active: currentLanguage === 'th' }"
      style="color: white;">
      ไทย
    </button> -->
            <b-link :to="{ path: '/login' }">
              <button class="theme-btn">Login</button>
            </b-link>
            &nbsp;
            <b-link @click="openGoogle">
              <button class="theme-btn">Open Account</button>
            </b-link>
            <!-- <a :href="format_openaccount()" target="_blank">
              <button class="open-accountbtn">Open Account</button>
            </a> -->
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { HTTP } from "../../config/axios";
import NavDrawer from "./NavDrawer.vue";
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
export default {
  components: { NavDrawer },
  data() {
    return {
      memberId: 0,
      stompClient: null,
      islogin: false,
      userImage: "",
      user: {
        name: "",
        username: "",
        email: "",
      },
     currentLanguage: localStorage.getItem('language') || 'EN',
      notificationCount: 0,
      reslockStatusDepositCash: false,
      reslockStatusDepositCoin: false,
      reslockStatusWithdrawCash: false,
      reslockStatusWithdrawCoin: false,
      toPathDeposit: "",
      toPathWithdraw: "",
      url: [],
      urlRegex: /^(ftp|http|https):\/\/[^ "]+$/,
    };
  },
  created() {
    let url = localStorage.getItem("web-url");
    let getURL = "";
    if (url == null || url == "") {
      this.getUrl();
    } else {
      getURL = JSON.parse(url);
      let check_url = this.isURL(getURL.homePage);
      if (check_url == false) {
        this.getUrl();
        return false;
      }
      this.url = getURL;
    }
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.islogin = false;
    } else {
      this.islogin = true;
      this.memberId = user_data.id;
      this.userImage = user_data.avatar;

      let storedData = JSON.parse(localStorage.getItem("resMember") || "{}");
      this.reslockStatusDepositCash =
        storedData?.reslockStatus?.cannotDepositCash;
      this.reslockStatusDepositCoin =
        storedData?.reslockStatus?.cannotDepositCoin;
      this.reslockStatusWithdrawCash =
        storedData?.reslockStatus?.cannotWithdrawCash;
      this.reslockStatusWithdrawCoin =
        storedData?.reslockStatus?.cannotWithdrawCoin;
      this.getNotificationCount();
      this.updatedDataSocket();
    }
  },
  mounted() {},
  watch: {
    $route(to, from) {
      if (to.name == "Notification") {
        this.notificationCount = 0;
      }
    },
  },
  methods: {
    changeLanguage() {
      // สลับภาษา
      this.currentLanguage = this.currentLanguage === "EN" ? "TH" : "EN";
      this.$i18n.locale = this.currentLanguage; // อัปเดต locale
      localStorage.setItem('language', this.currentLanguage);
    },
    openGoogle() {
      window.open(
        "https://openaccount.bitbullthailand.com/register/form/preinfo",
        "_blank"
      );
    },
    format_openaccount() {
      var url = "";
      if (this.MARKET_WS_URL == "https://webtrade.bitbullthailand.com") {
        url = "https://openaccount.bitbullthailand.com";
      } else if (this.MARKET_WS_URL == "https://uatservice.finansiada.com") {
        url = "https://openaccount.bitbullthailand.com";
      } else {
        url = "https://openaccount.bitbullthailand.com";
      }
      return url;
    },
    isURL(text) {
      return this.urlRegex.test(text);
    },
    mrkt() {},
    getNotificationCount() {
      if (this.islogin) {
        HTTP.get("/uc/member/get-all-member-notification-alert")
          .then((res) => {
            if (res.data.data) {
              this.notificationCount = res.data.data.count;

              setTimeout(() => {
                HTTP.get("/uc/member/get-member-notification")
                  .then((res) => {
                    if (res.data.data) {
                      console.log("NOTI CALL=", res.data.data);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }, 15000);
            } else {
              this.$store.dispatch("logout");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checktopage(page) {
      if (this.$route.name.toLowerCase() != page) {
        window.location.href = `/${page}`;
      }
    },
    updatedDataSocket() {
      if (this.stompClient) {
        this.stompClient.ws.close();
      }
      let self = this;
      let url = this.MARKET_WS_URL + "/market/market-ws";
      this.stompClient = Stomp.over(new SockJS(url));
      this.stompClient.debug = false;
      this.stompClient.connect({}, () => {
        this.stompClient.subscribe(
          "/topic/market/member-notification/" + self.memberId,
          function (msg) {
            let notiCount = JSON.parse(msg.body);
            if (notiCount) {
              self.notificationCount = notiCount.count;
            }
          }
        );
      });
    },
    getUrl() {
      localStorage.removeItem("web-url");
      let self = this;
      HTTP.get("/market/pageSite/get-page")
        .then(function (res) {
          if (res.data.data) {
            localStorage.setItem("web-url", JSON.stringify(res.data.data));
            self.url = res.data.data;
            // this.url.homePage =res.data.data.homePage;
          }
        })
        .catch(function (error) {
          // self.$notify({
          //   group: "notification",
          //   type: "error",
          //   text: error,
          //   title: "Unable to get list of URLs",
          // });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.listmenu > div {
  min-width: max-content !important;
}

.listmenu .active {
  border-bottom: 2px solid #09be8b;
}

.site-main-header {
  background-color: #000000;
  padding: 19px 27px 14px 27px;
  height: 64px;

  // ================================= Middle menu
  .main-NavMenu {
    .dropbtn {
      background-color: #000000;
      color: rgb(255, 255, 255);
      font-size: 20px;
      border: none;

      text-align: left !important;
      position: relative;
      padding: 0px 0px 17px 0px;
    }

    .dropdown {
      position: relative;
      display: inline-block;
      padding-left: 0px;
      // padding-bottom: 8px !important;
      //padding-right: 13px;
    }

    .dropdown-togglemenu {
      padding-right: 15px;

      &:hover {
        border-bottom: 2px solid #09be8b;
      }

      button {
        position: relative;

        span {
          position: absolute;
          right: -16px;
        }
      }
    }

    .dropdown-content {
      margin-top: 2px;
      display: none;
      min-width: 145px;
      position: absolute;
      border-radius: 4px;
      background-color: #191b1c;
      z-index: 60;
    }

    .dropdown-content a {
      color: black;
      padding: 8px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content a:hover {
      background-color: #38454d !important;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    .dropdown:hover .dropbtn {
      background-color: #000000;
      // border-bottom: 2px solid #09BE8B;
    }

    .dropdown .dropbtn.active {
      background-color: #000000;
      border-bottom: 2px solid #09be8b;
    }

    a {
      text-decoration: none;
    }

    ul {
      display: flex;
      // gap: 32px;
      list-style: none;
      margin: 0;
      padding-left: 0;
      justify-content: center;
    }

    li {
      transition-duration: 0.5s;
      height: 25px;
      // border: 1px solid red;
    }

    li a {
      color: rgb(255, 255, 255) !important;
    }

    li:hover {
      cursor: pointer;
    }

    .hovername {
      font-weight: 400;
      font-size: 16px;
      line-height: 18.75px;
    }

    .hovername:hover {
      padding-bottom: 19px;
      // border-bottom: 2px solid #09BE8B;
    }

    .hovername.active {
      padding-bottom: 19px;
      // border-bottom: 2px solid #09BE8B;
    }

    ul li ul {
      background: #38454d;
      display: none;
    }

    ul li:hover > ul,
    ul li ul:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }

    ul li ul li {
      clear: both;
      width: 100%;
    }
  }

  /* CSS */
  .button-38 {
    border: 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
    color: #111827;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
    text-decoration: none #d1d5db solid;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-38:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .button-38:focus-visible {
    box-shadow: none;
  }

  // ================================================== hover submenu
  .fontdropdownLeft {
    color: rgb(255, 255, 255) !important;
    border-top-left-radius: 4px 4px 0px 0px !important;
  }

  .fontdropdownRight {
    color: rgb(255, 255, 255) !important;
    border-top-left-radius: 0px 0px 4px 4px !important;
  }

  .fontdropdownmiddle {
    color: rgb(255, 255, 255) !important;
    border-radius: 0px !important;
  }

  .fontdropdownLeft:hover {
    color: #9bacb6 !important;
    background-color: #38454d;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .fontdropdownRight:hover {
    color: #9bacb6 !important;
    background-color: #38454d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .fontdropdownmiddle:hover {
    color: #9bacb6 !important;
    background-color: #38454d;
    border-radius: 0px;
  }

  // ================================================== hover submenu

  // ================================================== notification
  .dropbtn-icon {
    background-color: #000000;
    border: none;
    text-align: left !important;
    position: relative;
    padding: 20px 10px 18px 10px !important;
  }

  .dropdown-icon {
    position: relative;
    display: inline-block;
    // height: 70px;
  }

  .dropdown-content-icon {
    display: none;
    position: absolute;
    background-color: #191b1c;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    width: 183px;
    min-height: 196px;
    right: 0px;
    margin-right: -10px;
    border-radius: 4px;
    // top: 60px;
  }

  .dropdown-content-icon a {
    color: black;
    text-decoration: none;
    display: block;
  }

  // .dropdown-content-icon a:hover {
  //   background-color: #000000;
  // }

  .dropdown-icon:hover .dropdown-content-icon {
    display: block;
  }

  .dropdown-icon:hover .dropbtn-icon {
    background-color: #000000;
  }

  ul.Nameiofo {
    padding: 8px 8px;
  }

  li.accountnum {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9bacb6;
  }

  .g12 {
    padding-bottom: 8px;
  }

  ul li {
    text-decoration: none;
  }

  ul.Nameiofo li.name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgb(255, 255, 255);
  }

  ul.Nameiofo li.accountnum {
    width: 167px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  ul.coverp8 {
    padding: 0px !important;
    margin-left: 8px !important;
  }

  li.name a.p8 {
    padding: 8px !important;
  }

  // .icon-navbar_right .header-dots .btn__dropdownIcon a {
  //   color: rgb(255, 255, 255) !important;
  // }
  .line-icon {
    border-bottom: 1px solid #28363e;
    width: 167px;
    position: absolute;
    right: 8px;
  }

  .coverp0 {
    padding: 0;
  }

  .dropbtn-icon {
    border-bottom-width: 17px !important;
    border-top-width: 17px !important;
    font-size: none !important;
  }

  // ================================================== End notification

  // <!-- ******** BUTTON Login AND Add   Open Account ******** -->
  .icon-navbar_right {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // padding: 0px;
    // gap: 16px;
    /* Style Login */
    .FDA-BTnLogin {
      background-color: var(--gray-default-state);
      border-radius: 4px;
      border-style: none;
      color: #fff;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      outline: none;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 4px;
      width: 68px;
      height: 32px;
      position: relative;
      text-align: center;
      transform: translate3d(0, 0, 0);
      transition: all 0.3s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: top;
      white-space: nowrap;
    }

    .FDA-BTnLogin:hover {
      background-color: var(--gray-default-state);
      opacity: 1;
      transform: translateY(0);
      transition-duration: 0.35s;
    }

    .FDA-BTnLogin:active {
      transform: translateY(2px);
      transition-duration: 0.35s;
    }

    /* Style Open Acc */
    .FDA-BTnOpenAcc {
      background-color: var(--orange-accent);
      border-radius: 4px;
      border-style: none;
      color: #fff;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      outline: none;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 16px;
      gap: 4px;
      width: 122px;
      height: 32px;
      position: relative;
      text-align: center;
      transform: translate3d(0, 0, 0);
      transition: all 0.3s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: top;
      white-space: nowrap;
    }

    .FDA-BTnOpenAcc:hover {
      background-color: var(--orange-AccentHover);
      opacity: 1;
      transform: translateY(0);
      transition-duration: 0.35s;
    }

    .FDA-BTnOpenAcc:active {
      transform: translateY(2px);
      transition-duration: 0.35s;
    }

    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus,
    .btn:active:focus,
    .btn.active:focus,
    .btn.show:focus {
      box-shadow: none !important;
    }

    .btn-check:focus + .btn,
    .btn:focus {
      box-shadow: none !important;
    }
  }

  // <!-- ******** BUTTON Login AND Add   Open Account ******** -->

  // <!-- ******** BUTTON Add Favorite AND Add Price Alert ******** -->
  .icon-navbar_right {
    .header-dots {
      display: flex;
      flex-direction: row;
      // justify-content: flex-end;
      align-items: center;
      gap: 16px;

      li {
        display: inline-block;
      }

      .btn__dropdownIcon {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--gray-default-state);
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        .icon .IconBell {
          width: 16.41px;
          height: 20px;
        }

        .count-indicator {
          position: relative;
          border-radius: 100%;
          text-align: center;
        }

        .count-varient2 {
          background: #de2d40;
        }

        .count {
          border-radius: 100%;
          top: -4px;
          color: #ffffff;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2px 6px;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 21px;
        }

        a {
          color: var(--sub-icon) !important;
        }

        a:hover {
          color: var(--sub-icon) !important;
        }
      }

      .open-account {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .open-account button {
        // stroke-opacity: 1;
        fill: rgba(0, 220, 0, 0.5);
        // fill-opacity: 1;
        stroke: rgb(0, 220, 0);
        transform: translate(0, 3.766);
        background-color: #09be8b;
        color: black;
        padding: 6px 16px;
        border-radius: 4px;
        border-style: none;
      }

      .login-button button {
        background-color: #191b1c;
        color: #fff;
        padding: 6px 16px;
        border-radius: 4px;
        border-style: none;
      }
    }
  }

  // <!-- ******** BUTTON Add Favorite AND Add Price Alert ******** -->
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media (max-width: 848px) {
  .site-main-header {
    display: none;
  }

  .site-main-header .main-NavMenu ul {
    margin-left: -70px;
  }
}

@media (max-width: 790px) {
  .icon-navbar_right {
    .header-dots {
      gap: 3px !important;
    }
  }
}

@media (max-width: 990px) {
  .main-header-logo {
    justify-content: center !important;
    // padding-bottom: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media (min-width: 768px) {
  .main-NavMenu {
    ul {
      li {
        margin: 0 4px;
      }
    }
  }

  .icon-navbar_right {
    .header-dots {
      justify-content: end;
      // margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .pmd {
    padding-bottom: 140px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main-NavMenu {
    margin-top: 10px;
  }

  .Nav1 {
    order: 3;
  }

  .icon-navbar_right {
    order: 2;
  }

  .pmd {
    // padding-bottom: 90px;
  }
}

@media (min-width: 992px) {
  .pmd {
    padding-bottom: 0;
  }

  .site-main-header .main-NavMenu .dropbtn {
    padding: 0px 0px 0px 0px;
  }
}
button {
  padding: 8px 11px;
  border: none;
  border-radius: 50%; /* ทำให้ปุ่มกลม */
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.th-button {
  background-color: red; /* สีพื้นหลังสำหรับไทย */
}

.en-button {
  background-color: blue; /* สีพื้นหลังสำหรับอังกฤษ */
}

button:hover {
  opacity: 0.8; /* ลดความทึบเมื่อวางเมาส์ */
}
@media (min-width: 1024px) {
  .site-main-header .main-NavMenu ul {
    margin-left: 150px;
  }

  .site-main-header .main-NavMenu .dropbtn {
    padding: 0px 0px 19px 0px;
  }

  .pmd {
    padding-bottom: 0;
  }

  .main-NavMenu {
    ul {
      li {
        margin: 0 15.5px;
      }
    }
  }

  .icon-navbar_right {
    .header-dots {
      justify-content: end;
      margin: 0;
    }
  }
}

@media (max-width: 1280px) {
  .main-NavMenu {
    ul {
      li {
        margin: 0 8px;
      }
    }
  }
}

@media (min-width: 1366px) {
  .main-NavMenu {
    ul {
      li {
        //margin: 0 25px;
      }
    }
  }
}

.main-header-logo {
  display: flex;
  justify-content: left;
  // padding-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  .lmg {
    height: 28px;
    width: 112;
    // margin-left: -2px;
  }
}

.loginbtn {
  width: 68px;
  height: 32px;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: white;
  background: #191b1c;
  border-radius: 4px;
}

.open-accountbtn {
  font-size: 16px;
  color: #ffffff;
  padding: 12px 28px;
  transition: all 0.5s;
  text-transform: uppercase;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: #09be8b;
  box-shadow: 0 3px 24px rgb(0 0 0 / 12%);
  z-index: 1;
}
.open-accountbtn:hover::before {
  content: "";
  height: 300px;
  width: 300px;
  background: #00081d;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}
.open-accountbtn:hover::before {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.theme-btn {
  font-size: 16px;
  color: #ffffff;
  padding: 8px 17px;
  transition: all 0.5s;
  text-transform: uppercase;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: #09be8b;
  box-shadow: 0 3px 24px rgb(0 0 0 / 12%);
  z-index: 1;
}

.theme-btn::before {
  overflow: hidden;
  content: "";
  height: 300px; /* Adjust to match button height */
  width: 300px;
  background: #00081d;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}

.theme-btn:hover {
  color: #fff;
}

.theme-btn:hover::before {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.border-dp:hover {
  border-bottom: 2px solid #09be8b !important;
}
</style>
