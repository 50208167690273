<template>
  <div>
    <div v-if="isLoading">
      <div class="loader-wrapper">
    <div class="loader"></div>
  </div>
    </div>
    <div class="cash-main-account-container">
      <!-- top section start -->
      <div class="dcc-head">
        <div class="col-12">
          <router-link to="/withdraw/cash">
            <button class="active">Withdraw Cash</button>
          </router-link>
          <router-link to="/withdraw/coin">
            <button class="not-active">Withdraw Coin</button>
          </router-link>
        </div>
      </div>
      <!-- top section end -->
      <div class="row center-section">
        <div class="col-12">
          <p
            class="heading thai-font"
            style="line-height: 24px; color: #d6dde1"
          >
            กรุณาเลือกบัญชีหลักที่ท่านต้องการรับเงิน
          </p>
          <div
            class="d-flex"
            style="margin-bottom: 16px"
            v-for="(bank, index) in bankData"
            :key="index"
          >
            <input type="radio" v-model="selectedBank" :value="bank" />
            <div
              class="d-info"
              :class="selectedBank == bank ? 'b-active' : ''"
              @click="selectedBank = bank"
            >
              <span>บัญชีธนาคารที่รับเงิน</span>
              <div class="bankList">
                <p>{{ bank.accountName }}</p>
                <p>{{ bank.bankname }}</p>
                <p>{{ bank.bankaccno }}</p>
                <p v-if="selectedBank == bank">บัญชีหลัก</p>
                <p v-else></p>
              </div>
            </div>
          </div>
          <ul class="iner-list thai-font">
            <li style="position: relative; left: 5px">
              <span style="position: relative; left: -5px"
                >เมื่อมีการถอนเงินบริษัทจะโอนเงินเข้าบัญชีหลักที่ท่านเลือก</span
              >
            </li>
          </ul>
          <div class="bottom-section thai-font">
            <div class="last-btn">
              <button
                @click="updateMainBank();"
                class="thai-font"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="right-info">
        <p style="color: #677f8e">คู่มือแนะนำการทำรายการฝาก</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ QR Code</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-Bank-Transfer.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ Bank Transfer</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการยกเลิกฝาก แบบ QR Code</a
            >
          </li>
        </ul>
      </div> -->
    </div>
    <AccountModel
      :bank="selectedBank"
      :realName="realName"
    />
      <!-- @saveAccount="saveAccount" -->
    <RightInfo />
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import OtpModel from "@/components/Modal/WIthdrawCashOtpModel.vue";
import RightInfo from "../../../components/deposit-withdraw/RightInfo.vue";
import AccountModel from "@/components/Modal/AccountModel.vue";
export default {
  components: {
    OtpModel,
    RightInfo,
    AccountModel,
  },
  data() {
    return {
      radio1: false,
      radio2: false,
      bankData: [],
      realName: "",
      selectedBank: [],
      isLoading:false,
      bl: [],
    };
  },
  methods: {
    /* saveAccount(d) {
      this.updateMainBank();
    }, */
    format_accountnumber(value) {
      if (value.length == 10) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 4);
        let c = value.slice(4, 9);
        let d = value.slice(9, 10);
        return a + "-" + b + "-" + c + "-" + d;
      }
      else  if  (value.length == 12) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 5);
        let c = value.slice(5, 11);
        let d = value.slice(11, 12);
        return a + "-" + b + "-" + c + "-" + d;
      } else{
        return value
      }
    },
    updateMainBank(){
      if(this.selectedBank.mainbank){
        this.$router.push("/withdraw/cash");
      }else{
        this.isLoading = true
        HTTP.post("/uc/withdraw/updatemainbank", {
          bankaccount: this.selectedBank.bankaccno,
        })
        .then((res) => {
          if(res.data.code == 0){
            this.isLoading = false
            this.$bvModal.show('main-verify-modal');
            // this.isLoading = false
            // this.getBankAccountList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
    getBankAccountList(){
      HTTP.post("/uc/withdraw/bankaccountlist")
        .then((res) => {
          this.bl = res.data.data;
          this.realName = res.data.data.realName;
          this.bankData = res.data.data.userInfo;
          this.bankData.sort((a, b) => {
            if (a.mainbank === b.mainbank) {
              return a.whitelistBankRequestId - b.whitelistBankRequestId;
            }
            return a.mainbank ? -1 : 1;
          });
          let bankSelected = this.bankData.find((x) => x.mainbank == true);
          if (bankSelected?.bankaccno) {
            this.selectedBank = bankSelected;
          } else {
            this.selectedBank = this.bankData[0];
          }
          localStorage.setItem("mb-info", JSON.stringify(this.selectedBank));
          localStorage.setItem("bl", JSON.stringify(this.bl));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {
    this.selectedBank = JSON.parse(localStorage.getItem("mb-info") || "{}");
    this.getBankAccountList();
  },
};
</script>
<style lang="scss">
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 43, 47, 0.7); /* สีดำที่โปร่งแสง 70% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* สร้าง loader ที่หมุน */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4); 
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cash-main-account-container {
  border-radius: 8px;
  min-height: 436px !important;
  position: relative;
  .dcc-head {
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;

      padding: 12.5px 0;

      margin-left: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;
      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }
  .center-section {
    .select-container {
      background-color: #191b1c;
      color: #d6dde1;
      border-radius: 4px;
      height: 40px;
      width: 375px;
      margin: auto 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .icon {
        pointer-events: none;
        position: absolute;
        right: 20px;
      }
      .rotate-sc-icon {
        transform: rotateX(180deg);
      }
      .text {
        padding-left: 15px;
        span {
          position: absolute;
          right: 45px;
        }
        span.red {
          color: #ff2500;
        }
        span.yellow {
          color: #f8c417;
        }
      }
      .options {
        position: absolute;
        top: 45px;
        width: 100%;
        background-color: #191b1c;
        border-radius: 4px;
        ul {
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 15px;
            border-radius: 4px;
            &:hover {
              background-color: #2c3b44;
            }
            span.t-red {
              color: #ff2500;
              position: absolute;
              right: 20px;
            }
            span.t-yellow {
              color: #f8c417;
              position: absolute;
              right: 20px;
            }
          }
        }
      }
      .hidden {
        display: none;
      }
      .show {
        display: block;
      }
    }
    .input-container {
      position: relative;
      input {
        background-color: #191b1c;
        color: #d6dde1;
        border-radius: 4px;
        height: 40px;
        padding: 0 40px 0 8px;
        text-transform: capitalize;
        &::placeholder {
          color: #566a76;
        }
      }
      span {
        color: #9bacb6;
        margin-left: -30px;
      }
      .coin-image {
        position: absolute;
        left: 50px;
        top: 7.5px;
        pointer-events: none;
        img {
          width: 23px;
        }
      }
    }
    .info {
      color: #ff2500;
      p {
        margin: 0;
      }
    }
  }

  .bottom-section {
    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
      margin-top: -0.1px;
    }
    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }
    span {
      margin-left: 10px;
    }
    .last-btn {
      text-align: center;
      margin-top: 12px;
      button {
        color: #0a0d10;
        background-color: #09BE8B;
        border-radius: 4px;
        height: 36px;
        width: 152px;
        &:hover {
          background-color: #66cdb0;
        }
      }
    }
  }
}
.right-info {
  position: absolute;
  top: 3px;
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
  ul {
    list-style: disc;
    position: relative;
    left: -8px;
    font-weight: 400;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
@media (max-width: 640px) {
  .cash-main-account-container {
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
    .center-section {
      padding: 0;
    }
    .input-container {
      input {
        width: 190px;
      }
    }
  }
}
@media (min-width: 640px) {
  .cash-main-account-container {
    width: 616px;
    min-height: 330px;
    padding-bottom: 0;
    .center-section {
      padding: 0 29px 25px 24px;
    }

    .input-container {
      input {
        width: 240px;
      }
    }
  }
}

@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}
@media (min-width: 1024px) {
  .right-info {
    display: block;
    // right: -14px;
    // margin-right: -255px;
    top: 0;
  }
  .cash-main-account-container {
    transform: translateX(-50%);
  }
}
@media (max-width: 848px) {
  .cash-main-account-container {
    background-color: none;
    // margin-left: 40px;
    width: 100%;
    .dcc-head {
      background-color: #121516;
    }
    .bottom-section {
      margin: 0 40px;
    }
    .bcb {
      margin: 0 20px;
    }
    .center-section {
      margin: 0;
    }
  }
}
@media (min-width: 848px) {
  .cash-main-account-container {
    background-color: #121516;
    margin-left: 50%;
    margin-top: 32px;
    margin-bottom: 32px;
    transform: translateX(-75.7%);
    .dcc-head {
      border-bottom: 1px solid #28363e;
    }

    .bcb {
      margin: 0 -4px;
    }
  }
}
</style>
<style scoped>
.bankList p {
  display: block;
  margin: 0;
  padding: 0;
}
.bankList p:last-child {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  color: #09BE8B;
}
.sec {
  margin-top: 16px;
}
.heading {
  margin-top: 32px;
  margin-bottom: 20px;
}
.d-info {
  cursor: pointer;
  font-family: "Sarabun";
  background: #28363e;
  border-radius: 4px;
  height: 90px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  color: #9bacb6;
  padding: 8px 10px 8px;
  font-size: 14px;
  border: 2px solid transparent;
  flex-grow: 1;
  margin-left: 12px;
}
.d-info > div {
  width: 380px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #9bacb6;
  font-weight: 400;
  font-size: 14px;
}
input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 37px;
  right: 1px;
  position: relative;
  background-color: #191b1c;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid #38454d;
}
input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #d6dde1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid #09BE8B;
}
.link {
  width: 60px;
  line-height: 19px;
  color: #09BE8B;
  margin-top: 9px;
}
.iner-list {
  margin: 32px 0px;
  list-style: inherit;
  padding: 0px;
  list-style-position: inside;
  line-height: 19px;
  color: #677f8e;
}
.last-btn button {
  height: 44px !important;
  width: 565px !important;
  line-height: 44px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.b-active {
  border: 2px solid #09BE8B;
}
</style>
