<template>
  <div>
    <b-modal
      class="settings-modal"
      id="settings-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <h2 style="padding-top: 20px" v-if="settingMenu">Setting</h2>
      <h2 style="padding-top: 20px" v-if="chartSetting">Chart Setting</h2>
      <h2 style="padding-top: 20px" v-if="indicatorSetting">
        Indicator Setting
      </h2>
      <h2 style="padding-top: 20px" v-if="strongTrend">
        {{ name_indicators_setting }} Setting
      </h2>
      <h2 style="padding-top: 20px" v-if="trendRibbon">Trend Ribbon Setting</h2>
      <h2 style="padding-top: 20px" v-if="ema">EMA Setting</h2>
      <h2 style="padding-top: 20px" v-if="sma">SMA Setting</h2>
      <h2 style="padding-top: 20px" v-if="volume">Volume Setting</h2>
      <h2 style="padding-top: 20px" v-if="macd">MACD Setting</h2>
      <h2 style="padding-top: 20px" v-if="rsi">RSI Setting</h2>
      <h2 style="padding-top: 20px" v-if="stochasticFast">
        Stochastic Fast Setting
      </h2>
      <h2 style="padding-top: 20px" v-if="stochasticSlow">
        Stochastic Slow Setting
      </h2>
      <h2 style="padding-top: 20px" v-if="adx">ADX Setting</h2>
      <h2 style="padding-top: 20px" v-if="bolingerBrands">
        Bollinger Band Setting
      </h2>
      <h2 style="padding-top: 20px" v-if="ichilmoku">Ichimoku Setting</h2>
      <h2 style="padding-top: 20px" v-if="parabolic">Parabolic SAR Setting</h2>
      <h2 style="padding-top: 20px" v-if="selectIndicator">Indicator</h2>
      <span @click="close_modal()" class="close">
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
            fill="#677F8E"
          />
        </svg>
      </span>
      <template v-if="settingMenu">
        <div
          class="setting-item"
          @click="
            settingMenu = false;
            chartSetting = true;
            getSettings();
          "
        >
          <p>Chart Setting</p>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            settingMenu = false;
            indicatorSetting = true;
            getSettings();
            getIndicator();
          "
        >
          <p>Indicator Setting</p>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
      </template>
      <template v-if="chartSetting">
        <div class="chart-setting">
          <p class="cs-title">Closing Line</p>
          <div class="s-i">
            <div class="row">
              <div class="col-8 text-left">
                <p>Line Color</p>
              </div>
              <div class="col-4 text-right">
                <div class="pt-3">
                  <span class="color-badge" @click="closeColor('line')">
                    <div
                      :class="{ 'white-color': line }"
                      :style="{ backgroundColor: bgColorLine }"
                      class="color-box"
                    ></div>
                    <svg
                      width="7"
                      height="5"
                      viewBox="0 0 7 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                        fill="#677F8E"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8 text-left">
                <p>Line Style</p>
              </div>
              <div class="col-4 text-right">
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select">
                    <img
                      class="pt-0"
                      v-if="selectedText == 'solid'"
                      style="width: 30px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="selectedText == 'dash'"
                      style="width: 30px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="selectedText == 'dot'"
                      style="width: 30px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="selectedText == 'dash-dot'"
                      style="width: 30px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="width: 90px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                      @click="selectOption(option)"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8 text-left">
                <p>Line Width</p>
              </div>
              <div class="col-4 text-right">
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="height: 32px">
                    <img
                      src="../../../assets/svg/linewidth.svg"
                      class="svg-icon"
                      style="width: 15px"
                      alt="Icon"
                    />
                    <span class="custom-select-text pt-0"
                      >{{ selectedValue_width }}px</span
                    >
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="width: 95px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options_width"
                      :key="option.value"
                      @click="selectOption_width(option)"
                    >
                      <img
                        class="pt-0"
                        style="width: 20px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <span
                        class="custom-select-text pt-0"
                        style="margin-left: 0"
                      >
                        {{ option.value }}px</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-setting" style="margin-top: 59px">
          <p class="cs-title">Candlestick</p>
          <div class="s-i">
            <p style="display: inline-block; width: 248px">Up Candle</p>
            <div class="d-inline-flex" style="margin-right: 25px">
              <label>
                <input
                  type="checkbox"
                  v-model="candleStickCheck1"
                  class="form-check-input"
                />
                <span class="w-title" style="margin-left: 10px">Fill</span>
              </label>
            </div>
            <span
              class="color-badge"
              @click="closeColor('upCandle')"
              style="border-radius: 3px"
            >
              <div
                :class="{ 'green-color': upCandle }"
                :style="{ backgroundColor: bgColorUp }"
                class="color-box"
              ></div>

              <svg
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <p style="display: inline-block; width: 248px">Down Candle</p>
            <div class="d-inline-flex" style="margin-right: 25px">
              <label>
                <input
                  type="checkbox"
                  v-model="candleStickCheck2"
                  class="form-check-input"
                />
                <span class="w-title" style="margin-left: 10px">Fill</span>
              </label>
            </div>
            <span class="color-badge" @click="closeColor('downCandle')">
              <div
                :class="{ 'red-color': downCandle }"
                :style="{ backgroundColor: bgColorDown }"
                class="color-box"
              ></div>

              <svg
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="csf">
          <p @click="resetSettings('reset')">Reset to Default</p>
          <div class="csfb">
            <button
              class="b-o"
              @click="
                $bvModal.hide('settings-modal');
                chartSetting = false;
                settingMenu = true;
                updateSettings();
              "
            >
              ยืนยัน
            </button>
            <button
              class="b-g"
              @click="
                chartSetting = false;
                settingMenu = true;
              "
            >
              ยกเลิก
            </button>
          </div>
        </div>
        <!-- reset -->
      </template>
      <template v-if="indicatorSetting">
        <!-- setting menu item -->
        <p style="margin-bottom: 0px; font-size: 15px">Popular</p>
        <div
          v-for="(item, index) in indicators_popular"
          :key="item.title ? item.title + index : item.indicator + index"
          class="setting-item"
          style="padding: 20px"
          @click="
            indicatorSetting = false;
            strongTrend = true;
            name_indicators_setting =
              item.title !== null ? item.title : item.indicator;
            getThemeidicators(item);
          "
        >
          <p>{{ item.indicator }}</p>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <p style="margin-bottom: 0px; margin-top: 20px; font-size: 15px">
          All Indicators
        </p>
        <div
          v-for="(item, index) in indicators"
          :key="index"
          class="setting-item"
          style="padding: 20px"
          @click="
            indicatorSetting = false;
            strongTrend = true;
            name_indicators_setting =
              item.title !== null ? item.title : item.indicator;
            getThemeidicators(item);
          "
        >
          <p>{{ item.title !== null ? item.title : item.indicator }}</p>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <!-- <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            trendRibbon = true;
          "
        >
          <p>Trend Ribbon</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            ema = true;
          "
        >
          <p>EMA</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            sma = true;
          "
        >
          <p>SMA</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            volume = true;
          "
        >
          <p>Volume</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            macd = true;
          "
        >
          <p>MACD</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            rsi = true;
          "
        >
          <p>RSI</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            stochasticFast = true;
          "
        >
          <p>Stochastic Fast</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            stochasticSlow = true;
          "
        >
          <p>Stochastic Slow</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            adx = true;
          "
        >
          <p>ADX</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            bolingerBrands = true;
          "
        >
          <p>Bolinger Bands</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            ichilmoku = true;
          "
        >
          <p>Ichimoku</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="setting-item"
          @click="
            indicatorSetting = false;
            parabolic = true;
          "
        >
          <p>Parabolic SAR Setting</p>
          <span
            ><svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div> -->
        <!-- setting menu item -->
      </template>
      <div class="indicator-items pt-3">
        <!-- <template v-if="selectIndicator">
          <div class="chart-setting">
            <div v-for="(item, index) in getActiveIndicatorDetails" :key="index">
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label>
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">{{ item.name }}</span>
                  </label>
                  <div class="count" style="margin-left: 128px">
                    <input maxlength="2" :value="item.value" type="text" />
                  </div>
                </div>
              </div>
              <div v-if="item.name === 'Line'">
                <div v-for="(item, index) in item.value" :key="index" class="s-i">
                  <p v-if="item.name === 'Line Color' || item.name === `Line ${index} Color`">
                    {{ item.name }}
                    <span class="color-badge" @click="closeColor('line')">
                      <div :class="{ 'white-color': line }" :style="{ backgroundColor: bgColorLine }" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                          fill="#677F8E" />
                      </svg>
                    </span>
                  </p>
                  <p v-if="item.name === 'Line Style' || item.name === `Line ${index} Style`">
                    {{ item.name }}
                    <span>
                      <div class="custom-dropdown-line">
                        <select v-model="lineValue">
                          <option v-for="style in LineStyle" :key="style" :value="style">{{ style }}</option>
                        </select>
                      </div>
                    </span>
                  </p>
                  <p v-if="item.name === 'Line Width' || item.name === `Line ${index} Width`">
                    {{ item.name }}
                    <span>
                      <div class="custom-dropdown">
                        <select v-model="selectedValue">
                          <option value="1px">1px</option>
                          <option value="2px">2px</option>
                          <option value="3px">3px</option>
                          <option value="4px">4px</option>
                          <option value="5px">5px</option>
                        </select>
                      </div>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template> -->
        <template v-if="strongTrend">
          <div
            class="chart-setting"
            v-for="(indicator, index) in data_indicators"
            :key="index"
          >
            <p class="cs-title">{{ name_indicators_setting }}</p>
            <div
              v-if="data_indicators[index].Line_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color1
                <span
                  class="color-badge"
                  @click="opencolorpicker(index,'line1')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor: data_indicators[index].Line_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_Width_value"
                  type="text"
                />
              </div>

              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="data_indicators[index].Line_Style_value == 'solid'"
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="data_indicators[index].Line_Style_value = 'dot'"
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_2_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color2
                <span
                  class="color-badge"
                @click="opencolorpicker(index,'line2')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_2_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_2_Width_value"
                  type="text"
                />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_2_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_2_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_2_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_2_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_2_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_2_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_2_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_2_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_3_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color3
                <span
                  class="color-badge"
               @click="opencolorpicker(index,'line3')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_3_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_3_Width_value"
                  type="text"
                />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_3_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_3_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_3_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_3_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_3_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_3_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_3_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_3_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_4_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color4
                <span
                  class="color-badge"
                   @click="opencolorpicker(index,'line4')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_4_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_4_Width_value"
                  type="text"
                />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_4_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_4_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_4_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_4_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_4_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_4_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_4_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_4_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_5_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color5
                <span
                  class="color-badge"
                 @click="opencolorpicker(index,'line5')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_5_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_5_Width_value"
                  type="text"
                />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_5_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_5_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_5_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_5_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_5_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_5_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_5_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_5_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_6_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color6
                <span
                  class="color-badge"
                   @click="opencolorpicker(index,'line6')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_6_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_6_Width_value"
                  type="text"
                />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_6_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_6_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_6_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_6_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_6_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_6_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_6_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_6_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_7_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color7
                <span
                  class="color-badge"
                  @click="opencolorpicker(index,'line7')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_7_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input
                  maxlength="2"
                  v-model="data_indicators[index].Line_7_Width_value"
                  type="text"
                />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_7_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_7_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_7_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_7_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_7_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_7_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_7_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_7_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Line_8_Color_value != null"
              class="s-i"
              style="position: relative"
            >
              <p>
                Color8
                <span
                  class="color-badge"
                    @click="opencolorpicker(index,'line8')"
                  style="border-radius: 3px"
                >
                  <div
                    :class="{ 'green-color': smap }"
                    :style="{
                      backgroundColor:
                        data_indicators[index].Line_8_Color_value,
                    }"
                    class="color-box"
                  ></div>
                  <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div
                class="count"
                style="position: absolute; top: 16px; left: 49px"
              >
                <input maxlength="2" v-model="Line_8_Width_value" type="text" />
              </div>
              <div
                style="position: absolute; top: 0px; width: 100px; left: 220px"
              >
                <div class="custom-select-wrapper pt-3" @click="toggleDropdown">
                  <div class="custom-select" style="width: 88px">
                    <img
                      class="pt-0"
                      v-if="
                        data_indicators[index].Line_8_Style_value == 'solid'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/solidline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_8_Style_value == 'dash'"
                      style="width: 40px"
                      src="../../../assets/svg/dashline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="data_indicators[index].Line_8_Style_value == 'dot'"
                      style="width: 40px"
                      src="../../../assets/svg/dotline.svg"
                      alt="Icon"
                    />
                    <img
                      v-if="
                        data_indicators[index].Line_8_Style_value == 'dash-dot'
                      "
                      style="width: 40px"
                      src="../../../assets/svg/dashdotline.svg"
                      alt="Icon"
                    />
                    <!-- <span class="custom-select-text pt-0">{{
                      selectedText
                    }}</span> -->
                    <img
                      src="../../../assets/svg/arrow-down.svg"
                      style="right: -7px"
                      role="button"
                    />
                  </div>
                  <div class="custom-options">
                    <div
                      class="custom-option"
                      v-for="option in options"
                      :key="option.value"
                    >
                      <img
                        class="pt-0"
                        v-if="option.text == 'solid'"
                        @click="
                          data_indicators[index].Line_8_Style_value = 'solid'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/solidline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash'"
                        @click="
                          data_indicators[index].Line_8_Style_value = 'dash'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dot'"
                        @click="
                          data_indicators[index].Line_8_Style_value = 'dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dotline.svg"
                        alt="Icon"
                      />
                      <img
                        v-if="option.text == 'dash-dot'"
                        @click="
                          data_indicators[index].Line_8_Style_value = 'dash-dot'
                        "
                        style="width: 30px"
                        src="../../../assets/svg/dashdotline.svg"
                        alt="Icon"
                      />
                      <!-- <span class="custom-select- pt-0">
                        {{ option.text }}</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="data_indicators[index].Periods_value != null"
              class="s-i"
            >
              <p>
                Periods<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Periods_value"
                    type="text"
                /></span>
              </p>
            </div>

            <div
              v-if="data_indicators[index].Base_Banker_value != null"
              class="s-i"
            >
              <p>
                Base Banker<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Base_Banker_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Base_Hot_Money_value != null"
              class="s-i"
            >
              <p>
                Base Hot Money<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Base_Hot_Money_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Long_Cycle_value != null"
              class="s-i"
            >
              <p>
                Long Cycle<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Long_Cycle_value"
                    type="text"
                /></span>
              </p>
            </div>
            <!-- <div v-if="Moving_Average_Type_value != null" class="s-i">
              <p>
                Moving Average Type <span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input   v-model="Moving_Average_Type_value"
                    maxlength="2"
                    value="20"
                    type="text"
                /></span>
              </p>
            </div> -->
            <div
              v-if="data_indicators[index].Periods_Base_Banker_value != null"
              class="s-i"
            >
              <p>
                Periods Base Banker<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Periods_Base_Banker_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Periods_Base_Hot_Money_value != null"
              class="s-i"
            >
              <p>
                Periods Base Hot Money<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="
                      data_indicators[index].Periods_Base_Hot_Money_value
                    "
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Sensitivity_Banker_value != null"
              class="s-i"
            >
              <p>
                Sensitivity Banker<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Sensitivity_Banker_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Sensitivity_Hot_Money_value != null"
              class="s-i"
            >
              <p>
                Sensitivity Hot Money<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Sensitivity_Hot_Money_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Short_Cycle_value != null"
              class="s-i"
            >
              <p>
                Short Cycle<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Short_Cycle_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Signal_Periods_value != null"
              class="s-i"
            >
              <p>
                Signal Periods<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Signal_Periods_value"
                    type="text"
                /></span>
              </p>
            </div>

            <div v-if="data_indicators[index].Source_value != null" class="s-i">
              <p>
                Source<span
                  class=""
                  style="float: right; margin-left: 0; display: inline-flex"
                >
                  <select
                    v-model="data_indicators[index].Source_value"
                    class="option_source"
                  >
                    <option value=".close">Close Price</option>
                    <option value=".open">Open Price</option>
                    <option value=".high">High Price</option>
                    <option value=".low">Low Price</option>
                    <option value=".volume">Volume</option>
                  </select>
                  <!-- <div
                    class="select-container-settings"
                    tabindex="0"
                    @click="open_dropdown_close = !open_dropdown_close"
                    @blur="open_dropdown_close = false"
                    style="float: right; margin-top: 0px; width: 150px"
                  >
                    <span class="text">{{ Source_value }}</span>
                    <span
                      class="icon"
                      :class="open_dropdown_close ? 'rotate-sc-icon' : ''"
                    >
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                          fill="#677F8E"
                        />
                      </svg>
                    </span>
                    <div
                      class="options"
                      :class="open_dropdown_close ? 'show' : 'hidden'"
                      style="top: 27px !important; height: 200px"
                    >
                      <ul>
                        <li @click="Source_value = '.close'">Close Price</li>
                        <li @click="Source_value = '.open'">Open Price</li>
                        <li @click="Source_value = '.high'">High Price</li>
                        <li @click="Source_value = '.low'">Low Price</li>
                        <li @click="Source_value = '.volume'">Volume</li>
                      </ul>
                    </div>
                  </div> -->
                </span>
              </p>
            </div>

            <!-- <div v-if="Source_value != null" class="stf">
              <p>
                Source
                <span class="pt-2" style="float: right">
                  <div
                    class="select-container-settings"
                    tabindex="0"
                    @click="open_dropdown_close = !open_dropdown_close"
                    @blur="open_dropdown_close = false"
                    style="float: right; margin-top: 0px; width: 150px"
                  >
                    <span class="text">{{ Source_value }}</span>
                    <span
                      class="icon"
                      :class="open_dropdown_close ? 'rotate-sc-icon' : ''"
                    >
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                          fill="#677F8E"
                        />
                      </svg>
                    </span>
                    <div
                      class="options"
                      :class="open_dropdown_close ? 'show' : 'hidden'"
                      style="top: 27px !important; height: 200px"
                    >
                      <ul>
                        <li @click="Source_value = '.close'">Close Price</li>
                        <li @click="Source_value = '.open'">Open Price</li>
                        <li @click="Source_value = '.high'">High Price</li>
                        <li @click="Source_value = '.low'">Low Price</li>
                        <li @click="Source_value = '.volume'">Volume</li>
                      </ul>
                    </div>
                  </div>
                </span>
              </p>
            </div> -->

            <div v-if="data_indicators[index].Shift_value != null" class="s-i">
              <p>
                Shift Percentage<span
                  class="count"
                  style="float: right; margin-left: 0; display: inline-flex"
                  ><input
                    maxlength="2"
                    v-model="data_indicators[index].Shift_value"
                    type="text"
                /></span>
              </p>
            </div>
            <div
              v-if="data_indicators[index].Source_2_value != null"
              class="s-i"
            >
              <p>
                Source 2<span
                  class=""
                  style="float: right; margin-left: 0; display: inline-flex"
                >
                  <select
                    v-model="data_indicators[index].Source_2_value"
                    class="option_source"
                  >
                    <option value=".close">Close Price</option>
                    <option value=".open">Open Price</option>
                    <option value=".high">High Price</option>
                    <option value=".low">Low Price</option>
                    <option value=".volume">Volume</option>
                  </select>
                </span>
              </p>
            </div>
          </div>
          <div >
            <div class="csfi">
              <div class="csfb" style="
    margin-top: 0px;
">
                <button
                  class="b-o"
                @click="addindicator()"
                >
                  เพิ่ม Indicators
                </button>
              
              </div>
            </div>
          </div>
          <!-- <div v-if="Periods_value != null" class="stf" style="padding-top: 6px;">
            <p>%D Periods<span class="" style="float: right;">
                <div class="pt-2">
                  <div class="select-container-settings" tabindex="0" @click="open_dropdown_samp = !open_dropdown_samp"
                    @blur="open_dropdown_samp = false" style="float: right; margin-top: 0px; width: 200px">
                    <span class="text">{{ Periods_value }}</span>
                    <span class="icon" :class="open_dropdown_samp ? 'rotate-sc-icon' : ''">
                      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                          fill="#677F8E" />
                      </svg>
                    </span>
                    <div class="options" :class="open_dropdown_samp ? 'show' : 'hidden'">
                      <ul>
                        <li @click="selected_option_samp = 'Simple'">Simple</li>
                        <li @click="selected_option_samp = 'Time Series'">Time Series</li>
                        <li @click="selected_option_samp = 'Triangular'">Triangular</li>
                        <li @click="selected_option_samp = 'Variable'">Variable</li>
                        <li @click="selected_option_samp = 'VIDYA'">VIDYA</li>
                        <li @click="selected_option_samp = 'Weighted'">Weighted</li>
                        <li @click="selected_option_samp = 'Welles Wilder Smoothing'">Welles Wilder Smoothing</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </span>
            </p>
          </div> -->

          <!-- //reset -->
          <div style="margin-bottom: 22px">
            <div class="csf">
              <p @click="getThemeidicators('defalse')">Reset to Default</p>
              <div class="csfb" style="margin-top: 195px">
                <button
                  class="b-o"
                  @click="
                    $bvModal.hide('settings-modal');
                    strongTrend = false;
                    settingMenu = true;
                    add_indicators();
                  "
                >
                  ยืนยัน
                </button>
                <button
                  class="b-g"
                  @click="
                    strongTrend = false;
                    indicatorSetting = true;
                  "
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template>
        <!-- <template v-if="trendRibbon">
          <div>
            <div class="chart-setting">
              <p class="cs-title">SMA</p>
              <div class="s-i" style="position: relative">
                <p>
                  Period
                  <span class="color-badge" @click="closeColor('smap')">
                    <div :class="{ 'green-color': smap }" :style="{ backgroundColor: bgColorSma }" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                        fill="#677F8E" />
                    </svg>
                  </span>
                </p>
                <div class="count" style="position: absolute; top: 16px; left: 49px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="position: absolute; top: 16px; left: 194px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
                <p>Type</p>
                <div class="select-container-settings" tabindex="0" @click="open_dropdown_samp = !open_dropdown_samp"
                  @blur="open_dropdown_samp = false" style="float: right; margin-top: -19px; width: 88px">
                  <span class="text">{{ selected_option_samp }}</span>
                  <span class="icon" :class="open_dropdown_samp ? 'rotate-sc-icon' : ''">
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                        fill="#677F8E" />
                    </svg>
                  </span>
                  <div class="options" :class="open_dropdown_samp ? 'show' : 'hidden'">
                    <ul>
                      <li @click="selected_option_samp = 'option'">option</li>
                      <li @click="selected_option_samp = 'option'">option</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chart-setting" style="margin-top: 27px">
              <p class="cs-title">EMA</p>
              <div class="s-i" style="position: relative">
                <p>
                  Period
                  <span class="color-badge" @click="closeColor('emap')">
                    <div :class="{ 'red-color': emap }" :style="{ backgroundColor: bgColorEma }" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0108643L3.5 2.68253L6.1775 0.0108643L7 0.833364L3.5 4.33336L0 0.833364L0.8225 0.0108643Z"
                        fill="#677F8E" />
                    </svg>
                  </span>
                </p>
                <div class="count" style="position: absolute; top: 16px; left: 49px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="position: absolute; top: 16px; left: 194px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
                <p>Type</p>
                <div class="select-container-settings" tabindex="0" @click="open_dropdown_exp = !open_dropdown_exp"
                  @blur="open_dropdown_exp = false" style="float: right; margin-top: -19px; width: 120px">
                  <span class="text">{{ selected_option_exp }}</span>
                  <span class="icon" :class="open_dropdown_exp ? 'rotate-sc-icon' : ''">
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                        fill="#677F8E" />
                    </svg>
                  </span>
                  <div class="options" :class="open_dropdown_exp ? 'show' : 'hidden'">
                    <ul>
                      <li @click="selected_option_exp = 'option 2'">
                        option 2
                      </li>
                      <li @click="selected_option_exp = 'option 3'">
                        option 3
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="bp">
              <p>MA Base Price</p>
              <div class="select-container-settings" tabindex="0" @click="open_dropdown_close = !open_dropdown_close"
                @blur="open_dropdown_close = false" style="float: right; margin-top: -37px; width: 88px">
                <span class="text">{{ selected_option_close }}</span>
                <span class="icon" :class="open_dropdown_close ? 'rotate-sc-icon' : ''">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E" />
                  </svg>
                </span>
                <div class="options" :class="open_dropdown_close ? 'show' : 'hidden'" style="
                    top: 27px !important;
                    height: 160px;
                    top: 27px !important;
                    width: 180px;
                    height: 160px;
                    left: -92px;
                  ">
                  <ul>
                    <li @click="selected_option_close = 'Close'">Close</li>
                    <li @click="selected_option_close = 'Open'">Open</li>
                    <li @click="selected_option_close = 'High'">High</li>
                    <li @click="selected_option_close = 'Low'">Low</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="csf" style="text-align: center">
              <p>Reset to Default</p>
              <div class="csfb" style="margin-top: 35px !important">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    trendRibbon = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      trendRibbon = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="ema">
          <div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 1</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('eperiod1')">
                  <div :class="{ 'yellow-color': eperiod1 }" :style="{ backgroundColor: bgColorEperiod1 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 2</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('eperiod2')">
                  <div :class="{ 'blue-color': eperiod2 }" :style="{ backgroundColor: bgColorEperiod2 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 3</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('eperiod3')">
                  <div :class="{ 'pink-color': eperiod3 }" :style="{ backgroundColor: bgColorEperiod3 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 4</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('eperiod4')">
                  <div :class="{ 'dark-yellow': eperiod4 }" :style="{ backgroundColor: bgColorEperiod4 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 5</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('eperiod5')">
                  <div :class="{ 'green-color': eperiod5 }" :style="{ backgroundColor: bgColorEperiod5 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 6</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('eperiod6')">
                  <div :class="{ 'white-color': eperiod6 }" :style="{ backgroundColor: bgColorEperiod6 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="
                margin-bottom: 20px;
                padding-right: 24px;
                padding-top: 13px;
              " class="bp">
              <p>EMA Base Price</p>
              <div class="select-container-settings" tabindex="0" @click="open_dropdown_close = !open_dropdown_close"
                @blur="open_dropdown_close = false" style="float: right; margin-top: -37px; width: 88px">
                <span class="text">{{ selected_option_close }}</span>
                <span class="icon" :class="open_dropdown_close ? 'rotate-sc-icon' : ''">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E" />
                  </svg>
                </span>
                <div class="options" :class="open_dropdown_close ? 'show' : 'hidden'"
                  style="top: 27px !important; height: 160px">
                  <ul>
                    <li @click="selected_option_close = 'Close'">Close</li>
                    <li @click="selected_option_close = 'Open'">Open</li>
                    <li @click="selected_option_close = 'High'">High</li>
                    <li @click="selected_option_close = 'Low'">Low</li>
                  </ul>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px; margin-top: 26px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="margin-top: 75px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    ema = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      ema = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="sma">
          <div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 1</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('speriod1')">
                  <div :class="{ 'yellow-color': speriod1 }" :style="{ backgroundColor: bgColorSperiod1 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 2</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('speriod2')">
                  <div :class="{ 'blue-color': speriod2 }" :style="{ backgroundColor: bgColorSperiod2 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 3</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('speriod3')">
                  <div :class="{ 'pink-color': speriod3 }" :style="{ backgroundColor: bgColorSperiod3 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 4</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('speriod4')">
                  <div :class="{ 'dark-yellow': speriod4 }" :style="{ backgroundColor: bgColorSperiod4 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 5</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('speriod5')">
                  <div :class="{ 'green-color': speriod5 }" :style="{ backgroundColor: bgColorSperiod5 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label>
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period 6</span>
                </label>
                <div class="count" style="margin-left: 86px">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('speriod6')">
                  <div :class="{ 'white-color': speriod6 }" :style="{ backgroundColor: bgColorSperiod6 }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px; padding-top: 13px" class="bp">
              <p>SMA Base Price</p>
              <div class="select-container-settings" tabindex="0" @click="open_dropdown_close = !open_dropdown_close"
                @blur="open_dropdown_close = false" style="float: right; margin-top: -37px; width: 88px">
                <span class="text">{{ selected_option_close }}</span>
                <span class="icon" :class="open_dropdown_close ? 'rotate-sc-icon' : ''">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E" />
                  </svg>
                </span>
                <div class="options" :class="open_dropdown_close ? 'show' : 'hidden'"
                  style="top: 27px !important; height: 160px">
                  <ul>
                    <li @click="selected_option_close = 'Close'">Close</li>
                    <li @click="selected_option_close = 'Open'">Open</li>
                    <li @click="selected_option_close = 'High'">High</li>
                    <li @click="selected_option_close = 'Low'">Low</li>
                  </ul>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px; margin-top: 25px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    sma = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      sma = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="volume">
          <div>
            <div style="margin-bottom: 14px">
              <div class="d-inline-flex">
                <label>
                  <span class="w-title">Bar Colors - Volume Up</span>
                </label>
              </div>
              <div class="d-inline-flex float-end">
                <span class="color-badge" @click="closeColor('volumeUp')">
                  <div :class="{ 'green-color': volumeUp }" :style="{ backgroundColor: bgColorVolumeup }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </span>
              </div>
            </div>
            <div>
              <div class="d-inline-flex">
                <label>
                  <span class="w-title">Bar Colors - Volume Down</span>
                </label>
              </div>
              <div class="d-inline-flex float-end">
                <span class="color-badge" @click="closeColor('volumeDown')">
                  <div :class="{ 'red-color': volumeDown }" :style="{ backgroundColor: bgColorVolumedown }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="csf" style="text-align: center">
              <p>Reset to Default</p>
              <div class="csfb" style="
                  margin-top: 35px !important;
                  position: absolute;
                  bottom: 47px;
                  left: 72px;
                ">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    volume = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      volume = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="macd">
          <div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Short</span>
                </label>
                <div class="count" style="margin-left: 0">30</div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('short')">
                  <div :class="{ 'yellow-color': short }" :style="{ backgroundColor: bgColorShort }" class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Long</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('long')">
                  <div :class="{ 'blue-color': long }" :style="{ backgroundColor: bgColorLong }" class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Signal</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('signal')">
                  <div :class="{ 'pink-color': signal }" :style="{ backgroundColor: bgColorSignal }" class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Base</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('base')">
                  <div :class="{ 'dark-yellow': base }" :style="{ backgroundColor: bgColorBase }" class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    macd = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      macd = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="rsi">
          <div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('rsiPeriod')">
                  <div :class="{ 'yellow-color': rsiPeriod }" :style="{ backgroundColor: bgColorRsiPeriod }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Signal</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('rsiSignal')">
                  <div :class="{ 'blue-color': rsiSignal }" :style="{ backgroundColor: bgColorRsiSignal }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Overbought</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('overbought')">
                  <div :class="{ 'pink-color': overbought }" :style="{ backgroundColor: bgColorOverbought }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Oversold</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('oversold')">
                  <div :class="{ 'dark-yellow': oversold }" :style="{ backgroundColor: bgColorOversold }"
                    class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    rsi = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      rsi = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="stochasticFast">
          <div>
            <div class="stf">
              <p>
                Period<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Period1<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div style="
                margin-bottom: 20px;
                padding-top: 20px;
                border-top: 1px solid #28363e;
                padding-left: 12px;
              ">
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 149px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Fast %K 5</span>
                  </label>
                  <div class="pt" style="margin-left: 83px; margin-right: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
                <div class="d-inline-flex">
                  <span class="color-badge" @click="closeColor('stochasticFast')">
                    <div style="background-color:#F85E00;" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 149px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Fast %D 3</span>
                  </label>
                  <div class="pt" style="margin-left: 83px; margin-right: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
                <div class="d-inline-flex">
                  <span class="color-badge" @click="closeColor('stochasticFast')">
                    <div style="background-color:#B554FF;" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    stochasticFast = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      stochasticFast = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="stochasticSlow">
          <div>
            <div class="stf">
              <p>
                Sto1<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input maxlength="2"
                    value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Sto2<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input maxlength="2"
                    value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Sto3
                <span class="count" style="float: right; margin-left: 0; display: inline-flex"><input maxlength="2"
                    value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Overheating<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Stagnation<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div style="
                margin-bottom: 20px;
                padding-top: 20px;
                border-top: 1px solid #28363e;
              ">
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Slow`%K 12.5</span>
                  </label>

                  <div class="pt" style="margin-left: 83px; margin-right: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
                <div class="d-inline-flex">
                  <span class="color-badge" @click="closeColor('stochasticSlow')">
                    <div style="background-color:#F8C417;" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Fast %D 5</span>
                  </label>
                  <div class="pt" style="margin-left: 83px; margin-right: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
                <div class="d-inline-flex">
                  <span class="color-badge" @click="closeColor('stochasticSlow')">
                    <div style="background-color:#20A3FF;" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Overheating 80</span>
                  </label>
                  <div class="pt" style="margin-left: 83px; margin-right: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
                <div class="d-inline-flex">
                  <span class="color-badge" @click="closeColor('stochasticSlow')">
                    <div style="background-color:#F63A6C;" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Stagnation 20</span>
                  </label>

                  <div class="pt" style="margin-left: 83px; margin-right: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
                <div class="d-inline-flex">
                  <span class="color-badge" @click="closeColor('stochasticSlow')">
                    <div style="background-color:#B554FF;" class="color-box">
                    </div>
                    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    stochasticSlow = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      stochasticSlow = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="adx">
          <div>
            <div style="margin-bottom: -13px">
              <div class="d-inline-flex">
                <label style="width: 161px">
                  <input type="checkbox" class="form-check-input" />
                  <span class="w-title">Period</span>
                </label>
                <div class="count" style="margin-left: 0">
                  <input maxlength="2" value="20" type="text" />
                </div>
                <div class="pt" style="margin-left: 16px; margin-right: 16px">
                  <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                  </svg>
                  1 pt
                  <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E" />
                  </svg>
                </div>
              </div>
              <div class="d-inline-flex">
                <span class="color-badge" @click="closeColor('adx')">
                  <div style="background-color:#F8C417;" class="color-box">
                  </div>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    adx = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      adx = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="bolingerBrands">
          <div>
            <div class="stf">
              <p>
                Period<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                D1<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input maxlength="2"
                    value="20" type="text" /></span>
              </p>
            </div>
            <div style="
                margin-bottom: 20px;
                padding-top: 20px;
                border-top: 1px solid #28363e;
              ">
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Center Line</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('bolingerBrands')">
                      <div style="background-color:#F8C417;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>

              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Ceiling Line</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('bolingerBrands')">
                      <div style="background-color:#20A3FF;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 20px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Floor Line</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('bolingerBrands')">
                      <div style="background-color:#B554FF;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    bolingerBrands = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      bolingerBrands = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="ichilmoku">
          <div>
            <div class="stf">
              <p>
                Short Period<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Mid Period<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Long Period<span class="count" style="float: right; margin-left: 0; display: inline-flex"><input
                    maxlength="2" value="20" type="text" /></span>
              </p>
            </div>
            <div style="
                margin-bottom: 20px;
                padding-top: 20px;
                border-top: 1px solid #28363e;
              ">
              <div style="margin-bottom: 16px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Tenkan</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('ichilmoku')">
                      <div style="background-color:#F8C417;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 16px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Kijun</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('ichilmoku')">
                      <div style="background-color:#20A3FF;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 16px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Chikou</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('ichilmoku')">
                      <div style="background-color:#F63A6C;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 16px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Senkou1</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('ichilmoku')">
                      <div style="background-color:#B554FF;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 16px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">Senkou1</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('ichilmoku')">
                      <div style="background-color:#B554FF;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    ichilmoku = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      ichilmoku = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
        <!-- <template v-if="parabolic">
          <div>
            <div class="stf">
              <p>
                Minimum AF
                <span class="count" style="float: right; margin-left: 0; display: inline-flex"><input maxlength="2"
                    value="9" type="text" /></span>
              </p>
            </div>
            <div class="stf">
              <p>
                Maxinum AF
                <span class="count" style="float: right; margin-left: 0; display: inline-flex"><input maxlength="2"
                    value="26" type="text" /></span>
              </p>
            </div>
            <div style="
                margin-bottom: 20px;
                padding-top: 20px;
                border-top: 1px solid #28363e;
              ">
              <div style="margin-bottom: 16px">
                <div class="d-inline-flex">
                  <label style="width: 161px">
                    <input type="checkbox" class="form-check-input" />
                    <span class="w-title">PSAR</span>
                  </label>
                  <div class="d-inline-flex" style="margin-left: 83px">
                    <span class="color-badge" @click="closeColor('ichilmoku')">
                      <div style="background-color:#F8C417;" class="color-box">
                      </div>
                      <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"></path>
                      </svg>
                    </span>
                  </div>
                  <div class="pt" style="margin-left: 16px">
                    <svg width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.964844" y1="1" x2="30.2982" y2="0.999999" stroke="white" stroke-width="2" />
                    </svg>
                    1 pt
                    <svg style="position: relative; left: 7px" width="7" height="5" viewBox="0 0 7 5" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 20px" class="csf">
              <p>Reset to Default</p>
              <div class="csfb" style="position: absolute; bottom: 35px; left: 72px">
                <button class="b-o" @click="
      $bvModal.hide('settings-modal');
    parabolic = false;
    settingMenu = true;
    ">
                  ยืนยัน
                </button>
                <button class="b-g" @click="
      parabolic = false;
    indicatorSetting = true;
    ">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </template> -->
      </div>
    </b-modal>
    <!-- <Cp1 /> -->
    <Cp2 @send-color="onChangeColor" />
  </div>
</template>
<script>
import Cp1 from "./Cp1.vue";
import Cp2 from "./Cp2.vue";
import EventBus from "../../../event-bus";
import { controllers } from "chart.js";
export default {
  components: {
    Cp1,
    Cp2,
  },
  data() {
    return {
      index_change_color:0,
      value_change_color:"",
      is_change_color:false,
      bodyaddindicators: [],
      Base_Banker: null,
      Base_Hot_Money: null,
      Long_Cycle: null,
      Periods_Base_Banker: null,
      Periods_Base_Hot_Money: null,
      Sensitivity_Banker: null,
      Sensitivity_Hot_Money: null,
      Short_Cycle: null,
      Signal_Periods: null,
      Base_Banker_value: null,
      Base_Hot_Money_value: null,
      Long_Cycle_value: null,
      Periods_Base_Banker_value: null,
      Periods_Base_Hot_Money_value: null,
      Sensitivity_Banker_value: null,
      Sensitivity_Hot_Money_value: null,
      Short_Cycle_value: null,
      Signal_Periods_value: null,
      Periods_value: null,
      isOpen: false,
      id_theme_indicators: 0,
      defalse_indicators: [],
      id_update: 0,
      name_indicators_setting: "",
      indicatorList: [
        { title: "Strong Trend" },
        { title: "Trend Ribbon" },
        { title: "EMA" },
        { title: "SMA" },
        { title: "Volume" },
        { title: "MACD" },
        { title: "RSI" },
        { title: "Stochastic Fast" },
        { title: "Stochastic Slow" },
        { title: "ADX" },
        { title: "Bolinger Bands" },
        { title: "Ichimoku" },
        { title: "Parabolic SAR Setting" },
      ],
      lineValue: "solid",
      LineStyle: [],
      selectedValue: "1",
      open_dropdown_exp: false,
      selected_option_exp: "Exponential",
      open_dropdown_samp: false,
      selected_option_samp: "Simple",
      open_dropdown_close: false,
      selected_option_close: "Close Price",
      settingMenu: true, //make it true by default
      chartSetting: false,
      indicatorSetting: false,
      selectIndicator: false,
      //   indecator items start
      strongTrend: false,
      trendRibbon: false,
      ema: false,
      sma: false,
      volume: false,
      macd: false,
      rsi: false,
      stochasticFast: false,
      stochasticSlow: false,
      adx: false,
      bolingerBrands: false,
      ichilmoku: false,
      parabolic: false,
      //   indecator items end
      line: true,
      upCandle: true,
      downCandle: true,
      weekTrend: true,
      speculation: true,
      strongUptrend: true,
      ma: true,
      smap: true,
      emap: true,
      eperiod1: true,
      eperiod2: true,
      eperiod3: true,
      eperiod4: true,
      eperiod5: true,
      eperiod6: true,
      speriod1: true,
      speriod2: true,
      speriod3: true,
      speriod4: true,
      speriod5: true,
      speriod6: true,
      volumeUp: true,
      volumeDown: true,
      short: true,
      long: true,
      signal: true,
      base: true,
      rsiPeriod: true,
      rsiSignal: true,
      overbought: true,
      openDropdown: false,
      oversold: true,
      bgColorLine: "",
      bgColorUp: "",
      bgColorDown: "",
      bgColorWeekTrend: "",
      bgColorSpeculation: "",
      bgColorStrongUptrend: "",
      bgColorMa: "",
      bgColorSma: "",
      bgColorEma: "",
      bgColorEperiod1: "",
      bgColorEperiod2: "",
      bgColorEperiod3: "",
      bgColorEperiod4: "",
      bgColorEperiod5: "",
      bgColorEperiod6: "",
      bgColorSperiod1: "",
      bgColorSperiod2: "",
      bgColorSperiod3: "",
      bgColorSperiod4: "",
      bgColorSperiod5: "",
      bgColorSperiod6: "",
      bgColorVolumeup: "",
      bgColorVolumedown: "",
      bgColorShort: "",
      bgColorLong: "",
      bgColorSignal: "",
      bgColorBase: "",
      bgColorRsiPeriod: "",
      bgColorRsiSignal: "",
      bgColorOverbought: "",
      bgColorOversold: "",
      candleStickCheck1: false,
      candleStickCheck2: false,
      settingPayload: null,
      activeIndicator: [],
      indicators: [],
      indicators_popular: [],
      indicators_setting_data: [],
      activeIndicatorDetail: null,
      Line_Color: true,
      Line_2_Color: true,
      data_insert: [],
      Line_Style: true,
      Line_2_Style: true,

      Line_Width: true,
      Line_2_Width: true,

      Line_3_Width: true,
      Line_3_Color: true,
      Line_3_Style: true,
      Line_4_Width: true,
      Line_4_Color: true,
      Line_4_Style: true,
      Line_4_Width: true,
      Line_5_Color: true,
      Line_5_Style: true,
      Line_5_Width: true,
      Line_5_Color: true,
      Line_6_Style: true,
      Line_6_Width: true,
      Line_6_Color: true,
      Line_6_Style: true,
      Line_7_Width: true,
      Line_7_Color: true,
      Line_7_Style: true,
      Line_8_Width: true,
      Line_8_Color: true,
      Line_8_Style: true,
      theme_id_now: {
        id: 0,
        defalse_type: false,
      },
      options: [
        {
          value: "solid",
          text: "solid",
          image: "../../../assets/svg/solidline.svg",
        },
        {
          value: "dash",
          text: "dash",
          image: "../../../assets/svg/dashline.svg",
        },
        { value: "dot", text: "dot", image: "../../../assets/svg/dotline.svg" },
        {
          value: "dash-dot",
          text: "dash-dot",
          image: "../../../assets/svg/dashdotline.svg",
        },
      ],
      options_width: [
        {
          value: "1",
        },
        {
          value: "2",
        },
        { value: "3" },
        {
          value: "4",
        },
        {
          value: "5",
        },
      ],
      selectedValue: "solid",
      selectedText: "solid",
      selectedValue_width: "1",
      selectedImage: "../../../assets/svg/solidline.svg",
      Source_2: false,
      Periods: false,
      Shift: false,
      Source: false,
      Moving_Average_Type: false,
      Line_Color_value: null,
      Line_2_Color_value: null,
      Line_Style_value: null,
      Line_2_Style_value: null,
      Line_Width_value: null,
      Line_2_Width_value: null,
      Line_3_Color_value: null,
      Line_3_Style_value: null,
      Line_3_Width_value: null,
      Line_4_Color_value: null,
      Line_4_Style_value: null,
      Line_4_Width_value: null,
      Line_5_Color_value: null,
      Line_5_Style_value: null,
      Line_5_Width_value: null,
      Line_6_Color_value: null,
      Line_6_Style_value: null,
      Line_6_Width_value: null,
      Line_7_Color_value: null,
      Line_7_Style_value: null,
      Line_7_Width_value: null,
      Line_8_Color_value: null,
      Line_8_Style_value: null,
      Line_8_Width_value: null,
      Periods_value: null,
      Shift_value: null,
      Source_value: null,
      Source_2_value: null,
      Moving_Average_Type_value: null,
      defalse_colorup: "#3f984e",
      defalse_colordown: "#de2d40",
      data_indicators: [
        {
          Source_2: false,
          Periods: false,
          Shift: false,
          Source: false,
          Moving_Average_Type: false,
          Line_Color_value: null,
          Line_2_Color_value: null,
          Line_Style_value: null,
          Line_2_Style_value: null,
          Line_Width_value: null,
          Line_2_Width_value: null,
          Line_3_Color_value: null,
          Line_3_Style_value: null,
          Line_3_Width_value: null,
          Line_4_Color_value: null,
          Line_4_Style_value: null,
          Line_4_Width_value: null,
          Line_5_Color_value: null,
          Line_5_Style_value: null,
          Line_5_Width_value: null,
          Line_6_Color_value: null,
          Line_6_Style_value: null,
          Line_6_Width_value: null,
          Line_7_Color_value: null,
          Line_7_Style_value: null,
          Line_7_Width_value: null,
          Line_8_Color_value: null,
          Line_8_Style_value: null,
          Line_8_Width_value: null,
          Periods_value: null,
          Shift_value: null,
          Source_value: null,
          Source_2_value: null,
          Moving_Average_Type_value: null,
        },
      ],
    };
  },
  computed: {
    getActiveIndicatorDetails() {
      const lineArray = [];
      const otherPropertiesArray = [];
      for (const key in this.activeIndicatorDetail) {
        if (key.startsWith("Line ")) {
          const propertyType = key.split(" ")[1].replace(/ /g, "");
          const propertyName = `Line${propertyType}`;
          lineArray.push({
            name: propertyName,
            value: this.activeIndicatorDetail[key],
          });
        } else {
          const cleanedKey = key.replace(/ /g, "");
          otherPropertiesArray.push({
            name: cleanedKey,
            value: this.activeIndicatorDetail[key],
          });
        }
      }
      const lineObject = { name: "Line", value: lineArray };
      otherPropertiesArray.splice(0, 0, lineObject);
      return otherPropertiesArray;
    },
  },
  mounted() {
    this.$parent.$on("child-event", this.childMethod);
    this.$parent.$on("child-event-indicator", this.childMethodIndicator);
    this.$parent.$on(
      "child-event-indicator-popular",
      this.childMethodIndicator_popular
    );
    this.$parent.$on("child-setting-indicator", this.ichecksettingindicator);
    this.$parent.$on(
      "child-event-active-indicator",
      this.childMethodActiveIndicator
    );
    this.$parent.$on("active-indicator-details", this.activeIndicatorDetails);
    EventBus.$on("get-Reset-Model", () => {
      this.getResetModel();
    });
    window.addEventListener("click", this.checkIfClickedOutside);
  },
  beforeDestroy() {
    this.$parent.$off("child-event", this.childMethod);
    document.removeEventListener("click", this.handleClickOutside);
    this.$parent.$off("child-event-indicator", this.childMethodIndicator);
    this.$parent.$off(
      "child-event-indicator-popular",
      this.childMethodIndicator_popular
    );
    this.$parent.$off("child-setting-indicator", this.ichecksettingindicator);
    this.$parent.$off(
      "child-event-active-indicator",
      this.childMethodActiveIndicator
    );
    this.$parent.$off("active-indicator-details", this.activeIndicatorDetails);
  },
  methods: {
    addindicator() {
      if (this.data_indicators.length !== 0) {
        const newIndicator = JSON.parse(JSON.stringify(this.bodyaddindicators));
        this.data_indicators.push(newIndicator);
      }
    },
    checkIfClickedOutside(event) {},
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("open");
      // if (this.openDropdown) {
      //   document.addEventListener('click', this.handleClickOutside);
      // } else {
      //   document.removeEventListener('click', this.handleClickOutside);
      // }
    },
    toggleOptions(event) {
      this.$refs.customSelect.classList.toggle("open");
    },
    handleClickOutside(event) {
      // if (!this.$refs.customSelect.contains(event.target)) {
      //   this.$refs.customSelect.classList.remove('open');
      // }
    },
    selectOption(option) {
      this.selectedValue = option.value;
      this.selectedText = option.text;
      this.selectedImage = option.image;
    },
    selectOption_width(option) {
      this.selectedValue_width = option.value;
    },
    reset_data() {
      this.id_update = 0;
      this.Line_Color = true;
      this.Line_2_Color = true;
      this.Line_3_Color = true;
      this.Line_4_Color = true;
      this.Line_5_Color = true;
      this.Line_6_Color = true;
      this.Line_7_Color = true;
      this.Line_8_Color = true;
      this.Line_Style = false;
      this.Line_2_Style = false;
      this.Line_3_Style = false;
      this.Line_Width = false;
      this.Line_2_Width = false;
      this.Line_3_Width = false;
      this.Periods = false;
      this.Shift = false;
      this.Source = false;
      this.Moving_Average_Type = false;
      this.Line_Color_value = null;
      this.Line_Style_value = null;
      this.Line_Width_value = null;
      this.Line_2_Color_value = null;
      this.Line_2_Style_value = null;
      this.Line_2_Width_value = null;
      this.Line_3_Color_value = null;
      this.Line_3_Style_value = null;
      this.Line_3_Width_value = null;
      this.Line_4_Color_value = null;
      this.Line_4_Style_value = null;
      this.Line_4_Width_value = null;
      this.Line_5_Color_value = null;
      this.Line_5_Style_value = null;
      this.Line_5_Width_value = null;
      this.Line_6_Color_value = null;
      this.Line_6_Style_value = null;
      this.Line_6_Width_value = null;
      this.Line_7_Color_value = null;
      this.Line_7_Style_value = null;
      this.Line_7_Width_value = null;
      this.Line_8_Color_value = null;
      this.Line_8_Style_value = null;
      this.Line_8_Width_value = null;
      this.Base_Banker_value = null;
      this.Base_Hot_Money_value = null;
      this.Long_Cycle_value = null;
      this.Periods_Base_Banker_value = null;
      this.Periods_Base_Hot_Money_value = null;
      this.Sensitivity_Banker_value = null;
      this.Sensitivity_Hot_Money_value = null;
      this.Short_Cycle_value = null;
      this.Signal_Periods_value = null;
      this.Periods_value = null;
      this.Shift_value = null;
      this.Source_value = null;
      this.Moving_Average_Type_value = null;
    },
    check_setting(value) {
      this.data_insert = [];
      this.reset_data();
      this.id_update = value.id;
      var param = value.params;
      if (param["Line Color"]) {
        this.Line_Color_value = param["Line Color"];
      }

      if (param["Line Style"]) {
        this.Line_Style = true;
        this.Line_Style_value = param["Line Style"];
      }
      if (param["Line Width"]) {
        this.Line_Width = true;
        this.Line_Width_value = param["Line Width"];
      }
      if (param["Line 2 Color"]) {
        this.Line_2_Color_value = param["Line 2 Color"];
      }
      if (param["Line 2 Style"]) {
        this.Line_2_Style = true;
        this.Line_2_Style_value = param["Line 2 Style"];
      }
      if (param["Line 2 Width"]) {
        this.Line_2_Width = true;
        this.Line_2_Width_value = param["Line 2 Width"];
      }
      if (param["Line 3 Color"]) {
        this.Line_3_Color_value = param["Line 3 Color"];
      }
      if (param["Line 3 Style"]) {
        this.Line_3_Style = true;
        this.Line_3_Style_value = param["Line 3 Style"];
      }
      if (param["Line 3 Width"]) {
        this.Line_3_Width = true;
        this.Line_3_Width_value = param["Line 3 Width"];
      }
      if (param["Line 4 Color"]) {
        this.Line_4_Color_value = param["Line 4 Color"];
      }
      if (param["Line 4 Style"]) {
        this.Line_4_Style = true;
        this.Line_4_Style_value = param["Line 4 Style"];
      }
      if (param["Line 4 Width"]) {
        this.Line_4_Width = true;
        this.Line_4_Width_value = param["Line 4 Width"];
      }
      if (param["Line 5 Color"]) {
        this.Line_5_Color_value = param["Line 5 Color"];
      }
      if (param["Line 5 Style"]) {
        this.Line_5_Style = true;
        this.Line_5_Style_value = param["Line 5 Style"];
      }
      if (param["Line 5 Width"]) {
        this.Line_5_Width = true;
        this.Line_5_Width_value = param["Line 5 Width"];
      }
      if (param["Line 6 Color"]) {
        this.Line_6_Color_value = param["Line 6 Color"];
      }
      if (param["Line 6 Style"]) {
        this.Line_6_Style = true;
        this.Line_6_Style_value = param["Line 6 Style"];
      }
      if (param["Line 6 Width"]) {
        this.Line_6_Width = true;
        this.Line_6_Width_value = param["Line 6 Width"];
      }
      if (param["Line 7 Color"]) {
        this.Line_7_Color_value = param["Line 7 Color"];
      }
      if (param["Line 7 Style"]) {
        this.Line_7_Style = true;
        this.Line_7_Style_value = param["Line 7 Style"];
      }
      if (param["Line 7 Width"]) {
        this.Line_7_Width = true;
        this.Line_7_Width_value = param["Line 7 Width"];
      }
      if (param["Line 8 Color"]) {
        this.Line_8_Color_value = param["Line 8 Color"];
      }
      if (param["Line 8 Style"]) {
        this.Line_8_Style = true;
        this.Line_8_Style_value = param["Line 8 Style"];
      }
      if (param["Line 8 Width"]) {
        this.Line_8_Width = true;
        this.Line_8_Width_value = param["Line 8 Width"];
      }
      if (param["Moving Average Type"] != undefined) {
        this.Moving_Average_Type = true;
        this.Moving_Average_Type_value = param["Moving Average Type"];
      }
      if (param["Base Banker"] != undefined) {
        this.Base_Banker = true;
        this.Base_Banker_value = param["Base Banker"];
      }
      if (param["Base Hot Money"] != undefined) {
        this.Base_Hot_Money = true;
        this.Base_Hot_Money_value = param["Base Hot Money"];
      }

      if (param["Long Cycle"] != undefined) {
        this.Long_Cycle = true;
        this.Long_Cycle_value = param["Long Cycle"];
      }
      if (param["Periods Base Banker"] != undefined) {
        this.Periods_Base_Banker = true;
        this.Periods_Base_Banker_value = param["Periods Base Banker"];
      }
      if (param["Periods Base Hot Money"] != undefined) {
        this.Periods_Base_Hot_Money = true;
        this.Periods_Base_Hot_Money_value = param["Periods Base Hot Money"];
      }
      if (param["Sensitivity Banker"] != undefined) {
        this.Sensitivity_Banker = true;
        this.Sensitivity_Banker_value = param["Sensitivity Banker"];
      }
      if (param["Sensitivity Hot Money"] != undefined) {
        this.Sensitivity_Hot_Money = true;
        this.Sensitivity_Hot_Money_value = param["Sensitivity Hot Money"];
      }
      if (param["Short Cycle"] != undefined) {
        this.Short_Cycle = true;
        this.Short_Cycle_value = param["Short Cycle"];
      }
      if (param["Signal Periods"] != undefined) {
        this.Signal_Periods = true;
        this.Signal_Periods_value = param["Signal Periods"];
      }
      if (param["Periods"] != undefined) {
        this.Periods = true;
        this.Periods_value = param["Periods"];
      }
      if (param["Shift"] != undefined) {
        this.Shift = true;
        this.Shift_value = param["Shift"];
      }
      if (param["Source"] != undefined) {
        this.Source = true;
        this.Source_value = param["Source"];
      }
    },
    handleItemClick(item) {
      this.indicatorSetting = false;
      this[item.action] = true;
    },
    close_modal() {
      this.data_indicators = [];
      this.$bvModal.hide("settings-modal");
      this.getResetModel();
    },
    activeIndicatorDetails(detail) {
      this.activeIndicatorDetail = detail;
    },
    checkSelectedIndicator(indicator) {
      this.activedata_indicators[index].forEach((item) => {
        if (item.id === data_indicators[index].id) {
          this.$emit("getActiceIndicatorsDetails", data_indicators[index].id);
          this.selectIndicator = true;
        } else {
          this.$emit("addIndicators", indicator);
          this.getIndicator();
        }
      });
    },
    getIndicatorSettings(list) {
      this.indicatorSetting = false;
      if (list == "Strong Trend") {
        this.selectIndicator = true;
      }
      if (list == "Trend Ribbon") {
        this.trendRibbon = true;
      }
      if (list == "EMA") {
        this.ema = true;
      }
      if (list == "SMA") {
        this.sma = true;
      }
      if (list == "Volume") {
        this.volume = true;
      }
      if (list == "MACD") {
        this.macd = true;
      }
      if (list == "RSI") {
        this.rsi = true;
      }
      if (list == "Stochastic Fast") {
        this.stochasticFast = true;
      }
      if (list == "Stochastic Slow") {
        this.stochasticSlow = true;
      }
      if (list == "ADX") {
        this.adx = true;
      }
      if (list == "Bolinger Bands") {
        this.bolingerBrands = true;
      }
      if (list == "Ichimoku") {
        this.ichilmoku = true;
      }
      if (list == "Parabolic SAR Setting") {
        this.parabolic = true;
      }
    },
    childMethod(payload) {
      this.settingPayload = payload;
      this.settingPayload.LineColor = payload.Theme.ClosingLine.LineColor;
      this.LineStyle = payload.LineStyle;
      this.bgColorLine = payload.Theme.ClosingLine.LineColor;
      this.lineValue = payload.Theme.ClosingLine.LineStyle;
      if (
        payload.Theme.ClosingLine.LineWidth == 1 &&
        payload.Theme.ClosingLine.LineWidth != undefined
      ) {
        this.selectedValue = "1";
      } else {
        this.selectedValue = payload.Theme.ClosingLine.LineWidth;
      }
      this.candleStickCheck1 = payload?.Theme.Candlestick.upCandle?.fill;
      this.candleStickCheck2 = payload?.Theme.Candlestick.DownCandle?.fill;
      this.bgColorUp = payload?.Theme.Candlestick.upCandle?.color;
      this.bgColorDown = payload.Theme.Candlestick.DownCandle.color;
    },
    childMethodIndicator(payload) {
      this.indicators = payload;
    },
    childMethodIndicator_popular(payload1) {
      this.indicators_popular = [...payload1];
      // this.indicators_popular = payload;
    },
    ichecksettingindicator(payload) {
      this.indicators_setting_data = payload;
      this.data_insert = [];
      this.reset_data();
      this.data_indicators = []; // Clear the array before processing
      this.bodyaddindicators = [];
      if (this.indicators_setting_data !== undefined) {
        this.id_update = this.indicators_setting_data.id;
        const params = this.indicators_setting_data.params; // Assume params is an array

        const parameterMapping = {
          "Line Color": "Line_Color_value",
          "Line Style": "Line_Style_value",
          "Line Width": "Line_Width_value",
          "Line 2 Color": "Line_2_Color_value",
          "Line 2 Style": "Line_2_Style_value",
          "Line 2 Width": "Line_2_Width_value",
          "Line 3 Color": "Line_3_Color_value",
          "Line 3 Style": "Line_3_Style_value",
          "Line 3 Width": "Line_3_Width_value",
          "Line 4 Color": "Line_4_Color_value",
          "Line 4 Style": "Line_4_Style_value",
          "Line 4 Width": "Line_4_Width_value",
          "Line 5 Color": "Line_5_Color_value",
          "Line 5 Style": "Line_5_Style_value",
          "Line 5 Width": "Line_5_Width_value",
          "Line 6 Color": "Line_6_Color_value",
          "Line 6 Style": "Line_6_Style_value",
          "Line 6 Width": "Line_6_Width_value",
          "Line 7 Color": "Line_7_Color_value",
          "Line 7 Style": "Line_7_Style_value",
          "Line 7 Width": "Line_7_Width_value",
          "Line 8 Color": "Line_8_Color_value",
          "Line 8 Style": "Line_8_Style_value",
          "Line 8 Width": "Line_8_Width_value",
          "Moving Average Type": "Moving_Average_Type_value",
          "Base Banker": "Base_Banker_value",
          "Base Hot Money": "Base_Hot_Money_value",
          "Long Cycle": "Long_Cycle_value",
          "Periods Base Banker": "Periods_Base_Banker_value",
          "Periods Base Hot Money": "Periods_Base_Hot_Money_value",
          "Sensitivity Banker": "Sensitivity_Banker_value",
          "Sensitivity Hot Money": "Sensitivity_Hot_Money_value",
          "Short Cycle": "Short_Cycle_value",
          "Signal Periods": "Signal_Periods_value",
          Periods: "Periods_value",
          Shift: "Shift_value",
          Source: "Source_value",
        };

        params.forEach((param, index) => {
          const dataObject = {};

          Object.keys(param).forEach((key) => {
            if (parameterMapping[key] && param[key] !== undefined) {
              this[parameterMapping[key]] = param[key];
              dataObject[parameterMapping[key]] = param[key];
            }
          });
          this.data_indicators.push(dataObject);
        });
      }
      this.bodyaddindicators = this.data_indicators[0];
    },
    childMethodActiveIndicator(payload) {
      this.activeIndicator = payload;
    },
    getSettings() {
      this.$emit("getChartSettings", true);
    },
    resetSettings(value) {
      this.bgColorLine = this.settingPayload.LineColor;
      this.lineValue = this.settingPayload.LineStyle[0];
      this.selectedValue = this.settingPayload.Theme.ClosingLine.LineWidth;
      this.candleStickCheck1 =
        this.settingPayload.Theme.Candlestick.upCandle.fill;
      this.candleStickCheck2 =
        this.settingPayload.Theme.Candlestick.DownCandle.fill;
      this.bgColorUp = this.settingPayload.Theme.Candlestick.upCandle.color;
      this.DownCandle = this.settingPayload.Theme.Candlestick.DownCandle.color;
      if (value == "reset") {
        this.bgColorUp = this.defalse_colorup;
        this.DownCandle = this.defalse_colordown;
      }
      this.$emit("resetChartSettings", true);
    },
    add_indicators() {
      var data = {
        id: {
          id_indicator: this.id_update,
        },
        data: [],
      };
      // {
      //     LINE_COLOR: this.Line_Color_value ?? null,
      //     LINE2_COLOR: this.Line_2_Color_value ?? null,
      //     LINE_STYLE: this.Line_Style_value ?? null,
      //     LINE2_STYLE: this.Line_2_Style_value ?? null,
      //     LINE_WIDTH:
      //       this.Line_Width_value != null
      //         ? Number(this.Line_Width_value)
      //         : null,
      //     LINE2_WIDTH:
      //       this.Line_2_Width_value != null
      //         ? Number(this.Line_2_Width_value)
      //         : null,
      //     LINE3_COLOR: this.Line_3_Color_value ?? null,
      //     LINE3_STYLE: this.Line_3_Style_value ?? null,
      //     LINE3_WIDTH:
      //       this.Line_3_Width_value != null
      //         ? Number(this.Line_3_Width_value)
      //         : null,
      //     LINE4_COLOR: this.Line_4_Color_value ?? null,
      //     LINE4_STYLE: this.Line_4_Style_value ?? null,
      //     LINE4_WIDTH:
      //       this.Line_4_Width_value != null
      //         ? Number(this.Line_4_Width_value)
      //         : null,
      //     LINE5_COLOR: this.Line_5_Color_value ?? null,
      //     LINE5_STYLE: this.Line_5_Style_value ?? null,
      //     LINE5_WIDTH:
      //       this.Line_5_Width_value != null
      //         ? Number(this.Line_5_Width_value)
      //         : null,
      //     LINE6_COLOR: this.Line_6_Color_value ?? null,
      //     LINE6_STYLE:
      //       this.Line_6_Style_value != null
      //         ? Number(this.Line_6_Style_value)
      //         : null,
      //     LINE6_WIDTH: this.Line_6_Width_value ?? null,
      //     LINE7_COLOR: this.Line_7_Color_value ?? null,
      //     LINE7_STYLE: this.Line_7_Style_value ?? null,
      //     LINE7_WIDTH:
      //       this.Line_7_Width_value != null
      //         ? Number(this.Line_7_Width_value)
      //         : null,
      //     LINE8_COLOR: this.Line_8_Color_value ?? null,
      //     LINE8_STYLE: this.Line_8_Style_value ?? null,
      //     LINE8_WIDTH:
      //       this.Line_8_Width_value != null
      //         ? Number(this.Line_8_Width_value)
      //         : null,
      //     PERIODS:
      //       this.Periods_value != null ? Number(this.Periods_value) : null,
      //     SHIFT: this.Shift_value ?? null,
      //     SOURCE: this.Source_value ?? null,
      //     SOURCE2: this.Source_2_value ?? null,
      //     LONG_CYCLE:
      //       this.Long_Cycle_value != null
      //         ? Number(this.Long_Cycle_value)
      //         : null,
      //     MOVING_AVERAGE_TYPE:
      //       this.Moving_Average_Type_value != null
      //         ? Number(this.Moving_Average_Type_value)
      //         : null,
      //     PERIODS_BASE_BANKER:
      //       this.Periods_Base_Banker_value != null
      //         ? Number(this.Periods_Base_Banker_value)
      //         : null,
      //     BASE_BANKER:
      //       this.Base_Banker_value != null
      //         ? Number(this.Base_Banker_value)
      //         : null,
      //     BASE_HOT_MONEY:
      //       this.Base_Hot_Money_value != null
      //         ? Number(this.Base_Hot_Money_value)
      //         : null,
      //     PERIODS_BASE_HOT_MONEY:
      //       this.Periods_Base_Hot_Money_value != null
      //         ? Number(this.Periods_Base_Hot_Money_value)
      //         : null,
      //     SENSITIVITY_BANKER:
      //       this.Sensitivity_Banker_value != null
      //         ? Number(this.Sensitivity_Banker_value)
      //         : null,
      //     SENSITIVITY_HOT_MONEY:
      //       this.Sensitivity_Hot_Money_value != null
      //         ? Number(this.Sensitivity_Hot_Money_value)
      //         : null,
      //     SHORT_CYCLE:
      //       this.Short_Cycle_value != null
      //         ? Number(this.Short_Cycle_value)
      //         : null,
      //     SIGNAL_PERIODS:
      //       this.Signal_Periods_value != null
      //         ? Number(this.Signal_Periods_value)
      //         : null,
      //   }
      // this.setParameterValue(StockChartX.IndicatorParam.SHORT_CYCLE, 12);
      // this.setParameterValue(StockChartX.IndicatorParam.LONG_CYCLE, 26);
      // this.setParameterValue(StockChartX.IndicatorParam.MA_TYPE, 0);

      // ลูปเพื่อเพิ่มค่าใน data_indicators ไปยัง data.data
      this.data_indicators.forEach((indicator, index) => {
        data.data.push( {
          LINE_COLOR: indicator.Line_Color_value ?? null,
          LINE2_COLOR: indicator.Line_2_Color_value ?? null,
          LINE_STYLE: indicator.Line_Style_value ?? null,
          LINE2_STYLE: indicator.Line_2_Style_value ?? null,
          LINE_WIDTH:
            indicator.Line_Width_value != null
              ? Number(indicator.Line_Width_value)
              : null,
          LINE2_WIDTH:
            indicator.Line_2_Width_value != null
              ? Number(indicator.Line_2_Width_value)
              : null,
          LINE3_COLOR: indicator.Line_3_Color_value ?? null,
          LINE3_STYLE: indicator.Line_3_Style_value ?? null,
          LINE3_WIDTH:
            indicator.Line_3_Width_value != null
              ? Number(indicator.Line_3_Width_value)
              : null,
          LINE4_COLOR: indicator.Line_4_Color_value ?? null,
          LINE4_STYLE: indicator.Line_4_Style_value ?? null,
          LINE4_WIDTH:
            indicator.Line_4_Width_value != null
              ? Number(indicator.Line_4_Width_value)
              : null,
          LINE5_COLOR: indicator.Line_5_Color_value ?? null,
          LINE5_STYLE: indicator.Line_5_Style_value ?? null,
          LINE5_WIDTH:
            indicator.Line_5_Width_value != null
              ? Number(indicator.Line_5_Width_value)
              : null,
          LINE6_COLOR: indicator.Line_6_Color_value ?? null,
          LINE6_STYLE:
            indicator.Line_6_Style_value != null
              ? Number(indicator.Line_6_Style_value)
              : null,
          LINE6_WIDTH: indicator.Line_6_Width_value ?? null,
          LINE7_COLOR: indicator.Line_7_Color_value ?? null,
          LINE7_STYLE: indicator.Line_7_Style_value ?? null,
          LINE7_WIDTH:
            indicator.Line_7_Width_value != null
              ? Number(indicator.Line_7_Width_value)
              : null,
          LINE8_COLOR: indicator.Line_8_Color_value ?? null,
          LINE8_STYLE: indicator.Line_8_Style_value ?? null,
          LINE8_WIDTH:
            indicator.Line_8_Width_value != null
              ? Number(indicator.Line_8_Width_value)
              : null,
          PERIODS:
            indicator.Periods_value != null ? Number(indicator.Periods_value) : null,
          SHIFT: indicator.Shift_value ?? null,
          SOURCE: indicator.Source_value ?? null,
          SOURCE2: indicator.Source_2_value ?? null,
          LONG_CYCLE:
            indicator.Long_Cycle_value != null
              ? Number(indicator.Long_Cycle_value)
              : null,
          MOVING_AVERAGE_TYPE:
            indicator.Moving_Average_Type_value != null
              ? Number(indicator.Moving_Average_Type_value)
              : null,
          PERIODS_BASE_BANKER:
            indicator.Periods_Base_Banker_value != null
              ? Number(indicator.Periods_Base_Banker_value)
              : null,
          BASE_BANKER:
            indicator.Base_Banker_value != null
              ? Number(indicator.Base_Banker_value)
              : null,
          BASE_HOT_MONEY:
            indicator.Base_Hot_Money_value != null
              ? Number(indicator.Base_Hot_Money_value)
              : null,
          PERIODS_BASE_HOT_MONEY:
            indicator.Periods_Base_Hot_Money_value != null
              ? Number(indicator.Periods_Base_Hot_Money_value)
              : null,
          SENSITIVITY_BANKER:
            indicator.Sensitivity_Banker_value != null
              ? Number(indicator.Sensitivity_Banker_value)
              : null,
          SENSITIVITY_HOT_MONEY:
            indicator.Sensitivity_Hot_Money_value != null
              ? Number(indicator.Sensitivity_Hot_Money_value)
              : null,
          SHORT_CYCLE:
            indicator.Short_Cycle_value != null
              ? Number(indicator.Short_Cycle_value)
              : null,
          SIGNAL_PERIODS:
            indicator.Signal_Periods_value != null
              ? Number(indicator.Signal_Periods_value)
              : null,
        });
      });
      data.data = data.data.map((indicator) => {
        const cleanedIndicator = { ...indicator }; // สร้างสำเนาของ indicator
        for (const key in cleanedIndicator) {
          if (cleanedIndicator[key] === null) {
            delete cleanedIndicator[key];
          }
        }
        return cleanedIndicator; // คืนค่า cleanedIndicator ที่ไม่มี null
      });

      this.$emit("addIndicators", data, true);
    },
    updateSettings() {
      this.$emit(
        "updateChartSettings",
        {
          ClosingLine: {
            LineColor: this.bgColorLine,
            LineStyle: this.lineValue,
            LineWidth: this.selectedValue,
          },
          Candlestick: {
            upCandle: {
              color: this.bgColorUp,
              fill: this.candleStickCheck1,
            },
            DownCandle: {
              color: this.bgColorDown,
              fill: this.candleStickCheck2,
            },
          },
        },
        true
      );
    },
    updateIndicator() {
      this.$emit("updateChartSettings", {}, true);
    },
    getIndicator() {
      this.$emit("getIndicators", true);
      this.$emit("getIndicators_popular", true);
    },
    getThemeidicators(value) {
      if (value == "defalse") {
        this.theme_id_now.defalse_type = true;
      } else {
        this.theme_id_now.id = value.id;
        this.theme_id_now.defalse_type = false;
      }
      this.$emit("getThemeIndicators", this.theme_id_now);
    },
  opencolorpicker(index,value){
    this.value_change_color = value
    this.index_change_color  = index
    this.is_change_color = true
    this.$bvModal.show("cp2");
  },
    closeColor(value) {
      this.$bvModal.show("cp2");
      if (value == "line1") {
        this.Line_Color = false;
        this.Line_2_Color = true;
        this.Line_3_Color = true;
        this.Line_4_Color = true;
        this.Line_5_Color = true;
        this.Line_6_Color = true;
        this.Line_7_Color = true;
        this.Line_8_Color = true;
      }
      if (value == "line2") {
        this.Line_Color = true;
        this.Line_2_Color = false;
        this.Line_3_Color = true;
        this.Line_4_Color = true;
        this.Line_5_Color = true;
        this.Line_6_Color = true;
        this.Line_7_Color = true;
        this.Line_8_Color = true;
      }
      if (value == "line3") {
        this.Line_Color = true;
        this.Line_2_Color = true;
        this.Line_3_Color = false;
        this.Line_4_Color = true;
        this.Line_5_Color = true;
        this.Line_6_Color = true;
        this.Line_7_Color = true;
        this.Line_8_Color = true;
      }
      if (value == "line4") {
        this.Line_Color = true;
        this.Line_2_Color = true;
        this.Line_3_Color = true;
        this.Line_4_Color = false;
        this.Line_5_Color = true;
        this.Line_6_Color = true;
        this.Line_7_Color = true;
        this.Line_8_Color = true;
      }
      if (value == "line5") {
        this.Line_Color = true;
        this.Line_2_Color = true;
        this.Line_3_Color = true;
        this.Line_4_Color = true;
        this.Line_5_Color = false;
        this.Line_6_Color = true;
        this.Line_7_Color = true;
        this.Line_8_Color = true;
      }
      if (value == "line6") {
        this.Line_Color = true;
        this.Line_2_Color = true;
        this.Line_3_Color = true;
        this.Line_4_Color = true;
        this.Line_5_Color = true;
        this.Line_6_Color = false;
        this.Line_7_Color = true;
        this.Line_8_Color = true;
      }
      if (value == "line7") {
        this.Line_Color = true;
        this.Line_2_Color = true;
        this.Line_3_Color = true;
        this.Line_4_Color = true;
        this.Line_5_Color = true;
        this.Line_6_Color = true;
        this.Line_7_Color = false;
        this.Line_8_Color = true;
      }
      if (value == "line8") {
        this.Line_Color = true;
        this.Line_2_Color = true;
        this.Line_3_Color = true;
        this.Line_4_Color = true;
        this.Line_5_Color = true;
        this.Line_6_Color = true;
        this.Line_7_Color = true;
        this.Line_8_Color = false;
      }
      if (value == "line") {
        this.line = false;
        this.upCandle = true;
        this.downCandle = true;
        this.weekTrend = true;
        this.speculation = true;
        this.strongUptrend = true;
        this.ma = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "upCandle") {
        this.upCandle = false;
        this.line = true;
        this.downCandle = true;
        this.weekTrend = true;
        this.speculation = true;
        this.strongUptrend = true;
        this.ma = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "downCandle") {
        this.downCandle = false;
        this.line = true;
        this.upCandle = true;
        this.weekTrend = true;
        this.speculation = true;
        this.strongUptrend = true;
        this.ma = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "weekTrend") {
        this.weekTrend = false;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speculation = true;
        this.strongUptrend = true;
        this.ma = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speculation") {
        this.speculation = false;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.strongUptrend = true;
        this.ma = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "strongUptrend") {
        this.strongUptrend = false;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.ma = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "ma") {
        this.ma = false;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.smap = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "smap") {
        this.smap = false;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.emap = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "emap") {
        this.emap = false;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.eperiod1 = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "eperiod1") {
        this.eperiod1 = false;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.eperiod2 = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "eperiod2") {
        this.eperiod2 = false;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.eperiod3 = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "eperiod3") {
        this.eperiod3 = false;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.eperiod4 = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "eperiod4") {
        this.eperiod4 = false;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.eperiod5 = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "eperiod5") {
        this.eperiod5 = false;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.eperiod6 = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "eperiod6") {
        this.eperiod6 = false;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speriod1 = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speriod1") {
        this.speriod1 = false;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speriod2 = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speriod2") {
        this.speriod2 = false;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speriod3 = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speriod3") {
        this.speriod3 = false;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speriod4 = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speriod4") {
        this.speriod4 = false;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speriod5 = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speriod5") {
        this.speriod5 = false;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.speriod6 = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "speriod6") {
        this.speriod6 = false;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.volumeUp = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "volumeUp") {
        this.volumeUp = false;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.volumeDown = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "volumeDown") {
        this.volumeDown = false;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.short = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "short") {
        this.short = false;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.long = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "long") {
        this.long = false;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.signal = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "signal") {
        this.signal = false;
        this.long = true;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.base = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "base") {
        this.base = false;
        this.signal = true;
        this.long = true;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.rsiPeriod = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "rsiPeriod") {
        this.rsiPeriod = false;
        this.base = true;
        this.signal = true;
        this.long = true;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.rsiSignal = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "rsiSignal") {
        this.rsiSignal = false;
        this.rsiPeriod = true;
        this.base = true;
        this.signal = true;
        this.long = true;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.overbought = true;
        this.oversold = true;
      }
      if (value == "overbought") {
        this.overbought = false;
        this.rsiSignal = true;
        this.rsiPeriod = true;
        this.base = true;
        this.signal = true;
        this.long = true;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
        this.oversold = true;
      }
      if (value == "oversold") {
        this.oversold = false;
        this.overbought = true;
        this.rsiSignal = true;
        this.rsiPeriod = true;
        this.base = true;
        this.signal = true;
        this.long = true;
        this.short = true;
        this.volumeDown = true;
        this.volumeUp = true;
        this.speriod6 = true;
        this.speriod5 = true;
        this.speriod4 = true;
        this.speriod3 = true;
        this.speriod2 = true;
        this.speriod1 = true;
        this.eperiod6 = true;
        this.eperiod5 = true;
        this.eperiod4 = true;
        this.eperiod3 = true;
        this.eperiod2 = true;
        this.eperiod1 = true;
        this.emap = true;
        this.smap = true;
        this.ma = true;
        this.strongUptrend = true;
        this.speculation = true;
        this.weekTrend = true;
        this.downCandle = true;
        this.line = true;
        this.upCandle = true;
      }
    },
    onChangeColor(color) {
      if(this.is_change_color == true){
        if(this.value_change_color == 'line1'){
          this.data_indicators[this.index_change_color].Line_Color_value = color
        }
        if(this.value_change_color == 'line2'){
          this.data_indicators[this.index_change_color].Line_2_Color_value = color
        }
        if(this.value_change_color == 'line3'){
          this.data_indicators[this.index_change_color].Line_3_Color_value = color
        }
        if(this.value_change_color == 'line4'){
          this.data_indicators[this.index_change_color].Line_4_Color_value = color
        }
        if(this.value_change_color == 'line5'){
          this.data_indicators[this.index_change_color].Line_5_Color_value = color
        }
        if(this.value_change_color == 'line6'){
          this.data_indicators[this.index_change_color].Line_6_Color_value = color
        }
        if(this.value_change_color == 'line7'){
          this.data_indicators[this.index_change_color].Line_7_Color_value = color
        }
        if(this.value_change_color == 'line8'){
          this.data_indicators[this.index_change_color].Line_8_Color_value = color
        }
        this.is_change_color = false
        return false
      }
      if (this.Line_Color == false) {
        this.Line_Color_value = color;
      }
      if (this.Line_2_Color == false) {
        this.Line_2_Color_value = color;
      }
      if (this.Line_3_Color == false) {
        this.Line_3_Color_value = color;
      }
      if (this.Line_4_Color == false) {
        this.Line_4_Color_value = color;
      }
      if (this.Line_5_Color == false) {
        this.Line_5_Color_value = color;
      }
      if (this.Line_6_Color == false) {
        this.Line_6_Color_value = color;
      }
      if (this.Line_7_Color == false) {
        this.Line_7_Color_value = color;
      }
      if (this.Line_8_Color == false) {
        this.Line_8_Color_value = color;
      }
      if (this.line == false) {
        this.bgColorLine = color;
      }
      if (this.upCandle == false) {
        this.bgColorUp = color;
      }
      if (this.downCandle == false) {
        this.bgColorDown = color;
      }
      if (this.weekTrend == false) {
        this.bgColorWeekTrend = color;
      }
      if (this.speculation == false) {
        this.bgColorSpeculation = color;
      }
      if (this.strongUptrend == false) {
        this.bgColorStrongUptrend = color;
      }
      if (this.ma == false) {
        this.bgColorMa = color;
      }
      if (this.smap == false) {
        this.bgColorSma = color;
      }
      if (this.emap == false) {
        this.bgColorEma = color;
      }
      if (this.eperiod1 == false) {
        this.bgColorEperiod1 = color;
      }
      if (this.eperiod2 == false) {
        this.bgColorEperiod2 = color;
      }
      if (this.eperiod3 == false) {
        this.bgColorEperiod3 = color;
      }
      if (this.eperiod4 == false) {
        this.bgColorEperiod4 = color;
      }
      if (this.eperiod5 == false) {
        this.bgColorEperiod5 = color;
      }
      if (this.eperiod6 == false) {
        this.bgColorEperiod6 = color;
      }
      if (this.speriod1 == false) {
        this.bgColorSperiod1 = color;
      }
      if (this.speriod2 == false) {
        this.bgColorSperiod2 = color;
      }
      if (this.speriod3 == false) {
        this.bgColorSperiod3 = color;
      }
      if (this.speriod4 == false) {
        this.bgColorSperiod4 = color;
      }
      if (this.speriod5 == false) {
        this.bgColorSperiod5 = color;
      }
      if (this.speriod6 == false) {
        this.bgColorSperiod6 = color;
      }
      if (this.volumeUp == false) {
        this.bgColorVolumeup = color;
      }
      if (this.volumeDown == false) {
        this.bgColorVolumedown = color;
      }
      if (this.short == false) {
        this.bgColorShort = color;
      }
      if (this.long == false) {
        this.bgColorLong = color;
      }
      if (this.signal == false) {
        this.bgColorSignal = color;
      }
      if (this.base == false) {
        this.bgColorBase = color;
      }
      if (this.rsiPeriod == false) {
        this.bgColorRsiPeriod = color;
      }
      if (this.rsiSignal == false) {
        this.bgColorRsiSignal = color;
      }
      if (this.overbought == false) {
        this.bgColorOverbought = color;
      }
      if (this.oversold == false) {
        this.bgColorOversold = color;
      }
    },
    getResetModel() {
      this.settingMenu = true;
      this.chartSetting = false;
      this.indicatorSetting = false;
      this.selectIndicator = false;
      this.ema = false;
      this.strongTren = false;
      this.trendRibbon = false;
      this.ema = false;
      this.sma = false;
      this.volume = false;
      this.macd = false;
      this.rsi = false;
      this.stochasticFast = false;
      this.stochasticSlow = false;
      this.adx = false;
      this.bolingerBrands = false;
      this.ichilmoku = false;
      this.strongTrend = false;
      this.parabolic = false;
    },
  },
};
</script>
<style lang="scss">
.option_source {
  height: 25px;
  background: #2c3b44;
  color: #ffffff;
}
.white-color {
  background-color: #ffffff;
}

.green-color {
  background-color: #3fde68;
}

.blue-color {
  background-color: #20a3ff;
}

.red-color {
  background-color: #de2d40;
}

.yellow-color {
  background-color: #f8c417;
}

.dark-yellow {
  background-color: #09BE8B;
}

.pink-color {
  background-color: #f63a6c;
}

.color-box {
  width: 18px;
  /* Adjust to your preference */
  height: 18px;
  /* Adjust to your preference */
  cursor: pointer;
  border: 2px solid transparent;
  margin-right: 5px;
  transition: border-color 0.2s ease-in-out;
}

.custom-dropdown-line select {
  background-color: #2c3b44;
  color: #ffffff;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  // margin-right: 30px;
  font-weight: 200;
  border-radius: 1px;
}

select {
  border: none;
  /* Remove the border */
  outline: none;
  /* Remove the focus outline */
}

/* Style for option elements */
.custom-dropdown-line option {
  background-color: #2c3b44;
  color: #ffffff;
  // border: none;
}

.custom-dropdown select {
  background-color: #2c3b44;
  color: #ffffff;
  border: none;
}

/* Style for option elements */
.custom-dropdown option {
  background-color: #2c3b44;
  color: #ffffff;
  border: none;
}

#settings-modal___BV_modal_content_ {
  width: 480px;
  height: 653px;
  margin-top: 76px !important;
}
.custom-select {
  // position: relative;
  width: 100%;
  // height: 40px;
  background-color: #2c3b44;
  border: none;
  border-radius: 4px;
  padding-left: 40px;
  cursor: pointer;
}

/* สไตล์ของรูปภาพ */
.custom-select img {
  position: absolute;
  // left: 10px;
  top: 58%;
  transform: translateY(0%);
  width: 24px;
  height: auto;
}

/* สไตล์ของ dropdown content */
.custom-options {
  display: none;
  position: absolute;
  background-color: #fff;
  // border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

/* แสดง dropdown content เมื่อ dropdown ถูกคลิก */
.custom-select:focus .custom-options {
  display: block;
}
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 70px;
}
.custom-select-wrapper-width {
  position: relative;
  display: inline-block;
  width: 105px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2c3b44;
  padding: 12px;
  border: 1px solid #2c3b44;
  border-radius: 4px;
  cursor: pointer;
}

.custom-select img {
  width: 10px;
  height: 7px;
}

.custom-select-text {
  // flex-grow: 1;
  font-size: 10px;
  margin-left: 15px;
}

.custom-options {
  display: none;
  position: absolute;
  background-color: #2c3b44;
  // border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  z-index: 1;
  // max-height: 150px;
  overflow-y: auto;
}

.custom-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.custom-option img {
  width: 20px;
  height: 20px;
}

.custom-option:hover {
  background-color: #2c3b44;
}
// .custom-options.show {
//   display: block;
// }

// .custom-options.hidden {
//   display: none;
// }
.custom-select-wrapper.open .custom-options {
  display: block;
}
#settings-modal___BV_modal_body_ {
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .close {
    position: absolute;
    top: 28px;
    right: 30px;
    cursor: pointer;
  }

  .setting-item {
    position: relative;
    border-bottom: 1px solid #28363e;
    padding-bottom: 16px;
    padding-top: 11px;
    cursor: pointer;
    margin-right: 5px;

    p {
      margin: 0;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #d6dde1;
    }

    span {
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  .chart-setting {
    padding-bottom: 20px;
    .cs-title {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #d6dde1;
      margin: 0;
      border-bottom: 1px solid #28363e;
      padding-bottom: 17px;
    }

    .s-i {
      width: 368px;
      margin: auto;

      p {
        margin-bottom: 0;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        padding-top: 16px;

        span {
          float: right;
          // width: 48px;
          height: 24px;
          background-color: #2c3b44;
          border-radius: 2px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 5px;
          }

          .sc {
            width: 19px;
            height: 18px;
            border: none;
            outline: none;
          }

          .dropdown-c {
            position: relative;
          }
        }
      }
    }
  }

  .select-container-settings {
    background-color: #191b1c;
    color: #d6dde1;
    border-radius: 4px;

    height: 24px;
    line-height: 24px;
    margin: auto 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .icon {
      pointer-events: none;
      position: absolute;
      right: 12px;
    }

    .rotate-sc-icon {
      transform: rotateX(180deg);
    }

    .text {
      padding-left: 15px;
    }

    .options {
      position: absolute;
      bottom: -82px;
      width: 100%;
      background-color: #191b1c;
      border-radius: 4px;
      z-index: 1;

      ul {
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 15px;
          border-radius: 4px;

          &:hover {
            background-color: #2c3b44;
          }
        }
      }
    }

    .hidden {
      display: none;
    }

    .show {
      display: block;
    }
  }

  .stf {
    p {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #d6dde1;
    }
  }

  .csf {
    border-top: 1px solid #28363e;
    margin-top: 35px;
    padding-top: 25px;

    p {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #09BE8B;
      cursor: pointer;
    }

    .csfb {
      margin-top: 67px;
      text-align: center;

      button {
        width: 156px;
        height: 40px;
        font-family: "Sarabun";
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        border-radius: 4px;
        margin-left: 8px;
      }

      button.b-o {
        background-color: #09BE8B;
        color: #0a0d10;
        font-weight: 500;
      }

      button.b-g {
        background-color: #191b1c;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }

  .csfi {
    // border-top: 1px solid #28363e;
    margin-top: 35px;
    // padding-top: 25px;

    p {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #09BE8B;
      cursor: pointer;
    }

    .csfb {
      margin-top: 67px;
      text-align: center;

      button {
        width: 156px;
        height: 40px;
        font-family: "Sarabun";
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        border-radius: 4px;
        margin-left: 8px;
      }

      button.b-o {
        background-color: #09BE8B;
        color: #0a0d10;
        font-weight: 500;
      }

      button.b-g {
        background-color: #191b1c;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }

  .indicator-items {
    margin-left: 25px;
    margin-right: 25px;

    label {
      margin-bottom: 0;

      span {
        margin-left: 8px;
        position: relative;
        top: 2px;
      }
    }

    // .color-badge {
    //   cursor: pointer;
    //   width: 48px;
    //   height: 24px;
    //   background-color: #2c3b44;
    //   border-radius: 2px;
    //   display: inline-flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   top: 3px;
    //   svg:nth-child(1) {
    //     position: relative;
    //     right: 2px;
    //   }
    //   svg:nth-child(2) {
    //     position: relative;
    //     left: 2px;
    //   }
    // }
    .bp {
      border-top: 1px solid #28363e;
      margin-top: 20px;
      margin-top: 20px;
      padding-top: 26px;

      p {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
      }
    }

    .view-color {
      width: 357px;
      height: 16px;
      border-radius: 4px;
      background: #fbf330;
      margin: auto;
      margin-top: 24px;
    }

    .pt {
      width: 88px;
      height: 24px;
      background-color: #2c3b44;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 24px;
    }
    .pt-line {
      width: 88px;
      height: 14px;
      background-color: #2c3b44;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 24px;
    }

    .cc {
      width: 357px;
      margin: auto;
      margin-top: 16px;

      .ct {
        width: 72px;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
      }

      .cs {
        width: 230px;
      }

      .cv {
        background: #191b1c;
        border-radius: 4px;
        width: 55px;
        height: 24px;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #d6dde1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    .count {
      width: 55px;
      height: 24px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2c3b44;
      margin-left: 98px;

      input {
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
      }
    }
  }

  .color-badge {
    cursor: pointer;
    width: 48px;
    height: 27px;
    background-color: #2c3b44;
    border-radius: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0px;

    svg:nth-child(1) {
      position: relative;
      right: 2px;
    }

    svg:nth-child(2) {
      position: relative;
      left: 2px;
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

/* สไตล์ของปุ่ม dropdown */
.dropdown-toggle {
  background-color: #f1f1f1;
  color: black;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

/* สไตล์ของ dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* สไตล์ของ dropdown content item */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* แสดง dropdown content เมื่อ hover หรือ focus */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:focus-within .dropdown-content {
  display: block;
}

/* สไตล์ของไอคอนดาว */
.star-icon {
  width: 20px;
  height: 20px;
  fill: gold;
  margin-right: 5px;
}
input[type="checkbox"] {
  background: #191b1c !important;
  border: 1px solid #3c515d !important;
  border-radius: 2px !important;
  height: 17px !important;
  width: 17px !important;
}

input[type="checkbox"]:checked {
  background-color: #09BE8B !important;
  border: 1px solid #3c515d !important;
}
</style>
