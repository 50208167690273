<template>
  <div>
    <b-modal
      class="price-alert-2"
      id="price-alert-2"
      :hide-footer="true"
      :hide-header="true"
      @shown="getAlertlist()"
    >
      <div class="price-alert-2">
        <h2>
          แจ้งเตือนราคา {{ currentCoin.symbol.split("/")[0] }}
          <!-- <span class="info-icon"
            ><svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                fill="#677F8E"
              />
            </svg>
          </span> -->
        </h2>
        <router-link to="/ProfileSetting/SetAlerts">
          <button class="aa">
            <svg
              width="12"
              height="15"
              viewBox="0 0 12 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.67333 13.0067C4.67333 13.74 5.26667 14.3333 6 14.3333C6.73333 14.3333 7.32667 13.74 7.32667 13.0067H4.67333ZM6 3C7.84 3 9.33333 4.49333 9.33333 6.33333V11H2.66667V6.33333C2.66667 4.49333 4.16 3 6 3ZM6 0C5.44667 0 5 0.446667 5 1V1.78C2.90667 2.23333 1.33333 4.1 1.33333 6.33333V10.3333L0 11.6667V12.3333H12V11.6667L10.6667 10.3333V6.33333C10.6667 4.1 9.09333 2.23333 7 1.78V1C7 0.446667 6.55333 0 6 0Z"
                fill="#677F8E"
              />
            </svg>
            All Alerts
          </button>
        </router-link>
        <span class="close" @click="$bvModal.hide('price-alert-2')"
          ><svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <div class="info">
          <span class="pair-f">{{ currentCoin.symbol.split("/")[0] }}</span>
          <span class="pair-l"> / {{ currentCoin.symbol.split("/")[1] }}</span>
          <span class="v-f" :class="priceInfo.pChg > 0 ? 'green' : 'red'">{{ $MyFunc.NumberFormat(priceInfo.last) }}</span>
          <span class="v-l" :class="priceInfo.pChg > 0 ? 'green' : 'red'">({{ $MyFunc.NumberFormat(priceInfo.pChg) }}%)</span>
        </div>
        <div class="cb-content">
          <div v-for="(alert, index) in alertsList" :key="index">
            <p class="cbt">{{ showTextCondition(alert.conditionType) }}</p>
            <p class="cbv">{{ calPriceAlert(alert.conditionType, alert.conditionValue, alert.basePrice) }}</p>
            <p class="cbr">{{ showTextTrigger(alert.triggerType) }}</p>
            <toggle-button
              @change="updateAlert(alert.id, alert.isEnable)"
              :value="alert.isEnable"
              :key="alert.isEnable"
              :color="{ checked: '#0c9' }"
              :height="24"
              :width="55"
              class="mb-0"
              :labels="{ checked: 'ON', unchecked: 'OFF' }"
            />
            <span class="di" @click="showDeleteModal(alert.id)"
              ><svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.3 17.5C2.8 17.5 2.375 17.325 2.025 16.975C1.675 16.625 1.5 16.2 1.5 15.7V3H0.5V1.5H5V0.625H11V1.5H15.5V3H14.5V15.7C14.5 16.2 14.325 16.625 13.975 16.975C13.625 17.325 13.2 17.5 12.7 17.5H3.3ZM13 3H3V15.7C3 15.7833 3.02933 15.8543 3.088 15.913C3.146 15.971 3.21667 16 3.3 16H12.7C12.7667 16 12.8333 15.9667 12.9 15.9C12.9667 15.8333 13 15.7667 13 15.7V3ZM5.4 14H6.9V5H5.4V14ZM9.1 14H10.6V5H9.1V14ZM3 3V16V15.7V3Z"
                  fill="#09BE8B"
                />
              </svg>
            </span>
          </div>

          <div v-if="alertsList.length < 1">
            <p class="text-blank">--ไม่พบข้อมูล--</p>
          </div>

          <div v-if="isLoading">
            <b-skeleton
              v-for="(item, index) in 6"
              :key="index"
              class="skc custom-skeleton-wave mb-3"
              animation="wave"
              width="100%"
            ></b-skeleton>
          </div>
        </div>
        <button
          @click="
            $bvModal.hide('price-alert-2');
            $emit('createAlertPopup', true);
          "
          class="clb"
        >
          + เพิ่มการแจ้งเตือน
        </button>
      </div>
    </b-modal>
    <DeleteModal :id="selectedId" @deleteDone="deleteDone" />
  </div>
</template>
<script>
import DeleteModal from "./DeleteModal.vue";
import { HTTP } from "@/config/axios";
export default {
  props: ["currentCoin", "priceInfo"],
  components: { DeleteModal },
  data() {
    return {
      show_p2_options: false,
      selected_p2_option: "เตือนวันละ 1 ครั้ง",
      alertsList: [],
      selectedId: 0,
      isLoading: true,
    };
  },
  methods: {
    getAlertlist() {
      HTTP.get("/uc/member/get-all-coin-price-alert")
        .then((res) => {
          this.isLoading = false;
          // Filter and assign the matching objects to this.alertsList
          this.alertsList = res.data.data
            .filter((item) => Object.keys(item)[0] === this.currentCoin.symbol)
            .map((item) => item[this.currentCoin.symbol])
            .flat(); // Flatten the array of arrays into a single array
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showDeleteModal(id) {
      this.selectedId = id;
      this.$bvModal.show("del-modal");
    },
    deleteDone(e) {
      // console.log(e);
      const index = this.alertsList.findIndex((item) => item.id === e);
      if (index !== -1) {
        this.alertsList.splice(index, 1);
      }
    },
    showTextCondition(conditionType){
      if(conditionType == 0){
        return "แจ้งเตือนเมื่อราคาถึง";
      }else if(conditionType == 1 || conditionType == 2){
        return "แจ้งเตือนเมื่อราคาเปลี่ยนแปลง";
      }else{
        return '';
      }
    },
    calPriceAlert(conditionType, conditionValue, basePrice){
      if(conditionType == 0){
        return `${this.$MyFunc.NumberFormat(conditionValue)} THB`;
      }else if(conditionType == 1){
        return `+${(((conditionValue - basePrice) / basePrice) * 100).toFixed(0)}% (${this.$MyFunc.NumberFormat(conditionValue)} THB)`;
      }else if(conditionType == 2){
        return `-${(((basePrice - conditionValue) / basePrice) * 100).toFixed(0)}% (${this.$MyFunc.NumberFormat(conditionValue)} THB)`;
      }else{
        return '';
      }
    },
    showTextTrigger(triggerType){
      if(triggerType == 0){
        return "เตือนวันละ 1 ครั้ง";
      }else if(triggerType == 1){
        return "เตือนครั้งเดียว";
      }else if(triggerType == 2){
        return "เตือนทุกครั้งเมื่อถึงเงื่อนไข";
      }else{
        return '';
      }
    },
    updateAlert(Id, Value){
      HTTP.post("/uc/member/update-coin-price-alert",
      {
        id: Id,
        isEnable: Value ? 0 : 1,
      })
        .then((res) => {
          this.getAlertlist();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
<style lang="scss">
#price-alert-2___BV_modal_content_ {
  width: 656px;
  min-height: 448.5px;
  background-color: #141b1f;
  margin-top: 140px !important;
  // padding-left: 16px;
  // padding-right: 16px;
}
#price-alert-2___BV_modal_body_ {
  padding: 0 !important;
  background-color: transparent !important;
}
.info-icon {
  position: relative;
  left: 3px;
  top: -1px;
}
.price-alert-2 {
  position: relative;
  padding-bottom: 30px;
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    padding-top: 24px;
  }
  button.aa {
    position: absolute;
    top: 25px;
    left: 10px;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #28363e;
    color: #677f8e;
    border-radius: 4px;
    padding: 5px 10px;
    svg {
      margin-right: 3px;
    }
  }
  span.close {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 16px;
  }
  .info {
    margin-top: 53px;
    padding-left: 13px;
    span.pair-f {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
    span.pair-l {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #9bacb6;
    }
    span.v-f {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-left: 18px;
    }
    span.v-l {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-left: 7px;
    }
    .red {
      color: #de2d40;
    }
    .green {
      color: #0c9;
    }
  }
  .c-content {
    border-top: 1px solid #28363e;
    border-bottom: 1px solid #28363e;
    margin-top: 22px;
    .p1-sc {
      position: relative;
      width: 220px;
      height: 40px;
      background-color: #191b1c;
      display: inline-block;
      margin-left: 24px;
      border-radius: 4px;
      .text {
        width: 100%;
        height: 100%;
        padding-left: 8px;
        padding-top: 6px;
        display: inline-block;
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgb(255, 255, 255);
        cursor: pointer;
      }

      svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
      .options {
        position: absolute;
        left: 0;
        background-color: #191b1c;
        width: 100%;
        text-align: left;
        top: 42px;
        border-radius: 4px;
        z-index: 20;
        ul {
          padding: 0;
          margin: 0;
          li {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 40px;
            padding-left: 5px;
            color: #677f8e;
            border-radius: 4px;
            &:hover {
              color: white;
              background-color: #2c3b44;
            }
          }
        }
      }
    }
    span.text {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #9bacb6;
    }
    .input-c {
      position: relative;
      span.symbol {
        position: absolute;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
      input {
        width: 220px;
        height: 40px;
        background-color: #191b1c;
        margin-left: 91px;
        border-radius: 4px;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgb(255, 255, 255);
        text-align: right;
        padding-left: 12px;
        padding-right: 42px;
      }
    }
  }
  .cb-content {
    max-height: 300px;
    overflow: auto;
    border-top: 1px solid #28363e;
    border-bottom: 1px solid #28363e;
    margin-top: 22px;
    padding-top: 26px;
    // padding-bottom: 28px;
    padding-left: 13px;
    padding-bottom: 10px;
    .cbt {
      margin-bottom: 0;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #9bacb6;
      margin-bottom: 12px;
      display: inline-block;
      width: 190px;
    }
    .cbv {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgb(255, 255, 255);
      display: inline-block;
      margin-bottom: 0;
      width: 179px;
    }
    .cbr {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgb(255, 255, 255);
      display: inline-block;
      width: 166px;
    }
    .h {
      position: relative;
      // top: 20px;
    }
    .di {
      cursor: pointer;
      margin-left: 19px;
    }
  }
  .clb {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0a0d10;
    background-color: #09BE8B;
    border-radius: 4px;
    width: 141px;
    height: 36px;
    display: block;
    margin: 55px auto 0 auto;
  }
  .text-blank {
    font-family: "Sarabun";
    font-style: normal;
    font-size: 14px;
    color: #9bacb6;
    text-align: center;
  }
}
</style>
