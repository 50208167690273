<template>
  <div>
    <!-- cash withdraw modal -->
    <transition name="modal">
      <div :class="wcd ? 'wcd' : 'modal-mask'">
        <div class="modal-wrapper">
          <div
            class="modal-container"
            style="margin-top: -161px"
            :style="[wcd ? { 'margin-top': '-161px' } : { '': '' }]"
          >
            <div class="modalHeading">
              <label v-if="whitelist"
                >ลงทะเบียน Whitelist
                <!-- <span
                  ><svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg> </span> -->
                </label>
              <label v-else-if="wcd"
                >ยืนยันการถอนเหรียญ
                <span
                  ><svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg> </span
              ></label>
              <label v-else>
                ยืนยันการถอนเงิน
                <span
                  ><svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </label>
              <span @click="closeModel()">&times;</span>
            </div>
            <!-- further heading -->
            <div class="modalHeadingWithdraw">
              <!-- :class="wcd ? 'noto-sans-thai-font' : 'thai-font'" -->
              <label
                class="noto-sans-thai-font"
                style="
                  color: #9bacb6;
                  font-size: 16px;
                  font-weight: 400;
                  text-align: center;
                "
              >
                ระบุ OTP ที่ไดัรับจาก SMS ({{
                 $MyFunc.formatPhone(user.mobile)
                }}) <br />
                Ref Code : {{ refCode }}
              </label>
            </div>
            <!-- OTP INPUT FIELD -->
            <div class="OTPInput">
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit1"
                ref="input1"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                autofocus
                @keydown.enter="onOTPSubmitted"
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit2"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                autofocus
                @keydown.enter="onOTPSubmitted"
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit3"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                autofocus
                @keydown.enter="onOTPSubmitted"
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit4"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                autofocus
                @keydown.enter="onOTPSubmitted"
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit5"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                autofocus
                @keydown.enter="onOTPSubmitted"
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <input
                type="number"
                maxlength="1"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @input="next"
                v-model="otp_code.digit6"
                v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                autofocus
                @keydown.enter="onOTPSubmitted"
                onClick="this.select();"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
            </div>
            <!-- buttons -->
            <div class="flexCoinWithdraw">
              <button class="btn1" @click="onOTPSubmitted()">ยืนยัน</button>
            </div>
            <div class="bottomLine text-center">
              <label>
                กรุณาตรวจสอบรหัส OTP บนโทรศัพท์มือถือของท่าน
                ภายใน 5 นาที หากท่านไม่ได้รับรหัส OTP
                <span @click="resendOtp" class="text-primary" role="button">กรุณาคลิกที่นี่ เพื่อขอรับรหัส OTP ใหม่อีกครั้ง</span>
                <template v-if="otp_delay > 0"> in {{ otp_delay / 1000 }} second</template><br>
                กรณีไม่สามารถยืนยัน OTP ได้ โทร. {{ CALLCENTER }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- cash withdraw done modal -->
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
export default {
  name: "model",
  props: [
    "wcd",
    "whitelist",
    "createWalletData",
    "wallet",
    "withdrawAmount",
    "refCode",
  ],
  data() {
    return {
      showWithdrawOTPModal: true,
      otp_lock: true,
      otp_delay: 0,
      otp_delayTime: 120000,
      otp_code: {
        digit1: null,
        digit2: null,
        digit3: null,
        digit4: null,
        digit5: null,
        digit6: null,
      },
      withdrawData: [],
      user: {
        mobile: "",
      },
    };
  },
  directives: {
    focusOnBackspace: {
      inserted(el, binding) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "Backspace" && event.target.value === "") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnLeftArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowLeft") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnRightArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowRight") {
            event.preventDefault();
            const nextInput = el.nextElementSibling;
            if (nextInput) {
              nextInput.select();
            }
          }
        });
      },
    },
  },
  created() {
    this.onModalShown();
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    this.user.mobile = user_data?.mobile || "";
  },
  methods: {
    next(e) {
      if (e.data == null) {
        e.target?.previousSibling?.select();
      } else {
        e.target?.nextSibling?.select();
      }
    },
    onOTPSubmitted() {
      //debugger
      let self = this;
      let otp =
        this.otp_code.digit1 +
        this.otp_code.digit2 +
        this.otp_code.digit3 +
        this.otp_code.digit4 +
        this.otp_code.digit5 +
        this.otp_code.digit6;

      HTTP.post("/uc/validateOtp", {
        verificationCode: otp,
        refCode: this.refCode,
      })
        .then((res) => {
          //console.log(res);
          self.$notify({
            group: "notification",
            type:
              res.data.message == "Successfully validated the user"
                ? "success"
                : "error",
            title:
              res.data.message == "Successfully validated the user"
                ? "Success"
                : "Otp validation Error",
            text: res.data.message,
          });
          if (res.data.message == "Successfully validated the user") {
            this.showWithdrawOTPModal = false;
            this.$emit("closeModel");
            this.$emit("onOTPSubmitted");
            //debugger

            // creating wallet
            if (this.createWalletData) {
              //console.log(this.createWalletData + "sdffddf");
              HTTP.post("/uc/member/create-member-whitelist", {
                coinId: this.createWalletData.coinName,
                address: this.createWalletData.wAdress,
                memo: this.createWalletData.memo,
                walletName: this.createWalletData.walletName,
                memberWallet: this.createWalletData.walletId,
                status: 1,
                coinNetworkId: this.createWalletData.coinNetworkId,
              })
                .then((res) => {
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (this.wcd) {
              console.log("Otp Model this.wcd");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resendOtp() {
      if (!this.otp_lock) {
        /* this.otp_delay = this.otp_delayTime;
        this.otpDelay(); */
        let self = this;
        HTTP.post("/uc/resendOtp")
          .then((res) => {
            if (res.data.code == 0) {
              self.$emit("UpdateRefCode", res.data.data);
              self.otp_lock = true;
              self.onModalShown();
            }
            self.$notify({
              group: "notification",
              type: res.data.code == "0" ? "success" : "error",
              title:
                res.data.code == "0"
                  ? "OTP Send to Mobile Successful"
                  : "Unable to Send OTP to Mobile",
              text: res.data.message,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "ไม่สามารถส่งได้",
          text: "กรุณารอเป็นเวลา " + this.otp_delay / 1000 + " วินาที",
        });
      }
    },
    onModalShown(){
      this.otp_delay = this.otp_delayTime;
      this.otpDelay();
      this.$nextTick(() => {
        this.$refs.input1.focus();
      });
    },
    otpDelay() {
      this.otpTimeCount();
      setTimeout(() => {
        this.otp_lock = false;
      }, this.otp_delayTime);
    },
    otpTimeCount() {
      if (this.otp_delay > 0) {
        setTimeout(() => {
          this.otp_delay -= 1000;
          if (this.otp_delay > 0) {
            this.otpTimeCount();
          }
        }, 1000);
      }
    },
    closeModel() {
      this.showWithdrawOTPModal = false;
      this.$emit("closeModel");
    },
  },
};
</script>
<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
  // margin-top: -10px;
  padding-top: 50px;
}
.wcd {
  position: absolute;
  z-index: 9998;
  top: -120%;
  left: -140%;
  width: 380%;
  height: 350%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
  margin-top: -100px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  max-width: 424px;
  font-family: "Sarabun" !important;
  background: #141b1f;
  border-radius: 8px 8px 8px 8px;
  margin: 0px auto;
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0px 25px;
  line-height: 24px;
  margin-top: 24px;
}
.modalHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.modalHeading > span {
  position: absolute;
  right: 5px;
  top: 27px;
  font-size: 30px;
  color: rgb(155, 172, 182);
  cursor: pointer;
}
.modalHeading label {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  color: rgb(255, 255, 255);
  margin-top: 28px;
  margin-bottom: 30px;
}
.modalHeadingWithdraw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.OTPInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 16px;
  input {
    caret-color: #f09BE8B38220;
  }
}
.flexCoinWithdraw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bottomLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
  margin-top: 22px;
}
.bottomLine label {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
}
.flexCoinWithdraw .btn1 {
  background-color: #09BE8B;
  margin-top: 40px;
  padding: 0px 16px;
  color: #0a0d10;
  width: 344px;
  height: 44px;
  cursor: pointer;
  border-radius: 4px;
  border-style: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.OTPInput input {
  width: 44px;
  height: 56px;
  background-color: #191b1c;
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  font-size: 30px;
  border-style: none;
  outline: none;
}
.l-info {
  color: #677f8e;
  font-size: 14px;
  text-align: center;
  display: block;
  line-height: 24px;
  margin-top: 32px;
  padding-bottom: 28px;
}
</style>
