<template>
  <div>
    <b-modal
      class="qr-camera"
      id="qr-camera"
      :hide-footer="true"
      :hide-header="true"
      @shown="isScanning = true">
    
      <div>
         <!-- <button @click="isScanning = true">Start Scanning</button> -->

    <qrcode-stream @decode="onDecode" @init="onInit" v-if="isScanning"></qrcode-stream>

    <!-- <p>{{ result }}</p> -->
      </div>
    </b-modal>
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: { QrcodeStream },
  data() {
    return {
         isScanning: false,
      result: null,
    };
  },
//  mounted() {
//     this.isScanning = true
//  },
  methods: {
     onDecode(result) {   
      this.result = result;
      this.isScanning = false;
    this.$emit("qrCode" , result);
    if (result) {
        this.$bvModal.hide("qr-camera");
         this.$notify({
                group: "notification",
                type: "success",
                title: "Success",
                text: "Qr fetched Successfully",
              });
    }
    },
    onInit(promise) {
  promise.then(() => {
    console.log('Initialization finished, ready to decode QR codes')
  })
  .catch((error) => {
    this.$bvModal.hide("qr-camera");
    if (error.name === 'NotAllowedError') {
      // user denied camera access permisson
      this.isScanning = false
      alert('Camera access was denied');
    } else if (error.name === 'NotFoundError') {
      // no suitable camera device installed
      this.isScanning = false
      alert('No suitable camera device installed');
    } else if (error.name === 'NotSupportedError') {
      // page is not served over HTTPS (or localhost)
      this.isScanning = false
      alert('Page is not served over HTTPS (or localhost)');
    } else if (error.name === 'NotReadableError') {
      // maybe camera is already in use
      this.isScanning = false
      alert('Camera might already be in use');
    } else if (error.name === 'OverconstrainedError') {
      // passed constraints don't match any camera. Did you requested the front camera although there is none?
      this.isScanning = false
      alert('Requested camera is not found');
    } else {
      // browser is probably lacking features (WebRTC, Canvas)
      this.isScanning = false
      alert('Browser is probably lacking necessary features (WebRTC, Canvas)');
    }
  })
},
  },
};
</script>
<style lang="scss">

</style>
