<template>
  <div>
    <b-modal
      class="qr-success-modal"
      id="qr-success-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="d-flex justify-content-center" style="margin-top: 98px">
        <img src="@/assets/images/wallet/tick-svg.png" alt="tick-image" />
        <span class="cross-i" @click="$bvModal.hide('qr-success-modal')"
          ><svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#677F8E"
            />
          </svg>
        </span>
      </div>
      <div class="m-center">
        <p class="c-title noto-sans-thai-font">บันทึก QR Code สำเร็จ</p>
        <p class="c-value">นำ QR Code</p>
        <p class="c-value">ที่ได้รับไปสแกนจากแอปพลิเคชั่นต้นทาง</p>
        <p class="c-value">เพื่อฝากเหรียญเข้าสู่ Portfolio ของท่าน</p>
      </div>
      <div class="d-flex mt-3 justify-content-center">
        <button
          v-if="showStatusButton"
          @click="onCloseSuccessModel(1)"
          class="btn-yellow"
        >
          ตรวจสอบสถานะการถอน
        </button>
      </div>
      <div class="d-flex justify-content-around" style="margin-top: 20px">
        <button @click="onCloseSuccessModel(2)" class="btn-blue">
          เสร็จสิ้น
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CoinStatusModel from "./CoinStatusModel.vue";
export default {
  components: { CoinStatusModel },
  props: {
    showStatusButton: { type: Boolean, default: false },
  },
  methods: {
    onCloseSuccessModel(n) {
      if (n === 1) {
        this.$emit("onShowStatus");
      } else {
        this.$emit("onCloseSuccessModel");
      }
      this.$bvModal.hide("qr-success-modal");
    },
  },
};
</script>
<style lang="scss">
#qr-success-modal___BV_modal_content_ {
  background-color: #141b1f;
  min-height: 538px;
  width: 480px;
  margin-top: 100px !important;
  line-height: 24px;
  font-family: "Noto Sans Thai";
  #qr-success-modal___BV_modal_body_ {
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
    }
    .m-center {
      text-align: center;
      margin-top: 28px;
      p {
        margin: 0;
      }
      .c-title {
        color: #d6dde1;
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
      }
      .c-value {
        color: #677f8e;
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }
    .btn-blue {
      color: #fff;
      background-color: #38454d;
      border-radius: 4px;
      height: 44px;
      width: 163px;
      font-family: "Noto Sans Thai";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      &:hover {
        background-color: #283238 !important;
      }
    }
    .btn-yellow {
      color: #0a0d10;
      background-color: #09BE8B;
      border-radius: 4px;
      height: 36px;
      min-width: 132px;
      &:hover {
        color: #0a0d10;
        background-color: #66cdb0 !important;
      }
    }
  }
}
.cross-i {
  position: absolute;
  right: 30px;
  top: 42px;
  cursor: pointer;
}
</style>
