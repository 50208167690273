<template>
  <div>
    <div
      class="deposite-cash-container"
      :class="currentSettings === 'qr' ? 'qr-h' : 'bc-h'"
    >
      <!-- top section start -->
      <div class="dcc-head">
        <div class="col-12" style="display: inline-flex">
          <router-link to="/deposit/cash" :style="DepositCashDisplay"
            ><button :class="ActiveDepositCash">
              {{ $t('depositcash') }}
            </button></router-link
          >
          <router-link to="/deposit/coin" :style="DepositCoinDisplay"
            ><button :class="ActiveDepositCoin">
              {{ $t('deposistcoin') }}
            </button></router-link
          >
        </div>
      </div>
      <!-- top section end -->
      <!-- center section start -->
      <div
        v-if="!reslockStatusDepositCash"
        class="row center-section"
        style="margin-top: 24px"
      >
        <div class="col-12 p-lg-0">
          <div class="row margin-l-24">
            <div
              class="col-5 col-md-4 my-auto thai-font p-0 l-24"
              style="width: 142px"
            >
            {{ $t('choicedeposit') }}
            </div>
            <div class="col-7 col-md-8">
              <div
                class="select-container"
                tabindex="0"
                @blur="open_dropdown = false"
              >
                <!-- @click="open_dropdown = !open_dropdown" -->
                <span class="text">{{ selected_option }}</span>
                <span :class="open_dropdown ? 'rotate-sc-icon icon' : 'icon'">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <div :class="open_dropdown ? 'show options' : 'hidden options'">
                  <ul>
                    <!-- <li
                      @click="
                        (selected_option = 'Bank Transfer'),
                          (currentSettings = 'bank_transfer')
                      "
                    >
                      Bank Transfer
                    </li> -->
                    <li
                      @click="
                        (selected_option = 'QR Code (Prompt Pay)'),
                          (currentSettings = 'qr'),
                          CheckQRCode()
                      "
                    >
                      QR Code (Prompt Pay)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <form @submit.prevent="sendQrPayment">
            <div
              class="row margin-l-24"
              v-if="currentSettings === 'qr'"
              style="margin-top: 24px"
            >
              <div
                class="col-5 col-md-4 my-auto thai-font p-0 l-24"
                style="width: 142px"
              >
              {{ $t('depositamount') }}
              </div>
              <div class="col-7 col-md-8 input-container">
                <cleave
                  ref="inputamount"
                  v-model="cashDepositAmount"
                  :options="options"
                  placeholder="0.00"
                ></cleave>
                <span style="margin-left: -40px">THB</span>
              </div>
            </div>
            <div
              class="row margin-l-24"
              v-if="currentSettings === 'qr'"
              style="margin-top: 24px"
            >
              <!-- style="margin-top: 24px" -->
              <div
                class="col-5 col-md-4 my-auto thai-font p-0 l-24"
                style="width: 142px"
              >
              {{ $t('bank') }}
              </div>
              <div class="col-7 col-md-8 input-container">
                <!-- <input
                  type="text"
                  v-model="NewBank.AccountNumber"
                  minlength="10"
                  maxlength="14"
                  style="width: 240px;"
                  placeholder="ระบุข้อมูล"
                  @input="validateInput"
                /> -->
                <div
                  ref="baDropdown"
                  class="col-7 col-md-8 input-container"
                  style="line-height: 24px; position: relative; width: 240px"
                  @click="open_Banks_Dropdown = true"
                  @blur="open_Banks_Dropdown = false"
                  tabindex="0"
                >
                  <input
                    type="text"
                    style="cursor: pointer"
                    v-model="searchBank"
                    @change="removeBank"
                    @input="validateInput"
                  />
                  <span
                    v-if="searchBank.length == 0"
                    style="
                      position: absolute;
                      left: 42px;
                      top: 7px;
                      color: #566a76;
                      pointer-events: none;
                    "
                  >
                  {{ $t('choicebank') }}
                  </span>
                  <span>
                    <img src="@/assets/svg/arrow-down.svg" role="button" />
                  </span>
                  <div
                    style="width: 240px"
                    class="bank-options"
                    :class="open_Banks_Dropdown ? 'show' : 'hidden'"
                  >
                    <ul
                      style="
                        max-height: 200px;
                        position: relative;
                        z-index: 999;
                        overflow: auto;
                      "
                    >
                      <li
                        v-for="(item, index) in filteredListBanks"
                        :key="index"
                        @click.stop="
                          searchBank = item.bankName;
                          NewBank.selectedBankID = item.bankId;
                          (open_Banks_Dropdown = false), check_bank_member();
                        "
                      >
                        {{ item.bankName }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row margin-l-24"
              v-if="currentSettings === 'qr'"
              style="margin-top: 24px"
            >
              <div
                class="col-5 col-md-4 my-auto thai-font p-0 l-24"
                style="width: 142px"
              >
              {{ $t('accountbank') }}
              </div>
              <div class="col-7 col-md-8 input-container">
                <!-- <input
                  type="text"
                  v-model="NewBank.AccountNumber"
                  minlength="10"
                  maxlength="14"
                  style="width: 240px"
                  placeholder="ระบุเลขที่บัญชี"
                  @input="validateInput"
                /> -->
                <div
                  ref="baDropdown1"
                  class="col-7 col-md-8 input-container"
                  style="line-height: 24px; position: relative; width: 240px"
                  @click="open_Banks_Dropdown1 = true"
                  @blur="open_Banks_Dropdown1 = false"
                  tabindex="0"
                >
                  <input
                    type="number"
                    style="cursor: pointer"
                    v-model="NewBank.AccountNumber"
                    :placeholder="$t('accountinput')"
                  />
                  <span v-if="dropdown_acc_data.length > 0">
                    <img src="@/assets/svg/arrow-down.svg" role="button" />
                  </span>
                  <div
                    style="width: 240px"
                    class="bank-options1"
                    :class="open_Banks_Dropdown1 ? 'show' : 'hidden'"
                  >
                    <ul
                      style="
                        max-height: 200px;
                        position: relative;
                        z-index: 999;
                        overflow: auto;
                      "
                    >
                      <li
                        v-for="(item, index) in dropdown_acc_data"
                        :key="index"
                        @click.stop="
                          NewBank.AccountNumber = item;
                          open_Banks_Dropdown1 = false;
                        "
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 margin-l-24" v-if="currentSettings === 'qr'">
              <div class="col-12 info thai-font p-0">
                <p>   {{ $t('depositterm') }}</p>
                <ul
                  class="p-0 margin-l-24 mt-1"
                  style="margin-bottom: 20px; font-weight: 400; color: #9bacb6"
                >
                  <li>
                    {{ $t('depositterm1') }}
                  </li>
                  <li> {{ $t('depositterm2') }}</li>
                  <li> {{ $t('depositterm3') }}</li>
                </ul>
              </div>
            </div>
            <div
              class=" "
              style="line-height: 24px"
              v-if="currentSettings === 'bank_transfer'"
            >
              <div
                class="row mt-4 margin-l-24 bt-sec"
                style="line-height: 24px"
              >
                <div class="col-5 col-md-4 p-0" style="width: 142px">
                  ชื่อบัญชี
                </div>
                <div class="col-7 col-md-8 lr noto-sans-thai-font">
                  {{ bank_company_name }}
                  <span
                    class="thai-font"
                    style="cursor: pointer"
                    @click="copy(bank_company_name)"
                    >คัดลอก</span
                  >
                </div>
                <div class="col-5 col-md-4 p-0 mt-3" style="width: 142px">
                  ธนาคาร
                </div>
                <div class="col-7 col-md-8 mt-3">{{ bankName }}</div>
                <div
                  class="col-5 col-md-4 p-0 mt-3 d-none"
                  style="width: 142px"
                >
                  Reference Code1
                </div>
                <div class="col-7 col-md-8 mt-3 d-none">{{ referenceOne }}</div>
                <div
                  class="col-5 col-md-4 p-0 mt-3 d-none"
                  style="width: 142px"
                >
                  Reference Code2
                </div>
                <div class="col-7 col-md-8 mt-3 d-none">{{ referenceTwo }}</div>
              </div>
            </div>
            <!-- bottom section start -->
            <div
              class="bottom-section thai-font"
              v-if="currentSettings === 'qr'"
            >
              <label>
                <input
                  class="form-check-input shadow"
                  v-model="isConfirm"
                  type="checkbox"
                />
                <span> {{ $t('allterm') }}</span>
              </label>
              <div class="last-btn">
                <button
                  type="submit"
                  class="thai-font"
                  :class="isConfirm ? '' : 'inactive'"
                  :disabled="!isConfirm"
                >
                {{ $t('confirmdeposit') }}
                </button>
              </div>
            </div>
            <!-- bottom section end -->
          </form>

          <div v-if="currentSettings === 'bank_transfer'">
            <div class="bcb"></div>
            <div class="row p-0 margin-l-24">
              <div class="col-12 bc-info thai-font p-0">
                <p>ยอมรับเงื่อนไขการฝากเงิน</p>
                <ul class="" style="line-height: 24px">
                  <li>โอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคาร)</li>
                </ul>
              </div>
            </div>
            <div class="row p-0 margin-l-24">
              <div class="col-12 bc-info thai-font p-0">
                <p>วิธีโอนเงิน</p>
                <ul class="number" style="line-height: 19px">
                  <li>ไปยัง Mobile Banking และเข้าสู่เมนู “จ่ายบิล”</li>
                  <li>ค้นหา “{{ bank_company_name }}”</li>
                  <li>
                    กรอกรหัสอ้างอิง 1 (Ref.1) เลขที่บัญชีซื้อขาย 8 หลัก
                    <!-- ด้วย
                    {{ referenceOne }} -->
                  </li>
                  <li>
                    กรอกรหัสอ้างอิง 2 (Ref.2) เลขบัตรประชาชน 13 หลัก
                    <!-- ด้วย
                    {{ referenceTwo }} -->
                  </li>
                  <li>ระบุจำนวนเงินที่ต้องการ เเละทำตามขั้นตอน</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- center section start -->
      <div v-else class="text-center" style="padding: 150px">
        คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน
      </div>

      <!-- <div class="right-info">
        <p style="color: #677f8e">คู่มือแนะนำการทำรายการฝาก</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Aug/FinansiaDA_%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%80%e0%b8%97%e0%b8%a3%e0%b8%94_Deposit_Cash.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ QR Code</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Aug/FinansiaDA_%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%80%e0%b8%97%e0%b8%a3%e0%b8%94_%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81_QR_Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการยกเลิกฝาก แบบ QR Code</a
            >
          </li>
        </ul>

        <br />
        <div id="deposit-text"></div>
      </div> -->
    </div>
    <RightInfo />
    <DepositCashModal
      :data="qrdata"
      :qrId="qrId"
      :qrFlag="qrFlag"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../config/axios";
import DepositCashModal from "./components/DepositCashModal";
import RightInfo from "../../../components/deposit-withdraw/RightInfo";
export default {
  components: { DepositCashModal, RightInfo },
  metaInfo() {
    let pageTitle = "Deposit";
    return {
      title: pageTitle,
      titleTemplate: "%s | " + this.TITLE,
    };
  },
  data() {
    return {
      open_dropdown: false,
      selected_option: "QR Code (Prompt Pay)",
      currentSettings: "qr",
      qrdata: {
        image: "",
        amount: "",
        fromAccountName: "",
        toAccountName: "",
        ref2: "",
      },
      cashDepositAmount: null,
      isConfirm: false,
      options: {
        numeral: true,
        numeralIntegerScale: 10,
        numeralDecimalScale: 2,
      },
      qrId: null,
      qrFlag: null,
      referenceOne: null,
      referenceTwo: null,
      bankName: null,
      isLoading: false,
      bank_company_name: "ฟินันเซีย ดิจิทัล แอสเซท (บี)",
      reslockStatusDepositCash: false,
      reslockStatusDepositCoin: false,
      DepositCashDisplay: "display: block;",
      DepositCoinDisplay: "display: block;",
      ActiveDepositCash: "active",
      ActiveDepositCoin: "not-active",
      ListBanks: [],
      links: [
        {
          href: "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Aug/FinansiaDA_%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%80%e0%b8%97%e0%b8%a3%e0%b8%94_Deposit_Cash.pdf",
          text: "คู่มือแนะนำการฝาก แบบ QR Code",
        },
        {
          href: "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-Bank-Transfer.pdf",
          text: "คู่มือแนะนำการฝาก แบบ Bank Transfer",
        },
        {
          href: "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Aug/FinansiaDA_%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%80%e0%b8%97%e0%b8%a3%e0%b8%94_%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81_QR_Code.pdf",
          text: "คู่มือแนะนำการยกเลิกฝาก แบบ QR Code",
        },
        {
          href: "https://example.com/new-link.pdf", // Replace with your new link
          text: "คู่มือใหม่ แบบ QR Code", // Replace with your new link text
        },
      ],
      searchBank: "",
      open_Banks_Dropdown: false,
      open_Banks_Dropdown1: false,
      ListBanks_acc: [],
      dropdown_acc_data: [],
      listtext:[],
      NewBank: {
        AccountName: "",
        SelectedBankID: "",
        SelectedBranchID: "",
        SelectedBankName: "",
        BranchName: "",
        AccountNumber: "",
      },
    };
  },
  beforeMount() {
    this.CheckQRCode();
    this.banktransfer();
  },
  watch: {
    cashDepositAmount(value) {},
  },
  methods: {
    checktext() {
      let self = this;
      HTTP.get("/uc/depositfiat/faq")
        .then(function (response) {
          if (response.data.code == "0") {
            self.listtext = response.data.data;
            if(self.listtext != "" && self.listtext != null){
              self.gettextright(self.listtext);
            }
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "checktext Successful"
                  : "Unable checktext List",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to checktextList",
            text: error,
          });
        });
      
    },
    gettextright(value) {
  // Sample data structure for value
  // value = [
  //   { text: "คำแนะนำการทำรายการฝากเงิน", url: "" },
  //   { text: "- ท่านสามารติตด่อสอบถามโดย กด\n", url: "" },
  //   { text: "  Add Line ที่นี่", url: "https://www.google.com" },
  //   { text: "- หากพบปัญหาการใช้งาน กรุณา", url: "" },
  //   { text: "   คลิก ที่นี่ เพื่อปรึกษากับเจ้าหน้าที่", url: "" }
  // ];

  const newListItem = document.createElement("div");
  newListItem.style.whiteSpace = "pre-wrap";
  let lastAnchor = null;

  value.forEach((item, index) => {
    const parts = item.text.split("\\n");
    parts.forEach((part, i) => {
      const newAnchor = document.createElement("a");
      if (item.url !== null && item.url !== "") {
        newAnchor.href = item.url;
        newAnchor.target = "_blank";
      } else {
        newAnchor.style.textDecoration = "none"; // No link if url is empty
      }
      
      newAnchor.style.lineHeight = "19px";
      newAnchor.style.color = "#677f8e";
      // newAnchor.innerHTML = part;
      // lastAnchor = newAnchor;
      // newListItem.appendChild(newAnchor);

      if (i < parts.length - 1 || (index < value.length - 1 && value[index + 1].url === "")) {
        const lineBreak = document.createElement("br");
        // newListItem.appendChild(lineBreak);
      }
    });
  });

  setTimeout(() => {
    const depositTextDiv = document.getElementById("deposit-text");
    // depositTextDiv.innerHTML = "";
    // depositTextDiv.appendChild(newListItem);
  }, 100);
},

    getBankList() {
      let self = this;
      HTTP.get("/uc/member/get-bank-ref")
        .then(function (response) {
          if (response.data.code == "0") {
            self.ListBanks = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Member Banks Successful"
                  : "Unable to Get Banks List",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Banks List",
            text: error,
          });
        });
    },
    getbanklasted() {
      var self = this;
      HTTP.post("/uc/depositfiat/bank-account-lasted")
        .then(function (response) {
          if (response.data.code == "0") {
            if (response.data.data.length > 0) {
              self.NewBank.AccountNumber = response.data.data[0].bankAccount;
              self.NewBank.selectedBankID = response.data.data[0].bankCode;
              self.searchBank = response.data.data[0].nameTh;
            }
          } else {
            // self.$notify({
            //   group: "notification",
            //   type: response.data.code == "0" ? "success" : "error",
            //   title:
            //     response.data.code == "0"
            //       ? "Member Banks Successful"
            //       : "Unable to Get Banks List",
            //   text: response.data.message,
            // });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Banks List",
            text: error,
          });
        });
    },
    bankdepositaccount() {
      let self = this;
      HTTP.post("/uc/depositfiat/bank-account-history")
        .then(function (response) {
          if (response.data.code == "0") {
            self.ListBanks_acc = response.data.data;
          } else {
            // self.$notify({
            //   group: "notification",
            //   type: response.data.code == "0" ? "success" : "error",
            //   title:
            //     response.data.code == "0"
            //       ? "Member Banks Successful"
            //       : "Unable to Get Banks Member List",
            //   text: response.data.message,
            // });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Banks Member List",
            text: error,
          });
        });
    },
    check_bank_member() {
      var check = false;
      if (this.ListBanks_acc.length > 0) {
        for (let i = 0; i < this.ListBanks_acc.length; i++) {
          if (this.NewBank.selectedBankID == this.ListBanks_acc[i].bankCode) {
            this.dropdown_acc_data = this.ListBanks_acc[i].bankAccount;
            this.NewBank.AccountNumber = this.ListBanks_acc[i].bankAccount[0];
            this.show_bank = true;
            check = true;
          }
        }
      }
      if (check == false) {
        this.NewBank.AccountNumber = "";
        this.dropdown_acc_data = [];
      }
    },
    async sendQrPayment() {
      let self = this;
      if (!/^\d{3}$/.test(this.NewBank.selectedBankID)) {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Validate",
          text: "โปรดระบุธนาคาร",
        });
        return false;
      }
      if (this.NewBank.AccountNumber == "") {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Validate",
          text: "โปรดระบุเลขที่บัญชี",
        });
        return false;
      }
      if (
        this.NewBank.AccountNumber.length < 8 ||
        this.NewBank.AccountNumber.length > 14
      ) {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Validate",
          text: "กรุณาระบุเลขที่บัญชีขั้นต่ำ 10 หลัก",
        });
        return false;
      }

      if (this.cashDepositAmount > 0 && this.cashDepositAmount <= 2000000) {
        if (this.isConfirm) {
          var check_send = await this.CheckQRCode_Before_Send();
          if (check_send == false) {
            return false;
          }
          this.isLoading = true;
          HTTP.post("/uc/depositfiat/qrpaymentBank", {
            amount: this.cashDepositAmount,
            bankCode: this.NewBank.selectedBankID,
            bankAccount: this.NewBank.AccountNumber,
          })
            .then((res) => {
              self.isLoading = false;
              if (res.data.code == 200) {
                self.qrdata = res.data.data;
                self.qrFlag = "Cancel";
                // self.cashDepositAmount = null;
                // self.searchBank = "";
                // self.NewBank.AccountNumber = null;
                // self.NewBank.selectedBankID = null;
                self.dropdown_acc_data = [];

                self.$bvModal.show("deposit-qr-modal");
              } else {
                let getname = localStorage.getItem("user-data");
                let name = "XXXXX XXXXX";
                if (getname !== null && getname !== undefined) {
                  try {
                    let parsedData = JSON.parse(getname);
                    if (parsedData && parsedData.realName) {
                      name = parsedData.realName;
                    }
                  } catch (e) {
                    console.error("Error parsing JSON data:", e);
                  }
                }
                self.qrdata.fromAccountName = name;
                self.$bvModal.show("deposit-qr-fail");
                // self.$notify({
                //   group: "notification",
                //   type: "error",
                //   title: "error",
                //   // "กรุณายกเลิกรายการฝากเงิน QR Code ก่อนหน้า"
                //   text: res.data.message,
                // });
                return false;
                if (res.data.message) {
                  const matches = res.data.message.match(/\(id=(\d+),/g);
                  if (matches) {
                    const idMatch = matches[0].match(/\(id=(\d+),/);
                    if (idMatch) {
                      if (parseInt(idMatch[1]) > 0) {
                        self.getDeposit(idMatch[1]);
                      }
                    }
                  } else {
                    self.$notify({
                      group: "notification",
                      type: "error",
                      title: "error",
                      text: res.data.message,
                    });
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              self.isLoading = false;
              self.isConfirm = false;
              // self.cashDepositAmount = null;
              // self.searchBank = "";
              // self.NewBank.AccountNumber = null;
              // self.NewBank.selectedBankID = null;
              self.dropdown_acc_data = [];
            });
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: "error",
            text: "Accept conditions to continue",
          });
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "error",
          text: "The amount must not be 0 and not exceed 2,000,000",
        });
      }
    },
    getDeposit(qrid) {
      let self = this;
      HTTP.get("/uc/bank/qr/" + qrid, {})
        .then((res) => {
          if (res.data.code == 200) {
            self.qrId = qrid;
            self.qrFlag = "Cancel";
            self.qrdata = res.data.data;
            self.$bvModal.show("deposit-qr-modal");
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    banktransfer() {
      let self = this;
      HTTP.post("/uc/depositfiat/banktransfer")
        .then((res) => {
          if (res.data.code == 0) {
            this.bankRef = res.data.data;
            this.bank_company_name =
              this.bankRef.bankAccName !== undefined
                ? this.bankRef.bankAccName
                : "ฟินันเซีย ดิจิทัล แอสเซท (บี)";
            this.referenceOne = this.bankRef.ref1;
            this.referenceTwo = this.bankRef.ref2;
            this.bankName = this.bankRef.bankName;
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateInput() {
      if (this.searchBank == "") {
        this.NewBank.AccountNumber = null;
        this.NewBank.selectedBankID = null;
        this.dropdown_acc_data = [];
      }
    },
    removeBank() {
      this.NewBank.selectedBankID = null;
    },
    validateBank() {
      if (!this.selectedBankID) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Select เลือกธนาคาร",
        // });
        this.text_error_bank = "โปรดเลือกธนาคาร";
        this.failEditbank = true;
        return false;
      } else if (!this.searchBank) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Select เลือกธนาคาร",
        // });
        this.text_error_bank = "โปรดเลือกธนาคาร";
        this.failEditbank = true;
        return false;
      } else if (!this.selectedBranchID) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Enter ชื่อสาขา",
        // });
        this.text_error_bank = "โปรดเลือกสาขา";
        this.failEditbank = true;
        return false;
      } else if (this.NewBank.AccountNumber.length < 10) {
        // this.$notify({
        //   group: "notification",
        //   type: "error",
        //   title: "Error",
        //   text: "Please Enter เลขที่บัญชี Minimun 10 Digits",
        // });
        this.text_error_bank = "โปรดกรอกเลขบัญชี ขั้นต่ำ 10 หลัก";
        this.failEditbank = true;
        return false;
      }
      return true;
    },
    copy(d) {
      navigator.clipboard
        .writeText(d)
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: "Success",
            text: "Copy Success",
          });
          // console.log("Text copied to clipboard:", d);
          // Optionally, you can show a success message or perform other actions
        })
        .catch((error) => {
          console.error("Unable to copy text to clipboard:", error);
          // Handle the error, show an error message, etc.
        });
    },
    closeDropdown() {
      this.open_Banks_Dropdown = false;
      this.open_Banks_Dropdown1 = false;
    },
    checkIfClickedOutside(event) {
      const CoinDropdown = this.$refs.baDropdown;
      const CoinDropdown1 = this.$refs.baDropdown1;
      if (CoinDropdown && !CoinDropdown.contains(event.target)) {
        this.open_Banks_Dropdown = false;
      }
      if (CoinDropdown1 && !CoinDropdown1.contains(event.target)) {
        this.open_Banks_Dropdown1 = false;
      }
    },
    CheckQRCode() {
      let self = this;
      HTTP.post("/uc/depositfiat/QRprevious")
        .then((res) => {
          if (res.data.code == 200) {
            self.qrId = res.data.data.id;
            self.qrFlag = "Cancel";
            self.qrdata = res.data.data;
            self.$bvModal.show("deposit-qr-modal");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async CheckQRCode_Before_Send() {
      let self = this;
      try {
        let res = await HTTP.post("/uc/depositfiat/QRprevious");
        if (res.data.code == 200) {
          self.qrId = res.data.data.id;
          self.qrFlag = "Cancel";
          self.qrdata = res.data.data;
          self.$bvModal.show("deposit-qr-modal");
          return false;
        } else {
          return true;
        }
      } catch (err) {
        console.log(err);
        return true; // Or any other value you wish to return in case of error
      }
    },
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (user_data?.id) {
      let storedData = JSON.parse(localStorage.getItem("resMember") || "{}");
      if (storedData) {
        this.reslockStatusDepositCash =
          storedData?.reslockStatus?.cannotDepositCash;
        this.reslockStatusDepositCoin =
          storedData?.reslockStatus?.cannotDepositCoin;
      }
    }
    this.checktext();
    this.getbanklasted();
    this.getBankList();
    this.bankdepositaccount();
    // this.CheckQRCode();
    window.addEventListener("click", this.checkIfClickedOutside);
  },
  computed: {
    filteredListBanks() {
      if (
        /^\d{3}$/.test(this.NewBank.selectedBankID) &&
        this.searchBank != ""
      ) {
        return this.ListBanks;
      } else {
        return this.ListBanks.filter((item) =>
          item.bankName.toLowerCase().includes(this.searchBank.toLowerCase())
        );
      }
    },
  },
};
</script>
<style lang="scss">
.bank-options {
  position: absolute;
  top: 45px;
  width: 59%;
  background-color: #191b1c;
  border-radius: 4px;
  z-index: 10;
  img {
    width: 23px;
    margin-left: 26px;
    margin-right: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #2c3b44;
      }
    }
  }
}
.bank-options1 {
  position: absolute;
  top: 45px;
  width: 59%;
  background-color: #191b1c;
  border-radius: 4px;
  z-index: 10;
  img {
    width: 23px;
    margin-left: 26px;
    margin-right: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #2c3b44;
      }
    }
  }
}
.deposite-cash-container {
  border-radius: 8px;
  position: relative;
  .margin-l-24 {
    margin-left: 24px;
  }
  .bt-sec {
    font-family: "Sarabun";
  }
  .dcc-head {
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;
      padding: 12.5px 0;
      margin-left: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;
      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }
  .center-section {
    .l-24 {
      line-height: 24px;
    }
    .select-container {
      background-color: #191b1c;
      color: #d6dde1;
      border-radius: 4px;
      height: 40px;
      width: 240px;
      line-height: 24px;
      margin: auto 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .icon {
        pointer-events: none;
        position: absolute;
        right: 12px;
      }
      .rotate-sc-icon {
        transform: rotateX(180deg);
      }
      .text {
        padding-left: 15px;
      }
      .options {
        position: absolute;
        bottom: -82px;
        width: 100%;
        background-color: #191b1c;
        border-radius: 4px;
        ul {
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 15px;
            border-radius: 4px;
            &:hover {
              background-color: #2c3b44;
            }
          }
        }
      }
      .hidden {
        display: none;
      }
      .show {
        display: block;
      }
    }
    .input-container {
      input {
        background-color: #191b1c;
        color: #d6dde1;
        border-radius: 4px;
        height: 40px;
        padding: 0 50px 0 18px;
        line-height: 24px;
        &::placeholder {
          color: #566a76;
        }
      }
      span {
        color: #9bacb6;
        margin-left: -24px;
        line-height: 24px;
      }
    }
    .info {
      color: #d6dde1;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      p {
        margin: 0;
      }
      ul {
        list-style: disc;
      }
    }
    .lr {
      font-size: 16px;
      span {
        font-size: 14px;
        color: #09BE8B;
        margin-left: 8px;
      }
    }
    .bcb {
      margin-top: 24px;
      margin-bottom: 16px;
      border-top: 1px solid #28363e;
    }
    .bc-info {
      p {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      ul {
        list-style: disc;
        padding: 0 24px;
        margin-bottom: 16px;
        li {
          color: #9bacb6;
          width: 70%;
        }
      }
      ul.number {
        list-style: decimal;
      }
    }
  }

  .bottom-section {
    padding-top: 24px;
    border-top: 1px solid #28363e;
    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
    }
    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }
    span {
      margin-left: 10px;
      line-height: 24px;
    }
    .last-btn {
      text-align: center;
      margin-top: 14px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      button {
        color: #0a0d10;
        background-color: #09BE8B;
        border-radius: 4px;
        height: 36px;
        width: 132px;
        &:hover {
          background-color: #f28e38;
        }
      }
      button.inactive {
        opacity: 0.2;
      }
    }
  }
}
.right-info {
  position: absolute;
  top: 3px;
  left: 630px;
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
  ul {
    font-weight: 400;
    list-style: disc;
    position: relative;
    left: -8px;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
@media (max-width: 640px) {
  .deposite-cash-container {
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
    .center-section {
      padding: 0;
    }
    // .select-container {
    //   select {
    //     width: 190px;
    //   }
    // }
    .input-container {
      input {
        width: 190px;
      }
    }
  }
}
@media (min-width: 640px) {
  .deposite-cash-container {
    width: 616px;
    padding-bottom: 0;
    .center-section {
      padding: 0 14px;
    }
    // .select-container {
    //   select {
    //     width: 240px;
    //   }
    // }
    .input-container {
      input {
        width: 240px;
      }
    }
  }
  .qr-h {
    height: 458px;
  }
  .bc-h {
    height: 580px;
  }
}
@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}
@media (min-width: 1024px) {
  .right-info {
    display: block;
    // right: -14px;
    // margin-right: -255px;
    top: 0;
  }
  .deposite-cash-container {
    transform: translateX(-50%);
  }
}
@media (max-width: 848px) {
  .deposite-cash-container {
    background-color: none;
    // margin-left: 40px;
    width: 100%;
    .dcc-head {
      background-color: #121516;
    }
    .bottom-section {
      margin: 0 40px;
    }
    .bcb {
      margin: 0 20px;
    }
  }
}
@media (min-width: 848px) {
  .deposite-cash-container {
    background-color: #121516;
    margin-left: 50%;
    margin-top: 32px;
    transform: translateX(-75.7%);
    .dcc-head {
      border-bottom: 1px solid #28363e;
    }
    .bottom-section {
      margin: 0 25px;
    }
    // .bcb {
    //   margin: 0 -4px;
    // }
  }
}
</style>
