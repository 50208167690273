<template>
  <div>
    <b-modal id="pin-confirm-modal" :hide-footer="true" :hide-header="true" centered no-close-on-backdrop>
      <div class="row py-2 text-center">
        <div class="col d-flex justify-content-between">
          <div class="col"></div>
          <div class="col-8">
            <h5>Pin Confirmation</h5>
          </div>
          <div class="col text-end me-2">
            <span role="button" @click="cancelPin()">
              <img src="@/assets/svg/close.svg" />
            </span>
          </div>
        </div>
      </div>

      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-12">
            
            <div class="row my-4">
              <div class="col-3">
                <span class="d-flex align-items-center">
                  <p>PIN</p>
                </span>
              </div>
              <div class="col-9">
                <span class="d-flex align-items-center">
                  <input
                  class="form-control"
                  :type="isShowPin ? 'text' : 'password'"
                  v-model="pin"
                  @input="validateInput"
                  maxlength="6"
                  required
                  />
                  <span @click="isShowPin = !isShowPin" class="p-absolute" role="button">
                    <img v-if="isShowPin" src="@/assets/svg/eye-show.svg" />
                    <img v-else src="@/assets/svg/eye-hide.svg" />
                  </span>
                </span>
              </div>
            </div>
            
            <div class="row d-flex justify-content-around">
              <div class="col-5">
                <button type="submit" class="yellow-btn text-center">ยืนยัน</button>
              </div>
              <div class="col-5">
                <button type="button" class="grey-btn text-center" @click="cancelPin()" >ยกเลิก</button>
              </div>
            </div>

          </div>
        </div>
      </form>

    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pin: null,
      isShowPin: false,
    };
  },
  created() {
    this.resetValuePin();
  },
  mounted() {
      
  },
  methods: {
    resetValuePin(){
      this.pin = null;
      this.isShowPin = false;
    },
    submit() {
      this.$emit("validatePin", this.pin);
    },
    cancelPin() {
      this.$bvModal.hide("pin-confirm-modal");
      this.resetValuePin();
    },
    validateInput() {
      this.pin = this.pin.replace(/[^0-9]/g, "");
    },
  },
};
</script>
<style scoped>
.p-absolute {
  position: absolute;
  right: 30px;
}
</style>
