import axios from "axios";
import store from "./store";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // Enable cookies
});

const jsonRoutes = [
  "/exchange/order/cancelmulti",
  "/exchange/bitfinexorder/submit",
  "/exchange/okx/order/submit",
  "/exchange/okx/order/cancel",
  "/uc/fatca/save?fatcaType=1",
  "/uc/fatca/save?fatcaType=0",
  "/uc/kyc/review",
  "/uc/upload/base64",
  "/uc/save/attachment/multiple",
  "uc/auto-mode/add",
  "uc/auto-mode/edit",
  "uc/auto-mode/delete"
];
// Add a request interceptor
HTTP.interceptors.request.use(
  function (config) {
    let authToken = localStorage.getItem("auth-token");
    const isJsonRequest = jsonRoutes.some((route) =>
      config.url.includes(route)
    );
    const contentType = isJsonRequest
      ? "application/json; charset=utf-8"
      : "application/x-www-form-urlencoded; charset=utf-8";
    config.headers["Content-Type"] = contentType;
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Methods"] = "GET, POST, OPTIONS, PUT";
    config.headers["Access-Control-Allow-Headers"] = "X-Token";
    config.headers["x-auth-token"] = authToken;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  function (response) {
    // if (response.data.code == 4000) {
    //   store.dispatch("logout");
    // }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
