<template>
  <div class="example">
    <div>
      <svg class="treechart-svg" width="1392" height="387" viewBox="0 0 1392 387" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path v-for="(pathData, index) in data" :key="index" :d="pathData.d" :fill="pathData.fill"
          :stroke="pathData.stroke" />
        <text v-for="(data) in data_map" :x="data.x" :y="data.y" :font-size="data.fontsize" :fill="data.fill">
          {{ data.text }} 
        </text>
      </svg>


    </div>
    <!-- <apexchart
      width="100%"
      height="300"
      type="heatmap"
      :options="chartOptions"
      :series="series"
    ></apexchart> -->
  </div>
  
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "HeatmapExample",
  props: ["TableData"],
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      data_text: [{
        id: 1,
        x: 264.7215,
        y: 192.9485,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 2,
        x: 680.36,
        y: 192.5,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 3,
        x: 950.36,
        y: 100.5,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 4,
        x: 950.36,
        y: 300.5,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 5,
        x: 1215,
        y: 65,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 6,
        x: 1215,
        y: 180,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 7,
        x: 1170,
        y: 285,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 8,
        x: 1325,
        y: 279,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 9,
        x: 1170,
        y: 360,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 10,
        x: 1325,
        y: 340,
        fill: "white",
        fontsize: "20px"
      }, {
        id: 11,
        x: 1325,
        y: 380,
        fill: "white",
        fontsize: "20px"
      }],
      data: [{
        d: "M0.5 0.5 L529.943 0.5 L529.943 385.897 L0.5 385.897 Z",
        fill: "#CE5033",
        stroke: "black"
      }, {
        d: "M530.945 0.5H861.06V386.397H530.945V0.5Z",
        fill: "#D1BB4C",
        stroke: "black"
      }, {
        d: "M862.055 0.5H1080.15V198.273H862.055V0.5Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M862.055 199.273H1080.15V386.397H862.055V199.273Z",
        fill: "#DCBE50",
        stroke: "black"
      }, {
        d: "M1081.15 0.5H1391.5V116.634H1081.15V0.5Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M1081.15 117.634H1391.5V233.768H1081.15V117.634Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M1081.15 234.768H1287.72V328.422H1081.15V234.768Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M1081.15 329.422H1287.72V386.397H1081.15V329.422Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M1288.72 234.768H1391.5V314.224H1288.72V234.768Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M1288.72 315.224 L1391.502 315.224 L1391.502 357.999 L1288.72 357.999 Z",
        fill: "#6EA028",
        stroke: "black"
      }, {
        d: "M1288.72 359.001 L1391.502 359.001 L1391.502 386.397 L1288.72 386.397 Z",
        fill: "#6EA028",
        stroke: "black"
      }],
      textData: ["BTC", "ETH", "USDT", "USDC", "BNB", "SQL", "A2", "A3", "A2", "A3", "A6"],
      baseX: 200,
      baseY: 150,
      spacingX: 400,
      spacingY: 100,
      chartOptions: {
        plotOptions: {
          heatmap: {
            columnWidth: "300",
            radius: 0,
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 2,
                  to: 10,
                  color: "#44A765",
                  foreColor: "white",
                  name: "greater or equal value 2 color green",
                },
                {
                  from: 0,
                  to: 1,
                  color: "#596C78",
                  foreColor: "white",
                  name: "greater then 0 or less then 2 => value 0 to 0.9 color grey",
                },
                {
                  from: -10,
                  to: -1,
                  color: "#BD424C",
                  foreColor: "white",
                  name: "greater or equal value -1 color red",
                },
              ],
            },
          },
        },
        dataLabels: {
          offsetX: 0,
          offsetY: 0,
          enabled: true,
          position: 'bottom',
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          style: {
            fontSize: '12px',
            // colors: ["#304758"]
            columnWidth: 40
          }
        },
      },
      data_map: [],
      series: [
        {
          name: "Series 1",
          data: [
            {
              x: "SOL",
              y: "-6.89%",
            },
            {
              x: "BTC",
              y: "+8.89%",
            },
            {
              x: "USDC",
              y: "0.98%",
            },
            {
              x: "ALGO",
              y: "-9.89%",
            },
            {
              x: "BNB",
              y: "+2.48%",
            },
            {
              x: "USDT",
              y: "0.78%",
            },
          ],
        },
        {
          name: "Series 2",
          data: [
            {
              x: "TRX",
              y: "-8.89%",
            },
            {
              x: "UNI",
              y: "+8.89%",
            },
            {
              x: "BUSD",
              y: "0.98%",
            },
            {
              x: "DOT",
              y: "+6.45%",
            },
            {
              x: "USDT",
              y: "0.48%",
            },
            {
              x: "ALGO",
              y: "-1.05%",
            },
          ],
        },
        {
          name: "Series 3",
          data: [
            {
              x: "TRX",
              y: "-5.89%",
            },
            {
              x: "ETH",
              y: "-10%",
            },
            {
              x: "ADA",
              y: "+4.89%",
            },
            {
              x: "DOGE",
              y: "+10%",
            },
            {
              x: "BUSD",
              y: "0.38%",
            },
            {
              x: "USDT",
              y: "1%",
            },
          ],
        },
      ],
    };
  },
  methods: {
    check_data() {
      this.data_map = []
      const gainItems = this.TableData
        .sort((a, b) => b.chg - a.chg)
        .slice(0, 20);
      var i = 0
      if (gainItems.length > 0) {
        gainItems.forEach(element => {
          if (i <= 10) {
            var persent = element.chg * 100
           
            if(persent >= 0){
              this.data[i].fill = "#197935" 
            } else{
              this.data[i].fill = "#a81f1f" 
            }
            var a = {
              x: this.data_text[i].x,
              y: this.data_text[i].y,
              chg:persent,
              fontsize: "20",
              fill: "white",
              text: element.CoinName
            }
            this.data_map.push(a)
          }
          i++
        });
      }
    },
  },
  mounted() {
    this.check_data()
  }
};
</script>
