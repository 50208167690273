<template>
  <div>
    <b-modal
      class="qr-modal"
      id="fail-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="pre-test-fail-c">
        <img
          class="site-logo"
          :src="require('../../assets/img-fss-logo.png')"
          alt="site-logo"
        />
        <div class="cross-img">
          <img src="@/assets/images/wallet/cross.png" alt="cross-image" />
        </div>
        <div class="text">
          <p class="thai-font">
            คุณทำแบบทดสอบไม่ถึงเกณฑ์ที่กำหนด <br />
            (อย่างน้อย 15/15 ข้อ) <br />
            กรุณาเลือกคำตอบใหม่อีกครั้ง
          </p>
          <button @click="$bvModal.hide('fail-modal')">แก้ไขแบบทดสอบ</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
#fail-modal {
  background-color: #141b1f;
}
#fail-modal___BV_modal_content_ {
  background-color: #141b1f;
  width: 450px;
  border: none;
}
.pre-test-fail-c {
  margin: auto;
  margin-top: 27px;
  .site-logo {
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .cross-img {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    img {
      width: 60px;
    }
  }
  .text {
    margin-top: 19px;
    p {
      margin: 0;
      text-align: center;
      color: #d6dde1;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    button {
      color: #141b1f;
      font-family: "Noto Sans Thai";
      font-weight: 600;
      font-size: 16px;
      background-color: #09BE8B;
      border-radius: 4px;
      width: 250px;
      height: 44px;
      display: block;
      margin: auto;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}
</style>
