<template>
  <div>
    <b-modal
      class="otp-verify-modal"
      id="main-verify-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <!-- centered -->
      <div class="otp-verify-modal" style="padding: 0px 16px !important;">
        <div class="tick-img">
          <img
            src="@/assets/images/wallet/tick-svg.png"
            alt="tick-image"
            width="67px"
          />
        </div>
        <div class="m-center">
          <p class="c-title noto-sans-thai-font">
            คุณได้เลือกบัญชีเรียบร้อยเเล้ว
          </p>
          <p class="c-value thai-font">บริษัทจะโอนเงินเข้าบัญชี</p>
        </div>
        <div class="text-center">
          <p style="margin: 0; margin-top: 20px;">{{ realName }}</p>
          <p style="margin: 0;">{{ bank.bankname }}</p>
          <p style="margin: 0;">{{ bank.bankaccno }}</p>
        </div>
        <button @click="saveAccount()" class="f-btn noto-sans-thai-font">
          ตกลง
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["showAccount","bank", "realName"],
  methods: {
    saveAccount() {
      this.$bvModal.hide("main-verify-modal");
      this.$router.push("/withdraw/cash");
      //this.$emit("saveAccount", true);
    },
  },
};
</script>
<style lang="scss">
#main-verify-modal___BV_modal_content_ {
  width: 345px;
  height: 313px;
  /* UI-Layout/UI Layer #1 */
  border-radius: 15px;
  background-color: #141b1f;
  margin: auto;
  margin-top: 150px !important;
  font-family: "Noto Sans Thai";
  #main-verify-modal___BV_modal_body_ {
    padding: 0px;
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
    }
    .tick-img {
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 22px;
    }
    .m-center {
      text-align: center;

      p {
        margin: 0;
      }
      .c-title {
        color: #d6dde1;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }
      .c-value {
        color: #677f8e;
        line-height: 19px;
      }
    }
    .f-btn {
      width: 163px;
      height: 64.524px;
      display: block;
      margin: auto;
      border-radius: 4px;
      color: white;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 35px;
      margin-top: 32px;
      background-color: #191b1c;
    }
  }
}
.amn {
  .d {
    width: 290px;
  }
  width: 225px;
  margin-top: 6px !important;
  width: 235;
  padding: auto;
  p {
    margin: 0;
    color: #9bacb6;
    font-size: 14px;
    font-family: Sarabun;
    line-height: 24px;
  }
}
</style>
<style scoped>
hr {
  color: #28363e;
  width: 80%;
  margin: 24px auto !important;
  opacity: 1;
}
.col-5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #677f8e;
}
.col-5 > span {
  width: 88px;
  display: block;
  margin-left: auto;
}
.col-7 {
  line-height: 19px;
}
.tp {
  margin-top: 9px;
}
</style>