<template>
  <div class="knowledge-test-c">
    <!-- <router-link to="/"> -->
    <img class="top-logo" src="../../assets/img-fss-logo.png" alt="logo" />
    <!-- </router-link> -->
    <h1 class="noto-sans-thai-font">
      แบบทดสอบความรู้ ({{
        currentQuestion === 0
          ? "1"
          : currentQuestion === 5
          ? "2"
          : currentQuestion === 10
          ? "3"
          : ""
      }}/3)
    </h1>
    <p class="kt-info">
      โปรดเลือกคำตอบที่ถูกต้อง โดยแบบทดสอบความรู้มีจำนวน 15 ข้อ
      มีเงื่อนไขการผ่านเกณฑ์ 15/15 ข้อ (100%)
    </p>
    <!-- skaleton start -->
    <template v-if="!knowledgeTest">
      <div v-for="(item, index) in 5" :key="index + 'e'">
        <b-skeleton
          class="skc custom-skeleton-wave"
          animation="wave"
          width="100%"
          height="110px"
          style="margin-bottom: 16px"
        ></b-skeleton>
      </div>
    </template>
    <!-- skaleton end -->
    <form @submit.prevent="getAnswer">
      <!-- :style="{ height: deviceHeight - 350 + 'px' }" -->
      <div class="qas">
        <template v-for="(test, index1) in knowledgeTest">
          <div
            class="questions"
            :id="'questions' + index1"
            :key="index1"
            v-if="index1 >= currentQuestion && index1 < currentQuestion + 5"
          >
            <div class="q-container">
              <div class="q-text">
                <span class="q-count"
                  >{{ index1 + 1 }}. {{ test.question }}</span
                >
              </div>

              <div class="answer mt-5 pr-5">
                <div v-for="(item, index2) in test.choice_list" :key="index2">
                  <div class="radio_btn" style="margin-right: 13px">
                    <input
                      class="normal"
                      type="radio"
                      :id="'q' + index1 + 'choice' + index2"
                      :name="'question' + index1"
                      v-model="user_answers[index1]"
                      :value="index2"
                    />
                    <label
                      :for="'q' + index1 + 'choice' + index2"
                      :class="
                        test.answer_visible &&
                        knowledgeTest[index1]?.ans - 1 == index2
                          ? 'text-green'
                          : ''
                      "
                    >
                      {{ item }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="correct-answer" v-if="test.answer_visible">
              {{ test.ans_detail }}
            </div>
          </div>
        </template>

        <div
          v-if="currentQuestion === 10"
          class="f-section"
          :class="currentQuestion === 10 ? 'f-end' : ''"
        >
          <button type="submit" v-if="areAllRadioButtonsSelected">
            ส่งคำตอบ
          </button>
          <button
            style="opacity: 0.5 !important; cursor: unset"
            :disabled="!areAllRadioButtonsSelected"
            type="submit"
            v-else
          >
            ส่งคำตอบ
          </button>
        </div>
      </div>
    </form>
    <div class="button-container">
      <button
        class="next-btn"
        v-if="currentQuestion + 5 < knowledgeTest.length"
        @click="nextQuestion"
        :class="!areAllRadioButtonsSelected ? 'btn-dull' : ''"
        :disabled="!areAllRadioButtonsSelected"
      >
        ถัดไป
      </button>
      <button
        class="prev-btn"
        :class="[currentQuestion === 10 ? 'prev-end' : '']"
        v-if="currentQuestion > 0"
        @click="previousQuestion"
      >
        ย้อนกลับ
      </button>
    </div>
    <PassModal />
    <b-modal
      class="qr-modal"
      id="fail-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="pre-test-fail-c">
        <img
          class="site-logo"
          :src="require('../../assets/img-fss-logo.png')"
          alt="site-logo"
        />
        <div class="cross-img">
          <img src="@/assets/images/wallet/cross.png" alt="cross-image" />
        </div>
        <div class="text">
          <p class="thai-font">
            คุณทำแบบทดสอบไม่ถึงเกณฑ์ที่กำหนด <br />
            (อย่างน้อย 15/15 ข้อ) <br />
            กรุณาเลือกคำตอบใหม่อีกครั้ง
          </p>
          <button @click="$bvModal.hide('fail-modal'), checkscroll()">
            แก้ไขแบบทดสอบ
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { HTTP } from "../../config/axios";
import FailModal from "./FailModal.vue";
import PassModal from "./PassModal.vue";
export default {
  components: { FailModal, PassModal },
  data() {
    return {
      choice_fail_first: 0,
      knowledgeTest: "",
      user_answers: [],
      is_fail: false,
      deviceHeight: null,
      currentQuestion: 0,
      quizId: "",
      quizVersion: "",
    };
  },
  mounted() {
    this.deviceHeight = window.innerHeight;
  },
  created() {
    localStorage.setItem("isKnowledgePassed", false);
    HTTP.get(this.KNOWLEDGE_QUIZ_URL)
      .then((res) => {
        this.knowledgeTest = res.data.items;
        this.quizId = res.data.id;
        this.quizVersion = res.data.version;
        this.knowledgeTest = this.knowledgeTest.map((v) => ({
          ...v,
          answer_visible: false,
        }));
        this.user_answers = Array(this.knowledgeTest.length).fill(null);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    areAllRadioButtonsSelected() {
      const startIndex = this.currentQuestion;
      const endIndex = Math.min(startIndex + 5, this.knowledgeTest.length);
      const questionIndexes = Array.from(
        { length: endIndex - startIndex },
        (_, i) => i + startIndex
      );

      return questionIndexes.every(
        (index) =>
          this.user_answers[index] !== null &&
          this.user_answers[index] !== undefined
      );
    },
    convertedAnswers() {
      const result = [...this.user_answers].join("|");
      return result;
    },
  },
  methods: {
    previousQuestion() {
      this.currentQuestion -= 5;
    },
    nextQuestion() {
      this.currentQuestion += 5;
    },
    checkscroll() {
      if (this.choice_fail_first > 0) {
        const page = Math.floor((this.choice_fail_first - 1) / 5); // Calculate the page number (0-indexed)
        this.currentQuestion = page * 5; // Set to the first question of that page
      }

      setTimeout(() => {
        const choiceFailFirst = Number(this.choice_fail_first);
        const elementId = "questions" + (choiceFailFirst - 1);
        const element = document.querySelector(`#${elementId}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    },
    getAnswer() {
      let flagAnswer = -1;
      let counter = 0;
      this.choice_fail_first = 0;

      for (let index = 0; index < this.knowledgeTest.length; index++) {
        if (this.user_answers[index] + 1 === this.knowledgeTest[index].ans) {
          counter++;
          this.knowledgeTest[index].answer_visible = false;
        } else {
          if (this.choice_fail_first === 0) {
            this.choice_fail_first = index + 1; // 1-indexed position of the first incorrect answer
          }
          this.knowledgeTest[index].answer_visible = true;
          if (flagAnswer === -1) {
            flagAnswer = index;
          }
        }
      }

      if (counter >= 15) {
        localStorage.setItem("isKnowledgePassed", true);
        this.$bvModal.show("pass-modal");
        HTTP.post("/uc/check/knowledge", {
          qid: this.quizId,
          score: counter,
          ans: this.convertedAnswers,
        })
          .then((res) => {
            let userData = JSON.parse(
              localStorage.getItem("user-data") || "{}"
            );
            userData.knowledgeTest = true;
            localStorage.setItem("user-data", JSON.stringify(userData));
          })
          .catch((err) => {
            console.log(err);
          });

        this.is_fail = false;
      } else {
        this.$bvModal.show("fail-modal");
        this.is_fail = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#fail-modal {
  background-color: #141b1f;
}
#fail-modal___BV_modal_content_ {
  background-color: #141b1f;
  width: 450px;
  border: none;
}
.pre-test-fail-c {
  margin: auto;
  margin-top: 27px;
  .site-logo {
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .cross-img {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    img {
      width: 60px;
    }
  }
  .text {
    margin-top: 19px;
    p {
      margin: 0;
      text-align: center;
      color: #d6dde1;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    button {
      color: #141b1f;
      font-family: "Noto Sans Thai";
      font-weight: 600;
      font-size: 16px;
      background-color: #09BE8B;
      border-radius: 4px;
      width: 250px;
      height: 44px;
      display: block;
      margin: auto;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}
.top-logo {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 150px;
}
.knowledge-test-c {
  border-radius: 4px;
  margin: auto;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-right: 0;
  padding-left: 16px;
  font-family: "Noto Sans Thai";
  font-weight: 600;
  .qas {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .kt-info {
    // position: absolute;
    // margin-top: -70px;
    // width: 340px;
    text-align: center;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #9bacb6;
    margin-top: 22px;
    margin-bottom: 23px;
  }
  h1 {
    // font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 24px;
    color: rgb(255, 255, 255);
  }
  .questions:nth-of-type(n + 2) {
    margin-top: 16px;
  }
  .questions {
    .q-container {
      background-color: #121516;
      width: 100%;
      padding-top: 26px;
      padding-left: 88px;
      padding-bottom: 14px;
      padding-right: 50px;
      border-radius: 8px;
    }
    // padding-right: 20px;
    color: rgb(255, 255, 255);
    // padding: 10px 60px;
    // padding: 16px 70px 16px 70px;
    // padding-top: 30px;
    // padding-left: 87px;
    // padding-bottom: 15px;
    // border-radius: 8px;
    position: relative;
    .q-text {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: rgb(255, 255, 255);

      .q-count {
        position: absolute;
        left: 40px;
        top: 29px;
      }
      /* or 143% */

      /* Text+Icon/Primary */

      color: rgb(255, 255, 255);
    }
    .answer {
      .radio_btn {
        display: flex;
        align-items: center; /* ทำให้ปุ่มและข้อความอยู่กลางแนวแกนแนวตั้ง */
        // margin-right: 13px;
        line-height: 20px;
        label {
          padding-left: 7px;
          font-family: "Sarabun";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          position: relative;
          top: 0px;
          left: 2px;
        }

        input[type="radio"]:after {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          top: -4px;
          right: 4px;
          position: relative;
          background-color: #191b1c;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 3px solid #38454d;
        }

        input[type="radio"]:checked:after {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          position: relative;
          background-color: rgb(255, 255, 255);
          content: "";
          display: inline-block;
          visibility: visible;
        }
        .red:checked:after {
          border: 4px solid #de2d40;
        }
        .normal:checked:after {
          border: 4px solid #09BE8B;
        }
      }
      .radio_btn input[type="radio"] {
  margin-right: 5px; /* เว้นระยะห่างระหว่างปุ่มกับ label */
}
      .a1 {
        margin-left: 4px;
      }
      .a2 {
        margin-left: 48px;
      }
    }
    .correct-answer {
      width: 90%;
      color: #de2d40;
      margin-left: 5px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding-left: 87px;
      padding-top: 10px;
    }
  }
  .f-end {
    margin-left: -348px !important;
  }
  .f-section {
    margin-top: 8px;
    // margin-bottom: 30px;
    margin-left: -15px;
    button {
      font-family: "Sarabun";
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      display: block;
      width: 332px;
      height: 40px;
      margin: auto;
      color: #0a0d10;
      background-color: #09BE8B;
      font-weight: 500;

      border-radius: 4px;

      &:hover {
        background-color: #66cdb0;
      }
    }
  }
  .button-container {
    text-align: center;
    button {
      width: 332px;
      height: 40px;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      margin-top: 8px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .next-btn {
      background-color: #09BE8B;
      color: #0a0d10;
      font-weight: 500;
    }
    .prev-btn {
      background-color: #191b1c;
      color: #ffffff;
      font-weight: 400;
    }
    .prev-end {
      position: relative;
      top: -48px;
      left: 174px;
    }
    .btn-dull {
      opacity: 0.5;
    }
  }
  .fmg {
    margin-top: 50px;
  }
  .text-green {
    color: #5bc142;
  }
}
@media only screen and (max-width: 900px) {
  .knowledge-test-c {
    width: 700px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .knowledge-test-c {
    width: 950px;
  }
}
@media only screen and (min-width: 1366px) {
  .knowledge-test-c {
    width: 1174px;
  }
}
.radio-container {
  display: flex;
  align-items: center; /* เพื่อจัดให้ทุกองค์ประกอบอยู่ตรงกลางในแนวตั้ง */
}
</style>
