<template>
  <div class="d-flex align-items-center">
  <input ref="fileInput" type="file" class="d-none" @change="handleFileUpload"
    accept="image/jpeg, image/png, application/pdf" :disabled="fileInputDisabled" />
  <div>
    <div v-for="(item, index) in fileItems" :key="index" class="attachment-item" :title="item.fileName">
      <div class="text-truncate" @click="() => handlePreview(item)">{{ item.fileName }}</div>
      <button class="btn btn-danger btn-remove-item" @click="() => handleRemove(item)">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
    <button class="btn-upload" @click="uploadClick"><i class="fas fa-paperclip attach-icon"></i>&nbsp;&nbsp;อัพโหลด</button>
  </div>
</template>
  
<script>

import { HTTP } from "../../config/axios";

export default {
  props: {
    fileItems: {
      type: Array,
      default: [],
      require: true
    }
  },
  data() {
    return {
      fileInputDisabled: false, // Control whether the file input is disabled
    };
  },
  methods: {
    handleFileUpload(event) {
      if (this.fileInputDisabled) return; // Check if the input is disabled
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        // Check if the selected file's type is allowed
        if (allowedTypes.includes(selectedFile.type)) {
          const self = this;
          const reader = new FileReader();
          reader.onload = () => {
            const base64Data = reader.result;
            const [fullMIMEType, base64Content] = base64Data.split(';base64,');
            const fileItem = { fileName: selectedFile.name, file: selectedFile, base64: base64Content, contentType: selectedFile.type }
            const fileItems = [...self.fileItems, fileItem]
            self.$emit('update:fileItems', fileItems);
            self.$emit('seletectionChanged', fileItems);
            self.$refs.fileInput.value = ''
          }
          reader.readAsDataURL(selectedFile);

        } else {
          alert('Only images (jpg, png) and PDF files are allowed.');
          event.target.value = ''; // Reset the input field
        }
      }
    },
    handlePreview(item) {
      if (item?.file) {
        const objectURL = URL.createObjectURL(item.file)
        const link = document.createElement("a");
        link.href = objectURL;
        link.target = "_blank";
        link.click();
      } else if (item?.fileId) {
        /* 
        HTTP.get(`/market/coin/image/${item.fileId}`).then(res => {
          debugger;
          const blob = new Blob([res.data], { type: item.contentType });
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = objectURL;
          link.target = "_blank";
          link.click();
        }) 
        */
        const link = document.createElement("a");
        //เปิดรูปจาก Dev Kube
        link.href = this.MARKET_WS_URL +`/market/coin/image/${item.fileId}`;
        link.target = "_blank";
        link.click();

      }
    },
    handleRemove(item) {
      const result = this.fileItems.filter(x => x != item);
      this.$emit('update:fileItems', result);
      this.$emit('seletectionChanged', result);
    },
    uploadClick() {
      this.$refs.fileInput.click();
    }
  },
};
</script>
  
<style scoped>
/* Add custom styles for the "Clear" button and text container */
.btn-upload {
  display: flex;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: .375rem .75rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding-left: .75rem;
  margin-right: 10px;
  cursor: pointer;
  max-width: 200px;
  width: fit-content;
  margin-bottom: 5px;
}

.btn-remove-item {
  margin-left: .5rem;
  padding: .2rem .4rem;
  border-radius: 0px;
}
</style>





