<template>
  <div class="container-fluid" style="padding: 0; padding-left: 10px">
    <!-- top bar section start -->
    <div class="cd-tb">
      <div class="row top-search-row">
        <div
          class="col-12 col-md-3 col-xl-3"
          style="padding-left: 20px !important; width: 250px"
        >
          <div class="position-relative search-container">
            <div>
              <span class="search-svg">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
              <span v-if="!showSearchBox" class="coin-image">
                <img
                  v-if="filteredCoinsImages?.logoFileId"
                  :src="filteredCoinsImages.logoFileId"
                  width="24"
                  height="24"
                />
              </span>
              <div
                ref="coinDropdown"
                class="td"
                tabindex="0"
                @focusin="
                  showSearchBox = true;
                  showBtcText = false;
                "
                @blur="showSearchBox = false"
              >
                <input
                  v-model="coinSearchText"
                  class="ml-4 coins-search-input text-uppercase btc-text-true"
                  :class="showBtcText ? 'btc-text-true' : 'btc-text-false'"
                  type="text"
                  :placeholder="showBtcText ? coinName + ' / INR' : ''"
                  maxlength="10"
                  @blur="setCoinText()"
                  @keydown.enter="handleEnterKey"
                />
                <div v-if="showSearchBox" class="searching-box">
                  <p class="search-title">{{ $t('searchlast') }}</p>
                  <div
                    @click="
                      showBtcText = true;
                      coinSearchText = '';
                    "
                  >
                    <div
                      class="row"
                      v-for="(item, index) in filteredCoins"
                      :key="index"
                      @click="selectMarketSymbol(item.url.toString())"
                    >
                      <div class="col-2" style="width: 12%">
                        <img
                          v-if="item.logoFileId"
                          :src="item.logoFileId"
                          width="24"
                          height="24"
                          style="margin-left: 7px"
                        />
                      </div>
                      <div class="col-5" style="width: 44%">
                        <p class="coin-text">
                          {{ item.symbol.split("/")[0]
                          }}<span class="pair-text"
                            >/ {{ item.symbol.split("/")[1] }}</span
                          >
                        </p>
                        <p class="vol">
                          Vol.
                          {{
                            $MyFunc.NumberFormat(
                              item.volume,
                              currentScale.fixScale
                            )
                          }}M
                        </p>
                      </div>
                      <div class="col-5 text-right" style="width: 44%">
                        <p class="price">
                          {{ $MyFunc.NumberFormat(item.price, item.bathScale) }}
                        </p>
                        <p :class="item.color + ' percent'">
                          {{ $MyFunc.PercentChg(item.pChg) }}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-9 col-xl-6 my-auto trading-scores">
          <span class="live-amount-title red" :class="priceInfo.textColor">{{
            $MyFunc.NumberFormat(priceInfo.last)
          }}</span>
          <span class="live-amount-value red" :class="priceInfo.textColor"
            >({{ $MyFunc.PercentChg(priceInfo.pChg) }}%)</span
          >
          <span class="title">24h High:</span>
          <span class="value">{{
            $MyFunc.NumberFormat(priceInfo.high24)
          }}</span>
          <span class="title">24h Low:</span>
          <span class="value">{{ $MyFunc.NumberFormat(priceInfo.low24) }}</span>
          <span class="title">24h Volume :</span>
          <span class="value" style="margin-right: 0">{{
            $MyFunc.NumberFormat(priceInfo.vol24)
          }}</span>
        </div>
        <div class="col-12 col-md-3 col-xl-3 repon" >
          <div
            data-v-538150db=""
            class="add-alert-buttons d-inline lh-24 d-flex flex-row"
          >
            <button
              data-v-538150db=""
              @click="selectFav(currentCoin.symbol)"
              style="width: 100%"
            >
              <svg
                v-if="favCoinRows == true"
                data-v-538150db=""
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z"
                  fill="#D6DDE1"
                />
              </svg>
              <svg
                v-else
                data-v-538150db=""
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 7.24L12.81 6.62L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27L16.18 19L14.55 11.97L20 7.24ZM10 13.4L6.24 15.67L7.24 11.39L3.92 8.51L8.3 8.13L10 4.1L11.71 8.14L16.09 8.52L12.77 11.4L13.77 15.68L10 13.4Z"
                  fill="#677F8E"
                />
              </svg>
              {{ $t('addfavorite') }}
            </button>
            <button @click="handelAlertPopup" style="width: 100%">
              <svg
                data-v-538150db=""
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  data-v-538150db=""
                  d="M6.34167 16.5083C6.34167 17.425 7.08333 18.1667 8 18.1667C8.91667 18.1667 9.65833 17.425 9.65833 16.5083H6.34167ZM8 4C10.3 4 12.1667 5.86667 12.1667 8.16667V14H3.83333V8.16667C3.83333 5.86667 5.7 4 8 4ZM8 0.25C7.30833 0.25 6.75 0.808333 6.75 1.5V2.475C4.13333 3.04167 2.16667 5.375 2.16667 8.16667V13.1667L0.5 14.8333V15.6667H15.5V14.8333L13.8333 13.1667V8.16667C13.8333 5.375 11.8667 3.04167 9.25 2.475V1.5C9.25 0.808333 8.69167 0.25 8 0.25Z"
                ></path>
              </svg>
              {{ $t('addpricealert') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- top bar section end -->
    <div class="row coin-detail-main-content px-2">
      <div class="col-12 col-md-6 col-lg-7 col-xl-8">
        <div class="cd-main-chart mt-2">
          <div class="cd-chart-topbar">
            <div
              class="ctype-sc"
              tabindex="0"
              @click="show_chart_type_options = !show_chart_type_options"
              @blur="show_chart_type_options = false"
            >
              <span class="text" :class="selected_chart_type_option"></span>
              <span class="icon"
                ><svg
                  width="7"
                  height="5"
                  viewBox="0 0 7 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
              <div
                class="options"
                :class="show_chart_type_options ? 'show' : 'hidden'"
              >
                <ul>
                  <li
                    @click="
                      selected_chart_type_option = 'candles';
                      changeChartType('candle');
                    "
                  >
                    <span class="chart-icon candles"></span>
                    Candles
                  </li>
                  <li
                    @click="
                      selected_chart_type_option = 'line';
                      changeChartType('line');
                    "
                  >
                    <span class="chart-icon line"></span> Line
                  </li>
                  <li
                    @click="
                      selected_chart_type_option = 'mountain';
                      changeChartType('mountain');
                    "
                  >
                    <!-- <span class="chart-icon mountain"></span> Mountain -->
                    <span class="chart-icon mountain"></span> Area
                  </li>
                </ul>
              </div>
            </div>
            <div class="timeframe">
              <button
                :class="trendSignal === '15m' ? 'active' : ''"
                @click="
                  trendSignal = '15m';
                  changeTime('15', '');
                "
              >
                15m
              </button>
              <button
                :class="trendSignal === '30m' ? 'active' : ''"
                @click="
                  trendSignal = '30m';
                  changeTime('30', '');
                "
              >
                30m
              </button>
              <button
                :class="trendSignal === '1hr' ? 'active' : ''"
                @click="
                  trendSignal = '1hr';
                  changeTime('1', 'h');
                "
              >
                1hr
              </button>
              <button
                :class="trendSignal === '4hr' ? 'active' : ''"
                @click="
                  trendSignal = '4hr';
                  changeTime('4', 'h');
                "
              >
                4hr
              </button>
              <button
                :class="trendSignal === 'D' ? 'active' : ''"
                @click="
                  trendSignal = 'D';
                  changeTime('1', 'd');
                "
              >
                D
              </button>
              <div
                class="tf-sc"
                tabindex="0"
                @click="
                  (show_timeline_options = !show_timeline_options),
                    (trendSignal = null)
                "
                @blur="show_timeline_options = false"
              >
                <span
                  class="text"
                  :class="{
                  active: show_timeline_options,
                  active: !trendSignal,
                }"
                  >{{ selected_timeline_option }}</span
                >
                <span class="icon"
                  ><svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <div
                  class="options"
                  :class="show_timeline_options ? 'show' : 'hidden'"
                >
                  <ul>
                    <li
                      @click="
                        selected_timeline_option = '1m';
                        changeTime('1', '');
                      "
                    >
                      1m
                    </li>
                    <li
                      @click="
                        selected_timeline_option = '3m';
                        changeTime('3', '');
                      "
                    >
                      3m
                    </li>
                    <li
                      @click="
                        selected_timeline_option = '5m';
                        changeTime('5', '');
                      "
                    >
                      5m
                    </li>
                    <li
                      @click="
                        selected_timeline_option = '10m';
                        changeTime('10', '');
                      "
                    >
                      10m
                    </li>
                    <!-- <li
                      @click="
                        selected_timeline_option = '15m';
                        changeTime('15', '');
                      "
                    >
                      15m
                    </li>
                    <li
                      @click="
                        selected_timeline_option = '30 m';
                        changeTime('30', '');
                      "
                    >
                      30 m
                    </li>
                    <li
                      @click="
                        selected_timeline_option = '1 hr';
                        changeTime('1', 'h');
                      "
                    >
                      1 hr
                    </li> -->
                    <li
                      @click="
                        selected_timeline_option = '2hr';
                        changeTime('2', 'h');
                      "
                    >
                      2hr
                    </li>
                    <!-- <li
                      @click="
                        selected_timeline_option = '4 hr';
                        changeTime('4', 'h');
                      "
                    >
                      4 hr
                    </li>
                    <li
                      @click="
                        selected_timeline_option = '1 day';
                        changeTime('1', 'd');
                      "
                    >
                      1 day
                    </li> -->
                    <li
                      @click="
                        selected_timeline_option = 'W';
                        changeTime('1', 'w');
                      "
                    >
                      W
                    </li>
                    <li
                      @click="
                        selected_timeline_option = 'M';
                        changeTime('1', 'm');
                      "
                    >
                      M
                    </li>
                    <li
                      @click="
                        selected_timeline_option = 'Y';
                        changeTime('1', 'y');
                      "
                    >
                      Y
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="ic-sc"
              tabindex="0"
              @click="
                show_indicator_options = !show_indicator_options;
                addIndicators();
              "
              @blur="show_indicator_options = false"
            >
              <span
                class="text"
                :class="show_indicator_options ? 'active' : ''"
                >{{ selected_indicator_option }}</span
              >
              <span class="icon"
                ><svg
                  width="7"
                  height="5"
                  viewBox="0 0 7 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
            </div>

            <span class="expand" @click="chartFullScreen">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_3353_2144"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                >
                  <rect width="16" height="16" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3353_2144)">
                  <path
                    d="M8.81613 13.226C8.81613 12.8002 9.14951 12.4519 9.55696 12.4519H11.4757L8.8163 9.82018C8.52738 9.51831 8.52738 9.03066 8.8163 8.72879C9.10523 8.42691 9.57196 8.42691 9.86088 8.72879L12.5203 11.3605V9.35576C12.5203 8.93004 12.8537 8.58172 13.2611 8.58172C13.6686 8.58172 14.002 8.93004 14.002 9.35576V13.226C14.002 13.6517 13.6686 14 13.2611 14H9.55696C9.14951 14 8.81613 13.6517 8.81613 13.226Z"
                    fill="#677F8E"
                  />
                  <path
                    d="M7.18485 2.77404C7.18485 3.19976 6.85147 3.54808 6.44401 3.54808H4.52524L7.18467 6.17982C7.4736 6.48169 7.4736 6.96934 7.18467 7.27121C6.89575 7.57309 6.42902 7.57309 6.1401 7.27121L3.48067 4.63948V6.64424C3.48067 7.06996 3.14729 7.41828 2.73983 7.41828C2.33237 7.41828 1.999 7.06996 1.999 6.64424V2.77404C1.999 2.34832 2.33237 2 2.73983 2H6.44401C6.85147 2 7.18485 2.34832 7.18485 2.77404Z"
                    fill="#677F8E"
                  />
                </g>
              </svg>
            </span>
          </div>
          <iframe
            id="myIframe"
            :src="chartSymbolUrl"
            style="width: 100%; height: 100%"
          ></iframe>
          <!-- <iframe
            id="myIframe2"
            :src="chartSymbolUrl2"
            style="width: 0%; height: 0%"
          ></iframe> -->
        </div>
        <div class="container below-chart-content">
          <div class="row mt-3 mb-3">
            <div class="col-12 px-0 hbs">
              <p class="heading">
                {{ coinDetailInfo.fullName }} ({{ coinDetailInfo.coinId }})
              </p>
              <div class="offer-buttons">
                <button
                 @click="handleClick"
                  class="green"
                >
                  ซื้อ
                </button>
                <button
                 @click="handleClick"
                  class="red"
                >
                  ขาย
                </button>
              </div>
            </div>
          </div>
          <div v-html="coinDetailInfo.information"></div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 cd-rite-side">
        <p class="p-0 m-0 heading">{{ $t('partperfomance') }}</p>
        <div class="row">
          <div class="col-5"></div>
          <div
            class="col-2 performance-title"
            style="
              font-family: 'Roboto Flex';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            "
          >
          {{ $t('price') }}
          </div>
          <div
            class="col-5 performance-title"
            style="
              font-family: 'Roboto Flex';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            "
          >
          {{ $t('volume') }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">{{ $t('last7day') }}</div>
          <div
            class="col-2"
            :class="getPerformanceClass(coinDetailInfo.pastPrice7Day)"
          >
            {{ coinDetailInfo.pastPrice7Day }}
          </div>
          <div
            class="col-5"
            :class="getPerformanceClass(coinDetailInfo.pastVol7Day)"
          >
            {{ coinDetailInfo.pastVol7Day }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">{{ $t('last30day') }}</div>
          <div
            class="col-2"
            :class="getPerformanceClass(coinDetailInfo.pastPrice30Day)"
          >
            {{ coinDetailInfo.pastPrice30Day }}
          </div>
          <div
            class="col-5"
            :class="getPerformanceClass(coinDetailInfo.pastVol30Day)"
          >
            {{ coinDetailInfo.pastVol30Day }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">{{ $t('last90day') }}</div>
          <div
            class="col-2"
            :class="getPerformanceClass(coinDetailInfo.pastPrice90Day)"
          >
            {{ coinDetailInfo.pastPrice90Day }}
          </div>
          <div
            class="col-5"
            :class="getPerformanceClass(coinDetailInfo.pastVol90Day)"
          >
            {{ coinDetailInfo.pastVol90Day }}
          </div>
        </div>
        <p class="p-0 mb-2 heading" style="margin-top: 32px">
          {{ $t('marketstatus') }}
          <svg
            id="market-status"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 13.3333H9.16667V11.6667H7.5V13.3333ZM8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM8.33333 15C4.65833 15 1.66667 12.0083 1.66667 8.33333C1.66667 4.65833 4.65833 1.66667 8.33333 1.66667C12.0083 1.66667 15 4.65833 15 8.33333C15 12.0083 12.0083 15 8.33333 15ZM8.33333 3.33333C6.49167 3.33333 5 4.825 5 6.66667H6.66667C6.66667 5.75 7.41667 5 8.33333 5C9.25 5 10 5.75 10 6.66667C10 8.33333 7.5 8.125 7.5 10.8333H9.16667C9.16667 8.95833 11.6667 8.75 11.6667 6.66667C11.6667 4.825 10.175 3.33333 8.33333 3.33333Z"
              fill="#677F8E"
            />
          </svg>
          <b-tooltip target="market-status" variant="light"
            >ข้อมูลเหรียญโดยอ้างอิงจากเว็บไซต์ Coinmarketcap
          </b-tooltip>
        </p>
        <div class="row" style="margin-top: 6px">
          <div class="col-5 performance-title">{{ $t('rankbymarketcap') }}</div>
          <div class="col-7 performance-value">
            #{{ coinDetailInfo.capRank }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">{{ $t('marketcapusd') }}</div>
          <div class="col-7 performance-value">
            {{ coinDetailInfo.marketCap }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">
            Total Supply ({{ coinDetailInfo.coinId }})
          </div>
          <div class="col-7 performance-value">
            {{ coinDetailInfo.totalSupply }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">{{ $t('maxsupply') }}</div>
          <div class="col-7 performance-value">
            {{ coinDetailInfo.maxSupply }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5 performance-title">{{ $t('website') }}</div>
          <div class="col-7 performance-link">
            <a :href="coinDetailInfo.infolink || ''" target="_blank">{{
              coinDetailInfo.infolink || ''
            }}</a>
          </div>
        </div>
        <div>
          <p class="p-0 mb-2 mt-4 heading">
            {{ $t('trendsignal') }}

            <span
              class="info-b-i"
              @mouseenter="showinfo = true"
              @mouseleave="showinfo = false"
            >
              <svg
                style="position: relative; top: -1px"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 13.3333H9.16667V11.6667H7.5V13.3333ZM8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM8.33333 15C4.65833 15 1.66667 12.0083 1.66667 8.33333C1.66667 4.65833 4.65833 1.66667 8.33333 1.66667C12.0083 1.66667 15 4.65833 15 8.33333C15 12.0083 12.0083 15 8.33333 15ZM8.33333 3.33333C6.49167 3.33333 5 4.825 5 6.66667H6.66667C6.66667 5.75 7.41667 5 8.33333 5C9.25 5 10 5.75 10 6.66667C10 8.33333 7.5 8.125 7.5 10.8333H9.16667C9.16667 8.95833 11.6667 8.75 11.6667 6.66667C11.6667 4.825 10.175 3.33333 8.33333 3.33333Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </p>
          <div>
            <div class="cd-info-box" v-if="showinfo">
              <div class="d-inline-block" style="width: 60px">Strong Buy</div>
              <div class="d-inline-block">
                : ขาขึ้นแข็งแรง มี Momentum สูงมีโอกาส ปรับตัวขึ้นรวดเร็ว
              </div>
              <br />
              <div class="d-inline-block" style="width: 60px">Buy</div>
              <div class="d-inline-block">
                : ทิศทางขาขึ้น มี Momentum ปานกลาง ราคาค่อยๆ ปรับตัวขึ้น
              </div>
              <br />
              <div class="d-inline-block" style="width: 60px">Neutral</div>
              <div class="d-inline-block">
                : Sideway ราคาแกว่งตัวในกรอบหรือช่วงราคา รอเลือกทิศทาง
              </div>
              <br />
              <div class="d-inline-block" style="width: 60px">Sell</div>
              <div class="d-inline-block">
                : ทิศทางขาลง มี Momentum ปานกลาง ราคาค่อยๆ ปรับตัวลง
              </div>
              <br />
              <div class="d-inline-block" style="width: 60px">Strong Sell</div>
              <div class="d-inline-block">
                : ขาลงแรง มี Momentum สูง มีโอกาสปรับตัว ลงอย่างรวดเร็ว
              </div>
            </div>
          </div>
          <div>
            <div class="row trading-signal">
              <div
                @click="
                  trendSignal = '15m';
                  changeTimesig('15', '');
                "
                class="col-2 time"
                :class="trendSignal === '15m' ? 'ts-active-tab' : ''"
              >
                15m
              </div>
              <div
                @click="
                  trendSignal = '30m';
                  changeTimesig('30', '');
                "
                class="col-2 time"
                :class="trendSignal === '30m' ? 'ts-active-tab' : ''"
              >
                30m
              </div>
              <div
                @click="
                  trendSignal = '1hr';
                  changeTimesig('1', 'h');
                "
                class="col-2 time"
                :class="trendSignal === '1hr' ? 'ts-active-tab' : ''"
              >
                1hr
              </div>
              <div
                @click="
                  trendSignal = '4hr';
                  changeTimesig('4', 'h');
                "
                class="col-2 time"
                :class="trendSignal === '4hr' ? 'ts-active-tab' : ''"
              >
                4hr
              </div>
              <div
                @click="
                  trendSignal = 'D';
                  changeTimesig('1', 'd');
                "
                class="col-2 time"
                :class="trendSignal === 'D' ? 'ts-active-tab' : ''"
              >
                D
              </div>
              <div
                class="d-flex align-items-center col-2 more-time"
                style="padding-left: 2px; padding-right: 1px"
              >
                <div
                  class="cdc"
                  tabindex="0"
                  @click="show_time_options = !show_time_options, trendSignal = null"
                  @blur="show_time_options = false"
                  style="width: 128px"
                >
                  <span
                    class="text"
                    :class="{
                  active: show_time_options,
                  active: !trendSignal,
                }"
                    >{{ selected_time_option }}</span
                  >
                  <span class="icon"
                    ><svg
                      width="7"
                      height="5"
                      viewBox="0 0 7 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"
                      />
                    </svg>
                  </span>
                  <div
                    class="options"
                    :class="show_time_options ? 'show' : 'hidden'"
                  >
                    <ul>
                      <li
                        @click="
                          selected_time_option = '1m';
                          changeTimesig('1', '');
                        "
                      >
                        1m
                      </li>
                      <li
                        @click="
                          selected_time_option = '3m';
                          changeTimesig('3', '');
                        "
                      >
                        3m
                      </li>
                      <li
                        @click="
                          selected_time_option = '5m';
                          changeTimesig('5', '');
                        "
                      >
                        5m
                      </li>
                      <li
                        @click="
                          selected_time_option = '5m';
                          changeTimesig('10', '');
                        "
                      >
                        10m
                      </li>
                      <li
                        @click="
                          selected_time_option = '2hr';
                          changeTimesig('2', 'h');
                        "
                      >
                      2hr
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div v-if="isloading" class="pt-2">
                <div class="loading-cotainer">
                  <div class="spinner"></div>
                </div>
              </div>
              <div v-else class="chart-section">
                <div class="labels-container">
                  <div class="d-flex flex-wrap labels">
                    <div class="label strong-sell">
                      Strong <br />
                      Sell
                    </div>
                    <div class="label sell">Sell</div>
                    <div class="label neutral">Neutral</div>
                    <div class="label buy">Buy</div>
                    <div class="label strong-buy">Strong Buy</div>
                  </div>
                </div>
                <div class="chart-container">
                  <highcharts
                    v-if="show"
                    :highcharts="hcInstance"
                    class="highcharts"
                    :options="chartOptions"
                    :callback="callback()"
                  ></highcharts>
                  <highcharts
                    v-if="!show"
                    :highcharts="hcInstance"
                    class="highcharts"
                    :options="chartOptions1"
                    :callback="callback()"
                  ></highcharts>
                </div>
              </div>
              <div class="row ts-info mb-5">
                <div class="col-6 title">Strong Trend</div>
                <div id="st" class="col-6 info-positive">
                  <div v-if="isloading" class="loading"></div>
                  <span v-else> <span v-if="sum_ts < 50">-</span><span v-else-if="sum_ts == 50">0</span>
<span v-else>+</span></span>
                </div>
                <div class="col-6 title">
                  Trend Ribbon
                  <span
                    v-if="!isloading"
                    :style="{
                      color: trendColor(data_strong.type_trend_ribbon),
                    }"
                  >
                    <!-- ({{ data_strong.type_trend_ribbon }}) -->
                  </span>
                </div>
                <div id="tr" class="col-6 info-neutral">
                  <div v-if="isloading" class="loading"></div>
                  <span v-else>
                    <span v-if="data_strong.type_trend_ribbon == 'buy'">+</span>
                    <span v-else>-</span></span
                  >
                </div>
                <div class="col-6 title">
                  MACD
                  <span
                    v-if="!isloading"
                    :style="{ color: trendColor(data_strong.type_macd) }"
                  >
                    <!-- ({{ data_strong.type_macd }}) -->
                  </span>
                </div>
                <div id="macd" class="col-6 info-positive">
                  <div v-if="isloading" class="loading"></div>
                  <span v-else
                    ><span v-if="data_strong.type_macd == 'buy'">+</span>
                    <span v-else>-</span></span
                  >
                </div>
                <div class="col-6 title">
                  RSI
                  <span
                    v-if="!isloading"
                    :style="{ color: trendColor(data_strong.type_rsi) }"
                  >
                    <!-- ({{ data_strong.type_rsi }}) -->
                  </span>
                </div>
                <div id="rsi" class="col-6 info-overbought">
                  <div v-if="isloading" class="loading"></div>
                  <span v-else
                    ><span v-if="data_strong.type_rsi == 'buy'">+</span>
                    <span v-else>-</span></span
                  >
                </div>
                <div class="col-6 title">
                  EMA (5,20)
                  <span
                    v-if="!isloading"
                    :style="{ color: trendColor(data_strong.type_ema) }"
                  >
                    <!-- ({{ data_strong.type_ema }}) -->
                  </span>
                </div>
                <div id="ema" class="col-6 info-positive">
                  <div v-if="isloading" class="loading"></div>
                  <span v-else><span v-if="data_strong.type_ema == 'buy'">+</span>
                    <span v-else>-</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QuoteModal />
    <TradeOnChart
      @show_trade_on_chart="
        (e) => {
          this.show_trade_on_chart = e;
        }
      "
      v-if="show_trade_on_chart"
      :currentCoin="currentCoin"
      :current_tab="current_tab"
      :data_success="data_success"
      :coinCurrentValue="coinCurrentValue"
      :iframeContainer="iframeContainer"
    />
    <PriceAlert1 :currentCoin="currentCoin" :priceInfo="priceInfo" />
    <PriceAlert2
      :currentCoin="currentCoin"
      :priceInfo="priceInfo"
      @createAlertPopup="showCreateAlert"
    />
    <AlertTutorial @handelAlert="handelAlert" />
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import { mapState, mapMutations } from "vuex";
import TradeOnChart from "./../chart/components/TradeOnChart.vue";
import QuoteModal from "./QuoteModal.vue";
import PriceAlert1 from "@/pages/chart/components/PriceAlert1.vue";
import PriceAlert2 from "@/pages/chart/components/PriceAlert2.vue";
import AlertTutorial from "@/pages/chart/components/AlertTutorialModal.vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stock from "highcharts/modules/stock";

stock(Highcharts);
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
exportingInit(Highcharts);
export default {
  components: {
    QuoteModal,
    PriceAlert1,
    PriceAlert2,
    AlertTutorial,
    TradeOnChart,
  },
  data() {
    return {
      //
      firstload:true,
      data_success: [
        {
          status: "fail",
          type: "",
          theme: "",
          price: 0,
        },
      ],
      favCoinRows_chart: false,
      show_trade_on_chart: false,
      current_tab: "buy",
      sum_ts: "0",
      sum_trend: 0,
      strong_trade: 0,
      hcInstance: Highcharts,
      show: false,
      iframeContainer: null,
      iframeContainer2: null,
      showinfo: false,
      showBtcText: true,
      show_timeline_options: false,
      selected_timeline_option: "More",
      show_indicator_options: false,
      selected_indicator_option: "Indicator",
      show_chart_type_options: false,
      selected_chart_type_option: "bars",
      //

      trendSignal: "D",
      showSearchBox: false,
      searchText: "",
      guageAttrs: {
        background: "red",
        p1: 0,
        p2: 20,
        p3: 40,
        p4: 60,
        p5: 80,
        p6: 100,
        high_low_value: 0,
      },
      show_time_options: false,
      selected_time_option: "More",
      showBtcText: true,
      active_timeframe: "D",
      show_timeline_options: false,
      selected_timeline_option: "More",
      show_indicator_options: false,
      selected_indicator_option: "Indicator",
      show_chart_type_options: false,
      selected_chart_type_option: "hollow-candles",
      //
      open_dropdown_right: false,
      open_dropdown_ratio: false,
      open_dropdown_coin: false,
      Value: "Bid / Offer",
      Value1: "0.01",
      Value2: "All Coins",
      coinSearchText: "",
      defaultPath: this.$store.state.currentPair,
      showSearchBox: false,
      selected_sell_score: null,
      selected_buy_score: null,
      currentCoin: {
        base: "",
        coin: "",
        symbol: "BTC/INR",
        fee: 0,
        spreadOrder: 0,
      },
      plate: {
        maxPostion: 20,
        askRows: [],
        bidRows: [],
      },
      ticker: {
        tickerRows: [],
      },
      priceInfo: {
        last: "0",
        chg: "0",
        high24: "0",
        low24: "0",
        vol24: "0",
        pChg: "0",
        textColor: "",
      },
      balance_trade: 25,
      MACD: "+",
      RSI: 0,
      EMA_5: "+",
      EMA_20: "+",
      coinMarket: [],
      T_Ribbon: 0,
      T_Ribbon_text: "",
      chartSymbolUrl:
        "",
      // chartSymbolUrl2:
      //   "/libchart/index.php?symbol=BTC%2FTHB&uid=004&user=4",
      test: "",
      currentScale: {
        coinScale: 8,
        bathScale: 2,
        fixScale: 2,
        minVolume: 0,
        maxVolume: 0,
      },
      tl_isLoading: true,
      tr_isLoading: true,
      trs_isLoading: true,
      dpoint: 2,
      favCoinRows: false,
      isUserLogin: false,
      coinimg: null,
      isloading: false,
      chartOptions1: [],
      data_strong: {
        type_strong_trend: "",
        type_trend_ribbon: "",
        type_macd: "",
        type_rsi: "",
        type_ema: "",
      },
      check_reload: 0,
      chartOptions: {
        chart: {
          type: "gauge",
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          backgroundColor: "transparent",
          height: 240,
          style: {
            fontFamily: "Roboto Flex",
            lineHeight: "14px",
          },
        },
        responsive: {
          rules: [
            {
              // condition: {
              //   maxWidth: 415,
              // },
              chartOptions: {
                chart: {
                  height: 240,
                },
              },
            },
          ],
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },

        pane: {
          startAngle: -90,
          endAngle: 90,
          background: [
            {
              backgroundColor: "transparent",
            },
          ],
        },

        // the value axis
        yAxis: {
          min: parseInt(100),
          max: parseInt(150),
          tickInterval: 90000000000,
          tickWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: "Speedometer",
          },
          plotBands: [
            {
              from: parseInt(100),
              to: parseInt(150),
              color: "#EB4751",
              innerRadius: "120%",
              outerRadius: "100%",
            },
            {
              from: parseInt(110),
              to: parseInt(120),
              color: "#DA868B",
              innerRadius: "120%",
              outerRadius: "100%",
            },
            {
              from: parseInt(120),
              to: parseInt(130),
              color: "#677F8E",
              innerRadius: "120%",
              outerRadius: "100%",
            },
            {
              from: parseInt(130),
              to: parseInt(140),
              color: "#A4DBB6",
              innerRadius: "120%",
              outerRadius: "100%",
            },
            {
              from: parseInt(140),
              to: parseInt(150),
              color: "#44A765",
              innerRadius: "120%",
              outerRadius: "100%",
            },
          ],
        },
        plotOptions: {
          gauge: {
            dataLabels: {
              enabled: false,
            },
            dial: {
              radius: "95%",
              backgroundColor: "#9BACB6",
            },
            pivot: {
              radius: 8,
              backgroundColor: "#9BACB6",
            },
          },
        },
        series: [
          {
            name: "price",
            data: [25],
            backgroundColor: "red",
          },
        ],
      },
      coinCurrentValue: 0,
      coinDetailInfo: {
        coinId: "",
        fullName: "",
        information: "",
        infolink: "",
        capRank: "",
        marketCap: "",
        totalSupply: "",
        maxSupply: "",
        pastPrice7Day: "",
        pastPrice30Day: "",
        pastPrice90Day: "",
        pastVol7Day: "",
        pastVol30Day: "",
        pastVol90Day: "",
        id_cancle_format: "",
      },
      userData: JSON.parse(localStorage.getItem("user-data")),
    };
  },
  metaInfo() {
    let pageTitle = "Coin Detail";
    return {
      title: pageTitle,
      titleTemplate: "%s | "+ this.TITLE ,
    };
  },
  computed: {
    filteredCoins() {
      const searchTerm = this.coinSearchText.toLowerCase();
      return this.coinMarket.filter((coin) =>
        coin.symbol.toLowerCase().includes(searchTerm)
      );
    },
    filteredCoinsImages() {
      return this.coinMarket.filter((coin) =>
        coin.symbol
          .toLowerCase()
          .includes(this.currentCoin.symbol.toLowerCase())
      )[0];
    },
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    this.chartSymbolUrl = '/libchart/index.php?symbol=BTC%2FINR&uid=003'
    if (this.userData && this.userData?.username !== undefined) {
      this.chartSymbolUrl += `&user=${this.userData.username}`;
    } else {
      this.chartSymbolUrl += `&user=demo003`;
    }
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
    }
    localStorage.setItem('UID', "003");
    this.init();
    this.defaultSymbol();
    this.getSymbolThumb();
    this.getBidAsk();
    this.getCoinDetailInfo();
    this.getTickers();
    this.updateDataTrade();
  },
  mounted() {
    const domain = window.origin;
    let iframe = document.getElementById("myIframe") || null;
    let iframe2 = document.getElementById("myIframe2") || null;
    window.addEventListener("message", (event) => {
      if (event.origin !== domain) return;
      if (event.data.type == "favi") {
        if (this.favCoinRows) {
          this.selectFav(this.currentCoin.symbol);
        } else {
          this.selectFav(this.currentCoin.symbol);
        }
      }
      return false;
    });
    iframe.onload = () => {
      if (iframe.contentWindow) {
        this.iframeContainer = iframe.contentWindow;
        setTimeout(() => {
          if (typeof this.iframeContainer.check_favicon === "function") {
            this.iframeContainer.check_favicon(this.favCoinRows);
          }
        }, 2000);
      }
    };
    // iframe2.onload = () => {
    //   if (iframe2.contentWindow) {
    //     this.iframeContainer2 = iframe.contentWindow;
    //     setTimeout(() => {
    //       if (typeof this.iframeContainer2.check_favicon === "function") {
    //         this.iframeContainer2.check_favicon(this.favCoinRows);
    //       }
    //     }, 2000);
    //   }
    // };
    window.addEventListener("message", (event) => {
      if (event.origin !== domain) return;
      
      if (event.data.orderType == 0) {
        this.get_tradeonchart_buy(event.data);
      }
      if (event.data.orderType == 1) {
        this.get_tradeonchart_sell(event.data);
      }
      if (event.data.orderType == 2) {
        this.get_tradeonchart_sell(event.data);
      }
      if (event.data.orderType == 3) {
        this.get_tradeonchart_buy(event.data);
      }
      return false;
    });
    window.addEventListener("click", this.checkIfClickedOutside);
    // iframe2.onload = () => {
    //   if (iframe2.contentWindow) {
    //     this.iframeContainer = iframe2.contentWindow;
    //   }
    // };

    this.isloading = true;
    setTimeout(() => {
      this.check_trade();
    }, 3000);
  },
  methods: {
    handleClick() {
      this.$router.push('/exchange/' + this.$store.state.currentPair);
      localStorage.setItem('symbol', this.$store.state.currentPair);
    },
    get_tradeonchart_buy(value) {
      this.show_trade_on_chart = true;
      this.current_tab = "buy";
      this.coinCurrentValue = value.price;
      this.data_success = [
        {
          status: "fail",
          type: "buy",
          price: value.price,
          theme: "#9bacb6",
          percent: 0,
        },
      ];
      // None = 0,
      // Market = 1,
      // Condition = 2,
      // OpenOrder = 3
      // var buy = this.iframeContainer.addTrendlineOrder("buy", "buy", this.coinCurrentValue, "#9bacb6", 2, true, true)
      // var takeprofit = this.iframeContainer.addTrendlineOrder("takeprofit", "takeprofit", this.coinCurrentValue, "#40994f", 2, true, true)
      // var stoploss = this.iframeContainer.addTrendlineOrder("stoploss", "stoploss", this.coinCurrentValue, "#e04152", 2, true, true)
    },
    get_tradeonchart_sell(value) {
      this.show_trade_on_chart = true;
      this.current_tab = "sell";
      this.coinCurrentValue = value.price;
      // addTrendlineOrder("11", "title", 2500000, "#111110", 2, true, true)
      this.data_success = [
        {
          id: 0,
          status: "fail",
          type: "sell",
          price: value.price,
          theme: "#e04152",
          percent: 0,
        },
      ];
      // var buy = this.iframeContainer.addTrendlineOrder("buy", "buy", this.coinCurrentValue, "#9bacb6", 2, true, true)
      // var takeprofit = this.iframeContainer.addTrendlineOrder("takeprofit", "takeprofit", this.coinCurrentValue, "#40994f", 2, true, true)
      // var stoploss = this.iframeContainer.addTrendlineOrder("stoploss", "stoploss", this.coinCurrentValue, "#e04152", 2, true, true)
    },
    cancle_order(value) {
      this.id_cancle_format = value.split("_")[0];
      if (this.data_his.length > 0) {
        for (let i = 0; i < this.data_his.length; i++) {
          if (this.id_cancle_format == this.data_his[i].orderId) {
            this.selectedOrder = this.data_his[i];
            this.$bvModal.show("cancel-modal");
          }
        }
      }
    },
    trendColor(value) {
      return value === "buy" ? "#40994f" : "#de2d40";
    },
    callback() {},
    fetchChartData() {
      // โหลดข้อมูลจาก API และอัปเดตตัวแปร chartOptions
      // เมื่อมีการเปลี่ยนแปลงข้อมูล
      // ตัวอย่าง: axios.get('/api/chart-data')
      //   .then(response => {
      //     this.chartOptions = response.data;
      //   })
      //   .catch(error => {
      //     console.error('Error fetching chart data: ', error);
      //   });

      // ตัวอย่างโค้ดทดสอบ (ลบออกเมื่อใช้ API จริง)
      this.isloading = true;
      setTimeout(() => {
        let RSI = this.sum_ts;
        this.chartOptions = {
          chart: {
            type: "gauge",
            plotShadow: false,
            backgroundColor: "transparent",
            styledMode: false,
            height: 240,
            style: {
              fontFamily: "Roboto Flex",
              lineHeight: "14px",
            },
          },
          responsive: {
            rules: [
              {
                // condition: {
                //   maxWidth: 415,
                // },
                chartOptions: {
                  chart: {
                    height: 240,
                  },
                },
              },
            ],
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },

          pane: {
            startAngle: -90,
            endAngle: 90,
            background: [
              {
                backgroundColor: "transparent",
                borderWidth: 0,
              },
            ],
          },

          // the value axis
          yAxis: {
            min: parseInt(this.guageAttrs.p1),
            max: parseInt(this.guageAttrs.p6),
            tickInterval: 90000000000,
            tickWidth: 0,
            labels: {
              enabled: false,
            },
            title: {
              text: this.guageTitle,
            },
            plotBands: [
              {
                from: parseInt(this.guageAttrs.p1),
                to: parseInt(this.guageAttrs.p2),
                color: "#EB4751",
                innerRadius: "120%",
                outerRadius: "100%",
              },
              {
                from: parseInt(this.guageAttrs.p2),
                to: parseInt(this.guageAttrs.p3),
                color: "#DA868B",
                innerRadius: "120%",
                outerRadius: "100%",
              },
              {
                from: parseInt(this.guageAttrs.p3),
                to: parseInt(this.guageAttrs.p4),
                color: "#677F8E",
                innerRadius: "120%",
                outerRadius: "100%",
              },
              {
                from: parseInt(this.guageAttrs.p4),
                to: parseInt(this.guageAttrs.p5),
                color: "#A4DBB6",
                innerRadius: "120%",
                outerRadius: "100%",
              },
              {
                from: parseInt(this.guageAttrs.p5),
                to: parseInt(this.guageAttrs.p6),
                color: "#44A765",
                innerRadius: "120%",
                outerRadius: "100%",
              },
            ],
          },
          plotOptions: {
            gauge: {
              dataLabels: {
                enabled: false,
              },
              dial: {
                radius: "95%",
                backgroundColor: "#9BACB6",
              },
              pivot: {
                radius: 8,
                backgroundColor: "#9BACB6",
              },
            },
          },
          series: [
            {
              name: "price",
              data: [Number(RSI)],
              backgroundColor: "red",
            },
          ],
        };
        this.chartOptions1 = this.chartOptions;
        this.isloading = false;
      }, 500);
    },
    ...mapMutations(["setCurrentPair"]), // map mutation from Vuex store
    addIndicators() {
      if (typeof this.iframeContainer.iAddIndicators === "function") {
        this.iframeContainer.iAddIndicators();
      }
    },
    checkIfClickedOutside(event) {
      const CoinDropdown = this.$refs.coinDropdown;
      if (CoinDropdown && !CoinDropdown.contains(event.target)) {
        this.showSearchBox = false;
      }
    },
    check_trade() {
      if (this.iframeContainer != null) {
        this.checkTrendSignal();
      }
    },
    changeChartType(type) {
      if (typeof this.iframeContainer.iPriceStyleChange === "function") {
        this.iframeContainer.iPriceStyleChange(type);
      }
    },
    async changeTime(time, unit) {
      this.isloading = true;
      if (typeof this.iframeContainer.iTimeFrameChange === "function") {
        this.iframeContainer.iTimeFrameChange(time, unit);
        // this.iframeContainer.iTimeFrameChange(time, unit);

        var send_signal = this.checkTrendSignal();
      }
    },
    async changeTimesig(time, unit) {
      this.isloading = true;
      var get_iframe = document.getElementById("myIframe");
      if (get_iframe) {
        // get_iframe.style.height = "0";
      }
      if (typeof this.iframeContainer.iTimeFrameChange === "function") {
        // this.iframeContainer.iTimeFrameChange(time, unit);
        this.iframeContainer.iTimeFrameChange(time, unit);
        var send_signal = this.checkTrendSignal();
        // get_iframe.style.height = "100%";
      }
    },

    chartFullScreen() {
      if (typeof this.iframeContainer.iChartFullScreen === "function") {
        this.iframeContainer.iChartFullScreen();
      }
    },
    handelAlertPopup() {
      let showTut = localStorage.getItem("alertTut");
      if (showTut  == 'false') {
        this.$bvModal.show("alert-tutorial");
      } else {
        this.$bvModal.show("price-alert-2");
      }
    },
    showCreateAlert(e) {
      if (e) {
        this.$bvModal.show("price-alert-1");
      }
    },
    handelAlert(e) {
      if (e) {
        this.$bvModal.show("price-alert-2");
      }
    },
    init() {
      var params = this.$route.params.pair;
      if (params == undefined) {
        this.$router.push("/market/coin-detail/" + this.defaultPath);
        params = this.defaultPath;
      }
    },
    defaultSymbol() {
      this.check_reload++;
      if (this.$route.params.pair == undefined) {
        this.currentCoin.symbol = "BTC/INR";
        this.$route.params["pair"] = "btc_inr";
      } else {
        let pair = this.$route.params.pair;
        let dataArr = pair.split("_");
        this.currentCoin.symbol = (dataArr[0] + "/" + dataArr[1]).toUpperCase();
        let symbolEncode = encodeURIComponent(this.currentCoin.symbol);
        this.chartSymbolUrl =
          "/libchart/index.php?symbol=" + symbolEncode +'&uid=003'
          if (this.userData && this.userData?.username !== undefined) {
      this.chartSymbolUrl += `&user=${this.userData.username}`;
    } else {
      this.chartSymbolUrl += `&user=demo003`;
    }
        // this.chartSymbolUrl2 =
        //   "/libchart/index.php?symbol=" + symbolEncode +'&uid=004&user=4'
      }
      if (this.check_reload >= 2) {
        setTimeout(() => {
          if (this.active_timeframe == "15m") {
            this.changeTimesig("15", "");
          }
          if (this.active_timeframe == "30m") {
            this.changeTimesig("30", "");
          }
          if (this.active_timeframe == "1hr") {
            this.changeTimesig("1", "h");
          }
          if (this.active_timeframe == "4hr") {
            this.changeTimesig("4", "h");
          }
          if (this.active_timeframe == "D") {
            this.changeTimesig("1", "d");
          }
        }, 1500);
      }

      this.coinName = this.currentCoin.symbol.split("/")[0];
    },
    updateDataTrade() {
      if (this.stompClient) {
        this.stompClient.ws.close();
      }
      var stompClient = null;
      var that = this;
      var symbol = that.currentCoin.symbol;
      var url = this.MARKET_WS_URL + "/market/market-ws";
      var socket = new SockJS(url);
      stompClient = Stomp.over(socket);
      this.stompClient = stompClient;
      stompClient.debug = false;

      stompClient.connect({}, function (frame) {
        stompClient.subscribe("/topic/market/thumb", function (msg) {
          var resp = JSON.parse(msg.body);
          that.updatePriceInfo(resp);
        });

        stompClient.subscribe("/topic/market/trade/" + symbol, function (msg) {
          var resp = JSON.parse(msg.body);
          // console.log(resp);
          // console.log(msg);

          that.updateTickers(resp);
        });

        stompClient.subscribe(
          "/topic/market/trade-plate/" + symbol,
          function (msg) {
            var resp = JSON.parse(msg.body);
            // console.log(resp);
            // console.log(msg);

            that.updateBidAsk(resp);
          }
        );
      });
    },
    getBidAsk() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      HTTP.post("/market/exchange-plate", params, {
        emulateJSON: true,
      }).then((response) => {
        //console.log(response);
        var resp = response.data;
        if (resp.ask) {
          var asks = resp.ask;
          this.plate.askRows = [];
          for (var i = this.plate.maxPostion - 1; i >= 0; i--) {
            var ask = {
              price: asks[i]?.price.toFixed(2),
              amount: asks[i]?.amount.toFixed(6),
              total: (asks[i]?.price * asks[i]?.amount).toFixed(2),
            };
            this.plate.askRows.push(ask);
            this.tl_isLoading = false;
          }
        }
        if (resp.bid) {
          var bid = resp.bid;
          bid.map((item) => {
            let bidData = {
              price: item?.price,
              amount: item.amount,
              total: item?.price * item?.amount,
            };
            this.plate.bidRows.push(bidData);
          });
          if (this.plate.bidRows.length > 20) {
            this.plate.bidRows = this.plate.bidRows.slice(0, 20);
          }
          // console.log(this.plate.bidRows);
        }
      });
    },
    getCoinDetailInfo() {
      let symbolAt = this.currentCoin.symbol;
      let symbolCoin = symbolAt.split("/")[0];
      //HTTP.post("/market/exchange-plate", params,
      HTTP.get("/market/coin/detail/" + symbolCoin, {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        this.coinDetailInfo = { ...resp };
      });
    },
    updateBidAsk(resp) {
      // console.log(resp);
      var that = this;
      if (resp.direction == "SELL") {
        var asks = resp.items;
        this.plate.askRows = [];
        for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
          var ask = {
            price: asks[i]?.price.toFixed(2),
            amount: asks[i]?.amount.toFixed(6),
            total: (asks[i]?.price * asks[i]?.amount).toFixed(2),
          };
          that.plate.askRows.push(ask);
        }
        if (that.plate.askRows.length > 20) {
          that.plate.askRows = that.plate.askRows.slice(0, 20);
        }
      } else {
        var bids = resp.items;
        this.plate.bidRows = [];
        for (var i = that.plate.maxPostion - 1; i >= 0; i--) {
          var bid = {
            price: bids[i]?.price.toFixed(2),
            amount: bids[i]?.amount.toFixed(6),
            total: (bids[i]?.price * bids[i]?.amount).toFixed(2),
          };
          that.plate.bidRows.unshift(bid);
        }
        if (that.plate.bidRows.length > 20) {
          that.plate.bidRows = that.plate.bidRows.slice(0, 20);
        }
      }
    },
    getTickers() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/latest-trade", params, {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        if (resp.length > 0) {
          this.priceInfo.last = resp[0]?.price;
          // updateing selected value
          this.selected_sell_score = this.priceInfo.last.toFixed(2);
          this.selected_buy_score = this.priceInfo.last.toFixed(2);
          // updateing selected value
          for (var i = 0; i < resp.length; i++) {
            let date = new Date(resp[i].time);
            let hours = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            let second = ("0" + date.getSeconds()).slice(-2);
            let time = hours + ":" + minute + ":" + second;
            resp[i].price = resp[i]?.price.toFixed(2);
            resp[i].amount = resp[i]?.amount.toFixed(6);
            resp[i].time = time;
            this.ticker.tickerRows.push(resp[i]);
            this.trs_isLoading = false;
          }
        }
        if (this.ticker.tickerRows.length > 20) {
          this.ticker.tickerRows = this.ticker.tickerRows.slice(0, 20);
        }
      });
    },
    updateTickers(resp) {
      var that = this;
      if (resp != undefined) {
        if (resp.length > 0) {
          for (var i = 0; i < resp.length; i++) {
            let date = new Date(resp[i].time);
            let hours = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            let second = ("0" + date.getSeconds()).slice(-2);
            let time = hours + ":" + minute + ":" + second;
            resp[i].price = resp[i]?.price.toFixed(2);
            resp[i].amount = resp[i]?.amount.toFixed(6);
            resp[i].time = time;
            that.priceInfo.last = resp[i]?.price;
            that.ticker.tickerRows.unshift(resp[i]);
          }
        }
        if (that.ticker.tickerRows.length > 20) {
          that.ticker.tickerRows = that.ticker.tickerRows.slice(0, 20);
        }
      }
    },
    chartFullScreen() {
      this.iframeContainer.iChartFullScreen();
    },
    getSymbolThumb() {
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/symbol-thumb", {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        this.coinMarket = [];
        for (let i = 0; i < resp.length; i++) {
          let symbolArr = resp[i].symbol.split("/");
          let symbol = (symbolArr[0] + "_" + symbolArr[1]).toLowerCase();
          let color = "";
          if (resp[i].chg > 0) {
            color = "green";
          } else if (resp[i].chg < 0) {
            color = "red";
          }
          let coinMarket = {
            symbol: resp[i].symbol,
            price: resp[i].close,
            pChg: resp[i].chg,
            volume: resp[i].volume,
            url: "/market/coin-detail/" + symbol,
            color: color,
            logoFileId:
              this.MARKET_WS_URL + "/market/coin/image/" + resp[i].logoFileId,
          };
          this.coinMarket.push(coinMarket);
          this.tr_isLoading = false;
          // console.log(coinMarket);
          if (this.currentCoin.symbol == resp[i].symbol) {
            if (resp[i].chg < 0) {
              this.priceInfo.textColor = "red";
            } else if (resp[i].chg > 0) {
              this.priceInfo.textColor = "green";
            }
            this.priceInfo.chg = resp[i].change;
            this.priceInfo.high24 = resp[i].high;
            this.priceInfo.low24 = resp[i].low;
            this.priceInfo.vol24 = resp[i].volume;
            this.priceInfo.pChg = resp[i].chg;
            if (this.isUserLogin) {
              HTTP.post("uc/member/get-member-favorite").then((result) => {
                let memberFavorite = result.data;
                if (memberFavorite.code == 0) {
                  for (let key in memberFavorite.data) {
                    let typeSymbol = resp[i].symbol;
                    if (memberFavorite.data[key].coinId == typeSymbol) {
                      if (memberFavorite.data[key].favorite) {
                        this.favCoinRows = true;
                        break;
                      } else {
                        this.favCoinRows = false;
                      }
                    } else {
                      this.favCoinRows = false;
                    }
                  }
                }
              });
            }
          }
        }
      });
    },
    updatePriceInfo(resp) {
      for (let i = 0; i < this.coinMarket.length; i++) {
        if (this.coinMarket[i].symbol == resp.symbol) {
          this.coinMarket[i].price = resp.close;
          this.coinMarket[i].pChg = resp.chg;
          if (resp.chg > 0) {
            this.coinMarket[i].color = "green";
          } else if (resp.chg < 0) {
            this.coinMarket[i].color = "red";
          }

          break;
        }
      }

      if (this.currentCoin.symbol == resp.symbol) {
        if (resp.chg < 0) {
          this.priceInfo.textColor = "red";
        } else if (resp.chg > 0) {
          this.priceInfo.textColor = "green";
        }

        this.priceInfo.chg = resp.change;
        this.priceInfo.high24 = resp.high;
        this.priceInfo.low24 = resp.low;
        this.priceInfo.vol24 = resp.volume;
        this.priceInfo.pChg = resp.chg;
      }
    },
    setCoinText() {
      setTimeout(() => {
        if (this.coinSearchText == "") {
          this.showBtcText = true;
        }
      }, 500);
    },
    selectMarketSymbol(url, svgHtmlString) {
      let pair = url.split("/").pop();
      this.setCurrentPair(pair);
      //  setTimeout(() => {
      //     if (this.active_timeframe == "15m") {
      //       this.changeTime("15", "");
      //     }
      //     if (this.active_timeframe == "30m") {
      //       this.changeTime("30", "");
      //     }
      //     if (this.active_timeframe == "1hr") {
      //       this.changeTime("1", "h");
      //     }
      //     if (this.active_timeframe == "4hr") {
      //       this.changeTime("4", "h");
      //     }
      //     if (this.active_timeframe == "D") {
      //       this.changeTime("1", "d");
      //     }
      //   }, 1500);
      this.showBtcText = true;
      this.showSearchBox = false;
      this.coinSearchText = "";
      if (url != this.$route.path) {
        this.$router.push(url);
      }
      this.coinimg = svgHtmlString;
      //console.log(url);
      this.ticker.tickerRows = [];
      this.plate.bidRows = [];
      // this.plate.askRows = []
      this.defaultSymbol();
      this.getSymbolThumb();
      this.getBidAsk();
      this.getCoinDetailInfo();
      this.getTickers();
      this.updateDataTrade();
      this.updateTickers();
      var get_iframe = document.getElementById("myIframe");
      if (get_iframe) {
        get_iframe.style.height = "0";
      }
      setTimeout(() => {
        get_iframe.style.height = "100%";
      }, 5000);

      // console.log(this.showBtcText);
    },
    checkTrendSignal() {
      var get_iframe = document.getElementById("myIframe");
      if (get_iframe) {
        get_iframe.style.height = "0";
      }
      if (!this.isloading) {
        this.isloading = true;
      }
      if (this.iframeContainer.reset_indicatorsmobile != undefined) {
        // this.iframeContainer.reset_indicatorsmobile();
      }
      setTimeout(() => {
        if (this.iframeContainer.iTradesignal_get != undefined) {
          var signal = this.iframeContainer.iTradesignal_get(false,this.firstload);
        }
        if (signal != false && signal != undefined) {
          let sum = signal.MACD.volume_high - signal.MACD.volume_low;
          this.MACD = this.$MyFunc.NumberFormat(sum, 2);
          this.RSI = this.$MyFunc.NumberFormat(signal.RSI_Trade.volume, 2);
          this.EMA_5 = this.$MyFunc.NumberFormat(signal.EMA.volume_5, 2);
          this.EMA_20 = this.$MyFunc.NumberFormat(signal.EMA.volume_20, 2);
          this.strong_trade = this.$MyFunc.NumberFormat(
            signal.strong_trade.volume_strongtrade,
            2
          );
          if (signal.TradeRibbon.value_ribbon != undefined) {
            this.T_Ribbon = this.$MyFunc.NumberFormat(
              signal.TradeRibbon.value_ribbon,
              2
            );
            this.T_Ribbon_text = signal.TradeRibbon.value_type;
          }
          this.sum_trend = 0;
          this.data_strong.type_trend_ribbon = signal.TradeRibbon.value_type;
          this.data_strong.type_macd = signal.MACD.value_type;
          this.data_strong.type_rsi = signal.RSI_Trade.value_type;
          this.data_strong.type_ema = signal.EMA.value_type;
          // if (signal.TradeRibbon.value_type == "buy") {
          //   this.sum_trend += 100;
          // }
          // if (signal.TradeRibbon.value_type == "sell") {
          //   this.sum_trend -= 100;
          // }
          // if (signal.EMA.value_type == "buy") {
          //   this.sum_trend += 100;
          // }
          // if (signal.EMA.value_type == "sell") {
          //   this.sum_trend -= 100;
          // }
          // if (signal.MACD.value_type == "buy") {
          //   this.sum_trend += 100;
          // }
          // if (signal.MACD.value_type == "sell") {
          //   this.sum_trend -= 100;
          // }
          // if (signal.RSI_Trade.value_type == "buy") {
          //   this.sum_trend += 100;
          // }
          // if (signal.RSI_Trade.value_type == "sell") {
          //   this.sum_trend -= 100;
          // }
          const neutralThreshold = 50; // ปรับค่านี้ตามต้องการ
          const start = -100;
          const max = 100;
          // var sum_t = this.sum_trend / 4;
          // if (sum_t == -100) {
          //   this.sum_ts = 0;
          // } else if (sum_t == -75) {
          //   this.sum_ts = 12.5;
          // } else if (sum_t == -50) {
          //   this.sum_ts = 25;
          // } else if (sum_t == -25) {
          //   this.sum_ts = 37.5;
          // } else if (sum_t == 0) {
          //   this.sum_ts = 50;
          // } else if (sum_t == 25) {
          //   this.sum_ts = 62.5;
          // } else if (sum_t == 75) {
          //   this.sum_ts = 75;
          // } else if (sum_t == 100) {
          //   this.sum_ts = 100;
          // }
          var st = document.getElementById("st");
          var tr = document.getElementById("tr");
          var macd = document.getElementById("macd");
          var rsi = document.getElementById("rsi");
          var ema = document.getElementById("ema");

          st.classList.remove("info-positive");
          st.classList.remove("info-overbought");
          st.classList.remove("info-neutral");
          tr.classList.remove("info-positive");
          tr.classList.remove("info-overbought");
          tr.classList.remove("info-neutral");
          macd.classList.remove("info-positive");
          macd.classList.remove("info-overbought");
          macd.classList.remove("info-neutral");
          rsi.classList.remove("info-positive");
          rsi.classList.remove("info-overbought");
          rsi.classList.remove("info-neutral");
          ema.classList.remove("info-positive");
          ema.classList.remove("info-overbought");
          ema.classList.remove("info-neutral");
          this.sum_ts = signal.strong_trade.value_strongtrade
          if (this.sum_ts == 50) {
            st.classList.add("info-neutral");
          }
          if (this.sum_ts < 50) {
            st.classList.add("info-overbought");
          }
          if (this.sum_ts > 50) {
            st.classList.add("info-positive");
          }

          if (this.data_strong.type_trend_ribbon == "buy") {
            tr.classList.add("info-positive");
          } else {
            tr.classList.add("info-overbought");
          }
          if (this.data_strong.type_macd == "buy") {
            macd.classList.add("info-positive");
          } else {
            macd.classList.add("info-overbought");
          }
          if (this.data_strong.type_rsi == "buy") {
            rsi.classList.add("info-positive");
          } else {
            rsi.classList.add("info-overbought");
          }
          if (this.data_strong.type_ema == "buy") {
            ema.classList.add("info-positive");
          } else {
            ema.classList.add("info-overbought");
          }
          // info-positive สีเขียว
          // info-overbought สีแดง
          // info-neutral สีธรรมชาติ
          this.firstload = false
          this.fetchChartData();
        }
        if (get_iframe) {
          get_iframe.style.height = "100%";
        }
      }, 1000);
    },
    checkTimeOption(time) {
      this.selected_timeline_option = time;
    },
    selectFav(symbol) {
      if (this.favCoinRows) {
        this.favCoinRows = false;
      } else {
        this.favCoinRows = true;
      }
      HTTP.post("/uc/member/update-member-favorite", {
        coinId: symbol,
        isFavorite: this.favCoinRows,
      }).then((response) => {
        if (response.data.code == 0) {
          this.getSymbolThumb();
          if(this.currentCoin.symbol == symbol){
            if (typeof this.iframeContainer.check_favicon === "function") {
            this.iframeContainer.check_favicon(this.favCoinRows);
          }
          }
        }
      });
    },
    getPerformanceClass(value) {
      return {
        "performance-value-negative": value.includes("-"),
        "performance-value-positive": !value.includes("-"),
      };
    },
  },
  watch: {
    data_success(value) {
      if (value[0].status == "success") {
        this.show_trade_on_chart = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: iconFont;
  src: url("../../assets/icons/chart-icon/scx-icons/scx-icons.woff");
}

.cd-chart-topbar {
  width: 100%;
  min-height: 44px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 5px;
  padding: 6px;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: relative;

  .timeframe {
    border: 1px solid #28363e;
    border-radius: 4px;
    padding: 2px;
    margin-left: 8px;

    button {
      width: 38px;
      height: 22px;
      color: #677f8e;
      background-color: transparent;
      border: none;
    }

    button.active {
      color: white;
      background-color: #2c3b44;
      border-radius: 2px;
    }

    .tf-sc {
      position: relative;
      display: inline;
      border-radius: 4px;

      .text {
        display: inline-block;
        width: 57.83px;
        color: #677f8e;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 3px;
        cursor: pointer;
      }

      span.active {
        color: white;
        background-color: #2c3b44;
        border-radius: 2px;
      }

      svg {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      .options {
        position: absolute;
        left: 0;
        background-color: #1c262b;
        width: 100%;
        text-align: left;

        ul {
          padding: 0;
          margin: 0;

          li {
            display: flex;
            align-items: center;
            height: 30px;
            border-radius: 4px;
            padding-left: 5px;
            color: #677f8e;
            cursor: pointer;

            &:hover {
              color: white;
              background-color: #2c3b44;
            }
          }
        }
      }
    }
  }

  .ic-sc {
    border: 1px solid #28363e;
    border-radius: 4px;
    margin-left: 8px;
    position: relative;
    width: 77px;
    height: 28px;

    .text {
      width: 100%;
      height: 100%;
      padding-left: 8px;
      padding-top: 6px;
      display: inline-block;
      color: #677f8e;
      cursor: pointer;
    }

    span.active {
      color: white;
      background-color: #2c3b44;
      border-radius: 2px;
    }

    svg {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }

    .options {
      position: absolute;
      left: 0;
      background-color: #1c262b;
      width: 100%;
      text-align: left;

      ul {
        padding: 0;
        margin: 0;

        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          padding-left: 5px;
          color: #677f8e;

          &:hover {
            color: white;
            background-color: #2c3b44;
          }
        }
      }
    }
  }

  .ctype-sc {
    border: 1px solid #28363e;
    border-radius: 4px;
    position: relative;
    width: 35px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    .text {
      display: inline-block;
      color: #677f8e;
      font-family: iconFont;
      width: 100%;
      height: 100%;
      font-size: 13px;
      cursor: pointer;
    }

    span.active {
      color: white;
      background-color: #2c3b44;
      border-radius: 2px;
    }

    svg {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }

    .options {
      position: absolute;
      left: 0;
      background-color: #1c262b;
      width: 172px;
      border-radius: 4px;
      text-align: left;

      ul {
        padding: 0;
        margin: 0;

        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          padding-left: 5px;
          color: #677f8e;

          &:hover {
            color: white;
            background-color: #2c3b44;
          }
        }
      }
    }
  }

  .ct-alert {
    border: 1px solid #28363e;
    border-radius: 4px;
    position: relative;
    margin-left: 21px;
    width: 60px;
    height: 28px;
    padding-left: 12px;
    color: #677f8e;

    button {
      width: 100%;
      height: 100%;
    }

    svg {
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }

  span.expand {
    position: absolute;
    right: 0;
  }

  span.favorite,
  span.save,
  span.capture,
  span.expand,
  span.setting {
    color: #677f8e;
    border: 1px solid #28363e;
    border-radius: 4px;
    padding: 6px;
    margin-left: 8px;
    cursor: pointer;
  }

  span.favorite {
    position: relative;
    padding-left: 25px;
    padding-right: 10px;

    svg {
      left: 8px;
      position: absolute;
    }
  }

  span.chart-icon {
    font-family: iconFont;
    padding-right: 10px;
  }

  span.bars::before {
    content: "\6a";
  }

  span.colored-bars::before {
    content: "\6f";
    color: green;
    margin-left: -6px;
  }

  span.colored-bars::after {
    content: "\6e";
    color: red;
  }

  span.candles::before {
    content: "\6b";
  }

  span.hollow-candles::before {
    content: "\49";
  }

  span.heikin-ashi::before {
    content: "\48";
  }

  span.renko::before {
    content: "\35";
  }

  span.line-break::before {
    content: "\51";
  }

  span.kagi::before {
    content: "\4e";
  }

  span.line::before {
    content: "\4f";
  }

  span.mountain::before {
    content: "\53";
  }

  span.point-figure::before {
    content: "\57";
  }
}

.cdc {
  border-radius: 4px;
  position: relative;
  height: 22px;
  display: inline-block;

  .text {
    width: 100%;
    height: 100%;
    padding-left: 8px;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    color: #677f8e;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  span.active {
    color: white;
    background-color: #2c3b44;
    border-radius: 2px;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .options {
    position: absolute;
    left: 0;
    background-color: #28363e;
    width: 100%;
    text-align: left;
    z-index: 20;
    top: 25px;
    border-radius: 4px;

    ul {
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 4px;
        padding-left: 5px;
        color: #d6dde1;

        &:hover {
          color: white;
          background-color: #191b1c;
        }
      }
    }
  }
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.paddingLeft {
  padding-left: 40px !important;
}

.chart-section {
  position: relative;
  margin-top: 30px;
  margin-bottom: 5px;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  left: -20px;

  .labels {
    top: -21px;
    right: 0;
    left: 0;
    position: absolute;
    justify-content: center;

    .label {
      text-align: center;
    }

    .strong-sell {
      width: 60px;
      color: #eb4751;
      margin-top: 90px;
      position: relative;
      left: 6px;
    }

    .sell {
      width: 50px;
      color: #da868b;
      margin-right: 5px;
      margin-top: 35px;
    }

    .neutral {
      color: #677f8e;
      width: 100px;
      margin-top: 8px;
    }

    .buy {
      color: #a4dbb6;
      width: 50px;
      margin-left: 5px;
      margin-top: 34px;
    }

    .strong-buy {
      color: #44a765;
      width: 60px;
      margin-top: 90px;
      position: relative;
      right: 6px;
    }
  }
}

.cd-tb {
  .top-search-row {
    padding: 12px 0;
  }

  .search-container {
    position: relative;
    width: 100%;

    .row {
      padding: 4.5px 0;

      &:hover {
        background-color: #28363e;
        cursor: pointer;
      }
    }

    .searching-box {
      position: absolute;
      width: 360px;
      height: 480px;
      background-color: #1c262b;
      top: 44px;
      z-index: 50;
      padding: 10px;
      overflow-x: hidden;
      overflow-y: scroll;

      .search-title {
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #d6dde1;
        margin-bottom: 7px;
        margin-top: 2px;
      }

      p {
        margin-bottom: 0;
      }

      .vol {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #677f8e;
        width: 135px;
      }

      .coin-img {
        width: 23px;
      }

      .price {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #d6dde1;
      }

      .percent {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }

      .coin-text {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #d6dde1;

        span.pair-text {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #677f8e;
        }
      }
    }

    .coins-search-input {
      background-color: #191b1c;
      color: white;
      width: 219px;
      font-size: 18px;
      line-height: 24px;
      caret-color: #09BE8B;
      font-weight: 600;
      border-radius: 4px !important;
      padding-top: 7.9px;
      padding-bottom: 7.9px;
      padding-right: 60px;
      border: none;
    }

    .btc-text-true {
      padding-left: 40px;
    }

    .btc-text-false {
      padding-left: 40px;
    }

    .coins-search-input::placeholder {
      color: #d6dde1;
    }

    .search-svg {
      position: absolute;
      left: 186px;
      margin-right: 11px;
      margin-top: 8px;
      pointer-events: none;
    }

    .coin-image {
      position: absolute;
      font-size: 18px;
      left: 10px;
      top: 4.5px;
      pointer-events: none;

      .coin-img {
        width: 23px;
      }

      .btc-text {
        color: white;
        position: relative;
        top: 1.5px;
        margin-left: 7px;
        display: inline-block;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .trading-scores {
    .live-amount-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }

    .live-amount-title.red {
      color: #de2d40;
    }

    .live-amount-title.green {
      color: #40994f;
    }

    .live-amount-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-left: 6px;
      margin-right: 14px;
    }

    .live-amount-value.red {
      color: #de2d40;
    }

    .live-amount-value.green {
      color: #40994f;
    }

    .title {
      color: #9bacb6;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 6px;
    }

    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-right: 14px;
    }
  }
}

.cd-rite-side {
  border-left: 1px solid #28363e;
  padding: 0;
  padding: 24px;
  padding-top: 21px;
}

.trading-scores {
  .live-amount-title {
    color: #de2d40;
    font-family: "Roboto Flex";
    font-size: 18px;
    line-height: 24px;
  }

  .live-amount-value {
    color: #de2d40;
    font-size: 14px;
    margin-left: 8px;
    margin-right: 16px;
  }

  .title {
    display: inline-block;
    color: #9bacb6;
    font-size: 14px;
    line-height: 24px;
    font-family: "Roboto Flex";
    margin-right: 8px;
    font-weight: 400;
  }

  .value {
    font-weight: 500;
    font-size: 14px;
    margin-right: 16px;
    line-height: 24px;
    font-family: "Roboto Flex";
  }
}

.live-amounts {
  display: inline-block;
  margin: 8px 0;
}

.add-alert-buttons {
  button {
    padding: 6px 14px 6px 14px;
    color: #677f8e;
    border: 1px solid #677f8e;
    font-size: 14px;
    margin: 0 8px;
    height: 40px;
    border-radius: 4px;

    svg {
      color: #677f8e;
      fill: currentColor;
      position: relative;
      left: -8px;
      top: -2px;
    }
  }

  button:hover {
    color: white;
    border: 1px solid white;

    svg {
      color: white;
    }
  }
}

@media (max-width: 1200px) {
    .repon {
       width:400px
    }
}
@media (min-width: 1200px) {
    .repon {
       width:340px
    }
}
.coin-detail-main-content {
  border-top: 1px solid #28363e;

  .performance-title {
    color: var(--sub-2);
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    left: 3px;
  }

  .performance-value {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .performance-value-positive {
    color: var(--green-positive);
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .performance-value-negative {
    color: var(--red-negative);
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .heading {
    color: #d6dde1;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    svg {
      margin-left: 5px;
    }
  }

  .performance-link a {
    color: var(--orange-link);
    text-decoration: underline;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .loading {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  // #141b1f
  .loading-cotainer {
    background-color: #141b1f; /* สีน้ำตาล */
    top: 5px; /* ชิดด้านบน 50% ของหน้าจอ + 5px */
    height: 275px; /* กำหนดความสูงเท่ากับ 275px */
    display: flex; /* ให้เนื้อหาอยู่กลาง */
    justify-content: center; /* จัดเนื้อหาให้อยู่กึ่งกลางในแนวนอน */
    align-items: center; /* จัดเนื้อหาให้อยู่กึ่งกลางในแนวตั้ง */
  }

  .trading-signal {
    text-align: center;
    border: 1px solid var(--ui-layer-2);
    width: 95%;
    border-radius: 4px;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--sub-2);
    padding: 2px 2px;
    position: relative;
    left: 14px;

    // margin-left: 2px;
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 0;
      border-radius: 2px;
      cursor: pointer;

      select {
        cursor: pointer;
        width: 100%;
        height: 100%;
        text-align: center;
        outline: none;
        border: none;
        color: var(--sub-2);

        option {
          color: var(--sub-2);
        }
      }

      & svg {
        fill: currentColor;
      }
    }

    .more-time {
      position: relative;
    }

    .more-time:hover select {
      color: white;
    }

    .time:hover {
      color: white;
      background-color: var(--ui-layer-2);
    }

    .ts-active-tab {
      color: white;
      background-color: var(--ui-layer-2);
    }
  }

  .cd-guage-chart {
    position: relative;
    margin-left: -115px;
    margin-top: 10px;
    margin-bottom: 30px;
    position: relative;

    .options {
      position: absolute;
      left: 130px;

      .strong-sell {
        color: #eb4751;
        font-size: 14px;
      }

      .sell {
        color: #da868b;
        font-size: 14px;
      }

      .neutural {
        color: #677f8e;
        font-size: 14px;
      }

      .buy {
        color: #a4dbb6;
        font-size: 14px;
      }

      .strong-buy {
        color: #44a765;
        font-size: 14px;
      }
    }
  }
}

.ts-info {
  position: relative;
  z-index: 999;
  margin-top: -110px;
  width: 95%;
  font-size: 14px;
  left: 3px;

  .title {
    color: var(--sub-2);
    margin: 8px 0;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    // width: 160px;
    height: 24px;
  }

  .info-positive {
    color: var(--green-positive);
    background-color: rgba(64, 153, 79, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 8px 0;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    // width: 220px;
    height: 32px;
  }

  .info-neutral {
    color: #8a9ca8;
    background-color: rgba(138, 156, 168, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 8px 0;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    // width: 220px;
    height: 32px;
  }

  .info-overbought {
    color: #de2d40;
    background-color: rgba(222, 45, 64, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 8px 0;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    // width: 220px;
    height: 32px;
  }
}

.below-chart-content {
  .heading {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: white;
    display: inline-block;
    margin-bottom: 0;
  }

  .hbs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .offer-buttons {
    float: right;

    .green {
      font-family: "Noto Sans Thai";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: white;
      background-color: #40994f;

      &:hover {
        background-color: var(--green-positiveHover);
      }

      width: 219px;
      padding: 8px 0;
      border-radius: 4px;
    }

    .red {
      font-family: "Noto Sans Thai";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background-color: #de2d40;

      &:hover {
        background-color: var(--red-negativeHover);
      }

      color: white;
      width: 219px;
      padding: 8px 0;
      border-radius: 4px;
      margin-left: 16px;
    }
  }

  .th-instructions {
    color: #9bacb6;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;

    .title {
      color: white;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    ul {
      list-style-type: disc;
    }
  }
}

.cd-main-chart {
  height: 450px;
  padding-bottom: 43px;
}

.info-b-i {
  cursor: pointer;
}

.cd-info-box {
  position: absolute;
  z-index: 50;
  color: #0a0d10;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 3px 0 3px 8px;
  width: 437px;
  height: 128px;
  background-color: #d6dde1;
  border-radius: 4px;

  p {
    margin-bottom: 0;
  }
}

.loading-container {
  text-align: center;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .search-container {
    // width: 70%;
    margin-bottom: 10px;

    .coins-search-input {
      width: 100%;
    }

    .searching-box {
      width: 350px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
  div.col-xl-6 {
    width: 53%;
    padding: 0;
  }
}

@media only screen and (min-width: 1280px) {
  div.col-xl-6 {
    width: 54%;
  }
}

@media only screen and (min-width: 1500px) {
  div.col-xl-6 {
    width: 61%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .offer-buttons {
    .red,
    .green {
      width: 90px !important;
    }
  }

  .add-alert-buttons {
    margin-top: 10px;
    text-align: center;

    button {
      margin: 0 10px;
      padding: 8px 15px;
    }
  }
}
</style>
