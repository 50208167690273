<template>
  <div style="margin-top: 27px; margin-bottom: 44px; cursor: pointer">
    <svg
      class="treechart-svg"
      height="400"
      viewBox="0 0 1392 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.4375"
        y="123.089"
        width="359.799"
        height="89.7823"
        fill="#D68C43"
      />
      <rect
        x="0.4375"
        y="123.089"
        width="359.799"
        height="89.7823"
        fill="#D68C43"
      />
      <rect x="0.4375" width="287.565" height="123.089" fill="#D48240" />
      <rect x="288" width="112.134" height="45.7004" fill="#D06438" />
      <rect x="400.137" width="112.845" height="45.7003" fill="#D48240" />
      <rect x="512.984" width="84.8162" height="45.7003" fill="#A9B03E" />
      <rect
        x="288"
        y="45.7002"
        width="92.8496"
        height="77.3883"
        fill="#6EA028"
      />
      <rect
        x="380.855"
        y="45.7002"
        width="85.9087"
        height="30.0005"
        fill="#A9B03E"
      />
      <rect
        x="535.855"
        y="45.7002"
        width="61.9445"
        height="23.0879"
        fill="#CAB94A"
      />
      <rect
        x="535.855"
        y="68.7881"
        width="61.9445"
        height="17.1547"
        fill="#DCBE50"
      />
      <rect
        x="466.758"
        y="45.7002"
        width="69.0921"
        height="23.0878"
        fill="#CAB94A"
      />
      <rect
        x="466.758"
        y="68.7881"
        width="69.0921"
        height="17.1547"
        fill="#CAB94A"
      />
      <rect
        x="466.758"
        y="84.9165"
        width="50.0147"
        height="22.099"
        fill="#6EA028"
      />
      <rect
        x="466.758"
        y="107.015"
        width="50.0147"
        height="16.0735"
        fill="#D48240"
      />
      <rect
        x="516.773"
        y="107.015"
        width="41.1798"
        height="16.0735"
        fill="#D06438"
      />
      <rect
        x="516.773"
        y="85.9434"
        width="41.1798"
        height="13.6968"
        fill="#CAB94A"
      />
      <rect
        x="516.773"
        y="99.627"
        width="41.1798"
        height="7.3879"
        fill="#CAB94A"
      />
      <rect
        x="557.953"
        y="85.9434"
        width="39.8419"
        height="13.6968"
        fill="#D48240"
      />
      <rect
        x="557.953"
        y="99.6396"
        width="21.8949"
        height="9.72587"
        fill="#6EA028"
      />
      <rect
        x="579.848"
        y="99.6396"
        width="17.9467"
        height="9.72587"
        fill="#C82828"
      />
      <rect
        x="587.121"
        y="109.366"
        width="10.6767"
        height="5.78601"
        fill="#C4C4C4"
      />
      <rect
        x="557.953"
        y="109.366"
        width="16.2664"
        height="7.18248"
        fill="#D06438"
      />
      <rect
        x="557.953"
        y="116.548"
        width="11.1115"
        height="6.54047"
        fill="#6EA028"
      />
      <rect
        x="574.223"
        y="109.366"
        width="13.7618"
        height="5.78601"
        fill="#C4C4C4"
      />
      <rect
        x="380.855"
        y="75.7007"
        width="85.9087"
        height="26.3701"
        fill="#D48240"
      />
      <rect
        x="380.855"
        y="102.071"
        width="85.9087"
        height="21.0177"
        fill="#DCBE50"
      />
      <rect
        x="360.238"
        y="123.089"
        width="116.448"
        height="51.0179"
        fill="#6EA028"
      />
      <rect
        x="360.238"
        y="174.106"
        width="103.542"
        height="20.7195"
        fill="#DCBE50"
      />
      <rect
        x="360.238"
        y="194.826"
        width="103.542"
        height="18.045"
        fill="#D06438"
      />
      <rect
        x="463.777"
        y="174.106"
        width="59.6476"
        height="38.7644"
        fill="#CAB94A"
      />
      <rect
        x="476.684"
        y="123.089"
        width="92.3819"
        height="51.0179"
        fill="#DCBE50"
      />
      <rect
        x="569.062"
        y="123.089"
        width="28.7305"
        height="32.6569"
        fill="#A9B03E"
      />
      <rect
        x="569.062"
        y="104.516"
        width="28.7305"
        height="32.6569"
        fill="#D48240"
      />
      <rect
        x="569.062"
        y="155.746"
        width="28.7305"
        height="23.3135"
        fill="#A9B03E"
      />
      <rect
        x="522.879"
        y="174.106"
        width="46.1907"
        height="28.9559"
        fill="#CAB94A"
      />
      <rect
        x="569.062"
        y="179.06"
        width="28.7305"
        height="12.631"
        fill="#6EA028"
      />
      <rect
        x="569.062"
        y="191.69"
        width="28.7305"
        height="11.3723"
        fill="#CAB94A"
      />
      <rect
        x="569.062"
        y="203.062"
        width="28.7305"
        height="9.80856"
        fill="#6EA028"
      />
      <rect
        x="523.43"
        y="203.062"
        width="35.2233"
        height="9.80856"
        fill="#D06438"
      />
      <rect
        x="558.648"
        y="203.062"
        width="10.4167"
        height="9.80856"
        fill="#C82828"
      />
      <rect
        x="369.766"
        y="215.826"
        width="110.93"
        height="167.579"
        transform="rotate(90 369.766 215.826)"
        fill="#D48240"
      />
      <rect
        x="202.18"
        y="215.826"
        width="84.0205"
        height="202.182"
        transform="rotate(90 202.18 215.826)"
        fill="#6EA028"
      />
      <rect
        x="135.465"
        y="299.999"
        width="58.6802"
        height="135.462"
        transform="rotate(90 135.465 299.999)"
        fill="#A9B03E"
      />
      <rect
        x="202.18"
        y="299.999"
        width="42.0092"
        height="66.7198"
        transform="rotate(90 202.18 299.999)"
        fill="#D68C43"
      />
      <rect
        x="202.18"
        y="342.011"
        width="57.9883"
        height="66.7198"
        transform="rotate(90 202.18 342.011)"
        fill="#A9B03E"
      />
      <rect
        x="135.465"
        y="358.679"
        width="41.3197"
        height="135.462"
        transform="rotate(90 135.465 358.679)"
        fill="#CAB94A"
      />
      <rect
        x="599.508"
        y="215.826"
        width="88.6596"
        height="229.745"
        transform="rotate(90 599.508 215.826)"
        fill="#6EA028"
      />
      <rect
        x="599.508"
        y="304.485"
        width="34.5722"
        height="85.2997"
        transform="rotate(90 599.508 304.485)"
        fill="#A9B03E"
      />
      <rect
        x="514.207"
        y="304.485"
        width="28.6267"
        height="144.445"
        transform="rotate(90 514.207 304.485)"
        fill="#6EA028"
      />
      <rect
        x="514.207"
        y="333.112"
        width="26.4867"
        height="55.9958"
        transform="rotate(90 514.207 333.112)"
        fill="#A9B03E"
      />
      <rect
        x="599.508"
        y="339.058"
        width="34.7913"
        height="85.2996"
        transform="rotate(90 599.508 339.058)"
        fill="#D06438"
      />
      <rect
        x="599.508"
        y="373.849"
        width="26.1498"
        height="85.2996"
        transform="rotate(90 599.508 373.849)"
        fill="#A9B03E"
      />
      <rect
        x="514.207"
        y="380.9"
        width="19.0982"
        height="43.0936"
        transform="rotate(90 514.207 380.9)"
        fill="#CAB94A"
      />
      <rect
        x="471.117"
        y="380.9"
        width="19.0982"
        height="32.0193"
        transform="rotate(90 471.117 380.9)"
        fill="#DCBE50"
      />
      <rect
        x="514.207"
        y="359.599"
        width="21.3019"
        height="43.0934"
        transform="rotate(90 514.207 359.599)"
        fill="#CAB94A"
      />
      <rect
        x="471.117"
        y="359.599"
        width="21.3019"
        height="32.0193"
        transform="rotate(90 471.117 359.599)"
        fill="#CAB94A"
      />
      <rect
        x="441.016"
        y="359.599"
        width="15.4201"
        height="41.2477"
        transform="rotate(90 441.016 359.599)"
        fill="#6EA028"
      />
      <rect
        x="399.762"
        y="359.599"
        width="15.4201"
        height="30.0011"
        transform="rotate(90 399.762 359.599)"
        fill="#D48240"
      />
      <rect
        x="399.762"
        y="375.019"
        width="12.6962"
        height="30.0011"
        transform="rotate(90 399.762 375.019)"
        fill="#D06438"
      />
      <rect
        x="439.094"
        y="375.019"
        width="12.6962"
        height="25.5651"
        transform="rotate(90 439.094 375.019)"
        fill="#CAB94A"
      />
      <rect
        x="413.555"
        y="375.019"
        width="12.6962"
        height="13.7895"
        transform="rotate(90 413.555 375.019)"
        fill="#CAB94A"
      />
      <rect
        x="439.094"
        y="387.714"
        width="12.2837"
        height="25.5651"
        transform="rotate(90 439.094 387.714)"
        fill="#D48240"
      />
      <rect
        x="413.531"
        y="387.714"
        width="6.75047"
        height="18.1533"
        transform="rotate(90 413.531 387.714)"
        fill="#6EA028"
      />
      <rect
        x="413.531"
        y="394.466"
        width="5.53319"
        height="18.1533"
        transform="rotate(90 413.531 394.466)"
        fill="#C82828"
      />
      <rect
        x="395.375"
        y="387.714"
        width="5.01511"
        height="13.4061"
        transform="rotate(90 395.375 387.714)"
        fill="#D06438"
      />
      <rect
        x="381.969"
        y="387.714"
        width="3.42581"
        height="12.2078"
        transform="rotate(90 381.969 387.714)"
        fill="#6EA028"
      />
      <rect
        x="395.375"
        y="392.731"
        width="4.24292"
        height="10.7996"
        transform="rotate(90 395.375 392.731)"
        fill="#C4C4C4"
      />
      <rect
        x="458.211"
        y="333.112"
        width="26.4867"
        height="49.2199"
        transform="rotate(90 458.211 333.112)"
        fill="#D48240"
      />
      <rect
        x="408.996"
        y="333.112"
        width="26.4867"
        height="39.2295"
        transform="rotate(90 408.996 333.112)"
        fill="#DCBE50"
      />
      <rect
        x="369.766"
        y="326.756"
        width="35.9023"
        height="95.2248"
        transform="rotate(90 369.766 326.756)"
        fill="#C82828"
      />
      <rect
        x="274.535"
        y="326.756"
        width="31.9232"
        height="38.6729"
        transform="rotate(90 274.535 326.756)"
        fill="#DCBE50"
      />
      <rect
        x="235.863"
        y="326.756"
        width="31.9232"
        height="33.681"
        transform="rotate(90 235.863 326.756)"
        fill="#A9B03E"
      />
      <rect
        x="274.535"
        y="358.679"
        width="18.3901"
        height="72.3537"
        transform="rotate(90 274.535 358.679)"
        fill="#CAB94A"
      />
      <rect
        x="369.766"
        y="362.658"
        width="28.4824"
        height="95.2248"
        transform="rotate(90 369.766 362.658)"
        fill="#DCBE50"
      />
      <rect
        x="369.766"
        y="391.142"
        width="8.85795"
        height="60.9541"
        transform="rotate(90 369.766 391.142)"
        fill="#A9B03E"
      />
      <rect
        x="404.426"
        y="391.142"
        width="8.85795"
        height="60.9541"
        transform="rotate(90 404.426 391.142)"
        fill="#D48240"
      />
      <rect
        x="308.809"
        y="391.142"
        width="8.85795"
        height="43.5146"
        transform="rotate(90 308.809 391.142)"
        fill="#A9B03E"
      />
      <rect
        x="274.535"
        y="376.899"
        width="14.2411"
        height="54.0462"
        transform="rotate(90 274.535 376.899)"
        fill="#CAB94A"
      />
      <rect
        x="265.297"
        y="391.142"
        width="8.85795"
        height="23.5757"
        transform="rotate(90 265.297 391.142)"
        fill="#6EA028"
      />
      <rect
        x="241.719"
        y="391.142"
        width="8.85795"
        height="19.714"
        transform="rotate(90 241.719 391.142)"
        fill="#CAB94A"
      />
      <rect
        x="222.004"
        y="391.142"
        width="8.85795"
        height="19.714"
        transform="rotate(90 222.004 391.142)"
        fill="#6EA028"
      />
      <rect
        x="220.492"
        y="377.069"
        width="10.8598"
        height="18.3077"
        transform="rotate(90 220.492 377.069)"
        fill="#CAB94A"
      />
      <rect
        x="220.492"
        y="387.929"
        width="3.2116"
        height="13.5805"
        transform="rotate(90 220.492 387.929)"
        fill="#D48240"
      />
      <rect
        x="206.91"
        y="387.929"
        width="3.2116"
        height="4.72702"
        transform="rotate(90 206.91 387.929)"
        fill="#6EA028"
      />
      <rect
        width="5.64397"
        height="7.12722"
        transform="matrix(1.21988e-07 1 1 -1.56629e-08 591.949 379.599)"
        fill="#6EA028"
      />

      <rect
        width="158.177"
        height="47.4993"
        transform="matrix(-1 9.58013e-08 3.19295e-07 1 899.73 226.732)"
        fill="#A9B03E"
      />
      <rect
        width="74.9359"
        height="54.8656"
        transform="matrix(-1 9.84957e-08 3.10703e-07 1 899.73 313.095)"
        fill="#6EA028"
      />
      <rect
        width="77.2696"
        height="27.4328"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 824.793 313.095)"
        fill="#D48240"
      />
      <rect
        width="51.9619"
        height="21.2757"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 824.793 340.528)"
        fill="#D48240"
      />
      <rect
        width="58.5358"
        height="14.6665"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 772.832 340.528)"
        fill="#CAB94A"
      />
      <rect
        width="46.3916"
        height="16.4496"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 772.832 355.194)"
        fill="#DCBE50"
      />
      <rect
        width="74.9359"
        height="31.048"
        transform="matrix(-1 9.84957e-08 3.10703e-07 1 899.73 367.961)"
        fill="#D48240"
      />
      <rect
        width="51.9621"
        height="19.4707"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 824.793 361.803)"
        fill="#A9B03E"
      />
      <rect
        width="51.9619"
        height="17.7346"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 824.793 381.273)"
        fill="#C82828"
      />
      <rect
        width="46.3916"
        height="14.2046"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 772.832 371.539)"
        fill="#CAB94A"
      />
      <rect
        width="37.37"
        height="11.1263"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 726.445 367.515)"
        fill="#D06438"
      />
      <rect
        width="37.37"
        height="10.8502"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 726.445 378.641)"
        fill="#A9B03E"
      />
      <rect
        width="37.37"
        height="9.51733"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 726.445 389.491)"
        fill="#A9B03E"
      />
      <rect
        width="26.4561"
        height="7.6697"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 689.07 376.9)"
        fill="#C82828"
      />
      <rect
        width="10.4678"
        height="1.94042"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 613.387 395.285)"
        fill="#6EA028"
      />
      <rect
        width="3.95406"
        height="3.72303"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 617.336 395.285)"
        fill="#A9B03E"
      />
      <rect
        width="6.68983"
        height="3.72303"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 624.027 395.285)"
        fill="#DCBE50"
      />
      <rect
        width="10.4678"
        height="1.78257"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 613.387 397.226)"
        fill="#DCBE50"
      />
      <rect
        width="14.0665"
        height="3.87918"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 616.98 391.406)"
        fill="#6EA028"
      />
      <rect
        width="7.04483"
        height="3.87918"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 624.027 391.406)"
        fill="#A9B03E"
      />
      <rect
        width="10.6439"
        height="3.13958"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 624.027 388.267)"
        fill="#DCBE50"
      />
      <rect
        width="10.4905"
        height="3.13958"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 613.387 388.267)"
        fill="#A9B03E"
      />
      <rect
        width="12.3329"
        height="5.68339"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 615.227 382.584)"
        fill="#DCBE50"
      />
      <rect
        width="19.3551"
        height="5.68339"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 622.25 376.9)"
        fill="#D06438"
      />
      <rect
        width="19.3551"
        height="5.68339"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 641.473 376.9)"
        fill="#A9B03E"
      />
      <rect
        width="9.22658"
        height="5.68339"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 624.027 382.584)"
        fill="#A9B03E"
      />
      <rect
        width="10.5405"
        height="5.68339"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 634.57 382.584)"
        fill="#6EA028"
      />
      <rect
        width="10.5405"
        height="11.5119"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 645.109 382.584)"
        fill="#6EA028"
      />
      <rect
        width="17.5046"
        height="6.27821"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 662.613 382.584)"
        fill="#D06438"
      />
      <rect
        width="17.5046"
        height="5.2337"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 662.613 388.862)"
        fill="#A9B03E"
      />
      <rect
        width="10.6041"
        height="5.82856"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 634.57 388.267)"
        fill="#A9B03E"
      />
      <rect
        width="10.6041"
        height="4.91323"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 634.57 394.096)"
        fill="#6EA028"
      />
      <rect
        width="10.6041"
        height="4.91323"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 645.109 394.096)"
        fill="#DCBE50"
      />
      <rect
        width="17.5046"
        height="4.91323"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 662.613 394.096)"
        fill="#DCBE50"
      />
      <rect
        width="21.2093"
        height="5.68339"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 662.613 376.9)"
        fill="#CAB94A"
      />
      <rect
        width="26.4561"
        height="7.8219"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 689.07 384.569)"
        fill="#A9B03E"
      />
      <rect
        width="26.4561"
        height="6.63265"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 689.07 392.376)"
        fill="#A9B03E"
      />
      <rect
        width="31.1806"
        height="9.38548"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 689.07 367.515)"
        fill="#6EA028"
      />
      <rect
        width="31.1806"
        height="9.38548"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 657.891 367.515)"
        fill="#A9B03E"
      />
      <rect
        width="23.8157"
        height="9.38548"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 626.707 367.515)"
        fill="#CAB94A"
      />
      <rect
        width="46.3916"
        height="13.2652"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 772.832 385.744)"
        fill="#D06438"
      />
      <rect
        width="46.3916"
        height="12.3205"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 726.445 355.194)"
        fill="#DCBE50"
      />
      <rect
        width="38.5773"
        height="12.3205"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 680.051 355.194)"
        fill="#6EA028"
      />
      <rect
        width="38.5773"
        height="12.3205"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 641.473 355.194)"
        fill="#CAB94A"
      />
      <rect
        width="58.5358"
        height="14.6665"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 714.297 340.528)"
        fill="#D06438"
      />
      <rect
        width="52.845"
        height="14.6665"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 655.762 340.528)"
        fill="#A9B03E"
      />
      <rect
        width="77.2696"
        height="27.4328"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 747.805 313.095)"
        fill="#CAB94A"
      />
      <rect
        width="67.6187"
        height="27.4328"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 670.531 313.095)"
        fill="#D48240"
      />
      <rect
        width="158.177"
        height="38.8637"
        transform="matrix(-1 9.84957e-08 3.10703e-07 1 899.73 274.232)"
        fill="#A9B03E"
      />
      <rect
        width="138.638"
        height="38.8637"
        transform="matrix(-1 9.34203e-08 3.27375e-07 1 741.555 274.232)"
        fill="#CAB94A"
      />
      <rect
        width="138.638"
        height="47.4993"
        transform="matrix(-1 9.09038e-08 3.36572e-07 1 741.555 226.732)"
        fill="#6EA028"
      />
      <rect
        x="1167.07"
        y="371.287"
        width="140.493"
        height="39.8557"
        transform="rotate(-180 1167.07 371.287)"
        fill="#A9B03E"
      />
      <rect
        x="1167.07"
        y="298.821"
        width="66.5579"
        height="46.0366"
        transform="rotate(-180 1167.07 298.821)"
        fill="#6EA028"
      />
      <rect
        x="1100.51"
        y="298.821"
        width="68.6307"
        height="23.0183"
        transform="rotate(-180 1100.51 298.821)"
        fill="#D48240"
      />
      <rect
        x="1100.51"
        y="275.803"
        width="46.1525"
        height="17.852"
        transform="rotate(-180 1100.51 275.803)"
        fill="#D48240"
      />
      <rect
        x="1054.36"
        y="275.803"
        width="51.9914"
        height="12.3064"
        transform="rotate(-180 1054.36 275.803)"
        fill="#CAB94A"
      />
      <rect
        x="1054.36"
        y="263.497"
        width="41.2049"
        height="13.8025"
        transform="rotate(-180 1054.36 263.497)"
        fill="#DCBE50"
      />
      <rect
        x="1167.07"
        y="252.785"
        width="66.5579"
        height="26.0518"
        transform="rotate(-180 1167.07 252.785)"
        fill="#D48240"
      />
      <rect
        x="1100.51"
        y="257.952"
        width="46.1526"
        height="16.3375"
        transform="rotate(-180 1100.51 257.952)"
        fill="#A9B03E"
      />
      <rect
        x="1100.51"
        y="241.614"
        width="46.1525"
        height="14.8807"
        transform="rotate(-180 1100.51 241.614)"
        fill="#A9B03E"
      />
      <rect
        x="1054.36"
        y="249.782"
        width="41.2049"
        height="11.9188"
        transform="rotate(-180 1054.36 249.782)"
        fill="#CAB94A"
      />
      <rect
        x="1013.15"
        y="253.159"
        width="33.192"
        height="9.33589"
        transform="rotate(-180 1013.15 253.159)"
        fill="#6EA028"
      />
      <rect
        x="1013.15"
        y="243.823"
        width="33.192"
        height="9.10422"
        transform="rotate(-180 1013.15 243.823)"
        fill="#A9B03E"
      />
      <rect
        x="1013.15"
        y="234.718"
        width="33.192"
        height="7.98579"
        transform="rotate(-180 1013.15 234.718)"
        fill="#CAB94A"
      />
      <rect
        x="979.965"
        y="245.283"
        width="23.4982"
        height="6.43549"
        transform="rotate(-180 979.965 245.283)"
        fill="#A9B03E"
      />
      <rect
        x="909.672"
        y="233.111"
        width="6.2572"
        height="3.25494"
        transform="rotate(-180 909.672 233.111)"
        fill="#CAB94A"
      />
      <rect
        x="907"
        y="229.857"
        width="3.57996"
        height="3.12392"
        transform="rotate(-180 907 229.857)"
        fill="#CAB94A"
      />
      <rect
        x="910.574"
        y="229.857"
        width="3.57996"
        height="1.62816"
        transform="rotate(-180 910.574 229.857)"
        fill="#D06438"
      />
      <rect
        x="912.734"
        y="229.857"
        width="2.15733"
        height="1.62816"
        transform="rotate(-180 912.734 229.857)"
        fill="#C82828"
      />
      <rect
        x="916.246"
        y="229.857"
        width="3.51199"
        height="3.12392"
        transform="rotate(-180 916.246 229.857)"
        fill="#D06438"
      />
      <rect
        x="922.188"
        y="229.857"
        width="5.94189"
        height="3.12392"
        transform="rotate(-180 922.188 229.857)"
        fill="#D48240"
      />
      <rect
        x="910.574"
        y="228.229"
        width="3.57996"
        height="1.49572"
        transform="rotate(-180 910.574 228.229)"
        fill="#C82828"
      />
      <rect
        x="912.734"
        y="228.229"
        width="2.1572"
        height="1.49572"
        transform="rotate(-180 912.734 228.229)"
        fill="#D48240"
      />
      <rect
        x="915.934"
        y="233.111"
        width="6.2572"
        height="3.25494"
        transform="rotate(-180 915.934 233.111)"
        fill="#6EA028"
      />
      <rect
        x="922.188"
        y="233.111"
        width="6.2572"
        height="3.25494"
        transform="rotate(-180 922.188 233.111)"
        fill="#CAB94A"
      />
      <rect
        x="922.188"
        y="235.746"
        width="9.45388"
        height="2.63436"
        transform="rotate(-180 922.188 235.746)"
        fill="#D48240"
      />
      <rect
        x="912.734"
        y="235.746"
        width="9.31762"
        height="2.63436"
        transform="rotate(-180 912.734 235.746)"
        fill="#D06438"
      />
      <rect
        x="914.371"
        y="240.514"
        width="10.954"
        height="4.76881"
        transform="rotate(-180 914.371 240.514)"
        fill="#DCBE50"
      />
      <rect
        x="920.605"
        y="245.283"
        width="17.1912"
        height="4.76881"
        transform="rotate(-180 920.605 245.283)"
        fill="#D06438"
      />
      <rect
        x="937.684"
        y="245.283"
        width="17.1912"
        height="4.76881"
        transform="rotate(-180 937.684 245.283)"
        fill="#A9B03E"
      />
      <rect
        x="922.188"
        y="240.514"
        width="8.19503"
        height="4.76881"
        transform="rotate(-180 922.188 240.514)"
        fill="#A9B03E"
      />
      <rect
        x="931.551"
        y="240.514"
        width="9.36208"
        height="4.76881"
        transform="rotate(-180 931.551 240.514)"
        fill="#6EA028"
      />
      <rect
        x="940.914"
        y="240.514"
        width="9.36208"
        height="9.65944"
        transform="rotate(-180 940.914 240.514)"
        fill="#6EA028"
      />
      <rect
        x="956.461"
        y="240.514"
        width="15.5475"
        height="5.26791"
        transform="rotate(-180 956.461 240.514)"
        fill="#6EA028"
      />
      <rect
        x="956.461"
        y="235.247"
        width="15.5475"
        height="4.39149"
        transform="rotate(-180 956.461 235.247)"
        fill="#D06438"
      />
      <rect
        x="931.551"
        y="235.746"
        width="9.41858"
        height="4.89062"
        transform="rotate(-180 931.551 235.746)"
        fill="#D06438"
      />
      <rect
        x="931.551"
        y="230.855"
        width="9.41858"
        height="4.12259"
        transform="rotate(-180 931.551 230.855)"
        fill="#C82828"
      />
      <rect
        x="940.914"
        y="230.855"
        width="9.41858"
        height="4.12259"
        transform="rotate(-180 940.914 230.855)"
        fill="#DCBE50"
      />
      <rect
        x="956.461"
        y="230.855"
        width="15.5475"
        height="4.12259"
        transform="rotate(-180 956.461 230.855)"
        fill="#DCBE50"
      />
      <rect
        x="956.461"
        y="245.283"
        width="18.8381"
        height="4.76881"
        transform="rotate(-180 956.461 245.283)"
        fill="#CAB94A"
      />
      <rect
        x="979.965"
        y="238.848"
        width="23.4982"
        height="6.5632"
        transform="rotate(-180 979.965 238.848)"
        fill="#CAB94A"
      />
      <rect
        x="979.965"
        y="232.298"
        width="23.4982"
        height="5.56532"
        transform="rotate(-180 979.965 232.298)"
        fill="#CAB94A"
      />
      <rect
        x="979.965"
        y="253.159"
        width="27.6946"
        height="7.87517"
        transform="rotate(-180 979.965 253.159)"
        fill="#6EA028"
      />
      <rect
        x="952.262"
        y="253.159"
        width="27.6946"
        height="7.87517"
        transform="rotate(-180 952.262 253.159)"
        fill="#A9B03E"
      />
      <rect
        x="924.57"
        y="253.159"
        width="21.1531"
        height="7.87517"
        transform="rotate(-180 924.57 253.159)"
        fill="#CAB94A"
      />
      <rect
        x="1054.36"
        y="237.863"
        width="41.2049"
        height="11.1305"
        transform="rotate(-180 1054.36 237.863)"
        fill="#6EA028"
      />
      <rect
        x="1013.15"
        y="263.497"
        width="41.2049"
        height="10.3379"
        transform="rotate(-180 1013.15 263.497)"
        fill="#DCBE50"
      />
      <rect
        x="971.949"
        y="263.497"
        width="34.2643"
        height="10.3379"
        transform="rotate(-180 971.949 263.497)"
        fill="#6EA028"
      />
      <rect
        x="937.684"
        y="263.497"
        width="34.2643"
        height="10.3379"
        transform="rotate(-180 937.684 263.497)"
        fill="#CAB94A"
      />
      <rect
        x="1002.36"
        y="275.803"
        width="51.9914"
        height="12.3064"
        transform="rotate(-180 1002.36 275.803)"
        fill="#D06438"
      />
      <rect
        x="950.375"
        y="275.803"
        width="46.9368"
        height="12.3064"
        transform="rotate(-180 950.375 275.803)"
        fill="#A9B03E"
      />
      <rect
        x="1032.12"
        y="298.821"
        width="68.6307"
        height="23.0183"
        transform="rotate(-180 1032.12 298.821)"
        fill="#CAB94A"
      />
      <rect
        x="963.496"
        y="298.821"
        width="60.0588"
        height="23.0183"
        transform="rotate(-180 963.496 298.821)"
        fill="#D48240"
      />
      <rect
        x="1167.07"
        y="331.431"
        width="140.493"
        height="32.6097"
        transform="rotate(-180 1167.07 331.431)"
        fill="#A9B03E"
      />
      <rect
        x="1026.57"
        y="331.431"
        width="123.138"
        height="32.6097"
        transform="rotate(-180 1026.57 331.431)"
        fill="#CAB94A"
      />
      <rect
        x="1026.57"
        y="371.287"
        width="123.138"
        height="39.8557"
        transform="rotate(-180 1026.57 371.287)"
        fill="#6EA028"
      />
      <rect
        x="1170.75"
        y="226.732"
        width="37.1586"
        height="31.0337"
        fill="#D06438"
      />
      <rect
        x="1170.75"
        y="257.766"
        width="19.1989"
        height="52.552"
        fill="#6EA028"
      />
      <rect
        x="1189.95"
        y="257.766"
        width="80.0082"
        height="20.3724"
        fill="#A9B03E"
      />
      <rect
        x="1207.91"
        y="226.732"
        width="105.094"
        height="31.0336"
        fill="#D48240"
      />
      <rect
        x="1313"
        y="226.732"
        width="78.9907"
        height="31.0336"
        fill="#A9B03E"
      />
      <rect
        x="1334.31"
        y="257.766"
        width="57.6899"
        height="15.6783"
        fill="#CAB94A"
      />
      <rect
        x="1334.31"
        y="273.443"
        width="57.6899"
        height="11.6492"
        fill="#DCBE50"
      />
      <rect
        x="1269.96"
        y="257.766"
        width="64.3466"
        height="15.6782"
        fill="#CAB94A"
      />
      <rect
        x="1269.96"
        y="273.443"
        width="64.3466"
        height="11.6492"
        fill="#CAB94A"
      />
      <rect
        x="1269.96"
        y="284.396"
        width="46.5796"
        height="15.0067"
        fill="#6EA028"
      />
      <rect
        x="1269.96"
        y="299.403"
        width="46.5796"
        height="10.915"
        fill="#D48240"
      />
      <rect
        x="1316.54"
        y="299.403"
        width="38.3514"
        height="10.915"
        fill="#D06438"
      />
      <rect
        x="1316.54"
        y="285.093"
        width="38.3514"
        height="9.30109"
        fill="#CAB94A"
      />
      <rect
        x="1316.54"
        y="294.386"
        width="38.3514"
        height="5.01689"
        fill="#CAB94A"
      />
      <rect
        x="1354.89"
        y="285.093"
        width="37.1054"
        height="9.30109"
        fill="#D48240"
      />
      <rect
        x="1354.89"
        y="294.395"
        width="20.3911"
        height="6.60454"
        fill="#6EA028"
      />
      <rect
        x="1375.28"
        y="294.395"
        width="16.7141"
        height="6.60454"
        fill="#C82828"
      />
      <rect
        x="1382.06"
        y="300.999"
        width="9.94335"
        height="3.9291"
        fill="#C4C4C4"
      />
      <rect
        x="1354.89"
        y="300.999"
        width="15.1491"
        height="4.8774"
        fill="#D06438"
      />
      <rect
        x="1354.89"
        y="305.877"
        width="10.3483"
        height="4.44143"
        fill="#6EA028"
      />
      <rect
        x="1370.04"
        y="300.999"
        width="12.8166"
        height="3.9291"
        fill="#C4C4C4"
      />
      <rect
        x="1189.95"
        y="278.139"
        width="80.0082"
        height="17.9071"
        fill="#D48240"
      />
      <rect
        x="1189.95"
        y="296.046"
        width="80.0082"
        height="14.2725"
        fill="#DCBE50"
      />
      <rect
        x="1170.75"
        y="310.317"
        width="108.45"
        height="34.6446"
        fill="#6EA028"
      />
      <rect
        x="1170.75"
        y="344.962"
        width="96.4304"
        height="14.07"
        fill="#DCBE50"
      />
      <rect
        x="1170.75"
        y="359.032"
        width="96.4304"
        height="12.2538"
        fill="#D06438"
      />
      <rect
        x="1267.18"
        y="344.962"
        width="55.5508"
        height="26.3237"
        fill="#CAB94A"
      />
      <rect
        x="1279.21"
        y="310.317"
        width="86.0368"
        height="34.6446"
        fill="#DCBE50"
      />
      <rect
        x="1365.24"
        y="310.317"
        width="26.7572"
        height="22.1763"
        fill="#A9B03E"
      />
      <rect
        x="1365.24"
        y="297.706"
        width="26.7572"
        height="22.1763"
        fill="#D48240"
      />
      <rect
        x="1365.24"
        y="332.494"
        width="26.7572"
        height="15.8314"
        fill="#A9B03E"
      />
      <rect
        x="1322.23"
        y="344.962"
        width="43.0181"
        height="19.6631"
        fill="#CAB94A"
      />
      <rect
        x="1365.24"
        y="348.326"
        width="26.7572"
        height="8.57729"
        fill="#6EA028"
      />
      <rect
        x="1365.24"
        y="356.903"
        width="26.7572"
        height="7.17233"
        fill="#CAB94A"
      />
      <rect
        x="1365.24"
        y="364.076"
        width="26.7572"
        height="7.17233"
        fill="#6EA028"
      />
      <rect
        x="1322.74"
        y="364.626"
        width="32.804"
        height="6.66069"
        fill="#D06438"
      />
      <rect
        x="1355.54"
        y="364.626"
        width="9.70126"
        height="6.66069"
        fill="#C82828"
      />
      <rect
        x="1199.25"
        y="373.267"
        width="45.1366"
        height="9.36517"
        fill="#D48240"
      />
      <rect
        x="1244.38"
        y="373.267"
        width="50.847"
        height="6.45594"
        fill="#CAB94A"
      />
      <rect
        x="1244.38"
        y="379.724"
        width="40.298"
        height="7.24083"
        fill="#DCBE50"
      />
      <rect
        x="903.414"
        y="373.267"
        width="295.826"
        height="25.7421"
        fill="#C82828"
      />
      <rect
        x="1199.25"
        y="382.633"
        width="45.1368"
        height="8.57067"
        fill="#A9B03E"
      />
      <rect
        x="1199.25"
        y="391.203"
        width="45.1366"
        height="7.80644"
        fill="#C82828"
      />
      <rect
        x="1244.38"
        y="386.917"
        width="40.2979"
        height="6.25263"
        fill="#CAB94A"
      />
      <rect
        x="1284.68"
        y="385.146"
        width="32.4614"
        height="4.89762"
        fill="#D06438"
      />
      <rect
        x="1284.68"
        y="390.043"
        width="32.4614"
        height="4.77608"
        fill="#A9B03E"
      />
      <rect
        x="1284.68"
        y="394.82"
        width="32.4614"
        height="4.18936"
        fill="#CAB94A"
      />
      <rect
        x="1317.14"
        y="389.277"
        width="22.981"
        height="3.37606"
        fill="#C82828"
      />
      <rect
        x="1385.87"
        y="395.663"
        width="6.11947"
        height="1.70754"
        fill="#CAB94A"
      />
      <rect
        x="1388.49"
        y="397.37"
        width="3.50116"
        height="1.63881"
        fill="#CAB94A"
      />
      <rect
        x="1385"
        y="397.37"
        width="3.50116"
        height="0.854137"
        fill="#D06438"
      />
      <rect
        x="1382.88"
        y="397.37"
        width="2.10984"
        height="0.854137"
        fill="#C82828"
      />
      <rect
        x="1379.45"
        y="397.37"
        width="3.43469"
        height="1.63881"
        fill="#D06438"
      />
      <rect
        x="1373.63"
        y="397.37"
        width="5.8111"
        height="1.63881"
        fill="#D48240"
      />
      <rect
        x="1385"
        y="398.225"
        width="3.50116"
        height="0.784656"
        fill="#C82828"
      />
      <rect
        x="1382.88"
        y="398.225"
        width="2.10971"
        height="0.784656"
        fill="#D48240"
      />
      <rect
        x="1379.76"
        y="395.663"
        width="6.11947"
        height="1.70754"
        fill="#6EA028"
      />
      <rect
        x="1373.63"
        y="395.663"
        width="6.11947"
        height="1.70754"
        fill="#CAB94A"
      />
      <rect
        x="1373.63"
        y="394.28"
        width="9.24579"
        height="1.38199"
        fill="#D48240"
      />
      <rect
        x="1382.88"
        y="394.28"
        width="9.11252"
        height="1.38199"
        fill="#D06438"
      />
      <rect
        x="1381.28"
        y="391.778"
        width="10.7129"
        height="2.50172"
        fill="#DCBE50"
      />
      <rect
        x="1375.18"
        y="389.277"
        width="16.8128"
        height="2.50172"
        fill="#D06438"
      />
      <rect
        x="1358.48"
        y="389.277"
        width="16.8128"
        height="2.50172"
        fill="#A9B03E"
      />
      <rect
        x="1373.63"
        y="391.778"
        width="8.01465"
        height="2.50172"
        fill="#A9B03E"
      />
      <rect
        x="1364.48"
        y="391.778"
        width="9.15601"
        height="2.50172"
        fill="#6EA028"
      />
      <rect
        x="1355.32"
        y="391.778"
        width="9.15601"
        height="5.06735"
        fill="#6EA028"
      />
      <rect
        x="1340.12"
        y="391.778"
        width="15.2053"
        height="2.76355"
        fill="#D06438"
      />
      <rect
        x="1340.12"
        y="394.543"
        width="15.2053"
        height="2.30378"
        fill="#D06438"
      />
      <rect
        x="1364.48"
        y="394.28"
        width="9.21126"
        height="2.56563"
        fill="#D06438"
      />
      <rect
        x="1364.48"
        y="396.846"
        width="9.21126"
        height="2.16272"
        fill="#C82828"
      />
      <rect
        x="1355.32"
        y="396.846"
        width="9.21126"
        height="2.16272"
        fill="#DCBE50"
      />
      <rect
        x="1340.12"
        y="396.846"
        width="15.2053"
        height="2.16272"
        fill="#DCBE50"
      />
      <rect
        x="1340.12"
        y="389.277"
        width="18.4234"
        height="2.50172"
        fill="#CAB94A"
      />
      <rect
        x="1317.14"
        y="392.653"
        width="22.981"
        height="3.44306"
        fill="#CAB94A"
      />
      <rect
        x="1317.14"
        y="396.09"
        width="22.981"
        height="2.91957"
        fill="#CAB94A"
      />
      <rect
        x="1317.14"
        y="385.146"
        width="27.085"
        height="4.13132"
        fill="#6EA028"
      />
      <rect
        x="1344.22"
        y="385.146"
        width="27.085"
        height="4.13132"
        fill="#A9B03E"
      />
      <rect
        x="1371.3"
        y="385.146"
        width="20.6875"
        height="4.13132"
        fill="#CAB94A"
      />
      <rect
        x="1244.38"
        y="393.17"
        width="40.2979"
        height="5.8391"
        fill="#D06438"
      />
      <rect
        x="1284.68"
        y="379.724"
        width="40.298"
        height="5.42329"
        fill="#DCBE50"
      />
      <rect
        x="1324.98"
        y="379.724"
        width="33.5101"
        height="5.42329"
        fill="#6EA028"
      />
      <rect
        x="1358.48"
        y="379.724"
        width="33.5101"
        height="5.42329"
        fill="#CAB94A"
      />
      <rect
        x="1295.23"
        y="373.267"
        width="50.847"
        height="6.45594"
        fill="#D06438"
      />
      <rect
        x="1346.07"
        y="373.267"
        width="45.9037"
        height="6.45594"
        fill="#A9B03E"
      />
      <rect x="602.895" width="181.607" height="84.8623" fill="#6EA028" />
      <rect
        x="602.895"
        y="84.8618"
        width="120.052"
        height="47.3973"
        fill="#D06438"
      />
      <rect
        x="722.914"
        y="84.8623"
        width="88.7144"
        height="25.9849"
        fill="#D06438"
      />
      <rect
        x="810.547"
        y="84.8623"
        width="65.7033"
        height="25.9849"
        fill="#DCBE50"
      />
      <rect
        x="876.25"
        y="84.8623"
        width="53.649"
        height="25.9849"
        fill="#C82828"
      />
      <rect
        x="929.898"
        y="84.8623"
        width="65.7033"
        height="25.9849"
        fill="#C82828"
      />
      <rect
        x="602.895"
        y="132.26"
        width="120.052"
        height="25.9849"
        fill="#DCBE50"
      />
      <rect
        x="602.895"
        y="158.245"
        width="120.052"
        height="36.2499"
        fill="#A9B03E"
      />
      <rect
        x="602.895"
        y="194.495"
        width="120.052"
        height="30.2576"
        fill="#CAB94A"
      />
      <rect
        x="722.914"
        y="212.479"
        width="65.6571"
        height="12.2738"
        fill="#D06438"
      />
      <rect
        x="722.914"
        y="188.236"
        width="65.6571"
        height="24.2427"
        fill="#A9B03E"
      />
      <rect
        x="722.914"
        y="158.244"
        width="120.729"
        height="29.9909"
        fill="#D48240"
      />
      <rect
        x="788.574"
        y="188.236"
        width="55.0722"
        height="20.9935"
        fill="#A9B03E"
      />
      <rect
        x="828.188"
        y="209.229"
        width="15.462"
        height="5.86588"
        fill="#DCBE50"
      />
      <rect
        x="788.574"
        y="209.229"
        width="39.6099"
        height="5.86588"
        fill="#6EA028"
      />
      <rect
        x="788.574"
        y="219.283"
        width="39.6099"
        height="5.46837"
        fill="#DCBE50"
      />
      <rect
        x="788.574"
        y="215.095"
        width="24.1033"
        height="4.18888"
        fill="#6EA028"
      />
      <rect
        x="812.68"
        y="215.095"
        width="15.5062"
        height="4.18888"
        fill="#C82828"
      />
      <rect
        x="828.188"
        y="215.095"
        width="15.5062"
        height="4.76462"
        fill="#6EA028"
      />
      <rect
        x="828.188"
        y="219.859"
        width="15.5062"
        height="4.89258"
        fill="#DCBE50"
      />
      <rect
        x="843.691"
        y="219.859"
        width="54.0061"
        height="4.89258"
        fill="#A9B03E"
      />
      <rect
        x="897.703"
        y="219.859"
        width="21.3716"
        height="4.89258"
        fill="#A9B03E"
      />
      <rect
        x="919.066"
        y="219.859"
        width="14.7607"
        height="4.89258"
        fill="#6EA028"
      />
      <rect
        x="988.34"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#C82828"
      />
      <rect
        x="981.078"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#DCBE50"
      />
      <rect
        x="973.82"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#A9B03E"
      />
      <rect
        x="966.559"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#A9B03E"
      />
      <rect
        x="959.297"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#D06438"
      />
      <rect
        x="952.039"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#A9B03E"
      />
      <rect
        x="944.777"
        y="222.583"
        width="7.26159"
        height="2.16805"
        fill="#A9B03E"
      />
      <rect
        x="933.883"
        y="222.583"
        width="10.8926"
        height="2.16805"
        fill="#A9B03E"
      />
      <rect
        x="988.34"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#D06438"
      />
      <rect
        x="981.078"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#DCBE50"
      />
      <rect
        x="973.82"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#DCBE50"
      />
      <rect
        x="966.559"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#A9B03E"
      />
      <rect
        x="959.297"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#D48240"
      />
      <rect
        x="952.039"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#A9B03E"
      />
      <rect
        x="944.777"
        y="219.859"
        width="7.26159"
        height="2.72464"
        fill="#A9B03E"
      />
      <rect
        x="933.828"
        y="219.859"
        width="10.9461"
        height="2.72464"
        fill="#CAB94A"
      />
      <rect
        x="843.652"
        y="158.244"
        width="151.957"
        height="61.6147"
        fill="#CAB94A"
      />
      <rect
        x="810.547"
        y="136.995"
        width="44.5383"
        height="21.249"
        fill="#6EA028"
      />
      <rect
        x="855.09"
        y="110.847"
        width="43.4112"
        height="17.2131"
        fill="#CAB94A"
      />
      <rect
        x="855.09"
        y="128.06"
        width="43.4112"
        height="17.2131"
        fill="#A9B03E"
      />
      <rect
        x="855.09"
        y="145.273"
        width="43.4112"
        height="12.9711"
        fill="#A9B03E"
      />
      <rect
        x="898.5"
        y="148.698"
        width="32.9152"
        height="9.54622"
        fill="#CAB94A"
      />
      <rect
        x="898.5"
        y="138.074"
        width="32.9152"
        height="10.6245"
        fill="#CAB94A"
      />
      <rect
        x="898.5"
        y="124.46"
        width="32.9152"
        height="13.6135"
        fill="#D48240"
      />
      <rect
        x="898.5"
        y="110.847"
        width="32.9152"
        height="13.6135"
        fill="#A9B03E"
      />
      <rect
        x="931.418"
        y="110.847"
        width="32.9152"
        height="13.6135"
        fill="#CAB94A"
      />
      <rect
        x="931.418"
        y="124.46"
        width="32.9152"
        height="8.00463"
        fill="#DCBE50"
      />
      <rect
        x="964.328"
        y="124.46"
        width="31.2752"
        height="8.00463"
        fill="#D06438"
      />
      <rect
        x="958.395"
        y="132.465"
        width="21.5698"
        height="8.45397"
        fill="#6EA028"
      />
      <rect
        x="954.094"
        y="140.919"
        width="26.4889"
        height="6.22953"
        fill="#DCBE50"
      />
      <rect
        x="954.094"
        y="147.148"
        width="11.4319"
        height="6.22953"
        fill="#DCBE50"
      />
      <rect
        x="965.527"
        y="147.148"
        width="8.69434"
        height="11.096"
        fill="#6EA028"
      />
      <rect
        x="954.094"
        y="153.378"
        width="11.4319"
        height="4.86632"
        fill="#DCBE50"
      />
      <rect
        x="984.176"
        y="150.413"
        width="11.4319"
        height="7.83149"
        fill="#DCBE50"
      />
      <rect
        x="974.223"
        y="150.413"
        width="9.95337"
        height="7.83149"
        fill="#DCBE50"
      />
      <rect
        x="974.223"
        y="147.148"
        width="9.95337"
        height="3.26448"
        fill="#DCBE50"
      />
      <rect
        x="989.469"
        y="147.148"
        width="6.13363"
        height="3.26448"
        fill="#DCBE50"
      />
      <rect
        x="984.176"
        y="147.148"
        width="5.29783"
        height="3.26448"
        fill="#DCBE50"
      />
      <rect
        x="980.582"
        y="140.919"
        width="15.0229"
        height="6.22953"
        fill="#DCBE50"
      />
      <rect
        x="979.965"
        y="132.465"
        width="15.6381"
        height="8.45397"
        fill="#DCBE50"
      />
      <rect
        x="931.418"
        y="132.465"
        width="26.9831"
        height="8.45397"
        fill="#DCBE50"
      />
      <rect
        x="931.418"
        y="140.919"
        width="22.6788"
        height="6.22953"
        fill="#DCBE50"
      />
      <rect
        x="931.418"
        y="147.148"
        width="22.6788"
        height="6.22953"
        fill="#D48240"
      />
      <rect
        x="931.418"
        y="153.378"
        width="22.6788"
        height="4.86632"
        fill="#6EA028"
      />
      <rect
        x="964.328"
        y="110.847"
        width="31.2752"
        height="13.6135"
        fill="#D48240"
      />
      <rect
        x="810.547"
        y="110.847"
        width="44.5383"
        height="26.1484"
        fill="#A9B03E"
      />
      <rect
        x="722.734"
        y="110.847"
        width="87.8114"
        height="26.1484"
        fill="#D48240"
      />
      <rect
        x="722.734"
        y="136.995"
        width="87.8114"
        height="21.2491"
        fill="#C82828"
      />
      <rect x="784.5" width="107.101" height="42.4311" fill="#A9B03E" />
      <rect
        x="784.5"
        y="42.4307"
        width="116.932"
        height="42.4311"
        fill="#CAB94A"
      />
      <rect x="891.609" width="103.998" height="42.4311" fill="#DCBE50" />
      <rect
        x="901.438"
        y="42.4307"
        width="94.1673"
        height="17.58"
        fill="#A9B03E"
      />
      <rect
        x="965.742"
        y="60.0112"
        width="29.8614"
        height="8.88248"
        fill="#D48240"
      />
      <rect
        x="931.184"
        y="60.0112"
        width="34.5601"
        height="8.88248"
        fill="#A9B03E"
      />
      <rect
        x="931.184"
        y="68.8931"
        width="64.4211"
        height="7.10798"
        fill="#C4C4C4"
      />
      <rect
        x="931.184"
        y="68.8931"
        width="64.4211"
        height="7.10798"
        fill="#6EA028"
      />
      <rect
        x="977.672"
        y="76.002"
        width="17.9307"
        height="8.86052"
        fill="#A9B03E"
      />
      <rect
        x="959.738"
        y="76.002"
        width="17.9307"
        height="8.86069"
        fill="#6EA028"
      />
      <rect
        x="941.812"
        y="76.002"
        width="17.9307"
        height="8.86052"
        fill="#D06438"
      />
      <rect
        x="931.184"
        y="76.002"
        width="10.6277"
        height="8.86052"
        fill="#CAB94A"
      />
      <rect
        x="901.438"
        y="60.0112"
        width="29.7462"
        height="12.4366"
        fill="#6EA028"
      />
      <rect
        x="901.438"
        y="72.4263"
        width="29.7462"
        height="12.4366"
        fill="#DCBE50"
      />
      <rect x="999.281" width="209.265" height="61.9675" fill="#A9B03E" />
      <rect
        x="999.281"
        y="112.669"
        width="99.1387"
        height="71.5777"
        fill="#6EA028"
      />
      <rect
        x="1098.42"
        y="112.669"
        width="102.226"
        height="35.7888"
        fill="#D48240"
      />
      <rect
        x="1098.42"
        y="148.458"
        width="68.7445"
        height="27.7562"
        fill="#D48240"
      />
      <rect
        x="1167.17"
        y="148.458"
        width="77.4416"
        height="19.1339"
        fill="#CAB94A"
      />
      <rect
        x="1167.17"
        y="167.592"
        width="61.3751"
        height="21.4602"
        fill="#DCBE50"
      />
      <rect
        x="999.281"
        y="184.247"
        width="99.1387"
        height="40.5052"
        fill="#D48240"
      />
      <rect
        x="1098.42"
        y="176.214"
        width="68.7448"
        height="25.4015"
        fill="#A9B03E"
      />
      <rect
        x="1098.42"
        y="201.616"
        width="68.7445"
        height="23.1365"
        fill="#C82828"
      />
      <rect
        x="1167.17"
        y="188.915"
        width="61.375"
        height="18.5314"
        fill="#CAB94A"
      />
      <rect
        x="1228.54"
        y="183.665"
        width="49.4398"
        height="14.5154"
        fill="#D06438"
      />
      <rect
        x="1228.54"
        y="198.181"
        width="49.4398"
        height="14.1552"
        fill="#A9B03E"
      />
      <rect
        x="1228.54"
        y="212.336"
        width="49.4398"
        height="12.4163"
        fill="#CAB94A"
      />
      <rect
        x="1277.98"
        y="195.909"
        width="35.0008"
        height="10.0059"
        fill="#C82828"
      />
      <rect
        x="1382.67"
        y="214.834"
        width="9.32016"
        height="5.06078"
        fill="#CAB94A"
      />
      <rect
        x="1386.66"
        y="219.895"
        width="5.33238"
        height="4.85707"
        fill="#CAB94A"
      />
      <rect
        x="1381.32"
        y="219.895"
        width="5.33238"
        height="2.53147"
        fill="#D06438"
      />
      <rect
        x="1378.11"
        y="219.895"
        width="3.21336"
        height="2.53147"
        fill="#C82828"
      />
      <rect
        x="1372.88"
        y="219.895"
        width="5.23114"
        height="4.85707"
        fill="#D06438"
      />
      <rect
        x="1364.03"
        y="219.895"
        width="8.8505"
        height="4.85707"
        fill="#D48240"
      />
      <rect
        x="1381.32"
        y="222.426"
        width="5.33238"
        height="2.32554"
        fill="#C82828"
      />
      <rect
        x="1378.11"
        y="222.426"
        width="3.21316"
        height="2.32554"
        fill="#D48240"
      />
      <rect
        x="1373.35"
        y="214.834"
        width="9.32016"
        height="5.06078"
        fill="#6EA028"
      />
      <rect
        x="1364.03"
        y="214.834"
        width="9.32016"
        height="5.06078"
        fill="#CAB94A"
      />
      <rect
        x="1364.03"
        y="210.738"
        width="14.0816"
        height="4.09589"
        fill="#D48240"
      />
      <rect
        x="1378.11"
        y="210.738"
        width="13.8787"
        height="4.09589"
        fill="#D06438"
      />
      <rect
        x="1375.68"
        y="203.324"
        width="16.3161"
        height="7.41455"
        fill="#DCBE50"
      />
      <rect
        x="1366.38"
        y="195.909"
        width="25.6064"
        height="7.41455"
        fill="#D06438"
      />
      <rect
        x="1340.95"
        y="195.909"
        width="25.6064"
        height="7.41455"
        fill="#A9B03E"
      />
      <rect
        x="1364.03"
        y="203.324"
        width="12.2066"
        height="7.41455"
        fill="#A9B03E"
      />
      <rect
        x="1350.08"
        y="203.324"
        width="13.9449"
        height="7.41455"
        fill="#6EA028"
      />
      <rect
        x="1336.14"
        y="203.324"
        width="13.9449"
        height="15.0185"
        fill="#6EA028"
      />
      <rect
        x="1312.98"
        y="203.324"
        width="23.1582"
        height="8.19055"
        fill="#D06438"
      />
      <rect
        x="1312.98"
        y="211.515"
        width="23.1582"
        height="6.82788"
        fill="#D06438"
      />
      <rect
        x="1350.08"
        y="210.738"
        width="14.0291"
        height="7.60394"
        fill="#D06438"
      />
      <rect
        x="1350.08"
        y="218.342"
        width="14.0291"
        height="6.4098"
        fill="#C82828"
      />
      <rect
        x="1336.14"
        y="218.342"
        width="14.0291"
        height="6.4098"
        fill="#DCBE50"
      />
      <rect
        x="1312.98"
        y="218.342"
        width="23.1582"
        height="6.4098"
        fill="#DCBE50"
      />
      <rect
        x="1312.98"
        y="195.909"
        width="28.0595"
        height="7.41455"
        fill="#CAB94A"
      />
      <rect
        x="1277.98"
        y="205.915"
        width="35.0008"
        height="10.2044"
        fill="#CAB94A"
      />
      <rect
        x="1277.98"
        y="216.099"
        width="35.0008"
        height="8.65295"
        fill="#CAB94A"
      />
      <rect
        x="1277.98"
        y="183.665"
        width="41.2513"
        height="12.2443"
        fill="#6EA028"
      />
      <rect
        x="1319.23"
        y="183.665"
        width="41.2513"
        height="12.2443"
        fill="#A9B03E"
      />
      <rect
        x="1360.48"
        y="183.665"
        width="31.5077"
        height="12.2443"
        fill="#CAB94A"
      />
      <rect
        x="1167.17"
        y="207.446"
        width="61.375"
        height="17.3058"
        fill="#D06438"
      />
      <rect
        x="1228.54"
        y="167.592"
        width="61.3751"
        height="16.0734"
        fill="#DCBE50"
      />
      <rect
        x="1289.91"
        y="167.592"
        width="51.037"
        height="16.0734"
        fill="#6EA028"
      />
      <rect
        x="1340.95"
        y="167.592"
        width="51.037"
        height="16.0734"
        fill="#CAB94A"
      />
      <rect
        x="1244.61"
        y="148.458"
        width="77.4416"
        height="19.1339"
        fill="#D06438"
      />
      <rect
        x="1322.05"
        y="148.458"
        width="69.9128"
        height="19.1339"
        fill="#A9B03E"
      />
      <rect
        x="1200.27"
        y="112.669"
        width="102.226"
        height="35.7888"
        fill="#CAB94A"
      />
      <rect
        x="1302.5"
        y="112.669"
        width="89.4581"
        height="35.7888"
        fill="#D48240"
      />
      <rect
        x="999.281"
        y="61.9673"
        width="209.265"
        height="50.7016"
        fill="#A9B03E"
      />
      <rect
        x="1208.55"
        y="61.9668"
        width="183.415"
        height="50.7016"
        fill="#CAB94A"
      />
      <rect x="1208.55" width="183.415" height="61.9675" fill="#6EA028" />
      <text v-for="(data) in data_map" :x="data.x" :y="data.y" :font-size="data.fontsize" :fill="data.fill">
          {{ data.text }} 
        </text>
    </svg>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "HeatmapExample",
  props: ["TableData"],
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      data_text: [
        {
          id: 1,
          x: 264.7215,
          y: 100.9485,
          fill: "white",
          fontsize: "30px",
        },
        {
          id: 2,
          x: 264.36,
          y: 320.5,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 3,
          x: 750,
          y: 130.5,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 4,
          x: 720.36,
          y: 310.5,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 5,
          x: 1160,
          y: 130,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 6,
          x: 1215,
          y: 180,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 7,
          x: 1170,
          y: 285,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 8,
          x: 1325,
          y: 279,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 9,
          x: 1170,
          y: 360,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 10,
          x: 1325,
          y: 340,
          fill: "white",
          fontsize: "20px",
        },
        {
          id: 11,
          x: 1325,
          y: 380,
          fill: "white",
          fontsize: "20px",
        },
      ],
      data: [
        {
          d: "M0.5 0.5 L529.943 0.5 L529.943 385.897 L0.5 385.897 Z",
          fill: "#CE5033",
          stroke: "black",
        },
        {
          d: "M530.945 0.5H861.06V386.397H530.945V0.5Z",
          fill: "#D1BB4C",
          stroke: "black",
        },
        {
          d: "M862.055 0.5H1080.15V198.273H862.055V0.5Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M862.055 199.273H1080.15V386.397H862.055V199.273Z",
          fill: "#DCBE50",
          stroke: "black",
        },
        {
          d: "M1081.15 0.5H1391.5V116.634H1081.15V0.5Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M1081.15 117.634H1391.5V233.768H1081.15V117.634Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M1081.15 234.768H1287.72V328.422H1081.15V234.768Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M1081.15 329.422H1287.72V386.397H1081.15V329.422Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M1288.72 234.768H1391.5V314.224H1288.72V234.768Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M1288.72 315.224 L1391.502 315.224 L1391.502 357.999 L1288.72 357.999 Z",
          fill: "#6EA028",
          stroke: "black",
        },
        {
          d: "M1288.72 359.001 L1391.502 359.001 L1391.502 386.397 L1288.72 386.397 Z",
          fill: "#6EA028",
          stroke: "black",
        },
      ],
      textData: [
        "BTC",
        "ETH",
        "USDT",
        "USDC",
        "BNB",
        "SQL",
        "A2",
        "A3",
        "A2",
        "A3",
        "A6",
      ],
      baseX: 200,
      baseY: 150,
      spacingX: 400,
      spacingY: 100,
      chartOptions: {
        plotOptions: {
          heatmap: {
            columnWidth: "300",
            radius: 0,
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 2,
                  to: 10,
                  color: "#44A765",
                  foreColor: "white",
                  name: "greater or equal value 2 color green",
                },
                {
                  from: 0,
                  to: 1,
                  color: "#596C78",
                  foreColor: "white",
                  name: "greater then 0 or less then 2 => value 0 to 0.9 color grey",
                },
                {
                  from: -10,
                  to: -1,
                  color: "#BD424C",
                  foreColor: "white",
                  name: "greater or equal value -1 color red",
                },
              ],
            },
          },
        },
        dataLabels: {
          offsetX: 0,
          offsetY: 0,
          enabled: true,
          position: "bottom",
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          style: {
            fontSize: "12px",
            // colors: ["#304758"]
            columnWidth: 40,
          },
        },
      },
      data_map: [],
      series: [
        {
          name: "Series 1",
          data: [
            {
              x: "SOL",
              y: "-6.89%",
            },
            {
              x: "BTC",
              y: "+8.89%",
            },
            {
              x: "USDC",
              y: "0.98%",
            },
            {
              x: "ALGO",
              y: "-9.89%",
            },
            {
              x: "BNB",
              y: "+2.48%",
            },
            {
              x: "USDT",
              y: "0.78%",
            },
          ],
        },
        {
          name: "Series 2",
          data: [
            {
              x: "TRX",
              y: "-8.89%",
            },
            {
              x: "UNI",
              y: "+8.89%",
            },
            {
              x: "BUSD",
              y: "0.98%",
            },
            {
              x: "DOT",
              y: "+6.45%",
            },
            {
              x: "USDT",
              y: "0.48%",
            },
            {
              x: "ALGO",
              y: "-1.05%",
            },
          ],
        },
        {
          name: "Series 3",
          data: [
            {
              x: "TRX",
              y: "-5.89%",
            },
            {
              x: "ETH",
              y: "-10%",
            },
            {
              x: "ADA",
              y: "+4.89%",
            },
            {
              x: "DOGE",
              y: "+10%",
            },
            {
              x: "BUSD",
              y: "0.38%",
            },
            {
              x: "USDT",
              y: "1%",
            },
          ],
        },
      ],
    };
  },
  methods: {
    check_data() {
      this.data_map = [];
      const gainItems = this.TableData.sort((a, b) => b.hourChange - a.hourChange).slice(
        0,
        20
      );
      var i = 0;
      if (gainItems.length > 0) {
        gainItems.forEach((element) => {
          if (i <= 10) {
            var persent = element.hourChange ;

            if (persent >= 0) {
              this.data[i].fill = "#197935";
            } else {
              this.data[i].fill = "#a81f1f";
            }
            var a = {
              x: this.data_text[i].x,
              y: this.data_text[i].y,
              chg: persent,
              fontsize: "20",
              fill: "white",
              text: element.Categories,
            };
            this.data_map.push(a);
          }
          i++;
        });
      }
    },
  },
  mounted() {
    this.check_data();
  },
};
</script>
<style>
.treechart-svg {
  display: flex;
  margin: auto;
}

@media only screen and (min-width: 1440px) {
  .treechart-svg {
    width: 1392px;
  }
}

@media only screen and (max-width: 1366px) {
  .treechart-svg {
    width: 1200px;
    height: 343px;
  }
}

@media only screen and (max-width: 1100px) {
  .treechart-svg {
    width: 900px;
    height: 257px;
  }
}

@media only screen and (max-width: 800px) {
  .treechart-svg {
    width: 700px;
    height: 198px;
  }
}
</style>
