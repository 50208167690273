<template>
  <div class="container text-center">

    <div class="row my-3">
      <div class="col">
        <h1><strong>ดาวน์โหลด <span class="text-orange shadow-sm">BITBULL</span></strong></h1>
        <span class="font-size">
          ก้าวสู่โลกสินทรัพย์ดิจิทัล เปิดประสบการณ์ใหม่<br>
          เริ่มลงทุนด้วยฟีเจอร์เด่น ที่คัดสรรมาเพื่อคุณ
        </span>
      </div>
    </div>

    <div class="row px-5">
      <div class="col-6 ps-5 pt-4 hero">
        <img :src="imageMobile" alt="รูปภาพ" class="img-fluid size-image-mobile">
      </div>
      <div class="col-6 text-center mt-4 pe-5">
        <div class="row mt-4">
          <div class="col">
            <strong><span class="font-size">สแกนเพื่อดาวน์โหลด</span></strong>
            <br>
            <img :src="imageQrCode" alt="รูปภาพ" class="img-fluid size-image-qr-code">
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-end">
            <img :src="imageIos" alt="รูปภาพ" class="img-fluid size-image-ios">
          </div>
          <div class="col-6 text-start">
            <img :src="imageAndroid" alt="รูปภาพ" class="img-fluid size-image-android">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      imageMobile: require('@/assets/images/download/01.png'),
      imageQrCode: require('@/assets/images/download/QR-Code---Mockup.png'),
      imageIos: require('@/assets/images/download/App-Store.png'),
      imageAndroid: require('@/assets/images/download/Google-Play.png'),
    };
  }
}
</script>

<style lang="scss" scoped>
   .hero img {
      animation: float 3s ease-in-out infinite;
    }
  
    @keyframes float {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-30px);
      }
    }
.container {
  font-family: "Sarabun", sans-serif;
}
.font-size {
  font-size: 1.5rem;
}
.text-orange {
  color: #f37021 !important;
}
.size-image-mobile {
  max-width: 100%;
  max-height: 100%;
}
.size-image-qr-code {
  max-width: 80%;
  max-height: 80%;
}
.size-image-ios {
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  border-radius: 35px;
}
.size-image-android {
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  border-radius: 35px;
}
</style>
