<template>
  <div>
    <!-- cash withdraw modal -->
    <b-modal class="otp-success-modal font-robot-flex" id="otp-success-modal" ref="otp-success-modal" :hide-footer="true"
      :hide-header="true" @shown="onModalShown" @hidden="close">
      <!-- centered -->
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modalHeading">
            <label style="line-height: 27.2px; font-family: 'Noto Sans Thai'">ยืนยันการถอนเหรียญ
              <!-- <span><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                    fill="#677F8E" />
                </svg>
              </span> -->
            </label>
            <span style="top: 5px" @click="close">&times;</span>
          </div>

          <!-- further heading -->

          <div class="modalHeadingWithdraw thai-font" style="text-align: center;">
            <label style="
                color: #9bacb6;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                font-family: Noto Sans Thai;
              ">ระบุ OTP ที่ไดัรับจาก SMS ({{ $MyFunc.formatPhone(user.mobile) }})
              <br>Ref Code : {{ this.refCode }}</label>
          </div>

          <!-- OTP INPUT FIELD -->

          <div class="OTPInput">
            <input
             type="number"
             maxlength="1"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              @input="next" 
              v-model="otp_code.digit1" 
              ref="input1"
              v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                @keyup.enter="showWithdrawOTPModal"
                onClick="this.select();"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
               />
            <input
             type="number"
             maxlength="1"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              @input="next" 
              v-model="otp_code.digit2" 
              v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                @keyup.enter="showWithdrawOTPModal"
                onClick="this.select();"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
              />
            <input
             type="number"
             maxlength="1"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              @input="next"
               v-model="otp_code.digit3" 
               v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                @keyup.enter="showWithdrawOTPModal"
                onClick="this.select();"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
               />
            <input
             type="number"
             maxlength="1"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              @input="next" 
              v-model="otp_code.digit4" 
              v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                @keyup.enter="showWithdrawOTPModal"
                onClick="this.select();"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
              />
            <input 
            type="number" maxlength="1"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              @input="next" 
              v-model="otp_code.digit5" 
              v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                @keyup.enter="showWithdrawOTPModal"
                onClick="this.select();"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
              />
            <input type="number"
             maxlength="1"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              @input="next"
               v-model="otp_code.digit6"
               v-focusOnBackspace
                v-focusOnLeftArrow
                v-focusOnRightArrow
                @keyup.enter="showWithdrawOTPModal"
                onClick="this.select();"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                />
          </div>

          <!-- buttons -->

          <div class="flexCoinWithdraw">
            <button class="btn1 thai-font" @click="showWithdrawOTPModal()">
              ยืนยัน
            </button>
            <!-- <button class="btn2">ยกเลิกการฝากเงิน</button> -->
          </div>

          <div class="bottomLine text-center">
            <label>
              กรุณาตรวจสอบรหัส OTP บนโทรศัพท์มือถือของท่าน
              ภายใน 5 นาที หากท่านไม่ได้รับรหัส OTP
              <span @click="resendOtp" class="text-primary" role="button">กรุณาคลิกที่นี่ เพื่อขอรับรหัส OTP ใหม่อีกครั้ง</span>
              <template v-if="otp_delay > 0"> in {{ otp_delay / 1000 }} second</template><br>
              กรณีไม่สามารถยืนยัน OTP ได้ โทร. {{ CALLCENTER }}
            </label>
          </div>
        </div>
      </div>

      <!-- cash withdraw done modal -->
    </b-modal>
    <otp-success-model v-if="this.otp" :bData="bData" :realName="realName" :bFee="bFee" :withdrawAmount="withdrawAmount"
      :ci="ci"></otp-success-model>
    <account-model v-else></account-model>
    <WithdrawCoinDoneModal
      showStatusButton
      @onShowStatus="$bvModal.show('qr-status-modal')"
      @onCloseSuccessModel="$bvModal.hide('request-done-modal')"
      @resetWithdrawCoin="$emit('resetWithdrawCoin')"
    ></WithdrawCoinDoneModal>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import OtpSuccessModel from "./OtpSuccessModel.vue";
import AccountModel from "./AccountModel.vue";
import WithdrawCoinDoneModal from "@/pages/deposit-withdraw/withdraw/components/withdrawCoinDoneModal.vue";
export default {
  name: "model",
  props: ["otp", "bData", "realName", "bFee", "withdrawAmount", "ci", "requestId", "otpReference","refCode"],
  components: {
    OtpSuccessModel,
    AccountModel,
    WithdrawCoinDoneModal,
  },
  data() {
    return {
      otp_lock: true,
      otp_delay: 0,
      otp_delayTime: 120000,
      otp_code: {
        digit1: null,
        digit2: null,
        digit3: null,
        digit4: null,
        digit5: null,
        digit6: null,
      },
      user:{
        mobile: ""
      },
      receivedData: ''
    };
  },
  directives: {
    focusOnBackspace: {
      inserted(el, binding) {
        el.addEventListener('keydown', (event) => {
          if (event.key === 'Backspace' && event.target.value === '') {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnLeftArrow: {
      inserted(el) {
        el.addEventListener('keydown', (event) => {
          if (event.key === 'ArrowLeft') {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnRightArrow: {
      inserted(el) {
        el.addEventListener('keydown', (event) => {
          if (event.key === 'ArrowRight') {
            event.preventDefault();
            const nextInput = el.nextElementSibling;
            if (nextInput) {
              nextInput.select();
            }
          }
        });
      },
    },
  },
  methods: {
    onModalShown() {
      this.otp_delay = this.otp_delayTime;
      this.otpDelay();
      this.$nextTick(() => {
        this.$refs.input1.focus();
      });
    },
    close() {
      this.$bvModal.hide('otp-success-modal')
      this.otp_code.digit1 = null;
      this.otp_code.digit2 = null;
      this.otp_code.digit3 = null;
      this.otp_code.digit4 = null;
      this.otp_code.digit5 = null;
      this.otp_code.digit6 = null;
    },
    showWithdrawOTPModal() {
      let self = this;
      let otp =
        this.otp_code.digit1 +
        this.otp_code.digit2 +
        this.otp_code.digit3 +
        this.otp_code.digit4 +
        this.otp_code.digit5 +
        this.otp_code.digit6;
        //console.log(otp);
      HTTP.post("/uc/withdraw/withdraw-coin-validate", {
        verificationCode : otp,
        referenceCode : this.otpReference,
        Requestid : this.requestId,
        refCode: this.refCode
      })
        .then((res) => {
          // self.$notify({
          //   group: "notification",
          //   type:
          //     res.data.code == 200
          //       ? "success"
          //       : "error",
          //   title:
          //     res.data.code == 200
          //       ? "Success"
          //       : "Otp validation Error",
          //   text: res.data.message == "USER_VALIDATED_SUCCESSFULLY" ?"Order Withdraw coin unsuccessful"
          //   :"Order Withdraw coin unsuccessful",
          // });
          if (res.data.code == 200) {
            self.$bvModal.show("request-done-modal");
            self.$refs["otp-success-modal"].hide();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next(e) {
      if (e.data == null) {
        e.target?.previousSibling?.select();
      } else {
        e.target?.nextSibling?.select();
        //console.log(e.data);
      }
    },
    resendOtp() {
      if (!this.otp_lock) {
        /* this.otp_delay = this.otp_delayTime;
        this.otpDelay(); */
      let self = this;
      HTTP.post("/uc/withdraw/withdrawresendotp")
        .then((res) => {
          if (res.data.code === 0)
          {
            self.$emit('UpdateRefCode', res.data.data.otpReference);
            self.otp_lock = true;
            self.onModalShown();
          }
          /*
          self.$notify({
            group: "notification",
            type: res.data.code === 0 ? "success" : "error",
            title:
              res.data.code === 0
                ? "Successfully Resend the OTP"
                : "OTP Resend Failed",
            text: res.data.message,
          });
          */
        })
        .catch((err) => {
          console.log(err);
        });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "ไม่สามารถส่งได้",
          text: "กรุณารอเป็นเวลา " + this.otp_delay / 1000 + " วินาที",
        });
      }
    },
    otpDelay() {
      this.otpTimeCount();
      setTimeout(() => {
        this.otp_lock = false;
      }, this.otp_delayTime);
    },
    otpTimeCount() {
      if (this.otp_delay > 0) {
        setTimeout(() => {
          this.otp_delay -= 1000;
          if (this.otp_delay > 0) {
            this.otpTimeCount();
          }
        }, 1000);
      }
    },
  },
  created() {
    const resMember = JSON.parse(localStorage.getItem("user-data")) || "{}";
    this.user.mobile = resMember?.mobile || "";
  },
};
</script>
<style>
#otp-success-modal___BV_modal_content_ {
  border: none;
  padding: 0px;
  margin-top: 122px !important;
  max-width: 424px;
  min-height: 411px;
}
</style>
<style scoped>
/* .modal-body {
  background-color: none !important;
} */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container {
  /* left: 0px; */
  /* top: 75px; */
  border-radius: 8px 8px 8px 8px;
  margin: 0px auto;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0px 25px;
  font-family: "Sarabun";
}

.modalHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75px;
  position: relative;
  line-height: 75px;
}

.modalHeading>span {
  position: absolute;
  right: 0px;
  font-size: 30px;
  color: rgb(155, 172, 182);
  cursor: pointer;
}

.modalHeading label {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #d6dde1;
}

.modalHeadingWithdraw {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OTPInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 16px;
}

.flexCoinWithdraw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bottomLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
}

.bottomLine label {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
}

.flexCoinWithdraw .btn1 {
  background-color: #09BE8B;
  margin-top: 40px;
  padding: 0px 16px;
  color: #0a0d10;
  width: 344px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  border-style: none;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.OTPInput input {
  width: 44px;
  height: 56px;
  background-color: #191b1c;
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  font-size: 30px;
  border-style: none;
  outline: none;
  caret-color: #09BE8B;
  text-align: left;
}

.l-info {
  color: #677f8e;
  font-size: 14px;
  text-align: center;
  display: block;
  line-height: 24px;
  margin-top: 32px;
}
</style>
