<template>
  <div class="wraper-main">
    <div
      class="categories-detail"
      v-show="!ShowBuySell"
      style="margin-top: 45px"
    >
    <div class="iframe-container">
    <iframe src="https://cryptet.com/en/" loading="lazy"></iframe>
  </div>
    </div>
    <div class="buy-sell-detail" v-show="ShowBuySell">
      <div class="container-fluid">
        <div class="row" style="margin-bottom: 30px; margin-top: 23px">
          <div class="col-9 col-md-8 col-lg-10" style="width: 750px">
            <h2 class="heading-coin">{{ this.detail_topic.topic }}</h2>
            <p class="discription-coin thaiLang">
              {{ this.detail_topic.detail }}
            </p>
          </div>
          <!-- <div class="vr"></div> -->
          <div class="col-3 col-md-4 col-lg-2 px-0" style="width: 220px">
            <div class="wrpaer-values">
              <div class="change">
                <p class="heading">%Change</p>
                <p
                  :class="
                    detail_topic.change >= 0
                      ? 'text-success tableData-white'
                      : 'text-danger tableData-white'
                  "
                >
                  {{
                    detail_topic.change > 0
                      ? "+" + $MyFunc.PercentChg(detail_topic.change) + " %"
                      : $MyFunc.PercentChg(detail_topic.change) + " %"
                  }}
                </p>
              </div>

              <div class="change">
                <p class="heading">MCap.</p>
                <p class="value">
                  {{ $MyFunc.NumberFormatPrice(this.detail_topic.mcap) }}
                </p>
              </div>

              <div class="change">
                <p class="heading">Volume</p>
                <p class="value">
                  {{ $MyFunc.NumberFormatPrice(this.detail_topic.volume) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dynamic-Table
        :TableHeader="TableHeading"
        :favCoinRows="favCoinRows"
        :TableData="detail_cat"
        :Showfavorite="true"
        current_page="categories"
      ></Dynamic-Table>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import Table from "../../components/table/Table.vue";
import EventBus from "../../event-bus";
export default {
  name: "categories",

  components: {
    "Dynamic-Table": Table,
  },
  data() {
    return {
      data_categories: [],
      chart_series: [],
      chart_labels: [],
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price",
          headingHourChange: "Change (24h)",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
          headingDescription: "Description",
          headingCategories: "Categories",
          headingChart: "Chart (24h)",
        },
      ],
      TableDatas: [
      ],
      ShowBuySell: false,
      favCoinRows: [],
      favCoinRows: [],
      detail_cat: [],
      data_cat: [],
      detail_topic: {
        topic: "",
        detail: "",
        change: "",
        mcap: "",
        volume: "",
      },
    };
  },
  methods: {
    async get_categories() {
      try {
        this.TableDatas = [];
        await HTTP.get("/market/announcement/get-all-web-category").then(
          (response) => {
            var data = response.data;
            this.data_cat = data.data;
            if (data.code == 0) {
              let i = 0;
              data.data.forEach((element) => {
                var img = []
                if(data.data[i].details != null){
                  for(let j= 0 ;j < data.data[i].details.length;j++){
                    var r = {
                      index:j,
                      CoinImage: data.data[i].details[j].coinName
                    }
                    img.push(r)
                    }
                }
                var a = {
                  hourChange: element.chgAvg ,
                  volume: element.volumeTotal,
                  marketCap: element.marketCapTotal,
                  Description: element.cateDetail,
                  Categories: element.cateName,
                  image:img,
                  length: i,
                };
                i++;
                this.TableDatas.push(a);
              });
            }
          }
        );
      } catch (error) {
        console.log(error)
      }
    },
    filteredCoinMarket() {
      let compareWith = this.$store.state.marketCoinSearchField;
      const filteredCoins = this.detail_cat
        .filter(
          (coin) =>
            coin.Coinsybmol.toLowerCase().indexOf(compareWith.toLowerCase()) !==
            -1
        )
        .map((coin) => {
          coin.volume = coin.volume.toString();
          return coin;
        });
      return filteredCoins;
    },
    async get_categories_detail(value) {
      try {
        this.detail_cat = [];
        let i = 0;
        var data = this.data_cat[value.length];
        if (this.data_cat[value.length].details == null) {
          this.ShowBuySell = false;
          this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "ไม่พบรายละเอียดใน  Categories " + data.cateName+ " นี้ กรุณาจัดการที่ Backoffice"
              });
          return false;
        } else {
          this.detail_topic = {
            topic: data.cateName,
            detail: data.cateDetail,
            change: data.chgAvg ,
            mcap: data.marketCapTotal,
            volume: data.volumeTotal,
          }
          this.ShowBuySell = true
        }
        this.data_cat[value.length].details.forEach((element) => {
          var a = {
            // StarIcon: require("../../assets/images/favoriteTable/star.png"),
            CoinImage: require("../../assets/images/coin_32/" + element.coinName.toLowerCase() +'.png'),
            Coinsybmol: element.symbol,
            CoinName: element.coinName,
            lastPrice: element.close,
            hourChange: element.chg24h,
            volume: element.volume,
            marketCap: element.marketCap,
            Categories: "Currency",
            series: [
              {
                name: "STOCK ABC",
                data: element.trend,
              },
            ],
          };
          i++;
          this.detail_cat.push(a);
        });
        // await HTTP.get("uc/member/get-promotion").then((response) => {
        //   if (response.data.code == 0) {
        //     this.detail_cat = response.data.data.promotionList
        //   }
        // });
      } catch (error) {
        console.log(error)
      }
    },
    updateParent(value_from_child) {
      this.get_categories_detail(value_from_child);
      // this.ShowBuySell = value_from_child.child_msg;

      // alert("hello child" + value_from_child)
    },
  },
  created() {
    this.get_categories();

  },
  mounted() {
    EventBus.$on("get-Symbol-Thumb-trend-categories", () => {
      this.get_categories();
    });
  }
};
</script>
<style lang="scss" scoped>
// .categories-detail {
//   margin-top: 38px;
// }
.textGrey {
  color: rgb(255, 255, 255);
}

.textGreyDark {
  color: #677f8e;
}

.heading-coin {
  color: #d6dde1;
  font-size: 24px;
  font-weight: 500;
  // margin-top: 3px;
  margin-left: 0.5rem;
  margin-bottom: 10px;
}

.thaiLang {
  font-family: "Sarabun" !important;
  line-height: 24px;
}

.discription-coin {
  color: #677f8e;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0.5rem;
}

.heading {
  color: #677f8e;
  font-size: 14px;
  font-weight: 400;
  // margin-right: 3rem;
  min-width: 100px;
}

.green-value {
  color: #3fde68;
  font-size: 14px;
  font-weight: 400;
}

.value {
  color: #d6dde1;
  font-size: 14px;
  font-weight: 400;
}

.change {
  // justify-content: space-between;
  display: flex;
}

.wrpaer-values {
  border-left: 2px solid #28363e;
  // width: 28%;
  margin-top: 1rem;
  margin-left: 1rem;

  p {
    margin-bottom: 5px;
    padding-left: 32px;
  }
}

.vertical-line {
  border-left: 2px solid #28363e;
}
.iframe-container {
      position: relative;
      width: 100%;
      height: 100vh; /* Set the height to 100% of the viewport height */
      overflow: hidden;
      border: none;
    }

    .iframe-container iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale(1); /* Scale to 150% */
      transform-origin: 0 0; /* Scale from top-left corner */
    }
</style>
