<template>
  <div>
    <!-- modal Edit Phone Number -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div
                class="modal-content"
                style="background-color: initial !important; height: 215px; margin-top:0 !important"
              >
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            ลบบัญชีธนาคาร 
                            <!-- <img src="@/assets/svg/question.svg" role="button"> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="hide()"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <p
                          class="content"
                          style="margin-bottom: 32px; color: #ffffff"
                        >
                          คุณต้องการลบข้อมูลบัญชีธนาคารหรือไม่
                        </p>
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div class="button text-center">
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="DeleteBank()"
                        :disabled="flagDeleteBank"
                      >
                        ยืนยัน
                      </button>
                      <button
                        type="button"
                        style="color: #ffffff"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        @click="hide()"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      flagDeleteBank: false,
    };
  },
  methods: {
    show(id) {
      this.showModal = true;
      this.flagDeleteBank = false;
    },
    hide() {
      this.showModal = false;
    },
    DeleteBank()
    {
      this.flagDeleteBank = true;
      this.$emit('DeleteBankConfirm');
    },
    enableFlagDeleteBank(){
      this.flagDeleteBank = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.w-400 {
  font-weight: 400 !important;
}
button {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.modal-title {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-content {
  width: 100% !important;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}

.content {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.btn-text-sarabun {
  width: 140px;
  height: 40px;
  border-radius: 4px;
}
.btn-cancel {
  background: #191b1c;
}
.button {
  margin-bottom: 44px;
}
</style>
