<template>
  <div class="sa-container">
    <h2>แจ้งเตือนราคาเหรียญ</h2>
    <div v-for="(item, index) in alertsList" :key="index">
      <div class="coin-c" v-for="(details, pair) in item" :key="pair">
        <p class="coin-pair">
          {{ pair.split("/")[0] }} <span>/ {{ pair.split("/")[1] }}</span>
        </p>
        <p
          class="coin-price"
          :class="
            coinMarket.find((coin) => coin.symbol === pair).pChg > 0
              ? 'green'
              : 'red'
          "
        >
          {{ $MyFunc.NumberFormat(coinMarket.find((coin) => coin.symbol === pair).price)}}
          <span>({{ $MyFunc.NumberFormat(coinMarket.find((coin) => coin.symbol === pair).pChg,4) }}%)</span>
        </p>
        <!-- adjust this to get data from details -->

        <div class="detail-c">
          <div
            class="detail-item"
            v-for="(value, detailKey) in details"
            :key="detailKey"
          >
            <p class="title">{{ showTextCondition(value.conditionType) }}</p>
            <p class="value price">{{ calPriceAlert(value.conditionType, value.conditionValue, value.basePrice) }}</p>
            <p class="value detail">{{ showTextTrigger(value.triggerType) }}</p>

            <!-- adjust this to get data from details -->
            <toggle-button
              @change="updateAlert(value.id, value.isEnable)"
              :value="value.isEnable"
              :key="value.isEnable"
              :color="{ checked: '#0c9' }"
              :height="24"
              :width="55"
              class="mb-0"
              :labels="{ checked: 'ON', unchecked: 'OFF' }"
            />
            <span class="del" @click="showDeleteModal(value.id)">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.3 17.5C2.8 17.5 2.375 17.325 2.025 16.975C1.675 16.625 1.5 16.2 1.5 15.7V3H0.5V1.5H5V0.625H11V1.5H15.5V3H14.5V15.7C14.5 16.2 14.325 16.625 13.975 16.975C13.625 17.325 13.2 17.5 12.7 17.5H3.3ZM13 3H3V15.7C3 15.7833 3.02933 15.8543 3.088 15.913C3.146 15.971 3.21667 16 3.3 16H12.7C12.7667 16 12.8333 15.9667 12.9 15.9C12.9667 15.8333 13 15.7667 13 15.7V3ZM5.4 14H6.9V5H5.4V14ZM9.1 14H10.6V5H9.1V14ZM3 3V16V15.7V3Z"
                  fill="#09BE8B"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="alertsList.length < 1">
      <p class="title text-center">--ไม่พบข้อมูล--</p>
    </div>

    <DeleteModal :id="selectedId" @deleteDone="deleteDone" />
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import EventBus from "../../event-bus";
import DeleteModal from "../chart/components/DeleteModal.vue";
export default {
  components: { DeleteModal },
  data() {
    return {
      alertsList: [],
      selectedId: 0,
      coinMarket: [],
    };
  },
  created() {
    this.getCoinMarket();
    this.getAlerts();
  },
  methods: {
    getAlerts() {
      HTTP.get("/uc/member/get-all-coin-price-alert")
        .then((res) => {
          this.alertsList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showDeleteModal(id) {
      this.selectedId = id;
      this.$bvModal.show("del-modal");
    },
    deleteDone(e) {
      this.alertsList = this.alertsList.filter((item) => {
        const pairKey = Object.keys(item)[0];
        item[pairKey] = item[pairKey].filter(
          (obj) => String(obj.id) !== String(e)
        );
        return item[pairKey].length !== 0;
      });
    },
    getCoinMarket() {
      HTTP.post("/market/symbol-thumb", {
        emulateJSON: true,
      }).then((response) => {
        var resp = response.data;
        this.coinMarket = [];
        for (let i = 0; i < resp.length; i++) {
          let coinMarket = {
            symbol: resp[i].symbol,
            price: resp[i].close,
            pChg: resp[i].chg,
            volume: resp[i].volume,
          };
          this.coinMarket.push(coinMarket);
        }
      });
    },
    showTextCondition(conditionType){
      if(conditionType == 0){
        return "แจ้งเตือนเมื่อราคาถึง";
      }else if(conditionType == 1 || conditionType == 2){
        return "แจ้งเตือนเมื่อราคาเปลี่ยนแปลง";
      }else{
        return '';
      }
    },
    calPriceAlert(conditionType, conditionValue, basePrice){
      if(conditionType == 0){
        return `${this.$MyFunc.NumberFormat(conditionValue)} THB`;
      }else if(conditionType == 1){
        return `+${(((conditionValue - basePrice) / basePrice) * 100).toFixed(0)}% (${this.$MyFunc.NumberFormat(conditionValue)} THB)`;
      }else if(conditionType == 2){
        return `-${(((basePrice - conditionValue) / basePrice) * 100).toFixed(0)}% (${this.$MyFunc.NumberFormat(conditionValue)} THB)`;
      }else{
        return '';
      }
    },
    showTextTrigger(triggerType){
      if(triggerType == 0){
        return "เตือนวันละ 1 ครั้ง";
      }else if(triggerType == 1){
        return "เตือนครั้งเดียว";
      }else if(triggerType == 2){
        return "เตือนทุกครั้งเมื่อถึงเงื่อนไข";
      }else{
        return '';
      }
    },
    updateAlert(Id, Value){
      HTTP.post("/uc/member/update-coin-price-alert",
      {
        id: Id,
        isEnable: Value ? 0 : 1,
      })
        .then((res) => {
          this.getAlerts();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
<style lang="scss">
.sa-container {
  padding: 24px 48px;
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #d6dde1;
  }
  .coin-c {
    margin-top: 39px;
    .coin-pair {
      display: inline-block;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      span {
        color: #9bacb6;
      }
    }
    .coin-price {
      display: inline-block;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-left: 18px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .red {
    color: #de2d40;
  }
  .green {
    color: #0c9;
  }
  .detail-c {
    margin-top: 22px;
    border-bottom: 1px solid #28363e;
    padding-bottom: 18px;
    .detail-item {
      margin-bottom: 22px;
      p {
        display: inline-block;
        margin-bottom: 0;
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
      }
      p.title {
        color: #9bacb6;
        width: 214px;
      }
      .price {
        width: 219px;
      }
      .detail {
        width: 252px;
      }
      .del {
        margin-left: 36px;
        cursor: pointer;
      }
    }
  }
}
</style>
