<template>
  <b-modal class="cp2" id="cp2" :hide-footer="true" :hide-header="true">
    <div>
      <h2 style="margin-top: 30px">เลือกสี</h2>
      <span @click="$bvModal.hide('cp2')" class="close"></span>
      <div class="d-flex justify-content-center" style="margin-top: 52px">
        <Rectangles @change-color="changeColor" />
      </div>
      <div class="text-center">
        <button class="cp2-b" @click="handleSelectColor">เลือกสีอื่น</button>
      </div>
    </div>
    <Cp1 @check-color="onChangeColorcheck" @confirm-color="confirm_colorset" />
  </b-modal>
</template>

<script>
import Rectangles from "./Rectangles.vue";
import Cp1 from "../components/Cp1.vue"; // ตรวจสอบการนำเข้าให้ถูกต้อง
import ColorPicker from "./ColorPicker.vue";

export default {
  components: { Rectangles, Cp1, ColorPicker },
  data() {
    return {
      selectcolor: "",
    };
  },
  methods: {
    handleSelectColor() {
      this.$bvModal.show("cp1");
    },
    onChangeColorcheck(value) {
      if (value) {
        this.selectcolor = value;
      }
    },
    confirm_colorset(value) {
      const rgbValues = value.match(/\d+/g);
      this.changeColor(value);
      // if (rgbValues && rgbValues.length === 3) {
      //   const hexColor = this.rgbToHex(
      //     parseInt(rgbValues[0]),
      //     parseInt(rgbValues[1]),
      //     parseInt(rgbValues[2])
      //   );

      // }
    },
    rgbToHex(r, g, b) {
      return (
        "#" +
        ((1 << 24) + (r << 16) + (g << 8) + b)
          .toString(16)
          .slice(1)
          .toUpperCase()
      );
    },
    changeColor(color) {
      // ส่งค่าสีไปยังหน้า 1
      this.$emit("send-color", color);
      this.$bvModal.hide("cp1");
      this.$bvModal.hide("cp2");
    },
  },
};
</script>

<style lang="scss">
#cp2___BV_modal_content_ {
  width: 480px;
  height: 446px;
  margin-top: 76px !important;
}
#cp2___BV_modal_body_ {
  .close {
    position: absolute;
    top: 28px;
    right: 30px;
    cursor: pointer;
  }
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  .cp2-b {
    width: 294px;
    height: 40px;
    background: #09BE8B;
    border-radius: 4px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a0d10;
    margin-top: 80px;
  }
}
</style>
