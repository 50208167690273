<template>
  <div class="grid order-history">
    <div class="row m-0">
      <h1 class="p-0">Order History</h1>
      <div class="menu-tabs p-0">
        <ul>
          <li
            @click="active_tab = 'Limit & Market Orders'"
            :class="active_tab === 'Limit & Market Orders' ? 'active' : ''"
          >
            Limit & Market Orders
          </li>
          <li
            v-if="!$store.state.hide_menu"
            @click="active_tab = 'Trailing Stop'"
            :class="active_tab === 'Trailing Stop' ? 'active' : ''"
          >
            Trailing Stop
          </li>
        </ul>
      </div>
    </div>
    <div v-if="active_tab === 'Limit & Market Orders'" class="row m-0">
      <div class="col-12 filter-bar p-0">
        <div class="search-input">
          <input type="text" v-model="searchTerm" placeholder="ค้นหาเหรียญ" />
          <span><img src="@/assets/svg/search.svg" /></span>
        </div>
        <div class="filter-buttons">
          <button
            class="btn-all"
            @click="activeBtn = 99"
            :class="activeBtn == 99 ? 'active' : ''"
          >
            All
          </button>
          <button
            class="btn-buy"
            @click="activeBtn = 0"
            :class="activeBtn == 0 ? 'active' : ''"
          >
            Buy
          </button>
          <button
            class="btn-sell"
            @click="activeBtn = 1"
            :class="activeBtn == 1 ? 'active' : ''"
          >
            Sell
          </button>
        </div>
        <div class="select-status">
          <div class="second-selectbox">
            <div
              class="select"
              tabindex="0"
              @click="open_dropdown = !open_dropdown"
              @blur="open_dropdown = false"
            >
              <span class="text" style="left: 15px">{{
                $MyFunc.recHistory(current_status)
              }}</span>
              <span class="icon" :class="open_dropdown ? 'rotate-sc-icon' : ''">
                <img src="@/assets/svg/arrow-down.svg" />
              </span>
              <div class="options" v-show="open_dropdown">
                <ul>
                  <li @click="current_status = '99'">All Status</li>
                  <li @click="current_status = '1'">Match</li>
                  <li @click="current_status = '2'">Canceled</li>
                  <li @click="current_status = '0'">Pending</li>
                  <li @click="current_status = '4'">Rejected</li>
                  <li @click="current_status = '3'">Overtimed</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-date-picker">
          <date-picker
            :lang="lang"
            v-model="historyTime"
            :placeholder="historyTimeplaceholder"
            :editable="false"
            :format="TIMEPICKER"
            range
            style="width: 221px"
            popup-class="date-picker-style"
            :show-week-number="true"
            @change="historyData()"
          >
            <template v-slot:icon-calendar>
              <img src="@/assets/svg/calendar.svg" role="button" />
            </template>
          </date-picker>
        </div>
        <div class="export-button">
          <button @click="exportToExcel">Export to Excel</button>
        </div>
      </div>
      <div class="col-md-12 table-responsive p-0 m-0" style="overflow: auto">
        <table class="table table-borderless text-start p-0" id="myTable">
          <thead>
            <tr>
              <th scope="col">
                <span
                  @click="
                    historyData('time'),
                      (headCoinDowntime_lo = !headCoinDowntime_lo)
                  "
                >
                Order Date
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowntime_lo === null
                            ? '#808080'
                            : headCoinDowntime_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowntime_lo === null
                            ? '#808080'
                            : headCoinDowntime_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th
                style="padding-right: 20px"
                scope="col"
                @click="
                  historyData('bs'), (headCoinDownbs_lo = !headCoinDownbs_lo)
                "
              >
                B/S
                <svg
                  class="yellow"
                  width="6"
                  height="12"
                  viewBox="0 0 6 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4623_9379)">
                    <path
                      d="M0 8L3 11L6 8H0Z"
                      :fill="
                        headCoinDownbs_lo === null
                          ? '#808080'
                          : headCoinDownbs_lo
                          ? '#09BE8B'
                          : '#808080'
                      "
                    />
                    <path
                      d="M6 4L3 1L0 4L6 4Z"
                      :fill="
                        headCoinDownbs_lo === null
                          ? '#808080'
                          : headCoinDownbs_lo
                          ? '#808080'
                          : '#09BE8B'
                      "
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4623_9379">
                      <rect width="6" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </th>
              <th
                style="padding-right: 20px"
                scope="col"
                @click="
                  historyData('coin'),
                    (headCoinDowncoin_lo = !headCoinDowncoin_lo)
                "
              >
                Coin
                <svg
                  class="yellow"
                  width="6"
                  height="12"
                  viewBox="0 0 6 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4623_9379)">
                    <path
                      d="M0 8L3 11L6 8H0Z"
                      :fill="
                        headCoinDowncoin_lo === null
                          ? '#808080'
                          : headCoinDowncoin_lo
                          ? '#09BE8B'
                          : '#808080'
                      "
                    />
                    <path
                      d="M6 4L3 1L0 4L6 4Z"
                      :fill="
                        headCoinDowncoin_lo === null
                          ? '#808080'
                          : headCoinDowncoin_lo
                          ? '#808080'
                          : '#09BE8B'
                      "
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4623_9379">
                      <rect width="6" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </th>
              <th scope="col">
                <span
                  @click="
                    historyData('type'),
                      (headCoinDowntype_lo = !headCoinDowntype_lo)
                  "
                >
                  Type
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowntype_lo === null
                            ? '#808080'
                            : headCoinDowntype_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowntype_lo === null
                            ? '#808080'
                            : headCoinDowntype_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    historyData('status'),
                      (headCoinDownstatus_lo = !headCoinDownstatus_lo)
                  "
                >
                  Status
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownstatus_lo === null
                            ? '#808080'
                            : headCoinDownstatus_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownstatus_lo === null
                            ? '#808080'
                            : headCoinDownstatus_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col" class="text-end">
                <span
                  @click="
                    historyData('matchdate'),
                      (headCoinDownmatchdate_lo = !headCoinDownmatchdate_lo)
                  "
                >
                Status Date
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownmatchdate_lo === null
                            ? '#808080'
                            : headCoinDownmatchdate_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownmatchdate_lo === null
                            ? '#808080'
                            : headCoinDownmatchdate_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col" class="text-end">
                <span
                  @click="
                    historyData('averageFillPrice'),
                      (headCoinDownprice_lo = !headCoinDownprice_lo)
                  "
                >
                  Price
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownprice_lo === null
                            ? '#808080'
                            : headCoinDownprice_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownprice_lo === null
                            ? '#808080'
                            : headCoinDownprice_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col" class="text-end">
                <span
                  @click="
                    historyData('amount'),
                      (headCoinDownamount_lo = !headCoinDownamount_lo)
                  "
                >
                  Order Volume
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownamount_lo === null
                            ? '#808080'
                            : headCoinDownamount_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownamount_lo === null
                            ? '#808080'
                            : headCoinDownamount_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col" class="text-end">
                <span
                  @click="
                    historyData('netQuantity'),
                      (headCoinDownvolume_lo = !headCoinDownvolume_lo)
                  "
                >
                Matched Volume
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownvolume_lo === null
                            ? '#808080'
                            : headCoinDownvolume_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownvolume_lo === null
                            ? '#808080'
                            : headCoinDownvolume_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col" class="text-end">
                <span
                  @click="
                    historyData('feeVatTotal'),
                      (headCoinDownfree_lo = !headCoinDownfree_lo)
                  "
                >
                  Fee + VAT
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownfree_lo === null
                            ? '#808080'
                            : headCoinDownfree_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownfree_lo === null
                            ? '#808080'
                            : headCoinDownfree_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col" class="text-end">
                <span
                  @click="
                    historyData('totalIncome'),
                      (headCoinDowntotal_lo = !headCoinDowntotal_lo)
                  "
                >
                  Total
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowntotal_lo === null
                            ? '#808080'
                            : headCoinDowntotal_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowntotal_lo === null
                            ? '#808080'
                            : headCoinDowntotal_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>

              <th scope="col" style="padding-left: 30px">
                <span
                  @click="
                    historyData('condition'),
                      (headCoinDowncondition_lo = !headCoinDowncondition_lo)
                  "
                >
                  Conditions
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowncondition_lo === null
                            ? '#808080'
                            : headCoinDowncondition_lo
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowncondition_lo === null
                            ? '#808080'
                            : headCoinDowncondition_lo
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
            </tr>
          </thead>

          <tbody v-if="isUserLogin">
            <!-- skaleton start -->
            <template v-if="pageLoading">
              <tr v-for="(item, index) in 10" :key="index + 'e'">
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
                <td>
                  <b-skeleton
                    class="skc custom-skeleton-wave"
                    animation="wave"
                    width="80%"
                  ></b-skeleton>
                </td>
              </tr>
            </template>
            <!-- skaleton end -->
            <template v-else>
              <template v-if="filteredOrderHistory.length == 0">
                <tr>
                  <td colspan="10" class="text-center">-- ไม่มีข้อมูล --</td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(order, i) in filteredOrderHistory" :key="i">
                  <td>
                    {{
                      String(new Date(order.time).getDate()).padStart(2, "0")
                    }}/{{
                      String(new Date(order.time).getMonth() + 1).padStart(
                        2,
                        "0"
                      )
                    }}/{{ String(new Date(order.time).getFullYear()) }}
                    <span>
                      {{
                        String(new Date(order.time).getHours()).padStart(
                          2,
                          "0"
                        )
                      }}:{{
                        String(new Date(order.time).getMinutes()).padStart(
                          2,
                          "0"
                        )
                      }}:{{
                        String(new Date(order.time).getSeconds()).padStart(
                          2,
                          "0"
                        )
                      }}
                    </span>
                  </td>
                  <td style="padding-right: 20px">
                    <span :class="order.direction == 0 ? 'green' : 'red'">
                      {{ $MyFunc.BuySellDirection(order.direction, true) }}
                    </span>
                  </td>
                  <td style="padding-right: 20px">{{ order.coinSymbol }}</td>
                  <td>
                    {{ $MyFunc.BuySellType(order.type) }}
                  </td>
                  <td :class="$MyFunc.recHistoryColor(order.status)">
                    {{ $MyFunc.recHistory(order.status) }}
                  </td>
                  <td class="text-end">
                    <div v-if="order.status == 1">
                      {{ formattime(order.executionTime)
                      }}<span> {{ fortime(order.executionTime) }}</span>
                    </div>
                    <div v-if="order.status == 2">
                      {{ formattime(order.canceledTime)
                      }}<span> {{ fortime(order.canceledTime) }}</span>
                    </div>
                  </td>
                  <td class="text-end">
                    {{
                      order.status == 1
                        ? $MyFunc.NumberFormat(
                            order.averageFillPrice,
                            order.baseCoinScale
                          )
                        : $MyFunc.NumberFormat(order.price, order.baseCoinScale)
                    }}
                  </td>
                  <td class="text-end">
                    {{ formatNumber(order.amount) }}
                  </td>
                  <td class="text-end">
                    {{ formatNumber(order.netQuantity) }}
                  </td>
                  <td class="text-end">
                    {{ $MyFunc.NumberFormat(order.feeVatTotal) }}
                  </td>
                  <td class="text-end" style="padding-right: 0px">
                    {{ $MyFunc.NumberFormat(order.totalIncome) }}
                  </td>
                  <td
                    v-if="order.takeprofit != 0 && order.stoploss == 0"
                    style="padding-left: 30px"
                  >
                    {{
                      getTPCondition(
                        order.takeprofit,
                        order.takeprofitPercent,
                        order.bathScale
                      )
                    }}
                  </td>
                  <td
                    v-else-if="order.stoploss != 0 && order.takeprofit == 0"
                    style="padding-left: 30px"
                  >
                    {{
                      getSLCondition(
                        order.stoploss,
                        order.stoplossPercent,
                        order.bathScale
                      )
                    }}
                  </td>
                  <td v-else style="padding-left: 30px">
                    {{
                      getTPCondition(
                        order.takeprofit,
                        order.takeprofitPercent,
                        order.bathScale
                      )
                    }}&nbsp;&nbsp;<span
                      v-if="order.takeprofit != 0 && order.stoploss != 0"
                      >|</span
                    >&nbsp;&nbsp;{{
                      getSLCondition(
                        order.stoploss,
                        order.stoplossPercent,
                        order.bathScale
                      )
                    }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10"></td>
            </tr>
            <tr>
              <td colspan="10" style="text-align: center; color: #677f8e">
                Please
                <a href="/login" style="color: rgb(243, 130, 32)">Login</a> or
                <a :href="REGISTER_URL" style="color: rgb(243, 130, 32)"
                  >Register</a
                >
                Now to Trade
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="active_tab === 'Trailing Stop'" class="row m-0">
      <div class="col-12 filter-bar p-0">
        <div class="search-input">
          <input
            type="text"
            v-model="trailingSearchTerm"
            placeholder="ค้นหาเหรียญ"
          />
          <span><img src="@/assets/svg/search.svg" /></span>
        </div>
        <div class="filter-buttons">
          <button
            @click="trailingActiveBtn = 'ALL'"
            class="btn-all"
            :class="trailingActiveBtn == 'ALL' ? 'active' : ''"
          >
            All
          </button>
          <button
            @click="trailingActiveBtn = 'BUY'"
            class="btn-buy"
            :class="trailingActiveBtn == 'BUY' ? 'active' : ''"
          >
            Buy
          </button>
          <button
            @click="trailingActiveBtn = 'SELL'"
            class="btn-sell"
            :class="trailingActiveBtn == 'SELL' ? 'active' : ''"
          >
            Sell
          </button>
        </div>
        <div class="select-status">
          <div class="second-selectbox">
            <div
              class="select"
              tabindex="0"
              @click="open_dropdown = !open_dropdown"
              @blur="open_dropdown = false"
            >
              <span class="text" style="left: 15px">{{
                trailingCurrentStatus
              }}</span>
              <span class="icon" :class="open_dropdown ? 'rotate-sc-icon' : ''">
                <img src="@/assets/svg/arrow-down.svg" />
              </span>
              <div class="options" v-show="open_dropdown">
                <ul>
                  <li @click="trailingCurrentStatus = 'All Status'">
                    All Status
                  </li>
                  <li @click="trailingCurrentStatus = 'Matched'">Matched</li>
                  <li @click="trailingCurrentStatus = 'Cancel'">Cancel</li>
                  <li @click="trailingCurrentStatus = 'Open'">Open</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-date-picker">
          <date-picker
            :lang="lang"
            v-model="trailingHistoryTime"
            :placeholder="historyTimeplaceholder"
            :editable="false"
            :format="TIMEPICKER"
            range
            style="width: 221px"
            popup-class="date-picker-style"
            :show-week-number="true"
            @change="getTrailingData()"
          >
            <template v-slot:icon-calendar>
              <img src="@/assets/svg/calendar.svg" role="button" />
            </template>
          </date-picker>
        </div>
        <div class="export-button">
          <button @click="exportToExcelTrailing">Export to Excel</button>
        </div>
      </div>
      <div class="col-md-12 table-responsive p-0" style="overflow: auto">
        <table class="table table-borderless text-start p-0" id="myTable">
          <thead>
            <tr>
              <th scope="col">
                <span
                  @click="
                    sortTable('time'),
                      (headCoinDowntime_ts = !headCoinDowntime_ts)
                  "
                >
                  Date
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowntime_ts === null
                            ? '#808080'
                            : headCoinDowntime_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowntime_ts === null
                            ? '#808080'
                            : headCoinDowntime_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('bs'), (headCoinDownbs_ts = !headCoinDownbs_ts)
                  "
                >
                  B/S
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownbs_ts === null
                            ? '#808080'
                            : headCoinDownbs_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownbs_ts === null
                            ? '#808080'
                            : headCoinDownbs_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('coin'),
                      (headCoinDowncoin_ts = !headCoinDowncoin_ts)
                  "
                >
                  Coin
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowncoin_ts === null
                            ? '#808080'
                            : headCoinDowncoin_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowncoin_ts === null
                            ? '#808080'
                            : headCoinDowncoin_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('status'),
                      (headCoinDownstatus_ts = !headCoinDownstatus_ts)
                  "
                >
                  Status
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownstatus_ts === null
                            ? '#808080'
                            : headCoinDownstatus_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownstatus_ts === null
                            ? '#808080'
                            : headCoinDownstatus_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('startprice'),
                      (headCoinDownstartprice_ts = !headCoinDownstartprice_ts)
                  "
                >
                  Start Price (THB)
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownstartprice_ts === null
                            ? '#808080'
                            : headCoinDownstartprice_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownstartprice_ts === null
                            ? '#808080'
                            : headCoinDownstartprice_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('cond'),
                      (headCoinDowncond_ts = !headCoinDowncond_ts)
                  "
                >
                  Trailing Cond.
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowncond_ts === null
                            ? '#808080'
                            : headCoinDowncond_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowncond_ts === null
                            ? '#808080'
                            : headCoinDowncond_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('price'),
                      (headCoinDownprice_ts = !headCoinDownprice_ts)
                  "
                >
                  Price
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownprice_ts === null
                            ? '#808080'
                            : headCoinDownprice_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownprice_ts === null
                            ? '#808080'
                            : headCoinDownprice_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('volume'),
                      (headCoinDownvolume_ts = !headCoinDownvolume_ts)
                  "
                >
                  Volume
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownvolume_ts === null
                            ? '#808080'
                            : headCoinDownvolume_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownvolume_ts === null
                            ? '#808080'
                            : headCoinDownvolume_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('fee'),
                      (headCoinDownfree_ts = !headCoinDownfree_ts)
                  "
                >
                  Fee + VAT
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDownfree_ts === null
                            ? '#808080'
                            : headCoinDownfree_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDownfree_ts === null
                            ? '#808080'
                            : headCoinDownfree_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('total'),
                      (headCoinDowntotal_ts = !headCoinDowntotal_ts)
                  "
                >
                  Total
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowntotal_ts === null
                            ? '#808080'
                            : headCoinDowntotal_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowntotal_ts === null
                            ? '#808080'
                            : headCoinDowntotal_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">
                <span
                  @click="
                    sortTable('condition'),
                      (headCoinDowncondition_ts = !headCoinDowncondition_ts)
                  "
                >
                  Conditions
                  <svg
                    class="yellow"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4623_9379)">
                      <path
                        d="M0 8L3 11L6 8H0Z"
                        :fill="
                          headCoinDowncondition_ts === null
                            ? '#808080'
                            : headCoinDowncondition_ts
                            ? '#09BE8B'
                            : '#808080'
                        "
                      />
                      <path
                        d="M6 4L3 1L0 4L6 4Z"
                        :fill="
                          headCoinDowncondition_ts === null
                            ? '#808080'
                            : headCoinDowncondition_ts
                            ? '#808080'
                            : '#09BE8B'
                        "
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4623_9379">
                        <rect width="6" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </th>
              <th scope="col">Expired Date</th>
            </tr>
          </thead>
          <tbody v-if="isUserLogin">
            <template v-if="filteredTrailingHistory.length == 0">
              <tr>
                <td colspan="12" class="text-center">-- ไม่มีข้อมูล --</td>
              </tr>
            </template>
            <template v-else>
              <tr
                v-for="(order, index) in filteredTrailingHistory"
                :key="index"
              >
                <td>
                  {{ formattime(Number(order.creationDate))
                      }}<span> {{ fortime(Number(order.creationDate)) }}</span>
                </td>

                <td>
                  <span class="green" v-if="order.direction == '0'"> B </span>
                  <span class="red" v-else> S </span>
                </td>
                <td>{{ order.symbol.split("/")[0] }}</td>
                <td>
                  <span v-if="order.status == '0'"> Open </span>
                  <span v-else-if="order.status == '1'"> Activated</span>
                  <span v-else-if="order.status == '2'"> Matched </span>
                  <span v-else-if="order.status == '3'"> Cancel </span>
                  <span v-else-if="order.status == '4'"> Overtimed </span>
                  <span v-else-if="order.status == '5'" class="red">
                    Rejected</span
                  >
                  <span v-else> EXPIRED </span>
                </td>
                <td>{{ $MyFunc.NumberFormat(order.triggerPrice) }}</td>
                <td>
                  <span
                    v-if="order.trailingStep"
                    style="color: #d6dde1"
                    v-text="order.direction == '0' ? '+' : '-'"
                  ></span>
                  <span
                    v-if="order.trailingStepType == 0"
                    style="color: #d6dde1; margin-left: 0 !important"
                  >
                    {{ $MyFunc.NumberFormat(order.trailingStep) }}
                  </span>
                  <span
                    v-else
                    style="color: #d6dde1; margin-left: 0 !important"
                  >
                    {{ $MyFunc.NumberFormat(order.trailingStep) }}%
                  </span>
                </td>

                <td>{{ $MyFunc.NumberFormat(order.basePrice) }}</td>
                <td>
                  {{
                    $MyFunc.NumberFormat(order.filledQuantity, order.coinScale)
                  }}
                </td>
                <td>{{ $MyFunc.NumberFormat(order.feeVatTotal) }}</td>
                <td>{{ $MyFunc.NumberFormat(order.total) }}</td>
                <td>{{ getConditions(order) }}</td>
                <td v-if="order.expirationDate">
                  {{ exactDifference(order.expirationDate) }} ({{
                    formatExpirationDate(order.expirationDate)
                  }})
                </td>
                <td v-else></td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12"></td>
            </tr>
            <tr>
              <td colspan="12" style="text-align: center; color: #677f8e">
                Please
                <a href="/login" style="color: rgb(243, 130, 32)">Login</a> or
                <a :href="REGISTER_URL" style="color: rgb(243, 130, 32)"
                  >Register</a
                >
                Now to Trade
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
var XLSX = require("xlsx");
import { HTTP } from "@/config/axios";
import moment from "moment";
export default {
  data() {
    return {
      formattedDates: {},
      headCoinDownmatchdate_lo: null,
      headCoinDowntime_lo: null,
      headCoinDownbs_lo: null,
      headCoinDownamount_lo: null,
      headCoinDowncoin_lo: null,
      headCoinDownstatus_lo: null,
      headCoinDowntype_lo: null,
      headCoinDownprice_lo: null,
      headCoinDownvolume_lo: null,
      headCoinDownfree_lo: null,
      headCoinDowntotal_lo: null,
      headCoinDowncondition_lo: null,
      headCoinDownamount_lo: null,
      headCoinDownamount_ts: null,
      headCoinDowntime_ts: null,
      headCoinDownbs_ts: null,
      headCoinDowncoin_ts: null,
      headCoinDownstatus_ts: null,
      headCoinDowncond_ts: null,
      headCoinDownprice_ts: null,
      headCoinDownstartprice_ts: null,
      headCoinDownvolume_ts: null,
      headCoinDownfree_ts: null,
      headCoinDowntotal_ts: null,
      headCoinDowncondition_ts: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      open_dropdown: false,
      current_status: 99,
      trailingCurrentStatus: "All Status",
      trailingCurrentStatus_num: 99,
      historyTime: null,
      historyTimeplaceholder: null,
      trailingHistoryTime: [0, 0],
      active_tab: "Limit & Market Orders",
      activeBtn: 99,
      trailingActiveBtn: "ALL",
      trailingActiveBtn_num: 99,
      order_history: [],
      trailingHistory: [],
      searchTerm: "",
      trailingSearchTerm: "",
      isUserLogin: false,
      pageLoading: false,
      pageField: "",
      pageSort: "desc",
      isAscending: false,
      isCondition: false,
      digitscale: [],
    };
  },
  methods: {
    async getcoindigitscale() {
      await HTTP.get("/uc/asset/wallet/")
        .then((res) => {
          if (res.data.data) {
            res.data.data.map((item) => {
              this.digitscale.push({
                coinname: item.coin.name,
                coinUnitScale: item.coin.coinUnitScale,
              });
            });
            this.historyData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getHistoryTime() {
      let D1 = moment(new Date()).add(-1, "M");
      let D2 = moment(new Date());
      this.historyTime = [D1, D2];
      this.historyTimeplaceholder =
        moment(this.historyTime[0]).format(this.TIMEPICKER) +
        " ~ " +
        moment(this.historyTime[1]).format(this.TIMEPICKER);
    },
    async getHistoryTime_trailing() {
      let D1 = moment(new Date()).add(-1, "M");
      let D2 = moment(new Date());
      this.trailingHistoryTime = [D1, D2];
      this.historyTimeplaceholder =
        moment(this.trailingHistoryTime[0]).format(this.TIMEPICKER) +
        " ~ " +
        moment(this.trailingHistoryTime[1]).format(this.TIMEPICKER);
    },
    formattime(time) {
      return (
        String(new Date(time).getDate()).padStart(2, "0") +
        "/" +
        String(new Date(time).getMonth() + 1).padStart(2, "0") +
        "/" +
        String(new Date(time).getFullYear())
      );
    },
    fortime(time) {
      return (
        String(new Date(time).getHours()).padStart(2, "0") +
        ":" +
        String(new Date(time).getMinutes()).padStart(2, "0") +
        ":" +
        String(new Date(time).getSeconds()).padStart(2, "0")
      );
    },
    getConditions(item) {
      if (item.profitPrice > 0 && item.stopLossPrice > 0) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.profitPrice
        )}, SL: ${this.$MyFunc.NumberFormat(item.stopLossPrice)}`;
      } else if (item.profitPrice > 0 && !item.stopLossPrice > 0) {
        return `TP: ${this.$MyFunc.NumberFormat(item.profitPrice)}`;
      } else if (item.stopLossPrice > 0 && !item.profitPrice > 0) {
        return `SL: ${this.$MyFunc.NumberFormat(item.stopLossPrice)}`;
      } else if (!item.stopLossPrice > 0 && !item.profitPrice > 0) {
        return "-";
      }
    },
    getLimitMarketCondition(item, baseCoinScale) {
      if (
        item.takeprofit > 0 &&
        item.takeprofitPercent &&
        item.stoploss > 0 &&
        item.stoplossPercent
      ) {
        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit, baseCoinScale)}
              (+${item.takeprofitPercent}%) 
              | SL: ${this.$MyFunc.NumberFormat(item.stoploss, baseCoinScale)}
              (-${item.stoplossPercent}%)`;
      } else if (
        item.takeprofit > 0 &&
        item.takeprofitPercent &&
        item.stoploss > 0 &&
        !item.stoplossPercent
      ) {
        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit, baseCoinScale)}
                (+${item.takeprofitPercent}%) 
                | SL: ${this.$MyFunc.NumberFormat(
                  item.stoploss,
                  baseCoinScale
                )}`;
      } else if (
        item.takeprofit > 0 &&
        !item.takeprofitPercent &&
        item.stoploss > 0 &&
        item.stoplossPercent
      ) {
        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit, baseCoinScale)}
                | SL: ${this.$MyFunc.NumberFormat(item.stoploss, baseCoinScale)}
                (-${item.stoplossPercent}%)`;
      } else if (
        item.takeprofit > 0 &&
        !item.takeprofitPercent &&
        item.stoploss > 0 &&
        !item.stoplossPercent
      ) {
        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit, baseCoinScale)}
                | SL: ${this.$MyFunc.NumberFormat(
                  item.stoploss,
                  baseCoinScale
                )}`;
      } else if (item.takeprofit > 0 && item.takeprofitPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.takeprofit,
          baseCoinScale
        )}(+${item.takeprofitPercent}%)`;
      } else if (item.takeprofit > 0 && !item.takeprofitPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.takeprofit,
          baseCoinScale
        )}`;
      } else if (item.stoploss > 0 && !item.stoplossPercent) {
        return `SL: ${this.$MyFunc.NumberFormat(item.stoploss, baseCoinScale)}`;
      } else if (item.stoploss > 0 && item.stoplossPercent) {
        return ` SL: ${this.$MyFunc.NumberFormat(
          item.stoploss,
          baseCoinScale
        )}(-${item.stoplossPercent}%)`;
      } else if (!item.stoploss > 0 && !item.takeprofit > 0) {
        return "-";
      }
    },
    creationDate(timestamp) {
      return moment.unix(timestamp / 1000).format(this.TIMEPICKER); // this.TIMEPICKER
    },
    creationTime(timestamp) {
      return moment.unix(timestamp).format("HH:mm:ss");
    },
    exactDifference(timestamp) {
      const expirationDate = moment.unix(timestamp);
      const now = moment();

      const monthsDifference = expirationDate.diff(now, "months");
      now.add(monthsDifference, "months");

      const daysDifference = expirationDate.diff(now, "days");
      now.add(daysDifference, "days");

      const hoursDifference = expirationDate.diff(now, "hours");
      now.add(hoursDifference, "hours");

      const minutesDifference = expirationDate.diff(now, "minutes");

      let result = "";
      if (monthsDifference > 0) {
        if (daysDifference > 0) {
          result += `${monthsDifference + 1} month${
            monthsDifference + 1 > 1 ? "s" : ""
          } `;
        }

        return result.trim();
      }
      if (daysDifference > 0) {
        if (hoursDifference > 0 || minutesDifference > 0) {
          result += `${daysDifference + 1} day${
            daysDifference + 1 > 1 ? "s" : ""
          } `;
        }
        return result.trim();
      }
      // if (hoursDifference > 0) {
      //   result += `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} `;
      // }
      // if (minutesDifference > 0) {
      //   result += `${minutesDifference} minute${minutesDifference > 1 ? "s" : ""}`;
      // }
    },
    formatExpirationDate(timestamp) {
      return moment.unix(timestamp).format(this.TIMEPICKER);
    },
    exportToExcel() {
      const OrderHistory = this.filteredOrderHistory.map((order) => ({
        "Order Date":
          String(new Date(order.time).getDate()).padStart(2, "0") +
          "/" +
          String(new Date(order.time).getMonth() + 1).padStart(2, "0") +
          "/" +
          String(new Date(order.time).getFullYear()) +
          " " +
          String(new Date(order.time).getHours()).padStart(2, "0") +
          ":" +
          String(new Date(order.time).getMinutes()).padStart(2, "0") +
          ":" +
          String(new Date(order.time).getSeconds()).padStart(2, "0"),
        Coin: order.coinSymbol,
        "B/S": this.$MyFunc.BuySellDirection(order.direction, true),
        Type: this.$MyFunc.BuySellType(order.type),
        Status: this.$MyFunc.recHistory(order.status),
        "Status Date": {
        v: order.status === 1
          ? `${this.formattime(order.executionTime)} ${this.fortime(order.executionTime)}`
          : order.status === 2
          ? `${this.formattime(order.canceledTime)} ${this.fortime(order.canceledTime)}`
          : 'N/A', // Default value if status is neither 1 nor 2
        t: "s", // String type
      },
        Price: {
          v:
            order.status == 1
              ? this.$MyFunc.NumberFormat(
                  order.averageFillPrice,
                  order.baseCoinScale
                )
              : this.$MyFunc.NumberFormat(order.price, order.baseCoinScale),
          t: "n",
          //z: '#,##0.00'
          z: "#,##0." + "0".repeat(order.baseCoinScale),
        },
        "Order Volume": {
          v: this.$MyFunc.NumberFormat(order.amount, 8),
          t: "n",
          z: "#,##0." + "0".repeat(order.coinScale),
        },
        "Matched Volume": {
          v: this.$MyFunc.NumberFormat(order.netQuantity, 8),
          t: "n",
          z: "#,##0." + "0".repeat(order.coinScale),
        },
        "Fee + VAT": {
          v: this.$MyFunc.NumberFormat(order.feeVatTotal),
          t: "n",
          z: "#,##0.00",
        },
        Total: {
          v: this.$MyFunc.NumberFormat(order.totalIncome),
          t: "n",
          z: "#,##0.00",
        },
        Conditions: this.getLimitMarketCondition(order),
      }));
      /* create a new empty workbook */
      const wb = XLSX.utils.book_new();
      /* convert your array of objects to a worksheet */
      const ws = XLSX.utils.json_to_sheet(OrderHistory);
      /* add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, "Orders");
      /* save the workbook to an Excel file */
      XLSX.writeFile(wb, "orders_history.xlsx");
    },
    exportToExcelTrailing() {
      const TrailingHistory = this.filteredTrailingHistory.map((order) => ({
        Date:
          String(new Date(order.time).getDate()).padStart(2, "0") +
          "/" +
          String(new Date(order.time).getMonth() + 1).padStart(2, "0") +
          "/" +
          String(new Date(order.time).getFullYear()) +
          " " +
          String(new Date(order.time).getHours()).padStart(2, "0") +
          ":" +
          String(new Date(order.time).getMinutes()).padStart(2, "0"),
        "B/S": order.direction == "0" ? "B" : "S",
        Coin: order.symbol.split("/")[0],
        Status:
          order.status == "0"
            ? "Open"
            : order.status == "1"
            ? "Activated"
            : order.status == "2"
            ? "Matched"
            : order.status == "3"
            ? "Cancel"
            : order.status == "4"
            ? "Overtimed"
            : order.status == "5"
            ? "Rejected"
            : "EXPIRED",
        "Start Price (THB)": this.$MyFunc.NumberFormat(order.triggerPrice),
        "Trailing Cond.": order.trailingStep
          ? `${order.direction == "0" ? "+" : "-"} ${
              order.trailingStepType == 0
                ? this.$MyFunc.NumberFormat(order.trailingStep)
                : this.$MyFunc.NumberFormat(order.trailingStep) + " %"
            }`
          : "",
        Price: this.$MyFunc.NumberFormat(order.basePrice),
        Volume: this.$MyFunc.NumberFormat(
          order.filledQuantity,
          order.coinScale
        ),
        "Fee + VAT": this.$MyFunc.NumberFormat(order.feeVatTotal),
        Total: this.$MyFunc.NumberFormat(order.total),
        Conditions: this.getConditions(order),
        "Expired Date": order.expirationDate
          ? this.exactDifference(order.expirationDate) +
            this.formatExpirationDate(order.expirationDate)
          : "",
      }));
      /* create a new empty workbook */
      const wb = XLSX.utils.book_new();
      /* convert your array of objects to a worksheet */
      const ws = XLSX.utils.json_to_sheet(TrailingHistory);
      /* add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, "Orders");
      /* save the workbook to an Excel file */
      XLSX.writeFile(wb, "orders_trailing_stop.xlsx");
    },
    historyData(secField = "time") {
      if (secField === "time") {
        // this.headCoinDowntime_lo = null
        this.headCoinDownbs_lo = null;
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
      }

      if (secField === "type") {
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        // this.headCoinDowntype_lo = null
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
      }
      if (secField === "averageFillPrice") {
        this.order_history.sort((a, b) => {
          // Determine values to compare based on status
          const valueA = a.status === 1 ? a.averageFillPrice : a.price;
          const valueB = b.status === 1 ? b.averageFillPrice : b.price;

          // Sort based on headCoinDownprice_lo flag
          if (this.headCoinDownprice_lo) {
            return valueA - valueB; // Sort in ascending order
          } else {
            return valueB - valueA; // Sort in descending order
          }
        });

        this.headCoinDowntime_lo = null;
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        // this.headCoinDownprice_lo = null
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "netQuantity") {
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        // this.headCoinDownvolume_lo = null
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
      }
      if (secField === "feeVatTotal") {
        this.headCoinDowntime_lo = null;
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        // this.headCoinDownfree_lo = null
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
      }
      if (secField === "totalIncome") {
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        // this.headCoinDowntotal_lo = null
        this.headCoinDowncondition_lo = null;
      }
      if (secField === "status") {
        this.order_history.sort((a, b) => {
          if (this.isAscending) {
            return a.status - b.status; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.status - a.status; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDowncoin_lo = null;
        // this.headCoinDownstatus_lo = null
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
        this.isAscending = !this.isAscending; // สลับค่า isAscending
        this.pageLoading = false;
        return false;
      }
      if (secField === "bs") {
        this.order_history.sort((a, b) => {
          if (this.headCoinDownbs_lo) {
            return a.direction - b.direction; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.direction - a.direction; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        // this.headCoinDownbs_lo = null
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "matchdate") {
        this.order_history.sort((a, b) => {
          // Determine values to compare based on status
          const timeA =
            a.status === 1
              ? new Date(a.executionTime).getTime()
              : new Date(a.canceledTime).getTime();
          const timeB =
            b.status === 1
              ? new Date(b.executionTime).getTime()
              : new Date(b.canceledTime).getTime();

          // Sort based on headCoinDownmatchdate_lo flag
          if (this.headCoinDownmatchdate_lo) {
            return timeA - timeB; // Sort in ascending order
          } else {
            return timeB - timeA; // Sort in descending order
          }
        });

        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        // this.headCoinDownbs_lo = null
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "coin") {
        this.order_history.sort((a, b) => {
          // Sort based on headCoinDowncoin_lo flag
          if (this.headCoinDowncoin_lo) {
            return a.coinSymbol.localeCompare(b.coinSymbol); // Sort in ascending order
          } else {
            return b.coinSymbol.localeCompare(a.coinSymbol); // Sort in descending order
          }
        });

        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        // this.headCoinDowncoin_lo = null
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "amount") {
        this.order_history.sort((a, b) => {
          if (this.headCoinDownamount_lo) {
            return a.amount - b.amount; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.amount - a.amount; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDownmatchdate_lo = null;
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        // this.headCoinDowncoin_lo = null
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        this.headCoinDowncondition_lo = null;
        // this.headCoinDownamount_lo = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "condition") {
        this.order_history.sort((a, b) => {
          // Determine values to compare based on the conditions
          const valueA = a.takeprofit !== 0 ? a.takeprofit : a.stoploss;
          const valueB = b.takeprofit !== 0 ? b.takeprofit : b.stoploss;

          // Sort based on the isCondition flag
          if (this.isCondition) {
            return valueA - valueB; // Sort in ascending order
          } else {
            return valueB - valueA; // Sort in descending order
          }
        });

        this.headCoinDownmatchdate_lo = null;
        this.headCoinDownamount_lo = null;
        this.headCoinDowntime_lo = null;
        this.headCoinDownbs_lo = null;
        this.headCoinDowncoin_lo = null;
        this.headCoinDownstatus_lo = null;
        this.headCoinDowntype_lo = null;
        this.headCoinDownprice_lo = null;
        this.headCoinDownvolume_lo = null;
        this.headCoinDownfree_lo = null;
        this.headCoinDowntotal_lo = null;
        // this.headCoinDowncondition_lo = null
        this.isCondition = !this.isCondition; // สลับค่า isAscending
        this.pageLoading = false;
        return false;
      }
      if (secField == this.pageField) {
        if (this.pageSort == "asc") {
          this.pageSort = "desc";
        } else {
          this.pageSort = "asc";
        }
      } else {
        this.pageField = secField;
        this.pageSort = "desc";
      }
      if (!this.historyTime[0] || !this.historyTime[1]) {
        this.getHistoryTime();
      }
      HTTP.post("/exchange/order/personal/history", {
        startDate:
          moment(this.historyTime[0]).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(this.historyTime[1]).format("YYYY-MM-DD") + "T23:59:59",
        field: this.pageField,
        sort: this.pageSort,
      })
        .then((res) => {
          this.order_history = res.data.content;
          if (this.digitscale.length > 0) {
            this.order_history.forEach((order) => {
              const matchingScale = this.digitscale.find(
                (scale) => scale.coinname === order.coinSymbol
              );
              if (matchingScale) {
                order.coinUnitScale = matchingScale.coinUnitScale;
              }
            });
          }

          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTPCondition(tp, tpPercent, bathScale) {
      let tpCondition = "";
      if (tp != 0) {
        tpCondition = ` TP: ${this.$MyFunc.NumberFormat(tp, bathScale)}`;
        if (tpPercent != 0) {
          tpCondition += ` (+${tpPercent}%)`;
        }
      }
      return tpCondition;
    },
    getSLCondition(sl, slPercent, bathScale) {
      let slCondition = "";
      if (sl != 0) {
        slCondition = ` SL: ${this.$MyFunc.NumberFormat(sl, bathScale)}`;
        if (slPercent != 0) {
          slCondition += ` (-${slPercent}%)`;
        }
      }
      return slCondition;
    },
    formatNumber(value) {
      // Convert the value to a number if it's a string
      if (typeof value === "string") {
        // Handle JSON stringification case or any number represented as a string
        value = JSON.parse(value);
      }

      // If the value is still not a number, return it directly
      if (isNaN(value)) {
        return value;
      }

      // Format the number with Intl.NumberFormat
      return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      }).format(value);
    },
    sortTable(secField = "time") {
      this.pageLoading = true;
      if (secField === "status") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownstatus_ts) {
            return a.status - b.status; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.status - a.status; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        // this.headCoinDownstatus_ts = !this.headCoinDownstatus_ts
        this.pageLoading = false;
        return false;
      }
      if (secField === "time") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDowntime_ts) {
            return a.creationDate - b.creationDate; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.creationDate - a.creationDate; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "bs") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownbs_ts) {
            return a.direction - b.direction; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.direction - a.direction; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "coin") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDowncoin_ts) {
            return a.symbol - b.symbol; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.symbol - a.symbol; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "startprice") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownstartprice_ts) {
            return a.price - b.price; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.price - a.price; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "volume") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownvolume_ts) {
            return a.filledQuantity - b.filledQuantity; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.filledQuantity - a.filledQuantity; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        // this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "cond") {
        this.trailingHistory.sort((a, b) => {
          if (a.direction === 0 && b.direction === 0) {
            if (this.headCoinDowncond_ts) {
              return b.trailingStep - a.trailingStep; // จากมากไปน้อย
            } else {
              return a.trailingStep - b.trailingStep; // จากน้อยไปมาก
            }
          } else if (a.direction === 1 && b.direction === 1) {
            if (this.headCoinDowncond_ts) {
              return a.trailingStep - b.trailingStep; // จากน้อยไปมาก
            } else {
              return b.trailingStep - a.trailingStep; // จากมากไปน้อย
            }
          } else {
            if (this.headCoinDowncond_ts) {
              return a.direction - b.direction; // จากมากไปน้อย
            } else {
              return b.direction - a.direction; // จากน้อยไปมาก
            }
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "price") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownprice_ts) {
            return a.basePrice - b.basePrice; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.basePrice - a.basePrice; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      // if (secField === "price") {
      //   this.trailingHistory.sort((a, b) => {
      //     if (this.headCoinDownvolume_ts) {
      //       return a.basePrice - b.basePrice; // เรียงลำดับจากน้อยไปมาก
      //     } else {
      //       return b.basePrice - a.basePrice; // เรียงลำดับจากมากไปน้อย
      //     }
      //   });
      //   this.headCoinDowntime_ts = null;
      //   this.headCoinDownbs_ts = null;
      //   this.headCoinDowncoin_ts = null;
      //   this.headCoinDowncond_ts = null;
      //   this.headCoinDownprice_ts = null;
      //   this.headCoinDownstatus_ts = null;
      //   this.headCoinDownstartprice_ts = null;
      //   this.headCoinDownfree_ts = null;
      //   this.headCoinDowntotal_ts = null;
      //   this.headCoinDowncondition_ts = null;
      //   this.pageLoading = false;
      //   return false;
      // }
      if (secField === "fee") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownfree_ts) {
            return a.feeVatTotal - b.feeVatTotal; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.feeVatTotal - a.feeVatTotal; // เรียงลำดับจากน้อยไปมาก
          }
        });

        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "total") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDowntotal_ts) {
            return a.total - b.total; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.total - a.total; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "volume") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDownvolume_ts) {
            return a.filledQuantity - b.filledQuantity; // เรียงลำดับจากน้อยไปมาก
          } else {
            return b.filledQuantity - a.filledQuantity; // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.headCoinDowncondition_ts = null;
        this.pageLoading = false;
        return false;
      }
      if (secField === "condition") {
        this.trailingHistory.sort((a, b) => {
          if (this.headCoinDowncondition_ts) {
            return (
              a.takeprofitPrice - b.takeprofitPrice ||
              a.stopLossPrice - b.stopLossPrice
            ); // เรียงลำดับจากน้อยไปมาก
          } else {
            return (
              b.takeprofitPrice - a.takeprofitPrice ||
              b.stopLossPrice - a.stopLossPrice
            ); // เรียงลำดับจากมากไปน้อย
          }
        });
        this.headCoinDowntime_ts = null;
        this.headCoinDownbs_ts = null;
        this.headCoinDowncoin_ts = null;
        this.headCoinDowncond_ts = null;
        this.headCoinDownprice_ts = null;
        this.headCoinDownstatus_ts = null;
        this.headCoinDownstartprice_ts = null;
        this.headCoinDownvolume_ts = null;
        this.headCoinDownfree_ts = null;
        this.headCoinDowntotal_ts = null;
        this.pageLoading = false;
        return false;
      }
    },
    getTrailingData() {
      if (!this.trailingHistoryTime[0] || !this.trailingHistoryTime[1]) {
        this.getHistoryTime_trailing();
      }
      HTTP.post("/exchange/order/get-all-exchange-order-condition", {
        startDate:
          moment(this.trailingHistoryTime[0]).format("YYYY-MM-DD") +
          "T00:00:00",
        endDate:
          moment(this.trailingHistoryTime[1]).format("YYYY-MM-DD") +
          "T23:59:59",
        type: "TPLS",
      })
        .then((res) => {
          this.trailingHistory = res.data.data;
          if (this.trailingHistory.length > 0) {
            this.trailingHistory.forEach((item) => {
              if (item.expirationDate && item.expirationDate.length > 10) {
                item.expirationDate = item.expirationDate.slice(0, 10);
              }
            });
          }
         this.trailingHistory.sort((a, b) => b.creationDate - a.creationDate);

        })
        .catch((err) => {
          console.log(err);
        });
    },
    // calPersentTrailingCond(triggerStep, amount){
    //   return this.$MyFunc.NumberFormat((triggerStep*100)/amount);
    // },
  },
  metaInfo() {
    let pageTitle = "Order History";
    return { title: pageTitle, titleTemplate: "%s | " + this.TITLE };
  },
  computed: {
    filteredOrderHistory() {
      const searchTerm = this.searchTerm.toLowerCase().trim();
      let filtered = [];
      if (this.order_history != undefined) {
        filtered = this.order_history.filter((order) => {
          let isStatusMatch =
            this.current_status == 99 || order.status == this.current_status;
          let isDirectionMatch =
            this.activeBtn == 99 || order.direction == this.activeBtn;
          let isSymbolMatch =
            order.symbol && order.symbol.toLowerCase().startsWith(searchTerm);
          return isStatusMatch && isDirectionMatch && isSymbolMatch;
        });
      }
      return filtered;
    },
    filteredTrailingHistory() {
      // console.log("trailingActiveBtn=",this.trailingActiveBtn);
      const searchTerm = this.trailingSearchTerm.toLowerCase().trim();
      if (this.trailingCurrentStatus == "Matched") {
        this.trailingCurrentStatus_num = 2;
      } else if (this.trailingCurrentStatus == "Cancel") {
        this.trailingCurrentStatus_num = 3;
      } else if (this.trailingCurrentStatus == "Open") {
        this.trailingCurrentStatus_num = 0;
      }

      if (this.trailingActiveBtn == "BUY") {
        this.trailingActiveBtn_num = 0;
      } else if (this.trailingActiveBtn == "SELL") {
        this.trailingActiveBtn_num = 1;
      }
      let filtered = this.trailingHistory.filter((order) => {
        const isStatusMatch =
          this.trailingCurrentStatus === "All Status" ||
          order.status === this.trailingCurrentStatus_num;
        const isDirectionMatch =
          this.trailingActiveBtn == "ALL" ||
          order.direction == this.trailingActiveBtn_num;
        const isSymbolMatch =
          order.symbol && order.symbol.toLowerCase().startsWith(searchTerm);
        return isStatusMatch && isDirectionMatch && isSymbolMatch;
      });
      return filtered;
    },
  },
  created() {
    this.getHistoryTime();
    // check if user if login
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    // this.getcoindigitscale();

    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
      this.historyData();
      this.getTrailingData();
    }
  },
  watch: {
    // data_chart(value) {
    //   console.log(value)
    // },
  },
};
</script>
<style scoped>
.select {
  background: #191b1c;
  border-radius: 4px;
  width: 142px;
  height: 40px;
}

.select .options {
  position: absolute;
  top: 42px;
  z-index: 1;
  width: 100%;
  background-color: rgb(34, 43, 47);
  border-radius: 4px;
}

.select .options ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.select .options ul li {
  padding: 8px;
  cursor: pointer;
}

.select .options ul li:hover {
  background-color: #2c3b44;
}

.order-history {
  padding: 24px 25px 24px 26px !important;
}

.order-history table {
  border-spacing: 0px 24px;
  border-collapse: separate;
  margin-top: 0px;
}

.order-history table th svg {
  cursor: pointer;
  margin-left: 2px;
}

.order-history table th:nth-child(1) {
  padding-left: 0px !important;
}

.order-history table th {
  text-align: left;
  padding: 0px;
}

.order-history table td {
  padding: 0px;
}

.order-history table td:nth-child(1) {
  padding-left: 0px !important;
}
</style>
<style lang="scss">
.filter-bar {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 15px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #28363e;

  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #3c515d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d6dde1;
  border-radius: 20px;
}

.mx-input-wrapper .mx-input {
  background-color: #191b1c !important;
  color: #d6dde1;
  border: 1px solid #191b1c;
  height: 40px;
  line-height: 24px;
  &::placeholder {
    color: #d6dde1 !important;
  }
}

.mx-input-wrapper .mx-input:hover {
  border: 1px solid #191b1c !important;
}

.mx-icon-calendar,
.mx-icon-clear {
  color: #677f8e !important;
  display: flex !important;
  align-items: center !important;
}
.toggle-container {
  display: inline-block;
  cursor: pointer;
}
.toggle-checkbox {
  display: none;
}
.toggle-checkbox:checked + .toggle-label .first-path {
  fill: #09BE8B;
}
.toggle-checkbox:checked + .toggle-label .second-path {
  fill: #808080;
}
.toggle-checkbox:not(:checked) + .toggle-label .first-path {
  fill: #808080;
}
.toggle-checkbox:not(:checked) + .toggle-label .second-path {
  fill: #09BE8B;
}
.order-history {
  color: #d6dde1;

  // white-space: nowrap;
  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
  }

  .menu-tabs ul {
    padding: 0;
    border-bottom: 1px solid #28363e;

    li {
      display: inline-block;
      color: #677f8e;
      font-size: 16px;
      line-height: 19px;
      margin-right: 20px;
      padding: 10px 0;
      cursor: pointer;
    }

    li.active {
      color: #d6dde1;
      border-bottom: 2px solid #09BE8B;
    }
  }

  .search-input {
    position: relative;
    display: inline;
    margin-right: 15px;

    input {
      line-height: 24px;
      width: 142px;
      height: 40px;
      padding-left: 40px;
      padding-right: 5px;
      border-radius: 4px;
      background-color: #191b1c;
      color: white;

      &::placeholder {
        font-family: "sarabun";
        color: #566a76;
      }
    }

    span {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .filter-buttons {
    display: inline;
    margin-right: 15px;

    button {
      line-height: 24px;
      color: #9bacb6;
      width: 73px;
      height: 40px;
      margin-left: 1px;
      background-color: #191b1c;
    }

    button.active {
      color: white;
      background-color: #38454d;
    }

    .btn-all {
      border-radius: 4px 0px 0px 4px;
    }

    .btn-buy {
      background-color: #191b1c;
    }

    .btn-sell {
      border-radius: 0px 4px 4px 0px;
    }
  }

  .select-status {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    cursor: pointer;

    select {
      width: 142px;
      height: 40px;
      background-color: #191b1c;
      border-radius: 4px;
      color: #d6dde1;
      padding: 0 15px;
    }

    span {
      position: absolute;
      right: 15px;
      margin-top: 10px;
    }
  }

  .filter-date-picker {
    display: inline-block;
    position: relative;
    margin-right: 40px;
  }

  .export-button {
    display: inline-block;

    button {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      width: 124px;
      height: 40px;
      color: #677f8e;
      border-radius: 4px;
      border: 1px solid #28363e;

      &:active {
        border: 1px solid white;
        color: white;
        background-color: #38454d;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 10px;

    th {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      /* Text + Icon/Content - Sub#2, Icon */

      color: #677f8e;

      svg {
        // margin-left: 4px;
      }
    }

    tr td span {
      color: #677f8e;
      margin-left: 5px;
    }

    tr td {
      // line-height: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
    }

    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }

    td {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #d6dde1;
      vertical-align: middle;
    }

    .green {
      color: #40994f;
    }

    .red {
      color: #de2d40;
    }

    .orange {
      color: #09BE8B;
      font-weight: 400;
      font-family: "sarabun";
      cursor: pointer;
    }

    button {
      color: #d6dde1;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px;
      background: #38454d;
      border-radius: 4px;
      border: none;
    }

    button:hover {
      color: #d6dde1;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px;
      background: #38454d;
      border-radius: 4px;
      border: none;
    }
  }
}

@media only screen and (min-width: 100px) {
  .select-status {
    margin-top: 10px;
  }

  .filter-date-picker {
    margin-top: 10px;
  }

  .export-button {
    margin-top: 10px;
  }

  .search-input {
    margin-top: 10px;
  }

  .filter-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .select-status {
    margin-top: 0;
  }

  .filter-date-picker {
    margin-top: 0;
  }

  .export-button {
    margin-top: 0;
  }

  .search-input {
    margin-top: 0;
  }

  .filter-buttons {
    margin-top: 0;
  }

  .order-history .mx-input-wrapper .mx-input {
    // margin-top: 15px;
    margin-left: 10px;
  }
}

.date-picker-style {
  background-color: #191b1c;
  color: white;
}

.mx-calendar-content .cell.in-range,
.cell:hover {
  background-color: #141b1f !important;
}

.mx-calendar-content .cell.active {
  background-color: #09BE8B !important;
}

.mx-calendar-content .cell.hover-in-range {
  background-color: #141b1f !important;
}

.mx-table-date .cell.not-current-month {
  background-color: #191b1c !important;
  // color: #38454d;
}

.text-right {
  text-align: right !important;
}
</style>
