<template>
  <div>
    <b-modal
      class="limitorder-STPSL"
      id="limitorder-STPSL-market"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="stop-loss-detail-modal px-2">
        <div class="col-12">
          <h5>
            Take Profit / Stop Loss
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <span
              @click="$bvModal.hide('limitorder-STPSL-market')"
              style="position: absolute; right: 30px; cursor: pointer"
              ><svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </h5>
        </div>
        <div class="row top-row">
          <div class="col-3 ts-title">Order</div>
          <div class="col-9 ts-value">
            <div style="margin-left: 13px">
              <span class="buy-tag">BUY</span> <span style="font-weight: 500;">{{ coinName }}</span>
              <span class="gs">/{{ pairName }}</span>
            </div>
          </div>

          <div class="col-3 mt-2 ts-title">Price</div>
          <div class="col-9 mt-2 ts-value">
            <div style="margin-left: 13px">
              {{ coinCurrentValue }}
              {{ pairName }} (Market)
            </div>
          </div>

          <div class="col-3 mt-2 ts-title">Amount</div>
          <div class="col-9 mt-2 ts-value">
            <div style="margin-left: 13px">
              {{ all_coin }} {{ coinName }} 
              ({{ $MyFunc.NumberFormat(amount) }}
              {{ pairName }})
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 form-group form-check" style="padding-left: 2.5em">
            <label class="stpsl-cb">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="takeProfit"
              />
              <span class="w-title">Take Profit</span>
            </label>
          </div>
          <div class="col-9" style="padding: 0 20px 0 36px">
            <div class="row tabs-container">
              <div
                class="col-6 tab"
                @click="
                  tp = 'thb';
                  resetTP();
                "
                :class="tp === 'thb' ? 'active' : ''"
              >
                {{ pairName }}
              </div>
              <div
                class="col-6 tab"
                @click="
                  tp = '%';
                  resetTP();
                "
                :class="tp === '%' ? 'active' : ''"
              >
                %
              </div>
            </div>
            <div class="row sa-tabs" v-if="tp == '%'" style="margin-top: 8px">
              <div
                style="width: 85px"
                class="col-2 text-center sa-tab-first"
                @click="sa1 = 10"
                :class="sa1 === 10 ? 'active' : ''"
              >
                +10%
              </div>
              <div
                style="width: 85px"
                class="col-2 text-center sa-tab"
                @click="sa1 = 20"
                :class="sa1 === 20 ? 'active' : ''"
              >
                +20%
              </div>
              <div
                style="width: 85px"
                class="col-2 text-center sa-tab"
                @click="sa1 = 30"
                :class="sa1 === 30 ? 'active' : ''"
              >
                +30%
              </div>
              <div
                style="width: 86px"
                class="col-3 text-center sa-tab-last"
                @click="sa1 = null"
                :class="sa1 === null ? 'active' : ''"
              >
                <input
                  type="number"
                  placeholder="ระบุ %"
                  v-model="sa1p"
                  min="0"
                  max="6"
                  @input="handlemax"
                  class="sa-tab-last-input"
                />
              </div>
              <ul class="inp">
                <li>
                  ขายเมื่อราคา +<span
                    style="color: #8898a0"
                    v-if="sa1 ? sa1 : sa1p"
                    >{{ sa1 ? sa1 : sa1p }}</span
                  >
                  <span style="color: #8898a0" v-else>0</span>% ({{
                    this.$MyFunc.NumberFormat(percentCalculatedTp)
                  }}บาท)
                </li>
                <li>กำไรประมาณ {{ $MyFunc.NumberFormat(calculatedTp) }} บาท</li>
              </ul>
            </div>
            <div></div>
            <div class="row" v-if="tp != '%'">
              <div
                class="col-12 input-row"
                :class="{ 'input-row-tp-sl': sa1i !== null && sa1i !== '' }"
              >
              <div>
                <cleave
                  min="0"
                  max="14"
                  :options="options"
                  placeholder="0.00"
                  style="width: 344px; padding-right: 40px"
                  @blur="formatSa1i"
                  v-model="sa1i"
                ></cleave>
                <span v-if="sa1i == null || sa1i == ''"
                  >ระบุราคาที่ต้องการขาย</span
                >
                <span style="left: 310px">THB</span>
              </div>
              <div class="col-12">
                <ul class="inp pt-3" style="padding-left: 10px;">
                <li style="color: #8898a0">
                  ขายเมื่อราคา {{ this.$MyFunc.NumberFormat(sa1i) }} บาท (+{{this.$MyFunc.NumberFormat(find_percenttakeprofit(sa1i,coinCurrentValue))}}%) 
                </li>
                <li>กำไรประมาณ {{ Math.abs(coinCurrentValue - sa1i).toLocaleString()}} บาท</li>
              </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 70px; margin-bottom: 70px">
          <div class="col-3 form-group form-check" style="padding-left: 2.5em">
            <label class="stpsl-cb">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="stopLoss"
              />
              <span class="w-title">Stop Loss</span>
            </label>
          </div>
          <div class="col-9" style="padding: 0 20px 0 36px">
            <div class="row tabs-container">
              <div
                class="col-6 tab"
                @click="
                  sl = 'thb';
                  resetSL();
                "
                :class="sl === 'thb' ? 'active' : ''"
              >
                {{ pairName }}
              </div>
              <div
                class="col-6 tab"
                @click="
                  sl = '%';
                  resetSL();
                "
                :class="sl === '%' ? 'active' : ''"
              >
                %
              </div>
            </div>
            <div class="row sa-tabs" v-if="sl == '%'" style="margin-top: 8px">
              <div
                style="width: 85px"
                class="col-2 text-center sa-tab-first"
                @click="sa2 = 10"
                :class="sa2 === 10 ? 'active' : ''"
              >
                -10%
              </div>
              <div
                style="width: 85px"
                class="col-2 text-center sa-tab"
                @click="sa2 = 20"
                :class="sa2 === 20 ? 'active' : ''"
              >
                -20%
              </div>
              <div
                style="width: 85px"
                class="col-2 text-center sa-tab"
                @click="sa2 = 30"
                :class="sa2 === 30 ? 'active' : ''"
              >
                -30%
              </div>
              <div
                style="width: 86px"
                class="col-3 text-center sa-tab-last"
                @click="sa2 = null"
                :class="sa2 === null ? 'active' : ''"
              >
                <input
                  v-model="sa2p"
                  type="number"
                  min="0"
                  max="6"
                  @input="handlemax"
                  placeholder="ระบุ %"
                  class="sa-tab-last-input"
                />
              </div>
              <ul class="inp">
                <li>
                  ขายเมื่อราคา -<span
                    style="color: #8898a0"
                    v-if="sa2 ? sa2 : sa2p"
                    >{{ sa2 ? sa2 : sa2p }}</span
                  ><span style="color: #8898a0" v-else>0</span>% ({{
                    this.$MyFunc.NumberFormat(percentCalculatedSl)
                  }}
                  บาท)
                </li>
                <li>
                  ขาดทุนประมาณ {{ $MyFunc.NumberFormat(calculatedSl) }} บาท
                </li>
              </ul>
            </div>
            <div class="row" v-if="sl != '%'">
              <div
                class="col-12 input-row"
                :class="{ 'input-row-tp-sl': sa2i !== null && sa2i !== '' }"
              >
                <cleave
                  :options="options"
                  min="0"
                  max="14"
                  oninput="validity.valid||(value='');"
                  placeholder="0.00"
                  v-model="sa2i"
                  @blur="formatSa2i"
                  style="width: 344px; padding-right: 40px"
                ></cleave>
                <span v-if="sa2i == null || sa2i == ''"
                style="top: 20px;" >ระบุราคาที่ต้องการขายตัดขาดทุน</span
                >
                <span style="left: 310px">THB</span>
                <div class="col-12">
                <ul class="inp pt-3" style="padding-left: 10px;">
                  <li style="color: #8898a0">
                  ขายเมื่อราคา -{{this.$MyFunc.NumberFormat(find_percentstoploss(sa2i,coinCurrentValue))}}% ({{ this.$MyFunc.NumberFormat(sa2i) }} บาท)
                </li>
                <li>ขาดทุนประมาณ {{ this.$MyFunc.NumberFormat(sa2i - coinCurrentValue)}} บาท</li>
              </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          style="border-top: 1px solid #28363e; padding-top: 16px"
        >
          <div class="col-3 w-title my-auto">Sell Amount</div>
          <div class="col-9" style="padding-left: 36px">
            <div class="row sa-tabs">
              <div
                style="width: 63px"
                class="col-2 text-center sa-tab-first"
                @click="sa3 = 25"
                :class="sa3 === 25 ? 'active' : ''"
              >
                25%
              </div>
              <div
                style="width: 63px"
                class="col-2 text-center sa-tab"
                @click="sa3 = 50"
                :class="sa3 === 50 ? 'active' : ''"
              >
                50%
              </div>
              <div
                style="width: 63px"
                class="col-2 text-center sa-tab"
                @click="sa3 = 75"
                :class="sa3 === 75 ? 'active' : ''"
              >
                75%
              </div>
              <div
                style="width: 63px"
                class="col-2 text-center sa-tab"
                @click="sa3 = 100"
                :class="sa3 === 100 ? 'active' : ''"
              >
                100%
              </div>
              <div
                style="width: 90px"
                class="col-3 text-center sa-tab-last"
                @click="sa3 = ''"
                :class="sa3 === '' ? 'active' : ''"
              >
                <input
                  type="number"
                  min="0"
                  max="6"
                  @input="handlemax"
                  v-model="sa3p"
                  placeholder="ระบุ %"
                  class="sa-tab-last-input"
                />
              </div>
            </div>
          </div>
          <div class="col-3"></div>
          <div class="col-9" style="padding-left: 24px">
            <ul class="thai-font sl-ul">
              <li>
                ขายจำนวน &nbsp;
                <span style="color: #8898a0" v-if="sa3 ? sa3 : sa3p">{{ sa3 ? sa3 : sa3p }}% </span><span style="color: #8898a0" v-else>0%</span>
                เมื่อราคาเป็นไปตามเงื่อนไข
              </li>
              <li>
                {{ calculatedAllCoin }}
                {{ coinName }}
                ({{ $MyFunc.NumberFormat(calculatedTotalCoin) }} บาท)
              </li>
            </ul>
          </div>
          <button @click="tpslSubmit()" class="stpsl-submit">Submit</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import EventBus from "../../../event-bus";
export default {
  props: ["coinName", "pairName", "coinCurrentValue", "totalCoin","all_coin","amount","currentScale"],
  data() {
    return {
      tp: "thb",
      sl: "thb",
      sa1: 0,
      sa1p: null,
      sa1i: null,
      sa2: 0,
      sa2p: null,
      sa2i: null,
      sa3: 100,
      sa3p: null,
      takeProfit: false,
      stopLoss: false,
    };
  },
  computed: {
    
    percentCalculatedTp(){
      let pValue = this.sa1 ? Number(this.sa1) : Number(this.sa1p);
      let calcPercent = ((this.coinCurrentValue * (pValue+100)) / 100);
      return this.$MyFunc.mathFloorPrice(calcPercent,2);
    },
    percentCalculatedSl(){
      let pValue =  this.sa2 ? Number(this.sa2) : Number(this.sa2p);
      let calcPercent = ((this.coinCurrentValue * pValue) / 100);
      return this.$MyFunc.mathFloorPrice(parseFloat(this.coinCurrentValue - calcPercent), 2);
    },
    calculatedTp() {
      let pValue = this.sa1 ? Number(this.sa1) : Number(this.sa1p);
      let calcPercent = ((this.coinCurrentValue * (pValue + 100)) / 100);
      let total = (parseFloat(calcPercent - this.coinCurrentValue) * this.all_coin);
      return total;
    },
    calculatedSl() {
      let pValue = this.sa2 ? Number(this.sa2) : Number(this.sa2p);
      let calcPercent = ((this.coinCurrentValue * pValue) / 100);
      let balance = parseFloat(this.coinCurrentValue - calcPercent);
      let total = (parseFloat(this.coinCurrentValue - balance) * this.all_coin);
      return total;
    },
    calculatedAllCoin() {
      let pValue = this.sa3 ? Number(this.sa3) : Number(this.sa3p);
      let calcPercent = ((this.all_coin * pValue) / 100);
      let balance = parseFloat(((this.all_coin * 100) - (calcPercent * 100)) / 100);
      let coin = parseFloat(((this.all_coin * 100) - (balance * 100)) / 100);
      return  this.$MyFunc.mathFloorPrice(parseFloat(coin),8);
    },
    calculatedTotalCoin() {
      let pValue = this.sa3 ? Number(this.sa3) : Number(this.sa3p);
      let calcPercent = ((this.amount * pValue) / 100);
      let balance = parseFloat(this.amount - calcPercent);
      return  parseFloat(this.amount - balance);
    },
    options() {
      return {numeral:true,numeralIntegerScale:14,numeralDecimalScale:this.currentScale.bathScale,numeralThousandsGroupStyle:"thousand"};
    },
  },
  methods: {
    clear(){
      this.tp= "thb";
      this.sl= "thb";
      this.sa1= 0;
      this.sa1p= null;
      this.sa1i= null;
      this.sa2= 0;
      this.sa2p= null;
      this.sa2i= null;
      this.sa3= 100;
      this.sa3p= null;
      this.takeProfit= false;
      this.stopLoss= false;
      this.$emit("tpsl", {
        tp: null,
        sl: null,
        sm: null,
        tpPercentage: null,
        slPercentage: null,
        sellAmountPercentage: null,
      });
    },
    find_percentstoploss(now,price){
      let balance = price-now
      let a = (balance/now)*100
      let sum = 100
      if( a == Infinity){
        sum = 0
      } else{
        sum = a
      }
      return Math.abs(sum)
    },
    find_percenttakeprofit(now,price){
      let balance = price-now
      let a = (balance/now)*100
      let sum = 100
      if( a == Infinity){
        sum = 0
      } else{
        sum = a
      }
      return Math.abs(sum)
    },
    tpslSubmit() {
      if (this.takeProfit || this.stopLoss) {
        // Conditions for 'tp' when takeProfit is true
        if (this.takeProfit) {
          if (this.tp === "%") {
            if (
              (this.sa1 === null || this.sa1 <= 0) &&
              (this.sa1p === null || this.sa1p == 0)
            ) {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "Please select or type take profit percentage to continue",
              });
              return;
            }
          }
          if (this.tp === "thb") {
            if (this.sa1i === null || this.sa1i == 0) {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "Please enter take profit amount",
              });
              return;
            }else if(parseFloat(this.sa1i) < parseFloat(this.coinCurrentValue)){
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "Please specify the price you want to sell, which must be greater than the Price value.",
              });
              return;
            }
          }
        }

        // Conditions for 'sl' when stopLoss is true
        if (this.stopLoss) {
          if (this.sl === "%") {
            if (
              (this.sa2 === null || this.sa2 <= 0) &&
              (this.sa2p === null || this.sa2p == 0)
            ) {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "Please select or type stop loss percentage to continue",
              });
              return;
            }
          }
          if (this.sl === "thb") {
            if (this.sa2i === null || this.sa2i == 0) {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "Please enter stop loss amount",
              });
              return;
            }else if(parseFloat(this.sa2i) > parseFloat(this.coinCurrentValue)){
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: "Please specify the price you want to sell to cut losses not exceeding the price value.",
              });
              return;
            }
          }
        }

        this.$bvModal.hide("limitorder-STPSL-market");
        this.$emit("event", true);
        this.$emit("tpsl", {
          tp: this.takeProfit
            ? this.tp == "%"
            ? parseFloat(this.percentCalculatedTp)
            : parseFloat(this.sa1i)
            : null,
          sl: this.stopLoss
            ? this.sl == "%"
              ? parseFloat(this.percentCalculatedSl)
              : parseFloat(this.sa2i)
            : null,
          sm: this.calculatedTotalCoin * this.coinCurrentValue,
          tpPercentage: this.sa1? this.sa1 : parseFloat(this.sa1p),
          slPercentage: this.sa2? this.sa2 : parseFloat(this.sa2p),
          sellAmountPercentage: this.sa3? this.sa3 : parseFloat(this.sa3p),
        });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Please select at least one condition to continue",
        });
      }
    },

    resetTP() {
      this.sa1 = 0;
      this.sa1p = null;
      this.sa1i = null;
    },
    resetSL() {
      this.sa2 = 0;
      this.sa2p = null;
      this.sa2i = null;
    },
    formatSa2i() {
      if (!isNaN(this.sa2i) && Number(this.sa2i).toFixed(2) !== this.sa2i) {
        this.sa2i = Number(this.sa2i).toFixed(2);
      }
    },
    formatSa1i() {
      if (!isNaN(this.sa1i) && Number(this.sa1i).toFixed(2) !== this.sa1i) {
        this.sa1i = Number(this.sa1i).toFixed(2);
      }
    },
    handlemax() {
      if(this.sa1p){
        let sa1p = parseFloat(this.sa1p.slice(0, 6));
        if (sa1p > 100) {
          this.sa1p = 100;
        } else if (sa1p < 0) {
          this.sa1p = 0;
        }else{
          if(sa1p == 0){
            this.sa1p = '';
          } else {
            this.sa1p = sa1p;
          }
        }
      }
      if(this.sa2p){
        let sa2p = parseFloat(this.sa2p.slice(0, 6));
        if (sa2p > 100) {
          this.sa2p = 100;
        } else if (sa2p < 0) {
          this.sa2p = 0;
        }else{
          if(sa2p == 0){
            this.sa2p = '';
          } else {
            this.sa2p = sa2p;
          }
        }
      }
      if(this.sa3p){
        let sa3p = parseFloat(this.sa3p.slice(0, 6));
        if (sa3p > 100) {
          this.sa3p = 100;
        } else if (sa3p < 0) {
          this.sa3p = 0;
        }else{
          if(sa3p == 0){
            this.sa3p = '';
          } else {
            this.sa3p = sa3p;
          }
        }
      }
    },
  },
  mounted() {
    EventBus.$on('update-model-limitorder-STPSL-market', () => {
      this.clear();
    })
  },
};
</script>
<style>
#limitorder-STPSL-market___BV_modal_body_ {
  padding: 0px 11px 26px 16px !important;
  width: 560px;
}
</style>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.sl-ul {
  padding: 0px 8px !important;
  list-style: inside !important;
}
.sl-ul span {
  position: relative;
  left: -4px;
}
hr {
  margin: 16px 0px;
  background: #28363e;
}
.stop-loss-detail-modal .tab {
  color: #677f8e;
  line-height: 14px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 170px; */
}
.stop-loss-detail-modal .tab.active {
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-row {
  line-height: 24px;
  padding-bottom: 10px;
}
.stop-loss-detail-modal h5 {
  padding: 24px 0px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  padding-left: 30px;
  padding-top: 30px;
}
.sa-tab-last-input {
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
  color: white;
  text-align: center;
}
.sa-tab-last-input::placeholder {
  color: #9bacb6;
}
.inp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9bacb6;
  list-style: disc;
  position: absolute;
  top: 47px;
  left: 17px;
}
.input-row-tp-sl {
    input {
      text-align: left !important;
    }
}
</style>
