<template>
  <div>
    <b-modal
      class="qr-modal"
      id="deposit-qr-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <div v-if="!isLoading" class="qr-modal">
        <div id="html2canvas">
          <div class="modal-top pt-4">
            <p>สแกน QR Code</p>
            <p>ผ่าน Mobile Banking เพื่อฝากเงิน</p>
            <span v-if="hiddenIconQrCode" class="icon hide"
              <!-- ><svg
                width="20"
                height="70"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                  fill="#677F8E"
                />
              </svg> -->
            </span>
            <span
              v-if="hiddenIconQrCode"
              class="modal-close-button"
              @click="$bvModal.hide('deposit-qr-modal')"
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </div>
          <div style="background-color: #141b1f;">
            <div
              class="card-body row"
              style="
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  background-color: rgb(255, 255, 255);
                  border-radius: 5px;
                  width: 160px;
                  height: 160px;
                  padding-top: 8px;
                  padding-left: 8px;
                "
              >
                <VueQRCodeComponent
                  id="qrtext"
                  :text="data.qrText"
                  :size="145"
                  color="#000000"
                  bg-color="#ffffff"
                  error-level="L"
                >
                </VueQRCodeComponent>
              </div>
            </div>
            <div class="info">
              <div class="row">
                <div class="col-5 title">จำนวนเงินฝาก</div>
                <div class="col-7 n-value" style="font-family: 'Roboto Flex'">
                  {{ $MyFunc.NumberFormat(data.amount) }} THB
                </div>
                <div class="col-5 title">ผู้นำฝาก</div>
                <div class="col-7 value">{{ data.fromAccountName }}</div>
                <div class="col-5 title">ไปยัง</div>
                <div class="col-7 value">{{ data.toAccountName }}</div>
                <div class="col-5 title">เลขอ้างอิง</div>
                <div class="col-7 value">{{ data.ref2 }}</div>
                <div class="col-5 title">จากธนาคาร</div>
                <div class="col-7 value">{{ data.bankName }}</div>
                <div class="col-5 title">เลขที่บัญชี</div>
                <div class="col-7 value">{{ data.fromAccount }}</div>
              </div>
            </div>
            <div class="ul-c pb-1">
              <p>หมายเหตุ</p>
              <ul>
                <li class="c-g">
                  โอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคาร)
                </li>
                <li class="c-red">
                  QR Code นี้ใช้ได้ถึงวันที่ {{ data.expiryDate }}
                </li>
                <li class="c-red">
                  โอนจากบัญชีธนาคารนี้ที่ระบุเท่านั้น
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="f-btns">
          <button
            @click="
              $bvModal.hide('deposit-qr-modal');
              downloadImage();
            "
            class="b-yellow"
          >
            บันทึกรูป
          </button>
          <button @click="$bvModal.show('qrc-detail')" class="b-gray">
            ยกเลิกรายการ
          </button>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in 21" :key="index">
          <b-skeleton
            class="skc custom-skeleton-wave"
            animation="wave"
            width="100%"
          ></b-skeleton>
        </div>
      </div>
    </b-modal>
    <b-modal
    class="qr-modal-fail"
    id="deposit-qr-fail"
    :hide-footer="true"
    :hide-header="true"
    centered
  >
    <div v-if="!isLoading" class="qr-modal">
      <div id="html2canvas">
        <div class="modal-top pt-4">
          <p>ระบบตรวจพบข้อมูลผิดพลาด</p>
        </div>
        <div style="background-color: #141b1f;">
          <div class="info">
            <div class="row">
              <div class="col text-center">กรุณาระบุเลขที่บัญชีธนาคาร ที่มีชื่อตรงกับ</div>
            </div>
            <div class="row">
              <div class="col text-center">{{ data.fromAccountName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="f-btns">
        <button @click="$bvModal.hide('deposit-qr-fail');" class="b-yellow">
          ตกลง
        </button>
      </div>
    </div>
    <div v-else>
      <div v-for="(item, index) in 21" :key="index">
        <b-skeleton
          class="skc custom-skeleton-wave"
          animation="wave"
          width="100%"
        ></b-skeleton>
      </div>
    </div>
  </b-modal>
    <div v-if="qr_success">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div class="checkTick mt-5">
                          <img
                            class="image-danger-modal"
                            src="../../../../assets/images/wallet/greenTick.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message">
                          <p
                            class="text-grey"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 600;
                              font-size: 20px;
                              line-height: 36px;
                              color:#D6DDE1
                            "
                          >
                            บันทึก Qr Code สำเร็จ
                          </p>
                          <p
                            class="text-grey"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                            "
                          >
                            กรุณาไปยัง Mobile Banking<br>
                            ของคุณเพื่อการโอนเงิน
                          </p>
                         
                        </div>
                        <div
                          style="margin-bottom: 75px"
                          @click="qr_success = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                              background-color: #191b1c;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- deposit-qr-seccess -->
    <QrDetailModal
      @qrcDone="qrcDone"
      :data="qrId || data?.id"
      :qrFlag="qrFlag"
      @getAllCashReports="() => $emit('getAllCashReports')"
    />
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import QrDetailModal from "./QrDetailModal.vue";
import VueQRCodeComponent from "vue-qrcode-component";
export default {
  props: ["data", "qrId", "qrFlag", "isLoading"],
  components: { QrDetailModal, VueQRCodeComponent },
  data() {
    return {
      hiddenIconQrCode: true,
      qr_success:false,
    };
  },
  methods: {
    qrcDone() {
      this.$bvModal.hide("deposit-qr-modal");
    },
    downloadImage() {
      this.hiddenIconQrCode = false;
      
      setTimeout(() => {
        html2canvas(document.querySelector("#html2canvas")).then((canvas) => {
          const link = document.createElement("a");
          link.setAttribute("download", "barcode.png");
          link.setAttribute(
            "href",
            canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
          );
          link.click();
          this.qr_success = true
        });
      }, 100);
    },
  },
};
</script>
<style lang="scss">
#deposit-qr-modal___BV_modal_content_ {
  font-family: var(--bs-roboto-font-sans-serif);
  background-color: #141b1f;
  width: 480px;
  margin: auto;
  margin-top: 51px !important;
  padding-top: 1.5px;
}
#deposit-qr-fail___BV_modal_content_ {
  width: 400px;
  margin: auto;
}


.modal-close-button {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 25px;
}
.modal-top {
  background-color: #141b1f;
  padding-bottom: 18px;
  text-align: center;
  position: relative;
  span.icon {
    position: absolute;
    top: 10px;
    right: 66px;
  }
  p {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    margin-bottom: 2px;
    color: rgb(255, 255, 255);
  }
}
.qr-modal {
  font-family: "Sarabun";
  line-height: 24px;
  .barcode-image {
    height: 200px;
    width: 200px;
    margin: 24px auto 16px auto;
    display: block;
  }
  .info {
    background-color: #191b1c;
    width: 332px;
    margin: 10px auto;
    padding: 10px 22px;
    border-radius: 8px;
    .title {
      color: #9bacb6;
      margin-bottom: 5px;
    }
    .value {
      color: rgb(255, 255, 255);
      margin-bottom: 5px;
    }
    .n-value {
      font-size: 18px;
      font-weight: 600;
      color: rgb(255, 255, 255);
      margin-bottom: 5px;
    }
  }
  .ul-c {
    width: 380px;
    margin: auto;
    margin-top: 20px;
    margin-left: 54px;
    p {
      margin: 0;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    ul {
      margin: 0;
      padding-left: 22px;
      list-style: disc;
      li {
      }
      .c-red {
        color: #ff2500;
      }
      .c-g {
        color: #9bacb6;
      }
    }
  }
  .f-btns {
    text-align: center;
    margin-top: 25px;
    .b-yellow {
      background-color: #09BE8B;
      color: #0a0d10;
      font-weight: 500;
    }
    .b-gray {
      background-color: #38454d;
      color: #ffffff;
    }
    button {
      width: 160px;
      height: 36px;
      margin: 0 6px;
      border-radius: 4px;
    }
  }
}


.qr-modal-fail {
  font-family: "Sarabun";
  line-height: 24px;
  
    display: flex;
    align-items: center;
    justify-content: center;
  .barcode-image {
    height: 200px;
    width: 200px;
    margin: 24px auto 16px auto;
    display: block;
  }
  .info {
    background-color: #1c262b;
    width: 332px;
    margin: 10px auto;
    padding: 10px 22px;
    border-radius: 8px;
    .title {
      color: #9bacb6;
      margin-bottom: 5px;
    }
    .value {
      color: #d6dde1;
      margin-bottom: 5px;
    }
    .n-value {
      font-size: 18px;
      font-weight: 600;
      color: #d6dde1;
      margin-bottom: 5px;
    }
  }
  .ul-c {
    width: 380px;
    margin: auto;
    margin-top: 20px;
    margin-left: 54px;
    p {
      margin: 0;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    ul {
      margin: 0;
      padding-left: 22px;
      list-style: disc;
      li {
      }
      .c-red {
        color: #de2d40;
      }
      .c-g {
        color: #9bacb6;
      }
    }
  }
  .f-btns {
    text-align: center;
    margin-top: 25px;
    .b-yellow {
      background-color: #09BE8B;
      color: #0a0d10;
      font-weight: 500;
    }
    .b-gray {
      background-color: #38454d;
      color: #ffffff;
    }
    button {
      width: 160px;
      height: 36px;
      margin: 0 6px;
      border-radius: 4px;
    }
  }
}
</style>
