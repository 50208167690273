<template>
  <div>
    <div class="auto-mode-container">
      <div class="auto-mode-list" v-if="AddNoResultPage">
        <svg
          width="72"
          height="66"
          viewBox="0 0 72 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#566A76"
            d="M0.166688 42.1126L17.0509 24.937C17.7114 24.2637 18.6168 23.8858 19.5617 23.8858H52.4377C53.3825 23.8858 54.2879 24.2637 54.9485 24.937L71.8327 42.1126V59.6663C71.8327 62.7504 69.3175 65.2482 66.2119 65.2482H5.78681C2.68118 65.2482 0.166016 62.7504 0.166016 59.6663L0.166688 42.1126ZM5.43755 40.7095L19.1371 26.6877H52.8619L66.5614 40.7095H48.9986V43.5462C48.9986 47.0126 46.1641 49.8231 42.6735 49.8231H29.3234C25.8328 49.8231 22.9983 47.0126 22.9983 43.5462V40.7095H5.43755ZM2.96617 43.5635H20.3229C20.3229 48.4157 24.2816 52.3469 29.1675 52.3469H42.8323C47.7183 52.3469 51.6769 48.4156 51.6769 43.5635H69.0337V59.1322C69.0337 60.9739 67.5289 62.4683 65.6743 62.4683H6.32532C4.47072 62.4683 2.96594 60.9739 2.96594 59.1322L2.96617 43.5635ZM37.4042 16.8744C37.4042 17.6476 36.7744 18.2774 36.0001 18.2774C35.2259 18.2774 34.596 17.6476 34.596 16.8744V2.15307C34.596 1.37985 35.2259 0.75 36.0001 0.75C36.7744 0.75 37.4042 1.37987 37.4042 2.15307V16.8744ZM46.4938 18.1384C46.2926 18.8856 45.5184 19.3286 44.7703 19.1288C44.0224 18.929 43.5762 18.1601 43.7774 17.4129L47.5961 3.19097C47.7973 2.44382 48.5672 2.00074 49.3152 2.20055C50.0676 2.40038 50.5137 3.16925 50.3125 3.91645L46.4938 18.1384ZM28.529 17.413C28.7302 18.1601 28.284 18.929 27.5361 19.1289C26.7881 19.3287 26.0138 18.8856 25.817 18.1385L21.9939 3.91649C21.797 3.16933 22.2388 2.40043 22.9912 2.2006C23.7392 2.00077 24.5091 2.44386 24.7103 3.19101L28.529 17.413Z"
          />
        </svg>
        <p class="i-title">กรุณาเพิ่มเงื่อนไข เพื่อเริ่มใช้งาน</p>
        <button
          class="i-button"
          @click="
            AddNoResultPage = false;
            FormAutoMode = true;
            FormAutoModeEdit = false;
          "
        >
        {{ $t('addcondition') }}
        </button>
      </div>

      <div class="new-auto-mode" v-if="FormAutoMode">
        <p class="na-heading" v-if="!FormAutoModeEdit">
          {{ $t('newautomode') }}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#677F8E"
              d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
            />
          </svg>
        </p>
        <p class="na-heading" v-else>    {{ $t('editsetting') }}</p>
        <p class="na-sub-h d-inline">
          {{ $t('selectcoin') }}
        </p>
        <label class="na-cb float-end">
          <input
            type="checkbox"
            @change="FnHiddenMinCoin"
            v-model="ChkHiddenMinCoin"
            class="form-check-input"
          />
          <span>     {{ $t('hidecoin') }}</span>
        </label>
        <div class="coin-box">
          <div class="row">
            <div v-for="(value, index) in coinData" :key="index" class="col-3">
              <label class="na-cb">
                <input
                  type="checkbox"
                  v-model="checkBoxCoin"
                  :value="value.coin.name"
                  class="form-check-input"
                />
                <span>{{ value.coin.name }}</span>
              </label>
            </div>
          </div>
        </div>

        <p class="na-sub-h">
          {{ $t('settakeprofit') }}<span
            >(%{{ $t('settakeprofit2') }})</span
          >
        </p>
        <div>
          <div class="row" style="margin-top: 12px">
            <div class="col-4 d-flex align-items-center" style="width: 114px">
              <label class="na-cb">
                <input
                  type="checkbox"
                  v-model="takeProfitCheckBox"
                  class="form-check-input"
                />
                <span>{{ $t('takeprofit') }}</span>
              </label>
            </div>
            <div class="col-8 p-boxes">
              <div
                class="p1"
                :class="takeProfit == 10 ? 'active' : ''"
                @click="inputTakeProfit(10)"
              >
                +10%
              </div>
              <div
                class="p2"
                :class="takeProfit == 20 ? 'active' : ''"
                @click="inputTakeProfit(20)"
              >
                +20%
              </div>
              <div
                class="p3"
                :class="takeProfit == 30 ? 'active' : ''"
                @click="inputTakeProfit(30)"
              >
                +30%
              </div>
              <div
                class="p4"
                :class="takeProfitChkKeyIn ? 'active' : ''"
                @click="takeProfitChkKeyIn = true"
              >
                <input
                  type="text"
                  v-model="takeProfitKeyIn"
                  :placeholder="!takeProfitChkKeyIn ? 'ระบุ %' : ''"
                  @keyup="inputTakeProfit"
                  @blur="chkTakeProfit"
                />
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 12px">
            <div class="col-4 d-flex align-items-center" style="width: 114px">
              <label class="na-cb">
                <input
                  type="checkbox"
                  v-model="stopLossCheckBox"
                  class="form-check-input"
                />
                <span>{{ $t('stoploss') }}</span>
              </label>
            </div>
            <div class="col-8 p-boxes">
              <div
                class="p1"
                :class="stopLoss == 10 ? 'active' : ''"
                @click="inputStopLoss(10)"
              >
                -10%
              </div>
              <div
                class="p2"
                :class="stopLoss == 20 ? 'active' : ''"
                @click="inputStopLoss(20)"
              >
                -20%
              </div>
              <div
                class="p3"
                :class="stopLoss == 30 ? 'active' : ''"
                @click="inputStopLoss(30)"
              >
                -30%
              </div>
              <div
                class="p4"
                :class="stopLossChkKeyIn ? 'active' : ''"
                @click="stopLossChkKeyIn = true"
              >
                <input
                  type="text"
                  v-model="stopLossKeyIn"
                  :placeholder="!stopLossChkKeyIn ? 'ระบุ %' : ''"
                  @keyup="inputStopLoss"
                  @blur="chkStopLoss"
                />
              </div>
            </div>
          </div>
        </div>
        <p class="na-sub-h" style="margin-top: 25px">{{ $t('wantsell') }}</p>
        <div class="row" style="margin-top: 15px; margin-bottom: 48px">
          <div class="col-4 d-flex align-items-center" style="width: 114px">
            <span class="sell-t">{{ $t('sellamount') }}</span>
          </div>
          <div class="col-8 p-boxes">
            <div
              class="p1"
              :class="sellAmount == 25 ? 'active' : ''"
              @click="inputSellAmount(25)"
            >
              25%
            </div>
            <div
              class="p2"
              :class="sellAmount == 50 ? 'active' : ''"
              @click="inputSellAmount(50)"
            >
              50%
            </div>
            <div
              class="p3"
              :class="sellAmount == 75 ? 'active' : ''"
              @click="inputSellAmount(75)"
            >
              75%
            </div>
            <div
              class="p3"
              :class="sellAmount == 100 ? 'active' : ''"
              @click="inputSellAmount(100)"
            >
              100%
            </div>
            <div
              class="p5"
              :class="sellAmountChkKeyIn ? 'active' : ''"
              @click="sellAmountChkKeyIn = true"
            >
              <input
                type="text"
                v-model="sellAmountKeyIn"
                :placeholder="!sellAmountChkKeyIn ? 'ระบุ %' : ''"
                @keyup="inputSellAmount"
                @blur="chkSellAmount"
              />
            </div>
          </div>
        </div>
        <div class="na-f">
          <button
            class="b-l"
            @click="autoModeSave"
            v-text="!FormAutoModeEdit ? 'บันทึก' : 'บันทึกการแก้ไข'"
          ></button>
          <button
            class="b-r"
            @click="BtnCancel"
            v-text="!FormAutoModeEdit ? 'ยกเลิก' : 'ลบเงื่อนไขนี้'"
          ></button>
          <button
            v-if="FormAutoMode && FormAutoModeEdit"
            class="b-r"
            @click="BtnBack"
          >
            ย้อนกลับ
          </button>
        </div>
      </div>
      <div class="condition-list" v-if="conditionList">
        <p class="amt">Auto Mode</p>

        <div
          v-for="(item, index) in automodeList"
          :key="index"
          class="condition-c"
        >
          <span class="arrow" @click="setEdit(item)">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <p class="coin-name">
            <span v-for="(item2, index2) in item.coinunit" :key="index2"
              >{{ item2 }}{{ item.coinunit.length != index2 + 1 ? "," : "" }}
            </span>
          </p>
          <div class="float-end" style="margin-top: -23px">
            <toggle-button
            :value="item.active"
            @change="changestatus(item, $event)" 
            :color="{ checked: '#40994F' }"
            :height="24"
            :width="55"
            class="mb-0"
            :labels="{ checked: 'ON', unchecked: 'OFF' }"
          />
          </div>
          <div class="d-inline">
            <span class="at">Take profit:</span>
            <span class="av">+{{ item.takeprofit }}%</span>
          </div>
          <div class="d-inline">
            <span class="at">Amount:</span>
            <span class="av">{{ item.sell }}%</span>
          </div>
          <div>
            <span class="at">Stop Loss:</span>
            <span class="av">-{{ item.stoploss }}%</span>
          </div>
        </div>

        <div class="amc-f">
          <button
            @click="
              conditionList = false;
              FormAutoMode = true;
              FormAutoModeEdit = false;
            "
          >
            เพิ่มเหรียญ และตั้งเงื่อนไขใหม่
          </button>
        </div>
      </div>
      <!-- <div class="auto-right">
        <div class="fd">
          <p>Auto Mode</p>
          <p style="padding-right: 30px">
            คุณสามารถตั้ง Take Profit และ Stop Loss เพื่อให้ระบบทำการเฝ้า Order
            อัตโนมัติยาม ตลาดผันผวน
          </p>
          <ul>
            <li>
              เปิดการใช้งาน <br />เลือกเหรียญ และกำหนดเงื่อนไขตามขั้นตอน
              เพื่อเปิดใช้งาน
            </li>
            <li>
              ปิดการใช้งาน <br />เลือกเปิดการตั้ง Auto Mode ได้ตามต้องการ
              ที่หน้า Portfolio โดยเข้าไปที่แถบ Auto Mode ด้านล่าง
            </li>
          </ul>
        </div>
        <div>
          <p style="color: #677f8e">คู่มือแนะนำการทำรายการฝาก</p>
          <ul>
            <li>
              <a
                href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
                target="_blank"
                style="
                  width: 107px;
                  height: 24px;
                  margin-right: 16px;
                  line-height: 19px;
                  color: #677f8e;
                  text-decoration: none;
                "
                >คู่มือแนะนำการฝาก แบบ QR Code</a
              >
            </li>
            <li>
              <a
                href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-Bank-Transfer.pdf"
                target="_blank"
                style="
                  width: 107px;
                  height: 24px;
                  margin-right: 16px;
                  line-height: 19px;
                  color: #677f8e;
                  text-decoration: none;
                "
                >คู่มือแนะนำการฝาก แบบ Bank Transfer</a
              >
            </li>
            <li>
              <a
                href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
                target="_blank"
                style="
                  width: 107px;
                  height: 24px;
                  margin-right: 16px;
                  line-height: 19px;
                  color: #677f8e;
                  text-decoration: none;
                "
                >คู่มือแนะนำการยกเลิกฝาก แบบ QR Code</a
              >
            </li>
          </ul>
        </div>
      </div> -->
      <b-modal
        class="custom-modal-width"
        id="automode-detail"
        style="margin-top: 100px"
        :hide-footer="true"
        :hide-header="true"
      >
        <div class="row">
          <div class="col-12 modal-th">
            <h5>
              Auto Mode
              <span class="cursor-pointer" style="right: 60px">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <!-- <path
                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                  fill="#677F8E"
                /> -->
                </svg>
              </span>
              <span
                @click="$bvModal.hide('automode-detail')"
                style="position: absolute; right: 30px; cursor: pointer"
                ><svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
            </h5>
          </div>
        </div>

        <div class="row body thai-font">
          <div class="col-md-12" style="padding: 0 16px">
            <p style="margin-bottom: 18px; color: #d6dde1">
              เพื่อให้คุณเทรด Crypto ได้สบายใจ แม้ไม่ได้อยู่เฝ้าจอ <br />
              ให้เราช่วยคุณตั้งขายกำไร และตัดขาดทุนอัตโนมัติ<br />
              ไว้ล่วงหน้า
            </p>
            <a
              @click="to_link_video('pdf')"
              href="javascript:void(0);"
              class="d-inline"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                color: #09BE8B;
                line-height: 24px;
              "
            >
              อ่านคู่มือการใช้งาน
            </a>
            <p
              class="d-inline"
              style="
                width: 24;
                height: 24px;
                margin-right: 16px;
                line-height: 24px;
                color: #677f8e;
              "
            >
              หรือ
            </p>
            <a
              @click="to_link_video('video')"
              href="javascript:void(0);"
              class="d-inline"
              style="
                width: 99px;
                height: 24px;
                margin-right: 16px;
                color: #09BE8B;
                line-height: 24px;
              "
            >
              ดูวิดีโอสอนใช้งาน
            </a>

            <div
              class="form-group form-check"
              style="margin-top: 24px !important"
            >
              <label class="checkbox-input m-0">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="notnoti"
                />
                <span style="line-height: 24px; color: #9bacb6"
                  >ไม่ต้องแจ้งเตือนอีก</span
                ></label
              >
            </div>

            <button
              @click="$bvModal.hide('automode-detail')"
              type="button"
              class="tpsl-btn text-center"
            >
              เริ่มต้นใช้งาน
            </button>
          </div>
        </div>
      </b-modal>
    </div>
    <AutoModeConfirm
      :coinDataSelect="coinDataSelect"
      :FormAutoModeEdit="FormAutoModeEdit"
      :FormAutoModeDelete="FormAutoModeDelete"
      @showList="showList"
      @ClearAll="ClearAll"
    />
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import { mapMutations } from "vuex";
import AutoModeConfirm from "./AutoModeConfirm";

export default {
  components: { AutoModeConfirm },
  data() {
    return {
      notnoti: false,
      AddNoResultPage: true,
      FormAutoMode: false,
      FormAutoModeEdit: false,
      FormAutoModeDelete: false,
      conditionList: false,
      idRowsCoin: [],
      checkBoxCoin: [],
      takeProfit: 10,
      takeProfitAmount: 0,
      takeProfitKeyIn: "",
      takeProfitChkKeyIn: false,
      takeProfitCheckBox: false,
      stopLoss: 10,
      stopLossAmount: 0,
      stopLossKeyIn: "",
      stopLossChkKeyIn: false,
      stopLossCheckBox: false,
      sellAmount: 25,
      sellSumAmount: 0,
      sellAmountKeyIn: "",
      sellAmountChkKeyIn: false,
      sellAmountBalance: 0,
      ChkHiddenMinCoin: this.$store.state.ChkHiddenMinCoin,
      memberId: 0,
      groupId: "",
      coinData: [],
      coinDataSelect: [],
      automodeList: [],
      delaySave: false,
    };
  },
  metaInfo() {
    let pageTitle = "Automode";
    return {
      title: pageTitle,
      titleTemplate: "%s | " + this.TITLE,
    };
  },
  methods: {
    ClearAll() {
      this.$bvModal.hide("auto-mode-confirm");
      this.conditionList = true;
      this.FormAutoMode = false;
      this.FormAutoModeEdit = false;
      this.FormAutoModeDelete = false;
      this.takeProfit = 10;
      this.stopLoss = 10;
      this.sellAmount = 25;
      this.takeProfitCheckBox = false;
      this.stopLossCheckBox = false;
      this.takeProfitKeyIn = "";
      this.stopLossKeyIn = "";
      this.idRowsCoin = [];
      this.checkBoxCoin = [];
      this.coinDataSelect = [];
    },
    BtnCancel() {
      if (this.FormAutoMode && this.FormAutoModeEdit) {
        this.FormAutoModeDelete = true;
        this.autoModeSave();
      } else {
        this.ClearAll();
        if (this.automodeList.length == 0) {
          this.conditionList = false;
          this.AddNoResultPage = true;
        }
      }
    },
    BtnBack() {
      this.ClearAll();
    },
    setEdit(item) {
      this.conditionList = false;
      this.FormAutoMode = true;
      this.FormAutoModeEdit = true;
      this.idRowsCoin = [];
      this.checkBoxCoin = [];
      item.id.map((aa) => {
        this.idRowsCoin.push(aa);
      });
      item.coinunit.map((aa) => {
        this.checkBoxCoin.push(aa);
      });
      this.groupId = item.groupId;
      if (item.takeprofitStatus) {
        this.takeProfitCheckBox = true;
        this.takeProfit = item.takeprofit;
        if (
          item.takeprofit != 10 ||
          item.takeprofit != 20 ||
          item.takeprofit != 30
        ) {
          this.takeProfitKeyIn = "+" + item.takeprofit + "%";
          this.takeProfitChkKeyIn = true;
        }
      }
      if (item.stoplossStatus) {
        this.stopLossCheckBox = true;
        this.stopLoss = item.stoploss;
        if (item.stoploss != 10 || item.stoploss != 20 || item.stoploss != 30) {
          this.stopLossKeyIn = "-" + item.stoploss + "%";
          this.stopLossChkKeyIn = true;
        }
      }
      this.sellAmount = item.sell;
      if (
        item.sell != 25 ||
        item.sell != 50 ||
        item.sell != 75 ||
        item.sell != 100
      ) {
        this.sellAmountKeyIn = item.sell + "%";
        this.sellAmountChkKeyIn = true;
      }
    },
    autoModeSave() {
      let chkForm = true;
      if (this.checkBoxCoin.length == 0) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "กรุณาเลือกเหรียญที่ต้องการใช้งาน Auto Mode",
        });
        chkForm = false;
      }
      if (chkForm) {
        if (!this.takeProfitCheckBox && !this.stopLossCheckBox) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "กรุณาเลือก Take Profit หรือ Stop Loss อย่างน้อย 1 รายการ",
          });
          chkForm = false;
        }
        if (this.takeProfitCheckBox && this.takeProfit == 0) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "กรุณากรอก % Take Profit หรือเลือกอย่างน้อย 1 รายการ",
          });
          chkForm = false;
        }
        if (this.stopLossCheckBox && this.stopLoss == 0) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "กรุณากรอก % Stop Loss หรือเลือกอย่างน้อย 1 รายการ",
          });
          chkForm = false;
        }
      }
      if (chkForm) {
        if (this.sellAmount == 0) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "กรุณาเลือก % {{ $t('quwanttosell') }}",
          });
          chkForm = false;
        }
      }
      if (chkForm) {
        this.coinDataSelect = [];
        if (!this.FormAutoModeEdit) {
          const d = new Date();
          this.groupId =
            d.getDate().toString() +
            d.getHours().toString() +
            d.getMinutes().toString() +
            d.getSeconds().toString();
        }
        this.checkBoxCoin.map((item1, index1) => {
          this.coinData.map((item2, index2) => {
            if (item1 == item2.coin.name) {
              let takeProfitAmount =
                item2.averagePrice +
                (item2.averagePrice * this.takeProfit) / 100;
              let stopLossAmount =
                item2.averagePrice - (item2.averagePrice * this.stopLoss) / 100;
              let sellAmount = (item2.balance * this.sellAmount) / 100;
              let data = {};
              data.id = this.idRowsCoin[index1];
              data.memberId = this.memberId;
              data.groupId = this.groupId;
              data.coinName = item2.coin.name;
              data.coinunit = item2.coin.unit;
              data.coinAveragePrice = item2.averagePrice;
              data.sell = this.sellAmount;
              data.sellamount = sellAmount;
              data.sellCoinBalance = item2.balance;
              if (this.takeProfitCheckBox) {
                data.takeprofit = this.takeProfit;
                data.takeprofitNet = takeProfitAmount - item2.averagePrice;
                data.takeprofitPrice = takeProfitAmount;
                data.takeprofitCoinAmount =
                  (takeProfitAmount * item2.balance) / item2.averagePrice;
              } else {
                data.takeprofit = 0;
                data.takeprofitNet = 0;
                data.takeprofitPrice = 0;
                data.takeprofitCoinAmount = 0;
              }
              if (this.stopLossCheckBox) {
                data.stoploss = this.stopLoss;
                data.stoplossNet = item2.averagePrice - stopLossAmount;
                data.stoplossPrice = stopLossAmount;
                data.stoplossCoinAmount =
                  (stopLossAmount * item2.balance) / item2.averagePrice;
              } else {
                data.stoploss = 0;
                data.stoplossNet = 0;
                data.stoplossPrice = 0;
                data.stoplossCoinAmount = 0;
              }
              this.coinDataSelect.push(data);
            }
          });
        });

        this.$bvModal.show("auto-mode-confirm");
        this.FormAutoMode = false;
        this.conditionList = false;
      }
    },
    to_link_video(value) {
      if (value == "pdf") {
        var url_pdf =
          "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Aug/FinansiaDA_%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%80%e0%b8%97%e0%b8%a3%e0%b8%94_Auto_Mode.pdf";
        window.open(url_pdf, "_blank");
      }
      if (value == "video") {
        // var url_video =
        //   "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Video/2024/Mar/FDA-Tutorial-VDO-Market-Order_PC.mp4";
        // window.open(url_video, "_blank");
      }
    },
    showList() {
      if (!this.delaySave) {
        this.delaySave = true;
        let self = this;
        let url_add_edit = "/uc/auto-mode/add";
        if (this.FormAutoModeDelete) {
          url_add_edit = "/uc/auto-mode/delete";
        } else if (this.FormAutoModeEdit) {
          url_add_edit = "/uc/auto-mode/edit";
        }
        HTTP.post(url_add_edit, this.coinDataSelect)
          .then((res) => {
            self.ClearAll();
            self.FcAutoMode();
            setTimeout(function () {
              self.delaySave = false;
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setTimeout(function () {
              if (self.FormAutoMode && !self.conditionList) {
                self.ClearAll();
              }
            }, 5000);
          });
      }
    },
    inputTakeProfit(e) {
      if (e.target?.value) {
        this.takeProfit = parseInt(e.target.value.replace(/[^0-9]/g, ""));
        this.takeProfitKeyIn = this.takeProfit;
        this.takeProfitChkKeyIn = true;
      } else {
        this.takeProfit = e;
        this.takeProfitKeyIn = "";
        this.takeProfitChkKeyIn = false;
      }
      this.takeProfitCheckBox = true;
    },
    chkTakeProfit() {
      if (!this.takeProfitChkKeyIn) {
        this.takeProfitKeyIn = "";
      } else {
        if (this.takeProfit == 0) {
          this.takeProfitKeyIn = "+0%";
          this.takeProfitCheckBox = false;
        } else {
          this.takeProfitKeyIn = "+" + this.takeProfit + "%";
        }
      }
    },
    inputStopLoss(e) {
      if (e.target?.value) {
        this.stopLoss = parseInt(e.target.value.replace(/[^0-9]/g, ""));
        if (this.stopLoss > 100) {
          this.stopLoss = 100;
          this.stopLossKeyIn = 100;
        } else {
          this.stopLossKeyIn = this.stopLoss;
        }
        this.stopLossChkKeyIn = true;
      } else {
        this.stopLoss = e;
        this.stopLossKeyIn = "";
        this.stopLossChkKeyIn = false;
      }
      this.stopLossCheckBox = true;
    },
    chkStopLoss() {
      if (!this.stopLossChkKeyIn) {
        this.stopLossKeyIn = "";
      } else {
        if (this.stopLoss == 0) {
          this.stopLossKeyIn = "-0%";
          this.stopLossCheckBox = false;
        } else {
          this.stopLossKeyIn = "-" + this.stopLoss + "%";
        }
      }
    },
    inputSellAmount(e) {
      if (e.target?.value) {
        this.sellAmount = parseInt(e.target.value.replace(/[^0-9]/g, ""));
        if (this.sellAmount > 100) {
          this.sellAmount = 100;
          this.sellAmountKeyIn = 100;
        } else {
          this.sellAmountKeyIn = this.sellAmount;
        }
        this.sellAmountChkKeyIn = true;
      } else {
        this.sellAmount = e;
        this.sellAmountKeyIn = "";
        this.sellAmountChkKeyIn = false;
      }
    },
    chkSellAmount() {
      if (!this.sellAmountChkKeyIn) {
        this.sellAmountKeyIn = "0%";
      } else {
        if (this.sellAmount == 0) {
          this.sellAmountKeyIn = "0%";
        } else {
          this.sellAmountKeyIn = this.sellAmount + "%";
        }
      }
    },
    changestatus(item,active){
      HTTP.get("/uc/auto-mode/set-group-status?groupId="+item.groupId+"&status="+active.value).then((res) => {
        if(res.data.code == 0){
          // this.FnCoinWallet();
          // this.FcAutoMode();
        }else{
          // alert("not found")
        }
      });
    },
    FnCoinWallet() {
      this.checkBoxCoin = [];
      HTTP.post("/uc/auto-mode/coin-lists").then((res) => {
        let data = [];
        res.data.data.map((item) => {
          if (item.coin.name != "THB") {
            if (
              item.actual > 0 ||
              item.total > 0 ||
              item.frozenBalance > 0 ||
              item.realized != 0 ||
              item.balance != 0
            ) {
              if (
                this.$MyFunc.FnChkHiddenMinCoin(item, this.ChkHiddenMinCoin)
              ) {
                data.push(item);
              }
            }
          } else {
            this.sellAmountBalance = item.balance;
          }
        });
        this.coinData = data;
      });
    },
    FcAutoMode() {
      this.automodeList = [];
      HTTP.post("/uc/auto-mode/get").then((res) => {
        if (res.data.data.length > 0) {
          let nowList = [];
          res.data.data.map((item, index) => {
            let chk = true;
            nowList.map((item2) => {
              if (item.groupId == item2) {
                chk = false;
              }
            });
            if (chk) {
              nowList.push(item.groupId);
              this.automodeList.push({
                groupId: item.groupId,
                active: item.active,
                coinAveragePrice: item.coinAveragePrice,
                coinunit: [item.coinunit],
                id: [item.id],
                sell: item.sell,
                sellamount: item.sellamount,
                stoploss: item.stoploss,
                stoplossPrice: item.stoplossPrice,
                stoplossStatus: item.stoplossStatus,
                takeprofit: item.takeprofit,
                takeprofitPrice: item.takeprofitPrice,
                takeprofitStatus: item.takeprofitStatus,
              });
            } else {
              this.automodeList.map((item2) => {
                if (item.groupId == item2.groupId) {
                  item2.id.push(item.id);
                  item2.coinunit.push(item.coinunit);
                }
              });
            }
          });
          this.automodeList.reverse();
          //console.log(this.automodeList);
          this.AddNoResultPage = false;
          this.conditionList = true;
        } else {
          this.AddNoResultPage = true;
          this.FormAutoMode = false;
          this.conditionList = false;
        }
      });
    },
    FnHiddenMinCoin() {
      this.setHiddenMinCoin(this.ChkHiddenMinCoin);
      this.FnCoinWallet();
    },
    ...mapMutations(["setHiddenMinCoin"]),
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (user_data?.id) {
      this.memberId = user_data.id;
      this.FnCoinWallet();
      this.FcAutoMode();
    }
  },
  mounted() {
    if (localStorage.getItem("automode-detail") == "false") {
      this.$bvModal.show("automode-detail");
    }
  },
};
</script>
<style lang="scss">
.custom-modal-width .modal-dialog {
  max-width: 350px;
}
.modal-dialog {
  // background-color: #141b1f;
  width: 350px;
  height: 305px;
  border-radius: 8px;
}
.auto-mode-container {
  width: 616px;
  min-height: 500px;
  margin: auto;
  margin-top: 32px;
  background-color: #121516;
  border-radius: 8px;
  position: relative;
  left: -186px;
  .auto-mode-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-top: 100px;
    .i-title {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-top: 51px;
      text-align: center;
      color: #9bacb6;
    }
    .i-button {
      width: 240px;
      height: 40px;
      background: #09BE8B;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0a0d10;
      margin-top: 22px;
    }
  }
  .new-auto-mode {
    padding: 24px;
    .na-heading {
      font-family: "Noto Sans Thai";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #d6dde1;
      border-bottom: 1px solid #28363e;
      padding-bottom: 11px;
      svg {
        margin-left: 5px;
      }
    }
    .na-del {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #09BE8B;
    }
    .na-sub-h {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #d6dde1;
      margin-bottom: 0;
      span {
        color: #677f8e;
      }
    }
    .sell-t {
      display: inline-block;
      margin-left: 8px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #9bacb6;
    }
    .na-cb {
      margin-bottom: 13px;
      input {
        background: #191b1c;
        border: 1px solid #3c515d;
        border-radius: 2px;
        height: 17px;
        width: 17px;
      }
      input:checked {
        background-color: #09BE8B;
        border: 1px solid #3c515d;
      }
      span {
        display: inline-block;
        margin-left: 8px;
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
      }
    }
    .coin-box {
      border: 1px solid #28363e;
      border-radius: 4px;
      width: 568px;
      min-height: 128px;
      margin-top: 9px;
      margin-bottom: 32px;
      padding: 15px;
    }
    .p-boxes {
      div {
        background-color: #191b1c;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
        height: 40px;
        display: inline-block;
      }
      div.p1 {
        border-radius: 4px 0px 0px 4px;
      }
      div.p4 {
        border-radius: 0px 4px 4px 0px;
        width: 170px !important;
        input {
          width: 100%;
          height: 100%;
          color: #9bacb6;
          text-align: center;
          &::placeholder {
            color: #9bacb6;
          }
          &:focus {
            outline: none;
          }
        }
      }
      div.p5 {
        border-radius: 0px 4px 4px 0px;
        width: 107px !important;
        input {
          width: 100%;
          height: 100%;
          color: #9bacb6;
          text-align: center;
          &::placeholder {
            color: #9bacb6;
          }
          &:focus {
            outline: none;
          }
        }
      }
      div.p1,
      div.p2,
      div.p3,
      div.p4,
      div.p5 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        cursor: pointer;
        margin-right: 1px;
      }
      div.active {
        background: #38454d;
        color: #ffffff;
      }
    }
    .na-f {
      display: flex;
      justify-content: center;
      button {
        margin-left: 16px;
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-family: "Sarabun";
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
      }
      .b-l {
        background-color: #09BE8B;
        font-weight: 500;
      }
      .b-r {
        background-color: #191b1c;
        font-weight: 400;
        color: white;
      }
    }
  }
  .condition-list {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 30px;

    .condition-c {
      position: relative;
      border-bottom: 1px solid #28363e;
      padding-bottom: 11px;
      .arrow {
        position: absolute;
        right: 0;
        top: 50px;
        cursor: pointer;
      }
    }
    .amt {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: #d6dde1;
      border-bottom: 1px solid #28363e;
      padding-bottom: 12px;
    }
    .coin-name {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #d6dde1;
      margin-top: 14px;
      margin-bottom: 14px;
    }
    .at {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #677f8e;
    }
    .av {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #d6dde1;
      margin-left: 14px;
      margin-right: 27px;
    }
    .amc-f {
      text-align: center;
      padding: 20px 0;
      button {
        width: 204px;
        height: 40px;
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #0a0d10;
        background-color: #09BE8B;
        border-radius: 4px;
      }
    }
  }
  .auto-right {
    position: absolute;
    top: 0;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #677f8e;
    right: -320px;
    width: 285px;
    div.fd {
      border-bottom: 1px solid #28363e;
      padding-bottom: 8px;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 5px;
    }
    ul {
      list-style: disc;
      position: relative;
      left: -5px;
    }
  }
}
#automode-detail___BV_modal_content_ {
  margin: 150px auto 0 auto;
  width: 360px;
}
@media only screen and (max-width: 1050px) {
  .auto-right {
    display: none;
  }
  .auto-mode-container {
    left: 0;
  }
}
</style>
