<template>
  <div>
    <b-modal
      class="auto-confirm"
      id="auto-mode-confirm"
      :hide-footer="true"
      :hide-header="true"
    >
      <div>
        <div>
          <p class="amc-title">ยืนยันเปิดใช้งาน Auto Mode</p>
          <span class="amc-close" @click="$emit('ClearAll')">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>

        <div class="row">
          <div class="col-4"></div>
          <div class="col-4 text-right"><p class="s-value" style="margin-bottom: 0;">Take Profit</p></div>
          <div class="col-4 text-right"><p class="s-value" style="margin-bottom: 0;">Stop Loss</p></div>
        </div>

        <div v-for="(item , index) in coinDataSelect" :key="index" class="row amc-settings">
          <div class="col-4">
            <p class="s-percentage">{{ item.coinName }}</p>
            <p class="s-title">ขายเมื่อ</p>
            <p class="s-title">กำไร/ขาดทุน</p>
            <p class="s-title">จำนวน</p>
          </div>
          <div class="col-4 text-right">
            <p class="s-percentage">+{{ item.takeprofit }}%</p>
            <p class="s-value">{{ $MyFunc.NumberFormat(item.takeprofitPrice,2) }}</p>
            <p class="s-value">{{ $MyFunc.NumberFormat(item.takeprofitNet,2) }}</p>
            <p v-if="item.takeprofit" class="s-value">{{ $MyFunc.NumberFormat(item.sellamount,8) }} {{ item.coinUnit }} ({{ item.sell }}%)</p>
            <p v-else class="s-value">0.00 {{ item.coinUnit }} (0%)</p>
          </div>
          <div class="col-4 text-right">
            <p class="s-percentage">-{{ item.stoploss }}%</p>
            <p class="s-value">{{ $MyFunc.NumberFormat(item.stoplossPrice,2) }}</p>
            <p class="s-value">{{ $MyFunc.NumberFormat(item.stoplossNet,2) }}</p>
            <p v-if="item.stoploss" class="s-value">{{ $MyFunc.NumberFormat(item.sellamount,8) }} {{ item.coinUnit }} ({{ item.sell }}%)</p>
            <p v-else class="s-value">0.00 {{ item.coinUnit }} (0%)</p>
          </div>
        </div>

        <div class="text-center">
          <button v-if="FormAutoModeDelete" class="amc-confirm-btn" @click="$emit('showList')">
            ยืนยันการลบ
          </button>
          <button v-else class="amc-confirm-btn" @click="$emit('showList')">
            {{ FormAutoModeEdit ? 'บันทึกการแก้ใข' : 'เปิดใช้งาน Auto Mode' }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "AutoModeConfirm",
  props: [
    "coinDataSelect","FormAutoModeEdit","FormAutoModeDelete"
  ]
};
</script>
<style lang="scss">
#auto-mode-confirm___BV_modal_content_ {
  width: 550px;
  padding: 0 8px;
}
#auto-mode-confirm___BV_modal_body_ {
  .amc-title {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #d6dde1;
    padding-top: 8px;
  }
  .amc-close {
    position: absolute;
    top: 26px;
    right: 26px;
    right: 21px;
    cursor: pointer;
  }
  .amc-settings {
    margin-top: 15px;
    border-bottom: 1px solid #3f525f;
    padding-bottom: 10px;
    p {
      margin-bottom: 0;
    }

    .s-heading {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #677f8e;
      margin-bottom: 16px;
    }
    .s-title {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #677f8e;
    }
    .s-percentage {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #d6dde1;
      margin-bottom: 4px;
    }
    .s-value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #9bacb6;
    }
  }
  .amc-confirm-btn {
    width: 161px;
    height: 40px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a0d10;
    background: #09BE8B;
    border-radius: 4px;
    margin-top: 24px;
  }
}
</style>
