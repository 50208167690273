export default {
    message: {
      hello: 'Hello',
      welcome: 'Welcome to our website!'
    },
    header:{
        home:"home",
        market:"Market",
        scan:"Scan",
        buysell:"Buy/Sell",
        chart:"Chart",
        portfolio:"Portfolio",
        depositwithdraw:"Deposit/Withdraw",
        download:"Download",
        favorite:"Favorite",
        allcoins:"All Coins",
        cryptosignals:"Crypto Signals",
        cryptobubbles:"Crypto Bubbles",
        heatmap:"Heatmap",
        topgainloss:"Top Gain/Loss",
        news:"News",
        coindetail:"Coin Detail",
        myportfolio:"My Portfolio",
        automode:"Auto Mode",
        depositwithdrawreport:"Deposit/Withdraw Report",
        history:"History",
        deposit:"Deposit",
        withdraw:"Withdraw"
    },
    announcement:"Announcement",
    allcoin:"All Coins",
    all:"All",
    topgain:"Top Gain",
    toploss:"Top Loss",
    addfavorite:"Add Favorite",
    addpricealert:"Add Price Alert",
    partperfomance:"Past Performance",
    last7day:"Last 7 days",
    last30day:"Last 30 days",
    last90day:"Last 90 days",
    marketstatus:"Market Status",
    rankbymarketcap:"Rank by Market Cap",
    marketcapusd:"Market Cap (USD)",
    totalsupply:"Total Supply ()",
    maxsupply:"Max Supply",
    website:"website",
    trendsignal:"Trend Signal",
    price:"Price",
    volume:"Volume",
    searchlast:"Search Last",
    notipassword: "Password Notification",
    processed:"Processed",
    notprocessed:"Not Processed",
    notipasswordtext: "It is time to change your password. Please change your password for security.",
    kyctext: "Your KYC information review has not been approved. Please complete the KYC information review before the date ",
    kyctext1: "Please complete the KYC information review before the date ",
    kyctext2: "If the deadline is missed, you will not be able to make any purchase transactions.",
    myportfoliovalue:"My Portfolio Value",
    coin:"Coin",
    cash:"Cash",
    plun:"P/L Unrealized (Est.)",
    plre:"P/L Realized (Today)",
    myport:"My Port",
    coinheatmap:"Coin Heatmap",
    assetallocation:"Asset Allocation",
    hidecoin:"Hide small value coins",
    addcondition:"Please add conditions. to start using",
    selectcoin:"Select the coin in the Portfolio that you want to use Auto Mode ",
    newautomode:"Create new Auto Mode conditions",
    editsetting:"Edit settings",
    settakeprofit:"Set the desired Take Profit / Stop Loss values.",
    settakeprofit2:"Calculated from average cost",
    wantsell:"Quantity you want to sell",
    sellamount:"Sell Amount",
    takeprofit:"Take Profit",
    stoploss:"Stop Loss",
    depositcash:"Deposit Cash",
    depositcoin:"Deposit Coin",
    choicedeposit:"Choose a deposit method",
    depositamount:"Amount to be deposited",
    bank:"Bank",
    choicebank: "Choice Bank",
    accountbank:"Account Bank",
    depositterm:"Accept the deposit terms.",
    depositterm1:"You must use the bank account specified above to transfer money only.",
    depositterm2:"Funds can be transferred 24 hours a day (depending on the originating bank).",
    depositterm3:"QR Code is valid for no more than 24 hours.",
    allterm:"Accept all terms",
    confirmdeposit:"Deposit confirmation",
    accountinput:"Specify account number",
    choicecoindeposit:"Select the coin you want to deposit.",
    choicecoin:"Choice Coin",
    withdrawcash:"Withdraw Cash",
    withdrawcoin:"Withdraw Coin",
    amountwithdraw:"Amount to withdraw",
    bankmoney:"Bank account receiving money",
    samarywithdraw:"Summary of withdrawal list",
    samarywithdraw1:"Amount to withdraw",
    samarywithdraw2:"Withdrawal fees",
    samarywithdraw3:"Amount received",
    samarywithdraw3:"Amount received",
    notewithdraw:"note",
    notewithdraw1:"Minimum withdrawal amount 20 baht (excluding withdrawal fees)",
    notewithdraw2:"Withdrawals not exceeding 2,000,000 baht will be received within 24 hours.",
    notewithdraw3:"Withdrawals of more than 2,000,000 baht will be received within 3 business days.",
    submitwithdraw:"Confirm withdrawal",
    withdrawall:"Withdraw All",
    change:"Change",
    detail:"Detail",
    buysell:"Buy/Sell",
    quwanttobuy:"Quantity you want to buy",
    quwanttosell:"Quantity you want to sell",
    amountthatcan:"Amount that can be purchased",
    priceper:"Price Per",
    quantity:"Quantity",
    toget:"to get",
    tosell:"to sell",
  };
  