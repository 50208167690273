<template>
  <div>
    <b-modal
      class="price-alert-1"
      id="price-alert-1"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="price-alert-1">
        <h2>
          แจ้งเตือนราคาเหรียญ
          <span>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </h2>
        <span class="close" @click="$bvModal.hide('price-alert-1')"
          ><svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <div class="info">
          <span class="pair-f">{{ currentCoin.symbol.split("/")[0] }}</span>
          <span class="pair-l"> / {{ currentCoin.symbol.split("/")[1] }}</span>
          <span class="v-f" :class="priceInfo.pChg > 0 ? 'green' : 'red'">{{ $MyFunc.NumberFormat(priceInfo.last) }}</span>
          <span class="v-l" :class="priceInfo.pChg > 0 ? 'green' : 'red'">({{ $MyFunc.NumberFormat(priceInfo.pChg) }}%)</span>
        </div>
        <div class="c-content">
          <div>
            <div style="margin-top: 24px">
              <span class="text">ประเภทการแจ้งเตือน</span>
              <div
                class="p1-sc"
                :class="show_p2_options ? 'p1ca' : ''"
                tabindex="0"
                @click="show_p2_options = !show_p2_options"
                @blur="show_p2_options = false"
              >
                <span
                  class="text"
                  :class="pai > 0 || pai == -20 ? '' : 'placeholder-text'"
                  >{{ selected_p2_option }}</span
                >
                <span class="icon"
                  ><svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <div
                  class="options"
                  :class="show_p2_options ? 'show' : 'hidden'"
                >
                  <ul>
                    <li
                      v-if="selected_p2_option != 'เมื่อถึงราคาที่กำหนด (THB)'"
                      @click="
                        selected_p2_option = 'เมื่อถึงราคาที่กำหนด (THB)';
                        pai = null;
                      "
                    >
                      เมื่อถึงราคาที่กำหนด (THB)
                    </li>
                    <li
                      v-if="selected_p2_option != 'เมื่อราคาลดลง (%)'"
                      @click="
                        selected_p2_option = 'เมื่อราคาลดลง (%)';
                        pai = 20;
                      "
                    >
                      เมื่อราคาลดลง (%)
                    </li>
                    <li
                      v-if="selected_p2_option != 'เมื่อราคาเพิ่มขึ้น (%)'"
                      @click="
                        selected_p2_option = 'เมื่อราคาเพิ่มขึ้น (%)';
                        pai = 20;
                      "
                    >
                      เมื่อราคาเพิ่มขึ้น (%)
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div style="margin-top: 16px">
              <span
                class="text"
                v-if="selected_p2_option == 'เมื่อถึงราคาที่กำหนด (THB)'"
                >ระบุราคา</span
              >
              <span
                class="text"
                v-if="selected_p2_option == 'เมื่อราคาลดลง (%)'"
                >ระบุ %</span
              >
              <span
                class="text"
                v-if="selected_p2_option == 'เมื่อราคาเพิ่มขึ้น (%)'"
                >ระบุ %</span
              >
              <span class="input-c">
                <cleave
                 v-if="selected_p2_option == 'เมื่อถึงราคาที่กำหนด (THB)'"
                  :options="options"
                  placeholder="0.00"
                  v-model="pai"
                  :style="[
                    selected_p2_option == 'เมื่อถึงราคาที่กำหนด (THB)'
                      ? { 'margin-left': '91px' }
                      : { 'margin-left': '105px' },
                  ]"
                >
                </cleave>
                <!--  -->
                <cleave
                 v-if="selected_p2_option == 'เมื่อราคาลดลง (%)'"

                  :options="optiondown"
                  placeholder="0.00"
                  v-model="pai"
                  :style="[
                    selected_p2_option == 'เมื่อถึงราคาที่กำหนด (THB)'
                      ? { 'margin-left': '91px' }
                      : { 'margin-left': '105px' },
                  ]"
                >
                </cleave>
                <!--  -->
                <cleave
                 v-if="selected_p2_option == 'เมื่อราคาเพิ่มขึ้น (%)'"

                  :options="optionup"
                  placeholder="0.00"
                  v-model="pai"
                  :style="[
                    selected_p2_option == 'เมื่อถึงราคาที่กำหนด (THB)'
                      ? { 'margin-left': '91px' }
                      : { 'margin-left': '105px' },
                  ]"
                >
                </cleave>

                <span
                  class="symbol"
                  v-text="
                    selected_p2_option == 'เมื่อถึงราคาที่กำหนด (THB)'
                      ? 'THB'
                      : '%'
                  "
                ></span>
               
              </span>
            </div>
            <div
              v-if="selected_p2_option != 'เมื่อถึงราคาที่กำหนด (THB)'"
              class="info-text"
            >
              เตือนเมื่อราคา {{ $MyFunc.NumberFormat(calculatedPai) }}
            </div>
            <div style="margin-top: 16px">
              <span class="text">รูปแบบการเตือน</span>
              <div
                class="p1-sc"
                :class="show_p1_options ? 'p1ca' : ''"
                style="margin-left: 49px"
                tabindex="0"
                @click="show_p1_options = !show_p1_options"
                @blur="show_p1_options = false"
              >
                <span
                  class="text"
                  :class="pai > 0 || pai == -20 ? '' : 'placeholder-text'"
                  >{{ selected_p1_option }}</span
                >
                <span class="icon"
                  ><svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <div
                  class="options"
                  :class="show_p1_options ? 'show' : 'hidden'"
                >
                  <ul>
                    <li
                      v-if="selected_p1_option != 'เตือนวันละ 1 ครั้ง'"
                      @click="selected_p1_option = 'เตือนวันละ 1 ครั้ง'"
                    >
                      เตือนวันละ 1 ครั้ง
                    </li>
                    <li
                      v-if="selected_p1_option != 'เตือนครั้งเดียว'"
                      @click="selected_p1_option = 'เตือนครั้งเดียว'"
                    >
                      เตือนครั้งเดียว
                    </li>
                    <li
                      v-if="
                        selected_p1_option != 'เตือนทุกครั้งเมื่อถึงเงื่อนไข'
                      "
                      @click="
                        selected_p1_option = 'เตือนทุกครั้งเมื่อถึงเงื่อนไข'
                      "
                    >
                      เตือนทุกครั้งเมื่อถึงเงื่อนไข
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button @click="createPriceAlert()" class="clb">บันทึก</button>
      </div>
    </b-modal>
    <!-- <PriceAlert2 /> -->
  </div>
</template>
<script>
// import PriceAlert2 from "./PriceAlert2.vue";
import { HTTP } from "@/config/axios";

export default {
  // components: { PriceAlert2 },
  props: ["currentCoin", "priceInfo"],
  data() {
    return {
      show_p1_options: false,
      show_p2_options: false,
      selected_p1_option: "เตือนวันละ 1 ครั้ง",
      selected_p2_option: "เมื่อถึงราคาที่กำหนด (THB)",
      pai: null,
      options: {
        numeral: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
      },
       optiondown: {
        numeral: true,
        numeralIntegerScale: 5,
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
        prefix: '-',
        rawValueTrimPrefix: true
      },
       optionup: {
        numeral: true,
        numeralIntegerScale: 5,
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
        prefix: '+',
        rawValueTrimPrefix: true
      },
    };
  },
  // watch: {
  //   selected_p2_option(val) {
  //     console.log(val);
  //   },
  //   pai(val){
  //     console.log(val);
  //   },
  //   calculatedPai(val){
  //     console.log(val);
  //   }
  // },
  computed: {
    alertType() {
      if (this.selected_p2_option == "เมื่อถึงราคาที่กำหนด (THB)") {
        return 0;
      } else if (this.selected_p2_option == "เมื่อราคาเพิ่มขึ้น (%)") {
        return 1;
      } else if (this.selected_p2_option == "เมื่อราคาลดลง (%)") {
        return 2;
      }
    },
    calculatedPai() {
      if (this.selected_p2_option == "เมื่อถึงราคาที่กำหนด (THB)") {
        return this.pai;
      } else if (this.selected_p2_option == "เมื่อราคาเพิ่มขึ้น (%)") {
        return (this.priceInfo.last + (Math.floor(this.priceInfo.last * this.pai) / 100));
      } else if (this.selected_p2_option == "เมื่อราคาลดลง (%)") {
        return (this.priceInfo.last - (Math.floor(this.priceInfo.last * this.pai) / 100));
      }
    },
    calculateFrequency() {
      if (this.selected_p1_option == "เตือนวันละ 1 ครั้ง") {
        return 0;
      } else if (this.selected_p1_option == "เตือนครั้งเดียว") {
        return 1;
      } else if (this.selected_p1_option == "เตือนทุกครั้งเมื่อถึงเงื่อนไข") {
        return 2;
      }
    },
  },
  methods: {
    createPriceAlert() {
      let self = this;
     if (this.pai <= 0) {
       self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: 'plese enter value',
            });
     } else {
       HTTP.post("/uc/member/create-coin-price-alert", {
        conditionType: this.alertType,
        conditionPrice: this.calculatedPai,
        basePrice: this.priceInfo.last, // Coin Last Price
        triggerType: this.calculateFrequency,
        coinSymbol: this.currentCoin.symbol,
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.pai = null 
            self.$notify({
              group: "notification",
              type: "success",
              title: "success",
              text: res.data.message,
            });
            this.$bvModal.hide("price-alert-1");
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
          // this.$bvModal.show("price-alert-2");
          //console.log(res);
        })
        .catch((err) => {
          //console.log(err);
        });
     }
    },
  },
};
</script>
<style lang="scss">
#price-alert-1___BV_modal_content_ {
  margin-top: 135px !important;
  width: 480px;
  min-height: 448px;
  background-color: #141b1f;
  // padding-left: 16px;
  // padding-right: 16px;
}
#price-alert-1___BV_modal_body_ {
  padding: 0;
  background-color: transparent !important;
}
.price-alert-1 {
  position: relative;
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    padding-top: 5px;
  }
  span.close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 16px;
  }
  .info {
    margin-top: 47px;
    span.pair-f {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
    span.pair-l {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #9bacb6;
    }
    span.v-f {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-left: 18px;
    }
    span.v-l {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-left: 7px;
    }
    .red{
        color: #de2d40;
    }
    .green{
      color: #0c9;
    }
  }
  .c-content {
    height: 210px;
    margin-top: 47px;
    .p1-sc {
      position: relative;
      width: 220px;
      height: 40px;
      background-color: #191b1c;
      display: inline-block;
      margin-left: 24px;
      border-radius: 4px;
      .text {
        width: 100%;
        height: 100%;
        padding-left: 12px;
        padding-top: 7px;
        display: inline-block;
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        cursor: pointer;
      }

      svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
      .options {
        position: absolute;
        left: 0;
        background-color: #1c262b;
        width: 100%;
        text-align: left;
        top: 42px;
        border-radius: 4px;
        z-index: 20;
        ul {
          padding: 0;
          margin: 0;
          li {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 34px;
            padding-left: 5px;
            color: #d6dde1;
            border-radius: 4px;
            &:hover {
              color: white;
              background-color: #2c3b44;
            }
          }
        }
      }
    }
    span.text {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #9bacb6;
    }
    .info-text {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #677f8e;
      margin-left: 146px;
      margin-top: 11px;
      margin-bottom: 17px;
    }
    .input-c {
      position: relative;
      span.symbol {
        position: absolute;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
      input {
        width: 220px;
        height: 40px;
        background-color: #191b1c;
        margin-left: 91px;
        border-radius: 4px;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        text-align: right;
        padding-left: 12px;
        padding-right: 42px;
        &::placeholder {
          color: #566a76;
        }
      }
    }
  }
  // span.placeholder-text {
  //   color: #566a76 !important;
  // }
  .clb {
    width: 120px;
    height: 36px;
    display: block;
    margin: auto;
    border-radius: 4px;
    background-color: #09BE8B;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a0d10;
  }
}
.p1ca {
  background: #38454d !important;
  color: red !important;
}
</style>
