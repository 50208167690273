<template>
  <div class="container">
    <div class="row">
      <div class="col">

        <div class="policy-form-c">
          <img class="top-logo" src="@/assets/img-fss-logo.png" alt="logo" />

          <div v-if="Array.isArray(documents) && documents.length > 0" class="scrollable-area mt-3">
            <template v-for="(item, index) in documents">
              <h4>{{ item.title }}</h4>
              <div v-html="item.data"></div>
              <div>
                <input
                  v-model="selectedOptions"
                  :value="index"
                  type="checkbox"
                  class="form-check-input"
                  :id="'checkedArrays' + index"
                  @change="checkedScrollbar"
                /> 
                <label :for="'checkedArrays' + index"><h6>&nbsp; ยอมรับ{{ item.title }}</h6></label>
              </div>
              <div v-if="documents.length - 1 !== index" class="my-3 mx-2">
                <div class="line"></div>
              </div>
            </template>
          </div>
          <div v-else>
            <h5>ไม่พบข้อมูล</h5>
          </div>

          <div class="form-group mt-1">
            <!-- <button @click="scrollToBottom" class="btn btn-primary w-100">เลื่อนไปล่างสุด</button> -->
            <h5>กรุณาอ่านและทำเครื่องหมายในช่องสี่เหลี่ยม <span class="text-color">*</span></h5>
            <div class="row">
              <div class="col-auto ms-1">
                <input
                type="checkbox"
                v-model="checkedSubmit"
                class="form-check-input"
                :class="checkDisable ? 'check-disable' : '' "
                :disabled="checkDisable"
                id="checkedSubmit"
                />
              </div>
              <div class="col me-2">
                <label for="checkedSubmit" class="" :class="checkDisable ? 'check-disable' : '' ">
                  <h6>
                    ข้าพเจ้าได้อ่านข้อกำหนดต่างๆในสัญญาแต่งตั้งตัวแทนซื้อขายสินทรัพย์ดิจิทัลนี้แล้วและตกลงยินยอมทำตามเงื่อนไขตามที่กำหนดไว้ทุกประการข้าพเจ้าเข้าใจความเสี่ยงที่เกี่ยวกับการลงทุนในผลิตภัณฑ์ต่างๆแล้ว ข้าพเจ้ายืนยันว่าข้าพเจ้าเป็นเจ้าของบัญชีที่แท้จริง และไม่ได้เปิดบัญชีแทนบุคคลอื่นแต่อย่างใด ข้อมูลที่ข้าพเจ้ากรอกทั้งหมดนี้ถูกต้องและเป็นความจริง เมื่อใดที่ข้อมูลข้าพเจ้าเปลี่ยนแปลง ข้าพเจ้าจะแจ้งบริษัทฯเป็นลายลักษณ์อักษรโดยทันที
                  </h6> 
                </label>
              </div>
            </div>
          </div>

          <div class="button-container mt-1 mb-2">
            <button
              class="next-btn"
              :disabled="!checkedSubmit"
              :class="!checkedSubmit ? 'btn-dull' : ''"
              @click="confirm"
            >
              ตกลง
            </button>
            <button class="prev-btn" @click="cancel" >
              ยกเลิก
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
export default {
  components: {  

  },
  data() {
    return {
      documents: [],
      checkedSubmit: false,
      checkDisable: true,
      selectedOptions: []
    };
  },
  async mounted() {
    await this.getPolicy();
    //this.checkScrollbar();
  },
  created() {
    
  },
  computed: {
   
  },
  methods: {
    scrollToBottom() {
      const scrollableArea = document.querySelector('.scrollable-area');
      if (scrollableArea) {
        scrollableArea.scrollTo({
          top: scrollableArea.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    async getPolicy() {
      try {
        const response = await HTTP.get("/uc/member/get-pdpa");
        let sortedDocuments = [];
        if (response.data.code === 0) {

          /* for (const key in response.data.data) {
            if (response.data.data.hasOwnProperty(key)) {
              const document = response.data.data[key];
              if (document.type === 3) {
                sortedDocuments.unshift(document);
              } else {
                sortedDocuments.push(document);
              }
            }
          }

          if (sortedDocuments.some(doc => doc.type === 3)) {
            sortedDocuments.sort((a, b) => {
              if (a.type === b.type) {
                return 0;
              } else if (a.type === 3) {
                return -1;
              } else {
                return 1;
              }
            });
          } */

          //เก็บเฉพาะ type == 3
          for (const key in response.data.data) {
            if (response.data.data.hasOwnProperty(key)) {
              const document = response.data.data[key];
              if (document.type === 3) {
                sortedDocuments.push(document);
              }
            }
          }

          sortedDocuments.forEach(document => {
            document.data = document.data.replace(/color\s*:\s*(windowtext|black|#0000?|#000000)/g, 'color:#d6dde1');
          });

          if(sortedDocuments.length > 0){
            this.documents = sortedDocuments;
          }else{
            this.checkDisable = false;
          }
        }
      } catch (error) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: error,
        });
      }
    },
    checkedScrollbar (){
      if(this.selectedOptions.length == this.documents.length){
        this.checkDisable = false;
      } else {
        this.checkDisable = true;
        this.checkedSubmit = false;
      }
    },
    checkScrollbar() {
      const scrollableArea = document.querySelector('.scrollable-area');
      if (scrollableArea && scrollableArea.scrollHeight > scrollableArea.clientHeight) {
        scrollableArea.addEventListener('scroll', this.handleScroll);
      } else {
        this.notScrollbar();
      }
    },
    handleScroll() {
      const scrollableArea = document.querySelector('.scrollable-area');
      const isAtBottom = scrollableArea.scrollHeight - scrollableArea.scrollTop - 1 <= scrollableArea.clientHeight;
      if (isAtBottom) {
        this.checkDisable = false;
      } else {
        this.checkDisable = true;
        this.checkedSubmit = false;
      }
    },
    notScrollbar() {
      this.checkDisable = false;
    },
    confirm (){
      let self = this;
      HTTP.post("/uc/member/create-pdpa-approve")
        .then((res) => {
          if (res.data.code === 0) {
            let userData = JSON.parse(localStorage.getItem("user-data") || "{}");
            userData.requirePdpa = false;
            localStorage.setItem("user-data", JSON.stringify(userData));

            self.$router.push({ path: "/market/all-coins" });
          }else{
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel (){
      this.$store.dispatch("logout");
    }
  },
  beforeDestroy() {
    /* const scrollableArea = document.querySelector('.scrollable-area');
    if (scrollableArea) {
      scrollableArea.removeEventListener('scroll', this.handleScroll);
    } */
  }
};
</script>
<style lang="scss" scoped>
.top-logo {
  width: 150px;
}
.policy-form-c {
  margin: auto;
  padding-top: 16px;
  font-family: "Noto Sans Thai";
  font-weight: 600;
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 18px;
    color: #d6dde1;
  }
  .button-container {
    text-align: center;
    button {
      width: 332px;
      height: 40px;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .next-btn {
      background-color: #09BE8B;
      color: #0a0d10;
      font-weight: 500;
    }
    .btn-dull {
      opacity: 0.5;
    }
    .prev-btn {
      background-color: #191b1c;
      color: #ffffff;
      font-weight: 400;
    }
  }
}
.text-color {
  color: #09BE8B !important;
}
.text-white {
  color: white !important;
}
.form-check-input:checked {
  background-color: #09BE8B !important;
  border-color: #09BE8B !important;
}
.check-disable {
  opacity: 20%;
}
.scrollable-area {
  max-height: 55vh;
  overflow-y: auto;
}
.line {
  width: 100%;
  height: 1px;
  background-color: white;
}
</style>