<template>
  <div
    class="padding-table"
    :class="
      current_page === 'categories' ||
      current_page === 'theme' ||
      current_page === 'heatmap' ||
      current_page === 'allCoin' ||
      current_page === 'favourite'
        ? 'scroller'
        : ''
    "
  >
    <table
      class="table table-responsive"
      :class="
        current_page === 'categories' ||
        current_page === 'theme' ||
        current_page === 'heatmap' ||
        current_page === 'allCoin' ||
        current_page === 'favourite'
          ? 'category-theme-w-setting'
          : 'table-w-setting'
      "
      id="myTable"
    >
      <thead v-for="(item, index) in TableHeader" :key="index">
        <!-- Heading Coin -->
        <th scope="col" class="textGreyDark" v-if="Showfavorite || ShowTreeMap">
          <div class="d-flex px-0">
            <div
              class="align-Icon-Heading"
              :class="
                Showfavorite
                  ? 'Header-Name'
                  : '' || ShowTreeMap
                  ? 'Header-Name'
                  : ''
              "
            >
              {{ item.headingCoin }}
            </div>
            <div @click="sortTable('headCoinDown', headCoinDown, 'handle')">
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headCoinDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{ yellow: !headCoinDown && headCoinDown !== null }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading Coin -->

        <!-- Heading CoinGain/Loss -->
        <th scope="col" class="textGreyDark" v-if="ShowGainLoss">
          <div class="d-flex px-0">
            <div class="px-0 align-Icon-Heading">{{ item.headingCoin }}</div>
            <div
              @click="sortTable('headGainLossDown', headGainLossDown, 'handle')"
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headGainLossDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headGainLossDown && headGainLossDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading CoinGain/Loss -->

        <!-- Hedaing Categories -->
        <th
          scope="col"
          class="textGreyDark my-auto"
          v-if="ShowCategories || ShowThemes || ShowAllCategoriesCurrency"
        >
          <div class="d-flex px-0">
            <div class="align-Icon-Heading">{{ item.headingCategories }}</div>
            <div @click="sortTable('headCategories', headCategories, 'handle')">
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headCategories }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headCategories && headCategories !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Hedaing Categories -->
        <!-- Heading Chart -->
        <th class="d-flex justify-content-end" scope="col" v-if="Showfavorite && scannnerValue != 3">
          <div class="align-Icon-Heading" style="margin-bottom: 20px">
            {{ item.headingChart }}
          </div>
        </th>
        <!-- End Heading Chart -->
        <!-- Heading LastPrice -->
        <th
          scope="col"
          class="textGreyDark"
          v-if="Showfavorite || ShowTreeMap || ShowGainLoss"
        >
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingLastPrice }}</div>
            <div
              @click="
                sortTable('headLastPriceDown', headLastPriceDown, 'handle')
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headLastPriceDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headLastPriceDown && headLastPriceDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading LastPrice -->
        <!-- Hedaing MarketCap -->
   
        <th
          scope="col"
          class="textGreyDark"
          v-if="
          showvolume ||
            ShowCategories ||
            ShowThemes ||
            (ShowTreeMap && show_marketcap != true)
          "
        >
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingMarketCap }}</div>
            <div
              @click="sortTable('headMarketCapDown', headMarketCapDown, 'handle')"
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headMarketCapDown && headMarketCapDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Hedaing MarketCap -->
        <!-- Heading HourChange -->
        
        <th
          scope="col"
          class="textGreyDark"
          v-if="
            Showfavorite ||
            ShowCategories ||
            ShowThemes ||
            ShowTreeMap ||
            ShowGainLoss
          "
        >
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingHourChange }}</div>
            <div
              @click="
                sortTable('headHourChangeDown', headHourChangeDown, 'handle')
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headHourChangeDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headHourChangeDown && headHourChangeDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading HourChange -->
        <!-- Heading Volume -->
        <th v-if="showvolume" scope="col" class="textGreyDark">
       <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingVolumeHour}}</div>
            <div
              @click="sortTable('headMarketCapDown', headMarketCapDown, 'handle')"
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headMarketCapDown && headMarketCapDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <th
          scope="col"
          class="textGreyDark"
          v-if="
            Showfavorite ||
            ShowCategories ||
            ShowThemes ||
            ShowTreeMap ||
            ShowAllCategoriesCurrency ||
            ShowGainLoss
          "
        >
          <div class="d-flex justify-content-end" style="padding-left: 50px">
            <div class="align-Icon-Heading">
              {{ item.headingVolume }}
            </div>
            <div @click="sortTable('headVolumeDown', headVolumeDown, 'handle')">
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headVolumeDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headVolumeDown && headVolumeDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading Volume -->
        <!-- Heading HourChange for nested table ShowAllCategoriesCurrency with three dropdown  -->
        <th scope="col" class="textGreyDark" v-if="ShowAllCategoriesCurrency">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingHourChange }}</div>
            <div
              @click="
                sortTable('headHourChangeDown', headHourChangeDown, 'handle')
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headHourChangeDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headHourChangeDown && headHourChangeDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading HourChange -->
        <!-- Heading marketcap on end as on favorite tab -->
        <th
          scope="col"
          class="textGreyDark"
          v-if="Showfavorite && scannnerValue != '3' || ShowGainLoss "
        >
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingMarketCap }}</div>
            <div
              @click="
                sortTable(
                  'headMarketCapFavDown',
                  headMarketCapFavDown,
                  'handle'
                )
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapFavDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow:
                      !headMarketCapFavDown && headMarketCapFavDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading marketcap on end as on favorite tab -->
        <!-- Heading marketcap on end as on nested tab ShowAllCategoriesCurrency -->
        <th scope="col" class="textGreyDark" v-if="ShowAllCategoriesCurrency">
          <div style="display: flex; justify-content: end">
            <div class="align-Icon-Heading">{{ item.headingMarketCapM }}</div>
            <div
              @click="
                sortTable(
                  'headMarketCapFavDown',
                  headMarketCapFavDown,
                  'handle'
                )
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapFavDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow:
                      !headMarketCapFavDown && headMarketCapFavDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading marketcap on end as on nested tab ShowAllCategoriesCurrency-->
        <!-- Heading MarketCap for heat map -->
        <th
          scope="col"
          class="textGreyDark"
          v-if="current_page == 'heatmap' && show_marketcap == true"
        >
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">
              {{ item.headingMarketCap }}
            </div>
            <div
              @click="
                sortTable(
                  'headMarketCapPercDown',
                  headMarketCapPercDown,
                  'handle'
                )
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapPercDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow:
                      !headMarketCapPercDown && headMarketCapPercDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!--End Heading MarketCap for heat map -->
        <!-- Heading MarketCap Percentage -->
        <!-- {{ item.headingMarketCapPercentage }}
        {{ ShowTreeMap }}
        {{ ShowAllCategoriesCurrency }} -->
        <th
          scope="col"
          class="textGreyDark"
          v-if="item.headingMarketCapPercentage != undefined"
        >
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">
              {{ item.headingMarketCapPercentage }}
            </div>
            <div
              @click="
                sortTable(
                  'headMarketCapPercDown',
                  headMarketCapPercDown,
                  'handle'
                )
              "
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapPercDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow:
                      !headMarketCapPercDown && headMarketCapPercDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!--End Heading MarketCap Percentage -->
        <!-- Heading Description -->
        <th
          scope="col"
          class="textGreyDark-description"
          v-if="ShowCategories || ShowThemes || ShowAllCategoriesCurrency"
        >
          <div
            class="d-flex justify-content-left px-3"
            style="margin-bottom: 15px"
          >
            <div
              v-if="current_page != 'heatmap'"
              class="align-Icon-Heading"
              style="padding-left: 15px"
            >
              {{ item.headingDescription }}
            </div>
          </div>
        </th>
      
        <th v-if="showvolume" scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingVolumeUP }}</div>
            <div
              @click="sortTable('headMarketCapDown', headMarketCapDown, 'handle')"
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headMarketCapDown && headMarketCapDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <th v-if="showvolume" scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end">
            <div class="align-Icon-Heading">{{ item.headingUp }}</div>
            <div
              @click="sortTable('headMarketCapDown', headMarketCapDown, 'handle')"
            >
              <div class="chevronUp">
                <svg
                  :class="{ yellow: headMarketCapDown }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-up-fill innerChevronUp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                  />
                </svg>
              </div>
              <div class="chevronDown">
                <svg
                  :class="{
                    yellow: !headMarketCapDown && headMarketCapDown !== null,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="currentColor"
                  class="bi bi-caret-down-fill innerChevronDown"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </th>
        <!-- End Heading Description -->
      </thead>
      <tbody>
        <!-- skaleton start -->
        <template v-if="TableData.length == 0">
          <td colspan="10" class="text-center">-- ไม่มีข้อมูล --</td>
          <!-- <tr
            class="textDarkgrey-Border"
            v-for="(item, index) in 10"
            :key="index + 'e'"
          >
            <td>
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="80%"
              ></b-skeleton>
            </td>
            <td>
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="80%"
              ></b-skeleton>
            </td>
            <td>
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="80%"
              ></b-skeleton>
            </td>
            <td>
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="80%"
              ></b-skeleton>
            </td>
            <td>
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="80%"
              ></b-skeleton>
            </td>
            <td>
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="80%"
              ></b-skeleton>
            </td>
          </tr> -->
        </template>
        <!-- skaleton end -->
        <template v-else>
          <tr
            class="textDarkgrey-Border px-0 mx-2"
            v-for="(item, index) in TableData"
            :key="index"
          >
            <!-- {{ index }}: {{ item.lastPrice }} -->
            <!-- coin data without start in gainloss -->
            <td v-if="ShowGainLoss" class="px-0">
              <div class="d-flex flex-wrap px-0">
                <div class="icon-image">
                  <b-img
                    class="imageIcon-bitcoin"
                    :src="item.CoinImage"
                    fluid
                    style="width: 30px;"
                    alt="#"
                  >
                  </b-img>
                </div>
                <div
                  class="coin-symbol-container"
                  :class="isScanner ? 'w100p' : 'w100p'"
                >
                  <span class="coin-symbol">
                    {{ item.Coinsybmol.split("/")[0] }}
                  </span>
                  <span
                    class="textGreyDark-table thai-font h-t"
                    style="font-family: 'Sarabun' !important"
                  >
                    / {{ item.Coinsybmol.split("/")[1] }}
                  </span>
                </div>
                <div class="">
                  <span class="textGreyDark-table ms-2">
                    {{ item.CoinName }}
                  </span>
                </div>
              </div>
            </td>
            <!-- End coin data without star in gainloss -->
            <!-- coin data with star in showfavorite and all coins -->
            <!-- End coin data without star in gainloss -->
            <!-- coin data with star in showfavorite and all coins -->
            <td v-if="Showfavorite || ShowTreeMap" class="px-0">
              <div class="d-flex flex-wrap px-0">
                <div class="">
                  <div
                    :class="`${
                      item.CoinImage ? 'icon-image-with-star' : 'icon-image'
                    }`"
                  >
                    <!-- <img
                      v-if="item.StarIcon && !favourite_coin.includes(item.Coinsybmol)"
                      @click="addToFavourite(item.Coinsybmol)"
                      class="imageIcon-star"
                      src="../../assets/images/favoriteTable/star-uncheck.png"
                      fluid
                      alt="#"
                    >
                    <img
                      v-else
                      @click="addToFavourite(item.Coinsybmol)"
                      class="imageIcon-star"
                      src="../../assets/images/favoriteTable/star.png"
                      fluid
                      alt="#"
                    > -->
                    <svg
                      v-if="favCoinRows[item.Coinsybmol] == true"
                      @click="addToFavourite(item.Coinsybmol)"
                      class="imageIcon-star"
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.0013 13.3917L14.1513 16.5001L12.7846 10.6417L17.3346 6.70008L11.343 6.19175L9.0013 0.666748L6.65964 6.19175L0.667969 6.70008L5.21797 10.6417L3.8513 16.5001L9.0013 13.3917Z"
                        fill="#D6DDE1"
                      />
                      1
                    </svg>
                    <svg
                      v-else
                      @click="addToFavourite(item.Coinsybmol)"
                      class="imageIcon-star"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.8327 6.70008L10.841 6.18341L8.49935 0.666748L6.15768 6.19175L0.166016 6.70008L4.71602 10.6417L3.34935 16.5001L8.49935 13.3917L13.6494 16.5001L12.291 10.6417L16.8327 6.70008ZM8.49935 11.8334L5.36602 13.7251L6.19935 10.1584L3.43268 7.75841L7.08268 7.44175L8.49935 4.08341L9.92435 7.45008L13.5743 7.76675L10.8077 10.1667L11.641 13.7334L8.49935 11.8334Z"
                        fill="#677F8E"
                      />
                    </svg>
                    <b-img
                      class="imageIcon-bitcoin"
                      :src="item.CoinImage"
                      fluid
                      alt="#"
                    >
                    </b-img>
                  </div>
                </div>
                <div
                  class="coin-symbol-container"
                  :class="isScanner ? 'w100p' : 'w100p'"
                >
                  <span class="coin-symbol">
                    {{ item.Coinsybmol.split("/")[0] }}
                  </span>
                  <span
                    class="textGreyDark-table thai-font h-t"
                    style="font-family: 'Sarabun' !important"
                  >
                    / {{ item.Coinsybmol.split("/")[1] }}
                  </span>
                </div>
                <div class="h-t">
                  <span class="textGreyDark-table ms-2">
                    {{ item.CoinName }}
                  </span>
                </div>
              </div>
            </td>
            <!-- End coin data with star in showfavorite and all coins -->
            <!-- Categories data with three image -->
            <td
              class="texdtGrey px-0"
              v-if="ShowCategories || ShowThemes || ShowAllCategoriesCurrency"
            >
              <div class="d-flex flex-wrap px-0">
                <div class="image-wrapper d-flex">
                  <b-img
                  v-for="(coin, index) in item.image"
                    :key="index"
                    id="img1Table"
                    class="imageCoinTable"
                    :src="
                      require('../../assets/images/coin_32/'+coin.CoinImage.toLowerCase()+'.png')
                    "
                    fluid
                    alt="#"
                  >
                  </b-img>
                </div>
                <div class="">
                  <span class="textGrey Coin-Symbol">{{item.Categories }}</span>
                </div>
              </div>
            </td>
            <!-- End Categories data with three image -->
            <!-- Chart PLoting in table -->
            <td
              class="textGrey p-0 my-0"
              style="position: relative; width: 140px"
              v-if="Showfavorite && scannnerValue != 3"
            >
            <div v-if="item.hourChangechart != undefined" class="d-flex justify-content-end">
                {{ $MyFunc.NumberFormat(item.hourChangechart) }} %
              </div>
              <div v-else>
              <div
               v-if="item.hourChange != undefined"
                id="chart"
                style="position: absolute; top: -10px; right: -2px"
              >
                <apexchart
                  type="area"
                  height="80"
                  width="124"
                  :options="item.hourChange >= 0 ? chartOptions : chartOptionss"
                  :series="item.series"
                ></apexchart>
              </div>
              </div>
             
            </td>
            <!-- End Chart PLoting in table -->
            <!-- last price data -->
            <td
              class="textGrey tableData-white"
              v-if="Showfavorite || ShowTreeMap || ShowGainLoss"
            >
              <div class="d-flex justify-content-end">
                {{ $MyFunc.NumberFormat(item.lastPrice, item.bathScale) }}
              </div>
            </td>
            <!-- End last price data -->
            <!-- marketCap data -->
            <td
              class="textGrey justify-content-left tableData-white"
              style="text-align: right"
              v-if="
                ShowCategories ||
                ShowThemes ||
                (ShowTreeMap && show_marketcap != true)
              "
            >
              {{ $MyFunc.NumberFormatPrice(item.marketCap) }}
            </td>
            <!--End marketCap data -->
            <!-- hour change data -->
            <td
              v-if="
                Showfavorite ||
                ShowCategories ||
                ShowThemes ||
                ShowTreeMap ||
                ShowGainLoss
              "
              :class="
                item.hourChange >= 0
                  ? 'text-success tableData-white'
                  : 'text-danger tableData-white'
              "
            >
              <div class="d-flex justify-content-end">
                {{
                  item.hourChange > 0
                    ? "+" + $MyFunc.PercentChg(item.hourChange) + " %"
                    : $MyFunc.PercentChg(item.hourChange) + " %"
                }}
              </div>
            </td>
            <!--End hour change data -->
            <!-- Volume data -->
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="
               showvolume ||
                Showfavorite ||
                ShowCategories ||
                ShowThemes ||
                ShowTreeMap ||
                ShowAllCategoriesCurrency ||
                ShowGainLoss 
              "
            >
              {{ $MyFunc.NumberFormatPrice(item.volume) }}
            </td>
            <!-- End Volume data -->
            <!-- hour change data -->
            <td :class="
                item.hourChange >= 0
                  ? 'text-success tableData-white'
                  : 'text-danger tableData-white'
              " v-if="ShowAllCategoriesCurrency">
              <div class="d-flex justify-content-end">
                {{
                  item.hourChange > 0
                    ? "+" + $MyFunc.PercentChg(item.hourChange) + " %"
                    : $MyFunc.PercentChg(item.hourChange) + " %"
                }}
              </div>
            </td>
           
            </td>
            <!--End hour change data -->
            <!-- marketCap data at end in show favorite tab -->
            <td
              class="textGrey tableData-white"
              v-if="Showfavorite && scannnerValue != '3'|| ShowGainLoss && scannnerValue != 3"
              style="text-align: right"
            >
              <span
                >{{ $MyFunc.NumberFormatPrice(item.marketCap) }}</span
              >
            </td>
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="showvolume"
            >
              {{ $MyFunc.NumberFormatPrice(item.volumePrev) }}
            </td>
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="showvolume"
            >
              {{ $MyFunc.NumberFormatPrice(item.volume) }}
            </td>
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="showvolume"
            >
              {{ $MyFunc.NumberFormatPrice(item.volumeUp) }} 
            </td>
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="showvolume"
            >
              {{ $MyFunc.NumberFormat(item.percentVolumeUp)  }} %
            </td>
            
            <!-- End marketCap data at end in show favorite tab -->
            <!-- Description data -->
            <td
              class="textGreyDark thaiLang px-3"
              style="padding-left: 32px !important"
              v-if="ShowCategories || ShowThemes"
            >
              {{ item.Description }}
            </td>
            <!--End Description data -->
            <!-- marketCapM in nested ShowAllCategoriesCurrency data -->
            <td
              style="text-align: right"
              class="textGrey"
              v-if="ShowAllCategoriesCurrency"
            >
            {{ $MyFunc.NumberFormatPrice(item.marketCapM) }}
            </td>
            <!-- End  marketCapM in nested ShowAllCategoriesCurrency data -->
            <!-- MarketCapPercentage data for heatmap -->
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="current_page == 'heatmap' && show_marketcap == true"
            >
              {{ item.MarketCapPercentageS }}
            </td>
            <!-- End MarketCapPercentage data for heatmap -->
            <!-- MarketCapPercentage data -->
            <td
              class="textGrey tableData-white"
              style="text-align: right"
              v-if="item.MarketCapPercentage != undefined"
            >
              {{ $MyFunc.NumberFormat(item.MarketCapPercentage || 0) }} %
            </td>
            <!-- End MarketCapPercentage data -->
            <!--Description ShowAllCategoriesCurrency data  -->
            <td
              class="textGreyDark thaiLang px-3"
              style="padding-left: 32px !important"
              v-if="ShowAllCategoriesCurrency"
            >
              {{ item.Description }}
            </td>
            <!--End Description ShowAllCategoriesCurrency data  -->
            <!-- buysell button -->
            <td
              class="px-0"
              v-if="Showfavorite || ShowTreeMap || ShowGainLoss"
              style="
                text-align: end;
                vertical-align: middle;
                padding-right: 16px !important;
              "
            >
              <span
                v-if="!$store.state.hide_menu"
                class="textYellow"
                @click="handleDetail(item.Coinsybmol)"
                >{{ $t('detail') }}</span
              >
              <span v-if="!$store.state.hide_menu" class="textSeprator"></span>
              <span class="textYellow" @click="handleBuySell(item.Coinsybmol)"
                >{{ $t('buysell') }}</span
              >
            </td>
            <!-- End BuySell Button -->
            <!-- Detaiol Button -->
            <td
              class="px-0"
              v-if="ShowCategories || ShowThemes || ShowAllCategoriesCurrency"
              style="text-align: end; vertical-align: middle"
            >
              <span class="textYellow" @click="childMethod(item)">{{ $t('detail') }}</span>
            </td>
            <!-- End Detaiol Button -->
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import EventBus from "@/event-bus";
import favoriteGraph from "./Graph-data.js";
import { debounce } from "lodash";
export default {
  name: "favorite",
  Showfavorite: "",
  ShowThemes: "",
  props: [
    "TableHeader",
    "TableData",
    "favCoinRows",
    "Showfavorite",
    "ShowCategories",
    "showvolume",
    "ShowThemes",
    "ShowTreeMap",
    "ShowAllCategoriesCurrency",
    "ShowGainLoss",
    "current_page",
    "mycoins",
    "PChartLabels",
    "PSeries",
    "show_marketcap",
    "pullTheBull",
    "isScanner",
    "scannnerValue"
  ],
  data() {
    return {
      favourite_coin: [],
      headCoinDown: null,
      headGainLossDown: null,
      headCategories: null,
      headLastPriceDown: null,
      headMarketCapDown: null,
      headHourChangeDown: null,
      headVolumeDown: null,
      headMarketCapFavDown: null,
      headMarketCapPercDown: null,
      favoriteGraph: favoriteGraph,
      // child_msg: "message from child"
      child_msg: true,
      defaultType: null,
      defaultStatus: null,

      chartOptions: {
        chart: {
          type: "area",
          height: 80,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "straight",
        },
        colors: ["#40994F", "#40994F", "#40994F"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0.05,
            stops: [50, 100, 100, 100],
          },
        },
        grid: {
          show: false, // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false, //or just here to disable only x axis grids
            },
          },
          yaxis: {
            lines: {
              show: false, //or just here to disable only y axis
            },
          },
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        labels: this.PChartLabels,
        legend: {
          horizontalAlign: "left",
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 1,
          curve: "straight",
        },
      },
      chartOptionss: {
        chart: {
          type: "area",
          height: 80,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "straight",
        },
        colors: ["#FF0000", "#FF0000", "#FF0000"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0.05,
            stops: [50, 100, 100, 100],
          },
        },
        grid: {
          show: false, // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false, //or just here to disable only x axis grids
            },
          },
          yaxis: {
            lines: {
              show: false, //or just here to disable only y axis
            },
          },
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        labels: this.PChartLabels,
        legend: {
          horizontalAlign: "left",
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 1,
          curve: "straight",
        },
      },
      isUserLogin: false,
    };
  },

  mounted() {},
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
    }
  },
  watch: {
    // TableData: {
    //   handler(newData, oldData) {
    //     if (newData !== oldData) {
    //       this.updateSortTable();
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    /* NumberFormat(volume) {
      if (volume >= 10000) {
        return (volume / 1000000).toFixed(2) + " M";
      } else if (volume >= 100) {
        return (volume / 1000).toFixed(2) + " k";
      } else {
        return volume;
      }
    }, */
    updateSortTable() {
      if (this.TableData && this.TableData.length > 0) {
        if (this.ShowGainLoss) {
          this.clearSorting(null);
          this.sortTable("headHourChangeDown", null, "socket");
        } else {
          if(this.current_page != 'theme'){
            this.sortTable(
            this.defaultType ? this.defaultType : "headCoinDown",
            this.defaultStatus !== null ? this.defaultStatus : true,
            "socket"
          );
          }
          
        }
      }
    },
    addToFavourite(symbol) {
      if (this.isUserLogin == false) {
        let a = localStorage.getItem("fav");
        let c = [];
        if (a != null || a != undefined) {
          c = a.split(",");
        }
        if (this.favCoinRows[symbol]) {
          if (c != "") {
            for (let i = 0; i < c.length; i++) {
              if (symbol == c[i]) {
                c.splice(i, 1);
                let e = c.join();
                localStorage.setItem("fav", e);
                this.favCoinRows[symbol] = false;
                return false;
              }
            }
          } else {
            if (a == "") {
              let a = [];
              a = symbol;
              localStorage.setItem("fav", a);
              this.favCoinRows[symbol] = false;
              return false;
            } else {
              let a = [];
              a.push(symbol);
              let e = a.join();
              localStorage.setItem("fav", e);
              this.favCoinRows[symbol] = false;
              return false;
            }
          }
        } else {
          if (c != "") {
            if (a != null || a != undefined) {
              c.push(symbol);
              let d = c;
              let e = d.join();
              localStorage.setItem("fav", e);
            } else {
              let a = [];
              a.push(symbol);
              let e = a.join();
              localStorage.setItem("fav", e);
            }
          } else {
            if (a == "") {
              let a = [];
              a = symbol;
              localStorage.setItem("fav", a);
            } else {
              let a = [];
              a.push(symbol);
              let e = a.join();
              localStorage.setItem("fav", e);
            }
          }
          this.favCoinRows[symbol] = true;
          return false;
        }
      }
      if (this.favCoinRows[symbol]) {
        this.favCoinRows[symbol] = false;
      } else {
        this.favCoinRows[symbol] = true;
      }
      HTTP.post("/uc/member/update-member-favorite", {
        coinId: symbol,
        isFavorite: this.favCoinRows[symbol],
      }).then((response) => {
        if (response.data.code == 0) {
          if (this.current_page == "favourite") {
            EventBus.$emit("get-Symbol-Thumb-trend-favourite");
          } else if(this.current_page == "categories") {
            EventBus.$emit("get-Symbol-Thumb-trend-categories");
          } else if(this.current_page == "theme") {
            EventBus.$emit("get-Symbol-Thumb-trend-theme");
          } else{
            EventBus.$emit("get-Symbol-Thumb-trend-allCoin");
          }
        }
      });
    },
    sortTable(type, status, flag) {
      this.defaultType = type;
      //Favorite, All Coinss => Coin
      // headCategories 
      if (type === "headCoinDown") {
        if (flag == "handle") {
          if (this.headCoinDown == null) {
            this.headCoinDown = true;
          } else {
            this.headCoinDown = !this.headCoinDown;
          }
          this.defaultStatus = this.headCoinDown;
          if (!status) {
            this.TableData.sort((a, b) =>
              a.Coinsybmol.localeCompare(b.Coinsybmol)
            );
          } else {
            this.TableData.sort((a, b) =>
              b.Coinsybmol.localeCompare(a.Coinsybmol)
            );
          }
        } else if (flag == "socket") {
          if (status) {
            this.TableData.sort((a, b) =>
              a.Coinsybmol.localeCompare(b.Coinsybmol)
            );
          } else {
            this.TableData.sort((a, b) =>
              b.Coinsybmol.localeCompare(a.Coinsybmol)
            );
          }
        }
        //Top Gain/Loss => Coin
      } 
      else if (type === "headGainLossDown") {
        if (flag == "handle") {
          if (this.headGainLossDown == null) {
            this.headGainLossDown = true;
          } else {
            this.headGainLossDown = !this.headGainLossDown;
          }
          this.defaultStatus = this.headGainLossDown;
          if (!status) {
            this.TableData.sort((a, b) =>
              a.Coinsybmol.localeCompare(b.Coinsybmol)
            );
          } else {
            this.TableData.sort((a, b) =>
              b.Coinsybmol.localeCompare(a.Coinsybmol)
            );
          }
        } else if (flag == "socket") {
          if (status) {
            this.TableData.sort((a, b) =>
              a.Coinsybmol.localeCompare(b.Coinsybmol)
            );
          } else {
            this.TableData.sort((a, b) =>
              b.Coinsybmol.localeCompare(a.Coinsybmol)
            );
          }
        }
        //not found
      } else if (type === "headCategories") {
        if (this.headCategories == null) {
          this.headCategories = true;
        } else {
          this.headCategories = !this.headCategories;
        }
        if (flag == "handle") {
          if (status == null) {
            this.headCategories = true;
          } else {
            if(status == true){
              status = false
            } else{
              status = true;
            }
          }
          if (!status) {
            this.TableData.sort(
              (a, b) =>  a.Categories.localeCompare(b.Categories)
            );
          } else {
            this.TableData.sort(
              (a, b) =>  b.Categories.localeCompare(a.Categories)
            );
          }
        }
        //Favorite, All Coinss, Top Gain/Loss => Last Price
      } else if (type === "headLastPriceDown") {
        if (flag == "handle") {
          if (this.headLastPriceDown == null) {
            this.headLastPriceDown = true;
          } else {
            this.headLastPriceDown = !this.headLastPriceDown;
          }
          this.defaultStatus = this.headLastPriceDown;
          if (!status) {
            this.TableData.sort(
              (a, b) => Number(a.lastPrice) - Number(b.lastPrice)
            );
          } else {
            this.TableData.sort(
              (a, b) => Number(b.lastPrice) - Number(a.lastPrice)
            );
          }
        } else if (flag == "socket") {
          if (status) {
            this.TableData.sort(
              (a, b) => Number(a.lastPrice) - Number(b.lastPrice)
            );
          } else {
            this.TableData.sort(
              (a, b) => Number(b.lastPrice) - Number(a.lastPrice)
            );
          }
        }
        //not found
      } else if (type === "headMarketCapDown") {
        if (this.headMarketCapDown == null) {
          this.headMarketCapDown = true;
        } else {
          this.headMarketCapDown = !this.headMarketCapDown;
        }
        if (flag == "handle") {
          if (status == null) {
            this.headMarketCapDown = true;
          } else {
            if(status == true){
              status = false
            } else{
              status = true;
            }
          }
          if (status) {
            this.TableData.sort((a, b) => a.marketCap - b.marketCap);
          } else {
            this.TableData.sort((a, b) => b.marketCap - a.marketCap);
          }
        }
        //Favorite, All Coinss, Top Gain/Loss => 24h Change
      } else if (type === "headHourChangeDown") {
        if (flag == "handle") {
          if (this.headHourChangeDown == null) {
            this.headHourChangeDown = true;
          } else {
            this.headHourChangeDown = !this.headHourChangeDown;
          }
          this.defaultStatus = this.headHourChangeDown;
          if (!status) {
            this.TableData.sort((a, b) => a.hourChange - b.hourChange);
          } else {
            this.TableData.sort((a, b) => b.hourChange - a.hourChange);
          }
        } else if (flag == "socket") {
          if (status) {
            this.TableData.sort((a, b) => a.hourChange - b.hourChange);
          } else {
            this.TableData.sort((a, b) => b.hourChange - a.hourChange);
          }
        }
        //Favorite, All Coinss, Top Gain/Loss => Volume
      } else if (type === "headVolumeDown") {
        if (flag == "handle") {
          if (this.headVolumeDown == null) {
            this.headVolumeDown = true;
          } else {
            this.headVolumeDown = !this.headVolumeDown;
          }
          this.defaultStatus = this.headVolumeDown;
          if (!status) {
            this.TableData.sort((a, b) => a.volume - b.volume);
          } else {
            this.TableData.sort((a, b) => b.volume - a.volume);
          }
        } else if (flag == "socket") {
          if (status) {
            this.TableData.sort((a, b) => a.volume - b.volume);
          } else {
            this.TableData.sort((a, b) => b.volume - a.volume);
          }
        }
        //Favorite, All Coinss, Top Gain/Loss => Market Cap
      } else if (type === "headMarketCapFavDown") {
        if (flag == "handle") {
          if (this.headMarketCapFavDown == null) {
            this.headMarketCapFavDown = true;
          } else {
            this.headMarketCapFavDown = !this.headMarketCapFavDown;
          }
          this.defaultStatus = this.headMarketCapFavDown;
          if (!status) {
            this.TableData.sort((a, b) => a.marketCap - b.marketCap);
          } else {
            this.TableData.sort((a, b) => b.marketCap - a.marketCap);
          }
        } else if (flag == "socket") {
          if (status) {
            this.TableData.sort((a, b) => a.marketCap - b.marketCap);
          } else {
            this.TableData.sort((a, b) => b.marketCap - a.marketCap);
          }
        }
        //not found
      } else if (type === "headMarketCapPercDown") {
        if (this.headMarketCapPercDown == null) {
          this.headMarketCapPercDown = true;
        } else {
          this.headMarketCapPercDown = !this.headMarketCapPercDown;
        }
        // console.log("not found headMarketCapPercDown !!!");
      }
      this.clearSorting(type);
    },
    clearSorting(type) {
      this.headCoinDown = type === "headCoinDown" ? this.headCoinDown : null;
      this.headGainLossDown =
        type === "headGainLossDown" ? this.headGainLossDown : null;
      this.headCategories =
        type === "headCategories" ? this.headCategories : null;
      this.headLastPriceDown =
        type === "headLastPriceDown" ? this.headLastPriceDown : null;
      this.headMarketCapDown =
        type === "headMarketCapDown" ? this.headMarketCapDown : null;
      this.headHourChangeDown =
        type === "headHourChangeDown" ? this.headHourChangeDown : null;
      this.headVolumeDown =
        type === "headVolumeDown" ? this.headVolumeDown : null;
      this.headMarketCapFavDown =
        type === "headMarketCapFavDown" ? this.headMarketCapFavDown : null;
      this.headMarketCapPercDown =
        type === "headMarketCapPercDown" ? this.headMarketCapPercDown : null;
    },
    sortTables(x) {
      var table, rows, switching, i, x, y, shouldSwitch;
      table = document.getElementById("myTable");
      switching = true;
      /*Make a loop that will continue until
  no switching has been done:*/
      while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /*Loop through all table rows (except the
    first, which contains table headers):*/
        for (i = 1; i < rows.length - 1; i++) {
          //start by saying there should be no switching:
          shouldSwitch = false;
          /*Get the two elements you want to compare,
      one from current row and one from the next:*/
          x = rows[i].getElementsByTagName("TD")[x];
          y = rows[i + 1].getElementsByTagName("TD")[x];
          //check if the two rows should switch place:
          if (Number(x.innerHTML) > Number(y.innerHTML)) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
        if (shouldSwitch) {
          /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
    },
    childMethod(value) {
      let obj= {
        child_msg:this.child_msg,
        length:value.length
      }
      this.$emit("child-method", obj);
    
    },
    handleBuySell(coin) {
      coin = coin.toLowerCase().replace("/", "_");
      localStorage.setItem("symbol",coin)
      this.$router.push("/exchange/" + coin);
    },
    handleDetail(coin) {
      coin = coin.toLowerCase().replace("/", "_");
      this.$store.state.currentPair = coin
      this.$router.push("/market/coin-detail/" + coin);
    },
  },
};
</script>
<style lang="scss" scoped>
.table tr td {
  padding: 24px 2px;
}
.icon-image {
  width: 40px;
}
.icon-image-with-star {
  width: 81px;
  .imageIcon-star {
    width: 16.67px;
    margin-right: 26px;
    cursor: pointer;
  }
  .imageIcon-bitcoin {
    width: 30px;
  }
}
.coin-symbol-container {
  display: flex;
  align-items: center;
  padding: 0;
  .coin-symbol {
    align-items: center;
    text-align: center;
    margin-left: 5px;
    font-size: 16px;
    font-family: "Roboto Flex";
    line-height: 20px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    vertical-align: middle;
    margin-right: 5px;
  }
  .coin-text {
    color: #677f8e;
    vertical-align: middle;
    font-size: 14px;
    font-family: "Sarabun";
    font-weight: 400;
    margin-left: 5px;
  }
}

.thaiLang {
  font-family: "Sarabun" !important;
  line-height: 24px;
}
.image-wrapper {
}

.tableData-white {
  vertical-align:middle;
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
}
.yellow {
  color: #09BE8B;
}
// .padding-table {
//   // padding: 0px 18px 0px 18px;
//   // padding: 0px 0px 0px 18px;
//   // margin-right: 18px;
//   margin: 0 24px;
// }
.imageIcon-Star {
  height: auto;
  // max-width: 100%;
  // padding-left: 6px;
  border-radius: 50%;
}
.imageIcon-bitcoin {
  height: auto;
  max-width: 24px;
  max-height: 24px;
  // margin-left: 20px;
  margin-right: 5px;

  border-radius: 50%;
}
.imageIcon-bitcoin-gainloss {
  height: auto;
  max-width: 30%;
  // margin-left: 32px;
  margin-right: 18px;
  border-radius: 50%;
}
.textGreen {
  color: #0c9;

  vertical-align: middle;
  font-size: 16px;
}
.textYellow {
  font-family: "Roboto Flex";
  color: #09BE8B;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}
.textYellow:hover {
  color: #d6dde1;
}
.textSeprator {
  color: #28363e;
  border-right: 2px solid;
  margin-left: 12px;
  margin-right: 12px;
}
.textGrey {
  color: rgb(255, 255, 255);
  vertical-align: middle;
}
.textGreyDark {
  color: #677f8e;
  vertical-align: middle;
}
.textGreyDark-table {
  color: #677f8e;
  vertical-align: middle;
  line-height: 24px;
  font-family: "Roboto Flex";
  font-size: 14px;
  font-weight: 400;
}
.textGreyDark-description {
  color: #677f8e;
  vertical-align: middle;
}
.textDarkgrey-Border {
  border-color: #28363e;
}
.margin-top {
  margin-top: 3px;
}
.imageCoinTable {
  width: 23px;
  border-radius: 50%;
  height: 20px;
  top: 0px;
  border-left: 3px solid black;
}
.texdtGrey{
  vertical-align:middle
}
.imageBitCoinTable {
  height: auto;
  width: 100%;
  max-width: 20px;
  border-radius: 50%;
  // margin-bottom: 15px;
  position: absolute;
  top: 0px;
}

#img1Table {
  position: relative;
  z-index: 10;
}
#img2Table {
  position: relative;
  z-index: 20;
  // right: 2%;
  right: 6px;
}
#img3Table {
  position: relative;
  z-index: 30;
  // right: 4%;
  right: 13px;
}
.Header-Name {
  // padding-left: 27px;
  padding-left: 42px !important;
}
.align-Icon-Heading {
  width: max-content;
  position: relative;
  bottom: 2px;
  font-size: 14px;
  font-family: "Roboto Flex";
  line-height: 14px;
  font-weight: 400;
  color: #677f8e;
}
.chevronUp {
  position: relative;
  margin-top: -10px;
  left: 6px;
  cursor: pointer;
  color: #677f8e;
}
.chevronDown {
  cursor: pointer;
  position: relative;
  // left: -2px;
  left: 6px;
  bottom: 14px;
  color: #677f8e;
}
.Graphalign {
  position: relative;
  top: 5px;
  right: 40px;
}
.Coin-Symbol {
  font-family: "Roboto Flex";
  line-height: 20px;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
}

.align-marketcap-data {
  // position: relative;
  // left: 12px;
  text-align: center;
}
.scroller {
  overflow: auto;
}

.scroller:hover {
  // width: 300px;
  //height: 300px;
  // overflow-y: scroll;
  // scrollbar-color: rebeccapurple green;
  // scrollbar-width: thin;
}

// .scroller:hover{
//   // width: 300px;
//   height: 200px;
//   overflow-y: scroll;
//   // scrollbar-color: rebeccapurple green;
//   // scrollbar-width: thin;
// }

@media only screen and (max-width: 880px) {
  .table-w-setting {
    // width: 120%;
    margin: auto;
  }

  .h-t {
    display: none;
  }
  .coin-symbol-container {
    width: auto;
  }
  .scroller {
    // overflow: unset;
  }
}

@media only screen and (max-width: 1280px) {
  .category-theme-w-setting {
    width: 100%;
    min-width: 900px;
    margin: auto;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121516;
}
@media (max-width: 848px) {
  .padding-table {
    margin: 0 16px;
  }
}
@media (min-width: 848px) {
  .padding-table {
    margin: 0 24px;
  }
}
.w100p {
  width: 100px;
}
.w80p {
  width: 80px;
}
</style>
