<template>
  <div>
    <b-modal
      class="trailingorder-SSL"
      id="trailingorder-SSL"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="stop-loss-detail-modal px-2">
        <div class="col-12 my-2">
          <h5>
            Stop Loss
            <span class="cursor-pointer">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                  fill="#677F8E"
                />
              </svg>
              <span
                @click="$bvModal.hide('trailingorder-SSL')"
                style="position: absolute; right: 30px; cursor: pointer"
                ><svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
            </span>
          </h5>
        </div>

        <div class="row top-row">
          <div class="col-3 ts-title">Order</div>
          <div class="col-9 ts-value p-0">
            <span class="sell-tag">SELL</span> <span style="font-weight: 500;">{{ coinName }}</span>
            <span class="gs">/{{ pairName }}</span>
          </div>
          <div class="col-3 mt-2 ts-title">Price</div>
          <div class="col-9 mt-2 ts-value p-0">
            {{coinCurrentValue }}
            {{ pairName }} (Trailing Stop)
          </div>
          <div class="col-3 mt-2 ts-title">Amount</div>
          <div class="col-9 mt-2 ts-value p-0">
            {{ all_coin }}
            {{ coinName }}
            ({{ $MyFunc.NumberFormat(amount) }} {{ pairName }})
          </div>
          <div class="mt-2 border-line"></div>
        </div>

        <div class="row">
          <div
            class="col-3 form-group form-check"
            style="padding-left: 1em !important"
          >
            <label class="stpsl-cb" style="margin-left: 18px">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="stopLoss"
                checked
              />
              <span class="w-title">Stop Loss</span>
            </label>
          </div>
          <div class="col-9" style="padding-right: 20px">
            <div class="row tabs-container" style="width: 344px">
              <div
                class="col-6 tab"
                @click="
                  sl = 'thb';
                  resetSL();
                "
                :class="sl === 'thb' ? 'active' : ''"
              >
                {{ pairName }}
              </div>
              <div
                class="col-6 tab"
                @click="
                  sl = '%';
                  resetSL();
                "
                :class="sl === '%' ? 'active' : ''"
              >
                %
              </div>
            </div>
            <div class="row" v-if="sl == 'thb'">
              <div
                class="col-12 input-row"
                :class="{ 'input-row-sl': sa2i !== null && sa2i !== '' }"
              >
                <cleave
                  :options="options"
                  v-model="sa2i"
                  oninput="validity.valid||(value='');"
                  placeholder="0.00"
                  style="width: 344px; padding-right: 40px"
                ></cleave>
                <span v-if="sa2i == null || sa2i == ''"
                style="top: 20px;">ระบุราคาที่ต้องการขายตัดขาดทุน</span
                >
                <span style="left: 310px">THB</span>
              </div>
              <ul class="thai-font sl-ul">
                <li>
                  ขายเมื่อราคา
                  <span style="color: #8898a0"
                    >&nbsp; {{ this.$MyFunc.NumberFormat(sa2i) || 0 }} บาท</span
                  >
                  (<span v-if="Number(sa2i) < Number(coinCurrentValue)"> -</span
                  >{{
                    this.$MyFunc.NumberFormat(
                      find_percentstoploss(coinCurrentValue, sa2i || 0)
                    )
                  }}
                  % )
                </li>
                <li>
                  ขาดทุนประมาณ
                  {{ Math.abs(coinCurrentValue - sa2i).toLocaleString() }} บาท
                </li>
              </ul>
            </div>

            <div class="row sa-tabs" v-if="sl == '%'" style="margin-top: 8px">
              <div
                style="width: 80px"
                class="col-2 text-center sa-tab-first"
                @click="sa2 = 10"
                :class="sa2 === 10 ? 'active' : ''"
              >
                -10%
              </div>
              <div
                style="width: 80px"
                class="col-2 text-center sa-tab"
                @click="sa2 = 20"
                :class="sa2 === 20 ? 'active' : ''"
              >
                -20%
              </div>
              <div
                style="width: 80px"
                class="col-2 text-center sa-tab"
                @click="sa2 = 30"
                :class="sa2 === 30 ? 'active' : ''"
              >
                -30%
              </div>
              <div
                style="width: 98px"
                class="col-3 text-center sa-tab-last"
                @click="sa2 = null"
                :class="sa2 === null ? 'active' : ''"
              >
                <input
                  type="number"
                  min="0"
                  max="6"
                  v-model="sa2p"
                  @input="handlemax"
                  placeholder="ระบุ %"
                  class="sa-tab-last-input"
                />
              </div>
            </div>
            <ul class="thai-font sl-ul" v-if="sl == '%'">
              <li>
                ขายเมื่อราคา -
                <span style="color: #8898a0" v-if="sa2 ? sa2 : sa2p">{{
                  sa2 ? sa2 : sa2p
                }}</span>
                <span style="color: #8898a0" v-else>0</span>% ({{
                  this.$MyFunc.NumberFormat(percentCalculatedSl)
                }}
                บาท)
              </li>
              <li>ขาดทุนประมาณ {{ $MyFunc.NumberFormat(calculatedSl) }} บาท</li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row" style="margin-top: 15px">
          <div class="col-3 w-title my-auto" style="padding-left: 15px">
            Sell Amount
          </div>
          <div class="col-9">
            <div class="row sa-tabs">
              <div
                class="col-2 text-center sa-tab-first"
                @click="sa3 = 25"
                :class="sa3 === 25 ? 'active' : ''"
                style="width: 63px"
              >
                25%
              </div>
              <div
                class="col-2 text-center sa-tab"
                @click="sa3 = 50"
                :class="sa3 === 50 ? 'active' : ''"
                style="width: 63px"
              >
                50%
              </div>
              <div
                class="col-2 text-center sa-tab"
                @click="sa3 = 75"
                :class="sa3 === 75 ? 'active' : ''"
                style="width: 63px"
              >
                75%
              </div>
              <div
                class="col-2 text-center sa-tab"
                @click="sa3 = 100"
                :class="sa3 === 100 ? 'active' : ''"
                style="width: 63px"
              >
                100%
              </div>
              <div
                class="col-3 text-center sa-tab-last"
                @click="sa3 = ''"
                :class="sa3 === '' ? 'active' : ''"
                style="width: 90px"
              >
                <input
                  type="number"
                  min="0"
                  max="6"
                  @input="handlemax"
                  v-model="sa3p"
                  placeholder="ระบุ %"
                  class="sa-tab-last-input"
                />
              </div>
            </div>
          </div>
          <div class="col-3"></div>
          <div class="col-9">
            <ul
              class="thai-font sl-ul"
              style="
                list-style: outside !important;
                padding: 0px 12px !important;
              "
            >
              <li>
                ขายจำนวน &nbsp;
                <span style="color: #8898a0" v-if="sa3 ? sa3 : sa3p"
                  >{{ sa3 ? sa3 : sa3p }}% </span
                ><span style="color: #8898a0" v-else>0%</span>
                เมื่อราคาเป็นไปตามเงื่อนไข
              </li>
              <li>
                {{ calculatedAllCoin }}
                {{ coinName }}
                ({{ $MyFunc.NumberFormat(calculatedTotalCoin) }} บาท)
              </li>
            </ul>
          </div>
          <button @click="slSubmit()" class="ssl-submit">Submit</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import EventBus from "../../../event-bus";
export default {
  props: [
    "coinName",
    "pairName",
    "coinCurrentValue",
    "totalCoin",
    "all_coin",
    "amount",
    "currentScale",
  ],
  data() {
    return {
      stopLoss: true,
      tp: "thb",
      sl: "thb",
      sa: "100%",
      sa2: 0,
      sa2p: null,
      sa3: 100,
      sa3p: null,
      sa2i: null,
    };
  },
  computed: {
    percentCalculatedSl() {
      let pValue = this.sa2 ? Number(this.sa2) : Number(this.sa2p);
      let calcPercent = (this.coinCurrentValue * pValue) / 100;
      return this.$MyFunc.mathFloorPrice(
        this.coinCurrentValue - calcPercent,
        2
      );
    },
    calculatedSl() {
      let pValue = this.sa2 ? Number(this.sa2) : Number(this.sa2p);
      let calcPercent = (this.coinCurrentValue * pValue) / 100;
      let balance = parseFloat(this.coinCurrentValue - calcPercent);
      let total = parseFloat(this.coinCurrentValue - balance) * this.all_coin;
      return total;
    },
    calculatedAllCoin() {
      let pValue = this.sa3 ? Number(this.sa3) : Number(this.sa3p);
      let calcPercent = (this.all_coin * pValue) / 100;
      let balance = parseFloat((this.all_coin * 100 - calcPercent * 100) / 100);
      let coin = parseFloat((this.all_coin * 100 - balance * 100) / 100);
      return this.$MyFunc.mathFloorPrice(parseFloat(coin), 8);
    },
    calculatedTotalCoin() {
      let pValue = this.sa3 ? Number(this.sa3) : Number(this.sa3p);
      let calcPercent = (this.amount * pValue) / 100;
      let balance = parseFloat(this.amount - calcPercent);
      return parseFloat(this.amount - balance);
    },
    options() {
      return {
        numeral: true,
        numeralIntegerScale: 14,
        numeralDecimalScale: this.currentScale.bathScale,
        numeralThousandsGroupStyle: "thousand",
      };
    },
  },
  methods: {
    clear() {
      this.stopLoss = true;
      this.tp = "thb";
      this.sl = "thb";
      this.sa = "100%";
      this.sa2 = 0;
      this.sa2p = null;
      this.sa3 = 100;
      this.sa3p = null;
      this.sa2i = null;
      this.$emit("sl", {
        sl: null,
        slPercentage: null,
        sm: null,
        sellAmountPercentage: null,
        sa3: null,
        sa3p: null,
      });
    },
    find_percentstoploss(now, price) {
      let balance = price - now;
      let a = (balance / now) * 100;
      let sum = 100;
      if (a == Infinity) {
        sum = 0;
      } else {
        sum = a;
      }
      return Math.abs(sum);
    },
    slSubmit() {
      if (this.stopLoss) {
        // Conditions for 'sl' when stopLoss is true
        if (this.sl === "%") {
          if (
            (this.sa2 === null || this.sa2 <= 0) &&
            (this.sa2p === null || this.sa2p == 0)
          ) {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "Please select or type stop loss percentage to continue",
            });
            return;
          }
        }
        if (this.sl === "thb") {
          if (this.sa2i === null || this.sa2i == 0) {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "Please enter stop loss amount",
            });
            return;
          } else if (
            parseFloat(this.sa2i) > parseFloat(this.coinCurrentValue)
          ) {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "Please specify the price you want to sell to cut losses not exceeding the price value.",
            });
            return;
          }
        }
        this.$bvModal.hide("trailingorder-SSL");
        this.$emit("SLevent", true);
        this.$emit("sl", {
          sl:
            this.sl == "%"
              ? parseFloat(this.percentCalculatedSl)
              : parseFloat(this.sa2i),
          slPercentage: this.sa2 ? this.sa2 : parseFloat(this.sa2p),
          sm: this.calculatedTotalCoin,
          sellAmountPercentage: this.sa3 ? this.sa3 : parseFloat(this.sa3p),
        });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Please select the condition to continue",
        });
      }
    },

    resetSL() {
      this.sa2 = 0;
      this.sa2p = null;
      this.sa2i = null;
    },
    handlemax() {
      if (this.sa2p) {
        let sa2p = parseFloat(this.sa2p.slice(0, 6));
        if (sa2p > 100) {
          this.sa2p = 100;
        } else if (sa2p < 0) {
          this.sa2p = 0;
        } else {
          if (sa2p == 0) {
            this.sa2p = "";
          } else {
            this.sa2p = sa2p;
          }
        }
      }

      if (this.sa3p) {
        let sa3p = parseFloat(this.sa3p.slice(0, 6));
        if (sa3p > 100) {
          this.sa3p = 100;
        } else if (sa3p < 0) {
          this.sa3p = 0;
        } else {
          if (sa3p == 0) {
            this.sa3p = "";
          } else {
            this.sa3p = sa3p;
          }
        }
      }
    },
  },
  mounted() {
    EventBus.$on("update-model-trailingorder-SSL", () => {
      this.clear();
    });
  },
};
</script>
<style>
#trailingorder-SSL___BV_modal_body_ {
  padding: 0px 11px 26px 16px !important;
}
</style>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.border-line {
  border: 2px solid #0a0d10;
  margin-left: -12px;
  width: 560px;
  max-width: 560px;
}
.stop-loss-detail-modal .top-row {
  border-bottom: 0px solid #0a0d10 !important;
}
.sl-ul {
  padding: 0px 8px !important;
  list-style: inside !important;
}
.sl-ul span {
  // position: relative;
  left: -4px;
}
.sa-tabs > div {
  color: #9bacb6;
}
.sa-tabs > .active {
  color: white !important;
}
hr {
  margin: 0px 0px;
  color: #28363e;
}
.stop-loss-detail-modal .header {
  // margin-top: 12px;
  // margin-bottom: 24px ;
  // margin: 0px !important;
  padding-top: 12px;
  width: 100%;
  line-height: 24px;
}
.stop-loss-detail-modal .header > span {
  margin-left: 10px;
}
.stop-loss-detail-modal .tab {
  color: #677f8e;
  line-height: 14px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stop-loss-detail-modal .tab.active {
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-row {
  line-height: 24px;
}
.stop-loss-detail-modal h5 {
  padding: 24px 0px;
  line-height: 24px;
  text-align: center;
  width: 100%;
}
.sa-tab-last-input {
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
  color: white;
  text-align: center;
}
.sa-tab-last-input::placeholder {
  color: #9bacb6;
}
.input-row {
  position: relative;

  span {
    position: absolute;
    top: 20px;
    left: 9px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #677f8e;
  }
}
.input-row-sl {
  input {
    text-align: left !important;
  }
}
</style>
