<template>
  <div>
    <b-modal class="cp1" id="cp1" :hide-footer="true" :hide-header="true">
      <div>
        <h2 style="margin-top: 30px;margin-bottom: 10px">เลือกสี</h2>
        <!-- <span @click="$bvModal.hide('cp1')" class="close"
          ><svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span> -->
        <div class="d-flex justify-content-center" style="margin-top: 0px">
          <!-- <ColorPalet /> -->
          <ColorPicker @check-colorset="changeColor" />
        </div>
        <div class="redd">Red</div>
        <div class="greenn">Green</div>
        <div class="bluee">Blue</div>
        <!-- <div class="d-flex justify-content-center" style="margin-top: 24px">
          <SettingFrame />
        </div> -->
        <!-- <div class="view-color"></div> -->
        <!-- <div class="cc" style="">
              <div class="d-inline-block ct" style="">Red</div>
              <div class="d-inline-block cs">slider</div>
              <div class="cv">0</div>
            </div> -->
        <div style="border: none" class="csf">
          <div
            class="csfb"
            style="position: absolute; bottom: 35px; left: 72px"
          >
            <button @click="confirmcolor()" class="b-o">
              <!-- $bvModal.hide('cp1');
            $bvModal.hide('cp2'); -->
              ยืนยัน
            </button>
            <button @click="$bvModal.hide('cp1')" class="b-g">ยกเลิก</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ColorPalet from "./ColorPalet.vue";
import ColorPicker from "./ColorPicker.vue";
import SettingFrame from "./SettingFrame.vue";

export default {
  prop:["selectcolor"],
  name: "Cp1",
  components: {
    ColorPalet,
    SettingFrame,

    ColorPicker,
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    changeColor(color) {
      this.value = color;
      this.$emit("check-color", color);
    },
    confirmcolor(color) {
      this.$emit("confirm-color", this.value);
    },
  },
};
</script>
<style lang="scss">
.redd {
  margin-top: -169px;
  margin-left: 18px;
}
.greenn {
  margin-top: 30px;
  margin-left: 17px;
}
.bluee {
  margin-top: 30px;
  margin-left: 17px;
}
#cp1___BV_modal_content_ {
  width: 480px;
  height: 701px;
  margin-top: 76px !important;
}
#cp1___BV_modal_body_ {
  .close {
    position: absolute;
    top: 80px;
    right: 30px;
    cursor: pointer;
  }
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  .csf {
    border-top: 1px solid #28363e;
    margin-top: 35px;
    padding-top: 25px;

    p {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #09BE8B;
      cursor: pointer;
    }
    .csfb {
      margin-top: 67px;
      text-align: center;
      button {
        width: 156px;
        height: 40px;
        font-family: "Sarabun";
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        border-radius: 4px;
        margin-left: 8px;
      }

      button.b-o {
        background-color: #09BE8B;
        color: #0a0d10;
        font-weight: 500;
      }
      button.b-g {
        background-color: #191b1c;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
}
</style>
