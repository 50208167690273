<template>
  <div>
    <b-modal
      class="del-modal"
      id="del-modal"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <div>
        <h2>ยืนยันลบการแจ้งเตือน</h2>
        <p>คุณต้องการลบ การแจ้งเตือนราคาเหรียญหรือไม่?</p>
        <div class="btns">
          <button class="o" @click="deleteAlert()">ยืนยัน</button>
          <button class="g" @click="$bvModal.hide('del-modal')">ยกเลิก</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";

export default {
  props: ["id"],
  data() {
    return {};
  },
  methods: {
    deleteAlert() {
      let self = this;
      HTTP.post("/uc/member/delete-coin-price-alert", {
        id: this.id,
      })
        .then((res) => {
          if (res.data.code == 0) {
            self.$notify({
              group: "notification",
              type: "success",
              title: "Success",
              text: "Alert deleted successfully",
            });
            this.$bvModal.hide("del-modal");
            this.$emit("deleteDone", this.id);
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: err,
          });
        });
    },
  },
};
</script>
<style lang="scss">
#del-modal___BV_modal_content_ {
  text-align: center;
  width: 360px;
  height: 247px;
}
#del-modal___BV_modal_body_ {
  padding: 0 !important;
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    margin-top: 22px;
    margin-bottom: 52px;
  }
  p {
    font-family: "Sarabun";
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: #d6dde1;
  }
  .btns {
    text-align: center;
    margin-top: 47px;
    button {
      width: 156px;
      height: 36px;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      margin-left: 8px;
    }
    .o {
      background: #09BE8B;
      color: #0a0d10;
      font-weight: 500;
    }
    .g {
      background: #191b1c;
      color: #ffffff;
      font-weight: 400;
    }
  }
}
</style>
