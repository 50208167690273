<template>
  <div class="wrapper">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h1 class="page-heading m-0">กำหนดต้นทุนการฝากเหรียญ</h1>
          </div>
        </div>
      </div>
      <div v-if="isLoading"> <div class="loader-wrapper"> <div class="loader"></div> </div> </div>
      <div v-else>
        <div class="row m-0" style="margin-left: 48px !important">
        <div class="col-10 p-0">
          <table class="table width-table">
            <thead>
              <th scope="col"></th>
            </thead>
            <tbody>
              <tr class="telephone-number border-bottom-table">
                <td class="text-dark-grey p-0" style="width: 195px">
                  <p style="margin-bottom: 20px">วิธีการระบุต้นทุน</p>
                </td>
                <td class="p-0">
                  <p class="table-text-white m-0">
                    <span v-if="rdoCostDeposit == '0'">ระบุต้นทุนด้วยตัวเอง</span>
                    <span v-if="rdoCostDeposit == '1'">ระบุต้นทุนด้วยราคาตลาด</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <p class="text-dark-grey mb-4" style="margin-left: 48px !important">
          เลือกวิธีการระบุต้นทุนการฝากเหรียญของคุณ
        </p>
      </div>
      <div v-if="configResponse == null">
        <!-- Skeleton loading for radio buttons -->
        <div style="margin-left: 48px !important">
          <div class="form-check">
            <div class="skeleton-radio" style="height: 16px; width: 150px;">
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="100%"
              >
              </b-skeleton>
            </div>
            <div class="skeleton-text" style="height: 12px; width: 350px;margin-top: 5px;">
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="100%"
              >
              </b-skeleton>
            </div>
          </div>
        </div>

        <div style="margin-left: 48px !important; margin-top: 24px">
          <div class="form-check">
            <div class="skeleton-radio" style="height: 16px; width: 150px;">
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="100%"
              >
              </b-skeleton>
            </div>
            <div class="skeleton-text" style="height: 12px; width: 350px; margin-top: 5px;">
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="100%"
              >
              </b-skeleton>
            </div>
          </div>
        </div>

        <!-- Skeleton loading for buttons -->
        <div class="row justify-content-start" style="margin: 40px 0px 66px 80px">
          <div class="col-4 p-0">
            <div class="button text-center d-flex">
              <div class="skeleton-button" style="height: 36px; width: 100px;">
                <b-skeleton
                  class="skc custom-skeleton-wave"
                  animation="wave"
                  width="100%"
                >
                </b-skeleton>
              </div>
              <div class="skeleton-button" style="height: 36px; width: 100px; margin-left: 5px;">
                <b-skeleton
                  class="skc custom-skeleton-wave"
                  animation="wave"
                  width="100%"
                >
                </b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="" style="margin-left: 48px !important">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="rdoCostDeposit"
              v-model="rdoCostDeposit"
              id="rdoCostDeposit1"
              value="0"
            />
            <label class="form-check-label m-0 ml-8" for="rdoCostDeposit1">
              ระบุต้นทุนด้วยตัวเอง
            </label>
            <p class="text-dark-grey mb-0 ml-8">
              ผู้ใช้งานกำหนดราคาต้นทุนเหรียญที่ฝากเข้ามาด้วยตนเอง
            </p>
          </div>
        </div>

        <div style="margin-left: 48px !important; margin-top: 24px">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              v-model="rdoCostDeposit"
              name="rdoCostDeposit"
              id="rdoCostDeposit2"
              value="1"
            />
            <label
              class="form-check-label m-0 ml-8"
              for="rdoCostDeposit2"
              style="color: #9bacb6"
            >
              ระบุต้นทุนด้วยราคาตลาด
            </label>
            <p class="text-dark-grey mb-0 ml-8">
              กำหนดต้นทุนฝากเหรียญโดยใช้ราคาตลาด
            </p>
          </div>
        </div>
        <!-- buttons -->
        <div class="row justify-content-start" style="margin: 40px 0px 66px 80px">
          <div class="col-4 p-0">
            <div class="button text-center d-flex">
              <button
                type="button"
                style="margin-right: 16px; height: 36px"
                class="btn btn-primary btn-text-sarabun buttonWidth"
                @click="UpdateConfig()"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
     
    </div>
    <!-- common success modal -->
    <!-- common success modal -->
    <div v-if="ModalSuccess">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div class="checkTick" style="padding-top: 48px">
                          <img
                            class="image-tick-modal"
                            src="../../assets/images/profile-setting/tick.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message">
                          <p
                            class="text-grey mb-0"
                            style="font-size: 18px; font-weight: 600"
                          >
                            ระบบได้รับข้อมูลของคุณแล้ว
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="ModalSuccess = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              border-radius: 4px;
                              font-weight: 400;
                              font-family: Sarabun !important;
                            "
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- common success modal end -->
  </div>
</template>

<script>
import memberConfigMixin from './MemberConfig.js';
export default {
  name: "DetermineCost",
  mixins: [memberConfigMixin],
  data() {
    return {
      ModalSuccess: false,
      rdoCostDeposit: 1,
    };
  },
  methods: {
    UpdateConfig(){
      this.updateMemberConfigs('COST_COIN_DEPOSIT', this.rdoCostDeposit);
    },
  },
  created (){
    this.getMemberConfigs();
  },
};
</script>
<style scoped>
.ml-8 {
  margin-left: 8px !important;
}
input[type="radio"] {
  width: 20px;
  height: 20px;
  background: #191b1c;
  border: 1px solid #38454d;
  border-radius: 26px;
}
.w-400 {
  font-weight: 400 !important;
}
.success-message p {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: rgb(255, 255, 255);
}
label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.buttonWidth {
  width: 140px !important;
}
.form-check-input:checked {
  background-color: #09BE8B !important;
  border-color: #09BE8B !important;
}
.bg-box {
  background-color: #191b1c;
}
.page-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: rgb(255, 255, 255);
}
.Heading {
  margin: 24px 0px 22px 48px;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
}
.width-table {
  max-width: 764px;
  margin-bottom: 24px;
}

.table-text {
  color: #9bacb6;
  font-size: 14px;

  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.table-text-white {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
/* modal */
.buttonSuccess {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Noto Sans Thai" !important;
}
.form-control {
  color: #09BE8B !important;
  outline: none;
}
.modal-title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 28px;
}
.text-grey {
  color: rgb(255, 255, 255);
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.correct-button {
  float: right;
  clear: both;
}
.text-dark-grey {
  color: #9bacb6;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-content {
  width: 100% !important;
  background-color: #141b1f !important;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}
.btn-cancel {
  color: white;
  background-color: #191b1c;
}
.button-confirm {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}
ul {
  list-style: none;
  color: #9bacb6 !important;
}
ul li::before {
  content: "\2022";
  color: #9bacb6 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* otp modal */
.otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  text-align: center;
}
/* otp modal end */

/* modal otp finish succes */
.otp-success {
  max-width: 409px !important;
}
.otp-error {
  max-width: 409px !important;
}
.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.image-cross-modal {
  background-color: linear-gradient(360deg, #de2d40 0%, #ff2500 100%);
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.checkTick {
  padding-top: 100px;
}
.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}
.finisButton-SuccesOtp {
  padding-bottom: 100px;
}
/* modal otp finish succes end */

/* // %%%  scroller  start %%%% */

.scroller {
  height: 400px;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.pinInput-width {
  width: 240px !important;
}
.SurnameWidth {
  width: 280px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #121516;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-device-width: 768px) {
  .table-text {
    font-size: 10px;
  }
  .table-text-white {
    font-size: 10px;
  }

  .table-text-yellow {
    font-size: 10px;
  }
}
</style>
