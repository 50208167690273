<template>
  <div>
    <div
      class="withdraw-cash-container thai-font"
      :class="currentSettings === 'qr' ? 'qr-h' : 'bc-h'"
    >
      <!-- top section start -->
      <div class="dcc-head">
        <div class="col-12" style="display: inline-flex">
          <router-link to="/withdraw/cash">
            <button :class="ActiveWithdrawCash" :style="withdrawCashDisplay">
              {{ $t('withdrawcash') }}
            </button>
          </router-link>
          <router-link to="/withdraw/coin">
            <button :class="ActiveWithdrawCoin" :style="withdrawCoinDisplay">
              {{ $t('withdrawcoin') }}
            </button>
          </router-link>
        </div>
      </div>
      <!-- top section end -->
      <div v-if="!reslockStatusWithdrawCash">
        <!-- center section start -->
        <div class="row mt-3 center-section">
          <div class="col-12">
            <div
              class="row"
              v-if="currentSettings === 'qr'"
              style="margin-top: 16px"
            >
              <div class="col-5 col-md-4 my-auto l-24">
                จำนวนเงินที่ต้องการถอน
              </div>
              <div class="col-7 col-md-8 input-container">
                <cleave
                  v-model="ci"
                  :options="options"
                  placeholder="0.00"
                  @input="setMaxWithdrawCash"
                  @blur="setBlurMaxWithdrawCash"
                ></cleave>
                <span class="lh-24">THB</span>
              </div>
            </div>
            <div class="row">
              <div class="col-7 col-md-8 offset-md-4 input-container">
                <div class="input_label">
                  <div style="display: inline; line-height: 18px">
                    {{ $t('amountwithdraw') }}
                  </div>
                  &nbsp;
                  <div style="display: inline; line-height: 14px">
                    {{ $MyFunc.NumberFormat(thbWallet.balance) }} THB
                  </div>
                  &nbsp;&nbsp;&nbsp;<u
                    style="line-height: 24px; cursor: pointer"
                    @click="withdrawAllClick"
                    >{{ $t('withdrawall') }}</u
                  >
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-5 col-md-4 my-auto l-24">
                {{ $t('bankmoney') }}
              </div>
              <div class="col-7 col-md-8 l-24">
                <div class="d-info">
                  <div class="bankList" v-if="bData">
                    <p>{{ realName.slice(0, 40) }}</p>
                    <p>{{ bData.bankname.slice(0, 40) }}</p>
                    <p>{{ bData.bankaccno.slice(0, 40) }}</p>
                    <p @click="mainPage()">{{ $t('change') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4" v-if="currentSettings === 'qr'">
              <div class="col-12 info">
                <p class="lh-24" style="margin-left: -2px">   {{ $t('samarywithdraw') }}</p>
                <div class="row" style="margin-top: 8px">
                  <div class="col-5 col-md-4 my-auto del-font">
                    {{ $t('samarywithdraw1') }}
                  </div>
                  <div
                    class="col-7 col-md-8 l-24 font-roboto-flex">
                    <span v-if="ci > 0">{{ $MyFunc.NumberFormat(ci) }}</span>
                    <span v-else>0.00</span> THB
                  </div>
                  <div class="col-5 col-md-4 my-auto del-font">
                    {{ $t('samarywithdraw2') }}
                  </div>
                  <div
                    class="col-7 col-md-8 l-24 font-roboto-flex">
                    {{ $MyFunc.NumberFormat(bFee,2) }} THB
                  </div>
                  <div class="col-5 col-md-4 my-auto del-font">
                    {{ $t('samarywithdraw3') }}
                  </div>
                  <div
                    class="col-7 col-md-8 l-24 font-roboto-flex">
                    <span v-if="withdrawAmount > 0">{{
                      $MyFunc.NumberFormat(withdrawAmount)
                    }}</span>
                    <span v-else>0</span> THB
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentSettings === 'bank_transfer'">
              <div class="bcb"></div>
              <div class="row">
                <div class="col-12 bc-info">
                  <p>ยอมรับเงื่อนไขการฝากเงิน</p>
                  <ul class="">
                    <li>โอนเงินด้วยบัญชีที่ให้ไว้กับบริษัทเท่านั้น</li>
                    <li>โอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคารต้นทาง)</li>
                  </ul>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 bc-info">
                  <p>ยอมรับเงื่อนไขการฝากเงิน</p>
                  <ul class="number">
                    <li>โอนเงินด้วยบัญชีที่ให้ไว้กับบริษัทเท่านั้น</li>
                    <li>โอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคารต้นทาง)</li>
                    <li>QR Code ใช้งานได้ไม่เกิน 24 ชั่วโมง</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- center section start -->
        <!-- bottom section start -->
        <div
          class="pt-1 bottom-section mt-3 b-ins"
          v-if="currentSettings === 'qr'"
        >
          <p>     {{ $t('notewithdraw') }}</p>
          <ul>
            <li> {{ $t('notewithdraw1') }}</li>
            <li> {{ $t('notewithdraw2') }}</li>
            <li> {{ $t('notewithdraw3') }}</li>
          </ul>

          <div class="last-btn">
            <button
              :disabled="btnDisabled"
              :class="{ btnDisabled: btnDisabled }"
              @click="showOS()"
            >
            {{ $t('submitwithdraw') }}
            </button>
          </div>
        </div>
        <!-- bottom section end -->
      </div>
      <div v-else class="text-center" style="padding: 150px">
        คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน
      </div>

     
      <!-- <div class="right-info">
        <p style="color: #677f8e">คู่มือแนะนำการทำรายการถอน</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%96%e0%b8%ad%e0%b8%99%e0%b9%80%e0%b8%87%e0%b8%b4%e0%b8%99.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >	คู่มือแนะนำการถอนเงิน</a
            >
          </li>
        </ul>
      </div> -->
    </div>
    <RightInfo />
    <otp-model
      :otp="true"
      :bData="bData"
      :realName="realName"
      :bFee="bFee"
      :withdrawAmount="withdrawAmount"
      :ci="ci"
      :requestId="requestId"
      :otpReference="otpReference"
      :refCode="refCode"
      @UpdateRefCode="UpdateRefCode"
    ></otp-model>
  </div>
</template>
<script>
import OtpModel from "@/components/Modal/WIthdrawCashOtpModel.vue";
import RightInfo from "../../../components/deposit-withdraw/RightInfo";
import { HTTP } from "@/config/axios";
export default {
  components: {
    OtpModel,
    RightInfo,
  },
  data() {
    return {
      open_dropdown: false,
      selected_option: "QR Code (Prompt Pay)",
      currentSettings: "qr",
      showWithdrawOTPModal: false,
      ci: null,
      bankData: [],
      selectedBank: [],
      realName: "",
      bData: "",
      bFee: "",
      bMinimum: 0,
      requestId: "",
      otpReference: "",
      refCode: "",
      thbWallet: {
        coin: {
          minWithdrawAmount: "",
        },
      },
      options: {
        numeral: true,
        numeralIntegerScale: 10,
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
      },
      reslockStatusWithdrawCash: false,
      reslockStatusWithdrawCoin: false,
      withdrawCashDisplay: "display: block;",
      withdrawCoinDisplay: "display: block;",
      ActiveWithdrawCash: "active",
      ActiveWithdrawCoin: "not-active",
      btnDisabled: false,
    };
  },
  created() {
    let mbInfo = localStorage.getItem("mb-info");
    let mbl = JSON.parse(localStorage.getItem("bl"));
    this.selectedBank = JSON.parse(mbInfo);
    this.getBankAccountList();

    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (user_data?.id) {
      let storedData = JSON.parse(localStorage.getItem("resMember") || "{}");
      if (storedData) {
        this.reslockStatusWithdrawCash =
          storedData?.reslockStatus?.cannotWithdrawCash;
        this.reslockStatusWithdrawCoin =
          storedData?.reslockStatus?.cannotWithdrawCoin;
      }
    }
  },
  metaInfo() {
    let pageTitle = "Withdraw";
    return {
      title: pageTitle,
      titleTemplate: "%s | "+ this.TITLE ,
    };
  },
  methods: {
    minwithdraw_format(value){
      var min = value ? "" + value : value
      return min
    },
    format_accountnumber(value) {
      if (value.length == 10) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 4);
        let c = value.slice(4, 9);
        let d = value.slice(9, 10);
        return a + "-" + b + "-" + c + "-" + d;
      }
      else  if  (value.length == 12) {
        let a = value.slice(0, 3);
        let b = value.slice(3, 5);
        let c = value.slice(5, 11);
        let d = value.slice(11, 12);
        return a + "-" + b + "-" + c + "-" + d;
      } else{
        return value
      }
    },
    getBankAccountList() {
      this.btnDisabled = false;
      HTTP.post("/uc/withdraw/bankaccountlist")
        .then((res) => {
          this.bFee = res.data.data.fee ? res.data.data.fee : 20;
          this.bMinimum = res.data.data.minimum ? res.data.data.minimum : 20;
          localStorage.setItem("bl", JSON.stringify(this.bFee));

          if (res?.data.data.userInfo.length > 0) {
            let bankSelect = res.data.data.userInfo.find(
              (x) => x.mainbank == true
            );
            if (bankSelect?.bankaccno) {
              this.bData = bankSelect;
            } else {
              this.bData = res.data.data.userInfo[0];
            }
            //console.log("bData=", this.bData);
            localStorage.setItem("mb-info", JSON.stringify(this.bData));
            this.realName = this.bData.accountName; //res.data.data.realName;
          } else {
            this.btnDisabled = true;
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "กรุณาเพิ่ม Whiitelist Bank ก่อนการขอถอนเงิน",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateRefCode(newRefCode) {
      this.refCode = newRefCode;
    },
    mainPage() {
      this.$router.push("/withdraw/cash_main_account");
    },
    openOTPModel() {
      this.showWithdrawOTPModal = true;
    },
    sendRequetsWithdraw() {
      if (this.ci <= 0) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "the amount should not be empty",
        });
        return false;
      }
      if (this.ci < 20) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "the amount should greater than 20 baht",
        });
        return false;
      }
      let self = this;
      let allBalance = 0;
      if (this.thbWallet.balance == this.ci) {
        allBalance = 1;
      }
      HTTP.post("/uc/withdraw/newwithdrawsendotp", {
        amount: this.ci,
        idBank: this.bData.bankaccno,
        refCode: this.refCode,
        whitelistBankId: this.bData.whitelistBankRequestId,
        allBalance: allBalance,
      })
        .then((res) => {
          /*
          self.$notify({
              group: "notification",
              type: res.data.code === 0 ? "success" : "error",
              title:
              res.data.code === 0
                  ? "OTP Send to Mobile Successful"
                  : "Unable to Send OTP to Mobile",
              text: res.data.message,
          });
          */
          if (res.data.code === 0 && res.data.data.requestId) {
            self.requestId = res.data.data.requestId;
            self.otpReference = res.data.data.otpReference;
            self.refCode = res.data.data.otpReference;
            self.$bvModal.show("otp-success-modal");
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "ระบบปิดการถอนเงินชั่วคราว",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    withdrawAllClick() {
      this.ci = this.thbWallet.balance;
    },
    showOS() {
      if(parseFloat(this.ci)){
        if(parseFloat(this.ci) < parseFloat(this.thbWallet.coin.minWithdrawAmount)){
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: `Minimum withdraw amount is ${this.thbWallet.coin.minWithdrawAmount} THB`,
          });
        }else if(parseFloat(this.ci) > parseFloat(this.thbWallet.balance)){
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "the amount should not be greater then withdrawable amount",
          });
        }else if(parseFloat(this.ci) < (parseFloat(this.bFee)+parseFloat(this.bMinimum))){
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please enter the amount you wish to withdraw. more than withdrawal fees",
          });
        }else{
          this.sendRequetsWithdraw();
        }
      }else{
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "the amount should not be empty or 0",
        });
      }
    },
    fetchWalletData() {
      HTTP.get("/uc/asset/wallet")
        .then((res) => {
          let thbData = res.data.data.filter((item) => item.coin.unit == "THB");
          let coinFloor = (
            Math.floor(Math.trunc(thbData[0].balance * 100)) / 100
          ).toFixed(2);
          thbData[0].balance = coinFloor;
          this.thbWallet = thbData[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setMaxWithdrawCash() {
      if (parseFloat(this.ci) > parseFloat(this.thbWallet?.balance)) {
        if (this.thbWallet?.balance <= 0) {
          this.ci = 0;
        } else {
          this.ci = this.thbWallet?.balance;
        }
      }
    },
    setBlurMaxWithdrawCash(){
      setTimeout(()=>{
        let ci  = parseFloat(this.ci);
        let tb = parseFloat(this.thbWallet.balance);
        if (ci >= tb) {
          this.ci = tb;
        }
      }, 100);
    }
  },
  computed: {
    withdrawAmount() {
      if (!this.ci) {
        return 0.0;
      } else {
        return this.ci - this.bFee;
      }
    },
  },
  mounted() {
    this.fetchWalletData();
  },
};
</script>

<style lang="scss">
.withdraw-cash-container {
  border-radius: 8px;
  position: relative;

  .dcc-head {
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;

      padding: 12.5px 0;

      margin-left: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;

      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }

  .center-section {
    .select-container {
      background-color: #191b1c;
      color: #d6dde1;
      border-radius: 4px;
      height: 40px;
      width: 240px;
      margin: auto 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .icon {
        pointer-events: none;
        position: absolute;
        right: 20px;
      }

      .rotate-sc-icon {
        transform: rotateX(180deg);
      }

      .text {
        padding-left: 15px;
      }

      .options {
        position: absolute;
        bottom: -82px;
        width: 100%;
        background-color: #191b1c;
        border-radius: 4px;

        ul {
          margin: 0;
          padding: 0;

          li {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 15px;
            border-radius: 4px;

            &:hover {
              background-color: #2c3b44;
            }
          }
        }
      }

      .hidden {
        display: none;
      }

      .show {
        display: block;
      }
    }

    .input-container {
      input {
        background-color: #191b1c;
        color: #d6dde1;
        border-radius: 4px;
        height: 40px;
        width: 220px;
        padding: 0 45px 0 18px;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        &::placeholder {
          color: #566a76;
        }
      }

      span {
        color: #9bacb6;
        margin-left: -40px;
      }
    }

    .info {
      color: #d6dde1;
      padding-left: 14px;

      p {
        margin: 0;
      }

      ul {
        list-style: disc;
      }
    }

    .lr {
      font-size: 16px;

      span {
        font-size: 14px;
        color: #09BE8B;
        margin-left: 8px;
      }
    }

    .bcb {
      margin: 0 -27px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-top: 1px solid #28363e;
    }

    .bc-info {
      ul {
        list-style: disc;
        padding: 0 24px;

        li {
          color: #9bacb6;
          width: 70%;
        }
      }

      ul.number {
        list-style: decimal;
      }
    }
  }

  .bottom-section {
    border-top: 1px solid #28363e;

    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
      margin-top: -0.1px;
    }

    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }

    span {
      margin-left: 10px;
    }

    .last-btn {
      text-align: center;
      margin-top: 50px;

      button {
        color: #0a0d10;
        background-color: #09BE8B;
        border-radius: 4px;
        height: 36px;
        width: 134px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &:hover {
          background-color: #66cdb0;
        }
      }
      button.btnDisabled {
        opacity: 0.2;
      }
    }
  }
}

.right-info {
  position: absolute;
  top: 3px;
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;

  p {
    margin-bottom: 10px;
  }

  ul {
    font-weight: 400;
    list-style: disc;
    position: relative;
    left: -8px;

    li {
      &:hover {
        color: #d6dde1;
      }

      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}

@media (min-width: 640px) {
  .withdraw-cash-container {
    width: 616px;
    padding-bottom: 0;

    .center-section {
      padding: 0 30px;
    }

    // .select-container {
    //   select {
    //     width: 240px;
    //   }
    // }
    .input-container {
      input {
        width: 240px;
      }
    }
  }

  .qr-h {
    height: 458px;
  }

  .bc-h {
    height: 580px;
  }
}

@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}

@media (min-width: 1024px) {
  .right-info {
    display: block;
    // right: -14px;
    // margin-right: -255px;
    top: 0;
  }

  .withdraw-cash-container {
    transform: translateX(-50%);
  }
}

@media (max-width: 848px) {
  .withdraw-cash-container {
    background-color: none;
    // margin-left: 40px;
    width: 100%;

    .dcc-head {
      background-color: #121516;
    }

    .bottom-section {
      margin: 0 27px;
    }

    .bcb {
      margin: 0 20px;
    }

    .center-section {
      margin: 0 3px;
    }
  }
}

@media (min-width: 848px) {
  .withdraw-cash-container {
    background-color: #121516;
    margin-left: 50%;
    margin-top: 32px;
    transform: translateX(-75.7%);

    .dcc-head {
      border-bottom: 1px solid #28363e;
    }

    .bottom-section {
      margin: 0 24px;
    }

    .bcb {
      margin: 0 -4px;
    }
  }
}
</style>
<style scoped>
.bankList p {
  display: block;
  margin: 0;
  padding: 0;
}
.bankList p:last-child {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  color: #09BE8B;
}
.withdraw-cash-container {
  min-height: 580px !important;
}

.input-container .input_label {
  margin: 0px;
  line-height: 24px;
  color: #677f8e;
  font-size: 12px;
  font-weight: 400;
  font-size: 12px;
}

.d-info {
  max-width: 100%;
  height: 80px;
  background: #191b1c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #9bacb6;
  padding: 0px 10px;
  font-size: 14px;
  border-radius: 4px;
  font-family: "Sarabun";
  margin-right: 16px;
}

.d-info > div {
  position: relative;
}

.d-info > div span:nth-child(3) {
  color: #09BE8B;
  margin-top: -15px;
  display: block;
}

.del-font {
  color: #677f8e;
  line-height: 24px;
  font-size: 14px;
}

.l-24 {
  line-height: 24px;
}

.b-ins p {
  color: #677f8e;
  margin: 0px;
  line-height: 24px;
}

.b-ins ul {
  color: #677f8e;
  font-size: 14px;
  line-height: 24px;
  list-style: inherit;
}
</style>
